import styled from 'styled-components';

import { Button } from '../../../../../components';
import { fonts, colors } from '../../../../../styles/theme';
import { toRem } from '../../../../../utils';

export const WrapperStyled = styled.div`
  display: flex;
  width: 620px;
  flex-direction: column;
  padding: 1rem;
`;

export const TextStyled = styled.div`
  margin: ${toRem(36)} 0;
  color: ${colors.color1};
  ${fonts.lato.H16}

  strong {
    ${fonts.lato.H16B}
  }
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1.5rem;
`;
