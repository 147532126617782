import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { colors, fonts } from '../../../../styles/theme';
import { returnColorBaseOnValue, returnIconBaseOnValue, truncateNumberAndRoundWithDecimals } from '../../../../utils';

type CellProps = {
  val: any;
};

type PercentageStyledProps = {
  color: string;
};

type IconStyledProps = {
  color: string;
};

type GridCellStyledProps = {
  readonly small?: boolean;
};

const CellWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PercentageStyled = styled.div<PercentageStyledProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${fonts.lato.H14B}
  border-radius: 15px;
  width: fit-content;
  padding: 5px;
  height: 30px;
  color: ${({ color }) => color};
  background: ${({ color }) => {
    switch (color) {
      case colors.color14:
        return 'rgba(225, 93, 104, 0.05)';
      case colors.color2:
        return 'rgba(247, 141, 45, 0.05)';
      case colors.color21:
        return 'rgba(75, 199, 192, 0.05)';
      default:
        return 'none';
    }
  }};
  margin-left: 1rem;
`;

export const IconStyled = styled(FontAwesomeIcon)<IconStyledProps>`
  width: 15px;
  color: ${({ color }) => color};
`;

const CellFormatted = ({ val }: CellProps) => <CellWrapper>{val}</CellWrapper>;

const PublisherNameAndId = ({ val }: CellProps) => (
  <CellWrapper>{`${val.id} - ${val.publisher.companyName}`}</CellWrapper>
);

const GridCellStyled = styled.div<GridCellStyledProps>`
  display: grid;
  grid-template-columns: ${({ small }) => (small ? '20% 80%' : '35% 65%')};
  text-align: left;
  width: 100%;
  align-items: center;
`;

const FlexItemStyled = styled.div`
  display: flex;
  align-items: center;
`;

const PublisherTransactionsFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <GridCellStyled small>
      <FlexItemStyled>{val.approvedTransactions}</FlexItemStyled>
      <FlexItemStyled>
        <PercentageStyled
          color={returnColorBaseOnValue({
            value: val.approvedTransactionsIncrease,
            negativeColor: colors.color14,
            neutralColor: colors.color2,
            positiveColor: colors.color21,
          })}
        >
          {val.approvedTransactionsIncrease !== 0 && (
            <IconStyled
              icon={returnIconBaseOnValue<IconProp>({
                value: val.approvedTransactionsIncrease,
                negativeIcon: faLongArrowAltDown,
                positiveIcon: faLongArrowAltUp,
              })}
              color={returnColorBaseOnValue({
                value: val.approvedTransactionsIncrease,
                negativeColor: colors.color14,
                neutralColor: colors.color2,
                positiveColor: colors.color21,
              })}
            />
          )}
          {truncateNumberAndRoundWithDecimals(Math.abs(val.approvedTransactionsIncrease), 0)}%
        </PercentageStyled>
      </FlexItemStyled>
    </GridCellStyled>
  </CellWrapper>
);

const CommissionsFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <GridCellStyled>
      <FlexItemStyled>${val.totalCommissions}</FlexItemStyled>
      <FlexItemStyled>
        <PercentageStyled
          color={returnColorBaseOnValue({
            value: val.commissionIncrease,
            negativeColor: colors.color14,
            neutralColor: colors.color2,
            positiveColor: colors.color21,
          })}
        >
          {val.commissionIncrease !== 0 && (
            <IconStyled
              icon={returnIconBaseOnValue<IconProp>({
                value: val.commissionIncrease,
                negativeIcon: faLongArrowAltDown,
                positiveIcon: faLongArrowAltUp,
              })}
              color={returnColorBaseOnValue({
                value: val.commissionIncrease,
                negativeColor: colors.color14,
                neutralColor: colors.color2,
                positiveColor: colors.color21,
              })}
            />
          )}
          {truncateNumberAndRoundWithDecimals(Math.abs(val.commissionIncrease), 0)}%
        </PercentageStyled>
      </FlexItemStyled>
    </GridCellStyled>
  </CellWrapper>
);

const ProductNameAndId = ({ val }: CellProps) => <CellWrapper>{val.product.name}</CellWrapper>;

export const topPublisherColumns: TableColumn[] = [
  {
    dataField: 'publisher',
    text: 'Publishers',
    width: '40%',
    formatter: (_: any, row: any) => <PublisherNameAndId val={row} />,
  },
  {
    dataField: 'approvedTransactions',
    text: 'Approved Transactions',
    width: '30%',
    formatter: (_: any, row: any) => <PublisherTransactionsFormatted val={row} />,
  },
  {
    dataField: 'totalCommissions',
    text: 'Commission',
    width: '30%',
    formatter: (_: any, row: any) => <CommissionsFormatted val={row} />,
  },
];

export const topProductColumns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'Name',
    width: '30%',
    formatter: (_: any, row: any) => <ProductNameAndId val={row} />,
  },
  {
    dataField: 'clicks',
    text: 'Total Clicks',
    width: '17.5%',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'totalTransactions',
    text: 'Total Transactions',
    width: '17.5%',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'approvedTransactions',
    text: 'Approved Transactions',
    width: '17.5%',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'totalCommission',
    text: 'Total Commission',
    width: '17.5%',
    formatter: (_: any, row: any) => <CommissionsFormatted val={row} />,
  },
];
