import React from 'react';

import {
  AppWrapper,
  Calendar,
  ErrorBanner,
  InputCalendar,
  PageTitle,
  Pagination,
  RecordsDropdown,
  Table,
} from 'components';
import { PRODUCT_CATEGORY, RECORDS_PER_PAGE_OPTIONS, path } from 'utils';
import { DefaultPropsType } from 'types';
import PlaceHolderNoResults from 'components/NoResultsPlaceHolder';

import { columns } from './contracts';
import { useActiveCommissions } from './hooks';
import { PAGE_DESCRIPTION } from './enums';
import * as Styled from './styles';

type ActiveCommissionsProps = DefaultPropsType;

const ActiveCommissions = ({ permissionsCodeList = [] }: ActiveCommissionsProps): JSX.Element => {
  const hook = useActiveCommissions();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
      <PageTitle isLoading={hook.hookPageLoading}>
        Active&nbsp;
        {path.activeComissions.name}
      </PageTitle>
      <Styled.DescriptionStyled>{PAGE_DESCRIPTION}</Styled.DescriptionStyled>
      <Styled.FiltersStyled>
        <Styled.FiltersSelectStyled>
          <InputCalendar
            label="Active since"
            placeholder="Select Date"
            value={hook.selectedDate ? hook.selectedDate.toDateString() : ''}
            onClick={hook.openCalendar}
            isDisabled={hook.hookPageLoading}
          />
          <Styled.SelectStyled
            name="Product Category"
            label="Product Category"
            placeholder="Select Product Category"
            onChange={hook.onProductCategoryChange}
            selected={hook.productCategory}
            options={PRODUCT_CATEGORY}
            isDisabled={hook.hookPageLoading}
          />
          <Styled.SelectStyled
            name="Product"
            label="Product"
            placeholder="Select Product"
            onChange={hook.onProductChange}
            selected={hook.selectedProduct}
            options={hook.productList.map((p: { id: string; name: string }) => ({
              label: p.name,
              value: p.id,
            }))}
            isDisabled={hook.hookPageLoading || hook.getProductsLoading}
          />
        </Styled.FiltersSelectStyled>
        <Styled.FiltersSelectStyled>
          <Styled.SelectStyled
            name="Merchant"
            label="Merchant"
            placeholder="Merchant"
            onChange={hook.hookSetSelectedMerchant}
            options={hook.hookMerchantList}
            selected={hook.hookSelectedMerchant}
            isLoading={hook.hookPageLoading}
            isDisabled={hook.hookPageLoading}
          />
          <Styled.SelectStyled
            name="Commission Type"
            label="Commission Type"
            placeholder="Select Commission Type"
            onChange={hook.setSelectedCommissionType}
            selected={hook.selectedCommissionType}
            options={hook.commissionTypes}
            isDisabled={hook.hookPageLoading}
          />
          <Styled.ClearFormButtonStyled
            onClick={hook.onClearFormClick}
            type="reset"
            theme="text-only"
            disabled={hook.hookPageLoading}
          >
            Clear Form
          </Styled.ClearFormButtonStyled>
        </Styled.FiltersSelectStyled>

        <Styled.RowStyled>
          <RecordsDropdown
            selected={hook.records}
            onChange={hook.setRecords}
            options={RECORDS_PER_PAGE_OPTIONS}
            totalRecords={hook.totalRecords}
            currentPage={hook.hookCurrentPage}
          />
        </Styled.RowStyled>
      </Styled.FiltersStyled>

      {hook.hookTableData.length > 0 || hook.hookPageLoading ? (
        <>
          <Table
            columns={columns}
            data={hook.hookTableData}
            isLoading={hook.hookPageLoading}
            onSort={hook.hookSortTableHandler}
            loadWithData={hook.hookTableData.length > 0}
            sortColumn={hook.hookSortColumn}
          />
          <Pagination
            currentPage={hook.hookCurrentPage}
            total={hook.hookTotalPages}
            onPageChange={hook.hookSetCurrentPage}
          />
        </>
      ) : (
        <PlaceHolderNoResults />
      )}

      <Calendar
        date={hook.selectedDate}
        onApply={hook.onApplySelectedCalendar}
        onCancel={hook.onCancelSelectedCalendar}
        isOpen={hook.calendarIsOpen}
        hasRange={false}
        isSingle
      />
    </AppWrapper>
  );
};

export default ActiveCommissions;
