import { useState } from 'react';

import { IMAGE_FILE_TYPES, ZIP_FILE_TYPES, FILE_UPLOAD_ERR_MESSAGES, CSV_FILE_TYPES } from '../../../../utils/enums';

type OnChangeType = (files: File | FileList) => void;

export const useFileUpload = (maxFileSize: number, type?: string, fileSizeUnit?: string) => {
  const [fileState, setFileState] = useState<File | FileList | undefined>();
  const [fileNameState, setFileNameState] = useState<string>();
  const [errorState, setErrorState] = useState<string>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, onChange: OnChangeType, uploadedFiles?: any) => {
    const { files } = uploadedFiles || e.target;

    if (files && files.length) {
      if (files.length === 1) {
        let fileTypeError = false;
        if (type) {
          switch (type) {
            case 'image':
              if (!IMAGE_FILE_TYPES.includes(files[0].type)) {
                fileTypeError = true;
                setErrorState(FILE_UPLOAD_ERR_MESSAGES(type));
              }
              break;
            case 'zip':
              if (!ZIP_FILE_TYPES.includes(files[0].type)) {
                fileTypeError = true;
                setErrorState(FILE_UPLOAD_ERR_MESSAGES(type));
              }
              break;
            case 'csv':
              if (!CSV_FILE_TYPES.includes(files[0].type)) {
                fileTypeError = true;
                setErrorState(FILE_UPLOAD_ERR_MESSAGES(type));
              }
              break;
            default:
              break;
          }
        }
        if (files[0].size > maxFileSize || fileTypeError) {
          if (fileTypeError) return;
          const formatted = fileSizeUnit === 'KB' ? maxFileSize / 1000 : maxFileSize / 1000000;
          setErrorState(FILE_UPLOAD_ERR_MESSAGES('maxFileSize', formatted, fileSizeUnit));
        } else {
          setErrorState('');
          setFileState(files[0]);
          setFileNameState(files[0].name);
          onChange(files[0]);
        }
      } else {
        let isBig = false;

        for (let i = 0; i < files.length; i += 1) {
          if (files[i].size > maxFileSize) {
            setErrorState(`${files[i].name} is too big`);
            isBig = true;
            break;
          }
        }

        if (!isBig) {
          setFileState(files);
          setFileNameState(`${files.length} files selected`);
          onChange(files);
        }
      }
    }
  };

  return {
    hookFile: fileState,
    hookSetFile: handleChange,
    hookFileName: fileNameState,
    hookError: errorState,
  };
};
