import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFile } from '@fortawesome/free-solid-svg-icons';

import { ERROR_ICON, TABLE_FOOTER, dateFormatter, formateDollarAmount, monthNames } from '../../../../utils';
import * as Styled from '../styles';

export const columns: TableColumn[] = [
  {
    dataField: 'productCategory',
    text: 'category',
    width: '17%',
    formatter: (val: any) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'systemUserRate',
    text: 'System User Rate',
    width: '23%',
    formatter: (val: string[]) => (
      <Styled.TableDataStyled theme="secondary">{val?.map((v: string) => <p key={v}>{v}</p>)}</Styled.TableDataStyled>
    ),
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'minUsageFee',
    text: 'min Usage Fee',
    width: '15%',
    formatter: (val: any) => <Styled.TableDataStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'minBalanceRequired',
    text: 'min balance',
    width: '15%',
    formatter: (val: any) => <Styled.TableDataStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'salesTaxRate',
    text: 'sales Tax',
    width: '15%',
    formatter: (val: any) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'managementFee',
    text: 'management Fee',
    width: '15%',
    formatter: (val: any) => <Styled.TableDataStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
];

export const balanceStatsColumn: TableColumn[] = [
  {
    dataField: 'adminRate',
    text: 'System User Rate',
    width: '20%',
    formatter: (val: string[]) => <Styled.TableDataStyled>{val ? `${val}%` : ''}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'minUsageFee',
    text: 'min Usage Fee',
    width: '20%',
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        <div>
          {formateDollarAmount(parseFloat(val))}
          <p>
            {row.validStartDate && dateFormatter(new Date(row.validStartDate))} -{' '}
            {row.validUntilDate && dateFormatter(new Date(row.validUntilDate))}
          </p>
        </div>
      </Styled.TableDataStyled>
    ),
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'managementFee',
    text: 'management Fee',
    width: '20%',
    formatter: (val: any) => <Styled.TableDataStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'minBalanceRequired',
    text: 'min balance',
    width: '20%',
    formatter: (val: any) => <Styled.TableDataStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'salesTaxRate',
    text: 'sales Tax',
    width: '20%',
    formatter: (val: any) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
];

export const monthlyAccountenableNewStatsColumns = [
  {
    dataField: 'year',
    text: 'period',
    width: '130px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        {row.minBalanceRequired > row.balance && <FontAwesomeIcon icon={ERROR_ICON} />}
        {`${monthNames[parseInt(row.month, 10) - 1]} / ${val}`}
      </Styled.TableDataStyled>
    ),
    footerFormatter: () => <Styled.TableFooterStyled>{TABLE_FOOTER}</Styled.TableFooterStyled>,
  },
  {
    dataField: 'productCategory',
    text: 'product Category',
    width: '130px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => <Styled.TableDataMothlyStyled>{val}</Styled.TableDataMothlyStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'rollover',
    text: 'rollover',
    width: '140px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.rollover))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'deposits',
    text: 'deposits',
    width: '130px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.deposits))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalCommissions',
    text: 'commissions',
    width: '130px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.commissions))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'manualAdjustment',
    text: 'manual adjustments',
    width: '162px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.manualAdjustments))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'systemUsageFee',
    text: 'system Usage Fee',
    width: '150px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.systemFee))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'otherFee',
    text: 'other fees',
    width: '190px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.otherFees))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalTax',
    text: 'taxes',
    width: '190px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.taxes))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'balance',
    text: 'balance',
    width: '160px',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.balance))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'finalized',
    text: 'Statement',
    width: '100px',
    notClickable: true,
    formatter: (val: any, row: any, _: any, __: any, ___: any, ____: any, detailsClick: any, handleClick: any) => (
      <Styled.TableDataMothlyStyled theme="secondary">
        {val ? (
          <Styled.TableCellLink onClick={() => handleClick(row)}>
            <FontAwesomeIcon icon={faFile} size="lg" />
          </Styled.TableCellLink>
        ) : (
          <div />
        )}
        <Styled.TableCellLink onClick={() => detailsClick(row)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Styled.TableCellLink>
      </Styled.TableDataMothlyStyled>
    ),
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
];

export const monthlyAccountColumns = [
  {
    dataField: 'year',
    text: 'period',
    width: '12%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any, row: any) => (
      <Styled.TableDataMothlyStyled>
        {row.minBalanceRequired > row.balance && <FontAwesomeIcon icon={ERROR_ICON} />}
        {`${monthNames[parseInt(row.month, 10) - 1]} / ${val}`}
      </Styled.TableDataMothlyStyled>
    ),
    footerFormatter: () => <Styled.TableFooterStyled>{TABLE_FOOTER}</Styled.TableFooterStyled>,
  },
  {
    dataField: 'merchant',
    text: 'merchant',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => <Styled.TableDataMothlyStyled>{val}</Styled.TableDataMothlyStyled>,
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
  {
    dataField: 'rollover',
    text: 'rollover',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.rollover))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'deposits',
    text: 'deposits',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.deposits))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalCommissions',
    text: 'commissions',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.commissions))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'manualAdjustment',
    text: 'manual adjustments',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>
        {formateDollarAmount(parseFloat(footerData?.manualAdjustments))}
      </Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'systemUsageFee',
    text: 'system Usage Fee',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.systemFee))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'otherFee',
    text: 'other fees',
    width: '12%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.otherFees))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'totalTax',
    text: 'taxes',
    width: '12%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.taxes))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'balance',
    text: 'balance',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val: any) => (
      <Styled.TableDataMothlyStyled>{formateDollarAmount(parseFloat(val))}</Styled.TableDataMothlyStyled>
    ),
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) => (
      <Styled.TableFooterStyled>{formateDollarAmount(parseFloat(footerData?.balance))}</Styled.TableFooterStyled>
    ),
  },
  {
    dataField: 'finalized',
    text: 'Statement',
    width: '150px',
    notClickable: true,
    formatter: (val: any, row: any, _: any, __: any, ___: any, ____: any, detailsClick: any, handleClick: any) => (
      <Styled.TableDataMothlyStyled theme="secondary">
        {val ? (
          <Styled.TableCellLink onClick={() => handleClick(row)}>
            <FontAwesomeIcon icon={faFile} size="lg" />
          </Styled.TableCellLink>
        ) : (
          <div />
        )}
        <Styled.TableCellLink onClick={() => detailsClick(row)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Styled.TableCellLink>
      </Styled.TableDataMothlyStyled>
    ),
    footerFormatter: () => <Styled.TableFooterStyled />,
  },
];
