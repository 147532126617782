import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppWrapper, Checkbox, InputText, PageTitle, Select, Tooltip } from '../../../components';
import { AUDIENCE_SIZE, path } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import {
  AUDIENCE_AGE,
  AUDIENCE_MARKET_OPTIONS,
  AVERAGE_INCOME,
  BUTTON_LABEL,
  CREDIT_SCORE_RATING,
  GENDER_OPTIONS,
  INPUT_LABEL,
  PAGE_DESCRIPTION,
  PLACEHOLDER,
  PRODUCT_CATEGORIES,
  PROMOTION_METHOD,
  SUBTITLE,
  SUBTITLE_DESCRIPTION,
  TOOLTIP,
} from './contracts';
import { usePublisherOverview } from './hook';
import * as Styled from './styles';

type PublisherOverviewProps = DefaultPropsType;

const PublisherOverview = ({ permissionsCodeList = [] }: PublisherOverviewProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = usePublisherOverview(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.FlexStyled justify="space-between">
        <PageTitle isLoading={hook.hookLoading}>{path.overview.name}</PageTitle>
        <Styled.ButtonsWrapperStyled>
          <Styled.ButtonStyled onClick={() => navigate(-1)} theme="secondary">
            {BUTTON_LABEL.CANCEL}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled
            onClick={hook.hookUpdatePublisher}
            loading={hook.hookUpdateLoading}
            disabled={isReadOnly || hook.hookUpdateLoading}
          >
            {BUTTON_LABEL.SAVE}
          </Styled.ButtonStyled>
        </Styled.ButtonsWrapperStyled>
      </Styled.FlexStyled>
      <Styled.DescriptionStyled>{PAGE_DESCRIPTION}</Styled.DescriptionStyled>

      <Styled.GridStyled columns={3} rowGap={40}>
        <Styled.TextareaStyled
          disabled={isReadOnly || hook.hookUpdateLoading}
          required
          label={INPUT_LABEL.DESCRIPTION}
          value={hook.hookPublisherDescription}
          onChange={hook.hookSetPublisherDescription}
          placeholder={PLACEHOLDER.DESCRIPTION}
          onBlur={() => hook.hookValidateFields()}
          error={hook.hookPublisherErrors.publisherDescription}
          maxLength={1500}
        />
        <Styled.DropZoneStyled
          disabled={isReadOnly || hook.hookUpdateLoading}
          tooltip={TOOLTIP.PROFILE_PHOTO}
          name="profilePhoto"
          label={INPUT_LABEL.PROFILE_PHOTO}
          onChange={hook.hookUploadImage}
          maxFileSize={3000000}
          previewUrl={hook.hookPhotoUrl}
          accept=".png, .jpg, .jpeg, .gif"
          type="ProfileImage"
        />
        <InputText
          type="text"
          tooltip={TOOLTIP.PLATFORM_LINK}
          label={INPUT_LABEL.PLATFORM_LINK}
          value={hook.hookPlatformLink}
          onChange={hook.hookSetPlatformLink}
          error={hook.hookHasWebsite ? hook.hookPublisherErrors.website || hook.hookUrlError : ''}
          disabled={!hook.hookHasWebsite || isReadOnly || hook.hookUpdateLoading}
          placeholder={PLACEHOLDER.PLATFORM_LINK}
          onBlur={() => {
            hook.hookValidateWebsite();
            hook.hookValidateFields();
          }}
          required
        />
        <Styled.CheckboxStyled
          label={INPUT_LABEL.HAS_WEBSITE}
          onChange={hook.hookSetHasWebsite}
          checked={!hook.hookHasWebsite}
          disabled={isReadOnly || hook.hookUpdateLoading}
        />
      </Styled.GridStyled>

      <Styled.FlexStyled justify="space-between" marginTop={4}>
        <Styled.FlexStyled column>
          <Styled.FlexStyled align="center">
            <Styled.GridTitleStyled>
              {INPUT_LABEL.AUDIENCE_MARKET} <Styled.RequiredStyled>*</Styled.RequiredStyled>{' '}
              <Tooltip text={TOOLTIP.AUDIENCE_MARKET} />
            </Styled.GridTitleStyled>
          </Styled.FlexStyled>
          <Styled.GridStyled columns={2}>
            {AUDIENCE_MARKET_OPTIONS.map((option) => (
              <Checkbox
                key={option}
                label={option}
                disabled={isReadOnly || hook.hookUpdateLoading}
                onChange={(checked) => hook.hookSetAudienceMarkets(checked, option)}
                checked={hook.hookAudienceMarkets.includes(option)}
              />
            ))}
          </Styled.GridStyled>
          <Styled.ErrorStyled>{hook.hookPublisherArrorErrors.audienceMarkets}</Styled.ErrorStyled>
        </Styled.FlexStyled>

        <Select
          width="47%"
          required
          isDisabled={isReadOnly || hook.hookUpdateLoading}
          label={INPUT_LABEL.MONTHLY_AUDIENCE_SIZE}
          options={AUDIENCE_SIZE.slice(1)}
          selected={hook.hookAvgMonthlyAudience}
          onChange={hook.hookSetAvgMonthlyAudience}
          tooltip={TOOLTIP.MONTHLY_AUDIENCE_SIZE}
          error={hook.hookPublisherErrors.avgMonthlyAudience}
        />
      </Styled.FlexStyled>

      <Styled.FlexStyled marginTop={4} column>
        <Styled.GridTitleStyled>
          {INPUT_LABEL.PRODUCT_CATEGORIES} <Styled.RequiredStyled>*</Styled.RequiredStyled>{' '}
          <Tooltip text={TOOLTIP.PRODUCT_CATEGORIES} />
        </Styled.GridTitleStyled>
        <Styled.GridStyled columns={3}>
          {PRODUCT_CATEGORIES.map((option) => (
            <Checkbox
              disabled={isReadOnly || hook.hookUpdateLoading}
              key={option.value}
              label={option.label}
              onChange={(checked) => hook.hookSetProductCategories(checked, option.value)}
              checked={hook.hookProductCategories.includes(option.value)}
            />
          ))}
        </Styled.GridStyled>
        <Styled.ErrorStyled>{hook.hookPublisherArrorErrors.productCategories}</Styled.ErrorStyled>
      </Styled.FlexStyled>

      <Styled.FlexStyled marginTop={4} column>
        <Styled.GridTitleStyled>
          {INPUT_LABEL.PROMOTION} <Styled.RequiredStyled>*</Styled.RequiredStyled> <Tooltip text={TOOLTIP.PROMOTION} />
        </Styled.GridTitleStyled>
        <Styled.GridStyled columns={3}>
          {PROMOTION_METHOD.map((option) => (
            <Checkbox
              disabled={isReadOnly || hook.hookUpdateLoading}
              key={option.value}
              label={option.label}
              onChange={(checked) => hook.hookSetPromotionMethods(checked, option.value)}
              checked={hook.hookPromotionMethods.includes(option.value)}
            />
          ))}
        </Styled.GridStyled>
        <Styled.ErrorStyled>{hook.hookPublisherArrorErrors.promotionMethods}</Styled.ErrorStyled>
      </Styled.FlexStyled>

      <Styled.DemographicStyled>
        <Styled.DemographicTitleStyled>{SUBTITLE.DEMOGRAPHICS}</Styled.DemographicTitleStyled>
        <Styled.DemographicDescriprionStyled>{SUBTITLE_DESCRIPTION.DEMOGRAPHICS}</Styled.DemographicDescriprionStyled>

        <Styled.FlexStyled marginTop={4} column>
          <Styled.GridTitleStyled>{INPUT_LABEL.AUDIENCE_AGE}</Styled.GridTitleStyled>
          <Styled.GridStyled columns={3}>
            {AUDIENCE_AGE.map((option) => (
              <Checkbox
                disabled={isReadOnly || hook.hookUpdateLoading}
                key={option}
                label={option}
                onChange={(checked) => hook.hookSetAudienceAges(checked, option)}
                checked={hook.hookAudienceAges.includes(option)}
              />
            ))}
          </Styled.GridStyled>
        </Styled.FlexStyled>

        <Styled.FlexStyledDropDowns gap={3} marginTop={4}>
          <Select
            isDisabled={isReadOnly || hook.hookUpdateLoading}
            label={INPUT_LABEL.GENDER}
            options={GENDER_OPTIONS}
            selected={hook.hookGender}
            onChange={hook.hookSetGender}
          />
          <Select
            isDisabled={isReadOnly || hook.hookUpdateLoading}
            label={INPUT_LABEL.CREDIT_SCORE_RATING}
            options={CREDIT_SCORE_RATING}
            selected={hook.hookCreditScoreRating}
            onChange={hook.hookSetCreditScoreRating}
          />
        </Styled.FlexStyledDropDowns>

        <Styled.FlexStyled marginTop={4} column>
          <Styled.GridTitleStyled>{INPUT_LABEL.AVERAGE_INCOME}</Styled.GridTitleStyled>
          <Styled.GridStyled columns={3}>
            {AVERAGE_INCOME.map((option) => (
              <Checkbox
                disabled={isReadOnly || hook.hookUpdateLoading}
                key={option.value}
                label={option.label}
                onChange={(checked) => hook.hookSetAvgIncomes(checked, option.value)}
                checked={hook.hookAvgIncomes.includes(option.value)}
              />
            ))}
          </Styled.GridStyled>
        </Styled.FlexStyled>
      </Styled.DemographicStyled>
    </AppWrapper>
  );
};

export default PublisherOverview;
