import React from 'react';

import * as Styled from './styles';

type ToggleProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  handleClick?: (value: boolean) => void;
};

export const Toggle = ({ checked, onChange, disabled = false, className, handleClick }: ToggleProps) => (
  <Styled.WrapperStyled className={className}>
    <Styled.BodyStyled checked={checked} disabled={disabled}>
      <Styled.BallStyled checked={checked} />
    </Styled.BodyStyled>
    <input
      type="checkbox"
      checked={checked}
      onChange={() => onChange(!checked)}
      disabled={disabled}
      onClick={() => handleClick && handleClick(true)}
    />
  </Styled.WrapperStyled>
);
