import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query Notifications($limit: Int) {
    notifications(limit: $limit) {
      numberOfUnread
      notifications {
        id
        title
        description
        fromCompany {
          companyName
        }
        toCompany {
          companyName
        }
        toUserId
        read
        createdAt
      }
    }
  }
`;

export type GetNotificationsInput = {
  limit?: number;
};

export type GNNotificationType = {
  id: string;
  title: string | null;
  description: string | null;
  fromCompany: {
    companyName: string;
  } | null;
  toCompany: {
    companyName: string;
  } | null;
  toUserId: string | null;
  read: boolean | null;
  createdAt: string | null;
};
export type GetNotificationsOutput = {
  notifications: {
    numberOfUnread: number | null;
    notifications: GNNotificationType[] | null;
  };
};
