import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-start;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

export const DescriptionStyled = styled.p`
  ${fonts.gotham.H14M}
  color: ${colors.color1};
`;
