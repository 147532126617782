import styled from 'styled-components';

import { Button } from '../../components';
import { colors, fonts } from '../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 390px calc(100% - 390px);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color1};
`;

export const RightStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 140px 60px;
  position: relative;
`;

export const RightContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const TitleStyled = styled.h1`
  ${fonts.gotham.H32M}
  color: ${colors.color1};
  margin-bottom: 80px;
`;

export const DescriptionWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H20}
  color: ${colors.color17};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 16px;
`;

export const ButtonStyled = styled(Button)`
  width: 50%;
  padding: 1rem;
`;

export const LinkStyled = styled.a`
  color: ${colors.color2};
`;
