import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors, fonts } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
`;

export const ErrorStyled = styled.div`
  width: 100%;
  color: ${colors.color14};
  border: 2px solid ${colors.color14};
  border-radius: 3px;
  padding: 0.8rem;
  margin-bottom: 1rem;
  ${fonts.lato.H16}
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;
