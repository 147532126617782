import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';

import { LoadingDots, Pagination, Placeholder, Table } from 'components';

import { INTERNAL_PUBLISHER } from '../../enums';

import * as Styled from './styles';
import { TRACKING_DOMAINS } from './enums';
import { columns } from './contracts';

type TrackingDomainsTabProps = {
  modalHook: () => void;
  isOpen: boolean;
  hook: any;
  isReadOnly: boolean;
};

const TrackingDomainsTab = ({ modalHook, isOpen, hook, isReadOnly }: TrackingDomainsTabProps): JSX.Element => {
  const [, setOpenState] = useState(false);

  useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen]);

  return (
    <Styled.WrapperStyled>
      <Styled.TextStyled>{TRACKING_DOMAINS.DESCRIPTION}</Styled.TextStyled>

      {hook.hookLoading && <LoadingDots />}

      {hook.hookTrackingDomainsData.length > 0 && !hook.hookLoading && (
        <>
          <Table
            columns={columns}
            data={hook.hookTrackingDomainsData}
            isLoading={hook.hookLoading}
            onSort={hook.hookSetSortBy}
            sortColumn={hook.hookSortBy}
          />
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookSetCurrentPage}
          />
        </>
      )}

      {!hook.hookLoading && hook.hookTrackingDomainsData.length === 0 && (
        <Placeholder
          title={INTERNAL_PUBLISHER.NO_DOMAINS}
          subtitle={INTERNAL_PUBLISHER.NO_DOMAINS_DESCRIPTION}
          action={modalHook}
          buttonIcon={faPlus}
          buttonText={INTERNAL_PUBLISHER.ADD_PROFILE}
          isReadOnly={isReadOnly}
        />
      )}
    </Styled.WrapperStyled>
  );
};

export default TrackingDomainsTab;
