import styled from 'styled-components';

import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';
import { Spinner } from '../../Spinner';
import { Tooltip } from '../../Tooltip';

type InputContainerProps = {
  readonly width: string;
  readonly isNotAdmin?: boolean;
};

export const SelectStyles = (error?: string, optionBorder?: boolean) => {
  const styles = {
    borderRadius: 2,
    option: (_: any, { isFocused }: any) => ({
      color: colors.color1,
      backgroundColor: isFocused && colors.color6,
      padding: 8,
      cursor: 'pointer',
      fontFamily: 'latoregular',
      fontSize: 14,
      borderBottom: optionBorder ? `1px solid ${colors.color28}` : 'none',
    }),
    placeholder: () => ({
      color: colors.color1,
      opacity: 0.4,
      fontSize: 14,
      gridArea: '1/1/2/3',
      marginLeft: 4,
      fontFamily: 'latoregular',
    }),
    control: (_: any, { isDisabled }: any) => ({
      '&:hover': { borderColor: colors.color1 },
      border: `1px solid ${error ? colors.color14 : colors.color3}`,
      boxShadow: 'none',
      display: 'flex',
      backgroundColor: isDisabled ? colors.color11 : colors.color4,
      minHeight: 40,
      cursor: 'pointer',
      borderRadius: 3,
      fontFamily: 'latoregular',
      fontSize: 14,
    }),
    multiValue: (baseStyles: any) => ({
      ...baseStyles,
      div: {
        fontSize: 14,
      },
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  return styles;
};

export const WrapperStyled = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => width};
`;

export const LabelStyled = styled.label`
  display: flex;
  flex-direction: column;
`;

export const LabelTextStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const ErrorStyled = styled.span`
  margin-top: 0.3rem;
  color: ${colors.color14};
  ${fonts.lato.H14}
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;

export const SpinnerStyled = styled(Spinner)`
  margin-left: 0.5rem;
`;

export const DescriptionStyled = styled.span`
  color: ${colors.color13};
  ${fonts.lato.H14}
  margin-bottom: 0.5rem;
  white-space: nowrap;
`;
