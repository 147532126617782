import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GET_OVERVIEW } from '../graphql/queries';
import { useUserInfo } from '../../../../../../../hooks';
import { colors } from '../../../../../../../styles/theme';

export const useGraphs = () => {
  const { hookWhoAmI } = useUserInfo();
  const [publisherData, setPublisherData] = useState<any[]>([]);
  const [ruleData, setRuleData] = useState<any[]>([]);
  const [ruleDetailData, setRuleDetailData] = useState<any>();

  const [getOverview, { loading }] = useLazyQuery(GET_OVERVIEW);

  const getOverviewHandler = async () => {
    const { data } = await getOverview({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data.fintelChecksOverview !== undefined) {
      const lastDateCheckTotal =
        data.fintelChecksOverview.publisherCheckPass +
        data.fintelChecksOverview.publisherCheckFail +
        data.fintelChecksOverview.publisherCheckReview +
        data.fintelChecksOverview.publisherCheckIncomplete +
        data.fintelChecksOverview.publisherCheckNf;
      const prevDateCheckTotal =
        data.fintelChecksOverview.prevPublisherCheckPass +
        data.fintelChecksOverview.prevPublisherCheckFail +
        data.fintelChecksOverview.prevPublisherCheckReview +
        data.fintelChecksOverview.prevPublisherCheckIncomplete +
        data.fintelChecksOverview.prevPublisherCheckNf;

      setPublisherData([
        {
          date: data.fintelChecksOverview.prevCheckDate,
          checkPassVal: data.fintelChecksOverview.prevPublisherCheckPass,
          checkFailVal: data.fintelChecksOverview.prevPublisherCheckFail,
          checkReviewVal: data.fintelChecksOverview.prevPublisherCheckReview,
          checkIncompleteVal: data.fintelChecksOverview.prevPublisherCheckIncomplete,
          checkNotApplicableVal: data.fintelChecksOverview.prevPublisherCheckNf,
          checkPassPerc: (data.fintelChecksOverview.prevPublisherCheckPass / prevDateCheckTotal) * 100,
          checkFailPerc: (data.fintelChecksOverview.prevPublisherCheckFail / prevDateCheckTotal) * 100,
          checkReviewPerc: (data.fintelChecksOverview.prevPublisherCheckReview / prevDateCheckTotal) * 100,
          checkIncompletePerc: (data.fintelChecksOverview.prevPublisherCheckIncomplete / prevDateCheckTotal) * 100,
          checkNotApplicablePerc: (data.fintelChecksOverview.prevPublisherCheckNf / prevDateCheckTotal) * 100,
        },
        {
          date: data.fintelChecksOverview.lastCheckDate,
          checkPassVal: data.fintelChecksOverview.publisherCheckPass,
          checkFailVal: data.fintelChecksOverview.publisherCheckFail,
          checkReviewVal: data.fintelChecksOverview.publisherCheckReview,
          checkIncompleteVal: data.fintelChecksOverview.publisherCheckIncomplete,
          checkNotApplicableVal: data.fintelChecksOverview.publisherCheckNf,
          checkPassPerc: (data.fintelChecksOverview.publisherCheckPass / lastDateCheckTotal) * 100,
          checkFailPerc: (data.fintelChecksOverview.publisherCheckFail / lastDateCheckTotal) * 100,
          checkReviewPerc: (data.fintelChecksOverview.publisherCheckReview / lastDateCheckTotal) * 100,
          checkIncompletePerc: (data.fintelChecksOverview.publisherCheckIncomplete / lastDateCheckTotal) * 100,
          checkNotApplicablePerc: (data.fintelChecksOverview.publisherCheckNf / lastDateCheckTotal) * 100,
        },
      ]);

      // Add Colors to the data
      const formattedRuleDetails = {
        pass: data.fintelChecksOverview.ruleCheckDetails.pass.map((value: any) => ({
          ...value,
          color: colors.color37,
        })),
        review: data.fintelChecksOverview.ruleCheckDetails.review.map((value: any) => ({
          ...value,
          color: colors.color38,
        })),
        fail: data.fintelChecksOverview.ruleCheckDetails.fail.map((value: any) => ({
          ...value,
          color: colors.color14,
        })),
        nf: data.fintelChecksOverview.ruleCheckDetails.nf.map((value: any) => ({
          ...value,
          color: colors.color39,
        })),
        incomplete: data.fintelChecksOverview.ruleCheckDetails.incomplete.map((value: any) => ({
          ...value,
          color: colors.color12,
        })),
      };

      setRuleData([
        { value: data.fintelChecksOverview.ruleCheckPass, category: 'Check Pass', subData: formattedRuleDetails.pass },
        {
          value: data.fintelChecksOverview.ruleCheckReview,
          category: 'Check Review',
          subData: formattedRuleDetails.review,
        },
        { value: data.fintelChecksOverview.ruleCheckFail, category: 'Check Fail', subData: formattedRuleDetails.fail },
        {
          value: data.fintelChecksOverview.ruleCheckNf,
          category: 'Check Not Applicable',
          subData: formattedRuleDetails.nf,
        },
        {
          value: data.fintelChecksOverview.ruleCheckIncomplete,
          category: 'Check Incomplete',
          subData: formattedRuleDetails.incomplete,
        },
      ]);

      setRuleDetailData(formattedRuleDetails);
    } else {
      setPublisherData([]);
      setRuleData([]);
      setRuleDetailData([]);
    }
  };

  useEffect(() => {
    getOverviewHandler();
  }, []);

  return {
    hookLoadingOverview: loading,
    hookPublisherData: publisherData,
    hookRuleData: ruleData,
    hookRuleDetailData: ruleDetailData,
  };
};
