import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { fonts, colors } from '../../../../../../../styles/theme';
import { toRem } from '../../../../../../../utils';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: ${toRem(500)};
  align-items: center;
  margin: ${toRem(32)};
`;

export const SingelLine = styled.div`
  padding: 0 ${toRem(32)};
  min-width: ${toRem(500)};
  ${fonts.lato.H16};
  color: ${colors.color1};
`;

export const Total = styled.div`
  min-width: ${toRem(500)};
  display: flex;
  justify-content: space-between;
  color: ${colors.color2};
  margin: ${toRem(16)} ${toRem(32)};
`;

export const WordWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyParagraphStyled = styled.span`
  ${fonts.lato.H16};
  color: ${colors.color1};
  line-height: 1.5;
  max-width: ${toRem(491)};
`;

export const BodyWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${colors.color1};
  align-items: center;
  padding: ${toRem(16)};
  margin: ${toRem(32)};
  border-radius: ${toRem(4)};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: ${toRem(16)};
  font-size: 20px;
  color: ${colors.color1};
`;

export const MiddleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: ${toRem(500)};
`;

export const PaypalDesc = styled.p`
  margin-top: ${toRem(-5)};
  font-weight: 400;
  font-size: ${toRem(12)};
  margin-bottom: ${toRem(32)};
`;
