import React from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '../../../components';
import { EMAIL_RESENT_MESSAGE } from '../enums';

import * as Styled from './styles';

type VerificationEmailSentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  email: string;
};

export const VerificationEmailSentModal = ({ isOpen, onClose, email }: VerificationEmailSentModalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.WrapperStyled>
      <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 55, color: '#65c57d' }} />

      <h1>{EMAIL_RESENT_MESSAGE.TITLE}</h1>
      <span>
        {EMAIL_RESENT_MESSAGE.PART1} <a href={`mailto:${email}`}>{email}</a>
        {EMAIL_RESENT_MESSAGE.PART2}
      </span>

      <Styled.ButtonStyled onClick={onClose} width="150px">
        Close
      </Styled.ButtonStyled>
    </Styled.WrapperStyled>
  </Modal>
);
