import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($input: UserSimpleInput!) {
    createUser(input: $input) {
      user {
        id
        email
        auth0Id
      }
    }
  }
`;
