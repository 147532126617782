import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fonts, colors } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';
import { Button, PageTitle } from '../../../../../../components';

export const WrapperStyled = styled.div`
  padding: ${toRem(36)};
`;

export const PageTitleStyled = styled(PageTitle)`
  line-height: 2.5;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: ${toRem(16)};
`;

export const TextStyled = styled.p`
  margin: ${toRem(24)} 0;
  ${fonts.lato.H16}
  line-height: 1.5;
`;

export const HeaderWrapperStyled = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const CheckWrapperStyled = styled.span`
  ${fonts.lato.H16}
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 30%;
`;

export const BodyWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${colors.color1};
  align-items: center;
  padding: ${toRem(16)};
  margin-bottom: ${toRem(16)};
  border-radius: ${toRem(4)};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: ${toRem(16)};
  font-size: 20px;
  color: ${colors.color1};
`;

export const WordWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyTitleStyled = styled.span`
  ${fonts.lato.H16B};
  color: ${colors.color1};
  margin-bottom: ${toRem(6)};
`;

export const BodyParagraphStyled = styled.span`
  ${fonts.lato.H16};
  color: ${colors.color1};
  line-height: 1.5;
  max-width: ${toRem(491)};
`;
