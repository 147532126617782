export const REQUIRED_TEXT = 'Oops! Something went wrong. Please choose at least one group or publisher.';
export const PAGE_TITLE = (subType: string, type: string) => `Add ${subType} ${type} Commission`;
export const COMMISSION_STRUCTURE = 'Commission Structure';
export const REV_SHARE = 'RevShare';
export const LEFT = 'left';
export const RIGHT = 'right';

export const COMMISSION_DEFAULT_CUSTOM = {
  DEFAULT: 'Default',
  CUSTOM: 'Custom',
};

export const PAGE_DESCRIPTION: { [key: string]: string } = {
  CPA: 'Use this screen to set Commissions for Cost per Action.',
  CPA_TWO: 'Commissions are paid out on the date the transaction is approved.',
  REFERRAL: 'Use this screen to set Referrals for multiple levels of commissions.',
  REV_SHARE: 'Use this screen to share a portion of revenues with publishers.',
  BONUS: 'Use this screen to reward high quality traffic publishers with end of month bonuses.',
};

export const PUBLISHER_GROUPS = {
  TITLE: 'Publishers and Groups',
  DESCRIPTION_ONE: 'By default this commission applies to all Member Publishers.',
  DESCRIPTION_TWO: 'To make this commission apply to select Publishers and groups only, select them below.',
};

export const COMMISSION_LABELS = {
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  PRODUCT: 'Product',
  COMMISSION_NAME: 'Commission Name',
  COMMISSION_DESCRIPTION: 'Commission Description (140 Chars Max)',
  COMMISSION_BASE: 'Commission Base',
  COMMISSION_AMOUNT: 'Commission Amount ($)',
  FLAT_COMMISSION: 'Flat % Commission',
  FLAT_BONUS: 'Flat Bonus ($)',
  TRAILING_PERIOD: 'Trailing Period in Months',
  MIN_TO_GET: (value?: string) => `Minimum ${value || value === '' ? value : 'Amount'} To Get Commission`,
  NUM_TRANSACTIONS: (level?: number, bonus?: boolean) =>
    `Level ${level} ${bonus ? 'Bonus ($) Per ' : ''}Number of Transactions`,
  PER_TRANSACTION: (level?: number) => `Level ${level} Commission ($) per Transaction`,
  REFERRAL: (level?: number) => `Level ${level} Referral %`,
  REVENUE_SHARE: (level?: number) => `Revenue Share Level ${level} up to $ Amount`,
  MIN_TRANSACTIONS: (level?: number) => `Minimum Transaction for Bonus Level ${level}`,
  COMMISSION_PERCENT: (level?: number) => `Level ${level} Commission %`,
  AFTER: (level?: number, percent?: boolean) =>
    `All subsequent transactions earn ${percent ? '%' : 'commission ($)'} per transaction`,
};

export const PLACEHOLDERS = {
  SEARCH: 'Search by Publisher or Publisher Group Name/ID',
  SELECT_DATE: 'Select date',
};

export const BUTTON_TEXT = {
  ADD_COMMISSION: 'Add Commission',
  ADD_TIER: 'Add Tier',
  CANCEL: 'Cancel',
  CLEAR_DATE: 'Clear Dates',
};

export const RADIO_OPTIONS = ['Use Flat Commission', 'Use Tiered Commission'];

export const BONUS_RADIO_OPTIONS = [
  'Use Flat Commission',
  'Use Tiers, payout Stepped Bonus matching bonuses to their respective tiers',
  'Use Tiers, payout Lookup Bonus to payout all transactions based on top tier reached',
  'Use Tiers, payout Lookback Bonus (Stepped) to payout transactions within a given trailing time period',
  'Use Tiers, payout Lookback Bonus (Lookup) to payout transactions within a given trailing time period',
];

export const AMOUNT_ERROR_MESSAGE = 'Please enter an amount';

export const BONUS_CALCULATION_TYPES = ['', 'Stepped', 'Lookup', 'Stepped', 'Lookup'];
