export const PENDING_PAYMENTS = {
  EMPTY_LABEL: '',
  PUBLISHER_LABEL: 'Publisher Account Id',
  PAYMENT_METHOD_LABEL: 'Payment Method',
  PAYMENT_PERIOD: 'Payment Period',
  PAYMENT_STATUS_LABEL: 'Payment Status',
  PAYMENT_CURRENCY: 'Payment Currency',
  PAYMENT_ID_PLACEHOLDER: 'Search payment id',
  FILTER_THEME: 'tertiary',
  DOWNLOAD: 'Download CSV',
  GENERATE: 'Generate Report',
  RECORD: 'Records',
  APPROVE: 'Approve Payments',
  PAY: 'Pay Payments',
  CLEAR: 'Clear Form',
  CLICK: 'Click generate reports to get started',
  NOT_FOUND: 'No reports to show!',
};

export const FOOTER_VALUES = {
  ROLLOVER: '$234243.45',
  TOTAL_COMMISSION: '$234.46',
  BONUS_AMOUNT: '$53252.78',
  BONUS_TAX: '$2435.78',
};

export const PROCESS_PAYMENTS_TEXT = {
  HEADER_DESC_PENDING_1: 'Pending payments can only be paid after being approved.',
  HEADER_DESC_PENDING_2: 'Please ensure all pending payments have been reviewed for approval before paying.',
  TAB_NAME_PENDING_PAYMENTS: 'Pending Payments',
  TAB_NAME_PAYPAL_ORDER: 'PayPal Order',
};
export const AMOUNTS = [
  'currentRollover',
  'totalCommissions',
  'bonus',
  'bonusTax',
  'totalTaxes',
  'totalPayable',
  'periodBalance',
];

export const PENDING_PAYMENT = 'Pending Payment';

export const PENDING_APPROVAL = 'Pending Approval';

export const CAN_CHECKED = [PENDING_APPROVAL, PENDING_PAYMENT];

export const NO_MONEY = 0.0;
