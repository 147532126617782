import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';
import { toRem } from '../../utils';

type WrapperStyledProps = {
  readonly theme?: string;
};

export const WrapperStyled = styled.div<WrapperStyledProps>`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 4px;

  color: ${({ theme }) => {
    switch (theme) {
      case 'ERROR':
      case 'ERROR_SECONDARY':
        return colors.color31;
      default:
        return colors.color1;
    }
  }};

  border: ${({ theme }) => {
    switch (theme) {
      case 'ERROR':
      case 'ERROR_SECONDARY':
        return `2px solid ${colors.color31}`;
      default:
        return `1px solid ${colors.color1}`;
    }
  }};

  margin-bottom: ${({ theme }) => {
    switch (theme) {
      case 'ERROR':
        return toRem(40);
      case 'ERROR_SECONDARY':
        return 0;
      case 'secondary':
        return toRem(40);
      default:
        return 0;
    }
  }};
`;

export const LeftSideStyled = styled.div`
  margin-right: 1rem;
  font-size: 1.2rem;
`;

export const RightSideStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleStyled = styled.div`
  ${fonts.lato.H16B}
  color: ${colors.color1};
  margin-bottom: 0.5rem;
`;

export const ContentStyled = styled.div`
  ${fonts.lato.H16}
  margin-bottom: 5px;
`;
