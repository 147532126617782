import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { useModal, useToast, useUserInfo } from '../../../../hooks';
import {
  csvGenerator,
  RangeFormat,
  rangeFormat,
  RangeOptions,
  RECORDS_PER_PAGE_OPTIONS,
  toUTCHours,
  USER_TYPES_ID,
} from '../../../../utils';
import {
  LIST_MERCHANTS,
  ListMerchantInputType,
  ListMerchantOutputType,
  SEARCH_PERFORMANCE,
  SearchPerformanceInputType,
  SearchPerformanceOutputType,
  GET_MERCHANTS_FOR_REPORTS,
  GetMerchantsForReportsInputType,
  GetMerchantsForReportsOutputType,
  LIST_SAVED_REPORTS,
  LSRInputType,
  LSROutputType,
  LSRReportType,
  SHOW_MERCHANT_INFO,
  ShowMerchantInputType,
  ShowMerchantOutputType,
  ShowMerchantCompaniesType,
  ShowMerchantTrackingsType,
  ShowMerchantProductsType,
  ShowMerchantMembershipsType,
  ShowMerchantCampaignsType,
  ShowMerchantPublisherGroupsType,
  ShowMerchantAdsType,
} from '../graphql/queries';
import { SAVE_PERFORMANCE_REPORT, SPRInputType, SPROutputType } from '../graphql/mutations';
import { compareValues, getColumnsByGroupBy, processDataToCSVString, processTableData } from '../utils';
import {
  defaultAdCampaignOption,
  defaultAdsOption,
  defaultMerchantOption,
  defaultProductCategoryOption,
  defaultProductOption,
  defaultPublisherGroupOption,
  defaultPublisherOption,
  defaultTrackingsOption,
  GROUP_BY_OPTIONS,
  immutableProductCategories,
  immutableGlobalColumns,
} from '../enums';
import { PerformanceGroupBy, Totals, MerchantOptionsType } from '../types';
import { ReportListItemType } from '../../../../components/LoadReportProfile/hooks';
import { PERMISSION_OPTIONS } from '../Modals/SaveReportProfile/enums';
import { LIST_TRACKING_PROFILES_BY_PUBLISHER } from '../../../../components/AdGenerateCodeModal/graphql/queries';

const formatTrackProfile = (trackings: ShowMerchantTrackingsType[]) => {
  const formattedTrackings = trackings.map((tracking: ShowMerchantTrackingsType) => ({
    label: `${tracking.id} - ${tracking.profileName}`,
    value: tracking.id,
  }));

  return [defaultTrackingsOption, ...formattedTrackings];
};

export const usePerformanceReport = () => {
  // Global Values
  const { hookShowToast } = useToast();
  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const today = new Date();
  const defaultDateRange = rangeFormat('last30Days');

  const originalMerchantOption: SelectOption = useMemo(() => {
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ||
      hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT
    ) {
      const merchantId = hookWhoAmI.companyId?.toString() || '0';
      const merchantName = hookWhoAmI.companyName || '';
      return { label: `${merchantId} - ${merchantName}`, value: merchantId };
    }
    return defaultMerchantOption;
  }, [window.location.href]);
  const originalPublisherOption: SelectOption = useMemo(() => {
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER ||
      hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER
    ) {
      const publisherId = hookWhoAmI.companyId?.toString() || '0';
      const publisherName = hookWhoAmI.companyName || '';
      return { label: `${publisherId} - ${publisherName}`, value: publisherId };
    }
    return defaultPublisherOption;
  }, [window.location.href]);
  const currentUserType: UserStringType = useMemo(() => {
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER ||
      hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER
    ) {
      return 'Publisher';
    }
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ||
      hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT
    ) {
      return 'Merchant';
    }
    return 'Admin';
  }, [window.location.href]);
  const canLoadAndSave =
    hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT &&
    hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER;
  // Date Range
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState(defaultDateRange.start);
  const [endDate, setEndDate] = useState(defaultDateRange.end);
  const [dateRange, setDateRange] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState(`${startDate.toDateString()} / ${endDate.toDateString()}`);
  const [calendarKey, setCalendarKey] = useState<number>(0);
  // Dropdowns
  const [merchantList, setMerchantList] = useState<SelectOption[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption>(originalMerchantOption);
  const [publisherList, setPublisherList] = useState<SelectOption[]>([]);
  const [selectedPublisher, setSelectedPublisher] = useState<SelectOption>(originalPublisherOption);
  const [productList, setProductList] = useState<SelectOption[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<SelectOption>(defaultProductOption);
  const [productCategoryList, setProductCategoryList] = useState<SelectOption[]>([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState<SelectOption>(defaultProductCategoryOption);
  const [immutableTrackingProfiles, setAllTrackingProfiles] = useState<ShowMerchantTrackingsType[]>([]); // List of all Options for Merchant
  const [trackingProfileList, setTrackingProfileList] = useState<SelectOption[]>([]); // List filtered by Publisher Selected
  const [selectedtrackingProfile, setSelectedTrackingProfile] = useState<SelectOption>(defaultTrackingsOption);
  const [publisherTrackingProfiles, setPublisherTrackingProfiles] = useState<SelectOption[]>([defaultTrackingsOption]); // List for if Publisher or Admin(Publisher)

  const [groupByList] = useState<SelectOption[]>(GROUP_BY_OPTIONS);
  const [selectedGroupBy, setSelectedGroupBy] = useState<PerformanceGroupBy>(GROUP_BY_OPTIONS[0]);
  // Advanced Dropdowns
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [adCampaignList, setAdCampaignList] = useState<SelectOption[]>([]);
  const [selectedAdCampaign, setSelectedAdCampaign] = useState<SelectOption>(defaultAdCampaignOption);
  const [publisherGroupList, setPublisherGroupList] = useState<SelectOption[]>([]);
  const [selectedPublisherGroup, setSelectedPublisherGroup] = useState<SelectOption>(defaultPublisherGroupOption);
  const [adsList, setAdsList] = useState<SelectOption[]>([]);
  const [selectedAd, setSelectedAd] = useState<SelectOption>(defaultAdsOption);

  // Loading and Errors
  const [pageLoadingText, setPageLoadingText] = useState('Loading Merchants...');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [disabledFields, setDisabledFields] = useState(true); // Controls Disabling the dropdown fields
  const [isDisabled, setIsDisabled] = useState(true); // Controls Save Report & Download CSV (not sure if should control Download CSV)

  // Table Status
  const [selectedRecordsPerPage, setRecordsPerPage] = useState<SelectOption>(RECORDS_PER_PAGE_OPTIONS[0]);
  const [recordsPerPageList] = useState<SelectOption[]>(RECORDS_PER_PAGE_OPTIONS);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [reportColumns, setReportColumns] = useState<TableColumn[]>(
    getColumnsByGroupBy(selectedGroupBy as PerformanceGroupBy)
  );
  const [reportColumnsBackup, setReportColumnsBackup] = useState<TableColumn[]>(
    getColumnsByGroupBy(selectedGroupBy as PerformanceGroupBy)
  );
  const [globalColumnsState, setGlobalColumnsState] = useState<SelectOption[]>(immutableGlobalColumns);
  const [reportGenerated, setReportGenerated] = useState<boolean>(false);
  // Table Data
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState<any[]>([]);
  const [allData, setAllData] = useState<any[]>([]);
  const [tableTotals, setTableTotals] = useState<Totals>();

  // Ad Modal (When grouping by Ad ID)
  const [isAdModalOpen, setIsAdModalOpen] = useState<boolean>(false);
  const [adModalId, setAdModalId] = useState<string>('');

  // Columns Modal
  const [columnsModal, setColumnsModal] = useModal(false);

  // Load Saved Report
  const [savedReports, setSavedReports] = useState<LSRReportType[]>([]);
  const [savedReportsDisplay, setSavedReportsDisplay] = useState<ReportListItemType[]>([]);
  const [loadingSavedReport, setLoadingSavedReport] = useState<boolean>(false);
  const [resetTrackingProfileList, setResetTrackingProfileList] = useState<boolean>(false);
  const [loadedReportId, setLoadedReportId] = useState<string>('');
  const [loadedReportName, setLoadedReportName] = useState<string>('');
  const [notMyReportLoaded, setNotMyReportLoaded] = useState<boolean>(false);

  // Queries and Mutations
  const [getAdminMerchants, { loading: merchantLoading }] = useLazyQuery<ListMerchantOutputType, ListMerchantInputType>(
    LIST_MERCHANTS
  );
  const [getPublisherMerchants, { loading: publisherMerchantsLoading }] = useLazyQuery<
    GetMerchantsForReportsOutputType,
    GetMerchantsForReportsInputType
  >(GET_MERCHANTS_FOR_REPORTS);
  const [getMerchantInfo, { loading: merchantInfoLoading }] = useLazyQuery<
    ShowMerchantOutputType,
    ShowMerchantInputType
  >(SHOW_MERCHANT_INFO);
  const [generatePerformance, { loading: performanceLoading }] = useLazyQuery<
    SearchPerformanceOutputType,
    SearchPerformanceInputType
  >(SEARCH_PERFORMANCE);
  const [getSavedReports, { loading: listSavedReportsLoading }] = useLazyQuery<LSROutputType, LSRInputType>(
    LIST_SAVED_REPORTS
  );
  const [saveReport, { loading: saveReportLoading }] = useMutation<SPROutputType, SPRInputType>(
    SAVE_PERFORMANCE_REPORT
  );
  const [getPublisherTrackings, { loading: publisherTrackingsLoading }] = useLazyQuery(
    LIST_TRACKING_PROFILES_BY_PUBLISHER
  );

  const advancedSearchHandler = () => {
    setAdvancedSearch(!advancedSearch);
  };

  /** Sets Product Category List to given array with All Categories added in front */
  const setProductCategoryListHandler = (productCategoriesArray: string[]) => {
    const returnList = [defaultProductCategoryOption];

    if (productCategoriesArray) {
      const listOptions: SelectOption[] = productCategoriesArray.map((productCategory: string) => ({
        label: productCategory,
        value: productCategory,
      }));
      const options = listOptions.filter(
        (option: SelectOption, index) => listOptions.findIndex((o) => option.value === o.value) === index
      );

      returnList.push(...options);
    }
    setProductCategoryList(returnList);
  };

  /** For setting all the dropdown values when a specific merchant is selelcted or used. */
  const getMerchantInfoHandler = async (merchantId: string) => {
    const productListOptions: SelectOption[] = [defaultProductOption];
    const publisherListOptions: SelectOption[] = [originalPublisherOption];
    const immutableTrackingProfilesList: ShowMerchantTrackingsType[] = [];
    const productCategoriesList: string[] = [];
    const adCampaignListOptions: SelectOption[] = [defaultAdCampaignOption];
    const publisherGroupListOptions: SelectOption[] = [defaultPublisherGroupOption];
    const adsListOptions: SelectOption[] = [defaultAdsOption];

    const { data } = await getMerchantInfo({
      variables: {
        input: {
          companyType: 'Merchant',
          accountStatus: 'Approved',
          id: merchantId,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.companyOptions?.companies && data.companyOptions.companies[0]) {
      const companyData = data.companyOptions;
      companyData.companies.forEach((company: ShowMerchantCompaniesType) => {
        // For Product ID - Name dropdown
        const productsFormatted = company.program.products.map((product: ShowMerchantProductsType) => ({
          label: `${product.customizedProductId} - ${product.name}`,
          value: product.id,
        }));
        if (productListOptions.length > 0) {
          productListOptions.push(...productsFormatted);
        }

        // For Publisher ID - Name dropdown
        // For Tracking Profile dropdown
        const publishersFormatted = company.memberships.map((membership: ShowMerchantMembershipsType) => {
          immutableTrackingProfilesList.push(
            ...membership.publisher.trackings.map((tracking) => ({ ...tracking, publisherId: membership.publisher.id }))
          );
          return {
            label: `${membership.publisher.id} - ${membership.publisher.companyName}`,
            value: membership.publisher.id,
          };
        });
        if (publisherListOptions.length > 0) {
          publisherListOptions.push(...publishersFormatted);
        }

        // For Ad Campaign dropdown
        const adCampaignFormatted = company.program.campaigns.map((campaign: ShowMerchantCampaignsType) => ({
          label: `${campaign.id} - ${campaign.name}`,
          value: campaign.id,
        }));
        if (adCampaignListOptions.length > 0) {
          adCampaignListOptions.push(...adCampaignFormatted);
        }

        // For Publisher Group downdown
        const publisherGroupFormatted = company.program.publisherGroups.map(
          (publisherGroup: ShowMerchantPublisherGroupsType) => ({
            label: `${publisherGroup.id} - ${publisherGroup.name}`,
            value: publisherGroup.id,
          })
        );
        if (publisherGroupListOptions.length > 0) {
          publisherGroupListOptions.push(...publisherGroupFormatted);
        }

        // For Ad ID dropdown
        const adsFormatted = company.program.ads.map((ad: ShowMerchantAdsType) => ({
          label: `${ad.id} - ${ad.adName}`,
          value: ad.id,
        }));
        if (adsListOptions.length > 0) {
          adsListOptions.push(...adsFormatted);
        }

        // For Product Category dropdown
        productCategoriesList.push(...company.program.products.map((product) => product.productCategory));
      });
    }

    setProductList(productListOptions);
    setPublisherList(publisherListOptions);
    setAllTrackingProfiles(immutableTrackingProfilesList);
    setProductCategoryListHandler(productCategoriesList);
    setTrackingProfileList(formatTrackProfile(immutableTrackingProfilesList));
    setAdCampaignList(adCampaignListOptions);
    setPublisherGroupList(publisherGroupListOptions);
    setAdsList(adsListOptions);
    setDisabledFields(false);
  };

  /**
   * Controls Changing the Merchant Selected.
   * Sets All Dropdowns dependant on Merchant to default
   * Clears all fields if "All Merchants" selected.
   */
  const selectMerchantHandler = (merchant: SelectOption, noDefaults?: boolean) => {
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setReportGenerated(false);
    setSelectedMerchant(merchant);

    if (!noDefaults) {
      setSelectedPublisher(originalPublisherOption);
      setSelectedProduct(defaultProductOption);
      setSelectedProductCategory(defaultProductCategoryOption);
      setProductCategoryListHandler(immutableProductCategories);
      setSelectedTrackingProfile(defaultTrackingsOption);
      setSelectedAdCampaign(defaultAdCampaignOption);
      setSelectedPublisherGroup(defaultPublisherGroupOption);
      setSelectedAd(defaultAdsOption);
    }

    if (merchant.value) {
      getMerchantInfoHandler(merchant.value);
      setPageLoadingText(`Loading ${merchant.label}`);
    } else {
      setDisabledFields(true);
      setPublisherList([]);
      setProductList([]);
      setProductCategoryListHandler(immutableProductCategories);
      setAllTrackingProfiles([]);
      setTrackingProfileList([]);
      setAdCampaignList([]);
      setPublisherGroupList([]);
      setAdsList([]);
    }
  };

  /** When Publisher is set filter Tracking Profile List to that Publishers and set Selected Tracking Profile to default */
  const selectPublisherHandler = (publisher: SelectOption, noReset?: boolean) => {
    if (!noReset) {
      setTableData([]);
      setSelectedTrackingProfile(defaultTrackingsOption);
    }
    setCurrentPage(1);
    setSelectedPublisher(publisher);
    if (publisher.value) {
      const filteredTrackingProfiles = immutableTrackingProfiles.filter(
        (trackingProfile: ShowMerchantTrackingsType) => trackingProfile.publisherId === publisher.value
      );
      setTrackingProfileList(formatTrackProfile(filteredTrackingProfiles));
    } else {
      setTrackingProfileList(formatTrackProfile(immutableTrackingProfiles));
    }
  };

  const selectProductHandler = (product: SelectOption) => {
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setReportGenerated(false);
    setSelectedProduct(product);
  };

  const selectProductCategoryHandler = (productCategory: SelectOption) => {
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setReportGenerated(false);
    setSelectedProductCategory(productCategory);
  };

  const selectGroupByHandler = (groupBy: PerformanceGroupBy) => {
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setReportGenerated(false);
    setSelectedGroupBy(groupBy);
  };

  const setOpenCalendarHandler = () => {
    setOpenCalendar(!openCalendar);
  };

  const setOnCancelCalendarHandler = () => {
    setOpenCalendar(false);
  };

  const setOnApplyCalendarHandler = (startDateValue: Date, endDateValue?: Date, range?: string) => {
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setReportGenerated(false);
    setOpenCalendar(false);
    setStartDate(startDateValue);

    if (endDateValue) setEndDate(endDateValue);
    if (range) setDateRange(range);
    else setDateRange('');

    setSelectedDateRange(`${startDateValue.toDateString()} / ${endDateValue?.toDateString()}`);
  };

  const setClearFormHandler = () => {
    setTableData([]);
    setReportGenerated(false);
    setStartDate(defaultDateRange.start);
    setEndDate(today);
    setDateRange('');
    setSelectedDateRange(`${defaultDateRange.start.toDateString()} / ${today.toDateString()}`);
    selectMerchantHandler(originalMerchantOption);
    setSelectedPublisher(originalPublisherOption);
    setSelectedProduct(defaultProductOption);
    setSelectedProductCategory(defaultProductCategoryOption);
    setSelectedTrackingProfile(defaultTrackingsOption);
    setSelectedGroupBy(GROUP_BY_OPTIONS[0]);
    setSelectedAdCampaign(defaultAdCampaignOption);
    setSelectedPublisherGroup(defaultPublisherGroupOption);
    setSelectedAd(defaultAdsOption);
    setIsDisabled(true);
    setLoadedReportId('');
    setLoadedReportName('');
    setErrorMessage('');
    setNotMyReportLoaded(false);
    setCalendarKey(calendarKey + 1);
  };

  const setAdModalHandler = (value: string) => {
    setIsAdModalOpen(!isAdModalOpen);
    setAdModalId(value);
  };

  const setOpenCloseAdModalHandler = () => {
    setIsAdModalOpen(!isAdModalOpen);
  };

  const setOnDragEndHandler = (result: any) => {
    setReportColumns(result);
  };

  const setSelectedAdCampaingHandler = (adCampaign: SelectOption) => {
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setReportGenerated(false);
    setSelectedAdCampaign(adCampaign);
  };

  const setSelectedPublisherGroupHandler = (publisherGroup: SelectOption) => {
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setReportGenerated(false);
    setSelectedPublisherGroup(publisherGroup);
  };

  const setSelectedAdHandler = (ad: SelectOption) => {
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setReportGenerated(false);
    setSelectedAd(ad);
  };

  const setColumnsModalHandler = () => {
    setColumnsModal();
  };

  const setRecordsPerPageHandler = (option: SelectOption) => {
    setRecordsPerPage(option);
    setCurrentPage(1);
    if (tableData && allData) {
      setTableData(allData.slice(0, Number.parseInt(option.value, 10)));
      setTotalPages(Math.ceil(allData.length / Number.parseInt(option.value, 10)));
    }
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
    setTableData(
      allData.slice(
        (page - 1) * Number.parseInt(selectedRecordsPerPage.value, 10),
        page * Number.parseInt(selectedRecordsPerPage.value, 10)
      )
    );
  };

  /** Creates the list of available merchants for Merchant ID - Name dropdown */
  const createMerchantListHandler = (companyList: MerchantOptionsType[]) => {
    const newMerchantList = [originalMerchantOption];

    if (companyList) {
      const listOptions: SelectOption[] = companyList.map((merchant: MerchantOptionsType) => ({
        label: `${merchant.id} - ${merchant.companyName}`,
        value: merchant.id,
      }));
      newMerchantList.push(...listOptions);
    }
    setMerchantList(newMerchantList);
  };

  /** Gets the Merchant list if Admin, Publisher, or Publisher(Admin) */
  const getMerchantList = async () => {
    setErrorMessage('');

    // User is a Publisher or Admin as Publisher
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER ||
      hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER
    ) {
      const [{ data: publisherMerchants }, { data: publisherTrackings }] = await Promise.all([
        getPublisherMerchants({
          variables: {
            input: {
              companyType: 'Publisher',
              accountStatus: 'Approved',
              id: hookWhoAmI.companyId?.toString() || '0',
            },
          },
          fetchPolicy: 'no-cache',
          onError(err) {
            setErrorMessage(err.message);
          },
        }),
        getPublisherTrackings({
          variables: {
            companyId: hookWhoAmI.companyId?.toString() || '0',
          },
          fetchPolicy: 'no-cache',
          onError(err) {
            setErrorMessage(err.message);
          },
        }),
      ]);

      if (publisherMerchants && publisherMerchants.companyOptions) {
        createMerchantListHandler(publisherMerchants.companyOptions.companies);
      }
      if (publisherTrackings && publisherTrackings.trackings) {
        const options = (publisherTrackings.trackings?.trackings || []).map((opt: any) => ({
          label: `${opt.id} - ${opt.profileName}`,
          value: opt.id,
        }));
        setPublisherTrackingProfiles([defaultTrackingsOption, ...options]);
      }
    }

    // User is Admin
    if (hookWhoAmI?.isActingAsUserTypeId === undefined && hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN) {
      const { data } = await getAdminMerchants({
        variables: {
          input: {
            companyType: 'Merchant',
            accountStatus: 'Approved',
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      });

      if (data && data.companyOptions) {
        createMerchantListHandler(data.companyOptions.companies);
      }
    }
  };

  /** Queries Performance Report Data and Sets Table to Data */
  const setGenerateReportHandler = async (isSavedReportLoading?: boolean) => {
    setPageLoadingText(`Generating report for ${selectedMerchant.label}`);
    setTotalPages(1);
    setAllData([]);
    setTableData([]);
    setCurrentPage(1);
    setErrorMessage('');

    const generatePerformanceInput: SearchPerformanceInputType = {
      input: {
        startDate: toUTCHours(startDate, 'beginning').toISOString(),
        endDate: toUTCHours(endDate, 'end').toISOString(),
        groupBy: selectedGroupBy.value === 'PUBLISHER_NAME' ? 'PUBLISHER' : selectedGroupBy.value,
        ...(selectedMerchant.value && { merchantId: selectedMerchant.value }),
        ...(selectedPublisher.value && { publisherId: selectedPublisher.value }),
        ...(selectedtrackingProfile.value && { trackingProfileId: selectedtrackingProfile.value }),
        ...(selectedProduct.value && { productId: selectedProduct.value }),
        ...(selectedAd.value && { adId: selectedAd.value }),
        ...(selectedProductCategory.value && { productCategory: selectedProductCategory.value }),
        ...(selectedAdCampaign.value && { campaignId: selectedAdCampaign.value }),
        ...(selectedPublisherGroup.value && { publisherGroupId: selectedPublisherGroup.value }),
      },
    };
    setReportGenerated(false);
    const { data } = await generatePerformance({
      variables: generatePerformanceInput,
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
      errorPolicy: 'all', // makes Appolo client fill in both data and error on errors
    });

    if (isSavedReportLoading) setResetTrackingProfileList(true);
    setReportGenerated(true);
    if (data && data?.performances?.performances?.length > 0) {
      setIsDisabled(false);

      const { processedData, processedTotals } = processTableData(data.performances.performances);
      // Table needs to know usertype
      processedData.forEach((row) => {
        if (hookUserInfo.userTypesId === 3) {
          // eslint-disable-next-line no-param-reassign
          row.userType = currentUserType;
        }
      });
      const getColumns = getColumnsByGroupBy(selectedGroupBy as PerformanceGroupBy, setAdModalHandler, processedTotals);

      const filteredOutNonMatchs = getColumns.filter(
        (column) =>
          globalColumnsState.some((shownColumn) => shownColumn.value === column.dataField) ||
          !immutableGlobalColumns.some((globalColumns) => globalColumns.value === column.dataField)
      );

      if (isSavedReportLoading) {
        const sortColumns = (first: TableColumn, second: TableColumn) => {
          const firstIndex = 1000 - globalColumnsState.findIndex((col) => col.value === first.dataField);
          const secondIndex = 1000 - globalColumnsState.findIndex((col) => col.value === second.dataField);
          return secondIndex - firstIndex;
        };
        filteredOutNonMatchs.sort(sortColumns);
      }

      setReportColumns(filteredOutNonMatchs);
      setReportColumnsBackup(getColumns);

      setSortColumn({ column: getColumns[0].dataField, direction: 'desc' });
      const sortedData = processedData.sort(compareValues(getColumns[0].dataField, 'desc'));
      setAllData(sortedData);
      setTableData(
        sortedData.slice(
          (currentPage - 1) * Number.parseInt(selectedRecordsPerPage.value, 10),
          currentPage * Number.parseInt(selectedRecordsPerPage.value, 10)
        )
      );
      setTotalPages(Math.ceil(processedData.length / Number.parseInt(selectedRecordsPerPage.value, 10)));
      setTableTotals(processedTotals);
    } else {
      setIsDisabled(true);
      setTableData([]);
    }
  };

  /** Triggers download of Performance Report CSV file based on current Table Data */
  const setGenerateReportCSVHandler = () => {
    const csvString = processDataToCSVString(allData, tableTotals, reportColumns);
    csvGenerator(csvString, 'Performance Report');
  };

  /** Controls Turning a column on or off in the table */
  const setColumnsHandler = (column: SelectOption, checked: boolean) => {
    // Add a column from the column state
    if (checked) {
      let backupIndex = 0;
      const reportIndex = reportColumnsBackup
        .filter((bCol, index) => {
          const inCurrentColumns = reportColumns.some((rCol) => rCol.dataField === bCol.dataField);
          let isNewColumn = false;
          if (bCol.dataField === column.value) {
            backupIndex = index;
            isNewColumn = true;
          }
          return inCurrentColumns || isNewColumn;
        })
        .findIndex((item) => item.dataField === column.value);

      const newGlobalColumnState = [...globalColumnsState];
      const newReportColumnState = [...reportColumns];
      if (reportIndex > newGlobalColumnState.length)
        newGlobalColumnState.splice(newGlobalColumnState.length, 0, column);
      else newGlobalColumnState.splice(reportIndex, 0, column);
      if (reportIndex > newReportColumnState.length)
        newReportColumnState.splice(newReportColumnState.length, 0, reportColumnsBackup[backupIndex]);
      else newReportColumnState.splice(reportIndex, 0, reportColumnsBackup[backupIndex]);

      setReportColumns(newReportColumnState);
      setGlobalColumnsState(newGlobalColumnState);

      // Remove a column to the column state
    } else {
      const removeFromGlobalColumns = globalColumnsState.filter((col: SelectOption) => col.value !== column.value);
      const newReportColumnState = reportColumns.filter((rColumn) => rColumn.dataField !== column.value);
      setReportColumns(newReportColumnState);
      setGlobalColumnsState(removeFromGlobalColumns);
    }
  };

  const setSavedReportsHandler = (newSavedReports: LSRReportType[]) => {
    setSavedReports(newSavedReports);
  };

  /** Gets the Saved Reports of the user */
  const getSavedReportsList = async () => {
    setErrorMessage('');
    const getSavedReportsInput: LSRInputType = {
      user: {
        company: Number(hookWhoAmI.companyId) || null,
        user: {
          id: hookWhoAmI.id,
          userType: currentUserType,
        },
        report: 'Performance',
      },
    };
    if (currentUserType !== 'Admin') getSavedReportsInput.user.company = Number(hookWhoAmI.companyId);
    const { data } = await getSavedReports({
      variables: getSavedReportsInput,
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    if (data && data.reportProfiles) {
      setSavedReportsHandler(data.reportProfiles.reports);
      const savedReportsSelectList: ReportListItemType[] = data.reportProfiles.reports.map((report) => ({
        id: report.id,
        name: report.name,
        description: report.description,
      }));
      setSavedReportsDisplay(savedReportsSelectList);
    }
  };

  // Create or Updates a Saved Report
  const onSaveHandler = async (profile: any) => {
    // Create Input Object
    const setReportProfile: SPRInputType = {
      save: {
        filters: [
          {
            field: 'merchantId',
            value: selectedMerchant,
          },
          {
            field: 'publisherId',
            value: selectedPublisher,
          },
          {
            field: 'productId',
            value: selectedProduct,
          },
          {
            field: 'productCategory',
            value: selectedProductCategory,
          },
          {
            field: 'trackingProfileId',
            value: selectedtrackingProfile,
          },
          {
            field: 'groupBy',
            value: selectedGroupBy,
          },
          {
            field: 'campaignId',
            value: selectedAdCampaign,
          },
          {
            field: 'publisherGroupId',
            value: selectedPublisherGroup,
          },
          {
            field: 'adId',
            value: selectedAd,
          },
          {
            field: 'sizePerPage',
            value: selectedRecordsPerPage,
          },
        ],
        columns: reportColumns.map((column) => ({ label: column.text, value: column.dataField })),
        user: {
          id: hookWhoAmI.id,
          email: hookWhoAmI.email,
        },
        report: 'Performance',
        name: profile.name || '',
        // Add in Optional Fields
        ...(profile?.description && { description: profile.description }),
        ...(profile?.schedule?.frequency && { frequency: profile.schedule.frequency }),
        ...(profile?.schedule?.detailedFrequency && { every: profile.schedule.detailedFrequency }),
        ...(loadedReportId && { id: loadedReportId }),
      },
    };

    if (profile?.permission?.value === PERMISSION_OPTIONS.ALL_USERS) {
      if (currentUserType === 'Admin') setReportProfile.save.company = 1;
      else setReportProfile.save.company = Number(hookWhoAmI.companyId);
    }

    // Determine if using a Date Range
    if (dateRange) {
      setReportProfile.save.filters.push({
        field: 'dateRange',
        value: {
          value: dateRange,
          label: dateRange,
        },
      });
    } else {
      setReportProfile.save.filters.push(
        {
          field: 'startDate',
          value: {
            value: startDate.toString(),
            label: startDate.toString(),
          },
        },
        {
          field: 'endDate',
          value: {
            value: endDate.toString(),
            label: endDate.toString(),
          },
        }
      );
    }

    // Create or Update Saved Report
    setErrorMessage('');
    const { data } = await saveReport({
      variables: setReportProfile,
      onError(err) {
        setErrorMessage(err.message);
      },
      errorPolicy: 'all', // makes Appolo client fill in both data and error on errors
    });

    if (data) {
      hookShowToast(`Your report "${profile.name || ''}" was saved successfully.`);
      getSavedReportsList();
    }
  };

  const updateReportHandler = () => {
    const savedReportIndex = savedReports.findIndex((report) => report.id === loadedReportId);
    if (savedReportIndex === -1) {
      hookShowToast('Your report failed to update.');
      return;
    }
    const profile = {
      name: savedReports[savedReportIndex].name,
      description: savedReports[savedReportIndex].description,
      permission: savedReports[savedReportIndex].company ? PERMISSION_OPTIONS.ALL_USERS : PERMISSION_OPTIONS.ONLY_ME,
      schedule: {
        frequency: savedReports[savedReportIndex].frequency || '',
        detailedFrequency: savedReports[savedReportIndex].every || '',
      },
    };
    onSaveHandler(profile);
  };

  /** Sorts the table by the column and direction given */
  const sortTableHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    const newDirection = direction === 'desc' ? 'asc' : 'desc';
    const copy = structuredClone(allData);
    const sortedCopy = copy.sort(compareValues(column, newDirection));
    setAllData(sortedCopy);
    setTableData(
      sortedCopy.slice(
        (currentPage - 1) * Number.parseInt(selectedRecordsPerPage.value, 10),
        currentPage * Number.parseInt(selectedRecordsPerPage.value, 10)
      )
    );
    setSortColumn({ column, direction: newDirection });
  };

  /** Loads the saved report selected */
  const loadSavedReportHandler = (reportId: string) => {
    // Find the correct saved report
    const reportIndex = savedReports.findIndex((report) => report.id === reportId);
    if (reportIndex === -1) return;

    // Set the Dropdowns Correctly
    let newStartDate: Date = new Date();
    let newEndDate: Date = new Date();
    let newRange = '';
    let newGroupBy: PerformanceGroupBy = GROUP_BY_OPTIONS[0];
    savedReports[reportIndex].filters.forEach((field) => {
      switch (field.field) {
        case 'startDate':
          newStartDate = new Date(field.value.value);
          setStartDate(newStartDate);
          break;
        case 'endDate':
          newEndDate = new Date(field.value.value);
          setEndDate(newEndDate);
          break;
        case 'dateRange':
          newRange = field.value.value;
          break;
        case 'merchantId':
          selectMerchantHandler(field.value, true);
          break;
        case 'publisherId':
          setSelectedPublisher(field.value);
          break;
        case 'productId':
          setSelectedProduct(field.value);
          break;
        case 'productCategory':
          setSelectedProductCategory(field.value);
          break;
        case 'trackingProfileId':
          setSelectedTrackingProfile(field.value);
          break;
        case 'groupBy':
          newGroupBy = field.value as PerformanceGroupBy;
          setSelectedGroupBy(field.value as PerformanceGroupBy);
          break;
        case 'campaignId':
          setSelectedAdCampaign(field.value);
          break;
        case 'publisherGroupId':
          setSelectedPublisherGroup(field.value);
          break;
        case 'adId':
          setSelectedAdHandler(field.value);
          break;
        case 'sizePerPage':
          setRecordsPerPage(field.value);
          break;
        default:
          break;
      }
    });

    // if Range sets the Date Picker to the Range
    setCalendarKey(calendarKey + 1);
    if (RangeOptions.includes(newRange)) {
      const { start, end, range } = rangeFormat(newRange as RangeFormat);
      setStartDate(start);
      setEndDate(end);
      setDateRange(range);
      setSelectedDateRange(`${start.toDateString()} / ${end.toDateString()}`);
    } else {
      setSelectedDateRange(`${newStartDate.toDateString()} / ${newEndDate.toDateString()}`);
      setDateRange('');
    }

    // Set the Table Columns correctly
    setGlobalColumnsState(
      savedReports[reportIndex].columns.map((column): SelectOption => ({ label: column.label, value: column.value }))
    );
    const allColumns = getColumnsByGroupBy(newGroupBy);
    const filteredOutNonMatchs = allColumns.filter((column) =>
      savedReports[reportIndex].columns.some((shownColumn) => shownColumn.value === column.dataField)
    );
    const orderedColumns: TableColumn[] = savedReports[reportIndex].columns
      .map((column) => filteredOutNonMatchs.find((col) => column.value === col.dataField))
      .filter((col) => col !== undefined) as TableColumn[];
    setReportColumns(orderedColumns);
    setReportColumnsBackup(allColumns);

    // Save which Report was loaded in
    setLoadedReportId(reportId);
    setLoadedReportName(savedReports[reportIndex].name);

    // Check if it is your Report that was loaded
    if (savedReports[reportIndex].user.id !== hookUserInfo.id) {
      setNotMyReportLoaded(true);
    } else {
      setNotMyReportLoaded(false);
    }

    // Trigger the Table Fetch
    setLoadingSavedReport(true);
  };

  /** Checks or Uncheck all columns from report
   * @param {boolean} checked value of the checkbox, true if all columns are selected
   */
  const setAllColumnsHandler = (checked: boolean) => {
    /* If the checkbox is checked, select all columns, using the initial column values */
    if (checked) {
      setReportColumns(reportColumnsBackup);
      setGlobalColumnsState(immutableGlobalColumns);
    } else {
      /* Reset the columns, only allowing the merchant related to be displayed */
      setReportColumns(reportColumnsBackup.slice(0, 3));
      setGlobalColumnsState([]);
    }
  };

  // Functions to run on original page load
  useEffect(() => {
    setClearFormHandler();
    setProductCategoryListHandler(immutableProductCategories);
    selectMerchantHandler(originalMerchantOption);
    if (canLoadAndSave) getSavedReportsList();
    getMerchantList();
  }, [window.location.href]);

  // For Generating a saved report after fields have been set
  if (loadingSavedReport) {
    setGenerateReportHandler(true);
    setLoadingSavedReport(false);
  }

  // For Getting Correct Options in the Tracking Profile Dropdown after loading a saved report
  if (resetTrackingProfileList) {
    selectPublisherHandler(selectedPublisher, true);
    setResetTrackingProfileList(false);
  }

  return {
    // Page Wide Values
    hookWhoAmI,
    hookPageLoading:
      merchantLoading ||
      merchantInfoLoading ||
      performanceLoading ||
      publisherMerchantsLoading ||
      saveReportLoading ||
      publisherTrackingsLoading,
    hookPageLoadingText: pageLoadingText,
    hookErrorMessage: errorMessage,
    hookCanLoadAndSave: canLoadAndSave,
    hookCurrentUserType: currentUserType,

    // Dropdowns
    hookDisabledFields: disabledFields,
    hookClearForm: setClearFormHandler,
    // Calendar
    hookOpenCalendar: openCalendar,
    hookSetOpenCalendar: setOpenCalendarHandler,
    hookOnCancelCalendar: setOnCancelCalendarHandler,
    hookOnApplyCalendar: setOnApplyCalendarHandler,
    hookSelectDate: selectedDateRange,
    hookStartDate: startDate,
    hookEndDate: endDate,
    hookRange: dateRange,
    hookCalendarKey: calendarKey,
    // Merchant Id
    hookMerchantList: merchantList,
    hookSelectedMerchant: selectedMerchant,
    hookSetSelectedMerchant: selectMerchantHandler,
    hookMerchantLoading: merchantLoading || publisherMerchantsLoading,
    hookMerchantInfoLoading: merchantInfoLoading,
    // Publisher Id
    hookPublisherList: publisherList,
    hookSelectedPublisher: selectedPublisher,
    hookSetSelectedPublisher: selectPublisherHandler,
    // Product Id
    hookSelectedProduct: selectedProduct,
    hookSetSelectedProduct: selectProductHandler,
    hookProductList: productList,
    // Product Category
    hookProductCategoriesLoading: merchantLoading || merchantInfoLoading,
    hookProductCategoryList: productCategoryList,
    hookSelectedProductCategory: selectedProductCategory,
    hookSetSelectedProductCategory: selectProductCategoryHandler,
    // Tracking Profile
    hookTrackingProfileList: currentUserType === 'Publisher' ? publisherTrackingProfiles : trackingProfileList,
    hookSelectedtrackingProfile: selectedtrackingProfile,
    hookSetSelectedtrackingProfile: setSelectedTrackingProfile,
    // Group By Options
    hookGroupByList: groupByList,
    hookSelectedGroupBy: selectedGroupBy,
    hookSetSelectedGroupBy: selectGroupByHandler,
    // Advanced Dropdowns
    hookAdvancedSearch: advancedSearch,
    hookSetAdvancedSearch: advancedSearchHandler,
    // Ad Campaign
    hookAdCampaignList: adCampaignList,
    hookSelectedAdCampaign: selectedAdCampaign,
    hookSetSelectedAdCampaign: setSelectedAdCampaingHandler,
    // Publisher Group
    hookPublisherGroupList: publisherGroupList,
    hookSelectedPublisherGroup: selectedPublisherGroup,
    hookSetSelectedPublisherGroup: setSelectedPublisherGroupHandler,
    // Ad ID
    hookAdsList: adsList,
    hookSelectedAd: selectedAd,
    hookSetSelectedAd: setSelectedAdHandler,
    // Records
    hookRecordsPerPage: recordsPerPageList,
    hookSelectedRecordsPerPage: selectedRecordsPerPage,
    hookSetSelectedRecordsPerPage: setRecordsPerPageHandler,

    // Table Data
    hookTotalPages: totalPages,
    hookTotalRecords: allData.length,
    hookTableData: tableData,
    hookTableTotals: tableTotals,
    // Table State
    hookSortColumn: sortColumn,
    hookCurrentPage: currentPage,
    hookReportColumns: reportColumns,
    hookGlobalColumns: globalColumnsState,
    hookPerformanceLoading: performanceLoading,
    hookReportGenerated: reportGenerated,

    // Table Handlers
    hookSetOnDragEnd: setOnDragEndHandler,
    hookSortTableHandler: sortTableHandler,
    hookSetCurrentPage: setCurrentPageHandler,
    hookGenerateReport: setGenerateReportHandler,

    // Ad Modal (When 'Group By' Ad Id)
    hookAdModalId: adModalId,
    hookAdModal: isAdModalOpen,
    hookSetAdModal: setAdModalHandler,
    hookOpenCloseAdModal: setOpenCloseAdModalHandler,

    // Customize Columns Modal
    hookColumnsModal: columnsModal,
    hookSetColumnsModal: setColumnsModalHandler,
    hookSetColumns: setColumnsHandler,
    hookSetAllColumns: setAllColumnsHandler,

    // Download CSV
    hookGenerateReportCSV: setGenerateReportCSVHandler,

    // Save Report
    hookIsDisabled: isDisabled,
    hookOnSave: onSaveHandler,
    hookOnUpdate: updateReportHandler,
    hookIsSavedReportLoaded: !!loadedReportId,

    // Load Report
    hookSavedReports: savedReportsDisplay,
    hookLoadSavedReport: loadSavedReportHandler,
    hookSavedReportsLoading: listSavedReportsLoading,
    hookLoadedReportName: loadedReportName,
    hookNotMyReportLoaded: notMyReportLoaded,
  };
};
