import React from 'react';

import * as Styled from './styles';

type FormatedCellProps = {
  value?: any;
  secondValue?: any;
  children?: any;
};

export const FormatedCell = ({ value, secondValue, children }: FormatedCellProps) => (
  <Styled.CellWrapperStyled>
    <Styled.FirstLineStyled>{value}</Styled.FirstLineStyled>
    {children}
    {secondValue && <Styled.SecondLineStyled>{secondValue}</Styled.SecondLineStyled>}
  </Styled.CellWrapperStyled>
);
