import styled from 'styled-components';

import { colors, fonts } from '../../../../../styles/theme';

export const CellStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: pre-wrap;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SecondValueStyled = styled.a`
  ${fonts.lato.H14}
  color: ${colors.color2};
  line-height: 1.5;
`;
