import React, { useEffect, useId, useState } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import * as Styled from './styles';

interface SelectProps {
  children?: React.ReactNode;
  className?: string;
  creatable?: boolean;
  description?: string;
  error?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  label?: string;
  menuPlacement?: 'auto' | 'top';
  name?: string;
  onBlur?: () => void;
  onChange: (value: any) => void;
  optionBorder?: boolean;
  options?: any[];
  placeholder?: string;
  required?: boolean;
  selected?: {
    label: string;
    value: string | boolean | number | null | undefined;
  };
  selectedMulti?: {
    label: string;
    value: string;
  }[];
  tooltip?: string;
  tooltipExtraMarginLeft?: number;
  width?: string;
}

export const Select = ({
  children,
  className,
  creatable = false,
  description,
  error,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  label,
  menuPlacement = 'auto',
  name,
  onBlur,
  onChange,
  optionBorder = false,
  options = [],
  placeholder,
  required = false,
  selected,
  selectedMulti = [],
  tooltip,
  tooltipExtraMarginLeft,
  width = '100%',
}: SelectProps) => {
  const [selectedState, setSelectedState] = useState(selected);
  const id = useId();
  useEffect(() => {
    setSelectedState(selected);
  }, [JSON.stringify(selected)]);

  return (
    <Styled.WrapperStyled id={id} width={width} className={className}>
      <Styled.LabelStyled htmlFor={id}>
        {label && (
          <Styled.LabelTextStyled>
            {label} {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}{' '}
            {tooltip && <Styled.TooltipStyled extraMarginLeft={tooltipExtraMarginLeft} text={tooltip} />}{' '}
            {isLoading && <Styled.SpinnerStyled theme="secondary" size=".7rem" />}
          </Styled.LabelTextStyled>
        )}

        {description && <Styled.DescriptionStyled>{description}</Styled.DescriptionStyled>}
        {children && children}
        {creatable ? (
          <CreatableSelect
            isDisabled={isDisabled}
            options={options}
            onChange={onChange}
            defaultValue={selected || selectedMulti}
            value={selectedState || selectedMulti}
            styles={Styled.SelectStyles(error)}
            isMulti={isMulti}
            name={name}
            isLoading={isLoading}
            placeholder={placeholder}
            menuPlacement="auto"
            onBlur={onBlur}
          />
        ) : (
          <ReactSelect
            isDisabled={isDisabled}
            options={options}
            onChange={onChange}
            defaultValue={selected || selectedMulti}
            value={selectedState || selectedMulti}
            menuPortalTarget={document.body}
            styles={Styled.SelectStyles(error, optionBorder)}
            isMulti={isMulti}
            name={name}
            isLoading={isLoading}
            placeholder={placeholder}
            menuPlacement={menuPlacement || 'auto'}
            onBlur={onBlur}
          />
        )}

        {error && <Styled.ErrorStyled>{error}</Styled.ErrorStyled>}
      </Styled.LabelStyled>
    </Styled.WrapperStyled>
  );
};
