import styled from 'styled-components';

import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

type TableDataStyledProps = {
  readonly theme?: string;
  readonly isWarning?: boolean;
};

export const SectionStyled = styled.div`
  margin-top: 2rem;
`;

export const TitleStyled = styled.h2`
  ${fonts.gotham.H22}
`;

export const TitleButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 280px;
`;

export const SubHeaderWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${toRem(40)};
`;

export const TableDataStyled = styled.div<TableDataStyledProps>`
  display: flex;
  width: 100%;
  min-height: 48px;
  line-height: ${toRem(24)};

  flex-direction: ${({ theme }) => {
    switch (theme) {
      case 'row':
        return 'row';
      case 'rowSecondary':
        return 'row';
      default:
        return 'column';
    }
  }};
  align-items: ${({ theme }) => {
    switch (theme) {
      case 'row':
        return 'center';
      case 'rowSecondary':
        return 'center';
      default:
        return '';
    }
  }};

  justify-content: ${({ theme }) => {
    switch (theme) {
      case 'rowSecondary':
        return 'space-between';
      case 'row':
        return 'flex-start';
      default:
        return 'center';
    }
  }};

  svg {
    margin-right: ${toRem(10)};
    font-size: ${toRem(16)};
    color: ${({ isWarning }) => (isWarning ? colors.color2 : colors.color5)};

    &:hover {
      color: ${colors.color2};
    }
  }
`;

export const TableFooterStyled = styled.div`
  color: ${colors.color2};
  ${fonts.gotham.H14B}
`;
