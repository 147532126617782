import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppWrapper, PageTitle, Tabs, ErrorBanner } from 'components';
import { imageList, path } from 'utils';
import { DefaultPropsType } from 'types';

import { OverviewTab, ProductsTab, TermsConditionsTab } from './Tabs';
import { PROGRAM_DETAILS, MENU_BACK, MENU_CANCEL, MENU_APPLY, MENU_APPROVE, MENU_DECLINE } from './enum';
import { useProgramDetails } from './hooks';
import { ModalComponent } from './components/Modal';
import * as Styled from './styles';

type ProgramDetailsProps = DefaultPropsType;

const ProgramDetails = ({ permissionsCodeList = [] }: ProgramDetailsProps): JSX.Element => {
  const { hookIsReadOnlyList, ...hook } = useProgramDetails(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  const navigate = useNavigate();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner isOpen={!!hook.hookErrorMessage} message={hook.hookErrorMessage} />
      <Styled.HeaderStyled>
        <PageTitle isLoading={hook.hookPageLoading}>{path.programDetails.name}</PageTitle>
        <Styled.ButtonWrapperStyled>
          {hook.hookButtonOptions.includes(MENU_BACK) && (
            <Styled.ButtonStyled theme="secondary" onClick={() => navigate(-1)}>
              {PROGRAM_DETAILS.BUTTON.BACK}
            </Styled.ButtonStyled>
          )}
          {hook.hookButtonOptions.includes(MENU_DECLINE) && (
            <Styled.ButtonStyled onClick={hook.hookHandleDecline} theme="nonary" disabled={isReadOnly}>
              {MENU_DECLINE}
            </Styled.ButtonStyled>
          )}
          {hook.hookButtonOptions.includes(MENU_APPROVE) && (
            <Styled.ButtonStyled onClick={hook.hookHandleAccept} disabled={isReadOnly}>
              {MENU_APPROVE}
            </Styled.ButtonStyled>
          )}
          {hook.hookButtonOptions.includes(MENU_APPLY) && (
            <Styled.ButtonStyled onClick={hook.hookHandleApply} disabled={isReadOnly}>
              {MENU_APPLY}
            </Styled.ButtonStyled>
          )}
          {hook.hookButtonOptions.includes(MENU_CANCEL) && (
            <Styled.ButtonStyled onClick={hook.hookHandleCancel} disabled={isReadOnly}>
              {PROGRAM_DETAILS.BUTTON.CANCEL_MEMBERSHIP}
            </Styled.ButtonStyled>
          )}
        </Styled.ButtonWrapperStyled>
      </Styled.HeaderStyled>

      <Styled.MerchantStyled>
        <Styled.MerchantImgStyled src={hook.hookMerchant.companyImgUrl || imageList.publisherProfilePlaceholder.src} />
        <div>
          <Styled.MerchantNameStyled>{hook.hookMerchant.companyName}</Styled.MerchantNameStyled>
          <Styled.MerchantLinkStyled href={hook.hookMerchant.companyUrl} target="_blank">
            {hook.hookMerchant.companyUrl}
          </Styled.MerchantLinkStyled>
        </div>
      </Styled.MerchantStyled>

      <Tabs
        tabNames={
          hook.hookIsMember ? ['Overview', 'Terms and Conditions', 'Products'] : ['Overview', 'Terms and Conditions']
        }
        elements={[
          <OverviewTab hook={hook} key="OverviewTab" />,
          <TermsConditionsTab hook={hook} key="TermsConditionsTab" />,
          <ProductsTab hook={hook} key="ProductsTab" />,
        ]}
      />
      <ModalComponent
        isOpen={hook.hookCancelOpen}
        title={PROGRAM_DETAILS.CANCEL_MODAL.CANCEL_MEMBERSHIP}
        Description1={PROGRAM_DETAILS.CANCEL_MODAL.DESCRIPTION1}
        Description2={PROGRAM_DETAILS.CANCEL_MODAL.DESCRIPTION2}
        ButtonName={PROGRAM_DETAILS.CANCEL_MODAL.CANCEL_MEMBERSHIP}
        isDecline={false}
        onCancel={hook.hookHandleCancel}
        onConfirm={hook.hookCancelConfirm}
        merchantName={hook.hookProgram?.merchant?.companyName}
        loading={hook.hookCancelLoading}
      />
      <ModalComponent
        isOpen={hook.hookDecline}
        title={PROGRAM_DETAILS.DECLINE_MODAL.DECLINE}
        Description1={PROGRAM_DETAILS.DECLINE_MODAL.DESCRIPTION1}
        Description2={PROGRAM_DETAILS.DECLINE_MODAL.DESCRIPTION2}
        ButtonName={PROGRAM_DETAILS.DECLINE_MODAL.DEC_BUTTON}
        isDecline
        onCancel={hook.hookHandleDecline}
        onConfirm={hook.hookConfirmDecline}
        merchantName={hook.hookProgram?.merchant?.companyName}
        loading={hook.hookDeclineLoading}
      />

      <ModalComponent
        isOpen={hook.hookAcceptOpen}
        title={PROGRAM_DETAILS.ACCEPT_MODAL.ACCEPT}
        Description1={PROGRAM_DETAILS.ACCEPT_MODAL.DESCRIPTION1}
        Description2={PROGRAM_DETAILS.ACCEPT_MODAL.DESCRIPTION2}
        ButtonName={PROGRAM_DETAILS.ACCEPT_MODAL.ACCEPT_BUT}
        isDecline={false}
        onCancel={hook.hookHandleAccept}
        onConfirm={hook.hookAcceptConfirm}
        merchantName={hook.hookProgram?.merchant?.companyName}
        loading={hook.hookAcceptLoading}
      />
    </AppWrapper>
  );
};
export default ProgramDetails;
