import styled from 'styled-components';

import { Button, InputText, InputTextarea } from '../../../../components';

export const WrapperStyled = styled.div`
  padding-bottom: 100px;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
`;

export const HeaderButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const EditSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputTextStyled = styled(InputText)`
  margin-bottom: 2rem;
`;

export const InputTextareaStyled = styled(InputTextarea)`
  margin-bottom: 2rem;
`;
