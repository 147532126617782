/* eslint-disable */
import { ApolloError } from '@apollo/client';
import { ActorRefFrom, AnyEventObject, SnapshotFrom } from 'xstate';
import { CustomTextType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddCustomTextComponent';
import { EligibilityEnum } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/enums';
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';

import { RuleStatusSettingsType } from './components/RuleStatusSettingsComponent/enums';
import _ from 'lodash';

export interface IEvaluationRuleHook<T> {
  (
    stateMachine: SnapshotFrom<typeof machine>,
    send: (event: AnyEventObject) => void,
    refreshRuleManager: () => void
  ): T;
}

export type UseAddEvaluationRuleReturnType = {
  stateMachine: SnapshotFrom<typeof machine>;
  send: (event: AnyEventObject) => void;
  hookCancelOpen: boolean;
  selectedProduct: AddEvaluationRuleProductType | undefined;
  hookCancelButtonHandler: (state: boolean) => void;
  errorMessage: string;
  changeRuleName: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  ruleName: string;
  hookRuleNameError: string;
  productCategoryList: SelectOption[];
  changeSelectedProductCategory: (newSelectedProductCategory: SelectOption) => void;
  getProductDataLoading: boolean;
  availableProducts: SelectOption[];
  changeSelectedProduct: (newSelectedProduct: SelectOption) => void;
  selectedEligibility: EligibilityEnum;
  setSelectedEligibility: React.Dispatch<React.SetStateAction<EligibilityEnum>>;
  selectedCriteria: SelectedProductCriteriaType[];
  eligibilityProductFeedList: SelectOption[];
  criteriaProductFeedList: SelectOption[];
  selectedProductFeed: SelectOption | undefined;
  changeSelectedProductFeed: (changeEvent: SelectOption) => void;
  productEligibilityCustomText: string;
  changeProductEligibilityCustomText: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurCheck: (isCriteria?: boolean) => void;
  hookExitAddRuleModalHandler: () => void;
  checkRuleGroupsList: SelectOption[];
  changeSelectedRuleGroup: (newRuleGroup: SelectOption) => void;
  ruleTypeOptions: SelectOption[];
  changeSelectedRuleType: (newRuleType: SelectOption) => void;
  changeBrandNameType: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  changeBrandName: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  brandNameValue: string;
  existingBrandName: string;
  eligibilityCustomTextList: CustomTextType[];
  addNewCustomText: (isCriteria?: boolean) => void;
  updateCustomTextValue: (
    id: number,
    value: React.ChangeEvent<HTMLInputElement> & { nativeEvent: { inputType?: string } },
    isCriteria?: boolean
  ) => void;
  removeCustomTextById: (id: number, isCriteria?: boolean) => void;
  canAddMoreCustomText: boolean;
  textEligibilityErrors: IndexedObject<string>;
  changeProductEligibility: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;
  ruleStatusSettings: RuleStatusSettingsType;
  setRuleStatusSettings: React.Dispatch<React.SetStateAction<RuleStatusSettingsType>>;
  changeRuleStatusSettings: (newRuleStatusSettings: RuleStatusSettingsType) => void;
  ruleStatusErrors: IndexedObject<string>;
  criteriaCustomTextList: CustomTextType[];
  textCriteriaErrors: IndexedObject<string>;
  canAddMoreCriteriaCustomText: boolean;
  changeProductCriteria: (index: number, label: string, value: string, required: boolean) => void;
  appendCriteria: () => void;
  removeCriteria: (index: number) => void;
  createRule: () => Promise<void>;
  createRuleError: string;
};

export type UseEditEvaluationRuleHook = {
  stateMachine: SnapshotFrom<typeof machine>;
  send: (event: AnyEventObject) => void;
  errorMessage: string;
  existingBrandName: string;
  checkRuleGroupsList: SelectOption[];
  statusOptions: SelectOption[];
  changeSelectedRuleGroup: (newRuleGroup: SelectOption) => void;
  changeSelectedRuleStatus: (newRuleStatus: SelectOption) => void;
  isConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateEvaluationRule: () => Promise<void>;
  editRuleError: string;
  editRuleByIdLoading: boolean;
};

export type UseFintelRuleManagerHook = {
  stateMachine: SnapshotFrom<typeof machine>;
  send: (event: AnyEventObject) => void;
  actorRef: ActorRefFrom<typeof machine>;
  dropdownsLoading: boolean;
  dropdownsError: ApolloError | undefined;
  ruleManagerLoading: boolean;
  errorMessage: string;
  loadingMessage: string;
  tableData: CheckRulesOutputType[];
  totalPages: number;
  page: number;
  totalValues: number;
  sortColumn: TableSortColumn;
  tableLoading: boolean;
  changePage: (pageValue: number) => void;
  sortByHandler: (dataColumn: string, direction: 'asc' | 'desc' | undefined) => void;
  isDetailsModalOpen: boolean;
  setIsDetailsModalOpenHandler: (state: boolean) => void;
  modalRuleId: CheckRulesOutputType | undefined;
  setModalRuleIdHandler: ({ rule }: Record<string, CheckRulesOutputType>) => void;
  selectedProductCategory: SelectOption;
  selectedProductName: SelectOption;
  selectedStatus: SelectOption;
  selectedRule: SelectOption;
  selectedRecordsAmount: SelectOption;
  setSelectedProductCategory: (productCategorySelected: SelectOption) => void;
  setSelectedProductName: (productNameSelected: SelectOption) => void;
  setSelectedStatus: (statusSelected: SelectOption) => void;
  setSelectedRule: (ruleSelected: SelectOption) => void;
  setSelectedRecordsAmount: (statusSelected: SelectOption) => void;
  selectedRuleTypeHandler: (ruleTypeSelected: SelectOption) => void;
  selectedRuleGroupHandler: (ruleGroupSelected: SelectOption) => void;
  resetDropdowns: () => void;
  productCategoriesOptions: SelectOption[];
  productNameOptions: SelectOption[];
  statusOptions: SelectOption[];
  ruleNameOptions: SelectOption[];
  recordsAmountOptions: SelectOption[];
  isAddRuleModalOpen: boolean;
  setIsAddRuleModalOpen: (state: boolean) => void;
  refreshRuleManager: () => Promise<void>;
  isAddMonitoringRuleModalOpen: boolean;
  setIsAddMonitoringRuleModalOpen: (state: boolean) => void;
  handleDelete: ({ id }: { id: number }) => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (state: boolean) => void;
  deletePostback: () => Promise<void>;
  isRuleGroupModalOpen: boolean;
  setIsRuleGroupModalOpen: (state: boolean) => void;
  groupName: string;
  setGroupName: (name: string) => void;
  createRuleGroup: () => Promise<void>;
  addRuleGroupLoading: boolean;
  handleEdit: (selected: Rule) => void;
  saveEditRule: (editedRule: Rule, initialRuleState: Rule) => Promise<void>;
  saveRuleLoading: boolean;
  deactivateRule: (ruleId: number) => Promise<void>;
  defaultRuleTypesOptions: SelectOption[];
  selectedRuleType: SelectOption;
  ruleGroupOptions: SelectOption[];
  selectedRuleGroup: SelectOption;
  ruleStatusSettings: RuleStatusSettingsType;
  setRuleStatusSettings: (settings: RuleStatusSettingsType) => void;
  isReadOnlyList: boolean[];
  downloadMonitoringReportCSV: () => void;
};

export type AddEvaluationRuleProductType = {
  id: string;
  customizedProductId: string;
  name: string;
  productCategory: string;
  validRuleRequirements: {
    name: string;
    value?: string;
    values?: { name: string; description: string }[];
  }[];
  productFeedSection: ProductFeedSectionType;
};

export type ProductFeedSectionType = Record<string, string | LegalReferenceItemsType>;

export type LegalReferenceItemsType = {
  name: string;
  description: string;
}[];

export type EligibilityType = {
  type: string;
  value: string;
};

export type CheckRulesOutputType = {
  id: number;
  merchantId: string;
  applicableProducts: string; // is "No Specific Products" or "All Products" or a custom string
  productCategory: string; // goes in catergory field in table
  productId: string;
  customizedProductId: string; // prepend value to product name field seperated with -
  name: string; // needed if applicable products are not All Products or No Specific Products
  nominatedField: string; // displayed in modal under nominated field
  ruleName: string; // Displayed in rule name column
  customText: {
    // No idea what this is for no data in database has any
    id: number | null;
    customText: string;
    required: boolean;
  }[];
  productFeed: {
    // show details modal Parameters for the rule to check
    id: number | null;
    productFeed: string; // Gets displayed after being transformed eg. from testCase to Test Case. 2 special cases in old code
    productFeedData: string; // If not any product displayed in 'Data Associated with product feed
    required: boolean; // show a checked displayed checkbox if true
  }[];
  status: string; // status column if not active field is greyed out and checkbox set to true and disabled
  startDate: string; // goes start/end date column convert to date
  endDate: string | null; // left blank if null
  lastRun: string; // last run column
  reviewCount: number | null; // Number beside the warning triangle
  failCount: number | null; // Number beside the red circle
  nfCount: number | null; // Don't know what this is
  checked?: boolean; // added after by frontend
  fieldChecks?: {
    fieldName: string;
    expectedValue: string;
  }[];
};

export type MerchantRuleInfoOutput = {
  categories: SelectOption[];
  products: SelectOption[];
  rules: SelectOption[];
};

export type ProductFeed = {
  id: number;
  productFeed: string;
  productFeedData: string;
  required: boolean;
};

export type Rule = {
  status: string;
  applicableProducts: string;
  productName: string;
  startDate: string;
  customizedProductId: string;
  endDate: string | null;
  failCount: string | null;
  id: number;
  lastRun: string;
  eligibility: EligibilityType[];
  merchantId: string;
  nfCount: null;
  productCategory: string;
  productFeed: ProductFeed[];
  productId: string;
  reviewCount: number | null;
  brandName: string;
  checked: boolean;
  ruleName: string;
  ruleGroup?: string;
  ruleType: string;
  customText: {
    id: number | null;
    customText: string;
    required: boolean;
  }[];
  fieldChecks: {
    expectedValue: string;
    fieldName: string;
  }[];
  ruleStatusSettings: RuleStatusSettingsType;
};

export type CheckMonitoringRulesOutputType = {
  id: number;
  merchantId: string;
  customTextList: string[] | undefined;
  criteriaCustomTextList?: string[];
  ruleGroup: string;
  ruleName: string;
  ruleStatusSettings: RuleStatusSettingsType;
};

export type SelectedProductCriteriaType = {
  label: string;
  name: string;
  value: string;
  required: boolean;
};

export type IndexedObject<T> = {
  [key: number | string]: T;
};

/* Utils functions - to avoid dependecy cycle */
// Function from fintel-app for transforming productFeed Names to display
export const humanizeProductFeed = (str: string): string => {
  if (!str) return '';
  const parts = str.split(':');
  if (parts.length > 1 && parts[0] === 'legalReferenceItems') {
    if (parts[1]) return `Legal Reference: ${parts[1]}`;
    return 'Legal Reference Item';
  }
  if (parts[0] === 'APY' || parts[0] === 'apyRate') return 'APY';
  if (parts[0] === 'APR' || parts[0] === 'mortgageAprRate') return 'APR';
  return parts[0]
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const getFeedValue = (criteria: string, feed: ProductFeedSectionType | undefined): string => {
  let feedValue;
  if (!feed) return criteria;
  const feedItem = criteria.split(':');
  if (feedItem.length > 1) {
    feedValue = _.find(feed.legalReferenceItems as LegalReferenceItemsType, {
      name: feedItem[1],
    })?.description;
  } else {
    feedValue = feed[criteria] as string;
  }
  return feedValue || '';
};