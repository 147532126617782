import React from 'react';

import { CellWraper, IconCell } from './Components';

export const columns: TableColumn[] = [
  {
    dataField: 'groupName',
    text: 'Group Name',
    width: '20%',
    formatter: (val) => <CellWraper val={val} />,
  },
  {
    dataField: 'description',
    text: 'Description',
    width: '55%',
    formatter: (val) => <CellWraper val={val} ellipsis />,
  },
  {
    dataField: 'members',
    text: 'Members',
    width: '6%',
    formatter: (val) => <CellWraper val={val} />,
  },
  {
    dataField: 'date',
    text: 'Created Date',
    width: '15%',
    formatter: (val) => <CellWraper val={val} />,
  },
  {
    dataField: 'id',
    text: '',
    width: '4%',
    formatter: (val) => <IconCell val={val} />,
  },
];
