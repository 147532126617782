import React from 'react';

import { AppWrapper, Button, ErrorBanner } from '../../../../components';
import { path } from '../../../../utils';
import { DefaultPropsType } from '../../../../types';

import { CREATE_PUBLISHER_GROUP } from './enum';
import { useCreatePublisherGroup } from './hooks/useCreatePublisherGroup';
import * as Styled from './styles';

type CreatePublisherGroupProps = DefaultPropsType;

const CreatePublisherGroup = ({ permissionsCodeList = [] }: CreatePublisherGroupProps) => {
  const { hookIsReadOnlyList, ...hook } = useCreatePublisherGroup(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.PageTitleStyled isLoading={hook.hookLoading} loadingText="Creating this publisher group">
        {path.addPublisherGroup.name}
      </Styled.PageTitleStyled>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={hook.hookErrorMessage !== ''} />

      <Styled.InputTextStyled
        type="text"
        label={CREATE_PUBLISHER_GROUP.INPUT.GROUP_NAME}
        placeholder={CREATE_PUBLISHER_GROUP.INPUT.GROUP_NAME}
        error={hook.hookInputErrors.groupName}
        value={hook.hookName}
        onChange={hook.hookSetName}
        required
        disabled={isReadOnly}
      />

      <Styled.InputTextareaStyled
        name="description"
        value={hook.hookDescription}
        onChange={hook.hookSetDescription}
        placeholder={CREATE_PUBLISHER_GROUP.INPUT.TEXT_AREA.PLACEHOLDER}
        label={CREATE_PUBLISHER_GROUP.INPUT.TEXT_AREA.LABEL}
        error={hook.hookInputErrors.description}
        required
        disabled={isReadOnly}
      />

      <Styled.ButtonAreaStyled>
        <Button theme="secondary" onClick={hook.hookGoBack}>
          {CREATE_PUBLISHER_GROUP.BUTTON.CANCEL}
        </Button>
        <Styled.ButtonStyled onClick={hook.hookSaveHandler} disabled={isReadOnly} loading={hook.hookLoading}>
          {CREATE_PUBLISHER_GROUP.BUTTON.ADD_GROUP}
        </Styled.ButtonStyled>
      </Styled.ButtonAreaStyled>
    </AppWrapper>
  );
};

export default CreatePublisherGroup;
