import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { MerchantData } from '../../../../components/StatisticCard/types';
import { SnapshotStructureProps } from '../components/Snapshot/types';
import { ProductsApprovalStructure } from '../components/ProductByApproval/types';
import { UPDATE_PROFILE } from '../enums';
import {
  GET_ANNOUNCEMENTS_BY_DAY,
  GET_MERCHANT_INFO,
  GET_MESSAGE_COUNT,
  GET_PRODUCTS,
  GET_PUBLISHER_OVERVIEW,
  GET_PUBLISHER_PERFORMANCES,
  GET_SNAPSHOT,
  GET_PUBLISHER_MEMBERSHIP_INVITES,
} from '../graphql/queries';
import { useUserInfo } from '../../../../hooks';
import { USER_TYPES_ID, rangeFormat, toUTCHours } from '../../../../utils';
import environment from '../../../../config/environment';

import { useRenderCards } from './useRenderCards';

type NewMerchantProps = {
  logo: string;
  productCategories: string;
  country: string;
  action: string;
};
const InitialStatistics: MerchantData = {
  currentPerformance: 0,
  prevPerformance: 0,
  todaysPerformance: 0,
  performanceIncrease: 0,
};

const InitalSnapshot: SnapshotStructureProps = {
  totalTransactions: [0],
  approvedTransactions: [0],
  commissions: [0],
};

const InitialProductApproval: ProductsApprovalStructure = {
  category: 'category',
  value: 0,
};

const updateProfileData = {
  title: UPDATE_PROFILE.TITLE,
  tooltip: UPDATE_PROFILE.TOOLTIP,
  description: UPDATE_PROFILE.DESCRIPTION,
  button: UPDATE_PROFILE.BUTTON_TEXT,
  link: UPDATE_PROFILE.LINK,
};

const checkOverviewComplete = (overview: any) => {
  if (
    overview?.businessDesc &&
    (!overview?.hasWebsite || (overview?.hasWebsite && overview?.primaryPlatformLink)) &&
    overview?.audienceMarkets.length !== 0 &&
    overview?.avgMonthlyAudience &&
    overview?.productCategories.length !== 0 &&
    overview?.promotionMethods.length !== 0
  ) {
    return true;
  }
  return false;
};

export const useDashboard = () => {
  const hook = useRenderCards();
  const navigate = useNavigate();
  const { hookWhoAmI, hookUserInfo } = useUserInfo();

  // Merchants Filter
  const [merchant, setMerchant] = useState<SelectOption>({ label: 'All Merchants', value: '' });
  const [merchantList, setMerchantList] = useState<SelectOption[]>([{ label: 'All Merchants', value: '' }]);

  // Calendar States
  const today = new Date();
  const defaultRange = rangeFormat('last30Days');
  const defaultStartDate = defaultRange.start;
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(today);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [range, setRange] = useState(`${startDate.toDateString()} - ${endDate.toDateString()}`);

  // Performance Statistics States
  const [commission, setCommission] = useState<MerchantData>(InitialStatistics);
  const [approvedTransactions, setApprovedTransactions] = useState<MerchantData>(InitialStatistics);
  const [epc, setEpc] = useState<MerchantData>(InitialStatistics);
  const [conversionRate, setConversionRate] = useState<MerchantData>(InitialStatistics);

  // Snapshot State
  const [snapshotData, setSnapshotData] = useState<SnapshotStructureProps>(InitalSnapshot);

  // Optional Alert/Announcement Cards States
  const [announcements, setAnnouncements] = useState('');
  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [invitations, setInvitations] = useState('');
  const [showInvitations, setShowInvitations] = useState(false);
  const [messageUpdates, setMessageUpdates] = useState('');
  const [showMessageUpdates, setShowMessageUpdates] = useState(false);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);

  // New Merchants Table State
  const [newMerchantsFormatted, setNewMerchantsFormatted] = useState<NewMerchantProps[]>([]);
  const [newMerchantsTable, setNewMerchantsTable] = useState<NewMerchantProps[]>([]);

  // Top Products Table & Products By Approvals Pie Chart State
  const [productApprovalData, setProductApprovalData] = useState<ProductsApprovalStructure[]>([InitialProductApproval]);
  const [topProducts, setTopProducts] = useState<any[]>([]);

  // Page State
  const [refetchData, setRefetchData] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Queries
  const [getMerchantInfo, { loading: merchantInfoLoading }] = useLazyQuery(GET_MERCHANT_INFO);
  const [getSnapshot, { loading: snapshotLoading }] = useLazyQuery(GET_SNAPSHOT);
  const [getProducts, { loading: productsLoading }] = useLazyQuery(GET_PRODUCTS);
  const [getPerformances, { loading: performancesLoading }] = useLazyQuery(GET_PUBLISHER_PERFORMANCES);
  const [getPublisherMembershipInvites] = useLazyQuery(GET_PUBLISHER_MEMBERSHIP_INVITES);
  const [getAnnouncements] = useLazyQuery(GET_ANNOUNCEMENTS_BY_DAY);
  const [getMessageCount] = useLazyQuery(GET_MESSAGE_COUNT);
  const [getPublisherOverview] = useLazyQuery(GET_PUBLISHER_OVERVIEW);

  const setNewMerchantTableData = (allData: NewMerchantProps[]) => {
    let tableRows = 7;
    if (showUpdateProfile) tableRows -= 2;
    if (showAnnouncements) tableRows -= 1;
    if (showInvitations) tableRows -= 1;
    if (showMessageUpdates) tableRows -= 1;
    setNewMerchantsTable(allData.slice(0, tableRows));
  };

  const getMerchantInfoHandler = async () => {
    const { data } = await getMerchantInfo({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.publisherDashboardMerchants !== undefined) {
      const formatedMerchantList = data?.publisherDashboardMerchants.merchantList.map((item: any) => ({
        label: `${item.id} - ${item.companyName}`,
        value: item.id,
      }));

      const formatedProgramList = data.publisherDashboardMerchants.newPrograms.map((item: any) => ({
        logo: item.merchant.companyImgUrl,
        productCategories: Array.isArray(item?.productCategories) ? item.productCategories.join(', ') : '',
        country: item.merchant.country,
        action: item.id,
      }));

      setMerchantList([{ label: 'All Merchants', value: '' }, ...formatedMerchantList]);
      setNewMerchantsFormatted(formatedProgramList);
      setNewMerchantTableData(formatedProgramList);
    }
  };

  const getSnapshotHandler = async () => {
    const { data } = await getSnapshot({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.publisherDashboardSnapshot !== undefined) {
      setSnapshotData(data.publisherDashboardSnapshot);
    }
  };

  const setCardsHandler = (publisherInfo: any) => {
    setAnnouncements(
      `You have ${publisherInfo.announcements} recent announcement${publisherInfo.announcements > 1 ? 's' : ''}`
    );
    if (publisherInfo.announcements > 0) {
      setShowAnnouncements(true);
    }

    setInvitations(`You have ${publisherInfo.invitations} new merchant invitations`);
    if (publisherInfo.invitations > 0) {
      setShowInvitations(true);
    }

    setMessageUpdates(`View your message updates (${publisherInfo.messageCount})`);
    if (publisherInfo.messageCount > 0) {
      setShowMessageUpdates(true);
    }
  };

  const getMessageInfoHandler = async () => {
    setErrorMessage('');
    const [{ data: inviteInfo }, { data: announcementInfo }, { data: messageInfo }, { data: publisherInfo }] =
      await Promise.all([
        getPublisherMembershipInvites({
          variables: { input: { publisherId: hookWhoAmI?.companyId || '0' } },
          fetchPolicy: 'no-cache',
          onError(error) {
            setErrorMessage(error.message);
          },
        }),
        getAnnouncements({
          variables: {
            announcementQuery: {
              companyId: Number(hookWhoAmI?.companyId || 0),
              days: 5,
            },
          },
          fetchPolicy: 'no-cache',
          onError(error) {
            setErrorMessage(error.message);
          },
        }),
        getMessageCount({
          variables: {
            messageQuery: {
              companyId: Number(hookWhoAmI?.companyId || 0),
              userType: 'Publisher',
              isDeleted: false,
              isNew: true,
              status: 'new',
            },
          },
          fetchPolicy: 'no-cache',
          onError(error) {
            setErrorMessage(error.message);
          },
        }),
        getPublisherOverview({
          variables: { companyId: hookWhoAmI?.companyId || '0' },
          fetchPolicy: 'no-cache',
          onError(error) {
            setErrorMessage(error.message);
          },
        }),
      ]);

    const cardInfo = {
      announcements: 0,
      invitations: 0,
      messageCount: 0,
    };

    if (inviteInfo?.membershipsPublisherInvite?.count !== undefined) {
      cardInfo.invitations = inviteInfo.membershipsPublisherInvite.count;
    }
    if (announcementInfo?.announcementsByDays?.size !== undefined) {
      cardInfo.announcements = announcementInfo.announcementsByDays.size;
    }
    if (messageInfo?.messages?.size !== undefined) {
      cardInfo.messageCount = messageInfo.messages.size;
    }
    setCardsHandler(cardInfo);

    if (publisherInfo?.company !== undefined) {
      setShowUpdateProfile(!checkOverviewComplete(publisherInfo.company.overview));
    }
  };

  const getProductsHandler = async () => {
    setErrorMessage('');
    const { data } = await getProducts({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId?.toString(),
          startDate: toUTCHours(startDate, 'beginning'),
          endDate: toUTCHours(endDate, 'end'),
          ...(merchant.value && { merchantId: merchant.value }),
        },
      },
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data && data.publisherDashboardProducts) {
      if (data.publisherDashboardProducts.topProducts) setTopProducts(data.publisherDashboardProducts.topProducts);
      else setTopProducts([]);
      if (data.publisherDashboardProducts.productByApproval)
        setProductApprovalData(data.publisherDashboardProducts.productByApproval);
      else setProductApprovalData([]);
    } else {
      setTopProducts([]);
      setProductApprovalData([]);
    }
  };

  const setStatisticsHandler = (publisherInfo: any) => {
    setCommission(publisherInfo.commission);
    setApprovedTransactions(publisherInfo.approvedTransactions);
    setEpc(publisherInfo.EPC);
    // FC-513: Conversion Rate is not being calculated correctly by a factor of 100.
    setConversionRate({
      ...publisherInfo.conversionRate,
      currentPerformance: (publisherInfo.conversionRate.currentPerformance * 100).toFixed(2),
      prevPerformance: (publisherInfo.conversionRate.prevPerformance * 100).toFixed(2),
      todaysPerformance: (publisherInfo.conversionRate.todaysPerformance * 100).toFixed(2),
    });
  };

  const getPerformancesHandler = async () => {
    setErrorMessage('');
    const stDate = toUTCHours(startDate, 'beginning');
    const edDate = toUTCHours(endDate, 'end');

    const { data } = await getPerformances({
      variables: {
        input: {
          startDate: stDate,
          endDate: edDate,
          ...(merchant.value && { merchantId: merchant.value }),
          publisherId: hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    if (data && data.publisherDashboardPerformances) {
      setStatisticsHandler(data.publisherDashboardPerformances);
    } else {
      setStatisticsHandler({
        commission: InitialStatistics,
        approvedTransactions: InitialStatistics,
        EPC: InitialStatistics,
        conversionRate: InitialStatistics,
      });
    }
  };

  const setMerchantHandler = (value: SelectOption) => {
    setMerchant(value);
    setRefetchData(true);
  };

  const setCalendarOpenHandler = () => {
    setCalendarOpen(!calendarOpen);
  };

  const closeCalendarHandler = () => {
    setCalendarOpen(false);
  };

  const setDateRangeHandler = (firstDate: Date, lastDate: Date | undefined) => {
    setStartDate(firstDate);
    if (lastDate) {
      setEndDate(lastDate);
      setRange(`${firstDate.toDateString()} - ${lastDate.toDateString()}`);
    }
    closeCalendarHandler();
    setRefetchData(true);
  };

  const formatDomain = (suffix: string) => {
    let domain = '';
    switch (environment.app.environment) {
      case 'localDev':
      case 'development':
      case 'dev':
        domain = `dev${suffix}`;
        break;
      case 'staging':
      case 'demo':
        domain = `${environment.app.environment}${suffix}`;
        break;
      // prod
      default:
        break;
    }
    return domain;
  };

  const addMessageHubLink = (urlPath: string) => {
    const domain = formatDomain('.');
    // const userType = getTypeFromCookie();
    // const userType = 'admin';
    if (hookUserInfo?.userTypesId === USER_TYPES_ID.ADMIN) {
      return `https://support.${domain}app.fintelconnect.com/perspective/${hookWhoAmI.companyId}/?redirect=${urlPath}`;
    }
    return `https://support.${domain}app.fintelconnect.com/${urlPath}`;
  };

  if (refetchData) {
    setRefetchData(false);
    getProductsHandler();
    getPerformancesHandler();
  }

  useEffect(() => {
    setNewMerchantTableData(newMerchantsFormatted);
  }, [showAnnouncements, showInvitations, showUpdateProfile, showMessageUpdates]);

  useEffect(() => {
    getMerchantInfoHandler();
    getProductsHandler();
    getPerformancesHandler();
    getMessageInfoHandler();
    getSnapshotHandler();
  }, []);

  return {
    // Global Values
    hookNavigate: navigate,
    hookErrorMessage: errorMessage,

    // Calendar
    hookStartDate: startDate,
    hookEndDate: endDate,
    hookDateRange: range,
    hookCalendarOpen: calendarOpen,
    hookOpenCalendar: setCalendarOpenHandler,
    hookCloseCalendar: closeCalendarHandler,
    hookSetDateRange: setDateRangeHandler,

    // Merchant Filter
    hookMerchant: merchant,
    hookMerchantList: merchantList,
    hookSetMerchant: setMerchantHandler,

    // Performance Statistics Cards
    hookCommissions: commission,
    hookApprovedTransactions: approvedTransactions,
    hookEPC: epc,
    hookConversionRate: conversionRate,
    hookPerformancesLoading: performancesLoading,

    // Snapshot Chart
    hookSnapshotData: snapshotData,
    hookSnapshotLoading: snapshotLoading,

    // Optional Alert/Announcement Cards
    hookDisplayCard: hook.hookDisplayCard,
    hookAnnouncements: announcements,
    hookShowAnnouncements: showAnnouncements,
    hookInvitations: invitations,
    hookShowInvitations: showInvitations,
    hookMessageUpdates: messageUpdates,
    hookShowMessageUpdates: showMessageUpdates,
    hookAddMessageHubLink: addMessageHubLink,
    // Optional Update Profile Card
    hookDisplayUpdateProfile: hook.hookDisplayUpdateCard,
    hookShowUpdateProfile: showUpdateProfile,
    hookUpdateProfileData: updateProfileData,

    // New Merchants Info
    hookMerchantData: newMerchantsTable,
    hookMerchantInfoLoading: merchantInfoLoading,

    // Top Products Table & Products By Approvals Pie Chart
    hookTopProducts: topProducts,
    hookProductByApproval: productApprovalData,
    hookProductsLoading: productsLoading,
  };
};
