import React, { useMemo } from 'react';
import { faGrip, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper,
  Button,
  ErrorBanner,
  Modal,
  PageTitle,
  Pagination,
  ReportPlaceholder,
  Table,
} from '../../../../components';
import { RECORDS_PER_PAGE_OPTIONS, path } from '../../../../utils';
import { DefaultPropsType } from '../../../../types';

import { BUTTON_LABEL, INPUT_LABEL, TITLE_SUFFIX } from './contracts';
import { usePublisherSearch } from './hooks';
import {
  AdvancedSearchFilter,
  BasicSearchFilter,
  CustomizeColumnModel,
  SearchPublisherAddRemoveModal,
  SearchPublisherPageFooter,
} from './components';
import * as Styled from './styles';

type PublisherSearchProps = DefaultPropsType;

const PublisherSearch = ({ permissionsCodeList = [] }: PublisherSearchProps) => {
  const { hookIsReadOnlyList, ...hook } = usePublisherSearch(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
      <Styled.WrapperStyled>
        <PageTitle isLoading={hook.hookIsLoading}>{`${path.publisherSearch.name} ${TITLE_SUFFIX}`}</PageTitle>

        <BasicSearchFilter
          advancedFilterIsOpen={hook.hookAdvancedFilterIsOpen}
          setAdvancedFilterIsOpen={hook.hookSetAdvancedFilterIsOpen}
          searchBar={hook.hookSearch}
          setSearchBar={hook.hookSetSearch}
          publisherGroupOptions={hook.hookPublisherGroupOptions}
          clearFormHandler={hook.hookClearFilter}
          filter={hook.hookFilter}
          setFilter={hook.hookHandleFilterChange}
          isLoading={hook.hookIsLoading}
        />
        {useMemo(
          () => (
            <AdvancedSearchFilter
              filterIsOpen={hook.hookAdvancedFilterIsOpen}
              ageOptions={hook.hookAgeOptions}
              genderOptions={hook.hookGenderOptions}
              martketOptions={hook.hookMartketOptions}
              ratingOptions={hook.hookRatingOptions}
              filter={hook.hookFilter}
              setFilter={hook.hookHandleFilterChange}
              isLoading={hook.hookIsLoading}
            />
          ),
          [
            hook.hookAdvancedFilterIsOpen,
            hook.hookAgeOptions,
            hook.hookGenderOptions,
            hook.hookMartketOptions,
            hook.hookRatingOptions,
            hook.hookFilter,
          ]
        )}

        <Styled.FiltersStyled>
          <Styled.LeftSideStyled theme="secondary">
            <Styled.RecordsPerPageTextStyled>{INPUT_LABEL.RECORD}</Styled.RecordsPerPageTextStyled>
            <Styled.RecordsPerPageStyled
              name="pages"
              onChange={hook.hookSetRecordsPerPage}
              selected={hook.hookRecordsPerPage}
              options={RECORDS_PER_PAGE_OPTIONS}
            />
          </Styled.LeftSideStyled>

          <Styled.RightSideStyled theme="secondary">
            <Styled.RowStyled>
              <Button
                onClick={hook.hookSearchButtonHandler}
                disabled={hook.hookIsLoading}
                loading={hook.hookIsLoading}
                width="120px"
              >
                <FontAwesomeIcon icon={faSearch} style={{ marginRight: 5 }} /> Search
              </Button>
              <Styled.ButtonStyled theme="tertiary" onClick={hook.hookSetCustomizeColumnModal}>
                <FontAwesomeIcon icon={faGrip} />
                {BUTTON_LABEL.COLUMN}
              </Styled.ButtonStyled>
            </Styled.RowStyled>
          </Styled.RightSideStyled>
        </Styled.FiltersStyled>

        {((hook.hookPublishers && hook?.hookPublishers?.length > 0) ||
          (hook.hookIsLoading && hook.hookSearchClicked)) && (
          <>
            <Table
              columns={hook.hookTableColumns}
              data={hook.hookPublishers}
              cellAlignItems="flex-start"
              headerCheckBox={hook.hookHeaderCheckPublishers}
              handleHeaderCheckBox={hook.hookSetHeaderCheckPublishers}
              handleCheckBox={hook.hookSetSelectedPublisher}
              isLoading={hook.hookIsLoading}
              onRowClick={hook.hookNavigationHandler}
              onSort={hook.hookSetSortColumn}
              sortColumn={hook.hookSortColumn}
              loadWithData={hook.hookPublishers.length > 0}
            />
            <Pagination
              total={hook.hookTotalPages}
              currentPage={hook.hookCurrentPage}
              onPageChange={hook.hookSetCurrentPage}
            />
          </>
        )}

        {(!hook.hookPublishers || hook.hookPublishers.length < 1) &&
          !(hook.hookIsLoading && hook.hookSearchClicked) && (
            <ReportPlaceholder
              submitted={hook.hookSearchClicked}
              text={hook.hookSearchClicked ? 'Sorry! No results found' : 'Click search to get started'}
              subtext={hook.hookSearchClicked ? "We couldn't find what you're looking for. Please try it again." : ''}
            />
          )}
      </Styled.WrapperStyled>

      {useMemo(
        () => (
          <SearchPublisherPageFooter
            groupOptions={hook.hookPublisherGroupOptions}
            selectPublisherCount={hook.hookSelectedPublishers.length}
            selectedPublisherGroup={hook.hookSelectedPublisherGroupFooter}
            setSelectedPublisherGroup={hook.hookSetSelectedPublisherGroupFooter}
            buttonHandler={hook.hookFooterButtonHandler}
            isReadOnly={isReadOnly}
          />
        ),
        [hook.hookPublisherGroupOptions, hook.hookSelectedPublishers, hook.hookSelectedPublisherGroupFooter]
      )}

      {useMemo(
        () => (
          <Modal isOpen={hook.hookCustomizeColumnModal}>
            <CustomizeColumnModel
              selectedColumns={hook.hookCustomizeColumns}
              cancelHandler={hook.hookSetCustomizeColumnModal}
              columnCustomizeHandler={hook.hookSetCustomizeColumns}
            />
          </Modal>
        ),
        [hook.hookCustomizeColumnModal, hook.hookCustomizeColumns]
      )}

      {hook.hookModalType && (
        <Modal isOpen={hook.hookAddRemoveModal}>
          <SearchPublisherAddRemoveModal
            modalType={hook.hookModalType}
            groupName={hook.hookSelectedPublisherGroupFooter?.label || ''}
            selectedPublisherCount={hook.hookSelectedPublishers.length}
            cancelHandler={hook.hookSetAddRemoveModal}
            submitHandler={hook.hookAddRemoveSubmit}
          />
        </Modal>
      )}
    </AppWrapper>
  );
};

export default PublisherSearch;
