import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageTitle, Modal } from '../../../../../components';
import * as Styled from '../styles';
import { RULE_MANAGER } from '../enums';
import { useFintelRuleManager } from '../hooks';

const AddRuleGroupModal = ({ hook }: { hook: ReturnType<typeof useFintelRuleManager> }) => (
  <Modal isOpen={hook.isRuleGroupModalOpen}>
    <Styled.ModalWrapperStyled>
      <Styled.TopStyled>
        <PageTitle>{RULE_MANAGER.ADD_RULE_GROUP_TITLE}</PageTitle>

        <Styled.CloseButtonStyled
          theme="secondary"
          onClick={() => {
            hook.setIsRuleGroupModalOpen(false);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Styled.CloseButtonStyled>
      </Styled.TopStyled>

      <Styled.ContentStyled>
        <Styled.DescriptionStyled>{RULE_MANAGER.ADD_RULE_GROUP_DESCRIPTION}</Styled.DescriptionStyled>
        <Styled.InputStyled
          type="text"
          required
          label={RULE_MANAGER.ADD_RULE_GROUP_INPUT_LABEL}
          placeholder={RULE_MANAGER.ADD_RULE_GROUP_INPUT_PLACEHOLDER}
          value={hook.groupName}
          onChange={(e) => {
            hook.setGroupName(e.target.value);
          }}
        />
      </Styled.ContentStyled>
      <Styled.FooterStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => {
            hook.setIsRuleGroupModalOpen(false);
          }}
        >
          {RULE_MANAGER.RULE_DELETE_CANCEL}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          theme="primary"
          disabled={!hook.groupName}
          loading={hook.addRuleGroupLoading}
          onClick={() => {
            hook.createRuleGroup();
          }}
        >
          {RULE_MANAGER.ADD_RULE_GROUP_SUBMIT_BUTTON}
        </Styled.ButtonStyled>
      </Styled.FooterStyled>
    </Styled.ModalWrapperStyled>
  </Modal>
);

export default AddRuleGroupModal;
