import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import { Button, Modal, Pagination, Placeholder, Table } from '../../../../../../components';
import { MERCHANT_PREFIX, path } from '../../../../../../utils';
import { templateColumns } from '../../contracts';
import DeleteTemplateModal from '../../../InvitationTemplate/Modal';

import * as Styled from './styles';
import { INVITATION_TEMPLATE } from './enum';

type InvitationTemplateTabProps = {
  modalOpen: boolean;
  templateId: string;
  templateName: string;
  deleteTemplate: (id: string) => void;
  closeModal: () => void;
  templateList: any[];
  getTemplateList: () => void;
  sortBy: TableSortColumn;
  sortHandler: (dataField: string, direction: 'asc' | 'desc' | undefined) => void;
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  totalPages: number;
  templateAction: (row: any, actionType: string) => void;
  isReadOnly: boolean;
};

const InvitationTemplateTab = ({
  modalOpen,
  templateId,
  templateName,
  deleteTemplate,
  closeModal,
  templateList,
  getTemplateList,
  sortBy,
  sortHandler,
  currentPage,
  setCurrentPage,
  totalPages,
  templateAction,
  isReadOnly,
}: InvitationTemplateTabProps) => {
  const navigate = useNavigate();

  const goToNewTemplate = () => {
    navigate(`${MERCHANT_PREFIX}${path.invitationTemplate.href}`);
  };

  useEffect(() => {
    if (templateList.length > 0) return;
    getTemplateList();
  }, []);

  return (
    <Styled.WrapperStyled>
      <Styled.DescriptionStyled>
        {INVITATION_TEMPLATE.DESC.ONE}
        <br />
        {INVITATION_TEMPLATE.DESC.TWO}
        <Button onClick={goToNewTemplate} disabled={isReadOnly}>
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
          {INVITATION_TEMPLATE.BUTTON.NEW_TEMPLATE}
        </Button>
      </Styled.DescriptionStyled>

      {(!templateList || templateList.length < 1) && (
        <Placeholder
          title={INVITATION_TEMPLATE.PLACEHOLDER.TITLE}
          subtitle={INVITATION_TEMPLATE.PLACEHOLDER.SUBTITLE}
          buttonText={INVITATION_TEMPLATE.BUTTON.NEW_TEMPLATE}
          buttonIcon={faPlus}
          action={goToNewTemplate}
          isReadOnly={isReadOnly}
        />
      )}

      {templateList && templateList.length > 0 && (
        <>
          <Table
            columns={templateColumns(isReadOnly)}
            data={templateList}
            sortColumn={sortBy}
            onSort={sortHandler}
            handleCheckBox={templateAction}
          />
          <Pagination total={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
        </>
      )}
      <Modal isOpen={modalOpen}>
        <DeleteTemplateModal id={templateId} name={templateName} deleteTemplate={deleteTemplate} closeFn={closeModal} />
      </Modal>
    </Styled.WrapperStyled>
  );
};

export default InvitationTemplateTab;
