import { gql } from '@apollo/client';

export const UPDATE_TEMPLATE = gql`
  mutation updatePublisherInvitation($input: PublisherInvitationInput!) {
    updatePublisherInvitation(input: $input) {
      id
      merchantId
      name
      description
      subject
      message
      template
    }
  }
`;
