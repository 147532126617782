import { useContext, useRef } from 'react';

import { context } from '../context/notificationsContext';

export const useNotifications = () => {
  const { notifications, numberOfUnread, updateNotificationHandler } = useContext(context);
  const dropdownRef = useRef();

  return {
    hookNotifications: notifications || [],
    hookNumberOfUnread: numberOfUnread,
    hookDropdownRef: dropdownRef,
    hookUpdateNotificationHandler: updateNotificationHandler,
  };
};
