import styled from 'styled-components';

import { colors } from '../../styles/theme';

type WrapperProps = {
  readonly isOpen: boolean;
  readonly isLarge: boolean;
};

export const ContentStyled = styled.div`
  background-color: ${colors.color4};
  padding: 2rem;
  transition: all 0.3s ease-in-out;
  max-width: 900px;
  width: fit-content;
  height: max-content;
`;

export const WrapperStyled = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: ${({ isLarge }) => (isLarge ? 'flex-start' : 'center')};
  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  overflow-y: auto;
  padding: 2rem;

  ${ContentStyled} {
    margin-top: ${({ isOpen }) => (isOpen ? '0' : '-50px')};
  }
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
