import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { useToast, useUpload, useUserInfo } from 'hooks';
import { SUCCESS_MESSAGES } from 'utils';
import { useValidation } from 'utils/validation';

import { arrayValidationfields, validationfields } from '../contracts';
import { GET_COMPANY } from '../graphql/queries/getCompany';
import { UPDATE_COMPANY } from '../graphql/mutations/updateCompany';
import { Permission } from '../../../../entities';

type ImageFile = {
  file?: File;
  height: number;
  width: number;
};

export const usePublisherOverview = (permissionsCodeList: string[] = []) => {
  const userHook = useUserInfo();
  const validtionHook = useValidation();
  const { hookShowToast } = useToast();

  const upload = useUpload();

  const [publisherDescription, setPublisherDescription] = useState('');
  const [platformLink, setPlatformLink] = useState('');
  const [hasWebsite, setHasWebsite] = useState(false);
  const [audienceMarkets, setAudienceMarkets] = useState<string[]>([]);
  const [avgMonthlyAudience, setAvgMonthlyAudience] = useState<SelectOption | undefined>();
  const [productCategories, setProductCategories] = useState<string[]>([]);
  const [promotionMethods, setPromotionMethods] = useState<string[]>([]);
  const [audienceAges, setAudienceAges] = useState<string[]>([]);
  const [gender, setGender] = useState<SelectOption | undefined>();
  const [creditScoreRating, setCreditScoreRating] = useState<SelectOption | undefined>();
  const [avgIncomes, setAvgIncomes] = useState<string[]>([]);
  const [id, setId] = useState('');

  const [publisherErrors, setPublisherErrors] = useState<{ [key: string]: string }>({});
  const [publisherArrayErrors, setPublisherArrayErrors] = useState<{ [key: string]: string }>({});
  const [validOnBlur, setValidOnBlur] = useState(false);
  const [, setUrlStatus] = useState('');
  const [urlError, setUrlError] = useState('');
  const [runValidation, setRunValidation] = useState(false);
  const [createUser, { loading: createUserLoading }] = useLazyQuery(GET_COMPANY);
  const [errorMessage, setErrorMessage] = useState('');
  const [displayPhotoUrl, setDisplayPhotoUrl] = useState('');

  const [imageFile, setImageFile] = useState<ImageFile>({
    file: undefined,
    height: 0,
    width: 0,
  });

  const onUploadHandler = (file: any) => {
    if (file instanceof File) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const image: ImageFile = {
          file,
          height: img.naturalHeight,
          width: img.naturalWidth,
        };
        setImageFile(image);
      };
    }
  };

  const returnImageurl = async () => {
    let productImageUrl = '';
    if (imageFile.file !== undefined) productImageUrl = await upload.hookUploadImageFile(imageFile.file);
    return productImageUrl;
  };

  const selectedFormat = (value: string): SelectOption => ({
    label: value,
    value,
  });

  const validateWebsite = async () => {
    if (platformLink !== '') {
      const status = await validtionHook.validateUrlStatus(platformLink, setUrlStatus);
      validtionHook.renderUrlCheck(status, setUrlError);
    }
  };

  const handleValidation = (submitting = false) => {
    if (!submitting && !validOnBlur) return null;

    const validationValues: { [key: string]: string } = {
      publisherDescription,
      avgMonthlyAudience: avgMonthlyAudience?.value || '',
      website: hasWebsite ? platformLink : 'pass',
    };

    const valdationArrayValues = {
      audienceMarkets,
      productCategories,
      promotionMethods,
    };
    const valid = validtionHook.validateAll(validationValues, validationfields, setPublisherErrors, true);
    const validArray = validtionHook.validateArray(
      valdationArrayValues,
      arrayValidationfields,
      setPublisherArrayErrors
    );
    return valid && validArray;
  };

  const getPublisherInfo = async () => {
    const { data } = await createUser({
      variables: {
        companyId: userHook.hookWhoAmI.companyId?.toString(),
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.company !== undefined) {
      if (data.company.overview) {
        setDisplayPhotoUrl(data.company?.overview?.profilePhotoURL || '');
        setPublisherDescription(data.company?.overview?.businessDesc || '');
        setPlatformLink(data.company?.overview?.primaryPlatformLink || '');
        setHasWebsite(data.company?.overview?.hasWebsite || false);
        setAudienceMarkets(data.company?.overview?.audienceMarkets || []);
        setAvgMonthlyAudience(selectedFormat(data.company?.overview?.avgMonthlyAudience || ''));
        setProductCategories(data.company?.overview?.productCategories || []);
        setPromotionMethods(data.company?.overview?.promotionMethods || []);
        setAudienceAges(data.company?.overview?.audienceAges || []);
        setGender(selectedFormat(data.company?.overview?.gender || ''));
        setCreditScoreRating(selectedFormat(data.company?.overview?.creditScoreRating || ''));
        setAvgIncomes(data.company?.overview?.avgIncomes || []);
      }
      setId(data?.company?.id);
    } else {
      setErrorMessage('Failed to fetch company');
    }
  };

  const [updateCompany, { loading: updateCompanyLoading }] = useMutation(UPDATE_COMPANY);

  const noNull = (value: any) => {
    if (value === null) return undefined;
    return value;
  };

  const updatePublisherHandler = async () => {
    setValidOnBlur(true);
    const valid = await handleValidation(true);
    if (!valid) return;
    const im: any = await returnImageurl();
    const { data } = await updateCompany({
      variables: {
        input: {
          id,
          overview: {
            businessDesc: noNull(publisherDescription),
            profilePhotoURL: noNull(im.url),
            primaryPlatformLink: platformLink,
            hasWebsite,
            audienceMarkets,
            avgMonthlyAudience: avgMonthlyAudience?.label || '',
            productCategories,
            promotionMethods,
            audienceAges,
            gender: gender?.label || '',
            creditScoreRating: creditScoreRating?.label || '',
            avgIncomes,
          },
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.updateCompany === undefined) {
      setErrorMessage('Failed to update company!');
    } else {
      hookShowToast(SUCCESS_MESSAGES.PUBLISHER_OVERVIEW_EDIT());
      await getPublisherInfo();
    }
  };

  const setPublisherDescriptionHandler = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPublisherDescription(e.target.value);
  };

  const setPlatformLinkHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlatformLink(e.target.value);
  };

  const setHasWebsiteHandler = async (checked: boolean) => {
    setHasWebsite(!checked);
    if (checked) setPlatformLink('');
    if (checked) setUrlError('');
  };

  const setAudienceMarketsHandler = async (checked: boolean, value: string) => {
    if (checked) await setAudienceMarkets([...audienceMarkets, value]);
    if (!checked) await setAudienceMarkets([...audienceMarkets.filter((option) => option !== value)]);
    setRunValidation(validOnBlur);
  };

  const setAvgMonthlyAudienceHandler = async (value: SelectOption) => {
    setAvgMonthlyAudience(value);
    setRunValidation(validOnBlur);
  };

  const setProductCategoriesHandler = async (checked: boolean, value: string) => {
    if (checked) setProductCategories([...productCategories, value]);
    if (!checked) setProductCategories([...productCategories.filter((option) => option !== value)]);
    setRunValidation(validOnBlur);
  };

  const setPromotionMethodsHandler = async (checked: boolean, value: string) => {
    if (checked) setPromotionMethods([...promotionMethods, value]);
    if (!checked) setPromotionMethods([...promotionMethods.filter((option) => option !== value)]);
    setRunValidation(validOnBlur);
  };

  const setAudienceAgesHandler = async (checked: boolean, value: string) => {
    if (checked) setAudienceAges([...audienceAges, value]);
    if (!checked) setAudienceAges([...audienceAges.filter((option) => option !== value)]);
  };

  const setGendereHandler = async (value: SelectOption) => {
    setGender(value);
  };

  const setCreditScoreRatingHandler = async (value: SelectOption) => {
    setCreditScoreRating(value);
  };

  const setAvgIncomesHandler = async (checked: boolean, value: string) => {
    if (checked) setAvgIncomes([...avgIncomes, value]);
    if (!checked) setAvgIncomes([...avgIncomes.filter((option) => option !== value)]);
  };

  useEffect(() => {
    if (runValidation) {
      handleValidation();
      setRunValidation(false);
    }
  }, [runValidation]);

  useEffect(() => {
    getPublisherInfo();
  }, []);

  return {
    hookSetPublisherDescription: setPublisherDescriptionHandler,
    hookPublisherDescription: publisherDescription,

    hookSetPlatformLink: setPlatformLinkHandler,
    hookPlatformLink: platformLink,

    hookSetHasWebsite: setHasWebsiteHandler,
    hookHasWebsite: hasWebsite,

    hookSetAvgMonthlyAudience: setAvgMonthlyAudienceHandler,
    hookAvgMonthlyAudience: avgMonthlyAudience,

    hookSetGender: setGendereHandler,
    hookGender: gender,

    hookSetCreditScoreRating: setCreditScoreRatingHandler,
    hookCreditScoreRating: creditScoreRating,

    hookSetAudienceMarkets: setAudienceMarketsHandler,
    hookAudienceMarkets: audienceMarkets,

    hookSetProductCategories: setProductCategoriesHandler,
    hookProductCategories: productCategories,

    hookSetPromotionMethods: setPromotionMethodsHandler,
    hookPromotionMethods: promotionMethods,

    hookSetAudienceAges: setAudienceAgesHandler,
    hookAudienceAges: audienceAges,

    hookSetAvgIncomes: setAvgIncomesHandler,
    hookAvgIncomes: avgIncomes,

    hookUpdatePublisher: updatePublisherHandler,
    hookValidateFields: handleValidation,
    hookValidateWebsite: validateWebsite,
    hookPublisherErrors: publisherErrors,
    hookPublisherArrorErrors: publisherArrayErrors,
    hookUrlError: urlError,

    hookError: errorMessage,
    hookLoading: createUserLoading,

    hookUploadImage: onUploadHandler,

    hookUpdateLoading: updateCompanyLoading,

    hookPhotoUrl: displayPhotoUrl,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
