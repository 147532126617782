import React from 'react';

import AddCustomTextComponent from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddCustomTextComponent';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import RuleStatusSettingsComponent from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/RuleStatusSettingsComponent';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import { RULE_MANAGER } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/enums';

export const TextCriteria = ({ hook }: { hook: ReturnType<typeof useAddEvaluationRule> }): JSX.Element => (
  <>
    <Styled.TextDescriptionWrapper>
      <Styled.RuleStatusLabel>
        {RULE_MANAGER.EDIT_RULES.RULE_STATUS}
        <Styled.RequiredStyled>*</Styled.RequiredStyled>
      </Styled.RuleStatusLabel>
      <Styled.RuleStatusDescription>
        {RULE_MANAGER.EDIT_RULES.RULE_STATUS_SETTINGS_DESCRIPTION}
      </Styled.RuleStatusDescription>
      <RuleStatusSettingsComponent
        ruleStatusSettings={hook.stateMachine.context.ruleStatusSettings}
        errors={hook.ruleStatusErrors}
        setRuleStatusSettings={hook.changeRuleStatusSettings}
      />
    </Styled.TextDescriptionWrapper>

    <Styled.TextCriteriaContentWrapper>
      <AddCustomTextComponent
        inputLabelText="Custom Text Value"
        inputTooltipText="Please make sure the values are unique"
        textList={hook.criteriaCustomTextList}
        inputPlaceholderText="Text value to find"
        errors={hook.textCriteriaErrors}
        onBlur={() => hook.onBlurCheck(true)}
        onChange={(id, value) => hook.updateCustomTextValue(id, value, true)}
        removeTextHandler={(id) => hook.removeCustomTextById(id, true)}
        addTextHandler={() => hook.addNewCustomText(true)}
        isAddButtonEnabled={hook.canAddMoreCriteriaCustomText}
        addButtonText="Add Text Value"
        customInputWidth="60vw"
        customInfoText="Enter one or more Custom Text Values (words or phrases) for the rule to evaluate on a page(s)."
      />
    </Styled.TextCriteriaContentWrapper>
  </>
);
