import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, ChangeEvent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useToast, useUserInfo } from 'hooks';
import { MERCHANT_PREFIX, path, sortString } from 'utils';

import { PAGE_TYPES, TEMPLATE_ACTIONS, TOAST_MESSAGES } from '../contracts';
import { PUBLISHERS_FOR_INVITATION, TEMPLETES_FOR_INVITATION } from '../graphql/queries';
import { INVITE_PUBLISHERS } from '../Tabs/InvitePublishers/enum';
import { COPY_INVIATION_TEMPLATE, DELETE_INVIATION_TEMPLATE } from '../graphql/mutations';

type PrimaryAudienceOption = {
  label: string;
  value: string | null;
};

export const usePublisherInvitation = (page: string) => {
  const { state } = useLocation();
  const [getPublisherForInvitation, { loading: publishersLoading }] = useLazyQuery(PUBLISHERS_FOR_INVITATION);
  const [getTemplatesForInvitation] = useLazyQuery(TEMPLETES_FOR_INVITATION);

  const [copyInvitationTemplate] = useMutation(COPY_INVIATION_TEMPLATE);
  const [deleteInvitationTemplate] = useMutation(DELETE_INVIATION_TEMPLATE);

  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();

  const { hookShowToast } = useToast();

  const [checked, setChecked] = useState<boolean>(state?.checked || false);
  const [memberType, setMemberType] = useState<SelectOption | undefined>(
    state?.memberType || INVITE_PUBLISHERS.SELECT.MEMBER[0]
  );
  const [primaryAudience, setPrimaryAudience] = useState<SelectOption | undefined>(state?.primaryAudience);
  const [primaryAudienceOptions, setPrimaryAudienceOptions] = useState<PrimaryAudienceOption[]>(
    INVITE_PUBLISHERS.SELECT.PRIMARY_AUDIENCE
  );
  const [productCategory, setProductCategory] = useState<SelectOption | undefined>(state?.productCategory);
  const [promotionMethod, setPromotionMethod] = useState<SelectOption | undefined>(state?.promotionMethod);
  const [audienceSize, setAudienceSize] = useState<SelectOption | undefined>(state?.audienceSize);
  const [activityStatus, setActivityStatus] = useState<SelectOption | undefined>(state?.activityStatus);

  const [age, setAge] = useState<SelectOption | undefined>(state?.age);
  const [ageOptions, setAgeOptions] = useState<SelectOption[]>(INVITE_PUBLISHERS.SELECT.AGE);

  const [gender, setGender] = useState<SelectOption | undefined>(state?.gender);
  const [genderOptions, setGenderOptions] = useState<SelectOption[]>(INVITE_PUBLISHERS.SELECT.GENDER);

  const [creditScore, setCreditScore] = useState<SelectOption | undefined>(state?.creditScore);
  const [creditScoreOptions, setCreditScoreOptions] = useState<SelectOption[]>(INVITE_PUBLISHERS.SELECT.CREDIT);

  const [averageIncome, setAverageIncome] = useState<SelectOption | undefined>(state?.averageIncome);

  const [sortBy, setSortBy] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });
  const [search, setSearch] = useState(state?.search || '');

  const [publishers, setPublishers] = useState<any[]>();
  const [templates, setTemplates] = useState<InvitationsTemplate[]>([]);
  const [allTemplates, setAllTemplates] = useState<InvitationsTemplate[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [numberOfPagesTemplate, setNumberOfPagesTemplate] = useState(1);
  const [currentPageTemplate, setCurrentPageTemplate] = useState(1);

  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});
  const [resetPublishers, setResetPublishers] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [searchClicked, setSearchClicked] = useState<boolean>();

  const [currentTab, setCurrentTab] = useState<number>(state?.tabNumber || 0);

  const currentState = {
    search,
    memberType,
    primaryAudience,
    productCategory,
    promotionMethod,
    checked,
    audienceSize,
    activityStatus,
    age,
    gender,
    creditScore,
    averageIncome,
  };

  const setCurrentTabHandler = (newTab: number) => {
    setCurrentTab(newTab);
  };
  const overrideTabs = { activeTabOveride: currentTab, setActiveTab: setCurrentTabHandler };

  const formatterForOptions = (arr: string[]) =>
    arr.map((value) => ({
      value,
      label: value,
    }));

  const primaryAudienceFormatter = (arr: string[]): PrimaryAudienceOption[] =>
    arr.map((value) => {
      if (value === 'Any Audience Markets') {
        return {
          label: value,
          value: null,
        };
      }
      return {
        label: value,
        value,
      };
    });

  const getPublishers = async (reset = false, showData = false) => {
    try {
      const filterInputs: { [key: string]: string } = {};
      if (primaryAudience && primaryAudience.value !== INVITE_PUBLISHERS.ALL_VALUE)
        filterInputs.audienceMarkets = primaryAudience.value;
      if (productCategory && productCategory.value !== INVITE_PUBLISHERS.ALL_VALUE)
        filterInputs.productCategory = productCategory.value;
      if (promotionMethod && promotionMethod.value !== INVITE_PUBLISHERS.ALL_VALUE)
        filterInputs.promotionMethods = promotionMethod.value;
      if (audienceSize && audienceSize.value !== INVITE_PUBLISHERS.ALL_VALUE)
        filterInputs.avgMonthlyAudience = audienceSize.value;
      if (age && age.value !== INVITE_PUBLISHERS.ALL_AGES) filterInputs.audienceAges = age.value;
      if (gender && gender.value !== INVITE_PUBLISHERS.ALL_GENDER) filterInputs.gender = gender.value;
      if (creditScore && creditScore.value !== INVITE_PUBLISHERS.ALL_CREDIT_SCORE_RATING)
        filterInputs.creditScoreRating = creditScore.value;
      if (averageIncome && averageIncome.value !== INVITE_PUBLISHERS.ALL_VALUE)
        filterInputs.avgIncomes = averageIncome.value;
      if (activityStatus && activityStatus.value !== INVITE_PUBLISHERS.ALL_VALUE)
        filterInputs.activityStatus = activityStatus.value;

      const {
        data: {
          publishersForInvitationV2: { PublisherForInvitation, count, filterValues },
        },
        error,
      } = await getPublisherForInvitation({
        variables: {
          input: {
            merchantId: hookWhoAmI.companyId?.toString(),
            isMember: memberType?.value === 'true',
            trackingDomainUrl: search,
            options: {
              page: currentPage,
              items: 10,
              order: sortBy.direction?.toUpperCase(),
            },
            filterInputs,
            sortBy: sortBy.column,
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (error) throw error;

      if (searchClicked || showData) {
        setPublishers(PublisherForInvitation);
        setNumberOfPages(Math.ceil(count / 10));
      }
      setIsLoading(false);
      setIsTableLoading(false);
      if (filterValues && reset) {
        setAgeOptions(
          formatterForOptions(filterValues.audienceAges.filter((value: string) => value && value !== 'All ages'))
        );
        setPrimaryAudienceOptions(
          primaryAudienceFormatter(filterValues.audienceMarkets.filter((value: string) => value))
        );
        setGenderOptions(formatterForOptions(filterValues.gender));
        setCreditScoreOptions(formatterForOptions(filterValues.creditScoreRating));
      }
    } catch (error: any) {
      setErrorMessages({ message: error.message });
      setIsLoading(false);
      setIsTableLoading(false);
    }
  };

  const getTemplates = async () => {
    try {
      const {
        data: { publisherInvitations },
        error,
      } = await getTemplatesForInvitation({
        variables: {
          input: {
            merchantId: hookWhoAmI.companyId?.toString(),
            template: true,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) throw error;

      setAllTemplates(publisherInvitations);
      setNumberOfPagesTemplate(Math.ceil(publisherInvitations.length / 10));
      setTemplates(publisherInvitations.slice(0, 10));
    } catch (error: any) {
      setErrorMessages({ message: error.message });
      setIsLoading(false);
    }
  };

  const templateCopyHandler = async (row: any) => {
    try {
      const {
        data: { createPublisherInvitation },
        errors,
      } = await copyInvitationTemplate({
        variables: {
          input: {
            merchantId: row.merchantId,
            name: `Copy of ${row.name}`,
            description: row.description,
            subject: row.subject,
            message: row.message,
            template: row.template,
          },
        },
      });
      if (errors) throw new Error(errors[0].message);
      if (createPublisherInvitation?.id) {
        hookShowToast(TOAST_MESSAGES.COPY.MESSAGE, `${MERCHANT_PREFIX}${path.invitationTemplate.href}`);
        setAllTemplates([createPublisherInvitation, ...allTemplates]);
        setTemplates([createPublisherInvitation, ...allTemplates.slice(0, 9)]);
      }
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const templateDeleteHandler = async (id: string) => {
    try {
      const { errors } = await deleteInvitationTemplate({
        variables: { id },
      });
      if (errors) throw new Error(errors[0].message);
      hookShowToast(TOAST_MESSAGES.DELETE.MESSAGE);
      getTemplates();
    } catch (error: any) {
      setErrorMessages({ message: error.message });
    }
  };

  const clearFormHandler = () => {
    setMemberType(INVITE_PUBLISHERS.SELECT.MEMBER[0]);
    setPrimaryAudience(undefined);
    setActivityStatus(undefined);
    setAge(undefined);
    setAudienceSize(undefined);
    setAverageIncome(undefined);
    setCreditScore(undefined);
    setGender(undefined);
    setProductCategory(undefined);
    setPromotionMethod(undefined);
    setSearch('');
    setSearchClicked(false);
    setPublishers([]);
    setCurrentPage(1);
  };

  const startSearchHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (isTableLoading || isLoading) return;
      setIsTableLoading(true);
      if (page === PAGE_TYPES.INVITE) {
        getPublishers(true);
      }
    }
  };

  const navigationHandler = (row: any) => {
    const urlPath = `${MERCHANT_PREFIX}${path.membershipDetails.href}`;

    navigate(urlPath, {
      state: {
        search,
        memberType,
        primaryAudience,
        productCategory,
        promotionMethod,
        checked,
        audienceSize,
        activityStatus,
        age,
        gender,
        creditScore,
        averageIncome,
        publisherId: row.id,
        membershipId: row.membership?.id,
        invitesOnHold: row.invitesOnHold,
        from: `${MERCHANT_PREFIX}${path.publisherInvitations.href}`,
      },
    });
  };

  const navigateToInvitationHistory = () => {
    navigate(`${MERCHANT_PREFIX}${path.invitationHistory.href}`, {
      state: {
        search,
        memberType,
        primaryAudience,
        productCategory,
        promotionMethod,
        checked,
        audienceSize,
        activityStatus,
        age,
        gender,
        creditScore,
        averageIncome,
        from: `${MERCHANT_PREFIX}${path.publisherInvitations.href}`,
      },
    });
  };

  const setSortByHandler = (dataField: string, direction: 'asc' | 'desc' | undefined) => {
    if (sortBy.direction === null) {
      setSortBy({ column: dataField, direction });
    } else {
      setSortBy({ column: dataField, direction: sortBy.direction === 'asc' ? 'desc' : 'asc' });
    }
    if (page === PAGE_TYPES.MAIN) {
      const sortTemplates = sortString(allTemplates, dataField, direction);
      setTemplates(sortTemplates.slice(0, 10));
      setCurrentPage(1);
    }
  };

  const templateActionHandler = (row: any, actiontype: string) => {
    switch (actiontype) {
      case TEMPLATE_ACTIONS.COPY:
        templateCopyHandler(row);
        break;
      case TEMPLATE_ACTIONS.EDIT:
        navigate(`${MERCHANT_PREFIX}${path.invitationTemplate.href}?id=${row.id}&edit=${row.name}`);
        break;
      case TEMPLATE_ACTIONS.DELETE:
        setTemplateId(row.id);
        setTemplateName(row.name);
        setModalOpen(true);
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const setSearchHandler = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const setCurrentPageHandler = (pageNumber: number) => setCurrentPage(pageNumber);
  const setCurrentPageTemplateHandler = (pageNumber: number) => {
    setCurrentPageTemplate(pageNumber);
    setTemplates(allTemplates.slice(10 * (pageNumber - 1), 10 * pageNumber));
  };

  const getTempleteHandler = () => getTemplates();

  const searchButtonhandler = () => {
    setSearchClicked(true);
    getPublishers(false, true);
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    if (page === PAGE_TYPES.INVITE && state) {
      setSearchClicked(true);
      getPublishers(true, true);
    } else if (page === PAGE_TYPES.INVITE) {
      getPublishers(true);
    }
  }, []);

  useEffect(() => {
    if (isTableLoading || isLoading || !resetPublishers) return;
    setResetPublishers(false);
    if (page === PAGE_TYPES.INVITE) {
      getPublishers(true);
    }
  }, [resetPublishers]);

  useEffect(() => {
    if (isTableLoading || isLoading) return;
    if (page === PAGE_TYPES.INVITE) {
      getPublishers(true);
    }
  }, [memberType]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else if (!(isTableLoading || isLoading) && page === PAGE_TYPES.INVITE && searchClicked) {
      getPublishers();
    }
  }, [
    primaryAudience,
    productCategory,
    promotionMethod,
    memberType,
    age,
    gender,
    creditScore,
    averageIncome,
    audienceSize,
    activityStatus,
  ]);

  useEffect(() => {
    if (isTableLoading || isLoading) return;
    if (page === PAGE_TYPES.INVITE && searchClicked) {
      getPublishers();
    }
  }, [currentPage, JSON.stringify(sortBy)]);

  return {
    hookNavigateToInvitationHistory: navigateToInvitationHistory,
    hookPublisher: publishers,
    hookTemplates: templates,
    hookOverrideTabs: overrideTabs,
    hookCurrentState: currentState,
    hookChecked: checked,
    hookSetChecked: setChecked,

    hookMemberType: memberType,
    hookSetMemberType: setMemberType,

    hookPrimaryAudience: primaryAudience,
    hookSetPrimaryAudience: setPrimaryAudience,
    hookPrimaryAudienceOptions: primaryAudienceOptions,

    hookProductCategory: productCategory,
    hookSetProductCategory: setProductCategory,
    hookPromotionMethod: promotionMethod,
    hookSetPromotionMethod: setPromotionMethod,
    hookAudienceSize: audienceSize,
    hookSetAudienceSize: setAudienceSize,
    hookActivityStatus: activityStatus,
    hookSetActivityStatus: setActivityStatus,

    hookAge: age,
    hookSetAge: setAge,
    hookAgeOptions: ageOptions,

    hookGender: gender,
    hookSetGender: setGender,
    hookGenderOptions: genderOptions,

    hookCreditScore: creditScore,
    hookSetCreditScore: setCreditScore,
    hookCreditScoreOptions: creditScoreOptions,

    hookAverageIncome: averageIncome,
    hookSetAverageIncome: setAverageIncome,

    hookClearFormHandler: clearFormHandler,
    hookSetSortBy: setSortByHandler,

    hookSortBy: sortBy,
    hookNumberOfPages: numberOfPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,

    hookNumberOfPagesTemplate: numberOfPagesTemplate,
    hookCurrentPageTemplate: currentPageTemplate,
    hookSetCurrentPageTemplate: setCurrentPageTemplateHandler,

    hookSearch: search,
    hookSetSearch: setSearchHandler,
    hookStartSearch: startSearchHandler,

    hookSearchButtonHandler: searchButtonhandler,
    hookSearchClicked: searchClicked,

    hookErrors: errorMessages,
    hookIsLoading: isLoading,
    hookIsTableLoading: isTableLoading || publishersLoading,

    hookNavgationHandler: navigationHandler,
    hookGetTemplete: getTempleteHandler,

    hookTemplateAction: templateActionHandler,
    hookModalOpen: modalOpen,
    hookTemplateId: templateId,
    hookTemplateName: templateName,
    hookDeleteTemplate: templateDeleteHandler,
    hookCloseModal: closeModal,
  };
};
