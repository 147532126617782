import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Button, Select } from '../../../components';
import { fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type WrapperProps = {
  readonly isAdvance: boolean;
};

type FiltersRowStyledProps = {
  readonly isNotAdmin?: boolean;
};

type BoxStyledProps = {
  readonly theme?: string;
};

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const SelectStyled = styled(Select)`
  margin: 0 1rem;
`;

export const OptionsRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const WrapperStyled = styled.div<WrapperProps>`
  ${OptionsRowStyled} {
    margin-top: ${({ isAdvance }) => (isAdvance ? '2.5rem' : '0')};
  }
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;
`;

export const FiltersRowStyled = styled.div<FiltersRowStyledProps>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: ${({ isNotAdmin }) => (isNotAdmin ? '66%' : '100%')};
  margin-bottom: 1.5rem;

  &:nth-of-type(2) {
    justify-content: flex-end;
    margin-bottom: 0;
  }
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
  width: 100%;
  margin-bottom: ${toRem(24)};
`;

export const RightStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const PlaceholderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: ${toRem(5)};
`;

export const BoxStyled = styled.div<BoxStyledProps>`
  display: flex;
  flex-direction: ${({ theme }) => (theme === 'amountCol' ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${({ theme }) => (theme === 'amountCol' ? 'center' : 'flex-start')};
  min-height: ${({ theme }) => (theme === 'amountCol' ? 'auto' : toRem(42))};
`;

export const InfoBox = styled.div`
  margin-bottom: 30px;
  font-size: 15px;
  word-spacing: 1px;
  width: 90%;
  & > div {
    margin-bottom: 0.5rem;
  }
`;
