import styled from 'styled-components';

import { Button, InputText, Select, FixedFooter, Pagination } from '../../../../../components';
import { colors, fonts } from '../../../../../styles/theme';
import { toRem } from '../../../../../utils';

type ReportProps = {
  show: boolean;
};

type NotifyerProps = {
  isShow: boolean;
};

export const FiltersStyled = styled.div`
  margin-top: ${toRem(40)};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 40px;
  align-items: end;
`;

export const FilterSelectStyled = styled(Select)``;

export const FilterPeriodWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FilterPeriodLabelStyled = styled.div`
  ${fonts.gotham.H12M}
  color: ${colors.color13};
`;

export const FilterPeriodStyled = styled.div`
  ${fonts.lato.H14}
  margin-top: ${toRem(20)};
`;

export const FilterInputTextStyled = styled(InputText)``;

export const StyledNotifyer = styled.div<NotifyerProps>`
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  background-color: ${colors.color11};
  padding: ${toRem(10)};
  justify-content: center;
  align-content: center;
  margin: ${toRem(32)} 0;
  b {
    font-weight: bolder;
  }
`;

export const FilterButtonWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: ${toRem(40)} 0;
`;

export const FilterActionButtonsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${toRem(20)};
`;

export const FilterClearButtonStyled = styled(Button)`
  padding: 0;
`;

export const FilterButtonStyled = styled(Button)`
  margin-left: ${toRem(20)};
  min-width: 150px;

  svg {
    margin-right: 10px;
  }
`;

export const Report = styled.div<ReportProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const ReportFooter = styled(FixedFooter)`
  display: flex;
  justify-content: end;
  align-items: center;
  ${fonts.lato.H14}
`;

export const ReportFooterSelect = styled(Select)`
  width: ${toRem(90)};
  margin-left: ${toRem(20)};
`;

export const StyledPaginate = styled(Pagination)`
  margin-bottom: ${toRem(48)};
`;
