import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useFintelCheckContext } from '../../hooks';
import { GET_FINTEL_CHECK_DETAILS } from '../graphql/queries';
import { useUserInfo } from '../../../../../hooks';
import { GetFintelCheckDetailsOutput, GetFintelCheckDetailsInput, GFCDDetailType } from '../types';
import { compareValues } from '../../../../Reports/PerfomanceReport/utils';
import { CSVColumns, processDataToCSVString } from '../utils';
import { csvGenerator } from '../../../../../utils';

export const useChangeHistory = () => {
  const { hookWhoAmI } = useUserInfo();
  const { contextState } = useFintelCheckContext();
  const navigate = useNavigate();

  const [records, setRecords] = useState<SelectOption>({ label: '10', value: '10' });
  const RECORDS_PER_PAGE = Number(records.value);

  const [allData, setAllData] = useState<GFCDDetailType[]>([]);
  const [tableData, setTableData] = useState<GFCDDetailType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();

  const [errorMessage, setErrorMessage] = useState<string>('');

  // Queries
  const [getDetails, { loading }] = useLazyQuery<GetFintelCheckDetailsOutput, GetFintelCheckDetailsInput>(
    GET_FINTEL_CHECK_DETAILS
  );

  const getDetailsHandler = async () => {
    setErrorMessage('');
    const { data } = await getDetails({
      variables: {
        input: {
          merchantId: contextState.merchantId || hookWhoAmI.companyId?.toString() || '0',
          publisherId: contextState.publisherId || hookWhoAmI.companyId?.toString() || '0',
          referralUrl: contextState.referralUrl,
          ruleName: contextState.ruleName,
          productId: contextState.productId,
        },
      },
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data && data.fintelCheckDetails) {
      const formattedData = data.fintelCheckDetails.fintelChecks;

      setTableData(formattedData.slice((currentPage - 1) * RECORDS_PER_PAGE, currentPage * RECORDS_PER_PAGE));
      setAllData(formattedData);
      setCurrentPage(1);
      setTotalPages(Math.ceil(formattedData.length / RECORDS_PER_PAGE));
    } else {
      setCurrentPage(1);
      setTotalPages(1);
      setTableData([]);
      setAllData([]);
    }
  };

  const backButtonHandler = () => {
    navigate(-1);
  };

  const setRecordsHandler = (value: SelectOption) => {
    const newRecordsPerPage = Number(value.value);
    setRecords(value);
    setCurrentPage(1);
    setTotalPages(Math.ceil(allData.length / newRecordsPerPage));
    setTableData(allData.slice(0, newRecordsPerPage));
  };

  const onSortHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    const newDirection = direction === 'desc' ? 'asc' : 'desc';
    setSortColumn({
      column,
      direction: newDirection,
    });
    const sortFunction = compareValues(column, newDirection);
    const sortedData = [...allData].sort(sortFunction);
    setAllData(sortedData);
    setTableData(sortedData.slice((currentPage - 1) * RECORDS_PER_PAGE, currentPage * RECORDS_PER_PAGE));
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    setTableData(allData.slice((newPage - 1) * RECORDS_PER_PAGE, newPage * RECORDS_PER_PAGE));
  };

  const generateCSVHandler = () => {
    const csvString = processDataToCSVString(allData, CSVColumns);
    csvGenerator(csvString, 'Fintel Check Change History');
  };

  useEffect(() => {
    getDetailsHandler();
  }, []);

  return {
    hookContext: contextState,
    hookLoading: loading,
    hookErrorMessage: errorMessage,

    hookBackButtonhandler: backButtonHandler,
    hookDownloadCSVHandler: generateCSVHandler,

    hookRecords: records,
    hookSetRecords: setRecordsHandler,

    hookData: tableData,
    hookTotalPages: totalPages,
    hookCurrentPage: currentPage,
    hookTotalRecords: allData.length,
    hookSortColumn: sortColumn,

    hookOnSortHandler: onSortHandler,
    hookSetCurrentPageHandler: setCurrentPageHandler,
  };
};
