import React from 'react';

import { useManageUser } from '../../hooks';
import { PREFERED_LANGAUGE_OPTIONS } from '../../../../utils';
import { INPUT_LABELS } from '../../contracts/enum';

import * as Styled from './styles';

type AccountTabProps = {
  hook: ReturnType<typeof useManageUser>;
};

export const EditAccount = ({ hook }: AccountTabProps) => (
  <Styled.WrapperStyled>
    <Styled.InputTextStyled
      grids={1}
      type="text"
      label={INPUT_LABELS.NAME}
      required
      value={hook.hookFirstName}
      error={hook.hookErrors.firstName}
      onChange={hook.hookSetFirstName}
    />

    <Styled.InputTextStyled
      grids={1}
      type="text"
      label={INPUT_LABELS.LAST_NAME}
      required
      value={hook.hookLastName}
      error={hook.hookErrors.lastName}
      onChange={hook.hookSetLastName}
    />

    <Styled.InputTextStyled
      grids={1}
      type="text"
      label={INPUT_LABELS.EMAIL}
      required
      disabled
      value={hook.hookEmail}
      error={hook.hookErrors.email}
      onChange={hook.hookSetEmail}
    />

    <Styled.PasswordInputWrapper>
      <Styled.InputTextStyled
        grids={1}
        type="password"
        label={INPUT_LABELS.PASSWORD}
        value="placeholder"
        disabled
        onChange={() => ''}
        forceResetButtonHandler={hook.hookResetPassword}
      />
      {hook.hookSuccessMessagePassword}
    </Styled.PasswordInputWrapper>

    <Styled.InputPhoneStyled
      grids={1}
      label={INPUT_LABELS.PHONE}
      type="text"
      value={hook.hookPhone}
      onChange={hook.hookSetPhone}
      error={hook.hookErrors.phone}
    />

    <Styled.SelectStyled
      isDisabled
      grids={1}
      label={INPUT_LABELS.LANGUGE}
      onChange={hook.hookSetLanguage}
      options={PREFERED_LANGAUGE_OPTIONS}
      selected={hook.hookLanguage}
    />

    <Styled.SelectStyled
      grids={2}
      label={INPUT_LABELS.ROLE}
      required
      isDisabled={!hook.hookEditPermissions}
      onChange={hook.hookSetUserSubTypes}
      options={hook.hookUserSubTypeOptions}
      selectedMulti={hook.hookUserSubTypes}
      isMulti
      error={hook.hookErrors.role}
      isLoading={hook.hookSecurityRolesLoading}
    />

    <Styled.InputTextStyled
      grids={1}
      type="text"
      label={INPUT_LABELS.POSITION}
      value={hook.hookPosition}
      onChange={hook.hookSetPosition}
    />
  </Styled.WrapperStyled>
);
