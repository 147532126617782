import { gql } from '@apollo/client';

export const MEMBERSHIPS = gql`
  query memberships($input: MembershipSearchInput!) {
    memberships(input: $input) {
      count
      memberships {
        id
        status
        statusLastUpdatedBy
        createdAt
        approvedDate
        publisherInvitation {
          id
          subject
          message
        }
        program {
          id
          merchant {
            id
            companyName
            createdAt
            companyUrl
            networkStatus
            companyImgUrl
          }
          products {
            id
            productCategory
            targetDemographics {
              geographicalCountry
              geographicalStates
            }
          }
        }
      }
    }
  }
`;
