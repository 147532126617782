import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors, fonts } from '../../../../../../../../styles/theme';

type RowProps = {
  readonly status?: string;
  readonly disabled?: boolean;
};

export const CellStyled = styled.div<RowProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: visible;
`;

export const MainValStyled = styled.p`
  ${fonts.lato.H16}
  color: ${colors.color1};
  line-height: 1.5;
`;

export const SecondaryValStyled = styled(MainValStyled)<RowProps>`
  color: ${({ disabled }) => (disabled ? colors.color1 : colors.color35)};
`;

export const IdStyled = styled(CellStyled)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  overflow: visible;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  color: ${colors.color1};
`;
