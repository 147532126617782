import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserInfo } from '../../../../../hooks';
import { MEMBERSHIPS } from '../graphql/queries';
import { paginator, path, PUBLISHER_PREFIX } from '../../../../../utils';
import { handleMarkets, formatMemberships, COMPARE, handleCategoriesList } from '../enum';

export const useApplications = () => {
  const { hookWhoAmI } = useUserInfo();
  const [selectedStatus, setSelectedStatus] = useState<SelectOption>({ label: 'All Statuses', value: '' });
  const [selectedMarket, setSelectedMarket] = useState<SelectOption>({ label: 'All Markets', value: '' });
  const [selectedCategory, setSelectedCategory] = useState<SelectOption>({ label: 'All Categories', value: '' });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [modalMessage, setModalMessage] = useState({ name: '', subject: '', message: '' });

  const [statusList, setStatusList] = useState<SelectOption[]>([]);
  const [marketList, setMarketList] = useState<SelectOption[]>([]);
  const [categoryList, setCategoryList] = useState<SelectOption[]>([]);
  const [getMemberShips, { loading: membershipsLoading }] = useLazyQuery(MEMBERSHIPS);
  const [allMemberships, setAllMemberShips] = useState<any>([]);
  const [filteredMembers, setFilteredMembers] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [programId, setProgramId] = useState<any>('');

  const navigate = useNavigate();

  const [sortColumn, setSortColumn] = useState<TableSortColumn>();

  const [isOpen, setIsOpen] = useState(false);

  const cancelModal = () => {
    setIsOpen(false);
  };

  const handleSort = (dataField: string, direction: any) => {
    const nextDirection: number = direction === 'desc' ? -1 : 1;
    const compare = (a: any, b: any) => {
      let compareCondition = false;
      switch (dataField) {
        case COMPARE.id: {
          compareCondition = Number(a.id) >= Number(b.id);
          break;
        }
        case COMPARE.invited: {
          compareCondition = new Date(b.invited) <= new Date(a.invited);
          break;
        }
        case COMPARE.publisherInvitation: {
          compareCondition = a.publisherInvitation !== null && b.publisherInvitation === null;
          break;
        }
        default: {
          compareCondition = a[dataField] === b[dataField] ? Number(a.id) >= Number(b.id) : a[dataField] > b[dataField];
          break;
        }
      }
      return compareCondition ? nextDirection : nextDirection * -1;
    };
    const copyArray = [...allMemberships];
    const sortedArray = copyArray.sort((a, b) => compare(a, b));
    setAllMemberShips(sortedArray);
    setTableData(paginator(sortedArray, 10, 1));
    setSortColumn({ column: dataField, direction: sortColumn?.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  const handleRowClick = (row: any) => {
    navigate(`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${row.programId}`);
  };

  const termAndConditionsButton = () => {
    navigate(`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${programId}`);
    setIsOpen(false);
  };

  const handleStatusList = (value: any[]) => {
    const statusListV2: any[] = [];
    statusListV2.push({ label: `All Statuses (${value.length})`, value: '' });
    value.forEach((val: any) => {
      let valStatus = val.status;
      if (val.status === 'Closed') {
        valStatus = `Closed By ${val.statusLastUpdatedBy}`;
      }
      const existingStatus = statusListV2.find((status: any) => status.value === valStatus);
      if (!existingStatus) {
        statusListV2.push({ label: `${valStatus} (1)`, value: valStatus });
      } else {
        const count = Number(existingStatus.label.split(/\(|\)/)[1]) + 1;
        existingStatus.label = `${valStatus} (${count})`;
      }
    });
    setStatusList(statusListV2);
  };

  const handleGetMemberShips = async () => {
    const { data } = await getMemberShips({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.memberships !== undefined) {
      const formatted = formatMemberships(data.memberships.memberships).filter(
        (obj: any) =>
          !(
            obj.status === 'Pending' &&
            (obj.statusLastUpdatedBy === 'Merchant' || (!obj.statusLastUpdatedBy && obj.publisherInvitation))
          )
      );
      setMarketList(handleMarkets(formatted));
      handleStatusList(formatted);
      setCategoryList(handleCategoriesList(formatted));
      setTotalPages(Math.ceil(data.memberships.count / 10));
      setAllMemberShips(formatted);
      setTableData(paginator(formatted, 10, 1));
      setCurrentPage(1);
    }
  };

  const setCurrentPageHandler = (chosenPage: number) => {
    setTableData(paginator(filteredMembers, 10, chosenPage));
    setCurrentPage(chosenPage);
  };

  const handleOpenModal = (value: any) => {
    setModalMessage({
      name: value.companyName,
      subject: value.publisherInvitation.subject,
      message: value.publisherInvitation.message,
    });
    setProgramId(value.programId);
    setIsOpen(true);
  };

  const setSelectedStatusHandler = (value: SelectOption) => {
    setSelectedStatus({
      label: value.label,
      value: value.value,
    });
    setCurrentPageHandler(1);
  };

  const setSelectedMarketHandler = (value: SelectOption) => {
    setSelectedMarket({
      label: value.label,
      value: value.value,
    });
    setCurrentPageHandler(1);
  };

  const setSelectedCategoryHandler = (value: SelectOption) => {
    setSelectedCategory({
      label: value.label,
      value: value.value,
    });
    setCurrentPageHandler(1);
  };

  const clearFormHandler = () => {
    setSelectedStatus({ label: 'All Statuses', value: '' });
    setSelectedMarket({ label: 'All Markets', value: '' });
    setSelectedCategory({ label: 'All Categories', value: '' });
  };

  useEffect(() => {
    let filteredData = allMemberships;
    if (selectedCategory.label !== 'All Categories') {
      filteredData = filteredData.filter((obj: any) => obj.categories.includes(selectedCategory.label));
    }
    if (selectedMarket.label !== 'All Markets') {
      filteredData = filteredData.filter((obj: any) => obj.markets.includes(selectedMarket.label));
    }
    if (selectedStatus.value !== '') {
      if (selectedStatus.value === 'Closed By Merchant') {
        filteredData = filteredData.filter(
          (obj: any) => obj.status === 'Closed' && obj.statusLastUpdatedBy === 'Merchant'
        );
      } else if (selectedStatus.value === 'Closed By Publisher') {
        filteredData = filteredData.filter(
          (obj: any) => obj.status === 'Closed' && obj.statusLastUpdatedBy === 'Publisher'
        );
      } else {
        filteredData = filteredData.filter((obj: any) => obj.status === selectedStatus.value);
      }
    }
    setFilteredMembers(filteredData);
    setTableData(paginator(filteredData, 10, 1));
    setCurrentPage(1);
    setTotalPages(Math.ceil(filteredData.length / 10));
  }, [selectedCategory, selectedMarket, selectedStatus, allMemberships]);

  useEffect(() => {
    handleGetMemberShips();
  }, []);

  return {
    hookPageLoading: membershipsLoading,

    hookStatus: selectedStatus,
    hookSetStatus: setSelectedStatusHandler,

    hookTargetMarket: selectedMarket,
    hookSetTargetMarket: setSelectedMarketHandler,

    hookProductCategory: selectedCategory,
    hookSetProductCategory: setSelectedCategoryHandler,

    hookTableData: tableData,

    hookClearForm: clearFormHandler,

    hookAudienceMarkets: marketList,
    hookStatusList: statusList,
    hookCategoryList: categoryList,

    hookIsOpen: isOpen,
    hookCancelHandler: cancelModal,
    hookSaveHandler: termAndConditionsButton,
    hookHandleOpenModal: handleOpenModal,

    hookMessage: modalMessage,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSetCurrentPage: setCurrentPageHandler,

    hookSort: sortColumn,
    hookOnSort: handleSort,
    hookRowClick: handleRowClick,
  };
};
