import React, { ReactNode } from 'react';

import { Modal } from '..';
import { ErrorBanner } from '../../ErrorBanner';
import { TOAST_ERR_MESSAGES_NO_PAGE } from '../../../utils';

import * as Styled from './styles';

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  desc?: string;
  onCloseText: string;
  onConfirmText: string;
  children?: ReactNode;
  isLoading?: boolean;
  buttonWidth?: string;
  theme?: Theme;
  errorMsg?: string;
  width?: string;
  onConfirmDisabled?: boolean;
};

export const ConfirmationModal = ({
  onClose,
  onConfirm,
  isOpen,
  title,
  desc,
  onCloseText,
  onConfirmText,
  children,
  isLoading,
  buttonWidth,
  theme,
  errorMsg,
  width,
  onConfirmDisabled,
}: ConfirmationModalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.WrapperStyled theme={theme} width={width}>
      <ErrorBanner isOpen={!!errorMsg} message={TOAST_ERR_MESSAGES_NO_PAGE(errorMsg || '')} margin="0 0 1rem 0" />
      {title !== undefined && <Styled.PageTitleStyled>{title}</Styled.PageTitleStyled>}
      <Styled.TextStyled theme={theme}>{desc}</Styled.TextStyled>
      {children}
      <Styled.ButtonAreaStyled>
        <Styled.ButtonStyled theme="secondary" onClick={onClose} disabled={isLoading} width={buttonWidth}>
          {onCloseText}
        </Styled.ButtonStyled>
        <Styled.ButtonStyled loading={isLoading} onClick={onConfirm} disabled={onConfirmDisabled} width={buttonWidth}>
          {onConfirmText}
        </Styled.ButtonStyled>
      </Styled.ButtonAreaStyled>
    </Styled.WrapperStyled>
  </Modal>
);
