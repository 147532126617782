import { faFlag as unchecked } from '@fortawesome/free-regular-svg-icons';
import { faFlag as checked, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect } from 'react';

import { Button, Pagination, Table, Modal, Spacer } from 'components';
import { columns } from 'pages/Merchants/FintelCheck/ChangeMonitoring/ChangeMonitoringDetails/contracts/columns';
import ReviewStatus from 'pages/Merchants/FintelCheck/ChangeMonitoring/components/ReviewStatus';
import { FEEDBACK_MODAL, RULE_REVIEW_STATUSES } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/enums';
import { useEvaluationRulesReport } from 'pages/Merchants/FintelCheck/EvaluationRulesReport/hooks/useEvaluationRulesReport';
import { camelCaseToSentenceCase } from 'pages/Merchants/FintelCheck/FintelCheckChangeHistory/contracts';
import { useMonitoringRulesReport } from 'pages/Merchants/FintelCheck/MonitoringRulesReport/hooks/useMonitoringRulesReport';
import { colors } from 'styles/theme';

import {
  MODAL_SECTIONS,
  MODAL_TITLE,
  RULE_STATUS_SETTING_TITLE,
  RULE_STATUS_TITLE,
  CHECK_DATE_TITLE,
  FEEDBACK_TITLE,
  RULE_TEXT_TITLE,
  REPORT_RULE_STATUS,
  RULE_STATUS,
  RULE_TYPES,
} from './enums';
import { TextPhraseType } from './graphql/getMonitoringRuleStatusData';
import * as Styled from './styles';

type MonitoringDetailsModalProps = {
  hook: ReturnType<typeof useMonitoringRulesReport | typeof useEvaluationRulesReport>;
};

const MonitoringDetailsModal = ({ hook }: MonitoringDetailsModalProps): JSX.Element => {
  useEffect(() => {
    if (hook.isDetailedViewModalOpen) {
      hook.getReviewStatusData();
      hook.getTextPhrasesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hook.isDetailedViewModalOpen]);

  const ruleStatusColor = (ruleStatus: string): JSX.Element => {
    if (!ruleStatus || typeof ruleStatus !== 'string') {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    const formattedRuleStatus = _.startCase(ruleStatus.toLowerCase());

    switch (ruleStatus) {
      case RULE_STATUS.Pass:
        return <Styled.CheckResultsTextValueGreen>{formattedRuleStatus}</Styled.CheckResultsTextValueGreen>;
      case RULE_STATUS.Fail:
        return <Styled.CheckResultsTextValueRed>{formattedRuleStatus}</Styled.CheckResultsTextValueRed>;
      default:
        return <Styled.CheckResultsTextValue>{formattedRuleStatus}</Styled.CheckResultsTextValue>;
    }
  };

  return (
    <>
      <Styled.DetailedViewModal
        isOpen={hook.isDetailedViewModalOpen}
        closeModalFunction={() =>
          hook.isDetailedViewModalOpen && !hook.isFeedbackModalOpen && hook.closeDetailedViewModal()
        }
      >
        <Styled.DetailedViewContent>
          <Styled.DetailedViewHeader>
            <>
              <Styled.FlexColumn>
                <Styled.DetailedViewTitle>{MODAL_TITLE}</Styled.DetailedViewTitle>
                <Styled.DetailedViewSectionSubTitle>
                  You are viewing this Check’s latest Rule Status and Review Status History
                </Styled.DetailedViewSectionSubTitle>
              </Styled.FlexColumn>
              <Styled.DetailedViewCloseIcon icon={faTimes} onClick={() => hook.closeDetailedViewModal()} />
            </>
          </Styled.DetailedViewHeader>

          <Spacer size="small" direction="vertical" />

          <Styled.FlexRow>
            <Styled.RuleDetailsLabel>Rule Applied:</Styled.RuleDetailsLabel>
            <Styled.RuleDetailsLabel>{hook.detailedViewModalInfo?.ruleName}</Styled.RuleDetailsLabel>
          </Styled.FlexRow>

          <Spacer size="small" direction="vertical" />

          <Styled.FlexRow>
            <Styled.RuleDetailsLabel>URL Checked: </Styled.RuleDetailsLabel>
            <Styled.DetailedViewUrlButton href={hook.detailedViewModalInfo?.urlSearch} target="_blank" rel="noreferrer">
              {hook.detailedViewModalInfo?.urlSearch}
            </Styled.DetailedViewUrlButton>
          </Styled.FlexRow>

          <Spacer size="small" direction="vertical" />

          {/* Check Results Report */}
          <Styled.ContentWrapper>
            <Styled.DetailedViewSectionTitle>{MODAL_SECTIONS.CHECK_RESULTS}</Styled.DetailedViewSectionTitle>
            <Spacer size="small" direction="vertical" />

            <Styled.CheckResultsWrapper>
              <Styled.FlexRow>
                <Styled.CheckResultsTextLabel>{REPORT_RULE_STATUS}</Styled.CheckResultsTextLabel>
                {hook.detailedViewModalInfo?.ruleStatus && ruleStatusColor(hook.detailedViewModalInfo?.ruleStatus)}
              </Styled.FlexRow>
              <Spacer size="small" direction="vertical" />

              <Styled.FlexRow>
                <Styled.CheckResultsTextLabel>{CHECK_DATE_TITLE}</Styled.CheckResultsTextLabel>
                <Styled.CheckResultsTextValue>
                  {hook.detailedViewModalInfo?.createDatetime}
                </Styled.CheckResultsTextValue>
              </Styled.FlexRow>
            </Styled.CheckResultsWrapper>
          </Styled.ContentWrapper>

          <Spacer size="small" direction="vertical" />

          {/* Rule Eligiblity */}
          {hook.detailedViewModalInfo?.ruleType !== RULE_TYPES.MONITORING && (
            <Styled.ContentWrapper>
              <Styled.DetailedViewSectionTitle>{MODAL_SECTIONS.RULE_ELIGIBILITY}</Styled.DetailedViewSectionTitle>
              <Styled.CheckResultsWrapper>
                <Styled.DetailedViewSectionSubTitle style={{ color: colors.black }}>
                  These are the minimum requirements for a URL to be eligible for the rule to be applied
                </Styled.DetailedViewSectionSubTitle>
                <Styled.FlexRow>
                  <Styled.CheckResultsTextLabel>Eligibility requirement 1 (Brand Name):</Styled.CheckResultsTextLabel>
                  {hook.detailedViewModalInfo?.brandName}
                </Styled.FlexRow>
                {hook.detailedViewModalInfo?.eligibility?.map((item: { type: string; value: string }, index) => {
                  if (item.type.toLowerCase() === 'merchant name') return null;
                  return (
                    <div key={`${item.type}-${item.value}`}>
                      <Spacer size="small" direction="vertical" />

                      {hook.detailedViewModalInfo?.ruleType === RULE_TYPES.EVALUATION__PRODUCT ? (
                        <Styled.FlexRow>
                          <Styled.CheckResultsTextLabel>
                            {`Eligibility requirement ${index + 1} (${_.startCase(item.type.toLowerCase())}):`}
                          </Styled.CheckResultsTextLabel>
                          {item.value}
                        </Styled.FlexRow>
                      ) : (
                        <>
                          {index === 1 && (
                            <Styled.CheckResultsTextValue>
                              Text Matches at least one of these phrases on the page:
                            </Styled.CheckResultsTextValue>
                          )}
                          <Spacer size="small" direction="vertical" />

                          <Styled.CheckResultsTextValue>* {item.value}</Styled.CheckResultsTextValue>
                        </>
                      )}
                    </div>
                  );
                })}
              </Styled.CheckResultsWrapper>
            </Styled.ContentWrapper>
          )}

          <Spacer size="small" direction="vertical" />

          {/* Rule Criteria */}
          {hook.detailedViewModalInfo?.ruleStatus &&
          ![RULE_STATUS.NotApplicable, RULE_STATUS.Incomplete].includes(hook.detailedViewModalInfo?.ruleStatus) ? (
            <Styled.ContentWrapper>
              <Styled.DetailedViewSectionTitle>{MODAL_SECTIONS.RULE_CRITERIA}</Styled.DetailedViewSectionTitle>
              <Spacer size="small" direction="vertical" />

              {hook.detailedViewModalInfo?.ruleType === RULE_TYPES.EVALUATION__PRODUCT ? (
                <Styled.TextPhrasesWrapper>
                  <Styled.TextPhrasesColumnWrapper>
                    <Styled.TextPhrasesSmallColumn>
                      <Styled.TextPhrasesColumnLabel>{FEEDBACK_TITLE}</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesSmallColumn>
                    <Styled.TextPhrasesMediumColumn>
                      <Styled.TextPhrasesColumnLabel>Status</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesMediumColumn>
                    <Styled.TextPhrasesLargeColumn>
                      <Styled.TextPhrasesColumnLabel>Rule Requirement</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesLargeColumn>
                    <Styled.TextPhrasesMediumColumn>
                      <Styled.TextPhrasesText>
                        <Styled.TextPhrasesColumnLabel>Expected Match</Styled.TextPhrasesColumnLabel>
                      </Styled.TextPhrasesText>
                    </Styled.TextPhrasesMediumColumn>
                    <Styled.TextPhrasesLargeColumn>
                      <Styled.TextPhrasesColumnLabel>Required</Styled.TextPhrasesColumnLabel>
                    </Styled.TextPhrasesLargeColumn>
                  </Styled.TextPhrasesColumnWrapper>
                  {hook.textPhrasesData &&
                    hook.textPhrasesData.map((data: TextPhraseType) => (
                      <Styled.TextPhrasesValuesWrapper key={`${data.fieldName}-${data.textPhrase}`}>
                        <Styled.FeedbackButtonWrapper>
                          <Button onClick={() => hook.handleOnFeedbackModalOpen(data)} type="reset" theme="text-only">
                            <FontAwesomeIcon icon={data.hasFeedback ? checked : unchecked} color={colors.color1} />
                          </Button>
                        </Styled.FeedbackButtonWrapper>
                        <Styled.TextPhrasesMediumColumn>
                          <Styled.TextPhrasesValues>{data.status}</Styled.TextPhrasesValues>
                        </Styled.TextPhrasesMediumColumn>
                        <Styled.TextPhrasesLargeColumn>
                          <Styled.TextPhrasesValues>{camelCaseToSentenceCase(data.fieldName)}</Styled.TextPhrasesValues>
                        </Styled.TextPhrasesLargeColumn>
                        <Styled.TextPhrasesMediumColumn>
                          <Styled.TextPhrasesText>
                            <Styled.TextPhrasesValues>{data.textPhrase}</Styled.TextPhrasesValues>
                          </Styled.TextPhrasesText>
                        </Styled.TextPhrasesMediumColumn>
                        <Styled.TextPhrasesLargeColumn>
                          <Styled.TextPhrasesValues>{data.required === true ? 'Yes' : 'No'}</Styled.TextPhrasesValues>
                        </Styled.TextPhrasesLargeColumn>
                      </Styled.TextPhrasesValuesWrapper>
                    ))}
                </Styled.TextPhrasesWrapper>
              ) : (
                <>
                  {hook.ruleSettingsData && (
                    <Styled.CheckResultsWrapper>
                      <Styled.FlexRow>
                        <Styled.CheckResultsTextLabel>{RULE_STATUS_SETTING_TITLE}</Styled.CheckResultsTextLabel>
                        <Styled.CheckResultsTextValue>{hook.ruleSettingsData}</Styled.CheckResultsTextValue>
                      </Styled.FlexRow>
                      <Spacer size="medium" direction="vertical" />
                    </Styled.CheckResultsWrapper>
                  )}
                  <Styled.TextPhrasesWrapper>
                    <Styled.TextPhrasesColumnWrapper>
                      <Styled.TextPhrasesSmallColumn>
                        <Styled.TextPhrasesColumnLabel>{FEEDBACK_TITLE}</Styled.TextPhrasesColumnLabel>
                      </Styled.TextPhrasesSmallColumn>
                      <Styled.TextPhrasesMediumColumn>
                        <Styled.TextPhrasesColumnLabel>{RULE_STATUS_TITLE}</Styled.TextPhrasesColumnLabel>
                      </Styled.TextPhrasesMediumColumn>
                      <Styled.TextPhrasesLargeColumn>
                        <Styled.TextPhrasesColumnLabel>{RULE_TEXT_TITLE}</Styled.TextPhrasesColumnLabel>
                      </Styled.TextPhrasesLargeColumn>
                    </Styled.TextPhrasesColumnWrapper>
                    {hook.textPhrasesData &&
                      hook.textPhrasesData.map((data: TextPhraseType) => (
                        <Styled.TextPhrasesValuesWrapper key={`${data.fieldName}-${data.textPhrase}`}>
                          <Styled.FeedbackButtonWrapper>
                            <Button onClick={() => hook.handleOnFeedbackModalOpen(data)} type="reset" theme="text-only">
                              <FontAwesomeIcon icon={data.hasFeedback ? checked : unchecked} color={colors.color1} />
                            </Button>
                          </Styled.FeedbackButtonWrapper>
                          <Styled.TextPhrasesMediumColumn>
                            <Styled.TextPhrasesValues>{data.status}</Styled.TextPhrasesValues>
                          </Styled.TextPhrasesMediumColumn>
                          <Styled.TextPhrasesLargeColumn style={{ flex: '10 0 0' }}>
                            <Styled.TextPhrasesValues>{data.textPhrase}</Styled.TextPhrasesValues>
                          </Styled.TextPhrasesLargeColumn>
                        </Styled.TextPhrasesValuesWrapper>
                      ))}
                  </Styled.TextPhrasesWrapper>
                </>
              )}
            </Styled.ContentWrapper>
          ) : null}

          <Spacer size="small" direction="vertical" />

          {/* Review Status Report */}
          <Styled.ContentWrapper>
            {hook.reviewStatusTableData && hook.reviewStatusTableData.length > 0 && (
              <>
                <Styled.DetailedViewSectionTitle>{MODAL_SECTIONS.REVIEW_STATUS}</Styled.DetailedViewSectionTitle>
                <Spacer size="small" direction="vertical" />

                <Table
                  columns={columns()}
                  data={hook.reviewStatusTableData || []}
                  isLoading={hook.getMonitoringReviewStatusDataLoading}
                  loadWithData={hook.reviewStatusTableData ? hook.reviewStatusTableData.length > 0 : false}
                />
                <Pagination
                  currentPage={hook.currentReviewStatusPage}
                  total={hook.totalReviewStatusPages}
                  onPageChange={hook.handleOnChangeCurrentPage}
                />
              </>
            )}

            <Spacer size="small" direction="vertical" />

            <ReviewStatus
              selectedReviewStatus={hook.selectedReviewStatus}
              setReviewStatusNote={hook.setReviewStatusNote}
              reviewStatusNote={hook.reviewStatusNote}
              shouldDisableSetReviewStatusButton={hook.shouldDisableSetReviewStatusButton}
              setReviewStatusLoading={hook.setReviewStatusLoading}
              setSelectedReviewStatus={hook.setSelectedReviewStatus}
              dropdownOptions={RULE_REVIEW_STATUSES.filter(
                (status) => status.value !== 'All Open Statuses' && status.value !== 'First Check'
              )}
              onClickAction={() =>
                hook.handleOnSetReviewStatus(hook.selectedReviewStatus?.value || '', hook.detailedViewModalInfo?.id)
              }
            />
          </Styled.ContentWrapper>
        </Styled.DetailedViewContent>
      </Styled.DetailedViewModal>

      <Modal
        isOpen={hook.isFeedbackModalOpen}
        closeModalFunction={() => {
          hook.resetFeedbackModalValues();
        }}
      >
        <Styled.FeedbackModalContent>
          <Styled.FeedbackModalTitle>{FEEDBACK_MODAL.TITLE}</Styled.FeedbackModalTitle>

          <Styled.FeedbackModalText>{FEEDBACK_MODAL.DESCRIPTION}</Styled.FeedbackModalText>

          <Styled.FeedbackModalRadioWrapper>
            <Styled.FeedbackModalRadio
              name={FEEDBACK_MODAL.YES}
              value={FEEDBACK_MODAL.YES}
              label={FEEDBACK_MODAL.YES}
              checked={hook.isFeedbackRuleCorrect === true}
              onChange={() => hook.setIsFeedbackRuleCorrect(true)}
            />
            <Styled.FeedbackModalRadio
              name={FEEDBACK_MODAL.NO}
              value={FEEDBACK_MODAL.NO}
              label={FEEDBACK_MODAL.NO}
              checked={hook.isFeedbackRuleCorrect === false}
              onChange={() => hook.setIsFeedbackRuleCorrect(false)}
            />
          </Styled.FeedbackModalRadioWrapper>
          <Styled.SubmitFeedbackButtonWrapper>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                hook.resetFeedbackModalValues();
              }}
              theme="secondary"
              width="5rem"
              disabled={hook.setRuleFeedbackLoading}
            >
              {FEEDBACK_MODAL.CANCEL}
            </Button>
            <Button
              onClick={() => hook.handleFeedbackSubmit()}
              theme="primary"
              width="5rem"
              disabled={hook.isFeedbackRuleCorrect === undefined}
              loading={hook.setRuleFeedbackLoading}
            >
              {FEEDBACK_MODAL.SUBMIT}
            </Button>
          </Styled.SubmitFeedbackButtonWrapper>
        </Styled.FeedbackModalContent>
      </Modal>
    </>
  );
};

export default MonitoringDetailsModal;
