type ColumnKeys =
  | 'merchantId'
  | 'merchantName'
  | 'merchantURL'
  | 'impressions'
  | 'ctr'
  | 'clicks'
  | 'uniqueClicks'
  | 'uniqueImpressions'
  | 'epc'
  | 'conversions'
  | 'newPendingTransactions'
  | 'oldPendingTransactions'
  | 'declinedTransactions'
  | 'softDeclinedTransactions'
  | 'verifiedTransactions'
  | 'oldApprovedTransactions'
  | 'approvedTransactions'
  | 'totalTransactions'
  | 'approvalRate'
  | 'pendingGrossValue'
  | 'totalGrossValue'
  | 'totalNetValue'
  | 'commissionCpa'
  | 'commission'
  | 'totalCommission'
  | 'publisherId'
  | 'publisherName'
  | 'productId'
  | 'productName'
  | 'commissionAmount'
  | 'id'
  | 'createdAt'
  | 'processedDate'
  | 'transactionStatus'
  | 'firstTransaction'
  | 'note'
  | 'country'
  | 'acid'
  | 'trackingProfileId'
  | 'ip'
  | 'osBrowser'
  | 'userAgent'
  | 'productCategory'
  | 'thirdPartyUrl'
  | 'systemUsageFee'
  | 'trackingProfileUrl'
  | 'processedBy'
  | 'customerId'
  | 'orderId'
  | 'adId'
  | 'netSale'
  | 'grossSale'
  | 'commissionIds'
  | 'commissionTypes'
  | 'currency'
  | 'referralUrl'
  | 'merchantName'
  | 'merchantId';

type CheckboxType = {
  label: string;
  checked: boolean;
};

export type EditColumnProps = Record<ColumnKeys, CheckboxType>;

export const useEditColumnsReducer = (state: EditColumnProps, { data }: ComponentReducerType): EditColumnProps => ({
  ...state,
  ...data,
});
