import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';

import { Button, Pagination, Placeholder, Table, Modal } from '../../../../../../components';
import * as Styled from '../styles';
import { columns } from '../../contracts';
import { MEMBERSHIPS } from '../../enum';
import { MERCHANT_INVITATIONS } from '../MerchantInvitations/enum';
import type { useApplications } from '../../hooks';

type ApplicationPropsType = {
  hook: ReturnType<typeof useApplications>;
};

const ApplicationsTab = ({ hook }: ApplicationPropsType) => (
  <Styled.WrapperStyled>
    <Styled.TitleStyled>{MEMBERSHIPS.APPLICATIONS.TITLE}</Styled.TitleStyled>

    <Styled.FiltersStyled>
      <Styled.FiltersLabelStyled>{MEMBERSHIPS.APPLICATIONS.FILTERS}</Styled.FiltersLabelStyled>

      <Styled.SelectStyled
        name="status"
        selected={hook.hookStatus}
        onChange={hook.hookSetStatus}
        options={hook.hookStatusList}
        placeholder={MEMBERSHIPS.APPLICATIONS.PLACEHOLDER.ALL_STATUSES}
      />
      <Styled.SelectStyled
        name="target"
        selected={hook.hookTargetMarket}
        onChange={hook.hookSetTargetMarket}
        options={hook.hookAudienceMarkets}
        placeholder={MEMBERSHIPS.APPLICATIONS.PLACEHOLDER.TARGET_MARKET}
      />
      <Styled.SelectStyled
        name="category"
        selected={hook.hookProductCategory}
        onChange={hook.hookSetProductCategory}
        placeholder={MEMBERSHIPS.APPLICATIONS.PLACEHOLDER.PRODUCT_CATEGORY}
        options={hook.hookCategoryList}
      />

      <Button theme="text-only" onClick={hook.hookClearForm}>
        {MEMBERSHIPS.APPLICATIONS.CLEAR_FORM}
      </Button>
    </Styled.FiltersStyled>

    {hook.hookTableData.length === 0 && !hook.hookPageLoading && (
      <Placeholder
        title={MEMBERSHIPS.APPLICATIONS.NO_DATA.TITLE}
        subtitle={MEMBERSHIPS.APPLICATIONS.NO_DATA.SUBTITLE}
      />
    )}
    {(hook.hookTableData.length !== 0 || hook.hookPageLoading) && (
      <>
        <Table
          columns={columns}
          data={hook.hookTableData}
          isLoading={hook.hookPageLoading}
          setModalInfo={hook.hookHandleOpenModal}
          sortColumn={hook.hookSort}
          onSort={hook.hookOnSort}
          onRowClick={hook.hookRowClick}
        />
        <Pagination
          currentPage={hook.hookCurrentPage}
          total={hook.hookTotalPages}
          onPageChange={hook.hookSetCurrentPage}
        />
      </>
    )}
    <Modal isOpen={hook.hookIsOpen}>
      <Styled.ModalAreaStyled>
        <Styled.ModalTitleStyled>{`${MERCHANT_INVITATIONS.MODAL.MESSAGE_TITLE} ${hook.hookMessage.name}`}</Styled.ModalTitleStyled>
        <Styled.ModalHeaderStyled>{MERCHANT_INVITATIONS.MODAL.MESSAGE_SUBJECT}</Styled.ModalHeaderStyled>
        <Styled.MessageTextStyled>{hook.hookMessage.subject}</Styled.MessageTextStyled>
        <Styled.ModalHeaderStyled>{MERCHANT_INVITATIONS.MODAL.MESSAGE}</Styled.ModalHeaderStyled>
        <Styled.MessageTextStyled>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{hook.hookMessage.message}</ReactMarkdown>
        </Styled.MessageTextStyled>
        <Styled.ButtonAreaStyled>
          <Button theme="secondary" onClick={hook.hookCancelHandler}>
            {MERCHANT_INVITATIONS.BUTTON.CANCEL}
          </Button>
          <Button onClick={hook.hookSaveHandler}>{MERCHANT_INVITATIONS.BUTTON.TERMS}</Button>
        </Styled.ButtonAreaStyled>
      </Styled.ModalAreaStyled>
    </Modal>
  </Styled.WrapperStyled>
);

export default ApplicationsTab;
