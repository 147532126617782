import React from 'react';

import { CellProps } from '../../types';
import * as Styled from '../../styles';

export const NameCell = ({ val }: CellProps) => (
  <Styled.NameCellStyled>
    <p>{val.name}</p>
    <Styled.URLStyled>{val?.url}</Styled.URLStyled>
  </Styled.NameCellStyled>
);
