import { useQuery, useLazyQuery } from '@apollo/client';
import { useEffect, useState, useMemo } from 'react';

import { csvGenerator } from 'utils';

import { GET_BULK_IMPORT_LOG_DETAILS, GET_BULK_IMPORT_LOG_CSV } from '../graphql/queries';
import { BulkUploadOutputType } from '../../AddTransactions/Tabs/BulkImportLog/types';

type TShowBulkImportLogDetailsProps = {
  details?: BulkUploadOutputType;
};

type TDisplayedRowType = {
  status: string;
  message: string;
};

const filterOptions: SelectOption[] = [
  { label: 'All Status', value: 'AllStatus' },
  { label: 'Failed', value: 'Failed' },
  { label: 'Success', value: 'Success' },
];

const defaultFilter: SelectOption = filterOptions[0];

export const useShowBulkImportLogDetails = ({ details }: TShowBulkImportLogDetailsProps) => {
  const [tableData, setTableData] = useState<TDisplayedRowType[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState<SelectOption>(defaultFilter);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data: bulkUploadData, loading: bulkUploadLoading } = useQuery(GET_BULK_IMPORT_LOG_DETAILS, {
    variables: {
      bulkUploadTransactionDataId: details?.id,
    },
    onError(err) {
      setErrorMessage(err.message);
    },
  });

  const [getBulkImportCSV, { loading: bulkImportCSVLoading }] = useLazyQuery(GET_BULK_IMPORT_LOG_CSV);

  const bothTypes = useMemo(() => {
    let bothStatus: TDisplayedRowType[] = [];
    if (bulkUploadData?.bulkUploadTransactionData) {
      bothStatus = bulkUploadData.bulkUploadTransactionData.uploadErrors?.concat(
        bulkUploadData.bulkUploadTransactionData.processedTransactions
      );
    }
    return bothStatus;
  }, [bulkUploadData]);

  const showBulkImportLogCSVHandler = async (): Promise<void> => {
    setErrorMessage('');
    const { data } = await getBulkImportCSV({
      variables: {
        input: {
          id: details?.id,
          status: selectedStatus.value,
        },
      },
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data?.bulkUploadCSV) {
      csvGenerator(data.bulkUploadCSV.blob, 'Bulk Upload Log Details');
    }
  };

  const setPageHandler = (pageValue: number): void => {
    setCurrentPage(pageValue);
  };

  const getCurrentTableData = (): void => {
    const indexStart = (currentPage - 1) * 10;
    if (bulkUploadData?.bulkUploadTransactionData) {
      if (selectedStatus.value === filterOptions[0].value) {
        setTableData(bothTypes.slice(indexStart, indexStart + 10));
        setTotalPages(Math.ceil(bulkUploadData.bulkUploadTransactionData.count / 10));
      } else if (selectedStatus.value === filterOptions[1].value) {
        setTableData(bulkUploadData.bulkUploadTransactionData.uploadErrors.slice(indexStart, indexStart + 10));
        setTotalPages(Math.ceil(bulkUploadData.bulkUploadTransactionData.uploadErrors.length / 10));
      } else if (selectedStatus.value === filterOptions[2].value) {
        setTableData(bulkUploadData.bulkUploadTransactionData.processedTransactions.slice(indexStart, indexStart + 10));
        setTotalPages(Math.ceil(bulkUploadData.bulkUploadTransactionData.processedTransactions.length / 10));
      }
    }
  };

  const setFilterHandler = (value: SelectOption): void => {
    setCurrentPage(1);
    setSelectedStatus(value);
  };

  useEffect(() => {
    getCurrentTableData();
  }, [selectedStatus, currentPage, bulkUploadData]);

  return {
    hookTableData: tableData,

    hookTotalPages: totalPages,
    hookPage: currentPage,
    hookSetPage: setPageHandler,

    hookGetCSV: showBulkImportLogCSVHandler,
    hookGetCSVLoading: bulkImportCSVLoading,

    hookSelectedStatus: selectedStatus,
    hookSetFilter: setFilterHandler,
    hookFilterOptions: filterOptions,

    hookErrorMessage: errorMessage,
    hookIsLoading: bulkUploadLoading,
  };
};
