import { isStringEmptyOrUndefined } from './isStringEmptyOrUndefined';

export const compareProductFeed = (keyName: any, order: 'asc' | 'desc' | undefined = 'asc') => {
  const nextDirection: number = order === 'asc' ? -1 : 1;
  const compare = (a: any, b: any) => {
    let compareCondition = false;
    let bValue;
    let aValue;
    switch (keyName) {
      case 'trackingProfileId': {
        compareCondition = Number(b[keyName]) < Number(a[keyName]);
        break;
      }
      case 'merchants': {
        bValue = isStringEmptyOrUndefined(b[keyName][0]?.companyName) ? 'All Merchants' : b[keyName][0]?.companyName;
        aValue = isStringEmptyOrUndefined(a[keyName][0]?.companyName) ? 'All Merchants' : a[keyName][0]?.companyName;
        compareCondition = bValue.toLowerCase() < aValue.toLowerCase();
        break;
      }
      case 'productCategories': {
        bValue = isStringEmptyOrUndefined(b[keyName]?.join(', ')) ? 'All Categories' : b[keyName]?.join(', ');
        aValue = isStringEmptyOrUndefined(a[keyName]?.join(', ')) ? 'All Categories' : a[keyName]?.join(', ');
        compareCondition = bValue.toLowerCase() < aValue.toLowerCase();
        break;
      }
      case 'subcategories': {
        bValue = isStringEmptyOrUndefined(b[keyName]?.join(', ')) ? 'All Subcategories' : b[keyName]?.join(', ');
        aValue = isStringEmptyOrUndefined(a[keyName]?.join(', ')) ? 'All Subcategories' : a[keyName]?.join(', ');
        compareCondition = bValue.toLowerCase() < aValue.toLowerCase();
        break;
      }
      case 'region': {
        bValue = isStringEmptyOrUndefined(b.regionCountry) ? 'International' : b.regionCountry;
        aValue = isStringEmptyOrUndefined(a.regionCountry) ? 'International' : a.regionCountry;
        compareCondition = bValue.toLowerCase() < aValue.toLowerCase();
        break;
      }
      default: {
        compareCondition = b[keyName] < a[keyName];
        break;
      }
    }
    return compareCondition ? nextDirection : nextDirection * -1;
  };
  return compare;
};
