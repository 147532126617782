import React from 'react';

import { Button, ErrorBanner, InputPhone } from '../../../../components';
import Progress from '../../../../components/Progress';
import { imageList, path } from '../../../../utils';
import { SIGN_UP_ACTIONS } from '../../reducers';
import { SIGN_UP } from '../enums';

import * as Styled from './styles';
import { useCompanyInfo } from './hooks';
import { CONTACT_INFO } from './enums';

const ContactInfo = () => {
  const hook = useCompanyInfo();

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img src={imageList.logoFull.src} alt="logo" />
        </figure>

        <Progress step={2} />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
          <h1>{SIGN_UP.TITLE}</h1>
          <h2>
            <pre>{`Step 2   ${path.createAccountStep2.name}`}</pre>
          </h2>
          <Styled.FormStyled>
            <Styled.InputTwoColumnStyled
              label="company name"
              required
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyName: e.target.value },
                })
              }
              value={hook.hookContextState.companyName}
              error={hook.hookInputErrors?.companyName}
            />
            <Styled.InputTwoColumnStyled
              label="address 1"
              required
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyAddress1: e.target.value },
                })
              }
              value={hook.hookContextState.companyAddress1}
              error={hook.hookInputErrors?.companyAddress}
            />
            <Styled.InputStyled
              label="address 2"
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyAddress2: e.target.value },
                })
              }
              value={hook.hookContextState.companyAddress2}
            />
            <Styled.InputStyled
              label="city"
              required
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyCity: e.target.value },
                })
              }
              value={hook.hookContextState.companyCity}
              error={hook.hookInputErrors?.companyCity}
            />
            <Styled.SelectStyled
              label="country"
              required
              onChange={(e: SelectOption) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyCountry: e, companyState: { value: '', label: '' } },
                })
              }
              selected={hook.hookContextState.companyCountry}
              options={hook.hookCountryList}
              error={hook.hookInputErrors?.companyCountry}
            />
            <Styled.SelectStyled
              label="state/province"
              required
              onChange={(e: SelectOption) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyState: e },
                })
              }
              selected={hook.hookContextState.companyState}
              options={hook.hookRegionList}
              isDisabled={!hook.hookContextState.companyCountry}
              error={hook.hookInputErrors?.companyState}
            />
            <Styled.InputStyled
              label="zip/postal code"
              required
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyZIP: e.target.value },
                })
              }
              value={hook.hookContextState.companyZIP}
              error={hook.hookInputErrors?.companyZip}
            />
            <InputPhone
              label="phone number"
              required
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { companyPhone: e || '' },
                })
              }
              value={hook.hookContextState.companyPhone}
              error={hook.hookInputErrors?.companyPhone}
            />
            <Button onClick={() => hook.hookNavigate(path.createAccountStep1.href)} theme="secondary">
              {CONTACT_INFO.BACK}
            </Button>
            <Button onClick={hook.hookNavigateNextPageHandler}>{CONTACT_INFO.NEXT}</Button>
          </Styled.FormStyled>
        </Styled.RightContentStyled>
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};

export default ContactInfo;
