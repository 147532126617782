import { gql } from '@apollo/client';

export const SAVE_PERFORMANCE_REPORT = gql`
  mutation createUpdateReportProfile($save: ReportProfileInput) {
    createUpdateReportProfile(input: $save) {
      id
    }
  }
`;

export type SPRInputType = {
  save: {
    report: string;
    filters: {
      field: string;
      value: {
        label: string;
        value: string;
      };
    }[];
    columns: SelectOption[];
    user: {
      id: string;
      email: string;
    };
    name: string;
    description: string;
    company?: number;
    frequency?: string;
    every?: string;
    id?: string;
  };
};

export type SPROutputType = {
  createUpdateReportProfile: {
    id: string;
  };
};
