import React from 'react';
import { faClose, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../../../../../components/Button';
import { Select } from '../../../../../../../components/Input';
import { PageTitle } from '../../../../../../../components/PageTitle';
import { VisibilityHidden } from '../../../../../../../components/VisibilityHidden';
import { SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS } from '../../enums';
import { CustomDropdownCalendar } from '../../../../../../../components/Input/CustomDropdownCalendar';

import * as Styled from './styles';

type SaveReportStepTwoProps = {
  scheduleFrequency: any;
  scheduleFrequencyFn: (value: any) => void;
  scheduleFrequencyOptions: any;
  closeFn: () => void;
  backFn: () => void;
  selectDetailedFrequencyFn: (value: any) => void;
  selectedDetailedFrequency: any;
  saveFn: () => void;
};

const SaveReportStepTwo = ({
  closeFn,
  backFn,
  scheduleFrequency,
  scheduleFrequencyFn,
  scheduleFrequencyOptions,
  selectDetailedFrequencyFn,
  selectedDetailedFrequency,
  saveFn,
}: SaveReportStepTwoProps) => (
  <Styled.WrapperStyled>
    <Styled.HeaderStyled>
      <PageTitle>Save Report</PageTitle>

      <Styled.ButtonStyled onClick={closeFn}>
        <FontAwesomeIcon icon={faClose} />
        <VisibilityHidden>Close</VisibilityHidden>
      </Styled.ButtonStyled>
    </Styled.HeaderStyled>

    <Styled.ContentStyled>
      <Select
        onChange={scheduleFrequencyFn}
        label="Schedule Email Delivery"
        description="Receive a CSV of your reports via automatic email delivery."
        selected={scheduleFrequency}
        options={scheduleFrequencyOptions}
      />

      {scheduleFrequency.value === 'Weekly' && (
        <Select
          label="Frequency"
          options={SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS}
          onChange={selectDetailedFrequencyFn}
          selected={selectedDetailedFrequency}
        />
      )}

      {scheduleFrequency.value === 'Monthly' && (
        <CustomDropdownCalendar
          label="Frequency"
          onChange={selectDetailedFrequencyFn}
          selected={selectedDetailedFrequency}
        />
      )}
    </Styled.ContentStyled>

    <Styled.FooterAreaStyled>
      <span>Step 2 of 2</span>

      <Styled.ButtonAreaStyled>
        <Button onClick={backFn} theme="secondary">
          Back
        </Button>
        <Button onClick={saveFn}>
          <FontAwesomeIcon icon={faSave} style={{ marginRight: 5 }} />
          Save Report Profile
        </Button>
      </Styled.ButtonAreaStyled>
    </Styled.FooterAreaStyled>
  </Styled.WrapperStyled>
);

export default SaveReportStepTwo;
