import styled from 'styled-components';

import { Button } from '../../../Button';
import { InputPhone, InputText, Select } from '../../../Input';

type GridProps = {
  readonly grids: number;
};

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 2.5rem;
  margin-top: 2rem;
`;

export const InputTextStyled = styled(InputText)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;

export const InputPhoneStyled = styled(InputPhone)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;

export const SelectStyled = styled(Select)<GridProps>`
  grid-column: span ${({ grids }) => grids};
`;

export const ButtonStyled = styled(Button)`
  grid-column: span 1;
  height: 48px;
  align-self: flex-end;
`;

export const ButtonWrapper = styled.div`
  margin-top: 21px;
  max-height: 38px;
  display: flex;
  flex-direction: column;
  color: #6edb66;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
`;
