import * as am5 from '@amcharts/amcharts5';

import environment from 'config/environment';
import { IAppInitializer } from 'types';

export const amchartsInitializer: IAppInitializer = {
  name: 'amcharts',
  priority: 2,
  initialize: () => {
    am5.addLicense(environment.app.amChartGraphLicense);
    am5.addLicense(environment.app.amChartMapLicense);
  },
};
