import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserInfo } from '../../../hooks';
import { AD_TYPES, MERCHANT_PREFIX, path, USER_TYPES_ID, ERROR_MESSAGES } from '../../../utils';
import { LIST_AD_TYPES } from '../graphql/queries';
import { LIST_MERCHANTS } from '../graphql/queries/listMerchants';

type MerchantProgram = {
  id: string;
};

export const useAddAdModal = (isOpen: boolean) => {
  const [adTypeList, setAdTypeList] = useState([]);
  const [merchantList, setMerchantList] = useState([]);
  const [selectedAdType, setSelectedAdType] = useState<SelectOption | undefined>({ label: 'Banner', value: 'Banner' });
  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption | undefined>(undefined);
  const [selectedProgram, setSelectedProgram] = useState<MerchantProgram | undefined>(undefined);
  const [selectedMerchantError, setSelectedMerchantError] = useState('');

  const userHook = useUserInfo();
  const navigate = useNavigate();

  const [getAdTypes, { loading: adTypeLoading }] = useLazyQuery(LIST_AD_TYPES);
  const [getMerchants, { loading: merchantLoading }] = useLazyQuery(LIST_MERCHANTS);

  const adTypeFormatList = (list: any) => {
    const formatted = list.map((item: any) => ({
      label: item.type,
      value: item.type,
    }));

    return formatted;
  };

  const merchantFormatList = (list: any) => {
    const formatted = list.map((item: any) => ({
      label: item?.companyName || item?.id,
      value: item.id,
      program: item?.program?.id,
    }));

    return formatted;
  };

  const getAdTypesHandler = async () => {
    const { data } = await getAdTypes();
    if (data?.getAdTypes?.adTypes) setAdTypeList(adTypeFormatList(data?.getAdTypes?.adTypes));
  };

  const getMerchantsHandler = async () => {
    const { data } = await getMerchants({
      variables: {
        input: {
          filter: {
            accountStatusType: 'Approved',
          },
          sortBy: 'id',
          options: { order: 'ASC' },
        },
      },
    });
    if (data?.listSearchMerchants?.companies) {
      setMerchantList(merchantFormatList(data.listSearchMerchants.companies));
    }
  };

  const onCloseHandler = (onClose: () => void) => {
    setSelectedMerchant(undefined);
    onClose();
  };

  const setSelectedAdTypeHandler = (item: any) => {
    setSelectedAdType({
      label: item.label,
      value: item.value,
    });
  };

  const setSelectedMerchantHandler = (item: any) => {
    setSelectedMerchantError('');
    setSelectedMerchant({
      label: item.label,
      value: item.value,
    });
    setSelectedProgram({
      id: item.program,
    });
  };

  const onConfirmHandler = () => {
    let pathDirect = '';

    if (
      userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN &&
      userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT &&
      selectedMerchant === undefined
    ) {
      setSelectedMerchantError(ERROR_MESSAGES.MERCHANT_NAME);
      return;
    }
    setSelectedMerchantError('');

    switch (selectedAdType?.value) {
      case AD_TYPES.LINK:
        pathDirect =
          userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN &&
          userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT
            ? path.addNewAdLink.href
            : `${MERCHANT_PREFIX}${path.addNewAdLink.href}`;
        break;
      case AD_TYPES.BANNER:
        pathDirect =
          userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN &&
          userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT
            ? path.addNewAdBanner.href
            : `${MERCHANT_PREFIX}${path.addNewAdBanner.href}`;
        break;
      case AD_TYPES.HTML:
        pathDirect =
          userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN &&
          userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT
            ? path.addNewAdHtml.href
            : `${MERCHANT_PREFIX}${path.addNewAdHtml.href}`;
        break;
      default:
    }

    if (userHook.hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN && !userHook.hookWhoAmI.programId) {
      navigate(pathDirect, {
        state: {
          adType: selectedAdType,
          merchantId: selectedMerchant?.value,
          program: selectedProgram?.id,
        },
      });
    } else {
      navigate(pathDirect, {
        state: {
          adType: selectedAdType,
          merchant: userHook.hookWhoAmI.companyId,
          program: userHook.hookWhoAmI.programId,
        },
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      getAdTypesHandler();
      getMerchantsHandler();
    }
  }, [isOpen]);

  return {
    hookOnClose: onCloseHandler,
    hookAdTypeList: adTypeList,
    hookMerchantList: merchantList,
    hookLoading: merchantLoading || adTypeLoading,
    hookSelectedAdType: selectedAdType,
    hookSelectedMerchant: selectedMerchant,
    hookSelectedMerchantError: selectedMerchantError,
    hookSetSelectedAdType: setSelectedAdTypeHandler,
    hookSetSelectedMerchant: setSelectedMerchantHandler,
    hookOnConfirm: onConfirmHandler,
    hookUserType: userHook.hookUserInfo.userTypesId,
    hookWhoAmI: userHook.hookWhoAmI,
  };
};
