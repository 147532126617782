import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LIST_COUNTRIES } from '../../graphql';
import { useSignUp } from '../../hooks';
import { ERROR_TYPES, path, useValidation } from '../../../../../utils';
import type { ListCountriesOutput, LCCountryType } from '../../graphql';
import type { CountryType } from '../types';

export const useCompanyInfo = () => {
  const navigate = useNavigate();
  const validator = useValidation();
  const { contextState, contextDispatcher } = useSignUp();

  const [countryList, setCountryList] = useState<CountryType[]>([]);
  const [regionList, setRegionList] = useState<SelectOption[]>([]);
  const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});
  const [failedNext, setFailedNext] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getCountryList, { loading: loadingCountryList }] = useLazyQuery<ListCountriesOutput>(LIST_COUNTRIES);

  const nextDisabled = (() => {
    if (!contextState.companyName) return true;
    if (!contextState.companyAddress1) return true;
    if (!contextState.companyCity) return true;
    if (!contextState.companyState) return true;
    if (!contextState.companyCountry) return true;
    if (!contextState.companyZIP) return true;
    if (!contextState.companyPhone) return true;
    return false;
  })();

  const contactInfoValues: { [key: string]: string } = {
    companyName: contextState.companyName,
    companyAddress: contextState.companyAddress1,
    companyCity: contextState.companyCity,
    companyCountry: contextState.companyCountry.value,
    country: contextState.companyCountry.value, // for validator to use
    companyState: contextState.companyState.value,
    companyPhone: contextState.companyPhone,
    companyZip: contextState.companyZIP,
  };

  const contactInfoFields = {
    companyName: ERROR_TYPES.NOT_EMPTY,
    companyAddress: ERROR_TYPES.ADDRESS,
    companyCity: ERROR_TYPES.NOT_EMPTY,
    companyCountry: ERROR_TYPES.NOT_EMPTY,
    companyState: ERROR_TYPES.NOT_EMPTY,
    companyPhone: ERROR_TYPES.PHONE,
    companyZip: ERROR_TYPES.POSTAL_CODE,
  };

  const validateForm = () => validator.validateAll(contactInfoValues, contactInfoFields, setInputErrors, true);

  const countryFormatList = (list: LCCountryType[]) => {
    const formatted = list.map((item) => ({
      ...item,
      label: item.name,
      value: item.name,
    }));

    return formatted;
  };

  const regionFormatList = (list: CountryType[]) => {
    const formatted = list[0]?.subdivisions?.map((item) => ({
      label: item.name,
      value: item.name,
    }));

    return formatted;
  };

  const getCountriesHandler = async () => {
    const { data } = await getCountryList({
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data?.getCountries?.countries !== undefined) {
      setErrorMessage('');
      setCountryList(countryFormatList(data.getCountries.countries));
    }
  };

  const navigateHandler = (url: string) => {
    navigate(url);
  };

  const navigateNextPageHandler = async () => {
    setFailedNext(true);
    if (validateForm()) navigate(path.createAccountStep3.href);
  };

  // Navigate back to first Page if Context is lost (eg. Reloaded Page)
  if (!contextState.userEmail) {
    navigate(path.createAccountStep1.href);
  }

  useEffect(() => {
    getCountriesHandler();
  }, []);

  useEffect(() => {
    const findCountry = countryList.filter((x) => x.label === contextState.companyCountry.label);
    setRegionList(regionFormatList(findCountry));
  }, [contextState.companyCountry]);

  useEffect(() => {
    if (failedNext) validateForm();
  }, [contextState]);

  return {
    hookCountryLoading: loadingCountryList,
    hookErrorMessage: errorMessage,
    hookCountryList: countryList,
    hookRegionList: regionList,
    hookContextState: contextState,
    hookContextDispatcher: contextDispatcher,
    hookInputErrors: inputErrors,
    hookNavigate: navigateHandler,
    hookNextDisabled: nextDisabled,
    hookNavigateNextPageHandler: navigateNextPageHandler,
  };
};
