import React from 'react';

import * as Styled from './styles';

type LoadingPageProps = {
  className?: string;
};

export const LoadingPage = ({ className }: LoadingPageProps) => (
  <Styled.WrapperStyled className={className}>
    <Styled.EllipsisStyled>
      <Styled.DotsStyled />
      <Styled.DotsStyled />
      <Styled.DotsStyled />
      <Styled.DotsStyled />
    </Styled.EllipsisStyled>
  </Styled.WrapperStyled>
);
