import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Button, Modal } from '../../components';
import { colors, fonts } from '../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 390px calc(100% - 390px);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color1};
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightContentStyled = styled.div`
  width: 65%;
  display: flex;
  flex-flow: column nowrap;

  label {
    margin-bottom: 2rem;
  }

  h1 {
    color: ${colors.color1};
    margin-bottom: 2rem;
    ${fonts.gotham.H36M}
  }
`;

export const FormStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 3rem;
`;

export const RowStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;

  &:first-of-type {
    margin-bottom: 2rem;
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  padding: 1rem;

  &:first-of-type {
    margin-right: 2rem;
  }
`;

export const ModalStyled = styled(Modal)`
  div {
    padding: 5rem 9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    color: ${colors.color1};
    margin: 2rem 0;
    ${fonts.gotham.H32M}
  }

  span:first-of-type {
    margin-bottom: 2rem;
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  width: 50px;
`;

export const ConfirmEmailStyled = styled.span`
  ${fonts.lato.H16B}
  margin-top: 1rem;
`;
