import styled from 'styled-components';

import { fonts } from '../../styles/theme';
import { Button } from '../Button';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const TextStyled = styled.p`
  margin: 2.5rem 0;
  ${fonts.lato.H16}
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-right: 1rem;
`;
