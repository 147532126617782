import { gql } from '@apollo/client';

export const GET_PUBLISHER_COMMISSIONS = gql`
  query GetPublisherCommissions($input: PublisherCommissionsInput!) {
    getPublisherCommissions(input: $input) {
      count
      totalPages
      currentPage
      commissions {
        id
        endDate
        startDate
        description
        commissionStructure {
          cpaFlat {
            minimumSaleAmount
            commissionFee
            commissionBase
          }
          cpaTiered {
            commissionBase
            tiers {
              upTo
              commissionAmount
            }
            cutoffAmount
            minimumSaleAmount
          }
          referral {
            levels
          }
          revShareFlat {
            commissionFee
            commissionBase
          }
          revShareTiered {
            cutoffAmount
            commissionBase
            tiers {
              commissionAmount
              upTo
            }
          }
          bonusFlat {
            minimumSaleAmount
            commissionFee
            commissionBase
          }
          bonusTiered {
            minimumSaleAmount
            commissionBase
            period
            calculationType
            cutoffAmount
            tiers {
              commissionAmount
              upTo
            }
          }
        }
        commissionType
        commissionTarget {
          publisherGroupTargets {
            endDate
            publisherGroupId
            startDate
          }
          publisherTargets {
            endDate
            publisherId
            startDate
          }
        }
        products {
          id
          name
          productCategory
        }
        merchant {
          id
          companyName
        }
        publisherGroups {
          id
          name
        }
        publishers {
          id
          companyName
        }
      }
    }
  }
`;

enum PublisherCommissionsSortBy {
  publisherStartDate,
  createdAt,
  id,
  commissionType,
  productCategory,
}

export type GetPublisherCommissionsInput = {
  input: {
    publisherId: string;
    limit: number;
    currentPage: number;
    sortBy: string | PublisherCommissionsSortBy;
    sortDirection: 'asc' | 'desc' | undefined;
    startDate: Date | undefined;
    productCategory: string;
    productId: string;
    merchantId: string;
    commissionType: string | undefined;
  };
};

export type GPCCommissionStructureType = {
  cpaFlat: {
    minimumSaleAmount: string | null;
    commissionFee: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
  };
  cpaTiered: {
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
    tiers: { upTo: string | null; commissionAmount: string | null }[];
    cutoffAmount: string | null;
    minimumSaleAmount: string | null;
  };
  referral: { levels: string[] };
  revShareFlat: {
    commissionFee: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
  };
  revShareTiered: {
    tiers: { upTo: string; commissionAmount: string }[];
    cutoffAmount: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
  };
  bonusFlat: {
    minimumSaleAmount: string | null;
    commissionFee: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
  };
  bonusTiered: {
    minimumSaleAmount: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
    period: string | null;
    calculationType: 'Stepped' | 'Lookup' | null;
    tiers: { upTo: string; commissionAmount: string }[];
    cutoffAmount: string;
  };
};

export type GPCCommissionTargetType = {
  publisherGroupTargets: { endDate: string | null; publisherGroupId: string; startDate: string | null }[];
  publisherTargets: { endDate: string | null; publisherId: string; startDate: string | null }[];
};

export type GPCCommissionsType = {
  id: string;
  endDate: string | null;
  startDate: string | null;
  description: string | null;
  commissionName: string | null;
  commissionType: string | null;
  commissionStructure: GPCCommissionStructureType;
  commissionTarget: GPCCommissionTargetType;
  products: { id: string; name: string | null; productCategory: string | null }[];
  productCategory: string | null;
  merchant: { id: string };
  publisherGroups: { id: string; name: string }[];
  publishers: { id: string; companyName: string }[];
};

export type GetPublisherCommissionsOutput = {
  getPublisherCommissions: {
    count: number | null;
    commissions: GPCCommissionsType[];
    totalPages: number | null;
  };
};
