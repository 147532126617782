import styled from 'styled-components';

import { AppWrapper, Tooltip, Button } from '../../../components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type CollaspeSectionStyledPropsTypes = {
  readonly isVisible: boolean;
};

export const AppWrapperStyled = styled(AppWrapper)`
  > div:last-child > div:last-child {
    overflow: visible;
  }
`;

export const WrapperStyled = styled.div`
  width: 100%;
`;

export const TopContainerStyled = styled.div`
  ${fonts.gotham.H26M}
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.5rem;
`;

export const InviteLimitStyled = styled.section`
  border-bottom: 1px solid ${colors.color15};
  margin-top: ${toRem(32)};
  width: 100%;
  padding-bottom: ${toRem(8)};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    padding-bottom: ${toRem(40)};
    border-bottom: 0;
  }
`;

export const MerchantTypeStyled = styled.div`
  width: 100%;
  display: flex;
  ${fonts.gotham.H16M}
  color: ${colors.color1};
`;

export const ToolTipStyled = styled(Tooltip).attrs({ width: 252 })`
  margin-left: 4px;
  align-items: center;
`;

export const SetLimitStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${toRem(24)};
  margin-top: ${toRem(24)};
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 346px;
  ${fonts.gotham.H12M}
  padding-bottom: ${toRem(24)};
`;

export const CollaspeSectionStyled = styled.div<CollaspeSectionStyledPropsTypes>`
  height: ${({ isVisible }) => (isVisible ? '82px' : '0px')};
  overflow: hidden;
  transition: 0.2s all ease-in;
`;

export const FooterButtonStyled = styled(Button)`
  margin: ${toRem(4)} 0 ${toRem(4)} ${toRem(16)};
`;
