import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';

export const CellWrapperStyled = styled.div`
  color: ${colors.color17};
  ${fonts.lato.H16};
  line-height: 20px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: normal;
  gap: 4px;

  b {
    ${fonts.lato.H16B};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    ${fonts.lato.H14};
    color: ${colors.color13};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
