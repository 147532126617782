import { useQuery } from '@apollo/client';

import { LIST_MERCHANT_FILTER_OPTIONS } from '../graphql/queries/listMerchantFilterOptions';

export const useMerchantFilterOptions = () => {
  const CLOSED = 'Closed';
  const statusTypes: any[] = [
    {
      label: 'All Statuses',
      value: '',
    },
  ];

  const merchantTypes: any[] = [
    {
      label: 'All Merchant Types',
      value: '',
    },
  ];

  const countries: any[] = [
    {
      label: 'All Countries',
      value: '',
    },
  ];

  const { data, loading } = useQuery(LIST_MERCHANT_FILTER_OPTIONS, {
    variables: {
      input: {
        companyType: 'Merchant',
      },
    },
  });

  data?.getSearchCompaniesFilterOptions.accountStatusTypes.forEach((item: any) => {
    if (item.merchantDisplayValue !== CLOSED || (item.merchantDisplayValue === CLOSED && item.type === CLOSED)) {
      statusTypes.push({
        label: item.merchantDisplayValue,
        value: item.type,
      });
    }
  });

  data?.getSearchCompaniesFilterOptions.merchantTypes.forEach((item: any) => {
    merchantTypes.push({
      label: item.type === 'Self' ? 'Self Serve' : item.type,
      value: item.type,
    });
  });

  data?.getSearchCompaniesFilterOptions.countries.forEach((item: any) => {
    countries.push({
      label: item.name,
      value: item.name,
    });
  });

  return {
    hookAccountStatusTypeList: statusTypes,
    hookMerchantTypeList: merchantTypes,
    hookCountriesList: countries,
    hookFilterOptionsLoading: loading,
  };
};
