import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { CellWrapper } from '../components';
import { dateFormatter } from '../../../../../utils';
import { CommissionFormatted, Transaction } from '../../../../../utils/formatCommissions';
import * as Styled from '../styles';

export const columns = (handleDelete: (row: any) => void, handleEdit: (row: any) => void): TableColumn[] => [
  {
    dataField: '',
    text: 'ACTIONS',
    formatter: (val: any, row: any) => (
      <Styled.CellStyled theme="quaternary">
        <Styled.FAButtonStyled theme="text-only" onClick={() => handleEdit(row)}>
          <FontAwesomeIcon icon={faEdit} />
        </Styled.FAButtonStyled>
        <Styled.FAButtonStyled
          onClick={() => handleDelete(row)}
          theme="quaternary"
          disabled={row.startDate && new Date(row.startDate) < new Date()}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Styled.FAButtonStyled>
      </Styled.CellStyled>
    ),
    width: '7%',
  },
  {
    dataField: 'id',
    text: 'ID',
    formatter: (cell: any) => <CellWrapper>{cell}</CellWrapper>,
    width: '70px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'commissionName',
    text: 'Commission/Members',
    formatter: (val: any, row: CommissionFormatted) => (
      <CellWrapper>
        <b>{`${!row?.publishers?.length && !row?.publisherGroups?.length ? 'Default' : row.commissionName}`}</b>
        <p>{row.memberType}</p>
      </CellWrapper>
    ),
    width: '18%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'commissionType',
    text: 'Commission Type',
    formatter: (val: any) => (
      <CellWrapper>
        <b>{val}</b>
      </CellWrapper>
    ),
    width: '8%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
  {
    dataField: 'transaction',
    text: 'Transaction',
    formatter: (cell: Transaction) => (
      <CellWrapper>
        <b>{cell.commissionBase}</b>
        <p>{cell.minimumSaleAmount}</p>
      </CellWrapper>
    ),
    width: '12%',
  },
  {
    dataField: 'product',
    text: 'Products',
    formatter: (cell: any) => <CellWrapper>{cell?.name || ''}</CellWrapper>,
    width: '15%',
  },
  {
    dataField: 'productCategory',
    text: 'Product Category',
    formatter: (cell: any) => <CellWrapper>{cell || ''}</CellWrapper>,
    width: '12%',
  },
  {
    dataField: 'payable',
    text: 'Commission Payable',
    formatter: (cell: any) => <CellWrapper>{cell || ''}</CellWrapper>,
    width: '15%',
  },
  {
    dataField: 'startDate',
    text: 'Start/End Date',
    formatter: (cell: any, row: CommissionFormatted) => (
      <CellWrapper>
        {dateFormatter(new Date(cell))} - {row.endDate ? dateFormatter(new Date(row.endDate)) : ''}
      </CellWrapper>
    ),
    width: '10%',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
  },
];
