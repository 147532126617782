import styled from 'styled-components';

import { Button, Select, InputText } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const SelectStyled = styled(Select)`
  span:first-child {
    margin-right: 0rem;
  }
`;

export const InputTextStyled = styled(InputText)`
  flex: 2;
  margin-right: 2rem;
`;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const HeaderButtonsAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-right: 0.5rem;
  }
`;

export const FiltersSelectStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-basis: 30%;

  ${SelectStyled}:nth-of-type(2) {
    margin: 0 1rem;
  }
`;

export const FiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  ${FiltersSelectStyled} > span {
    margin-right: 1rem;
    ${fonts.lato.H14M}
    color: ${colors.color17};
  }
`;

export const OptionsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const RightButtonsStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
