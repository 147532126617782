import { dateFormatter } from '../../../../utils';

import { CommissionStructure } from './types';

export const CommissionStructureDefaultStructure: CommissionStructure = {
  bonusFlat: {},
  bonusTiered: { tiers: [] },
  cpaTiered: { tiers: [] },
  cpaFlat: {},
  referral: { levels: [] },
  revShareFlat: {},
  revShareTiered: { tiers: [] },
};

export const CREATION_ERROR_MESSAGE = 'Error Creating Commission';

export const CONFLICT_MODAL = {
  TITLE: 'Date Conflict Found',
  PROMPT: 'There appears to be a conflict with the following commission(s):',
  MESSAGE: 'Please set the date range of this new commission to be outside the above range(s)',
  MESSAGE_HAS_ENDLESS:
    'Please go to the commission above and set an end date for the structure before adding the new commission',
  BUTTON: 'Close',
  CONFLICT_COMMISSION: (id: string, startDate: Date, endDate?: Date) =>
    `ID ${id} ${'\xa0'.repeat(24)}${dateFormatter(new Date(startDate))} - ${endDate ? dateFormatter(new Date(endDate)) : ''}`,
};

export const TRAILING_PERIOD_OPTIONS = [
  {
    label: '1 months',
    value: '1 months',
  },
  {
    label: '2 months',
    value: '2 months',
  },
  {
    label: '3 months',
    value: '3 months',
  },
  {
    label: '4 months',
    value: '4 months',
  },
];

export const CPA_COMMISSION_BASES = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Gross Sales',
    value: 'GrossSales',
  },
  {
    label: 'Net Sales',
    value: 'NetSales',
  },
];

export const BONUS_COMMISSION_BASES = [
  {
    label: 'Gross Sales',
    value: 'GrossSales',
  },
  {
    label: 'Net Sales',
    value: 'NetSales',
  },
  {
    label: 'Units',
    value: 'SalesNumber',
  },
  {
    label: 'CPA',
    value: 'CPA',
  },
];

export const REV_SHARE_COMMISSION_BASES = [
  {
    label: 'Gross Sales',
    value: 'GrossSales',
  },
  {
    label: 'Net Sales',
    value: 'NetSales',
  },
];
