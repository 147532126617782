import styled from 'styled-components';

import { Button } from '../../../../../components';
import { colors } from '../../../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 850px;
  padding: 1rem;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ButtonStyled = styled(Button)`
  border: none;
  background: transparent;
  color: ${colors.color1};
  font-size: 1.2rem;

  &:hover {
    color: ${colors.color2};
    border: none;
    background: transparent;
  }
`;

export const ContentStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(13, 1fr);
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 12px;
`;

export const ContentCheckboxStyled = styled.div`
  margin-bottom: 1.2rem;
`;

export const ColumnStyled = styled.div``;

export const RowStyled = styled.div`
  margin-bottom: 0.8rem;
  font-size: 1rem;
  span {
    user-select: none;
  }
`;

export const CheckboxContentStyled = styled.div`
  width: 100%;
`;

export const CheckboxRowStyled = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
  span {
    user-select: none;
  }
  padding-bottom: 1rem;
  margin-right: 0.625rem;
`;
