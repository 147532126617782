import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { toRem } from '../../../../../../../utils';
import { colors, fonts } from '../../../../../../../styles/theme';
import { Button } from '../../../../../../../components';

export const WholeWrapper = styled.div`
  padding: ${toRem(52)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${toRem(32)};
  align-items: center;
  max-width: ${toRem(550)};
`;

export const BigIcon = styled(FontAwesomeIcon)`
  color: ${colors.color25};
  font-size: ${toRem(100)};
`;

export const TitleStyled = styled.p`
  ${fonts.gotham.H26M}
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H16}
  text-align: center;
  line-height: 1.2;
`;

export const ButtonStyled = styled(Button)`
  max-width: fit-content;
`;
