import styled from 'styled-components';

import { fonts } from '../../../../../../../styles/theme';

export const CellWrapperStyled = styled.div`
  display: flex;
  min-height: 30px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-flow: wrap row;
`;

export const TextStyled = styled.div`
  ${fonts.lato.H16}
`;
