import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import * as Styled from './styles';

type AlertProps = {
  message: string;
  type: 'error';
  isOpen: boolean;
  onClose: (value: boolean) => void;
  className?: string;
};

export const Alert = ({ message, type, isOpen, onClose, className }: AlertProps) => {
  useEffect(() => {
    if (isOpen) {
      // setIsOpenValue(true);
      setTimeout(() => {
        onClose(false);
      }, 3000);
    }
  }, [isOpen]);

  return (
    <Styled.WrapperStyled type={type} isOpen={isOpen} className={className}>
      {type === 'error' && <FontAwesomeIcon icon={faTriangleExclamation} />}
      {message}
    </Styled.WrapperStyled>
  );
};
