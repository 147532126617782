import React from 'react';

import { AppWrapper, PageTitle, Tabs } from 'components';
import { path } from 'utils';
import { DefaultPropsType } from 'types';

import { PerformanceReports, TransactionReports } from './Tabs';
import * as Styled from './styles';

type ManageSavedReportsProps = DefaultPropsType;

const ManageSavedReports = ({ permissionsCodeList = [] }: ManageSavedReportsProps): JSX.Element => (
  <AppWrapper permissionsCodeList={permissionsCodeList}>
    <Styled.HeaderStyled>
      <PageTitle>{path.manageSavedReports.name}</PageTitle>
    </Styled.HeaderStyled>

    <Tabs
      tabNames={['Performance Report', 'Transaction Master']}
      elements={[<PerformanceReports key="PerformanceReports" />, <TransactionReports key="TransactionReports" />]}
    />
  </AppWrapper>
);
export default ManageSavedReports;
