import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from '../../../../../../styles/theme';

export const IconCell = styled.div`
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  color: ${colors.color1};
`;
