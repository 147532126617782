import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { ERROR_MESSAGES, getDaysInMonth, rangeFormat, MONTH_NAMES as fullNameMonths } from '../../utils';
import { Button } from '../Button';

import * as Styled from './styles';

type CalendarProps = {
  date?: Date;
  endDate?: Date;
  isSingle?: boolean;
  hasRange?: boolean;
  isOpen?: boolean;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onApply: (date: Date, endDate?: Date, range?: string) => void;
  className?: string;
  byRange?: string;
  errors?: { [key: string]: string };
  minDate?: Date; // For setting the miniumum allowed start date
  maxDate?: Date; // For setting the maxiumum allowed date
  overrideRange?: string; // Overrides the displayed Range Value (does not affect internal value)
  reset?: boolean;
  maxYear?: number;
};
const minYear = 2020;
type CalendarSide = 'left' | 'right';

const checkIfDateIsSame = (checkDate: Date, compareDate: Date) =>
  checkDate.getUTCDate() === compareDate.getUTCDate() &&
  checkDate.getUTCFullYear() === compareDate.getUTCFullYear() &&
  checkDate.getUTCMonth() === compareDate.getUTCMonth();

export const Calendar = ({
  date = new Date(),
  endDate = new Date(),
  isSingle = false,
  hasRange = false,
  isOpen = false,
  onCancel,
  onApply,
  className,
  byRange,
  minDate,
  maxDate,
  errors,
  overrideRange,
  reset,
  maxYear = new Date().getFullYear() + 2,
}: CalendarProps) => {
  const [monthValue, setMonthValue] = useState(Number(date.getMonth()));
  const [yearValue, setYearValue] = useState(Number(date.getFullYear()));
  const [rightMonthValue, setRightMonthValue] = useState(Number(endDate.getMonth()));
  const [rightYearValue, setRightYearValue] = useState(Number(endDate.getFullYear()));
  const [selectedDate, setSelectedDate] = useState<Date>(date);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(endDate);
  const [leftTextError, setLeftTextError] = useState('');
  const [rightTextError, setRightTextError] = useState('');
  const [selectedRange, setSelectedRange] = useState(byRange);
  const [oldRange, setOldRange] = useState(byRange);
  const formatDate = (newDate: Date): string => {
    const month =
      (newDate.getMonth() + 1).toString().length !== 2 ? `0${newDate.getMonth() + 1}` : `${newDate.getMonth() + 1}`;
    const tempDate = newDate.getDate().toString().length !== 2 ? `0${newDate.getDate()}` : `${newDate.getDate()}`;
    const formattedDate = `${newDate.getFullYear()}-${month}-${tempDate}`;
    return formattedDate;
  };
  const [selectedLeftTextDate, setSelectedLeftTextDate] = useState(formatDate(date));
  const [selectedRightTextDate, setSelectedRightTextDate] = useState(formatDate(endDate));

  useEffect(() => {
    // If the Minutes are different run
    if (Math.floor(date.valueOf() / 60000) !== Math.floor(new Date().valueOf() / 60000)) {
      setSelectedDate(date);
    }
  }, [JSON.stringify(date)]);

  const onCancelHandler = (value: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel(value);
    setSelectedDate(date);
    setSelectedLeftTextDate(formatDate(date));
    setSelectedEndDate(endDate);
    setSelectedRightTextDate(formatDate(endDate));
    setSelectedRange(oldRange);
    setMonthValue(Number(date.getMonth()));
    setYearValue(Number(date.getFullYear()));
    setRightMonthValue(Number(endDate.getMonth()));
    setRightYearValue(Number(endDate.getFullYear()));
  };

  useEffect(() => {
    if (reset !== undefined) {
      setSelectedDate(new Date()); // THIS RESETS THE SELECTED DATE WHEN CALNEDAR IS BEING USED IN THE MODAL.
      setSelectedEndDate(new Date()); // WHEN CLOSING THE MODAL YOU WANT TO RESET THE SELECTED DATE.
    }
  }, [reset]);

  const selecteDateHandler = (selectedDay: number, calendarSide: CalendarSide) => {
    if (calendarSide === 'left') {
      const newDate = new Date(yearValue, monthValue, selectedDay);
      setSelectedDate(newDate);
      setSelectedLeftTextDate(formatDate(newDate));
      setLeftTextError('');
      setSelectedRange('');
    }

    if (calendarSide === 'right') {
      const newDate = new Date(rightYearValue, rightMonthValue, selectedDay);
      setSelectedEndDate(newDate);
      setSelectedRightTextDate(formatDate(newDate));
      setRightTextError('');
      setSelectedRange('');
    }
  };

  const weekDaysRender = () => {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const week = weekDays.map((day, index) => (
      <Styled.WeekItemStyled key={`${day}-${index}`}>{day}</Styled.WeekItemStyled>
    ));

    return <Styled.WeekListStyled>{week}</Styled.WeekListStyled>;
  };

  const daysRender = (selectedMonth: number, selectedYear: number, calendarSide: CalendarSide) => {
    const { daysInCurrentMonth, firstDateOfCurrentMonth, daysInPreviousMonth, lastDateOfCurrentMonth } = getDaysInMonth(
      selectedMonth,
      selectedYear
    );
    const today = formatDate(new Date());

    const days = [];

    for (let i = 0; i < firstDateOfCurrentMonth; i += 1) {
      const day = formatDate(new Date(selectedYear, selectedMonth - 1, daysInPreviousMonth - i));
      days.push(
        <Styled.InvalidDayStyled key={`${i}-prev`} isToday={day === today}>
          {daysInPreviousMonth - i}
        </Styled.InvalidDayStyled>
      );
    }

    days.reverse();

    for (let i = 1; i <= daysInCurrentMonth; i += 1) {
      const cellDate = new Date(Date.UTC(selectedYear, selectedMonth, i, 0, 0, 0));
      const cellDateLocalZone = new Date(selectedYear, selectedMonth, i);
      const day = formatDate(cellDateLocalZone);
      if (
        (minDate && minDate > cellDate && !checkIfDateIsSame(cellDate, minDate)) ||
        (maxDate && cellDate > maxDate && !checkIfDateIsSame(cellDate, maxDate))
      ) {
        days.push(
          <Styled.InvalidDayStyled key={`${i}-curr`} isToday={day === today} isDisabled>
            {i}
          </Styled.InvalidDayStyled>
        );
      } else {
        days.push(
          <Styled.DayItemStyled
            key={`${i}-curr`}
            isToday={day === today}
            isSelected={
              (calendarSide === 'left' && day === formatDate(selectedDate)) ||
              (calendarSide === 'right' && day === formatDate(selectedEndDate))
            }
          >
            <input
              type="radio"
              name={`day-${calendarSide}-${selectedMonth}`}
              value={`${i}`}
              onClick={() => selecteDateHandler(i, calendarSide)}
            />
            <span>{i}</span>
          </Styled.DayItemStyled>
        );
      }
    }

    for (let i = 0; i < 6 - lastDateOfCurrentMonth; i += 1) {
      const day = formatDate(new Date(selectedYear, selectedMonth, i + 1));

      days.push(
        <Styled.InvalidDayStyled key={`${i}-next`} isToday={day === today}>
          {i + 1}
        </Styled.InvalidDayStyled>
      );
    }

    return <Styled.DayListStyled>{days}</Styled.DayListStyled>;
  };

  const nextMonth = (calendar: CalendarSide) => {
    if (calendar === 'left') {
      if (monthValue === 11) {
        if (yearValue < maxYear) {
          setYearValue(yearValue + 1 < maxYear ? yearValue + 1 : maxYear);
        }
        setMonthValue(0);
      } else {
        setMonthValue(monthValue + 1);
      }
    }

    if (calendar === 'right') {
      if (rightMonthValue === 11) {
        if (rightYearValue < maxYear) {
          setRightYearValue(rightYearValue + 1 < maxYear ? rightYearValue + 1 : maxYear);
        }
        setRightMonthValue(0);
      } else {
        setRightMonthValue(rightMonthValue + 1);
      }
    }
  };

  const prevMonth = (calendar: CalendarSide) => {
    if (calendar === 'left') {
      if (monthValue === 0) {
        if (rightYearValue > minYear) {
          setMonthValue(11);
          setYearValue(yearValue - 1 > minYear ? yearValue - 1 : minYear);
        }
      } else {
        setMonthValue(monthValue - 1);
      }
    }

    if (calendar === 'right') {
      if (rightMonthValue === 0) {
        if (rightYearValue > minYear) {
          setRightMonthValue(11);
          setRightYearValue(rightYearValue - 1 > minYear ? rightYearValue - 1 : minYear);
        }
      } else {
        setRightMonthValue(rightMonthValue - 1);
      }
    }
  };

  const yearArray = () => {
    const years = [];

    for (let i = minYear; i <= maxYear; i += 1) {
      years.push(i);
    }

    return years;
  };

  const onApplyHandler = () => {
    setOldRange(selectedRange);
    if (isSingle) {
      onApply(selectedDate, undefined, selectedRange);
    }

    if (!isSingle) {
      onApply(selectedDate, selectedEndDate, selectedRange);
    }
  };

  const onChangeTextHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

    if (name === 'startDate') {
      setSelectedLeftTextDate(value);

      if (regex.test(value)) {
        const [year, month, day] = value.split('-');
        const newValue = new Date(Number(year), Number(month) - 1, Number(day));
        setSelectedDate(newValue);
        setMonthValue(newValue.getMonth());
        setYearValue(newValue.getFullYear());
        setLeftTextError('');
      } else {
        setLeftTextError(ERROR_MESSAGES.INVALID_DATE);
      }
    }

    if (name === 'endDate') {
      setSelectedRightTextDate(value);

      if (regex.test(value)) {
        const [year, month, day] = value.split('-');
        const newValue = new Date(Number(year), Number(month) - 1, Number(day));

        setSelectedEndDate(newValue);
        setRightMonthValue(newValue.getMonth());
        setRightYearValue(newValue.getFullYear());
        setRightTextError('');
      } else {
        setRightTextError(ERROR_MESSAGES.INVALID_DATE);
      }
    }
  };

  const onChangeMonthSelectionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    const numberValue = Number(value);

    if (name === 'startMonth') {
      setMonthValue(numberValue);
      setSelectedLeftTextDate(formatDate(new Date(yearValue, numberValue, 1)));
    }

    if (name === 'endMonth') {
      setRightMonthValue(numberValue);
      setSelectedRightTextDate(formatDate(new Date(rightYearValue, numberValue, 1)));
    }
  };

  const onChangeYearSelectionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    const numberValue = Number(value);

    if (name === 'startYear') {
      setYearValue(numberValue);
      setSelectedLeftTextDate(formatDate(new Date(numberValue, monthValue, 1)));
    }

    if (name === 'endYear') {
      setRightYearValue(numberValue);
      setSelectedRightTextDate(formatDate(new Date(numberValue, rightMonthValue, 1)));
    }
  };

  const onRangeChangeHandler = (range: any) => {
    setSelectedRange(range);
    const { start, end } = rangeFormat(range);
    setSelectedDate(start);
    setMonthValue(start.getMonth());
    setYearValue(start.getFullYear());
    setSelectedLeftTextDate(formatDate(start));

    if (!isSingle) {
      setSelectedEndDate(end);
      setRightMonthValue(end.getMonth());
      setRightYearValue(end.getFullYear());
      setSelectedRightTextDate(formatDate(end));
    }
  };

  useEffect(() => {
    setSelectedLeftTextDate(formatDate(date));
    setSelectedRightTextDate(formatDate(endDate));
    setLeftTextError('');
    setRightTextError('');
  }, [isOpen]);

  useEffect(() => {
    if (!errors?.calendar) return;
    setLeftTextError(errors.calendar);
    setRightTextError(errors.calendar);
  }, [errors]);

  return (
    <Styled.WrapperStyled isOpen={isOpen} className={className}>
      <Styled.InnerStyled isOpen={isOpen}>
        <Styled.BodyTopStyled>
          {hasRange && !isSingle && (
            <Styled.RangeSelectorContainerStyled>
              <Styled.RangeSelectorButtonStyled
                isSelected={overrideRange !== undefined ? overrideRange === 'today' : selectedRange === 'today'}
                onClick={() => onRangeChangeHandler('today')}
              >
                Today
              </Styled.RangeSelectorButtonStyled>
              <Styled.RangeSelectorButtonStyled
                isSelected={overrideRange !== undefined ? overrideRange === 'yesterday' : selectedRange === 'yesterday'}
                onClick={() => onRangeChangeHandler('yesterday')}
              >
                Yesterday
              </Styled.RangeSelectorButtonStyled>
              <Styled.RangeSelectorButtonStyled
                isSelected={overrideRange !== undefined ? overrideRange === 'week' : selectedRange === 'week'}
                onClick={() => onRangeChangeHandler('week')}
              >
                This Week
              </Styled.RangeSelectorButtonStyled>
              <Styled.RangeSelectorButtonStyled
                isSelected={overrideRange !== undefined ? overrideRange === 'lastWeek' : selectedRange === 'lastWeek'}
                onClick={() => onRangeChangeHandler('lastWeek')}
              >
                Last Week
              </Styled.RangeSelectorButtonStyled>
              <Styled.RangeSelectorButtonStyled
                isSelected={overrideRange !== undefined ? overrideRange === 'month' : selectedRange === 'month'}
                onClick={() => onRangeChangeHandler('month')}
              >
                This Month
              </Styled.RangeSelectorButtonStyled>
              <Styled.RangeSelectorButtonStyled
                isSelected={overrideRange !== undefined ? overrideRange === 'lastMonth' : selectedRange === 'lastMonth'}
                onClick={() => onRangeChangeHandler('lastMonth')}
              >
                Last Month
              </Styled.RangeSelectorButtonStyled>
              <Styled.RangeSelectorButtonStyled
                isSelected={
                  overrideRange !== undefined ? overrideRange === 'last30Days' : selectedRange === 'last30Days'
                }
                onClick={() => onRangeChangeHandler('last30Days')}
              >
                Last 30 Days
              </Styled.RangeSelectorButtonStyled>
            </Styled.RangeSelectorContainerStyled>
          )}

          <Styled.DateAreaStyled>
            <Styled.BodyStyled>
              <Styled.InputTextStyled
                label={isSingle ? 'Select Date' : 'Start Date'}
                name="startDate"
                type="text"
                value={selectedLeftTextDate}
                onChange={onChangeTextHandler}
                faIcon={faCalendarAlt}
                error={leftTextError}
              />

              <Styled.MonthWrapperStyled>
                <Button theme="calendar" onClick={() => prevMonth('left')}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </Button>

                <Styled.DropdownWrapperStyled>
                  <Styled.SelectionStyled name="startMonth" value={monthValue} onChange={onChangeMonthSelectionHandler}>
                    {fullNameMonths.map((monthName: string, index: number) => (
                      <Styled.OptionStyled key={`${monthName}-${index}`} value={index}>
                        {monthName}
                      </Styled.OptionStyled>
                    ))}
                  </Styled.SelectionStyled>

                  <Styled.SelectionStyled name="startYear" value={yearValue} onChange={onChangeYearSelectionHandler}>
                    {yearArray().map((yearDisplay: number) => (
                      <Styled.OptionStyled key={`${yearDisplay}`} value={yearDisplay}>
                        {yearDisplay}
                      </Styled.OptionStyled>
                    ))}
                  </Styled.SelectionStyled>
                </Styled.DropdownWrapperStyled>

                <Button theme="calendar" onClick={() => nextMonth('left')}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </Button>
              </Styled.MonthWrapperStyled>

              {weekDaysRender()}
              {daysRender(monthValue, yearValue, 'left')}
            </Styled.BodyStyled>

            {!isSingle && (
              <Styled.BodyStyled>
                <Styled.InputTextStyled
                  label="End Date"
                  name="endDate"
                  type="text"
                  value={selectedRightTextDate}
                  onChange={onChangeTextHandler}
                  faIcon={faCalendarAlt}
                  error={rightTextError}
                />

                <Styled.MonthWrapperStyled>
                  <Button theme="calendar" onClick={() => prevMonth('right')}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </Button>

                  <Styled.DropdownWrapperStyled>
                    <Styled.SelectionStyled
                      name="endMonth"
                      value={rightMonthValue}
                      onChange={onChangeMonthSelectionHandler}
                    >
                      {fullNameMonths.map((monthName: string, index: number) => (
                        <Styled.OptionStyled key={`${monthName}-${index}`} value={index}>
                          {monthName}
                        </Styled.OptionStyled>
                      ))}
                    </Styled.SelectionStyled>

                    <Styled.SelectionStyled
                      name="endYear"
                      value={rightYearValue}
                      onChange={onChangeYearSelectionHandler}
                    >
                      {yearArray().map((yearDisplay: number) => (
                        <Styled.OptionStyled key={`${yearDisplay}`} value={yearDisplay}>
                          {yearDisplay}
                        </Styled.OptionStyled>
                      ))}
                    </Styled.SelectionStyled>
                  </Styled.DropdownWrapperStyled>

                  <Button theme="calendar" onClick={() => nextMonth('right')}>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                </Styled.MonthWrapperStyled>

                {weekDaysRender()}
                {daysRender(rightMonthValue, rightYearValue, 'right')}
              </Styled.BodyStyled>
            )}
          </Styled.DateAreaStyled>
        </Styled.BodyTopStyled>

        <Styled.FooterStyled>
          <Button theme="secondary" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button onClick={onApplyHandler}>Apply</Button>
        </Styled.FooterStyled>
      </Styled.InnerStyled>
    </Styled.WrapperStyled>
  );
};
