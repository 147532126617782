import React from 'react';

import { RECORDS_PER_PAGE_OPTIONS_WITH_150 } from '../../utils';

import * as Styled from './styles';

type RecordsDropdownProps = {
  options?: any[];
  selected: {
    label: string;
    value: string;
  };
  currentPage: number;
  totalRecords: number;
  onChange: (value: any) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const RecordsDropdown = ({
  options = RECORDS_PER_PAGE_OPTIONS_WITH_150,
  selected,
  currentPage,
  totalRecords,
  onChange,
  isLoading = false,
  isDisabled = false,
}: RecordsDropdownProps) => (
  <Styled.WrapperStyled>
    <Styled.RecordsStyled>
      Records{' '}
      <Styled.SelectStyled
        width="140px"
        selected={selected}
        options={options}
        onChange={onChange}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </Styled.RecordsStyled>
    Showing {totalRecords === 0 ? 0 : (currentPage - 1) * Number(selected.value) + 1} to{' '}
    {currentPage * Number(selected.value) > totalRecords ? totalRecords : currentPage * Number(selected.value)} of{' '}
    {totalRecords} records
  </Styled.WrapperStyled>
);
