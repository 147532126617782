import styled from 'styled-components';

import { toRem } from '../../../utils';
import { Button } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

type ButtonStyledProps = {
  readonly margin?: string;
};

type InputContainerStyledProps = {
  readonly theme?: 'secondary' | 'third';
  readonly marginBottom?: 'sm';
};

type SectionStyledProps = {
  readonly theme?: 'secondary';
};

type SectionLabelWrapperStyledProps = {
  readonly theme?: 'secondary';
};

type SectionTitleStyledProps = {
  readonly theme?: 'secondary';
};

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toRem(24)};
`;

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  margin-left: ${({ margin }) => (margin === 'right' ? toRem(16) : 0)};

  svg {
    margin-right: ${toRem(5)};
  }
`;

export const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SectionStyled = styled.div<SectionStyledProps>`
  border-bottom: ${({ theme }) => (theme === 'secondary' ? 'none' : `1px solid ${colors.color19}`)};
  padding: ${toRem(50)} 0 ${toRem(24)};
`;

export const SectionTitleStyled = styled.h2<SectionTitleStyledProps>`
  ${fonts.gotham.H22M};
  margin-bottom: ${({ theme }) => (theme === 'secondary' ? toRem(40) : toRem(20))};
`;

export const SectionLabelWrapperStyled = styled.div<SectionLabelWrapperStyledProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => (theme === 'secondary' ? toRem(10) : 0)};
`;

export const SectionLabelStyled = styled.p`
  ${fonts.lato.H16B};
  line-height: ${toRem(24)};
`;

export const SectionContentStyled = styled.div`
  ${fonts.lato.H16};
  margin: ${toRem(10)} 0 ${toRem(24)};

  p {
    margin: 0 0 ${toRem(4)};
    line-height: ${toRem(24)};
  }
`;

export const DisclaimerStyled = styled.div`
  border-radius: ${toRem(2)};
  background-color: #feedde;
  ${fonts.lato.H12};
  margin-left: ${toRem(15)};
  padding: 0 ${toRem(5)};
  width: fit-content;
  line-height: ${toRem(20)};
`;

export const ChekcboxStyled = styled.div`
  margin: 0 0 ${toRem(10)};
  display: flex;
`;

export const InputContainerStyled = styled.div<InputContainerStyledProps>`
  display: grid;
  grid-template-columns: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return 'repeat(2, 1fr)';
      case 'third':
        return 'repeat(3, 1fr)';
      default:
        return 'repeat(3, 1fr) 43px';
    }
  }};
  margin: 0 0 ${({ marginBottom }) => (marginBottom === 'sm' ? 0 : toRem(40))};
`;

export const InputWrapperStyled = styled.div`
  width: 85%;
  margin-bottom: ${toRem(24)};
`;

export const GridButtonStyled = styled.div`
  align-self: center;
  height: ${toRem(45)};
  grid-column: 4/5;
`;

export const ModalTitleStyled = styled.h3`
  ${fonts.gotham.H26M}
`;

export const ModalCheckboxContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${toRem(10)};
  margin-bottom: ${toRem(40)};
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${toRem(700)};
  min-height: ${toRem(350)};
  padding: ${toRem(50)};
`;
