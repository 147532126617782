import { gql } from '@apollo/client';

export const GET_MEMBERSHIPS = gql`
  query memberships($input: MembershipSearchInput!) {
    memberships(input: $input) {
      count
      memberships {
        id
        status
        statusLastUpdatedBy
        createdAt
        approvedDate
        updatedAt
        publisherGroups {
          id
          name
        }
        publisherInvitation {
          id
        }
        pendingTrans {
          id
        }
        publisher {
          id
          companyName
          accountStatus
          country
          state
          trackingDomainUrls
          overview {
            productCategories
          }
          users {
            id
            email
            lastLogin
          }
          geoIp {
            IP
            country
            region
          }
          trackings {
            id
            primaryWebsite
            websiteStatus
            geography
            primaryCategory
            websiteTraffic
            promotionMethod
            promotingProduct
          }
        }
      }
    }
  }
`;

export type GetMembershipsInput = {
  input: {
    programId: string;
  };
};
type MembershipStatusType = 'Approved' | 'Closed' | 'Pending' | 'Declined' | 'Suspended';
type AccountStatusType =
  | 'Approved'
  | 'Paused'
  | 'Closed'
  | 'Pending'
  | 'Declined'
  | 'Suspended'
  | 'Integrating'
  | 'LivePending';
type WebsiteStatusType = 'Verified' | 'Unverified' | 'Inactive' | 'None';

export type GMMembershipType = {
  id: number;
  status: MembershipStatusType | null;
  statusLastUpdatedBy: 'Merchant' | 'Publisher' | null;
  createdAt: string | null;
  approvedDate: string | null;
  updatedAt: string | null;
  publisherGroups: {
    id: string;
    name: string;
  }[];
  publisherInvitation: {
    id: string;
  };
  pendingTrans: {
    id: string;
  };
  publisher: {
    id: string;
    companyName: string | null;
    accountStatus: AccountStatusType | null;
    country: string | null;
    state: string | null;
    trackingDomainUrls: string[] | null;
    overview: {
      productCategories: string[] | null;
    };
    users: {
      id: string;
      email: string;
      lastLogin: string | null;
    }[];
    geoIp: {
      IP: string;
      country: string | null;
      region: string | null;
    };
    trackings: {
      id: string;
      primaryWebsite: string | null;
      websiteStatus: WebsiteStatusType | null;
      geography: string;
      primaryCategory: string;
      websiteTraffic: string;
      promotionMethod: string[] | null;
      promotingProduct: string[] | null;
    }[];
  };
};
export type GetMembershipsOutput = {
  memberships: {
    count: number;
    memberships: GMMembershipType[];
  };
};
