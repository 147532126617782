import { gql } from '@apollo/client';

export const CAMPAIGN_OPTION = gql`
  query campaigns($input: CreateCampaignOptionsInput) {
    getCreateCampaignOptions(input: $input) {
      campaignStatusTypes {
        type
      }
      merchant {
        companyUrl
      }
      languages {
        language
      }
      products {
        id
        name
      }
      tags {
        id
        name
      }
      publisherGroups {
        id
        name
      }
    }
  }
`;
