import React from 'react';

import { Modal, Button } from '../../../../components';
import { BonusModalDetials } from '../types';
import { MANUAL_PUBLISHER_BONUS } from '../enums';

import * as Styled from './styles';

type DetailsModalProps = {
  isOpen: boolean;
  handleBack: () => void;
  bonusDetails: BonusModalDetials;
};

export const DetailsModal = ({ bonusDetails, isOpen, handleBack }: DetailsModalProps) => (
  <Modal isOpen={isOpen}>
    <Styled.OuterWrapperStyled theme="BonusDetails">
      <Styled.WrapperStyled>
        <Styled.TitleStyled>{MANUAL_PUBLISHER_BONUS.MANUAL_TITLE}</Styled.TitleStyled>
        <Styled.WrapperStyled theme="buttons" singleButton>
          <Button onClick={handleBack}>{MANUAL_PUBLISHER_BONUS.BACK}</Button>
        </Styled.WrapperStyled>
      </Styled.WrapperStyled>
      <Styled.WrapperStyled>
        <Styled.WrapperStyled theme="half">
          <Styled.TextStyled theme="header">{MANUAL_PUBLISHER_BONUS.ADDED_BY}</Styled.TextStyled>
          <Styled.TextStyled>{bonusDetails.addedBy}</Styled.TextStyled>
        </Styled.WrapperStyled>
        <Styled.WrapperStyled theme="half">
          <Styled.TextStyled theme="header">{MANUAL_PUBLISHER_BONUS.PUBLISHER_ID_NAME}</Styled.TextStyled>
          <Styled.TextStyled>{bonusDetails.publisherIdName}</Styled.TextStyled>
        </Styled.WrapperStyled>
      </Styled.WrapperStyled>
      <Styled.WrapperStyled>
        <Styled.WrapperStyled theme="half">
          <Styled.TextStyled theme="header">{MANUAL_PUBLISHER_BONUS.PAYMENT_AMOUNT}</Styled.TextStyled>
          <Styled.TextStyled>{bonusDetails.paymentAmount}</Styled.TextStyled>
        </Styled.WrapperStyled>
        <Styled.WrapperStyled theme="half">
          <Styled.TextStyled theme="header">{MANUAL_PUBLISHER_BONUS.PAYMENT_PERIOD}</Styled.TextStyled>
          <Styled.TextStyled>{bonusDetails.paymentPeriod}</Styled.TextStyled>
        </Styled.WrapperStyled>
      </Styled.WrapperStyled>
      <Styled.WrapperStyled>
        <Styled.WrapperStyled theme="half">
          <Styled.TextStyled theme="header">{MANUAL_PUBLISHER_BONUS.PAYMENT_TYPE}</Styled.TextStyled>
          <Styled.TextStyled>{bonusDetails.paymentType}</Styled.TextStyled>
        </Styled.WrapperStyled>
        <Styled.WrapperStyled theme="half">
          <Styled.TextStyled theme="header">{MANUAL_PUBLISHER_BONUS.MERCHANT}</Styled.TextStyled>
          <Styled.TextStyled>{bonusDetails.merchant}</Styled.TextStyled>
        </Styled.WrapperStyled>
      </Styled.WrapperStyled>

      <Styled.WrapperStyled>
        {bonusDetails.productCategory !== null && bonusDetails.productCategory !== undefined && (
          <Styled.WrapperStyled theme="half">
            <Styled.TextStyled theme="header">{MANUAL_PUBLISHER_BONUS.PRODUCT_CATEGORY}</Styled.TextStyled>
            <Styled.TextStyled>{bonusDetails.productCategory}</Styled.TextStyled>
          </Styled.WrapperStyled>
        )}
        <Styled.WrapperStyled theme="half">
          <Styled.TextStyled theme="header">{MANUAL_PUBLISHER_BONUS.NOTE}</Styled.TextStyled>
          <Styled.TextStyled>
            {bonusDetails.note && <Styled.TextStyled>{bonusDetails.note}</Styled.TextStyled>}
          </Styled.TextStyled>
        </Styled.WrapperStyled>
      </Styled.WrapperStyled>
    </Styled.OuterWrapperStyled>
  </Modal>
);
