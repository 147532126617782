import React from 'react';

import * as Styled from '../../styles';
import { Checkbox } from '../../../../../components';
import { EditColumnProps } from '../../reducers';
import { TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS } from '../../../TransactionMasterReport/enums';
import { immutableGlobalColumns } from '../../../PerfomanceReport/enums';

type ColumnsProps = {
  state: EditColumnProps;
  dispatcher: React.Dispatch<ComponentReducerType<Record<string, any>>>;
  isTransactionMaster?: boolean;
};

export const ColumnFilters = ({ state, dispatcher, isTransactionMaster }: ColumnsProps) => {
  if (isTransactionMaster) {
    return (
      <Styled.CheckBoxGridStyled>
        {TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS.map((column) => (
          <Checkbox
            key={column.value}
            label={column.label}
            checked={state[column.value as keyof typeof state]?.checked}
            onChange={(e: boolean) =>
              dispatcher({
                data: {
                  [column.value]: {
                    label: column.label,
                    checked: e,
                  },
                },
              })
            }
          />
        ))}
      </Styled.CheckBoxGridStyled>
    );
  }
  return (
    <Styled.CheckBoxGridStyled>
      {immutableGlobalColumns.map((column) => (
        <Checkbox
          key={column.value}
          label={column.label}
          checked={state[column.value as keyof typeof state]?.checked}
          onChange={(e: boolean) =>
            dispatcher({
              data: {
                [column.value]: {
                  label: column.label,
                  checked: e,
                },
              },
            })
          }
        />
      ))}
    </Styled.CheckBoxGridStyled>
  );
};
