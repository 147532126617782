import React from 'react';

import { imageList } from '../../utils';

import * as Styled from './styles';

export const PLACEHOLDER = {
  TEXT_PENDING: 'Click generate reports to get started',
  TEXT_NO_RESULTS: 'Sorry! No results found',
  SUBTEXT_PENDING: '',
  SUBTEXT_NO_RESULTS: "We couldn't find what you're looking for. Please try it again.",
};

type ReportPlaceholderProps = {
  submitted: boolean | undefined;
  subtext?: string;
  text: string;
};

export const ReportPlaceholder = ({ submitted, subtext, text }: ReportPlaceholderProps): JSX.Element => (
  <Styled.WrapperStyled display={submitted ? 'not-found' : 'wait'}>
    <Styled.TextStyled>{text}</Styled.TextStyled>

    {subtext && <Styled.SubtextStyled>{subtext}</Styled.SubtextStyled>}

    <figure>
      {submitted ? (
        <img src={imageList.noResults.src} alt={imageList.noResults.alt} />
      ) : (
        <img src={imageList.placeholder.src} alt={imageList.placeholder.alt} />
      )}
    </figure>
  </Styled.WrapperStyled>
);
