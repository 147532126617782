import styled from 'styled-components';

import { Button } from '../../../../components';
import { colors } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderInnerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

export const ButtonStyled = styled(Button)`
  background-color: ${colors.color4};
`;

export const ReportWrappe = styled.div`
  background-color: ${colors.color4};
  margin-top: 2rem;
  padding: 1.5rem;
`;
