import styled from 'styled-components';

import { Button } from '../../../../components';
import { toRem } from '../../../../utils';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toRem(40)};
`;

export const ButtonStyled = styled(Button)`
  svg {
    margin-right: 5px;
  }
`;
