import { gql } from '@apollo/client';

export const GET_MERCHANTS_FOR_REPORTS = gql`
  query getMerchantsForReports($input: CompanyInput) {
    companyOptions(input: $input) {
      companies {
        id
        companyName
      }
    }
  }
`;

export type GetMerchantsForReportsInputType = {
  input: {
    companyType: string;
    accountStatus?: string;
    id: string;
  };
};

export type GetMerchantsForReportsOutputType = {
  companyOptions: {
    companies: {
      id: string;
      companyName: string;
    }[];
  };
};
