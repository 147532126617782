import React from 'react';

import { AppWrapper, PageTitle, Tabs } from 'components';
import { path } from 'utils';
import { DefaultPropsType } from 'types';

import { BulkUpload, BulkImportLog } from './Tabs';
import * as Styled from './styles';

type AddTransactionsProps = DefaultPropsType;

const AddTransactions = ({ permissionsCodeList = [] }: AddTransactionsProps): JSX.Element => (
  <AppWrapper permissionsCodeList={permissionsCodeList}>
    <Styled.WrapperStyled>
      <Styled.HeaderStyled>
        <PageTitle>{path.addTransactions.name}</PageTitle>
      </Styled.HeaderStyled>
      <Tabs
        tabNames={['Bulk Upload', 'Bulk Import Log']}
        elements={[
          <BulkUpload permissionsCodeList={permissionsCodeList} key="BulkUpload" />,
          <BulkImportLog key="BulkImportLog" />,
        ]}
      />
    </Styled.WrapperStyled>
  </AppWrapper>
);

export default AddTransactions;
