import { gql } from '@apollo/client';

export const UPDATE_INVITE = gql`
  mutation updateCompany($input: CompanyInput!) {
    updateCompany(input: $input) {
      company {
        id
      }
    }
  }
`;
