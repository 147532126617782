import React from 'react';

import { AppWrapper, ErrorBanner, Modal } from '../../../components';
import { DefaultPropsType } from '../../../types';

import { useSummaryReport, useFintelCheckReport } from './hooks';
import { UpdateVisibility } from './Components';
import { SummaryReport } from './AdminReport';
import * as Styled from './styles';

type FintelCheckReportProps = DefaultPropsType;

const NewCheckReport = ({ permissionsCodeList = [] }: FintelCheckReportProps) => {
  // this needs to be updated
  const hook = useFintelCheckReport();
  const summaryHook = useSummaryReport();

  return (
    <AppWrapper background permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
      <Styled.SectionWrapper>
        <SummaryReport isMainReport hook={summaryHook} />
      </Styled.SectionWrapper>

      <Modal isOpen={hook.hookModalIsOpen}>
        <UpdateVisibility hook={hook} />
      </Modal>
    </AppWrapper>
  );
};

export default NewCheckReport;
