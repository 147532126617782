import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { LIST_PRODUCT_CATEGORIES, LIST_PROMOTION_METHODS } from '../../graphql';
import { useSignUp } from '../../hooks';
import { SIGN_UP_ACTIONS } from '../../../reducers';
import { path, URL_STATUSES } from '../../../../../utils';
import { useToast, useUpload } from '../../../../../hooks';
import { useValidation } from '../../../../../utils/validation';
import { PUBLISHER_INFO } from '../enums';

type CheckBoxOption = {
  label: string;
  value: string;
  checked: boolean;
};

export const usePublisher = () => {
  const validator = useValidation();
  const { contextState, contextDispatcher } = useSignUp();
  const { hookShowToast } = useToast();
  const navigate = useNavigate();
  const { hookUploadImageFileNoAuth } = useUpload();

  const [urlError, setUrlError] = useState<string>('');
  const [urlStatus, setUrlStatus] = useState<string>('');
  const [failedNext, setFailedNext] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getProductCategories, { loading: productCatsLoading }] = useLazyQuery(LIST_PRODUCT_CATEGORIES);

  const [getPromotionMethods, { loading: promotionMethodsLoading }] = useLazyQuery(LIST_PROMOTION_METHODS);

  const setProfilePhotoHandler = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await hookUploadImageFileNoAuth(file);
      contextDispatcher({
        type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
        data: { profilePhotoURL: response?.url || '' },
      });
    } catch (err: any) {
      hookShowToast(PUBLISHER_INFO.PROFILE_PHOTO.ERROR);
    }
  };

  const setPublisherProductCategoriesHandler = (value: CheckBoxOption) => {
    const newPublisherProductCategories = contextState.productCategories.map((option) => {
      if (option.value === value.value) {
        return {
          ...option,
          checked: !option.checked,
        };
      }
      return option;
    });

    contextDispatcher({
      type: SIGN_UP_ACTIONS.SET_PRODUCT_CATEGORIES,
      data: newPublisherProductCategories,
    });
  };

  const getProductCategoriesHandler = async () => {
    setErrorMessage('');
    const { data } = await getProductCategories({
      onError(error) {
        setErrorMessage(error.message);
      },
    });
    if (data) {
      const groupsFormatted = data.listProductCategories?.map((item: any) => ({
        label: item.name,
        value: item.id,
        checked: false,
      }));
      contextDispatcher({
        type: SIGN_UP_ACTIONS.SET_PRODUCT_CATEGORIES,
        data: groupsFormatted,
      });
    }
  };

  const setPublisherPromotionMethodsHandler = (value: CheckBoxOption) => {
    const newPublisherPromotionMethods = contextState.promotionMethods.map((option) => {
      if (option.value === value.value) {
        return {
          ...option,
          checked: !value.checked,
        };
      }
      return option;
    });

    contextDispatcher({
      type: SIGN_UP_ACTIONS.SET_PROMOTION_METHOD,
      data: newPublisherPromotionMethods,
    });
  };

  const getPromotionMethodsHandler = async () => {
    setErrorMessage('');
    const { data } = await getPromotionMethods({
      onError(error) {
        setErrorMessage(error.message);
      },
    });
    if (data) {
      const groupsFormatted = data.listPromotionMethods?.map((item: any) => ({
        label: item.type,
        value: item.id,
        checked: false,
      }));

      contextDispatcher({
        type: SIGN_UP_ACTIONS.SET_PROMOTION_METHOD,
        data: groupsFormatted,
      });
    }
  };

  const navigateHandler = (url: string) => {
    navigate(url);
  };

  const validateUrl = async () => {
    const newStatus = await validator.validateUrlStatus(
      contextState.companyUrl,
      setUrlStatus,
      undefined,
      undefined,
      true
    );
    if (newStatus || urlStatus) validator.renderUrlCheck(newStatus, setUrlError);
    return newStatus;
  };

  const navigateNextPageHandler = async () => {
    setFailedNext(true);
    const currentUrlStatus = await validateUrl();
    if (
      (currentUrlStatus === URL_STATUSES.EMPTY_WEBSITE.STATUS ||
        currentUrlStatus === URL_STATUSES.INVALID_WEBSITE.STATUS) &&
      contextState.hasWebsite
    ) {
      validator.renderUrlCheck(currentUrlStatus, setUrlError);
      return;
    }
    navigate(path.createAccountStep4.href);
  };

  // Navigate back to first Page if Context is lost (eg. Reloaded Page)
  if (!contextState.userEmail) {
    navigate(path.createAccountStep1.href);
  }

  useEffect(() => {
    if (contextState.promotionMethods.length === 0) {
      getPromotionMethodsHandler();
    }
    if (contextState.productCategories.length === 0) {
      getProductCategoriesHandler();
    }
  }, []);

  useEffect(() => {
    if (failedNext) {
      validateUrl();
    }
  }, [contextState.companyUrl]);

  return {
    hookErrorMessage: errorMessage,
    hookPromotionMethodsLoading: promotionMethodsLoading,
    hookProductCatsLoading: productCatsLoading,
    hookContextState: contextState,
    hookContextDispatcher: contextDispatcher,
    hookNavigate: navigateHandler,
    hookSetProfilePhoto: setProfilePhotoHandler,
    hookPublisherProductCategories: contextState.productCategories,
    hookSetPublisherProductCategoriesHandler: setPublisherProductCategoriesHandler,
    hookPublisherPromotionMethods: contextState.promotionMethods,
    hookSetPublisherPromotionMethodsHandler: setPublisherPromotionMethodsHandler,
    hookUrlError: urlError,
    hookNavigateNextPageHandler: navigateNextPageHandler,
    hookValidateUrl: validateUrl,
  };
};
