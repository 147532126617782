import React from 'react';

import { CellProps } from '../../types';
import { CellWrapper } from '../../styles';

export const Cell = ({ val }: CellProps) => (
  <CellWrapper>
    <p>{val}</p>
  </CellWrapper>
);
