import styled from 'styled-components';

import { colors, fonts } from '../../../../../styles/theme';
import { toRem, imageList } from '../../../../../utils';

export const WrapperStyled = styled.div`
  color: ${colors.color1};
  margin-top: 2rem;
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H16}
  line-height: 1.5;
`;

export const DetailDescriptionStyled = styled.p`
  ${fonts.lato.H16B}
  line-height: 1.5;
`;

export const TitleStyled = styled.p`
  ${fonts.gotham.H22}
  line-height: 1.5;
  margin: 2rem 0;
`;

export const Svg = styled.svg`
  min-width: 33%;
  min-height: ${toRem(200)};
  background: url(${imageList.noImage.src}) no-repeat center hsl(0, 0%, 80%);
  background-size: calc(100% / 3);
`;

export const OfferWrapper = styled.div`
  background-color: ${colors.color36};
  margin-top: ${toRem(10)};
  padding: ${toRem(20)};
  width: 100%;
`;

export const Large = styled.p`
  ${fonts.gotham.H20M}
  font-weight: bold;
  margin-top: ${toRem(64)};
`;

export const BigImageStyled = styled.img`
  min-width: 33%;
`;

export const Caption = styled.p`
  margin-top: ${toRem(20)};
  ${fonts.lato.H16}
`;

export const SubTitleStyled = styled.p`
  ${fonts.gotham.H12M}
  color: ${colors.color13};
  text-transform: uppercase;
  line-height: 1.5;
  margin: 1rem 0;
`;

export const FlexStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10%;
  justify-content: space-between;
  margin: 2rem 0;
`;

export const LinkStyled = styled.a`
  ${fonts.lato.H16}
  color: ${colors.color12};

  &:hover {
    color: ${colors.color2};
  }
`;
