import React from 'react';

import {
  AppWrapper,
  Button,
  CustomDropdownCalendar,
  InputText,
  InputTextarea,
  LoadingDots,
  PageTitle,
  Select,
} from '../../../components';
import { path } from '../../../utils';
import {
  SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS,
  SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS,
  SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS,
  SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS,
} from '../PerfomanceReport/Modals/SaveReportProfile/enums';
import { DefaultPropsType } from '../../../types';

import { useEditReport } from './hooks';
import { EDIT_REPORT } from './enums';
import { ColumnFilters, FilterSettings, LoadingMerchant } from './components';
import * as Styled from './styles';

type EditSavedReportsProps = DefaultPropsType;

export const EditSavedReports = ({ permissionsCodeList = [] }: EditSavedReportsProps) => {
  const hook = useEditReport();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <Styled.HeaderInnerStyled>
          <PageTitle>{path.editSavedReports.name}</PageTitle>
          <LoadingMerchant merchant={hook.hookFiltersState.merchantId} loading={hook.hookLoadingFilters} />
        </Styled.HeaderInnerStyled>
        <Styled.ButtonWrapperStyled>
          <Button
            theme="secondary"
            onClick={hook.hookNavigate}
            disabled={hook.hookLoadingFilters}
            loading={hook.hookLoadingFilters}
          >
            {EDIT_REPORT.BACK}
          </Button>
          <Button onClick={hook.hookUpdateReport} disabled={hook.hookLoadingFilters} loading={hook.hookLoadingFilters}>
            {EDIT_REPORT.SAVE}
          </Button>
        </Styled.ButtonWrapperStyled>
      </Styled.HeaderStyled>

      <Styled.GridStyled columns={2}>
        <InputText
          type="text"
          label={EDIT_REPORT.LABELS.REPORT_NAME}
          value={hook.hookReportState.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            hook.hookReportDispatcher({
              data: {
                name: e.target.value,
              },
            })
          }
          error={hook.hookInputErrors.reportName}
          required
        />
        <Select
          label={EDIT_REPORT.LABELS.SCHEDULE}
          selected={{
            label: hook.hookReportState.frequency || EDIT_REPORT.NEVER,
            value: hook.hookReportState.frequency || EDIT_REPORT.NEVER,
          }}
          options={SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS}
          onChange={(e: SelectOption) => {
            hook.hookReportDispatcher({
              data: {
                frequency: e.value,
              },
            });
            switch (e.value) {
              case SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS[2].value:
                hook.hookReportDispatcher({ data: { every: SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS[0] } });
                break;
              case SAVE_REPORT_PROFILE_SCHEDULE_FREQUENCY_OPTIONS[3].value:
                hook.hookReportDispatcher({ data: { every: SAVE_REPORT_PROFILE_SCHEDULE_MONTH_OPTIONS[0] } });
                break;
              default:
                hook.hookReportDispatcher({ data: { every: null } });
            }
          }}
        />
        <InputTextarea
          label={EDIT_REPORT.LABELS.DESCRIPTION}
          value={hook.hookReportState.description || ''}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            hook.hookReportDispatcher({
              data: {
                description: e.target.value,
              },
            })
          }
        />
        {hook.hookReportState.frequency === EDIT_REPORT.WEEKLY && (
          <Select
            label={EDIT_REPORT.LABELS.FREQUENCY}
            selected={hook.hookReportState.every || { label: '', value: '' }}
            options={SAVE_REPORT_PROFILE_SCHEDULE_WEEK_OPTIONS}
            onChange={(e: SelectOption) =>
              hook.hookReportDispatcher({
                data: {
                  every: e,
                },
              })
            }
          />
        )}
        {hook.hookReportState.frequency === EDIT_REPORT.MONTHLY && (
          <CustomDropdownCalendar
            label={EDIT_REPORT.LABELS.FREQUENCY}
            selected={hook.hookReportState.every || { label: '', value: '' }}
            onChange={(e: SelectOption) => {
              hook.hookReportDispatcher({
                data: {
                  every: e,
                },
              });
            }}
          />
        )}
        <Styled.PermissionStyled
          label={EDIT_REPORT.LABELS.PERMISSIONS}
          selected={{
            label: hook.hookReportState.company ? EDIT_REPORT.ALL_USERS : EDIT_REPORT.ONLY_ME,
            value: hook.hookReportState.company,
          }}
          options={SAVE_REPORT_PROFILE_PERMISSIONS_OPTIONS}
          onChange={(e: SelectOption) =>
            hook.hookReportDispatcher({
              data: {
                company: e.value,
              },
            })
          }
        />
      </Styled.GridStyled>

      <Styled.HeaderStyled>
        <PageTitle>{EDIT_REPORT.SETTINGS}</PageTitle>
      </Styled.HeaderStyled>
      {!hook.hookDateLoad ? (
        <LoadingDots />
      ) : (
        <FilterSettings
          state={hook.hookFiltersState}
          dispatcher={hook.hookFiltersDispatcher}
          lists={hook.hookLists}
          loading={hook.hookLoadingFilters || !hook.hookDateLoad}
          isTransactionMaster={hook.hookReportType === EDIT_REPORT.TRANSACTION}
          hook={hook}
        />
      )}
      <Styled.HeaderStyled>
        <PageTitle>{EDIT_REPORT.COLUMNS}</PageTitle>
      </Styled.HeaderStyled>
      {!hook.hookDateLoad ? (
        <LoadingDots />
      ) : (
        <>
          <ColumnFilters
            state={hook.hookColumnsState}
            dispatcher={hook.hookColumnsDispatcher}
            isTransactionMaster={hook.hookReportType === EDIT_REPORT.TRANSACTION}
          />
          <Styled.TableStyled
            columns={hook.hookTableColumns}
            data={hook.hookTableData}
            onDragEnd={hook.hookSetOnDragEnd}
            headers={false}
          />
        </>
      )}
    </AppWrapper>
  );
};
