import styled from 'styled-components';

import { Button, InputText, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  margin-bottom: 1rem;
`;

export const TitleStyled = styled.div`
  ${fonts.gotham.H22}
  margin: 3rem 0;
`;

export const ButtonStyled = styled(Button)`
  width: 160px;
  height: 40px;

  svg {
    margin-right: 5px;
  }
`;

export const FieldsStyled = styled.div`
  display: flex;
  column-gap: 2%;
  margin: 2rem 0;
  align-items: start;
  justify-content: space-between;

  ${ButtonStyled} {
    margin-top: 20px;
  }
`;

export const FileFieldStyled = styled.div`
  display: grid;
  column-gap: 1.7%;
  margin: 2rem 0;
  align-items: start;
  justify-content: space-between;
  grid-template-columns: 14fr 14fr 0.5fr;

  ${ButtonStyled} {
    margin-top: 25px;
  }
`;

export const ButtonFieldStyled = styled.div`
  display: flex;
  column-gap: 2%;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
`;

export const AdvancedStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2%;
  row-gap: 2.5rem;
  margin-bottom: 5rem;
`;

export const InputStyled = styled(InputText)`
  grid-column: 1 / 3;
`;

export const SelectStyled = styled(Select)`
  grid-column: 1 / 4;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const DeleteButtonStyled = styled(Button)`
  background: transparent;
  color: ${colors.color16};
  margin-top: 1.7rem;
  font-size: 1.2rem;
  border: none;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    background: transparent;
    color: ${colors.color2};
    border: none;
  }
`;
