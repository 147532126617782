import styled, { css } from 'styled-components';

import { getSideMenuItemStyle } from 'components/SideMenu/utils/getMenuItemStyle.utils';
import { fonts } from 'styles/theme';
import { toRem } from 'utils';

interface INestedMenuItemContainerProps {
  show: boolean;
}

interface INestedMenuItemHeaderProps {
  level: number;
  isOpen: boolean;
  isUnderManagement: boolean;
}

export const NestedMenuItemHeader = styled.button<INestedMenuItemHeaderProps>`
  ${({ level, isUnderManagement, isOpen }) => {
    const style = getSideMenuItemStyle(level, isUnderManagement);
    return css`
      display: grid;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem ${style.indent};
      width: 100%;
      text-align: left;
      background: ${style.backgroundColor};
      border: none;
      border-left: ${toRem(3)} solid transparent;
      cursor: pointer;
      color: ${style.color};
      font-size: 0.9rem;
      grid-template-columns: 1fr 10px;
      ${fonts.gotham.H12M}

      p {
        margin-top: ${toRem(2)};
      }

      &:hover {
        color: ${style.hoverColor};
      }

      &.active {
        color: ${style.activeColor};
      }

      svg:last-child {
        font-size: 0.8rem;
        margin-top: ${toRem(3)};
        transition: transform 0.3s;
        transform: rotate(${isOpen ? '90deg' : '0'});
      }
    `;
  }}
`;

export const NestedMenuItemContent = styled.div`
  overflow: hidden;
`;

export const NestedMenuItemContainer = styled.div<INestedMenuItemContainerProps>`
  ${NestedMenuItemContent} {
    max-height: ${({ show }) => (show ? '100%' : '0')};
  }
`;
