import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Select, Button, InputText, InputTextarea, PageTitle, Checkbox } from 'components';
import { colors, fonts } from 'styles/theme';
import { toRem } from 'utils';

export const WrapperStyled = styled.div`
  padding-bottom: 80px;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const HeaderButtonsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const HeaderStyledRuleManager = styled.div`
  display: flex;
  margin-bottom: 4rem;
`;

export const HeaderStyledRuleMangerTitle = styled.div`
  display: flex;
  flex: 1 0 0;
  justify-content: flex-start;
`;

export const HeaderStyledRuleMangerButtons = styled.div`
  display: flex;
  flex: 4 0 0;
  justify-content: flex-end;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  margin-bottom: 2rem;
`;

export const FooterWrapperStyled = styled.div`
  display: flex;
`;

export const RecordsStyled = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const ClearFormCSVSection = styled.div`
  flex: 1 0 0;
  display: flex;
  justify-content: flex-end;
`;

export const ExtendedRecordsStyled = styled.div`
  flex: 1 0 0;
  display: flex;
  align-items: center;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 0.625rem;
`;

export const RecordsLabelStyled = styled.div`
  ${fonts.lato.H14}
  color: ${colors.color1};
`;

export const SelectStyled = styled(Select)`
  width: 120px;
  margin: 0 1rem;
`;

export const TopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const CloseButtonStyled = styled(Button)`
  border: transparent;
  font-size: 1.2rem;

  &:hover {
    border: transparent;
    background: transparent;
    color: ${colors.color2};
  }
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.2rem;
`;

export const ContentStyled = styled.div`
  margin-bottom: 5px;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H16}
  color: ${colors.color1};
  margin-bottom: 2.5rem;
`;

export const InputStyled = styled(InputText)``;

export const EditInputStyled = styled(InputText)`
  flex: 0.3;
`;

export const EditInputAreaStyled = styled(InputTextarea)`
  flex: 0.3;
`;

export const RuleDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

export const EditSelectStyled = styled(Select)`
  flex: 0.3;
`;

export const EditRuleWrapper = styled.div`
  padding-top: 4rem;
`;

export const EditRuleLabelStyled = styled.p`
  ${fonts.lato.H20B}
  color: ${colors.color1};
  margin-bottom: 1rem;
`;

export const EditRuleContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 2rem;
  padding-top: 1rem;
`;

export const RuleCriteriaContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  padding-top: 1rem;
`;

export const RuleCriteriaContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 2rem;
`;

export const CategoryWrapper = styled.div`
  flex: 0.3;
  padding-bottom: 2rem;
`;

export const CategoryHeader = styled.p`
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: ${colors.color13};
  ${fonts.gotham.H12M};
`;

export const CategoryValue = styled.p`
  width: 100%;
  color: ${colors.color1};
  ${fonts.lato.H16}
  padding-bottom: 1rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  span {
    opacity: 1;
  }
`;
export const DetailsButton = styled(Button)`
  border: 1px solid ${colors.color2};
  height: 44px;
  width: 100%;
  :hover {
    border: 1px solid ${colors.color2};
  }
`;

export const RuleStatusWrapper = styled.div`
  margin-top: 1rem;
`;

export const RuleStatusLabel = styled.p`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  text-transform: uppercase;
`;

export const RuleStatusDescription = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
  padding-top: 1rem;
  padding-bottom: 0.75rem;
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: 0.25rem;
`;

export const CustomTextWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
  flex-direction: column;
`;

export const CustomTextListHeader = styled.p`
  ${fonts.lato.H16B};
  color: ${colors.color1};
  margin-bottom: 1rem;
`;

export const CustomTextInfo1 = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
  ${fonts.lato.H14};
  color: ${colors.color1};
`;

export const CustomTextValuesWrapper = styled.p`
  width: 100%;
  color: ${colors.color1};
  ${fonts.lato.H16};
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const CustomTextValue = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  align-items: center;
`;

export const CTSpanIndex = styled.span`
  margin-right: 0.75rem;
  text-align: center;
  ${fonts.lato.H16B};
  color: ${colors.color1};
`;

// ******************************************************************

type ProductFeedButtonProps = {
  readonly active: boolean;
};

export const StyledSelected = styled(Select)`
  max-height: 100px;
`;

export const BackButton = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const ModalWrapperStyled = styled.div`
  color: ${colors.color1};
  display: flex;
  flex-direction: column;
  width: 696px;
  padding: 8px 16px;
`;

export const BackIconStyled = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  :hover {
    cursor: pointer;
  }
`;

export const PageTitleStyled = styled(PageTitle)`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const DropdownSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 8px;
`;

export const AddRuleButtonWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const SubTitleTextStyled = styled.h3`
  ${fonts.gotham.H20M};
  color: ${colors.color1};
  line-height: 1.5;
`;

export const TextStyled = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
`;

export const TextPaddedStyled = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
  padding-top: 32px;
`;

export const BoldTextStyled = styled.span`
  ${fonts.lato.H14B};
  color: ${colors.color1};
`;

export const InputHeaderStyled = styled.label`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  text-transform: uppercase;
`;

export const EditRuleSelectStyled = styled(Select)`
  .optionLabel {
    ${fonts.lato.H14B};
    color: ${colors.color1};
    padding: 8px 0;
  }
  .optionDescription {
    ${fonts.lato.H14};
    color: ${colors.color16};
    padding-bottom: 8px;
  }
`;

export const RequirementRowStyled = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr 40px;
  grid-template-rows: 1fr auto;
  gap: 16px;
  padding: 12px 0;
`;

export const DeleteRequirementButton = styled.button`
  height: ${toRem(40)};
  width: ${toRem(40)};
  border-radius: 5px;
  border: 1px solid ${colors.color3};
  background-color: ${colors.color4};
  margin-top: ${toRem(22)};
  :hover {
    cursor: pointer;
    border: 1px solid ${colors.black};
  }
`;

export const RequiredCheckboxWrapper = styled.div`
  width: fit-content;
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${toRem(32)};
`;

export const PageCounterStyled = styled.div`
  ${fonts.lato.H14}
  color: ${colors.color1};
`;

export const NavButtonsWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export const AndSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0 12px 0;
`;

export const AndText = styled.span`
  color: ${colors.color23};
  ${fonts.lato.H16}
  margin: 0 8px;
`;

export const AndLine = styled.span`
  width: 100%;
  border-bottom: 1px solid ${colors.color15};
  margin-bottom: 7px;
`;

export const ProductFeedButton = styled.button<ProductFeedButtonProps>`
  height: ${toRem(40)};
  background-color: ${colors.color4};
  width: 100%;
  border: ${({ active }) => (active ? `1px solid ${colors.color2}` : `1px solid ${colors.color3}`)};
  color: ${colors.color2};

  :hover {
    cursor: ${({ active }) => (active ? 'pointer' : 'initial')};
  }
`;

export const ProductFeedSection = styled.div`
  text-transform: uppercase;
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  width: 100%;
  padding-bottom: 8px;
`;

export const CriteriaTitleStyled = styled.div`
  ${fonts.lato.H14B};
  padding-bottom: 4px;
`;

export const PageWrapper = styled.div`
  width: 100%;
`;

export const FormStyled = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const TextDescription = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  padding-top: 1rem;
`;

export const TextSubDescription = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  white-space: nowrap;
`;

export const TextDescriptionBold = styled.p`
  ${fonts.lato.H16B};
  color: ${colors.color1};
  padding-top: 1rem;
`;

export const RuleButtonsWrapper = styled(NavButtonsWrapperStyled)`
  gap: 2vw;
  position: fixed;
  bottom: 0.5rem;
  right: 0;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem 1rem;
`;

export const SectionContentStyled = styled.div`
  width: 100 %;
  display: flex;
  align-items: center;
`;

export const InputSection = styled(SectionContentStyled)`
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const RadioLabelStyled = styled.div`
  ${fonts.lato.H14}
  margin-left: 0.2rem;
`;

export const InputItem = styled.div`
  flex: 1;
  display: flex;
`;

export const InputItemType = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
`;

export const InputItemValue = styled.div`
  flex: 0.5;
`;

export const HeaderInfoText = styled.div`
  ${fonts.lato.H16};
  color: ${colors.color1};
`;

export const TextDescriptionWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const TextCriteriaContentWrapper = styled.div`
  margin-top: 3rem;
`;

export const TextFieldLabel = styled.p`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  text-transform: uppercase;
`;

export const TextFieldLabelDisclaimer = styled.p`
  ${fonts.lato.H12};
  color: ${colors.color13};
  margin-bottom: 0.5rem;
`;

export const TextFieldValue = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  word-break: break-word;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
  flex: 1;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 0.5rem;
  margin-top: 1rem;
  flex: 1;
`;

export const TextMatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
