import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useFintelCheckContext } from '../../hooks';
import { GET_FINTEL_CHECK_DATES, GetFintelCheckDateInput, GetFintelCheckDateOutput } from '../graphql/queries';
import { useUserInfo } from '../../../../../hooks';
import { FintelCheckHistoryOptions } from '../../../../../utils';
import { compareValues } from '../../../../Reports/PerfomanceReport/utils';

type HistoryOptionsProps = {
  label: string;
  value: string | number | null;
};

type HistoryRowType = {
  checkDate: string | null;
  publisherCount: number | null;
  zipfiles: string | null;
  status: string;
  summary: string | null;
};

const RECORDS_PER_PAGE = 10;

export const useHistory = () => {
  const navigate = useNavigate();
  const { contextDispatchHandler } = useFintelCheckContext();
  const { hookWhoAmI } = useUserInfo();

  const [dateRange, setDateRange] = useState<HistoryOptionsProps>(FintelCheckHistoryOptions[0]);
  const [allData, setAllData] = useState<HistoryRowType[]>([]);
  const [tableData, setTableData] = useState<HistoryRowType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getCheckDates, { loading: checkDatesLoading }] = useLazyQuery<
    GetFintelCheckDateOutput,
    GetFintelCheckDateInput
  >(GET_FINTEL_CHECK_DATES);

  const getCheckDatesHandler = async () => {
    setErrorMessage('');
    const { data } = await getCheckDates({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId?.toString() || '',
          year: dateRange.value !== null ? '' : dateRange.label,
          numMonths: dateRange.value ? Number(dateRange.value) : null,
        },
      },
      fetchPolicy: 'no-cache',
      onError(error) {
        setErrorMessage(error.message);
      },
    });
    if (data) {
      const formattedData = data.fintelCheckDates.checkDates.map(
        (item): HistoryRowType => ({ ...item, status: 'Full run', summary: item.checkDate })
      );
      setTableData(formattedData.slice((currentPage - 1) * RECORDS_PER_PAGE, currentPage * RECORDS_PER_PAGE));
      setAllData(formattedData);
      setCurrentPage(1);
      setTotalPages(Math.ceil(formattedData.length / RECORDS_PER_PAGE));
    } else {
      setCurrentPage(1);
      setTotalPages(1);
      setTableData([]);
      setAllData([]);
    }
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    setTableData(allData.slice((newPage - 1) * RECORDS_PER_PAGE, newPage * RECORDS_PER_PAGE));
  };

  const onSortHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    setSortColumn({
      column,
      direction: direction === 'desc' ? 'asc' : 'desc',
    });
    const sortFunction = compareValues('idNum', 'asc');
    const sortedData = [...allData].sort(sortFunction);
    setAllData(sortedData);
    setTableData(sortedData.slice((currentPage - 1) * RECORDS_PER_PAGE, currentPage * RECORDS_PER_PAGE));
  };

  const setDateRangeHandler = (value: SelectOption) => {
    setDateRange(value);
  };

  useEffect(() => {
    getCheckDatesHandler();
  }, [dateRange]);

  return {
    hookLoading: checkDatesLoading,
    hookErrorMessage: errorMessage,

    hookNavigate: navigate,
    hookData: tableData,
    hookDateRange: dateRange,
    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSortColumn: sortColumn,
    hookSortColumnHandler: onSortHandler,
    hookSetCurrentPage: setCurrentPageHandler,
    hookSetDateRange: setDateRangeHandler,
    hookSetContext: contextDispatchHandler,
  };
};
