import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

type CellWrapperStyledProps = {
  readonly theme?: string;
};

export const CellWrapperStyled = styled.div`
  color: ${colors.color1};
  ${fonts.lato.H16};
  line-height: 20px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: normal;
  gap: 4px;

  b {
    ${fonts.lato.H16B};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    ${fonts.lato.H14};
    color: ${colors.color13};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const UrlLinkStyled = styled.a`
  ${fonts.lato.H16};
  color: ${colors.color12};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
`;

export const InternalUrlLinkStyled = styled.a`
  ${fonts.lato.H16};
  color: ${colors.color2};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
`;

export const CheckboxWrapperStyled = styled.div<CellWrapperStyledProps>`
  height: 100%;
  min-height: ${({ theme }) => (theme === 'checkBox' ? 'auto' : toRem(38))};
  display: flex;
  align-items: ${({ theme }) => (theme === 'checkBox' ? 'center' : 'flex-start')};
  justify-content: ${({ theme }) => (theme === 'checkBox' ? 'center' : 'space-between')};
  flex-direction: ${({ theme }) => (theme === 'checkBox' ? 'row' : 'column')};
`;
