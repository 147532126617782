import { gql } from '@apollo/client';

export const GET_MONITORING_DETAILS_DATA = gql`
  query changeMonitoringDetailsData($input: ChangeMonitoringDetailsInput!) {
    changeMonitoringDetailsData(input: $input) {
      id
      diffImageS3Url
      diffImage
      snippedChanges {
        deleted {
          id
          text
        }
        added {
          id
          text
        }
      }
      statusChanges {
        status
        reviewer
        reviewDate
        note
      }
    }
  }
`;

export type ChangeMonitoringDetailsInput = {
  input: {
    id: string;
  };
};

type MonitoringDetailsChangesType = {
  id: number;
  text: string;
};

export type ReviewStatusChangesType = {
  status: string;
  reviewer: string;
  reviewDate: Date;
  note: string;
};

export type MonitoringDetailsType = {
  id: string;
  diffImageS3Url: string;
  diffImage: string;
  snippedChanges: {
    deleted: MonitoringDetailsChangesType[];
    added: MonitoringDetailsChangesType[];
  }[];
  statusChanges: ReviewStatusChangesType[];
};

export type ChangeMonitoringDetailsOutput = {
  changeMonitoringDetailsData: MonitoringDetailsType;
};
