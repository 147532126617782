import styled from 'styled-components';

import { colors, fonts } from '../../../../../../../styles/theme';

type CellWrapperProps = {
  readonly value: any;
};

export const CellWrapper = styled.div<CellWrapperProps>`
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ value }) => {
    if (value?.website || value?.description) {
      return 'space-between';
    }
    return 'center';
  }};
`;

export const ProfileStyled = styled.p`
  ${fonts.lato.H14}
`;

export const WebsiteStyled = styled.a`
  ${fonts.lato.H12}
  text-decoration: none;
  color: ${colors.color2};
`;

export const DescriptionStyled = styled.p`
  ${fonts.lato.H12}
  color: ${colors.color24};
`;

export const EditStyled = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
