import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from '../styled';
import { Button, Modal, PageTitle } from '../../../../../../../components';
import { imageList } from '../../../../../../../utils';
import type { useSummaryReport } from '../../../../Components/SummaryReport/hooks';

import { GRAPH_TITLE, MODAL } from './enums';
import { usePublisherChart } from './hooks';

type PublisherChartProps = {
  summaryHook: ReturnType<typeof useSummaryReport>;
};

export const PublisherChecksGraph = ({ summaryHook }: PublisherChartProps) => {
  const hook = usePublisherChart(summaryHook);

  return (
    <Styled.Wrapper>
      <Styled.TitleWrapperStyled>
        <Styled.TitleStyled>{GRAPH_TITLE}</Styled.TitleStyled>
        <Button theme="quaternary" onClick={hook.hookSetIsModalOpen}>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Button>
      </Styled.TitleWrapperStyled>
      <Styled.MapWrapperStyled>
        {hook.hookGraphLoading ? (
          <Styled.FigureStyled>
            <img src={imageList.publisherCheckPlaceholder.src} alt={imageList.publisherCheckPlaceholder.alt} />
          </Styled.FigureStyled>
        ) : (
          <Styled.MapStyled id="publisherCheckGraph" />
        )}
      </Styled.MapWrapperStyled>
      <Modal
        isOpen={hook.hookIsModalOpen}
        closeModalFunction={() => {
          if (hook.hookIsModalOpen) {
            hook.hookSetIsModalOpen();
          }
        }}
      >
        <Styled.ModalWrapperStyled>
          <PageTitle>{MODAL.TITLE}</PageTitle>
          <Styled.ModalTextStyled>{MODAL.DESCRIPTION}</Styled.ModalTextStyled>
          <Styled.FigureStyled>
            <img src={imageList.publisherCheckSample.src} alt={imageList.publisherCheckSample.alt} />
          </Styled.FigureStyled>
          {MODAL.CHECK.map((item, index) => (
            <Styled.ModalCheckWrapperStyled key={item.TITLE}>
              <Styled.CheckColorStyled index={index} />
              <Styled.ModalCheckWrapperStyled column>
                <Styled.ModalCheckTypeStyled>{item.TITLE}</Styled.ModalCheckTypeStyled>
                <Styled.ModalTextStyled>{item.DESCRIPTION}</Styled.ModalTextStyled>
              </Styled.ModalCheckWrapperStyled>
            </Styled.ModalCheckWrapperStyled>
          ))}
        </Styled.ModalWrapperStyled>
      </Modal>
    </Styled.Wrapper>
  );
};
