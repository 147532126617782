import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Button, Calendar, InputCalendar, LoadingDots, Placeholder, Select } from '../../../../../components';
import {
  BUTTON_LABEL,
  INPUT_LABEL,
  MODAL_TYPES,
  PLACEHOLDER,
  PLACEHOLDER_MONTHLY_RECORDED,
  PRODUCT_CATEGORIES,
  SUBTITLE,
  monthlyAccountColumns,
  monthlyAccountenableNewStatsColumns,
} from '../../contracts';
import * as Styled from '../../styles';
import { imageList } from '../../../../../utils';

type MonthlyAccountBalanceProps = {
  monthlyAccountBalanceData: MonthyAccountBalance[];
  monthlyAccountBalanceTotals: { [key: string]: any };
  date?: Date;
  endDate?: Date;
  handleCalenderApply: (date: Date, endDate?: Date, range?: string) => void;
  selectProductCategory: SelectOption;
  setSelectProductCategory: (value: SelectOption) => void;
  calendarOpen: boolean;
  setCalendarOpen: () => void;
  handleCalenderCancel: React.MouseEventHandler<HTMLButtonElement>;
  handleClearForm: () => void;
  csvDownloadHandler: () => void;
  openAddModal: (openModal: boolean, type: string) => void;
  isEnableNewStats: boolean;
  numberOfPages: number;
  currentPage: number;
  tableLoading: boolean;
  onPageChange: (page: number) => void;
  usePlaceholder: boolean;
  isLoading: boolean;
  setSortBy: (dataField: string, direction: 'asc' | 'desc' | undefined) => void;
  sortBy: TableSortColumn;
  onRowClick: (row: any) => void;
  onInvoiceClick: (row: any) => void;
  isReadOnly: boolean;
};

export const MonthlyAccountBalance = ({
  monthlyAccountBalanceData,
  handleCalenderApply,
  setSortBy,
  sortBy,
  date,
  endDate,
  calendarOpen,
  setCalendarOpen,
  handleClearForm,
  onRowClick,
  handleCalenderCancel,
  selectProductCategory,
  setSelectProductCategory,
  openAddModal,
  csvDownloadHandler,
  monthlyAccountBalanceTotals,
  isEnableNewStats,
  currentPage,
  numberOfPages,
  onPageChange,
  usePlaceholder,
  isLoading,
  onInvoiceClick,
  tableLoading,
  isReadOnly,
}: MonthlyAccountBalanceProps) => (
  <div>
    <Styled.SubsectionStyled>
      <Styled.SubtitleStyled>{SUBTITLE.MONTHLY}</Styled.SubtitleStyled>

      <Styled.ButtonContainerStyled>
        <Button onClick={() => openAddModal(true, MODAL_TYPES.FEE)} theme="secondary" disabled={isReadOnly}>
          <Styled.IconStyled icon={faPlus} />
          {BUTTON_LABEL.ADD_FEE}
        </Button>
        <Styled.ButtonWrapperStyled>
          <Button onClick={() => openAddModal(true, MODAL_TYPES.DEPOSIT)} disabled={isReadOnly}>
            <Styled.IconStyled icon={faPlus} />
            {BUTTON_LABEL.ADD_DEPOSIT}
          </Button>
        </Styled.ButtonWrapperStyled>
      </Styled.ButtonContainerStyled>
    </Styled.SubsectionStyled>

    {usePlaceholder ? (
      <Placeholder
        title={PLACEHOLDER_MONTHLY_RECORDED.TITLE}
        subtitle={PLACEHOLDER_MONTHLY_RECORDED.SUBTITLE}
        placeholderImage={imageList.lightHousePlaceholder}
      />
    ) : (
      <>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <>
            <Styled.SubsectionStyled>
              <Styled.ButtonContainerStyled width="80%">
                {isEnableNewStats && (
                  <Select
                    label={INPUT_LABEL.CATEGORY}
                    selected={selectProductCategory}
                    onChange={setSelectProductCategory}
                    options={PRODUCT_CATEGORIES}
                    width="40%"
                  />
                )}
                <Styled.ButtonWrapperStyled width="40%">
                  <InputCalendar
                    label={INPUT_LABEL.DATE}
                    placeholder={PLACEHOLDER.DATE}
                    value={date ? `${date.toDateString()} - ${endDate ? endDate.toDateString() : ''}` : ''}
                    width="100%"
                    onClick={setCalendarOpen}
                  />
                </Styled.ButtonWrapperStyled>
                <Styled.ButtonWrapperStyled>
                  <Button onClick={handleClearForm} theme="text-only">
                    {BUTTON_LABEL.CLEAR}
                  </Button>
                </Styled.ButtonWrapperStyled>
              </Styled.ButtonContainerStyled>

              <div>
                <Button onClick={csvDownloadHandler} theme="tertiary">
                  <Styled.IconWrapperStyled>
                    <FontAwesomeIcon icon={faFileArrowDown} />
                  </Styled.IconWrapperStyled>
                  {BUTTON_LABEL.DOWNLOAD}
                </Button>
              </div>
            </Styled.SubsectionStyled>
            <Styled.TableStyled
              isLoading={tableLoading}
              columns={isEnableNewStats ? monthlyAccountenableNewStatsColumns : monthlyAccountColumns}
              footerData={monthlyAccountBalanceTotals}
              data={monthlyAccountBalanceData}
              sortColumn={sortBy}
              onSort={setSortBy}
              setModalInfo={onRowClick}
              setContext={onInvoiceClick}
              onRowClick={onRowClick}
            />
          </>
        )}
        {numberOfPages > 1 && (
          <Styled.PaginationStyled total={numberOfPages} currentPage={currentPage} onPageChange={onPageChange} />
        )}
      </>
    )}

    <Calendar
      onApply={handleCalenderApply}
      onCancel={handleCalenderCancel}
      date={date}
      endDate={endDate}
      isOpen={calendarOpen}
    />
  </div>
);
