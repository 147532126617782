import React, { useState } from 'react';

import { AppWrapper, PageTitle, Tabs } from '../../../components';
import { path } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import PayPalOrders from './Tabs/PayPalOrders';
import PendingPayments from './Tabs/PendingPayments';
import { usePaypalOrder } from './hooks/usePaypalOrder';
import { PROCESS_PAYMENTS_TEXT } from './enums';
import { usePendingPayments } from './hooks/usePendingPayments';
import * as Styled from './styles';

type ProcessPaymentsProps = DefaultPropsType;

const ProcessPayments = ({ permissionsCodeList = [] }: ProcessPaymentsProps): JSX.Element => {
  const paypalHooks = usePaypalOrder();
  const [tab, setTab] = useState(0);

  const pendingHook = usePendingPayments(permissionsCodeList);

  const setTabHandler = (value: number): void => {
    setTab(value);
  };

  const hookTabs = { activeTabOveride: tab, setActiveTab: setTabHandler };

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <Styled.HeaderStyled>
          <PageTitle>{path.processPayment.name}</PageTitle>
          <Styled.HeaderDescriptionWrapperStyled>
            <Styled.HeaderDescriptionStyled>
              {PROCESS_PAYMENTS_TEXT.HEADER_DESC_PENDING_1}
            </Styled.HeaderDescriptionStyled>
            <Styled.HeaderDescriptionStyled>
              {PROCESS_PAYMENTS_TEXT.HEADER_DESC_PENDING_2}
            </Styled.HeaderDescriptionStyled>
          </Styled.HeaderDescriptionWrapperStyled>
        </Styled.HeaderStyled>
        <Tabs
          hookOveride={hookTabs}
          tabNames={[PROCESS_PAYMENTS_TEXT.TAB_NAME_PENDING_PAYMENTS, PROCESS_PAYMENTS_TEXT.TAB_NAME_PAYPAL_ORDER]}
          elements={[
            <PendingPayments hook={pendingHook} key="PendingPayments" />,
            <PayPalOrders hooks={paypalHooks} changeTabs={setTabHandler} key="PayPalOrders" />,
          ]}
        />
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default ProcessPayments;
