import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_DATES = gql`
  query getFintelCheckDates($input: FintelCheckSearchInput!) {
    fintelCheckDates(input: $input) {
      checkDates {
        checkDate
        publisherCount
        zipfiles
      }
    }
  }
`;

export type GetFintelCheckDateInput = {
  input: {
    merchantId: string;
    year: string;
    numMonths: number | null;
  };
};

export type GetFintelCheckDateOutput = {
  fintelCheckDates: {
    checkDates: {
      checkDate: string | null;
      publisherCount: number | null;
      zipfiles: string | null;
    }[];
  };
};
