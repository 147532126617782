import React from 'react';

import { INPUT_LABEL, NA, SUBTITLE } from '../../contracts';

import * as Styled from './styles';

type AccountInformationTabProps = {
  publisherDetails: PublisherDetailsType;
  membershipDetails: any;
  formatMembershipHistory: (status: string, approvedDate: string, updatedAt: string) => any;
  convertMembershipStatus: (publisherInvitationId: string, statusLastUpdatedBy: string, status: string) => string;
  publisherDefaultUser: string;
  isMemberPage: boolean;
};

const AccountInformationTab = ({
  publisherDetails,
  formatMembershipHistory,
  publisherDefaultUser,
  isMemberPage,
  membershipDetails,
  convertMembershipStatus,
}: AccountInformationTabProps) => (
  <Styled.WrapperStyled>
    <Styled.RowStyled>
      <Styled.PublisherInfoStyled>
        <Styled.PublisherLogoStyled src={publisherDetails.companyImgUrl} />
        <Styled.PublisherProfileNameStyled>{publisherDetails.companyName}</Styled.PublisherProfileNameStyled>
        <Styled.PublisherProfileSecondNameStyled>{publisherDefaultUser}</Styled.PublisherProfileSecondNameStyled>
      </Styled.PublisherInfoStyled>

      <Styled.DivAreaStyled>
        <Styled.LabelStyled>{INPUT_LABEL.ID}</Styled.LabelStyled>
        <Styled.ValueStyled>{publisherDetails.id}</Styled.ValueStyled>
      </Styled.DivAreaStyled>
      <Styled.DivAreaStyled>
        <Styled.LabelStyled>{INPUT_LABEL.NETWORK_DATE}</Styled.LabelStyled>
        <Styled.ValueStyled>{publisherDetails.createdAt}</Styled.ValueStyled>
      </Styled.DivAreaStyled>
      <Styled.DivAreaStyled>
        <Styled.LabelStyled>{INPUT_LABEL.ACTIVITY_STATUS}</Styled.LabelStyled>
        <Styled.ValueStyled>{publisherDetails.activityStatus}</Styled.ValueStyled>
      </Styled.DivAreaStyled>

      {isMemberPage && membershipDetails && (
        <>
          <Styled.DivAreaStyled gridRow="3/4">
            <Styled.LabelStyled>
              {membershipDetails.publisherInvitation ? INPUT_LABEL.INVITED_DATE : INPUT_LABEL.APPLIED_DATE}
            </Styled.LabelStyled>
            <Styled.ValueStyled>
              {new Date(membershipDetails?.createdAt).toLocaleDateString(undefined, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }) || ''}
            </Styled.ValueStyled>
          </Styled.DivAreaStyled>

          <Styled.DivAreaStyled gridRow="3/4">
            <Styled.LabelStyled>
              {membershipDetails.status === 'Pending' ? '' : INPUT_LABEL.MEMBERSHIP_HISTORY}
            </Styled.LabelStyled>
            <Styled.ValueStyled>
              <pre>
                {membershipDetails.status === 'Pending'
                  ? ''
                  : formatMembershipHistory(
                      membershipDetails?.status,
                      membershipDetails.approvedDate,
                      membershipDetails.updatedAt
                    )}
              </pre>
            </Styled.ValueStyled>
          </Styled.DivAreaStyled>

          <Styled.DivAreaStyled gridRow="3/4">
            <Styled.LabelStyled>{INPUT_LABEL.MEMBERSHIP_STATUS}</Styled.LabelStyled>
            <Styled.ValueStyled>
              {convertMembershipStatus(
                membershipDetails?.publisherInvitation?.id,
                membershipDetails?.statusLastUpdatedBy,
                membershipDetails?.status
              )}
            </Styled.ValueStyled>
          </Styled.DivAreaStyled>
        </>
      )}
    </Styled.RowStyled>

    <Styled.RowStyled>
      <Styled.SectionTitleStyled>{SUBTITLE.OVERVIEW}</Styled.SectionTitleStyled>

      <Styled.DivAreaStyled columnSpan={2}>
        <Styled.LabelStyled>{INPUT_LABEL.BUSINESS_DESCRIPTION}</Styled.LabelStyled>
        <Styled.ValueStyled>{publisherDetails.overview.businessDesc || NA}</Styled.ValueStyled>
      </Styled.DivAreaStyled>

      <Styled.DivAreaStyled gridRow="3/4">
        <Styled.LabelStyled>{INPUT_LABEL.PLATFORN_LINK}</Styled.LabelStyled>
        {publisherDetails.overview.primaryPlatformLink ? (
          <Styled.LinkStyled target="_blank" to={`${publisherDetails.overview.primaryPlatformLink}`}>
            {publisherDetails.overview.primaryPlatformLink}
          </Styled.LinkStyled>
        ) : (
          <p>{NA}</p>
        )}
      </Styled.DivAreaStyled>

      <Styled.DivAreaStyled gridRow="3/4">
        <Styled.LabelStyled>{INPUT_LABEL.AUDIENCE_SIZE}</Styled.LabelStyled>
        <Styled.ValueStyled>{publisherDetails.overview.avgMonthlyAudience || NA}</Styled.ValueStyled>
      </Styled.DivAreaStyled>

      <Styled.DivAreaStyled gridRow="4/5" columnSpan={3}>
        <Styled.LabelStyled>{INPUT_LABEL.AUDIENCE_MARKET}</Styled.LabelStyled>
        <Styled.ValueGridStyled>
          {publisherDetails.overview?.audienceMarkets?.length > 0 ? (
            publisherDetails.overview.audienceMarkets.map((market) => <p key={market}>{market}</p>)
          ) : (
            <p>{NA}</p>
          )}
        </Styled.ValueGridStyled>
      </Styled.DivAreaStyled>

      <Styled.DivAreaStyled gridRow="5/6" columnSpan={3}>
        <Styled.LabelStyled>{INPUT_LABEL.PRODUCT_CATEGORIES}</Styled.LabelStyled>
        <Styled.ValueGridStyled>
          {publisherDetails.overview?.productCategories.length > 0 ? (
            publisherDetails.overview.productCategories.map((product) => <p key={product}>{product}</p>)
          ) : (
            <p>{NA}</p>
          )}
        </Styled.ValueGridStyled>
      </Styled.DivAreaStyled>

      <Styled.DivAreaStyled gridRow="6/7" columnSpan={3}>
        <Styled.LabelStyled>{INPUT_LABEL.PROMOTION_METHOD}</Styled.LabelStyled>
        <Styled.ValueGridStyled>
          {publisherDetails.overview.promotionMethods.length > 0 ? (
            publisherDetails.overview.promotionMethods.map((method) => <p key={method}>{method}</p>)
          ) : (
            <p>{NA}</p>
          )}
        </Styled.ValueGridStyled>
      </Styled.DivAreaStyled>
    </Styled.RowStyled>

    <Styled.RowStyled>
      <Styled.SectionTitleStyled>{SUBTITLE.DEMOGRAPICS}</Styled.SectionTitleStyled>

      <Styled.DivAreaStyled columnSpan={3}>
        <Styled.LabelStyled>{INPUT_LABEL.AUDIENCE_AGE}</Styled.LabelStyled>
        <Styled.ValueGridStyled>
          {publisherDetails.overview.audienceAges.length > 0 ? (
            publisherDetails.overview.audienceAges.map((age) => <p key={age}>{age}</p>)
          ) : (
            <p>{NA}</p>
          )}
        </Styled.ValueGridStyled>
      </Styled.DivAreaStyled>

      <Styled.DivAreaStyled gridRow="3/4">
        <Styled.LabelStyled>{INPUT_LABEL.GENDER}</Styled.LabelStyled>
        <Styled.ValueStyled>
          {publisherDetails.overview.gender ? publisherDetails.overview.gender : NA}
        </Styled.ValueStyled>
      </Styled.DivAreaStyled>
      <Styled.DivAreaStyled gridRow="3/4">
        <Styled.LabelStyled>{INPUT_LABEL.RATING}</Styled.LabelStyled>
        <Styled.ValueStyled>
          {publisherDetails.overview.creditScoreRating ? publisherDetails.overview.creditScoreRating : NA}
        </Styled.ValueStyled>
      </Styled.DivAreaStyled>

      <Styled.DivAreaStyled columnSpan={3} gridRow="4/5">
        <Styled.LabelStyled>{INPUT_LABEL.INCOME}</Styled.LabelStyled>
        <Styled.ValueGridStyled>
          {publisherDetails.overview.avgIncomes.length > 0 ? (
            publisherDetails.overview.avgIncomes.map((income) => <p key={income}>{income}</p>)
          ) : (
            <p>{NA}</p>
          )}
        </Styled.ValueGridStyled>
      </Styled.DivAreaStyled>
      {membershipDetails?.status === 'Approved' && (
        <Styled.DivAreaStyled gridRow="5/6" columnSpan={3}>
          <Styled.LabelStyled>{INPUT_LABEL.PUBLISHER_GROUPS}</Styled.LabelStyled>
          <Styled.ValueGridStyled>
            {membershipDetails.publisherGroups && membershipDetails.publisherGroups.length > 0 ? (
              membershipDetails.publisherGroups.map((group: { name: string; id: string }) => (
                <p key={group.id}>{group.name}</p>
              ))
            ) : (
              <p>{NA}</p>
            )}
          </Styled.ValueGridStyled>
        </Styled.DivAreaStyled>
      )}
    </Styled.RowStyled>
  </Styled.WrapperStyled>
);

export default AccountInformationTab;
