import React from 'react';

import { AppWrapper, PageTitle, Tabs } from 'components';
import CurrencyRateTabs from 'components/CurrencyTabs';
import { path } from 'utils';
import { DefaultPropsType } from 'types';

import PaymentGenerator from './PaymentGenerator';
import { MAIN_TEXT } from './enums';
import * as Styled from './styles';

type GeneratePaymentsProps = DefaultPropsType;

const GeneratePayments = ({ permissionsCodeList = [] }: GeneratePaymentsProps): JSX.Element => (
  <AppWrapper permissionsCodeList={permissionsCodeList}>
    <Styled.WrapperStyled>
      <Styled.HeaderStyled>
        <PageTitle>{`${path.generatePayment.name} Process`}</PageTitle>
        <Styled.HeaderDescriptionWrapperStyled>
          <Styled.HeaderDescriptionStyled>{MAIN_TEXT.ONCE}</Styled.HeaderDescriptionStyled>
          <Styled.HeaderDescriptionStyled>{MAIN_TEXT.PENDING}</Styled.HeaderDescriptionStyled>
        </Styled.HeaderDescriptionWrapperStyled>
      </Styled.HeaderStyled>
      <Tabs
        tabNames={['Payment Generator', 'Currency Rates']}
        elements={[
          <PaymentGenerator permissionsCodeList={permissionsCodeList} key="Payment Generator" />,
          <CurrencyRateTabs key="CurrencyRateTabs" />,
        ]}
      />
    </Styled.WrapperStyled>
  </AppWrapper>
);

export default GeneratePayments;
