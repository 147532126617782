export const BUTTON_LABEL = {
  BACK: 'Back',
  DOWNLOAD: 'Download CSV',
  STATEMENT: 'Finalize Statement',
  CANCEL: 'Cancel',
  FINALIZE: 'Finalize',
  ACCOUNT_STATEMENT: 'Account Statement',
};

export const PLACEHOLDER = {
  SEARCH: 'Search by Publisher Name/Record Type',
};

export const MODAL_TITLE = 'Finalize Account Balance Statement';

export const MODAL_DESCRIPTION = 'Are you sure to finalize Account Balance Statement?';

export const MODALE_LIST_DESCRIPTION =
  "Make sure to complete the following items before finalizing the statement. Once the statement is finalized, it's not allowed to add any adjustment to the current period.";

export const MODALE_LIST = ['Enter Publisher manual adjustments', 'Enter Manual fees', 'Enter Deposits'];

export const SORT_DEFAULT: TableSortColumn = { column: 'recordDate', direction: 'asc' };

export const CSV_HEADERS_DETAILS = [
  { dataField: 'productCategory', text: 'CATEGORY' },
  { dataField: 'recordDate', text: 'RECORD DATE' },
  { dataField: 'recordName', text: 'RECORD NAME' },
  { dataField: 'recordType', text: 'RECORD TYPE' },
  { dataField: 'value', text: 'AMOUNT' },
  { dataField: 'systemFee', text: 'SYSTEM USAGE FEE' },
  { dataField: 'taxes', text: 'TAXES' },
  { dataField: 'totalCost', text: 'TOTAL COST' },
  { dataField: 'totalDeposit', text: 'TOTAL DEPOSITS' },
];
