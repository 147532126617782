import styled from 'styled-components';

import { Table } from '../../../../../components';
import { toRem } from '../../../../../utils';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${toRem(80)};
  margin-top: ${toRem(40)};
`;

export const TableStyled = styled(Table)`
  & tr > td:first-child {
    overflow: visible;
  }
  & div {
    overflow: visible;
  }
`;
