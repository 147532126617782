import { gql } from '@apollo/client';

export const NEW_SITE_MON_REPORT = gql`
  query newTrackingEvents($input: NewTrackingEventsInput) {
    newTrackingEvents(input: $input) {
      count
      totalPages
      limit
      currentPage
      trackingEventReportItems {
        referralUrl
        merchantId
        merchantName
        publisherId
        publisherName
        trackingProfileId
        trackingProfileUrl
        pageType
        adId
        adStatus
        productId
        productName
        impressions
        uniqueImpressions
        clicks
        uniqueClicks
        customizedProductId
        newToSystem
        createdAt
        updatedAt
        CTR
        RPI
        totalTransactions
        totalCommissions
        approvedTransactions
      }
    }
  }
`;
