import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components';
import { PUBLISHER_PREFIX, path } from 'utils';

import * as Styled from './styles';
import { APPLY_CARD } from './enums';

type CardProps = {
  programs: any[];
};

export const Card = ({ programs }: CardProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Styled.WrapperStyled>
      {programs.map((program) => (
        <Styled.CardWrapper key={program.company.companyName}>
          <Styled.HeaderStyled>
            <Styled.LogoStyled>
              <img src={program.company.companyImgUrl} alt={`${program.company.companyName} logo`} />
            </Styled.LogoStyled>
            <Styled.MerchantStyled>
              <Styled.MerchantNameStyled>{program.company.companyName}</Styled.MerchantNameStyled>
              <Styled.MerchantUrlStyled>{program.company.companyUrl}</Styled.MerchantUrlStyled>
            </Styled.MerchantStyled>
          </Styled.HeaderStyled>

          <Styled.LabelStyled>{APPLY_CARD.PRODUCT_CATEGORIES}</Styled.LabelStyled>
          <Styled.ValueStyled>{program.productCategories[0]}</Styled.ValueStyled>
          <Styled.LabelStyled>{APPLY_CARD.TARGET_MARKET}</Styled.LabelStyled>
          <Styled.ValueStyled>{program.targetMarkets[0]}</Styled.ValueStyled>

          <Styled.ButtonWrapper>
            <Button onClick={() => navigate(`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${program.id}`)}>
              {APPLY_CARD.APPLY}
            </Button>
          </Styled.ButtonWrapper>
        </Styled.CardWrapper>
      ))}
    </Styled.WrapperStyled>
  );
};
