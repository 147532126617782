import { gql } from '@apollo/client';

export const ACCOUNT_STATS = gql`
  query accountStats($input: AccountStatInput!) {
    accountStats(input: $input) {
      accountStats {
        programId
        startDate
        endDate
        adminRate
        minUsageFee
        minBalanceRequired
        activeCategories
        useRateSettings
        newUseTieredRate {
          id
          newAdminAfterTierRate
          newAdminTierCommission
          newAdminTierRate
        }
        managementFee {
          banking
          businesses
          creditCards
          creditReporting
          crypto
          insurance
          investments
          loans
          mortgage
          realEstate
          taxServices
          planning
        }
      }
    }
  }
`;
