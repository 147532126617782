import { gql } from '@apollo/client';

export const LIST_PUBLISHER_FILTER_OPTIONS = gql`
  query companies($input: SearchCompaniesFilterOptionsInput) {
    getSearchCompaniesFilterOptions(input: $input) {
      accountStatusTypes {
        publisherDisplayValue
        type
      }
      taxStatusTypes {
        type
      }
      countries {
        name
      }
      audienceMarkets {
        name
      }
      productCategories {
        name
      }
      promotionMethods {
        type
      }
      averageMonthlyAudience {
        range
      }
      activityStatusTypes {
        type
      }
      audienceAges {
        range
      }
      audienceGenders {
        type
      }
      creditScoreRatings {
        type
      }
      averageIncomes {
        range
      }
      paymentMethods {
        type
      }
    }
  }
`;
