import React from 'react';

import { BUTTON_LABEL, MODALE_LIST, MODALE_LIST_DESCRIPTION, MODAL_DESCRIPTION, MODAL_TITLE } from '../contracts';

import * as Styled from './styles';

type FinalizeModalProps = {
  setModalOpen: (modalOpen: boolean) => void;
  handleCreateFinalize: () => void;
  isLoading: boolean;
};

export const FinalizeModal = ({ setModalOpen, handleCreateFinalize, isLoading }: FinalizeModalProps) => (
  <Styled.ModalBodyStyled>
    <Styled.ModalTitleStyled>{MODAL_TITLE}</Styled.ModalTitleStyled>
    <p>{MODAL_DESCRIPTION}</p>

    <div>
      <p>{MODALE_LIST_DESCRIPTION}</p>
      <Styled.ModalListStyled>
        {MODALE_LIST.map((listItem) => (
          <Styled.ModalListItemStyled key={listItem}>{listItem}</Styled.ModalListItemStyled>
        ))}
      </Styled.ModalListStyled>
    </div>
    <Styled.ButtonContainerStyled>
      <Styled.ButtonStyled theme="secondary" onClick={() => setModalOpen(false)}>
        {BUTTON_LABEL.CANCEL}
      </Styled.ButtonStyled>

      <Styled.ButtonStyled onClick={handleCreateFinalize} loading={isLoading}>
        {BUTTON_LABEL.FINALIZE}
      </Styled.ButtonStyled>
    </Styled.ButtonContainerStyled>
  </Styled.ModalBodyStyled>
);
