import React from 'react';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppWrapper, Button, InfoBox, LoadingDots, Modal, PageTitle, Select } from '../../../components';
import { path, PRODUCT_CATEGORY } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import { AccountBalanceModal } from './Modals/AccountBalance';
import { useAccountBalanceManagement } from './hooks';
import { BUTTON_LABEL, ERROR_ICON, tableColumns } from './contracts';
import * as Styled from './styles';

type AccountBalanceManagementProps = DefaultPropsType;

const AccountBalanceManagement = ({ permissionsCodeList = [] }: AccountBalanceManagementProps) => {
  const hook = useAccountBalanceManagement();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {hook.hookErrors && <InfoBox theme="ERROR" title="" description={hook.hookErrors} icon={ERROR_ICON} />}
      <Styled.HeaderStyled>
        <PageTitle>{path.accountBalanceManagement.name}</PageTitle>
        <Button onClick={() => hook.hookSetModalOpen(true)} theme="tertiary">
          {BUTTON_LABEL.SELECT}
        </Button>
      </Styled.HeaderStyled>
      {!hook.hookIsLoading && (
        <>
          <Styled.FilterWrapperStyled>
            <Styled.FilterStyled>
              <Select
                label="Product Category"
                selected={hook.hookSelectedProductCategory}
                onChange={hook.hookSetSelectedProductCategory}
                options={PRODUCT_CATEGORY}
              />

              <Styled.FilterClearButtonStyled
                onClick={() => hook.hookSetSelectedProductCategory(PRODUCT_CATEGORY[0])}
                theme="text-only"
              >
                {BUTTON_LABEL.CLEAR}
              </Styled.FilterClearButtonStyled>
            </Styled.FilterStyled>

            <div>
              <Styled.FilterDownloadButtonStyled onClick={hook.hookGenerateCSV} theme="tertiary">
                <FontAwesomeIcon icon={faArrowDown} />
                {BUTTON_LABEL.DOWNLOAD}
              </Styled.FilterDownloadButtonStyled>
            </div>
          </Styled.FilterWrapperStyled>

          <Styled.TableStyled
            columns={tableColumns}
            footerData={hook.hookAccountBalanceTotals}
            data={hook.hookAccountBalances}
            isLoading={hook.hookIsTableLoading}
            onRowClick={hook.hookRowClick}
            onSort={hook.hookSetSortColumn}
            sortColumn={hook.hookSortColumn}
            setModalInfo={hook.hookRowClick}
            setContext={hook.hookNavigatePdf}
          />
          <Styled.PaginationStyled
            total={hook.hookNumberOfPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookSetCurrentPage}
          />
        </>
      )}

      {hook.hookIsLoading && !hook.hookNoLoading && (
        <Styled.LoadingWrapperStyled>
          <LoadingDots />
        </Styled.LoadingWrapperStyled>
      )}

      <Modal isOpen={hook.hookModalOpen}>
        <AccountBalanceModal
          merchantList={hook.hookSelectMerchantList}
          selectedMerchant={hook.hookSelectedMerchant}
          hookSetSeletedMerchantHandler={hook.hookSetSeletedMerchant}
          periodList={hook.hookPeriod}
          selectedPeriod={hook.hookSelectedPeriod}
          hookSetSelectedPeriod={hook.hookSetSelectedPeriod}
          cancelModal={hook.hookCancelModal}
          hookGetAccountBalances={hook.hookGetAccountBalances}
        />
      </Modal>
    </AppWrapper>
  );
};

export default AccountBalanceManagement;
