import styled from 'styled-components';

import { fonts, colors } from '../../../styles/theme';
import { toRem } from '../../../utils';
import { Button } from '../../Button';
import { PageTitle } from '../../PageTitle';

type ButtonStyledProps = {
  readonly width?: string;
};

type WrapperStyledProps = {
  readonly theme?: string;
  readonly width?: string;
};

export const WrapperStyled = styled.div<WrapperStyledProps>`
  width: ${({ width }) => width || 'fit-content'};
  padding: ${({ theme }) => (theme === 'secondary' ? toRem(24) : toRem(8))};
`;

export const PageTitleStyled = styled(PageTitle)`
  color: ${colors.color1};
  line-height: ${toRem(36)};
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  margin-left: ${toRem(16)};
  width: ${({ width }) => width || 'auto'};
`;

export const TextStyled = styled.p`
  color: ${colors.color1};
  margin: ${({ theme }) => (theme === 'secondary' ? `${toRem(8)} 0 ${toRem(24)} 0` : `${toRem(24)} 0`)};
  ${({ theme }) => (theme === 'secondary' ? `${fonts.lato.H16}` : `${fonts.lato.H14}`)};
  line-height: ${toRem(20)};
`;
