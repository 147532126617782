import { gql } from '@apollo/client';

export const INVITE_STATUS = gql`
  query company($companyId: ID!) {
    company(id: $companyId) {
      id
      invitesOnHold
      invitesOnHoldStartDate
    }
  }
`;
