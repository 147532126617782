import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Button, Checkbox } from '../../../../../../components';
import { PLACEHOLDER, INPUT_LABEL, BUTTON_LABEL, PUBLISHER_GROUP_DEFAULT } from '../../contracts';
import * as Styled from '../../styles';
import { MEMBERSHIP_STATUS_OPTIONS } from '../../../../../../utils';
import { SearchFilterOptions, SearchFilterType } from '../../types';

type BasicSearchFilterProps = {
  advancedFilterIsOpen: boolean;
  setAdvancedFilterIsOpen: (advancedFilterIsOpen: boolean) => void;
  searchBar: string;
  setSearchBar: (value: React.ChangeEvent<HTMLInputElement>) => void;
  publisherGroupOptions: SelectOption[];
  clearFormHandler: () => void;
  filter: SearchFilterType;
  setFilter: (name: SearchFilterOptions, value: SelectOption) => void;
  isLoading: boolean;
};

export const BasicSearchFilter = ({
  advancedFilterIsOpen,
  setAdvancedFilterIsOpen,
  searchBar,
  setSearchBar,
  publisherGroupOptions,
  isLoading,
  clearFormHandler,
  filter,
  setFilter,
}: BasicSearchFilterProps) => (
  <Styled.FiltersStyled>
    <Styled.InputTextStyled
      type="text"
      value={searchBar}
      onChange={setSearchBar}
      placeholder={PLACEHOLDER.SEARCH}
      faIcon={faSearch}
    />
    <Styled.SelectStyled
      placeholder={PLACEHOLDER.STATUS}
      name="membership"
      onChange={(value) => setFilter('membershipStatus', value)}
      selected={filter.membershipStatus}
      options={MEMBERSHIP_STATUS_OPTIONS}
      gridRow="2/3"
      isLoading={isLoading}
      isDisabled={isLoading}
    />
    <Styled.SelectStyled
      placeholder={PLACEHOLDER.GROUP}
      name="group"
      onChange={(value) => setFilter('publisherGroupIds', value)}
      selected={filter.publisherGroupIds}
      options={[PUBLISHER_GROUP_DEFAULT, ...publisherGroupOptions]}
      gridRow="2/3"
      isLoading={isLoading}
      isDisabled={isLoading}
    />

    <Styled.LeftSideStyled>
      <Checkbox label={INPUT_LABEL.SEARCH} checked={advancedFilterIsOpen} onChange={setAdvancedFilterIsOpen} />
    </Styled.LeftSideStyled>

    <Styled.RightSideStyled>
      <Button theme="text-only" onClick={clearFormHandler}>
        {BUTTON_LABEL.CLEAR}
      </Button>
    </Styled.RightSideStyled>
  </Styled.FiltersStyled>
);
