import { useCallback, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { CompanyType } from '../types';
import { GET_MERCHANT_COMPANIES } from '../graphql/queries';

export const useMerchantAccessExternal = (allMerchantAccessStartState?: boolean) => {
  const [allMerchantsAccess, setAllMerchantAccess] = useState<boolean | undefined>(allMerchantAccessStartState);
  const [merchantList, setMerchantList] = useState<CompanyType[]>([]);
  const [selectedMerchants, setSelectedMerchants] = useState<CompanyType[]>([]);
  const [checkedMerchants, setCheckedMerchants] = useState<CompanyType[]>([]);

  const { error, loading, data } = useQuery(GET_MERCHANT_COMPANIES, { fetchPolicy: 'no-cache' });

  const setAllMerchantAccessHandler = useCallback(
    (allMerchants: boolean) => {
      if (allMerchants === null || undefined) setAllMerchantAccess(true);
      else setAllMerchantAccess(allMerchants);
    },
    [setAllMerchantAccess]
  );

  const setCheckedMerchantHandler = useCallback(
    (companies: CompanyType[]) => {
      setCheckedMerchants(companies);
    },
    [setCheckedMerchants]
  );

  const setSelectedMerchantsHandler = useCallback(
    (companies: CompanyType[]) => {
      const sortedList = companies.sort((a, b) => a.companyName.localeCompare(b.companyName));
      setSelectedMerchants(sortedList);
    },
    [setSelectedMerchants, setCheckedMerchants]
  );

  const setMerchantListHandler = useCallback(
    (companies: CompanyType[]) => {
      setMerchantList(companies);
    },
    [setMerchantList]
  );

  useEffect(() => {
    if (!loading && !error && data && data.companies) {
      setMerchantList(data.companies.companies);
    }
  }, [data]);

  return {
    allMerchantsAccess,
    setAllMerchantAccessHandler,
    merchantList,
    checkedMerchants,
    selectedMerchants,
    setMerchantListHandler,
    setSelectedMerchantsHandler,
    setCheckedMerchantHandler,
  };
};
