import styled from 'styled-components';

import { Button } from '../../../../../components';
import { fonts, colors } from '../../../../../styles/theme';
import { toRem } from '../../../../../utils';

type DetailsStyledProps = {
  readonly bold?: boolean;
};

type RowStyledProps = {
  readonly merchant?: boolean;
};

export const WrapperStyled = styled.div`
  margin-top: 2rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
  white-space: nowrap;

  svg {
    margin-right: 0.5rem;
  }
`;

export const RowStyled = styled.div<RowStyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ merchant }) => (merchant ? '90%' : '95%')};
  margin-bottom: ${({ merchant }) => (merchant ? '0' : '3rem')};
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;

export const StepStyled = styled.div`
  margin-bottom: 2.5rem;
  ${fonts.lato.H16}
  color: ${colors.color1};

  h2 {
    margin-bottom: 1rem;
    ${fonts.gotham.H18}
  }

  p {
    line-height: 1.5;
  }

  strong {
    ${fonts.lato.H16B}
  }
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 1rem;
`;

export const DetailsStyled = styled.div<DetailsStyledProps>`
  margin-bottom: 1.5rem;
  ${({ bold }) => (bold ? fonts.lato.H16B : fonts.lato.H16)}
  color: ${colors.color5};
  line-height: 1.33rem;
`;

export const MerchantNameStyled = styled(DetailsStyled)<DetailsStyledProps>`
  ${({ bold }) => (bold ? fonts.lato.H24B : fonts.lato.H24)}
`;

export const ButtonsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const FooterButtonStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${toRem(40)};
`;
