import styled from 'styled-components';

import { fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type TextWrapperStyledProps = {
  readonly theme?: 'secondary';
};

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextWrapperStyled = styled.div<TextWrapperStyledProps>`
  margin-bottom: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return toRem(36);
      default:
        return toRem(20);
    }
  }};

  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return fonts.gotham.H22;
      default:
        return fonts.lato.H16;
    }
  }};
`;
