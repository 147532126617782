import { dateSetterFormatter } from '../../../../utils';
import { ADMIN_DATE_RANGE_INIT_RAW } from '../enums';

export type SummaryReportActionsProps = {
  showPlaceholder: boolean;
};

type SummaryReportDropdownProps = {
  dateRange: {
    startDate: any;
    endDate: any;
  };
  dateRangeCal: {
    startDate: any;
    endDate: any;
  };
  status: SelectOption;
  publisher: SelectOption;
  merchant: SelectOption;
  productCategory: SelectOption;
  product: SelectOption;
  records: SelectOption;
};

export const SUMMARY_REPORT_ACTIONS = {
  SHOW_PLACEHOLDER: Symbol('show placeholder'),
  HIDE_PLACEHOLDER: Symbol('hide placeholder'),
};

export const SUMMARY_REPORT_DROPDOWN_ACTIONS = {
  DEFINE_REPORT_DATE: Symbol('define report date'),
  CHANGE_LAST_CHECK: Symbol('change last check'),
  CHANGE_DATE_RANGE: Symbol('change date range'),
  CHANGE_DATE_RANGE_CAL: Symbol('change date range cal'),
  CHANGE_STATUS: Symbol('change status'),
  CHANGE_MERCHANT: Symbol('change merchant'),
  CHANGE_PUBLISHER: Symbol('change publisher'),
  CHANGE_PRODUCT_CATEGORY: Symbol('change product category'),
  CHANGE_PRODUCT: Symbol('change product'),
  CHANGE_RECORDS: Symbol('change records'),
  CLEAR_FORM: Symbol('clear form'),
};

export const summaryReportReducer = (state: any, { type }: Pick<BaseReducerType, 'type'>) => {
  switch (type) {
    case SUMMARY_REPORT_ACTIONS.HIDE_PLACEHOLDER:
      return {
        ...state,
        showPlaceholder: false,
      };
    case SUMMARY_REPORT_ACTIONS.SHOW_PLACEHOLDER:
      return {
        ...state,
        showPlaceholder: true,
      };
    default:
      return { ...state };
  }
};

export const initialState = {
  dateRange: {
    startDate: dateSetterFormatter(ADMIN_DATE_RANGE_INIT_RAW.startDate),
    endDate: dateSetterFormatter(ADMIN_DATE_RANGE_INIT_RAW.endDate),
  },
  dateRangeCal: { startDate: ADMIN_DATE_RANGE_INIT_RAW.startDate, endDate: ADMIN_DATE_RANGE_INIT_RAW.endDate },
  status: { label: 'All Statuses', value: 'All Statuses' },
  publisher: { label: 'All Publishers', value: '' },
  merchant: { label: 'All Merchants', value: '' },
  productCategory: { label: 'All Categories', value: '' },
  product: { label: 'All Products', value: 'All Products' },
  records: { label: '10', value: '10' },
};

export const summaryReportDropdownReducer = (
  state: any,
  { type, data }: BaseReducerType
): SummaryReportDropdownProps => {
  switch (type.toString()) {
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_DATE_RANGE.toString():
      return {
        ...state,
        dateRange: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_DATE_RANGE_CAL.toString():
      return {
        ...state,
        dateRangeCal: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_STATUS.toString():
      return {
        ...state,
        status: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PUBLISHER.toString():
      return {
        ...state,
        publisher: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_MERCHANT.toString():
      return {
        ...state,
        merchant: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT_CATEGORY.toString():
      return {
        ...state,
        productCategory: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_PRODUCT.toString():
      return {
        ...state,
        product: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CHANGE_RECORDS.toString():
      return {
        ...state,
        records: data,
      };
    case SUMMARY_REPORT_DROPDOWN_ACTIONS.CLEAR_FORM.toString():
      return {
        ...state,
        ...data,
      };
    default:
      return {
        ...state,
      };
  }
};
