import { useState } from 'react';

type ColumnsCB = {
  value: string;
  label: string;
};

export const useTableColumns = (columns: TableColumn[]) => {
  const [columnsState, setColumnsState] = useState(columns);

  const onDragEnd = (result: any, cb?: (columnsToReturn: TableColumn[]) => void) => {
    const { destination, source, draggableId } = result;
    const colId = draggableId.split('drag-')[1];

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newColumns = [...columnsState];
    newColumns.splice(source.index, 1);
    newColumns.splice(destination.index, 0, columnsState[colId]);

    setColumnsState(newColumns);

    const columnsToReturn: ColumnsCB[] = [];

    newColumns.forEach((column: TableColumn) => {
      columnsToReturn.push({ value: column.dataField, label: column.text });
    });

    if (cb) {
      cb(newColumns);
    }
  };

  return {
    hookColumns: columnsState,
    hookSetColumns: (newColumns: TableColumn[]) => {
      setColumnsState(newColumns);
    },
    hookOnDragEnd: onDragEnd,
  };
};
