import React from 'react';

import { AppWrapper, Button, ErrorBanner, PageTitle, Pagination, Table } from '../../../../components';
import { FintelCheckHistoryOptions, path } from '../../../../utils';
import { DefaultPropsType } from '../../../../types';

import { columns } from './contracts';
import { useHistory } from './hooks';
import { HISTORY } from './enums';
import * as Styled from './styles';

type FintelCheckHistoryProps = DefaultPropsType;

const FintelCheckHistory = ({ permissionsCodeList = [] }: FintelCheckHistoryProps) => {
  const hook = useHistory();

  return (
    <AppWrapper background permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
      <Styled.HeaderStyled>
        <Styled.HeaderLeftStyled>
          <PageTitle isLoading={hook.hookLoading}>{path.fintelCheckHistory.name}</PageTitle>
          <Styled.DescriptionStyled>{HISTORY.DESCRIPTION}</Styled.DescriptionStyled>
        </Styled.HeaderLeftStyled>

        <Button theme="denary" onClick={() => hook.hookNavigate(-1)}>
          {HISTORY.BACK}
        </Button>
      </Styled.HeaderStyled>

      <Styled.ReportWrapper>
        <Styled.SelectStyled
          label="Date Range"
          selected={hook.hookDateRange}
          options={FintelCheckHistoryOptions}
          onChange={hook.hookSetDateRange}
        />
        <Styled.TableWrapper>
          <Table
            isLoading={hook.hookLoading}
            columns={columns}
            data={hook.hookData}
            setContext={hook.hookSetContext}
            onSort={hook.hookSortColumnHandler}
            sortColumn={hook.hookSortColumn}
          />
          <Pagination
            currentPage={hook.hookCurrentPage}
            total={hook.hookTotalPages}
            onPageChange={hook.hookSetCurrentPage}
          />
        </Styled.TableWrapper>
      </Styled.ReportWrapper>
    </AppWrapper>
  );
};

export default FintelCheckHistory;
