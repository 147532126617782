import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';
import { Button } from '../Button';

type BreadCrumbItemProps = {
  readonly position: number;
};

type BreadCrumbLinkProps = {
  readonly disabled?: boolean;
};

export const WrapperStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BreadCrumbStyled = styled.div``;

export const BreadCrumbListStyled = styled.ul`
  display: flex;
`;

export const BreadCrumbItemStyled = styled.li<BreadCrumbItemProps>`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;

  ${({ position }) => {
    if (position > -1) {
      return `
        svg {
          margin-right: 0.5rem;
          font-size: 0.8rem;
        }
      `;
    }
    return '';
  }}
`;

export const BreadCrumbLinkStyled = styled(Link)<BreadCrumbLinkProps>`
  text-decoration: none;
  color: ${({ disabled }) => (disabled ? colors.color17 : colors.color12)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  ${fonts.lato.H14}
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${colors.color2};
  }
`;

export const MenuStyled = styled.div``;

export const MenuListStyled = styled.ul`
  display: flex;
  align-items: center;
`;

export const MenuItemStyled = styled.li`
  margin: 0 0.7rem;
`;

export const MenuNotificationtyled = styled.div``;

export const MenuLinkStyled = styled(Link)`
  text-decoration: none;
  color: ${colors.color1};
`;

export const LogoutStyled = styled(Button)`
  background: transparent;
  color: ${colors.color1};
  border: none;
  ${fonts.lato.H14}

  &:hover {
    background: transparent;
    border: none;
    color: ${colors.color2};
  }
`;
