import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InputText, Radio } from 'components';

import {
  BUTTON_LABEL,
  INFO_LABEL,
  PLACEHOLDER,
  SECTION_TITLES,
  TIRED_RATES,
  TIRED_RATE_OPTIONS,
  INPUT_LABEL,
  RATE_LEVEL_KEY,
  TOOLTIP_MESSAGES,
  DISCLAIMER,
} from '../../contracts';
import * as Styled from '../../styles';

type SystemUsageRateProps = {
  rateType: string;
  setRateType: (value: string) => void;
  currentSystemRate: string[];
  tierRateLevels: SystemTierRate[];
  setRateLevels: (value: string, field: string, id: number) => void;
  flatRateLevel: string;
  setFlatRateLevels: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addNewTier: () => void;
  removeNewTier: (id: number) => void;
  errors: { [key: string]: string | any };
  isReadOnly: boolean;
};

export const SystemUsageRate = ({
  rateType,
  setRateType,
  currentSystemRate,
  tierRateLevels,
  setRateLevels,
  flatRateLevel,
  setFlatRateLevels,
  addNewTier,
  removeNewTier,
  errors,
  isReadOnly,
}: SystemUsageRateProps): JSX.Element => (
  <Styled.SectionStyled>
    <Styled.SectionTitleStyled>{SECTION_TITLES.SYSTEM_RATE}</Styled.SectionTitleStyled>
    <Styled.SectionLabelStyled>{INFO_LABEL.RATE}</Styled.SectionLabelStyled>
    <Styled.SectionContentStyled>
      {currentSystemRate.map((rate) => (
        <p key={rate}>{rate}</p>
      ))}
    </Styled.SectionContentStyled>

    <Styled.SectionLabelWrapperStyled>
      <Styled.SectionLabelStyled>{INFO_LABEL.NEW_RATE}</Styled.SectionLabelStyled>
      <Styled.DisclaimerStyled>{DISCLAIMER}</Styled.DisclaimerStyled>
    </Styled.SectionLabelWrapperStyled>
    <Styled.SectionContentStyled>
      {TIRED_RATE_OPTIONS.map((option) => (
        <Styled.ChekcboxStyled key={option.value}>
          <Radio
            name={option.value}
            onChange={(value) => setRateType(`${value}`)}
            value={option.value}
            checked={rateType === option.value}
            label={option.label}
            disabled={isReadOnly}
          />
        </Styled.ChekcboxStyled>
      ))}
    </Styled.SectionContentStyled>

    {rateType === TIRED_RATES.TIER ? (
      <div>
        {tierRateLevels.map((level, index) => (
          <div key={level.id}>
            <Styled.InputContainerStyled>
              <Styled.InputWrapperStyled>
                <InputText
                  type="text"
                  label={INPUT_LABEL.COMMISSION.replace('###', `${index + 1}`)}
                  placeholder={PLACEHOLDER.COMMISSION}
                  onChange={(value) => setRateLevels(value.target.value, RATE_LEVEL_KEY.COMMISSION, level.id)}
                  value={level.newAdminTierCommission}
                  tooltip={TOOLTIP_MESSAGES.COMMISSION}
                  required
                  error={errors[level.id] ? errors[level.id][RATE_LEVEL_KEY.COMMISSION] : ''}
                  disabled={isReadOnly}
                />
              </Styled.InputWrapperStyled>

              <Styled.InputWrapperStyled>
                <InputText
                  type="text"
                  label={INPUT_LABEL.ADMIN_RATE.replace('###', `${index + 1}`)}
                  placeholder={PLACEHOLDER.COMMISSION}
                  onChange={(value) => setRateLevels(value.target.value, RATE_LEVEL_KEY.ADMIN_TIER, level.id)}
                  value={level.newAdminTierRate}
                  required
                  error={errors[level.id] ? errors[level.id][RATE_LEVEL_KEY.ADMIN_TIER] : ''}
                  disabled={isReadOnly}
                />
              </Styled.InputWrapperStyled>

              {index === tierRateLevels.length - 1 && (
                <Styled.InputWrapperStyled>
                  <InputText
                    type="text"
                    label={INPUT_LABEL.AFTER_RATE.replace('###', `${index + 1}`)}
                    placeholder={PLACEHOLDER.COMMISSION}
                    onChange={(value) => setRateLevels(value.target.value, RATE_LEVEL_KEY.AFTER_RATE, level.id)}
                    value={level.newAdminAfterTierRate}
                    required
                    error={errors[level.id] ? errors[level.id][RATE_LEVEL_KEY.AFTER_RATE] : ''}
                    disabled={isReadOnly}
                  />
                </Styled.InputWrapperStyled>
              )}
              {index > 0 && (
                <Styled.GridButtonStyled>
                  <Styled.ButtonStyled onClick={() => removeNewTier(level.id)} theme="septenary">
                    {BUTTON_LABEL.REMOVE}
                  </Styled.ButtonStyled>
                </Styled.GridButtonStyled>
              )}
            </Styled.InputContainerStyled>
          </div>
        ))}
        <Styled.ButtonStyled onClick={addNewTier} theme="tertiary" disabled={isReadOnly}>
          <FontAwesomeIcon icon={faPlus} />
          {BUTTON_LABEL.TIER}
        </Styled.ButtonStyled>
      </div>
    ) : (
      <Styled.InputContainerStyled theme="secondary">
        <InputText
          type="text"
          label={INPUT_LABEL.NEW_ADMIN_RATE}
          placeholder={PLACEHOLDER.COMMISSION}
          onChange={setFlatRateLevels}
          value={flatRateLevel}
          required
          error={errors[RATE_LEVEL_KEY.FLAT_RATE]}
          disabled={isReadOnly}
        />
      </Styled.InputContainerStyled>
    )}
  </Styled.SectionStyled>
);
