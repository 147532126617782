import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserInfo, useToast } from '../../../../../hooks';
import { MEMBERSHIPS } from '../graphql/queries';
import { paginator, path, PUBLISHER_PREFIX } from '../../../../../utils';
import {
  handleMarkets,
  formatMemberships,
  optionsForInvites,
  TOAST_MESSAGE,
  COMPARE,
  handleCategoriesList,
} from '../enum';
import { INVITE_STATUS } from '../graphql/queries/Company';
import { UPDATE_INVITE } from '../graphql/mutations/updateCompany';
import { renderStartDate } from '../components/timeFormatted';
import { Permission } from '../../../../../entities';

export const useMerchantInvitations = (permissionsCodeList: string[] = []) => {
  const { hookWhoAmI } = useUserInfo();
  const [selectedMarket, setSelectedMarket] = useState<SelectOption>({ label: 'All Markets', value: '' });
  const [selectedCategory, setSelectedCategory] = useState<SelectOption>({ label: 'All Categories', value: '' });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [modalMessage, setModalMessage] = useState({ name: '', subject: '', message: '' });

  const [errorMessage, setErrorMessage] = useState('');

  const [renderedFooter, setRenderedFooter] = useState<JSX.Element>();

  const [marketList, setMarketList] = useState<SelectOption[]>([]);
  const [categoryList, setCategoryList] = useState<SelectOption[]>([]);

  const [allMemberships, setAllMemberShips] = useState<any>([]);
  const [filteredMembers, setFilteredMembers] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);

  const [inviteSelected, setInviteSelected] = useState<SelectOption>();
  const [getMemberShips, { loading: membershipsLoading }] = useLazyQuery(MEMBERSHIPS);

  const [getInvites, { loading: invitesLoading }] = useLazyQuery(INVITE_STATUS);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>();

  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [updateInvite, { loading: updateInviteLoading }] = useMutation(UPDATE_INVITE);

  const hookToast = useToast();

  const navigate = useNavigate();

  const [programId, setProgramId] = useState('');

  const handleGetInvites = async () => {
    const { data } = await getInvites({
      variables: {
        companyId: hookWhoAmI.companyId?.toString(),
      },
      fetchPolicy: 'no-cache',
    });

    if (data.company !== undefined) {
      setInviteSelected(optionsForInvites[data.company.invitesOnHold]);
      setRenderedFooter(renderStartDate(data.company.invitesOnHoldStartDate));
    }
  };

  const termAndConditionsButton = () => {
    navigate(`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${programId}`);
    setIsOpen(false);
  };

  const handleConfirm = async (value: boolean) => {
    const { data } = await updateInvite({
      variables: {
        input: {
          invitesOnHold: value,
          invitesOnHoldStartDate: new Date(),
          id: hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data.upadteCompany === null) {
      setErrorMessage('Failed to update the invite status for this company');
    } else {
      handleGetInvites();
      if (value) hookToast.hookShowToast(TOAST_MESSAGE);
    }
    setInviteModalOpen(false);
  };

  const handleInviteModal = (value: any) => {
    if (value?.value) {
      setInviteModalOpen(true);
    } else {
      handleConfirm(false);
      setInviteSelected(value);
    }
  };

  const cancelInviteModal = () => {
    setInviteModalOpen(false);
  };

  const cancelModal = () => {
    setIsOpen(false);
  };

  const handleSort = (dataField: string, direction: any) => {
    const nextDirection: number = direction === 'desc' ? -1 : 1;
    const compare = (a: any, b: any) => {
      let compareCondition = false;
      switch (dataField) {
        case COMPARE.id: {
          compareCondition = Number(a.id) >= Number(b.id);
          break;
        }
        case COMPARE.invited: {
          compareCondition = new Date(b.invited) <= new Date(a.invited);
          break;
        }
        case COMPARE.publisherInvitation: {
          compareCondition = a.publisherInvitation !== null && b.publisherInvitation === null;
          break;
        }
        default: {
          compareCondition = a[dataField] === b[dataField] ? Number(a.id) >= Number(b.id) : a[dataField] > b[dataField];
          break;
        }
      }
      return compareCondition ? nextDirection : nextDirection * -1;
    };
    const copyArray = [...allMemberships];
    const sortedArray = copyArray.sort((a, b) => compare(a, b));
    setAllMemberShips(sortedArray);
    setTableData(paginator(sortedArray, 10, 1));
    setSortColumn({ column: dataField, direction: sortColumn?.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };
  const handleGetMemberShips = async () => {
    const { data } = await getMemberShips({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId?.toString(),
          onlyPublisherInvitation: true,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.memberships !== undefined) {
      const formatted = formatMemberships(data.memberships.memberships).filter(
        (obj: any) =>
          obj.status === 'Pending' &&
          (obj.statusLastUpdatedBy === 'Merchant' || (!obj.statusLastUpdatedBy && obj.publisherInvitation))
      );
      setMarketList(handleMarkets(formatted));
      setCategoryList(handleCategoriesList(formatted));
      setTotalPages(Math.ceil(data.memberships.count / 10));
      setAllMemberShips(formatted);
      setTableData(paginator(formatted, 10, 1));
      setCurrentPage(1);
    }
  };

  const setCurrentPageHandler = (chosenPage: number) => {
    setTableData(paginator(filteredMembers, 10, chosenPage));
    setCurrentPage(chosenPage);
  };

  const handleOpenModal = (value: any) => {
    setModalMessage({
      name: value.companyName,
      subject: value.publisherInvitation.subject,
      message: value.publisherInvitation.message,
    });
    setProgramId(value.programId);
    setIsOpen(true);
  };

  const setSelectedMarketHandler = (value: SelectOption) => {
    setSelectedMarket({
      label: value.label,
      value: value.value,
    });
    setCurrentPageHandler(1);
  };

  const setSelectedCategoryHandler = (value: SelectOption) => {
    setSelectedCategory({
      label: value.label,
      value: value.value,
    });
    setCurrentPageHandler(1);
  };

  const handleRowClick = (row: any) => {
    navigate(`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${row.programId}`);
  };

  const clearFormHandler = () => {
    setSelectedMarket({ label: 'All Markets', value: '' });
    setSelectedCategory({ label: 'All Categories', value: '' });
  };

  useEffect(() => {
    let filteredData = allMemberships;
    if (selectedCategory.label !== 'All Categories') {
      filteredData = filteredData.filter((obj: any) => obj.categories.includes(selectedCategory.label));
    }
    if (selectedMarket.label !== 'All Markets') {
      filteredData = filteredData.filter((obj: any) => obj.markets.includes(selectedMarket.label));
    }

    setFilteredMembers(filteredData);
    setTableData(paginator(filteredData, 10, 1));
    setCurrentPage(1);
  }, [selectedCategory, selectedMarket]);

  useEffect(() => {
    handleGetMemberShips();
    handleGetInvites();
  }, []);

  return {
    hookPageLoading: membershipsLoading,
    hookTotalData: allMemberships,

    hookTargetMarket: selectedMarket,
    hookSetTargetMarket: setSelectedMarketHandler,

    hookProductCategory: selectedCategory,
    hookSetProductCategory: setSelectedCategoryHandler,

    hookTableData: tableData,

    hookClearForm: clearFormHandler,

    hookAudienceMarkets: marketList,
    hookCategoryList: categoryList,

    hookIsOpen: isOpen,
    hookSaveHandler: handleConfirm,
    hookHandleOpenModal: handleOpenModal,

    hookCancelInviteModal: cancelInviteModal,

    hookConfirmChange: handleConfirm,

    hookMessage: modalMessage,

    hookCancelModal: cancelModal,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSetCurrentPage: setCurrentPageHandler,

    hookSort: sortColumn,
    hookOnSort: handleSort,
    hookRowClick: handleRowClick,

    hookInviteModal: inviteModalOpen,
    hookSetInviteModal: handleInviteModal,

    hookInvited: inviteSelected,
    hookRenderedFooter: renderedFooter,

    hookInvitesLoading: invitesLoading,

    hookInviteChangeLoading: updateInviteLoading,

    hookErroMessage: errorMessage,

    hookTermsAndConditions: termAndConditionsButton,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
