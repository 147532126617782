import { gql } from '@apollo/client';

export const GET_MEMBERSHIPS = gql`
  query memberships($input: MembershipSearchInput!) {
    memberships(input: $input) {
      memberships {
        publisher {
          id
          companyName
        }
      }
    }
  }
`;
