import React from 'react';

import * as Styled from '../styles';
import { CellProps } from '../types';

type DefaultCellProps = CellProps & {
  id?: boolean;
};

export const DefaultCell = ({ val, id = false }: DefaultCellProps) => (
  <Styled.CellWrapper isId={id}>{val}</Styled.CellWrapper>
);
