import styled from 'styled-components';

import { toRem } from '../../../../../utils';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${toRem(40)};
  align-items: flex-start;
`;

export const ButtonsWrapperStyled = styled.span`
  width: fit-content;
  display: flex;
  gap: ${toRem(20)};
  margin-bottom: ${toRem(16)};
`;

export const RightStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CheckBoxWrappperStyled = styled.div`
  display: flex;
  width: fit-content;
`;
