import React, { useMemo } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  AppWrapper,
  Button,
  Calendar,
  ErrorBanner,
  PageTitle,
  Pagination,
  RecordsDropdown,
  Select,
  Table,
} from 'components';
import { InputCalendar } from 'components/Input/Calendar';
import { DefaultPropsType } from 'types';
import { path } from 'utils';

import { usePostbackDetails } from './hooks/usePostbackDetails';
import DeleteRuleModal from './Modal/DeleteRuleModal';
import PostbackRuleModal from './Modal/PostbackRuleModal';
import { buildTableColumns } from './contracts/columns';
import * as Styled from './styles';

const PostbackDetails = ({
  companyType,
  targetType,
  permissionsCodeList = [],
}: { companyType: string; targetType: string } & DefaultPropsType): JSX.Element => {
  const postbackHook = usePostbackDetails(companyType, targetType, permissionsCodeList);
  const [isReadonly] = postbackHook.readOnlyPermissionsList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={postbackHook.errorMessage} isOpen={!!postbackHook.errorMessage} />
      <Styled.HeaderStyled>
        <PageTitle>{path.postbackDetails.name}</PageTitle>

        <Styled.HeaderButtonAreaStyled>
          <Styled.ButtonStyled onClick={() => postbackHook.handleAdd()} disabled={isReadonly}>
            <Styled.IconStyled icon={faPlus} />
            Add Postback Rule
          </Styled.ButtonStyled>
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>

      <Styled.FiltersStyled>
        <Select
          label="TRANSACTION EVENT"
          name="eventType"
          options={postbackHook.filters.eventType}
          selected={postbackHook.selectedFilters.eventType}
          onChange={(option) => {
            postbackHook.onChangeHandler('eventType', option);
          }}
          isDisabled={postbackHook.isLoading}
          width="100%"
        />
        <Select
          label={`${targetType} ID - Name`}
          name="companyOptions"
          options={postbackHook.filters.companyOptions}
          selected={postbackHook.selectedFilters.companyOptions}
          onChange={(option) => {
            postbackHook.onChangeHandler('companyOptions', option);
          }}
          isDisabled={postbackHook.isLoading || postbackHook.membershipsLoading}
          isLoading={postbackHook.isLoading || postbackHook.membershipsLoading}
          width="100%"
        />
        <InputCalendar
          label="Date Range"
          value={postbackHook.selectedDateRange()}
          onClick={() => postbackHook.setIsCalendarOpen(!postbackHook.isCalendarOpen)}
          isDisabled={postbackHook.isLoading}
          width="100%"
        />
      </Styled.FiltersStyled>
      <Styled.SubFiltersStyled>
        <RecordsDropdown
          onChange={postbackHook.setRecordsPerPageHandler}
          selected={postbackHook.selectedRecordsPerPage}
          options={postbackHook.recordsPerPage}
          isDisabled={postbackHook.isLoading}
          currentPage={postbackHook.currentPage}
          totalRecords={postbackHook.totalRecords()}
        />
        <Button theme="text-only" onClick={postbackHook.clearForm}>
          Clear Form
        </Button>
      </Styled.SubFiltersStyled>
      <Table
        columns={buildTableColumns(postbackHook.handleEdit, postbackHook.handleDelete, targetType, isReadonly)}
        data={postbackHook.filteredPostbacks}
        cellAlignItems="flex-start"
        isLoading={postbackHook.isLoading}
        onSort={postbackHook.onSortHandler}
        sortColumn={postbackHook.sortColumn()}
        loadWithData={postbackHook.filteredPostbacks.length > 0}
      />
      <Pagination
        total={postbackHook.totalPages}
        currentPage={postbackHook.currentPage}
        onPageChange={postbackHook.setCurrentPageHandler}
      />
      {useMemo(
        () => (
          <Calendar
            date={postbackHook.selectedFilters.createdAt.start}
            endDate={postbackHook.selectedFilters.createdAt.end}
            onApply={postbackHook.onChangeDateHandler}
            onCancel={() => postbackHook.setIsCalendarOpen(false)}
            isOpen={postbackHook.isCalendarOpen}
            hasRange
          />
        ),
        [
          postbackHook.selectedFilters.createdAt.start,
          postbackHook.selectedFilters.createdAt.end,
          postbackHook.isCalendarOpen,
        ]
      )}
      <PostbackRuleModal
        hook={postbackHook}
        modalType="Add"
        targetType={targetType}
        isModalOpen={postbackHook.isAddModalOpen}
        closeModal={() => postbackHook.setIsAddModalOpen(false)}
      />
      <PostbackRuleModal
        hook={postbackHook}
        modalType="Edit"
        targetType={targetType}
        isModalOpen={postbackHook.isEditModalOpen}
        closeModal={() => postbackHook.setIsEditModalOpen(false)}
      />
      <DeleteRuleModal hook={postbackHook} />
    </AppWrapper>
  );
};

export default PostbackDetails;
