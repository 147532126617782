import { Totals } from '../types';

const calcEpc = (totalClicks: number, totalCommissions: number) => {
  const total = totalClicks && totalCommissions ? totalCommissions / totalClicks : 0;
  return total;
};

export const processTableData = (data: any) => {
  const tempArr: any[] = [];
  /**  CAD === 0 USD === 1 for array position of totals */
  const columnTotals: Totals = {
    impressions: [0, 0],
    uniqueImpressions: [0, 0],
    clicks: [0, 0],
    uniqueClicks: [0, 0],
    epc: [0, 0],
    oldPendingTransactions: [0, 0],
    oldApprovedTransactions: [0, 0],
    approvedTransactions: [0, 0],
    totalTransactions: [0, 0],
    pendingGrossValue: [0, 0],
    totalGrossValue: [0, 0],
    totalNetValue: [0, 0],
    commissionCpa: [0, 0],
    commission: [0, 0],
    totalCommission: [0, 0],
    newPendingTransactions: [0, 0],
    declinedTransactions: [0, 0],
    softDeclinedTransactions: [0, 0],
    verifiedTransactions: [0, 0],
  };
  data.forEach((item: any) => {
    const find = tempArr.find((finder: any) => finder.id === item.id);

    const structure = {
      ...item,
      clicks: Number(item.clicks),
      impressions: Number(item.impressions),
      ctr: Number(item.CTR),
      uniqueClicks: Number(item.uniqueClicks),
      uniqueImpressions: Number(item.uniqueImpressions),
      approvedTransactions: Number(item.approvedTransactions),
      declinedTransactions: Number(item.declinedTransactions),
      pendingTransactions: Number(item.pendingTransactions),
      totalCommissionsAmount: Number(item.totalCommissionsAmount),
      commissionCpa: Number(item.CPACommission),
      commission: Number(item.revShareCommission),
      totalCommission: Number(item.totalCommissionsAmount),
      totalTransactions: Number(item.totalTransactions),
      verifiedTransactions: Number(item.verifyTransactions),
      oldApprovedTransactions: Number(item.oldApprovedTransactions),
      oldPendingTransactions: Number(item.oldPendingTransactions),
      pendingGrossValue: Number(item.pendingGrossValue),
      totalGrossValue: Number(item.totalGrossValue),
      totalNetValue: Number(item.totalNetValue),
      softDeclinedTransactions: Number(item.softDeclinedTransactions),
      newPendingTransactions: Number(item.newPendingTransactions),
      conversions: Number(item.conversions),
      approvalRate: Number(item.approvalRate),
      epc: Number(item.EPC),
      // For Group By Publisher
      publisherId: Number(item?.publisher?.id),
      publisherName: item?.publisher?.companyName || '',
      publisherUrl: item?.publisher?.trackings[0]?.primaryWebsite || '',
      // For Group By Merchant
      merchantId: Number(item?.merchant?.id),
      merchantName: item?.merchant?.companyName || '',
      merchantUrl: item?.merchant?.companyUrl || '',
      // For Group By Tracking Profile
      trackingProfileId: Number(item?.trackingProfile?.id),
      // For Group By Month
      month: item?.date || '',
      // For Group By Product
      productId: item?.product?.customizedProductId || '',
      productName: item?.product?.name || '',
      productCategory: item?.product?.productCategory || item?.productCategory || '', // Group by productCategory uses this field too
      // For Group by Ad Campaign
      campaignId: Number.isNaN(Number(item?.campaign?.id)) ? 0 : Number(item?.campaign?.id),
      campaignName: item?.campaign?.name || '',
    };

    if (!find) {
      tempArr.push(structure);
    } else {
      find.clicks += structure.clicks;
      find.impressions += structure.impressions;
      find.uniqueClicks += structure.uniqueClicks;
      find.uniqueImpressions += structure.uniqueImpressions;
      find.approvedTransactions += structure.approvedTransactions;
      find.declinedTransactions += structure.declinedTransactions;
      find.pendingTransactions += structure.pendingTransactions;
      find.commissionCpa += structure.commissionCpa;
      find.commission += structure.commission;
      find.totalCommission += structure.totalCommission;
      find.totalTransactions += structure.totalTransactions;
      find.verifiedTransactions += structure.verifiedTransactions;
      find.oldApprovedTransactions += structure.oldApprovedTransactions;
      find.oldPendingTransactions += structure.oldPendingTransactions;
      find.pendingGrossValue += structure.pendingGrossValue;
      find.totalGrossValue += structure.totalGrossValue;
      find.totalNetValue += structure.totalNetValue;
      find.softDeclinedTransactions += structure.softDeclinedTransactions;
      find.newPendingTransactions += structure.newPendingTransactions;
      find.conversions += structure.conversions;
      find.ctr += structure.ctr;
      find.approvalRate += structure.approvalRate;
      find.epc += structure.epc;
    }

    // CAD === 0 USD === 1 for array position of totals
    const currencyIndex = (() => {
      switch (item.currency) {
        case 'CAD':
          return 0;
        case 'USD':
          return 1;
        default:
          return -1;
      }
    })();
    columnTotals.impressions[currencyIndex] += Number(item?.impressions || 0);
    columnTotals.uniqueImpressions[currencyIndex] += Number(item?.uniqueImpressions || 0);
    columnTotals.clicks[currencyIndex] += Number(item?.clicks || 0);
    columnTotals.uniqueClicks[currencyIndex] += Number(item?.uniqueClicks || 0);
    columnTotals.oldPendingTransactions[currencyIndex] += Number(item?.oldPendingTransactions || 0);
    columnTotals.oldApprovedTransactions[currencyIndex] += Number(item?.oldApprovedTransactions || 0) || 0;
    columnTotals.newPendingTransactions[currencyIndex] += Number(item?.newPendingTransactions || 0);
    columnTotals.declinedTransactions[currencyIndex] += Number(item?.declinedTransactions || 0);
    columnTotals.softDeclinedTransactions[currencyIndex] += Number(item?.softDeclinedTransactions || 0);
    columnTotals.verifiedTransactions[currencyIndex] += Number(item?.verifyTransactions || 0);
    columnTotals.approvedTransactions[currencyIndex] += Number(item?.approvedTransactions || 0);
    columnTotals.totalTransactions[currencyIndex] += Number(item?.totalTransactions || 0);
    columnTotals.pendingGrossValue[currencyIndex] += Number(item?.pendingGrossValue || 0);
    columnTotals.totalGrossValue[currencyIndex] += Number(item?.totalGrossValue || 0);
    columnTotals.totalNetValue[currencyIndex] += Number(item?.totalNetValue || 0);
    columnTotals.commissionCpa[currencyIndex] += Number(item?.CPACommission || 0);
    columnTotals.commission[currencyIndex] += Number(item?.revShareCommission || 0);
    columnTotals.totalCommission[currencyIndex] += Number(item?.totalCommissionsAmount || 0);
  });
  columnTotals.epc[0] = calcEpc(columnTotals.clicks[0], columnTotals.totalCommission[0]);
  columnTotals.epc[1] = calcEpc(columnTotals.clicks[1], columnTotals.totalCommission[1]);
  return { processedData: tempArr, processedTotals: columnTotals };
};
