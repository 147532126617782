import { gql } from '@apollo/client';

export const GET_MANUAL_URL_LIST = gql`
  query getAllManualUrls($input: ManualUrlsListInput!) {
    getAllManualUrls(input: $input) {
      manualUrlsList {
        id
        url
        category
        checkType
      }
    }
  }
`;
