import styled from 'styled-components';

import { fonts } from '../../styles/theme';
import { toRem } from '../../utils';
import { Select } from '..';

export const SelectStyled = styled(Select)`
  margin: 0 1rem;
`;

export const PublisherSelectStyled = styled.div`
  width: ${toRem(290)};
  margin-left: ${toRem(30)};
`;

export const OptionsRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.5rem;
`;

export const WrapperStyled = styled.div`
  width: 100%;
  margin-top: ${toRem(30)};
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14};
  margin-right: 128px;
`;

export const RightStyled = styled.div`
  display: flex;
  align-items: center;
  width: 320px;
`;

export const PlaceholderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;
