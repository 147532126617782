import { LMRRow } from '../graphql/queries';

export const processDataToCSVString = (allTableData: any[], allReportColumns: TableColumn[]) => {
  // Create the Row of Column Titles
  const headerRow = allReportColumns.map((column) => column.text).toString();

  // Create the Data Rows
  const newData = allTableData.map((row) => {
    const rowArray = allReportColumns.map((column) => {
      if (!column?.dataField || row[column?.dataField] == null) return 'N/A';
      if (!['"', '\r', '\n', ','].some((e) => row[column.dataField].toString().indexOf(e) !== -1)) {
        if (typeof row[column.dataField] === 'number') return +row[column.dataField].toFixed(2);
        return row[column.dataField];
      }
      return `"${row[column.dataField].toString().replace(/"/g, '""')}"`;
    });
    return rowArray.toString();
  });

  return [headerRow, ...newData].join('\n');
};

// Copied from linkManager.module.js from fintel-app
export const formatDataForCSV = (trackingEventReportItems: LMRRow[]) =>
  trackingEventReportItems.map((trackingEventReportItem) => ({
    trackingProfileId: trackingEventReportItem.trackingProfileId
      ? `${trackingEventReportItem.trackingProfileId} - ${trackingEventReportItem.trackingProfileUrl}`
      : '',
    referralUrl: trackingEventReportItem.referralUrl || '',
    pageType: trackingEventReportItem.pageType,
    adId: trackingEventReportItem.adId || '',
    productName: trackingEventReportItem.customizedProductId
      ? `${trackingEventReportItem.customizedProductId} - ${trackingEventReportItem.productName}`
      : '',
    impressions: trackingEventReportItem.impressions || 0,
    uniqueImpressions: trackingEventReportItem.uniqueImpressions || 0,
    clicks: trackingEventReportItem.clicks || 0,
    uniqueClicks: trackingEventReportItem.uniqueClicks || 0,
    merchantId: trackingEventReportItem.merchantId
      ? `${trackingEventReportItem.merchantId} - ${trackingEventReportItem.merchantName}`
      : '',
    publisherId: trackingEventReportItem.publisherId
      ? `${trackingEventReportItem.publisherId} - ${trackingEventReportItem.publisherName}`
      : '',
    RPI: trackingEventReportItem.RPI || 0,
    CTR: trackingEventReportItem.CTR || 0,
    totalTransactions: trackingEventReportItem.totalTransactions || 0,
    approvedTransactions: trackingEventReportItem.approvedTransactions || 0,
    totalCommissions: trackingEventReportItem.totalCommissions || 0,
  }));
