import { gql } from '@apollo/client';

export const GET_POSTBACKS = gql`
  query postbacks($input: PostbackInput) {
    postbacks(input: $input) {
      postbacks {
        id
        companyId
        targetId
        target {
          companyName
        }
        targetType
        eventType
        url
        queryString
        disabled
        createdAt
      }
    }
  }
`;

export type GetPostbacksInputType = {
  input: {
    id?: string;
    companyId?: string;
    disabled?: boolean;
  };
};

export type GetPostbacksOutputType = {
  postbacks: {
    postbacks: {
      id: string;
      companyId: string;
      targetId?: string | null;
      target?: {
        companyName: string;
      } | null;
      targetType: string;
      eventType: string;
      url: string;
      queryString: string;
      disabled: boolean;
      createdAt: string;
    }[];
  };
};
