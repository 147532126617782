import React from 'react';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppWrapper, PageTitle, Pagination, ReportPlaceholder } from '../../../components';
import { Table } from '../../../components/Table';
import { path } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import { useReportDetails } from './hooks/useReportDetails';
import { columns } from './contracts/columns';
import { DETAILED_RECORDS } from './enums';
import * as Styled from './styles';

type PaymentReportDetailsProps = DefaultPropsType;

const PaymentReportDetails = ({ permissionsCodeList = [] }: PaymentReportDetailsProps) => {
  const hook = useReportDetails();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <div style={{ float: 'left', width: '48%', marginTop: '0' }}>
          <PageTitle isLoading={hook.loading} loadingText={DETAILED_RECORDS.LOADING_TEXT}>
            {path.paymentReportDetails.name}
          </PageTitle>
          <h2 style={{ marginTop: '10px', fontSize: '120%' }}>{`Publisher: ${hook.currentPublisher || ''}`}</h2>
        </div>
        <Styled.ButtonStyled theme={DETAILED_RECORDS.BUTTON_THEME} onClick={hook.hookHandleBack}>
          {DETAILED_RECORDS.BACK}
        </Styled.ButtonStyled>
      </Styled.HeaderStyled>
      <Styled.InfoBox>
        <div>
          In this report, all monetary amounts (including commissions, bonuses, taxes, rollovers) are displayed in the
          Payment Currency, which is the currency the affiliate chooses to receive their payments in. This can be
          different from the currency merchants have chosen to run their program in. Exchange rates used for the
          conversion, where appropriate, are display in this report and any fluctuation to the actual payment date is at
          the publisher/affiliate’s own cost.{' '}
        </div>

        <div>
          For clarity, the Merchant Currency column is the original currency that commissions, bonuses and taxes were
          calculated in, and will match other reports generated in this platform and the monthly invoices issue to the
          merchants.{' '}
        </div>
      </Styled.InfoBox>
      <Styled.ButtonWrapperStyled>
        <Styled.ButtonStyled theme={DETAILED_RECORDS.BUTTON_THEME} onClick={hook.hookDownloadCSV}>
          <FontAwesomeIcon icon={faFileDownload} />
          {DETAILED_RECORDS.DONWLOAD}
        </Styled.ButtonStyled>
      </Styled.ButtonWrapperStyled>
      {hook.hookTableData.length > 0 && (
        <Table
          columns={columns(hook.hookGoToPdfPage, hook.hookHandleRightArrow, hook.month, hook.year)}
          data={hook.hookTableData}
          sortColumn={hook.hookSort}
          onSort={hook.hookOnSort}
          footerData={hook.hookFooterData}
          onRowClick={hook.hookHandleRightArrow}
        />
      )}
      {hook.hookDataLoad && (
        <Pagination
          currentPage={hook.hookCurrentPage}
          total={hook.hookTotalPage}
          onPageChange={hook.hookHandleChangePage}
        />
      )}
      {hook.hookDataLoad && hook.hookTableData.length === 0 && (
        <ReportPlaceholder submitted text={DETAILED_RECORDS.NO_RESULTS} subtext={DETAILED_RECORDS.RUN_ESTIMATE} />
      )}
    </AppWrapper>
  );
};

export default PaymentReportDetails;
