import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { PageTitle } from '../../../../../components';
import { TRACKING_PROFILE_TYPE } from '../../../../../utils/enums';

import * as Styled from './styles';
import { ADD_TRACKING_PROFILE } from './enums';

type AddProfileTabProps = {
  hook: any;
};

const AddProfileTab = ({ hook }: AddProfileTabProps) => (
  <Styled.WrapperStyled>
    <Styled.TopStyled>
      <PageTitle>{hook.hookModalTitle}</PageTitle>

      <Styled.CloseButtonStyled theme="secondary" onClick={hook.hookResetModal}>
        <FontAwesomeIcon icon={faTimes} />
      </Styled.CloseButtonStyled>
    </Styled.TopStyled>

    <Styled.InputTextStyled
      label={ADD_TRACKING_PROFILE.PROFILE_NAME.LABEL}
      placeholder={ADD_TRACKING_PROFILE.PROFILE_NAME.PLACEHOLDER}
      value={hook.hookProfileName}
      onChange={hook.hookSetProfileName}
      error={hook.hookNameError ? ADD_TRACKING_PROFILE.ERRORS.NAME : ''}
      required
      type="text"
    />

    <Styled.SelectStyled
      label={ADD_TRACKING_PROFILE.PROFILE_TYPE.LABEL}
      required
      name="tracking"
      options={TRACKING_PROFILE_TYPE}
      selected={hook.hookProfileType}
      onChange={hook.hookSetProfileType}
      error={hook.hookTypeError ? ADD_TRACKING_PROFILE.ERRORS.TYPE : ''}
      placeholder={ADD_TRACKING_PROFILE.PROFILE_TYPE.PLACEHOLDER}
    />

    {hook.hookProfileType?.value === 'website' && (
      <Styled.InputTextStyled
        label={ADD_TRACKING_PROFILE.PROFILE_URL.LABEL}
        required
        value={hook.hookProfileUrl}
        onChange={hook.hookSetProfileUrl}
        type="text"
        placeholder={ADD_TRACKING_PROFILE.PROFILE_URL.PLACEHOLDER}
        error={hook.hookUrlError ? hook.hookUrlError : ''}
      />
    )}

    {hook.hookProfileType?.value === 'other' && (
      <Styled.InputTextStyled
        label={ADD_TRACKING_PROFILE.PROFILE_DESCRIPTION.LABEL}
        value={hook.hookProfileDescription}
        onChange={hook.hookSetProfileDescription}
        type="text"
        placeholder={ADD_TRACKING_PROFILE.PROFILE_DESCRIPTION.PLACEHOLDER}
      />
    )}

    <Styled.FooterStyled>
      <Styled.ButtonStyled theme="secondary" onClick={hook.hookResetModal}>
        {ADD_TRACKING_PROFILE.BUTTONS.CANCEL}
      </Styled.ButtonStyled>

      <Styled.ButtonStyled
        loading={hook.hookLoadingCreate || hook.hookLoadingUpdate}
        onClick={() => {
          hook.hookCreateOrUpdateTrackingProfile();
        }}
      >
        {ADD_TRACKING_PROFILE.BUTTONS.SAVE}
      </Styled.ButtonStyled>
    </Styled.FooterStyled>
  </Styled.WrapperStyled>
);

export default AddProfileTab;
