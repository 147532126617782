import React from 'react';

import { CampaignCell, DefaultCell, IconCell } from './Components';

export const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'id',
    width: '8%',
    formatter: (val) => <DefaultCell id val={val} />,
  },
  {
    dataField: 'campaign',
    text: 'Campaign/Definition',
    width: 'calc(92% - 300px)',
    formatter: (val) => <CampaignCell val={val} />,
  },
  {
    dataField: 'banner',
    text: 'banner',
    width: '70px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'text',
    text: 'text',
    width: '50px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'html',
    text: 'html 5',
    width: '60px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'tracking',
    text: 'tracking',
    width: '70px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'detail',
    text: '',
    width: '50px',
    formatter: (val) => <IconCell val={val} />,
  },
];
