import React from 'react';

import { Pagination, Table } from '../../../../../../components';
import * as TabStyled from '../styles';

import { useCommissions } from './hook';
import { columns } from './contracts';

const CommissionsTab = () => {
  const hook = useCommissions();

  return (
    <TabStyled.WrapperStyled>
      <Table columns={columns} data={hook.hookData} isLoading={hook.hookLoading} />
      {!hook.hookLoading && (
        <Pagination
          currentPage={hook.hookData.length > 0 ? hook.hookCurrentPage : 0}
          total={hook.hookTotalPages}
          onPageChange={hook.hookSetCurrentPage}
        />
      )}
    </TabStyled.WrapperStyled>
  );
};

export default CommissionsTab;
