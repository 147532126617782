export const PRODUCT_FEED = {
  CLEAR_FORM: 'Clear Form',
  URL_FILE_TYPE: 'url file type',
  XML: 'xml',
  GENERATE_URL: 'Generate URL',
  COPY_URL: 'Copy URL',
  ACTIVE_PRODUCT: 'Active Product Feeds',
  INFO_BOX: {
    TITLE: 'Use of the Product Feed',
    DESCRIPTION: 'Publishers are restricted to no more than 1000 number of calls per hour.',
    DESCRIPTION2:
      'Publishers are required to regularly call product feeds to ensure they are displaying the most up to date information for a product.',
  },
  TRACKING_PROFILE_ID: 'tracking profile id',
  MARKET_COUNTRY: 'market country',
  STATE_PROVINCE: 'state/province',
  MERCHANT: 'merchant',
  PRODUCT_ID: 'product id',
  PRODUCT_CATEGORY: 'product category',
  PRODUCT_SUBCATEGORY: 'product subcategory',
  PRODUCT_FEED_URL: 'product feed url',
  JSON: 'json',
  PLACEHOLDERS: {
    MARKET: 'Default: International',
    STATE: 'Default: All Areas',
    MERCHANT: 'Default: All Merchant',
    PRODUCT_ID: 'Default: All products',
    PRODUCT_CATEGORY: 'Default: All Categories',
    PRODUCT_SUBCATEGORY: 'Default: All subcategories',
  },
  TRACKING_PROFILE_ERROR: 'Oops! Something went wrong, Please select at least one option other than tracking profile',
  AT_LEAST_ONE: 'Oops! Something went wrong, Please select at least one option other than tracking profile',
};
