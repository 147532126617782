import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      companyImgUrl
      companyName
      companyType
      createdAt
      activityStatus
      accountStatus
      companyEmail

      overview {
        businessDesc
        profilePhotoURL
        primaryPlatformLink
        hasWebsite
        audienceMarkets
        avgMonthlyAudience
        productCategories
        promotionMethods
        audienceAges
        gender
        creditScoreRating
        avgIncomes
      }
      program {
        id
      }
    }
  }
`;
