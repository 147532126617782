import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_DETAILS_DROPDOWNS = gql`
  query FintelCheckDetailsDropdowns($input: FintelCheckSummaryInput!) {
    fintelCheckDetailsDropdowns(input: $input) {
      productFilterOptions {
        label
        value
      }
      ruleStatusOptions {
        label
        value
      }
      productCategoriesOptions {
        label
        value
      }
    }
  }
`;

export type GetFintelCheckDetailsDropdownsInput = {
  input: {
    merchantId: string;
    publisherId: string;
    publisherName?: string;
    checkDate: string;
  };
};

export type GetFintelCheckDetailsDropdownsOutput = {
  fintelCheckDetailsDropdowns: {
    productFilterOptions: SelectOption[] | null;
    ruleStatusOptions: SelectOption[] | null;
    productCategoriesOptions: SelectOption[] | null;
  };
};
