import React from 'react';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import { colors } from 'styles/theme';

import { LinkCard } from '../components/LinkCard';
import { Card } from '../components';
import { CardProps } from '../components/Card/types';

export const useRenderCards = () => {
  const displayCard = (show: boolean, title: string, link: string, outsideLink = false): JSX.Element | null => {
    if (show) {
      return (
        <LinkCard
          title={title}
          color={colors.color2}
          link={link}
          icon={faArrowUpRightFromSquare}
          outsideLink={outsideLink}
        />
      );
    }
    return null;
  };

  const displayUpdateProfile = (show: boolean, data: CardProps): JSX.Element | null => {
    if (show) {
      return (
        <Card
          title={data.title}
          tooltip={data.tooltip}
          description={data.description}
          button={data.button}
          link={data.link}
        />
      );
    }
    return null;
  };

  return {
    hookDisplayCard: displayCard,
    hookDisplayUpdateCard: displayUpdateProfile,
  };
};
