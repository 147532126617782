import React from 'react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../../../../../../components';
import { TRANSACTION_SETTINGS } from '../enums';
import { useAddTrackingSettings } from '../../../hooks/useAddTrackingSettings';

import * as Styled from './styles';
import { NEW_TRANSACTION_SETTING } from './enums';

type AddTrackingSettingsProps = {
  setModalOpen: () => void;
  tableData: any[];
  setTableData: (data: any[]) => void;
};

const AddTrackingSettings = ({ setModalOpen, tableData, setTableData }: AddTrackingSettingsProps) => {
  const hook = useAddTrackingSettings({ tableData, setTableData, setModalOpen });

  return (
    <Styled.WrapperStyled>
      {hook.hookError && (
        <Styled.ErrorStyled>
          <Styled.IconStyled icon={faTriangleExclamation} />
          {NEW_TRANSACTION_SETTING.ERROR}
        </Styled.ErrorStyled>
      )}
      <Styled.PageTitleStyled>{NEW_TRANSACTION_SETTING.TITLE}</Styled.PageTitleStyled>
      <Styled.SelectStyled
        label={NEW_TRANSACTION_SETTING.INTEGRATION.LABEL}
        name="currency"
        placeholder={NEW_TRANSACTION_SETTING.INTEGRATION.PLACEHOLDER}
        selected={hook.hookType}
        options={TRANSACTION_SETTINGS.TYPES}
        onChange={hook.hookSetType}
        error={hook.hookTypeError ? NEW_TRANSACTION_SETTING.INTEGRATION.ERROR : ''}
        required
      />
      <Styled.SelectStyled
        label={NEW_TRANSACTION_SETTING.TRACKING.LABEL}
        name="settings"
        placeholder={NEW_TRANSACTION_SETTING.TRACKING.PLACEHOLDER}
        selected={hook.hookTracking}
        options={TRANSACTION_SETTINGS.URLS}
        onChange={hook.hookSetTracking}
        error={hook.hookTrackingError ? NEW_TRANSACTION_SETTING.TRACKING.ERROR : ''}
        required
      />
      {hook.hookTracking.value === TRANSACTION_SETTINGS.URLS[1].value && (
        <Styled.InputStyled
          type="url"
          label={NEW_TRANSACTION_SETTING.URL.LABEL}
          placeholder={NEW_TRANSACTION_SETTING.URL.PLACEHOLDER}
          value={hook.hookUrl}
          onChange={hook.hookSetUrl}
          required
          error={hook.hookUrlError}
          onBlur={hook.hookValidateForm}
        />
      )}

      <Button theme="secondary" onClick={() => hook.hookCloseModal()}>
        {NEW_TRANSACTION_SETTING.BUTTONS.CANCEL}
      </Button>
      <Styled.ButtonStyled onClick={() => hook.hookSaveNewTracking()}>
        {NEW_TRANSACTION_SETTING.BUTTONS.SAVE}
      </Styled.ButtonStyled>
    </Styled.WrapperStyled>
  );
};

export default AddTrackingSettings;
