/* eslint-disable react/no-danger */
import React from 'react';

import { Checkbox } from '../../../../../components';
import { I_HAVE, MENU_APPLY, MENU_APPROVE } from '../../enum';

import * as Styled from './styles';
import { TERMS_AND_CONDITIONS } from './enum';

export const TermsConditionsTab = ({ hook }: { hook: any }) => (
  <Styled.WrapperStyled>
    <Styled.FlexStyled>
      <Styled.SubTitleStyled>{TERMS_AND_CONDITIONS.LAST_UPDATED}</Styled.SubTitleStyled>
      {hook.hookDate}
    </Styled.FlexStyled>
    <Styled.TitleStyled>{hook.hookTermsTitle}</Styled.TitleStyled>
    <Styled.DescriptionStyled>
      <div dangerouslySetInnerHTML={{ __html: hook.hookTermsCondition }} />
    </Styled.DescriptionStyled>
    {(hook.hookButtonOptions.includes(MENU_APPLY) || hook.hookButtonOptions.includes(MENU_APPROVE)) && (
      <Checkbox label={I_HAVE} checked={hook.hookTermChecked} onChange={hook.hookCheckTerm} />
    )}
  </Styled.WrapperStyled>
);
