import React from 'react';

import {
  Button,
  Calendar,
  InputCalendar,
  InputText,
  Pagination,
  ReportPlaceholder,
  Select,
  Table,
  ErrorBanner,
} from '../../../../../components';
import { QUEUE_STATUS, USER_TYPES_ID } from '../../../../../utils';
import BulkImportLogDetails from '../../../BulkImportLogDetails';
import { useUserInfo } from '../../../../../hooks';
import PlaceHolderNoResults from '../../../../../components/NoResultsPlaceHolder';

import { BULK_IMPORT_LOG_TAB } from './enums';
import { useBulkImportLog } from './hooks';
import { columns } from './contracts';
import * as Styled from './styles';

export const BulkImportLog = () => {
  const hook = useBulkImportLog();
  const userHook = useUserInfo();

  return (
    <Styled.WrapperStyled>
      <ErrorBanner isOpen={!!hook.hookErrorMessage} message={hook.hookErrorMessage} />
      {hook.hookDetailsOpen ? (
        <BulkImportLogDetails
          details={hook.hookSelectedBulkTransactionDetails}
          backButtonFunction={hook.hookBackButtonHandler}
        />
      ) : (
        <>
          {userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT &&
          userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.MERCHANT ? (
            <>
              <Styled.FieldStyled columns="1fr 1fr 1fr">
                <Select
                  label={BULK_IMPORT_LOG_TAB.INPUTS.MERCHANT}
                  name="merchantSelect"
                  options={hook.hookMerchantList()}
                  onChange={hook.hookSetMerchant}
                  selected={hook.hookSelectedMerchant}
                  isLoading={hook.hookMerchantLoading || hook.hookPageLoading}
                  isDisabled={hook.hookPageLoading}
                />
                <Select
                  label={BULK_IMPORT_LOG_TAB.INPUTS.QUEUE_STATUS}
                  name="merchantSelect"
                  onChange={hook.hookSetStatus}
                  options={QUEUE_STATUS}
                  selected={hook.hookSelectedStatus}
                  isDisabled={hook.hookPageLoading}
                  isLoading={hook.hookPageLoading}
                />
                <InputCalendar
                  label={BULK_IMPORT_LOG_TAB.INPUTS.DATE_RANGE}
                  value={hook.hookSelectDate}
                  onClick={hook.hookSetOpenCalendar}
                  isDisabled={hook.hookPageLoading}
                />
              </Styled.FieldStyled>

              <Styled.FieldStyled columns="1fr 1fr 1fr">
                <InputText
                  label={BULK_IMPORT_LOG_TAB.INPUTS.FILE_NAME}
                  type="text"
                  value={hook.hookSearch}
                  name="fileName"
                  onChange={hook.hookSetSearch}
                  disabled={hook.hookPageLoading}
                />
                <Styled.FiltersButtonAreaStyled theme="generateLog">
                  <Button
                    theme="tertiary"
                    onClick={hook.hookGenerateLogHandler}
                    loading={hook.hookPageLoading}
                    width="120px"
                  >
                    {BULK_IMPORT_LOG_TAB.GENERATE_LOG}
                  </Button>
                </Styled.FiltersButtonAreaStyled>

                <Styled.RightSideStyled>
                  <Button theme="text-only" onClick={hook.hookClearForm}>
                    Clear Form
                  </Button>
                </Styled.RightSideStyled>
              </Styled.FieldStyled>
            </>
          ) : (
            <Styled.FieldStyled columns="1fr 1fr 1fr 0.3fr">
              <Select
                label={BULK_IMPORT_LOG_TAB.INPUTS.QUEUE_STATUS}
                name="merchantSelect"
                onChange={hook.hookSetStatus}
                options={QUEUE_STATUS}
                selected={hook.hookSelectedStatus}
                isLoading={hook.hookMerchantLoading || hook.hookPageLoading}
                isDisabled={hook.hookPageLoading}
              />
              <InputCalendar
                label={BULK_IMPORT_LOG_TAB.INPUTS.DATE_RANGE}
                value={hook.hookSelectDate}
                onClick={hook.hookSetOpenCalendar}
                isDisabled={hook.hookPageLoading}
              />
              <InputText
                label={BULK_IMPORT_LOG_TAB.INPUTS.FILE_NAME}
                type="text"
                value={hook.hookSearch}
                name="fileName"
                onChange={hook.hookSetSearch}
                disabled={hook.hookPageLoading}
              />
              <Styled.FiltersButtonAreaStyled>
                <Button theme="tertiary" onClick={hook.hookGenerateLogHandler} width="100%">
                  {BULK_IMPORT_LOG_TAB.GENERATE_LOG}
                </Button>
              </Styled.FiltersButtonAreaStyled>
            </Styled.FieldStyled>
          )}

          {hook.hookTableData.length > 0 && (
            <>
              <Table
                columns={columns}
                data={hook.hookTableData}
                isLoading={hook.hookPageLoading}
                loadWithData
                handleCheckBox={hook.hookSetSelectedBulkTransactionHandler}
              />
              <Pagination
                total={hook.hookTotalPages}
                currentPage={hook.hookCurrentPage}
                onPageChange={hook.hookSetCurrentPage}
              />
            </>
          )}

          {!hook.hookIsGenerated && <ReportPlaceholder submitted={false} text="Click generate log to get started" />}

          {hook.hookTableData.length < 1 && hook.hookIsGenerated && <PlaceHolderNoResults />}
          <Calendar
            date={hook.hookStartDate}
            endDate={hook.hookEndDate}
            onApply={hook.hookOnApplyCalendar}
            onCancel={hook.hookOnCancelCalendar}
            isOpen={hook.hookOpenCalendar}
            hasRange
          />
        </>
      )}
    </Styled.WrapperStyled>
  );
};
