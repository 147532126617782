import styled from 'styled-components';

import { colors } from 'styles/theme';

type SecondLineStyledProps = {
  isLink: boolean;
};

type TCellWrapperStyled = {
  readonly capitalize?: boolean;
};

export const CellWrapperStyled = styled.div<TCellWrapperStyled>`
  display: flex;
  min-height: 40px;
  align-items: center;
  text-overflow: ellipsis;
  flex-flow: wrap row;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'initial')};
  align-content: center;
  line-height: 20px;
`;

export const SecondLineStyled = styled.div<SecondLineStyledProps>`
  color: ${colors.color13};
  width: 100%;

  a {
    text-decoration: none;
    color: ${colors.color1};

    &:hover {
      color: ${({ isLink }) => (isLink ? colors.color2 : '')};
    }
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
`;
