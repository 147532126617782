export const PUBLISHER_INVITES_PAGE = {
  PAGETITLE: 'Publisher Invites Management',
  SELF_SERVE_MERCHANT: {
    TITLE: 'Self Serve Merchant',
    TOOLTIP:
      'Self-Serve Merchants manage their own programs and Fintel Connect Admin staff only provide support to the merchant. Merchant users will have full access to all merchant account features.',
    CHECKBOX_LABEL: 'Set a limit to the number of Publisher Invites',
    INPUT_LABEL: 'Limit invites number per month',
    INPUT_NAME: 'self_serve_merhcant_invites_limit',
    INPUT_PLACEHOLDER: '0',
  },
  MANAGED_MERCHANT: {
    TITLE: 'Managed Merchant',
    TOOLTIP:
      'Merchants are managed by Fintel Connect Admin staff. Merchant users will have less access to features to prevent them from making changes that conflicts with the Fintel Connect management of the account.',
    CHECKBOX_LABEL: 'Set a limit to the number of Publisher Invites',
    INPUT_LABEL: 'Limit invites number per month',
    INPUT_NAME: 'managed_merhcant_invites_limit',
    INPUT_PLACEHOLDER: '0',
  },
  RESULTS_MERCHANT: {
    TITLE: 'Results Merchant',
    TOOLTIP:
      "The Results merchant only uses the Fintel Connect program for their own tracking and reporting needs. Merchant users don't have access to the Publisher module.",
    CHECKBOX_LABEL: 'Set a limit to the number of Publisher Invites',
    INPUT_LABEL: 'Limit invites number per month',
    INPUT_NAME: 'results_merchant_invites_limit',
    INPUT_PLACEHOLDER: '0',
  },
  FOOTER_BUTTON_TEXT: 'Update Settings',
  DISCARD_CHANGES_MODAL: {
    TITLE: 'Confirm Discard Changes',
    DESC: 'Your changes in Publisher Invites Management have not been applied. Discard changes?',
    ON_CLOSE_BUTTON_TEXT: 'Discard changes',
    ON_CONFIRM_BUTTON_TEXT: 'Cancel',
  },
  SAVE_CHANGES_MODAL: {
    TITLE: 'Update Publisher Invites Management',
    DESC: 'New limit number of Publisher Invites will be created.',
    ON_CLOSE_BUTTON_TEXT: 'Cancel',
    ON_CONFIRM_BUTTON_TEXT: 'Save Changes',
  },
};

export const PUBLISHER_INVITES_HOOK_ENUMS = {
  UPDATED_SETTINGS_SUCCESS_TOAST: 'Publisher Invites Management successfully updated.',
  UPDATED_SETTINGS_ERROR_TOAST: 'Error - Publisher Invites Management not updated.',
};
