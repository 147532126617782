import React from 'react';

import * as Styled from './styles';

type CellProps = {
  value: any;
};

export const CellDate = ({ value }: CellProps) => {
  const date = new Date(value);

  return (
    <Styled.CellStyled>
      {value === 'Never'
        ? 'Never'
        : date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
    </Styled.CellStyled>
  );
};
