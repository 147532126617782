export const PAYMENT_DETAILS = {
  COMMISSION_CPA: '$234243.45',
  COMMISSION: '$234.46',
  TOTAL_COMMISSIONS: '$53252.78',
  COMMISSION_TAX: '$2435.78',
  BONUS_AMOUNT: '$23452.75',
  BONUS_TAX: '$2345.67',
  TOTAL_PAYABLE: '$2345.43',
  PERIOD_BALANCE: '$2345.43',
};

export const DETAILED_FOOTER = {
  GROSS_SALES: '$234243.45',
  NET_SALES: '$234.46',
  AMOUNT: '$53252.78',
};

export const DETAILED_RECORDS = {
  LOADING_TEXT: 'Loading',
  BACK: 'Back',
  BUTTON_THEME: 'tertiary',
  DONWLOAD: 'Download CSV',
  NO_RESULTS: 'Sorry! No results found',
  RUN_ESTIMATE: 'Please run estimate to see the payment details',
};

export const CSV = {
  AMOUNTS: [
    'currentRollover',
    'cpaCommission',
    'revShareCommission',
    'totalCommissions',
    'bonusTax',
    'totalTaxAmount',
    'totalPayable',
    'periodBalance',
    'bonusAmount',
  ],
  MONTH_NAMES_SUB: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  COLUMNS: [
    { dataField: 'paymentId', text: 'PAYMENT ID' },
    { dataField: 'paymentPeriod', text: 'PAYMENT PERIOD' },
    { dataField: 'paymentStatus', text: 'PAYMENT STATUS' },
    { dataField: 'paymentMethod', text: 'PAYMENT METHOD' },
    { dataField: 'proccessedPeriod', text: 'PROCESSED PERIOD' },
    { dataField: 'merchantId', text: 'MERCHANT ID' },
    { dataField: 'merchant', text: 'MERCHANT' },
    { dataField: 'productCategory', text: 'PRODUCT CATEGORY' },
    { dataField: 'currentRollover', text: 'CURRENT ROLLOVER' },
    { dataField: 'cpaCommission', text: 'COMMISSION CPA' },
    { dataField: 'revShareCommission', text: '% COMMISSION' },
    { dataField: 'totalCommissions', text: 'TOTAL COMMISSIONS' },
    { dataField: 'totalTaxAmount', text: 'COMMISSION TAX' },
    { dataField: 'bonusAmount', text: 'BONUS AMOUNT' },
    { dataField: 'bonusTax', text: 'BONUS TAX' },
    { dataField: 'totalPayable', text: 'TOTAL PAYABLE' },
    { dataField: 'periodBalance', text: 'PERIOD BALANCE' },
  ],
};

export const reportDetailsColumns = [];
