import { gql } from '@apollo/client';

export const CREATE_NEW_API = gql`
  mutation createApiAccessKey($input: ApiAccessKeyInput!) {
    createApiAccessKey(input: $input) {
      apiAccessKey
      status
      apiCreatedAt
    }
  }
`;
