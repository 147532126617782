import React from 'react';

import { Button, Calendar, ErrorBanner } from '..';
import { Checkbox, InputCalendar, InputText, InputTextarea, Select } from '../Input';
import { Modal } from '../Modal';
import { PageTitle } from '../PageTitle';

import { useCreateCampaignModal } from './hooks';
import * as Styled from './styles';
import { BANNER_MESSAGE, BUTTON_LABEL, LABELS, PAGE_TITLE, PLACEHOLDERS, TAB_NAMES } from './utils';

type CreateCampaignModelProps = {
  isOpen: boolean;
  onClose: () => void;
  setCampaign: (value: any, language: any, publisherGroupsId: string[]) => void;
};

export const CreateCampaignModal = ({ onClose, isOpen, setCampaign }: CreateCampaignModelProps) => {
  const hook = useCreateCampaignModal(isOpen);

  return (
    <Modal isOpen={isOpen} theme="campaign">
      <Styled.WrapperStyled>
        <Styled.HeaderStyled>
          <PageTitle>{PAGE_TITLE}</PageTitle>
          <Styled.ButtonWrapper>
            <Button
              theme="secondary"
              width="104px"
              onClick={() => hook.hookOnClose(onClose)}
              disabled={hook.hookLoading}
            >
              {BUTTON_LABEL.CANCEL}
            </Button>
            <Button
              width="104px"
              onClick={() => hook.hookSubmitCampaign(setCampaign, onClose)}
              disabled={hook.hookLoading}
            >
              {BUTTON_LABEL.SAVE}
            </Button>
          </Styled.ButtonWrapper>
        </Styled.HeaderStyled>
        <ErrorBanner isOpen={hook.hookRequiredFieldsError} message={BANNER_MESSAGE} margin="2rem 0 0" />
        <Styled.ButtonWrapper borderBottom>
          <Styled.ButtonContainer selected={hook.hookPage === 1}>
            <Styled.ButtonStyled
              theme="text-only"
              onClick={() => hook.hookSetPage(1)}
              disabled={hook.hookLoading}
              selected={hook.hookPage === 1}
            >
              {TAB_NAMES.DETAILS}
            </Styled.ButtonStyled>
          </Styled.ButtonContainer>
          <Styled.ButtonContainer selected={hook.hookPage === 2}>
            <Styled.ButtonStyled
              theme="text-only"
              onClick={() => hook.hookSetPage(2)}
              disabled={hook.hookLoading}
              selected={hook.hookPage === 2}
            >
              {TAB_NAMES.TAG_GROUP}
            </Styled.ButtonStyled>
          </Styled.ButtonContainer>
        </Styled.ButtonWrapper>
        {hook.hookPage === 1 && (
          <>
            <Styled.FieldStyled columns="1fr 1fr" flex="start">
              <InputText
                type="text"
                label={LABELS.CAMPAIGN_NAME}
                required
                name="campaignName"
                value={hook.hookCampaignName}
                error={hook.hookCampaignErrors.campaignName}
                disabled={hook.hookLoading}
                onChange={hook.hookSetCampaignName}
                onBlur={hook.hookValidateSingleField}
              />
              <Select
                name="status"
                label={LABELS.STATUS}
                options={hook.hookStatusOptions}
                selected={hook.hookStatus}
                error={hook.hookCampaignErrors.status}
                isDisabled={hook.hookLoading}
                isLoading={hook.hookLoading}
                required
                onChange={hook.hookSetStatus}
              />
            </Styled.FieldStyled>
            <Styled.FieldStyled columns="1fr 1fr" flex="start">
              <InputTextarea
                height="150px"
                name="description"
                value={hook.hookDescription}
                error={hook.hookCampaignErrors.description}
                disabled={hook.hookLoading}
                onChange={hook.hookSetDescription}
                onBlur={hook.hookValidateSingleField}
                required
                label={LABELS.DESCRIPTION}
              />
              <div>
                <Styled.FieldStyled columns="1fr 1fr" margin="0 0 2rem 0" flex="flex-start">
                  <InputCalendar
                    required
                    label={LABELS.START_DATE}
                    value={hook.hookStartDate?.toDateString() || ''}
                    placeholder={PLACEHOLDERS.DATE}
                    onClick={hook.hookOnOpenStartCalendar}
                    error={hook.hookCampaignErrors.startDate}
                    isDisabled={hook.hookLoading}
                  />
                  <InputCalendar
                    label={LABELS.END_DATE}
                    value={hook.hookEndDate?.toDateString() || ''}
                    placeholder={PLACEHOLDERS.DATE}
                    onClick={hook.hookOnOpenEndCalendar}
                    error={hook.hookCampaignErrors.endDate}
                    isDisabled={hook.hookLoading}
                  />
                </Styled.FieldStyled>
                <Select
                  onChange={hook.hookSetLanguage}
                  label={LABELS.LANGUAGE}
                  required
                  selected={hook.hookLanguage}
                  error={hook.hookCampaignErrors.language}
                  options={hook.hookLanguageOptions}
                />
              </div>
            </Styled.FieldStyled>
            <Styled.FieldStyled columns="1fr 1fr" flex="flex-start">
              <InputText
                type="url"
                label={LABELS.DEFAULT_URL}
                value={hook.hookDefaultLandingPageUrl}
                onChange={hook.hookSetDefaultLandingPageUrl}
                name="landingUrl"
                error={hook.hookLandingUrlError}
                onBlur={hook.hookValidateWebsite}
                placeholder={hook.hookUrlPlaceholder}
                disabled={hook.hookLoading}
              />
              <InputText
                type="url"
                label={LABELS.THIRD_PARTY_URL}
                name="trackerUrl"
                value={hook.hookThirdPartyTrackingUrl}
                error={hook.hookThirdPartyUrlError}
                onChange={hook.hookSetThirdPartyTrackingUrl}
                onBlur={hook.hookValidateWebsite}
                disabled={hook.hookLoading}
              />
            </Styled.FieldStyled>
          </>
        )}
        {hook.hookPage === 2 && (
          <>
            <Styled.FieldStyled columns="1fr">
              <Select
                name="products"
                label={LABELS.PRODUCT}
                selected={hook.hookProducts}
                onChange={hook.hookSetProducts}
                placeholder={PLACEHOLDERS.PRODUCT}
                options={hook.hookProductsOptions}
                isDisabled={hook.hookLoading}
                isLoading={hook.hookLoading}
              />
            </Styled.FieldStyled>
            <Styled.FieldStyled columns="1fr">
              <Select
                name="tags"
                label={LABELS.TAGS}
                placeholder={PLACEHOLDERS.TAGS}
                selectedMulti={hook.hookTags}
                options={hook.hookTagsOptions}
                onChange={hook.hookSetTags}
                isDisabled={hook.hookLoading}
                isLoading={hook.hookLoading}
                isMulti
                creatable
              />
            </Styled.FieldStyled>
            {hook.hookPublisherGroupOptions.length > 0 && (
              <>
                <Styled.SectionTitleStyled>{LABELS.PUBLISHER_GROUPS}</Styled.SectionTitleStyled>
                <Styled.PublisherGroupStyled>
                  {hook.hookPublisherGroupOptions.map((publisherGroup) => (
                    <Checkbox
                      key={publisherGroup.value}
                      label={publisherGroup.label}
                      checked={publisherGroup.checked}
                      onChange={() => hook.hookSetPublisherGroupHandler(publisherGroup)}
                      disabled={hook.hookLoading}
                    />
                  ))}
                </Styled.PublisherGroupStyled>
              </>
            )}
          </>
        )}
      </Styled.WrapperStyled>
      <Calendar
        date={hook.hookStartDate}
        onApply={hook.hookOnApplyStartCalendar}
        onCancel={hook.hookOnCancelStartCalendar}
        isOpen={hook.hookStartCalendarOpen}
        hasRange={false}
        isSingle
      />
      <Calendar
        date={hook.hookEndDate}
        onApply={hook.hookOnApplyEndCalendar}
        onCancel={hook.hookOnCancelEndCalendar}
        isOpen={hook.hookEndCalendarOpen}
        hasRange={false}
        isSingle
      />
    </Modal>
  );
};
