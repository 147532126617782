import { useEffect, useState } from 'react';

import { cleanPathName } from '../../../utils';
import { Permission } from '../../../entities';

export const useAppWrapper = (permissionsCodeList: string[]) => {
  const getStoreMenuCollapse = () => {
    const storageMenuOpenState = sessionStorage.getItem('collapse') === 'true';

    return storageMenuOpenState;
  };

  const [isCollapsed, setIsCollapsed] = useState<boolean>(getStoreMenuCollapse);

  const toggleCollapsed = () => {
    sessionStorage.setItem('collapse', isCollapsed ? 'false' : 'true');
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    getStoreMenuCollapse();
  }, [window.location.pathname]);

  return {
    hookIsCollapsed: isCollapsed,
    hookToggleCollapsed: toggleCollapsed,
    hookCanAccess:
      cleanPathName(window.location.pathname) === '/dashboard' ||
      cleanPathName(window.location.pathname) === '/notifications'
        ? true
        : Permission.canAccess(permissionsCodeList),
  };
};
