export const RULE_SUMMARY = {
  TITLE: 'Rule Summary',
  PRODUCTS_RULE_APPLY: 'Product(s) for which the rule will apply',
  MINIMUM_CONDITIONS: 'Minimum conditions for the rule to be applicable',
  PARAMETERS_CHECK: 'Parameters for the rule to check',
  AND: 'And',
  VIEW_PRODUCT_FEED_BUTTON: 'Click to view all product feed data',
};

export const NEW_RULE_SUMMARY = {
  TITLE: 'New Rule Summary',
  REVIEW_NOTICE:
    'Review the rule details carefully here before saving. After you save the rule its criteria can not be changed. Rule name, Group and status can be updated at a later date.',
  PRODUCTS_RULE_APPLY: 'Select the product(s) for which the rule will apply',
  MINIMUM_CONDITIONS: 'Select the minimum conditions for the rule to be applicable',
  PARAMETERS_CHECK: 'Define the parameters for the rule to check',
  AND: 'And',
  VIEW_PRODUCT_FEED_BUTTON: 'Click to view all product feed data',
  CREATE_RULE_BUTTON: 'Create Rule',
  CANCEL_BUTTON: 'Cancel',
};

export const FIELD_LABELS = {
  RULE_NAME: 'Rule Name',
  RULE_GROUP: 'Rule Group',
  RULE_DATA: 'Data Associated With Product Field',
  CUSTOM_TEXT_LIST: 'Text phrases to search for',
  RULE_STATUS: 'Rule Status',
};
