import { gql } from '@apollo/client';

export const GET_COMMISSIONS = gql`
  query commissions($programId: String) {
    groups(programId: $programId) {
      count
      groups {
        id
        name
        createdAt
        description
        program {
          id
        }
        campaigns {
          id
          name
        }
        memberships {
          id
          createdAt
          status
        }
        commissions {
          id
          startDate
          endDate
          description
          commissionType
          commissionStructure {
            cpaFlat {
              minimumSaleAmount
              commissionFee
              commissionBase
            }
            revShareFlat {
              commissionFee
              commissionBase
            }
            cpaTiered {
              commissionBase
              cutoffAmount
              tiers {
                upTo
                commissionAmount
              }
            }
            revShareTiered {
              cutoffAmount
              tiers {
                upTo
                commissionAmount
              }
            }
            referral {
              levels
            }
            bonusFlat {
              minimumSaleAmount
              commissionFee
              commissionBase
            }
            bonusTiered {
              commissionBase
              period
              calculationType
              tiers {
                upTo
                commissionAmount
              }
            }
          }
          commissionTarget {
            publisherGroupTargets {
              publisherGroupId
              startDate
              endDate
            }
            publisherTargets {
              publisherId
              startDate
              endDate
            }
          }
          products {
            id
            name
          }
          merchant {
            id
          }
          publisherGroups {
            id
            name
          }
          publishers {
            id
            companyName
          }
        }
      }
    }
  }
`;
