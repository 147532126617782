type ReturnIconBasedOnValueArgs<T = any> = {
  value: number;
  negativeIcon: T;
  positiveIcon: T;
};

export const returnIconBaseOnValue = <T = any>({
  value,
  negativeIcon,
  positiveIcon,
}: ReturnIconBasedOnValueArgs<T>) => {
  if (value < 0) {
    return negativeIcon;
  }

  if (value > 0) {
    return positiveIcon;
  }

  return undefined as unknown as T;
};
