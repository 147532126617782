import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppWrapper, Button, PfdRender, PageTitle, LoadingDots, ErrorBanner } from '../../../components';
import { path } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import { BANK_ACCOUNT_INFO, BUTTON_LABEL, SUBTITLE } from './contracts';
import { useInvoiceStatement } from './hook';
import * as Styled from './styles';

type InvoiceStatementProps = DefaultPropsType;

const InvoiceStatement = ({ permissionsCodeList = [] }: InvoiceStatementProps) => {
  const navigate = useNavigate();
  const hook = useInvoiceStatement();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.hookErrors?.message} isOpen={!!hook.hookErrors?.message && !hook.hookIsLoading} />
      <Styled.HeaderStyled>
        <PageTitle>{path.invoiceStatement.name}</PageTitle>
        <Button onClick={() => navigate(-1)} theme="tertiary">
          {BUTTON_LABEL.BACK}
        </Button>
      </Styled.HeaderStyled>
      {hook.hookisMerchantPdf ? (
        <Styled.TextWrapperStyled>{SUBTITLE.MERCHANT}</Styled.TextWrapperStyled>
      ) : (
        <Styled.TextWrapperStyled>{SUBTITLE.PUBISHER}</Styled.TextWrapperStyled>
      )}

      <Styled.TextWrapperStyled theme="secondary">
        {`${hook.hookStatementDate} - ${hook.hookPayeeName}`}
      </Styled.TextWrapperStyled>
      {hook.hookIsLoading && <LoadingDots />}
      {!hook.hookIsLoading && hook.hookLoadedCorrectly && (
        <PfdRender
          title={hook.hookisMerchantPdf ? 'Invoice Statement' : `${hook.hookStatementDate} - ${hook.hookPayeeName}`}
          headerInfo={hook.hookReceiver}
          payeeInfo={hook.hookPayee}
          bankInfo={BANK_ACCOUNT_INFO}
          tableData={hook.hookPDFTableData}
          hasProductCategory={hook.hookHasProductCategory}
          isMerchantPdf={hook.hookisMerchantPdf}
          rate={hook.hookRate}
        />
      )}
    </AppWrapper>
  );
};

export default InvoiceStatement;
