import styled from 'styled-components';

import { colors } from '../../styles/theme';

export const WrapperStyled = styled.div`
  border-top: 1px solid ${colors.color3};
  position: fixed;
  z-index: 20;
  bottom: 0;
  right: 0;
  padding: 1rem;
  background-color: ${colors.color4};
`;
