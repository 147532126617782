import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { ERROR_TYPES } from '../../../../../utils';
import { useValidation } from '../../../../../utils/validation';
import { CREATE_PUBLISHER_GROUP } from '../enum';
import { useUserInfo } from '../../../../../hooks';
import { CREATE_GROUP } from '../createGroup';
import { Permission } from '../../../../../entities';

export const useCreatePublisherGroup = (permissionsCodeList: string[] = []) => {
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});
  const [secondRender, setSecondRender] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const vali = useValidation();
  const navigate = useNavigate();

  const user = useUserInfo();

  const [createPublishers, { loading: createPublishersLoading }] = useMutation(CREATE_GROUP);

  const goBack = () => {
    navigate(-1);
  };

  const createPublishersHandler = async () => {
    const { data } = await createPublishers({
      variables: {
        input: {
          name,
          description,
          programId: user.hookWhoAmI.programId?.toString(),
        },
      },
    });
    if (data?.createGroup === undefined) {
      setErrorMessage('Failed to save this publisher group');
    } else {
      goBack();
    }
  };

  const descriptionHandler = (e: any) => {
    if (e.target.value.length <= CREATE_PUBLISHER_GROUP.INPUT.TEXT_AREA.MAX_LENGTH) {
      setDescription(e?.target ? e.target.value : e);
    } else {
      setDescription(e.target.value.substring(0, CREATE_PUBLISHER_GROUP.INPUT.TEXT_AREA.MAX_LENGTH));
    }
  };

  const nameHandler = (e: any) => {
    setName(e?.target ? e.target.value : e);
  };

  const values: { [key: string]: string } = {
    groupName: name,
    description,
  };

  const fields = {
    groupName: ERROR_TYPES.NOT_EMPTY,
    description: ERROR_TYPES.NOT_EMPTY,
  };

  const handleValidation = () => {
    let noErrors = true;
    if (secondRender) {
      if (!name) {
        inputErrors.name = CREATE_PUBLISHER_GROUP.ERROR.NAME;
        noErrors = false;
      }
      if (!description) {
        inputErrors.description = CREATE_PUBLISHER_GROUP.ERROR.DESC;
        noErrors = false;
      }
    }
    return vali.validateAll(values, fields, setInputErrors, secondRender) && noErrors;
  };
  useEffect(() => {
    handleValidation();
  }, [secondRender, name, description]);

  const saveHandler = async () => {
    setSecondRender(true);
    const noErrors = handleValidation();
    if (noErrors) {
      await createPublishersHandler();
    }
  };

  return {
    hookSetDescription: descriptionHandler,
    hookDescription: description,
    hookSetName: nameHandler,
    hookName: name,
    hookInputErrors: inputErrors,
    hookSetSecondRender: setSecondRender,
    hookHandleValidation: handleValidation,
    hookSaveHandler: saveHandler,
    hookGoBack: goBack,
    hookLoading: createPublishersLoading,
    hookErrorMessage: errorMessage,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
