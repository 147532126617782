import styled from 'styled-components';

import { colors, fonts } from '../../../../../styles/theme';
import { LoadingDots, Tooltip } from '../../../../../components';
import { toRem } from '../../../../../utils';

type PublisherCheckProps = {
  readonly index: number;
};

type ModalWrapperProps = {
  readonly column?: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
  width: 50%;
  padding: 1rem;

  :first-of-type {
    border-right: 1px solid ${colors.color19};
    margin-right: 2rem;
  }
`;

export const TitleWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const TitleStyled = styled.h4`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
`;

export const TooltipStyled = styled(Tooltip)`
  margin-left: 0.5rem;
`;

export const MapWrapperStyled = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const LoadingStyled = styled(LoadingDots)`
  margin-top: 15rem;
`;

export const MapStyled = styled.div`
  width: 100%;
  height: 300px;
  background-color: ${colors.transparent};
`;

export const ModalWrapperStyled = styled.div`
  padding: ${toRem(40)};
  width: 600px;
`;

export const ModalTextStyled = styled.p`
  ${fonts.lato.H16R}
  color: ${colors.color1};
  line-height: 1.5;

  :first-of-type {
    margin: 2rem 0;
  }
`;

export const ModalCheckTypeStyled = styled.p`
  ${fonts.lato.H16M}
  color: ${colors.color1};
`;

export const FigureStyled = styled.figure`
  width: 100%;
  margin: 1.5rem 0;
  height: 300px;
  img {
    height: 300px;
  }
`;

export const ModalCheckWrapperStyled = styled.div<ModalWrapperProps>`
  margin: ${({ column }) => (column ? '0' : '1.5rem 0')};
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: flex-start;
`;

export const CheckColorStyled = styled.div<PublisherCheckProps>`
  min-width: 12px;
  min-height: 12px;
  border-radius: 6px;
  margin-top: 3px;
  background-color: ${({ index }) => {
    switch (index) {
      case 1:
        return colors.color38;
      case 2:
        return colors.color14;
      case 3:
        return colors.color39;
      case 4:
        return colors.color12;
      default:
        return colors.color37;
    }
  }};
`;
