import styled from 'styled-components';
import React from 'react';

import { fonts } from '../../styles/theme';
import { imageList, NO_RESULTS } from '../../utils';

const PlaceholderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;

const PlaceHolderNoResults = () => (
  <PlaceholderStyled>
    <p>{NO_RESULTS.SORRY}</p>
    <span>{NO_RESULTS.LOOKING}</span>

    <figure>
      <img src={imageList.noResults.src} alt={imageList.noResults.alt} />
    </figure>
  </PlaceholderStyled>
);

export default PlaceHolderNoResults;
