import styled, { keyframes } from 'styled-components';

import { colors } from '../../styles/theme';

type WrapperProps = {
  readonly size?: string;
  readonly theme?: Theme;
};

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const WrapperStyled = styled.div<WrapperProps>`
  display: inline-block;
  font-size: ${({ size }) => size};
  animation: ${rotate} 1s linear infinite;
  padding: 0;
  line-height: 0;

  color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colors.color1;
      case 'tertiary':
        return colors.color4;
      case 'primary':
        return colors.color4;
      case 'quaternary':
        return colors.color2;
      default:
        return colors.color10;
    }
  }};
`;
