import { useEffect, useState } from 'react';

import { COUNTRIES, GST_PST_STATES, GST_STATES, HST_STATES, QST_STATES } from '../utils';

export const useMerchantAddress = () => {
  const [address, setAddress] = useState('');
  const [addressTwo, setAddressTwo] = useState<string | undefined>();
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState({ label: '', value: '' });
  const [country, setCountry] = useState({ label: '', value: '' });

  const [gstDisabled, setGstDisabled] = useState(true);
  const [pstDisabled, setPstDisabled] = useState(true);
  const [hstDisabled, setHstDisabled] = useState(true);
  const [taxationNumberDisabled, setTaxationNumberDisabled] = useState(false);

  const [isRegionDisabled, setRegionIsDisabled] = useState(true);

  const setAddressOneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };
  const setAddressTwoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressTwo(e.target.value === '' ? undefined : e.target.value);
  };
  const setPostalCodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(e.target.value);
  };
  const setCityHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
  };

  const setAddressOneStringHandler = (value: string) => {
    setAddress(value);
  };
  const setAddressTwoStringHandler = (value: string) => {
    setAddressTwo(value === '' ? undefined : value);
  };
  const setPostalCodeStringHandler = (value: string) => {
    setPostalCode(value);
  };
  const setCityStringHandler = (value: string) => {
    setCity(value);
  };

  const setGstDisabledHandler = () => {
    setGstDisabled(!gstDisabled);
  };

  const setPstDisabledHandler = () => {
    setPstDisabled(!pstDisabled);
  };

  const setHstDisabledHandler = () => {
    setHstDisabled(!hstDisabled);
  };

  const setTaxationNumberDisabledHandler = () => {
    setTaxationNumberDisabled(!taxationNumberDisabled);
  };

  const setOriginalTax = () => {
    setGstDisabled(true);
    setHstDisabled(true);
    setPstDisabled(true);
    setTaxationNumberDisabled(false);
  };

  const checkRegionTax = (regionId: any) => {
    if (country.label === COUNTRIES.CA && GST_STATES.includes(regionId.label)) {
      setGstDisabledHandler();
      setTaxationNumberDisabledHandler();
    } else if (country.label === COUNTRIES.CA && HST_STATES.includes(regionId.label)) {
      setHstDisabledHandler();
      setTaxationNumberDisabledHandler();
    } else if (
      country.label === COUNTRIES.CA &&
      (GST_PST_STATES.includes(regionId.label) || QST_STATES.includes(regionId.label))
    ) {
      setGstDisabledHandler();
      setPstDisabledHandler();
      setTaxationNumberDisabledHandler();
    } else {
      setOriginalTax();
    }
  };

  const setRegionHandler = (value: any) => {
    setOriginalTax();
    setRegion({
      label: value.label,
      value: value.value,
    });
  };

  const setIsRegionDisabledHandler = () => {
    setRegionIsDisabled(false);
  };

  const setCountryHandler = (value: any) => {
    setCountry({
      label: value.label,
      value: value.value,
    });
    checkRegionTax(value);
    setIsRegionDisabledHandler();
  };

  useEffect(() => {
    checkRegionTax(region);
  }, [region]);

  return {
    hookAddress: address,
    hookAddressTwo: addressTwo,
    hookPostalCode: postalCode,
    hookCity: city,
    hookRegion: region,
    hookCountry: country,

    hookSetAddress: setAddressOneHandler,
    hookSetAddressTwo: setAddressTwoHandler,
    hookSetPostalCode: setPostalCodeHandler,
    hookSetCity: setCityHandler,
    hookSetRegion: setRegionHandler,
    hookSetCountry: setCountryHandler,

    hookSetStringAddress: setAddressOneStringHandler,
    hookSetStringAddressTwo: setAddressTwoStringHandler,
    hookSetStringPostalCode: setPostalCodeStringHandler,
    hookSetStringCity: setCityStringHandler,

    hookIsDisabled: isRegionDisabled,

    hookSetGstDisabled: setGstDisabledHandler,
    hookSetPstDisabled: setPstDisabledHandler,
    hookSetHstDisabled: setHstDisabledHandler,

    hookGstDisabled: gstDisabled,
    hookPstDisabled: pstDisabled,
    hookHstDisabled: hstDisabled,
    hookTaxationNumberDisabled: taxationNumberDisabled,
  };
};
