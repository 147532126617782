import React, { useState, useEffect } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';

import { Button, ErrorBanner, PageTitle, Modal } from 'components';
import environment from 'config/environment';

import { POSTBACK_DEFAULTS, inputDefaults, defaultErrors } from '../enums';
import { CompanyOptionsType, PostbackType, PostbackRuleErrors, MembershipsResultType } from '../types';

import * as Styled from './styles';

const isValidUrl = (value: string | any) => {
  const link = document.createElement('a');
  link.href = value;
  return link.host && link.host !== window.location.host;
};

const PostbackRuleModal = ({
  hook,
  modalType,
  targetType,
  isModalOpen,
  closeModal,
}: {
  hook: any;
  modalType: string;
  targetType: string;
  isModalOpen: boolean;
  closeModal: () => void;
}): JSX.Element => {
  const [eventOptions, setEventOptions] = useState<SelectOption[]>([]);
  const [selectedEventOption, setSelectedEventOption] = useState<SelectOption>(inputDefaults.eventType[1]);
  const [companyOptions, setCompanyOptions] = useState<SelectOption[]>([]);
  const [selectedCompanyOption, setSelectedCompanyOption] = useState<SelectOption>(inputDefaults.companyOptions);
  const [isNotActiveCompany, setIsNotActiveCompany] = useState<boolean>(false);
  const [errors, setErrors] = useState<PostbackRuleErrors>(defaultErrors);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  /**
   * @name initialize
   * @description Initializes the component
   */
  const initialize = () => {
    const newEventOptions: SelectOption[] = [...inputDefaults.eventType];
    const newCompanyOptions: SelectOption[] = [inputDefaults.companyOptions];

    // Remove the "All Events" event type from the list of options
    newEventOptions.shift();
    setEventOptions(newEventOptions);

    if (hook.postback?.eventType) {
      const selectedEvent = find<SelectOption>(newEventOptions, { value: hook.postback.eventType });
      if (selectedEvent) {
        setSelectedEventOption(selectedEvent);
      } else {
        setSelectedEventOption(inputDefaults.eventType[1]);
      }
    }

    if (hook.memberships) {
      hook.memberships.forEach((membership: MembershipsResultType) => {
        let company: CompanyOptionsType = { id: '', companyName: '' };
        // eslint-disable-next-line default-case
        switch (targetType) {
          case 'Publisher':
            company = membership.publisher;
            break;
          case 'Merchant':
            company = membership.merchant;
            break;
        }
        newCompanyOptions.push({ value: company.id, label: `${company.id} - ${company.companyName}` });
      });
    }

    if (hook.postback?.targetId) {
      const selectedCompany = find<SelectOption>(newCompanyOptions, { value: hook.postback.targetId });
      if (selectedCompany) {
        setSelectedCompanyOption(selectedCompany);
      } else {
        setSelectedCompanyOption(inputDefaults.companyOptions);
      }
      setIsNotActiveCompany(!selectedCompany);
    } else {
      setSelectedCompanyOption(inputDefaults.companyOptions);
      setIsNotActiveCompany(false);
    }

    setCompanyOptions(newCompanyOptions);
  };

  useEffect(initialize, [hook.postback, hook.memberships, hook.postbackId]);

  /**
   * @name onChangeHandler
   * @description Updates the `name` property on the postback object with `value` and resets the respective error.
   * @param {string} name The name of the property to update.
   * @param {{ value: string | null }} value The value to update the property with.
   */
  const onChangeHandler = (name: string, { value }: { value: string | null }) => {
    hook.setPostback((prev: PostbackType) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
    setError('');
    if (name === 'targetId' && (value === null || value === '' || value === '*')) {
      hook.setPostback((prev: PostbackType) => ({ ...prev, targetId: null }));
      setErrors((prev) => ({ ...prev, targetId: '' }));
    }
    if (value !== null) {
      if (name === 'eventType') {
        const selectedEvent = find(eventOptions, { value });
        setSelectedEventOption(selectedEvent || inputDefaults.eventType[1]);
      }
      if (name === 'targetId') {
        const selectedCompany = find(companyOptions, { value });
        setSelectedCompanyOption(selectedCompany || inputDefaults.companyOptions);
      }
    }
  };

  /**
   * @name handleClose
   * @description Resets the input fields and closes the modal.
   */
  const handleClose = () => {
    hook.setPostback(POSTBACK_DEFAULTS);
    setSelectedCompanyOption(inputDefaults.companyOptions);
    setErrors({});
    setError('');
    setIsNotActiveCompany(false);
    closeModal();
  };

  /**
   * @name handleCommit
   * @description Validates the input fields and commits the postback rule to the database.
   */
  const handleCommit = async () => {
    let hasErrors = false;
    setIsLoading(true);
    // validation
    const validation = ((postback) => {
      const result = {
        isError: false,
        errors: { ...defaultErrors } as PostbackRuleErrors,
      } as any;
      if (!postback.eventType) {
        result.isError = true;
        result.errors.eventType = 'Please select a valid event type.';
      }
      if (!postback.url || !isValidUrl(hook.postback?.url)) {
        result.isError = true;
        result.errors.url = 'Please enter a valid URL.';
      }
      if (!postback.queryString) {
        result.isError = true;
        result.errors.queryString = "Please provide a postback value that describes the data you'd like to receive.";
      }
      return result;
    })(hook.postback);
    if (validation.isError) {
      hasErrors = true;
      setErrors((prev) => ({ ...prev, ...validation.errors }));
    }
    if (!validation.isError) {
      // Check for duplicates
      const duplicate = find(
        hook.postbacks,
        (item) =>
          true &&
          item.eventType === hook.postback?.eventType &&
          item.url === hook.postback?.url &&
          item.targetId === hook.postback?.targetId &&
          item.queryString === hook.postback?.queryString &&
          (modalType === 'Add' || (modalType === 'Edit' && item.id !== hook.postbackId))
      );
      if (duplicate) {
        setError('This postback rule already exists.');
      } else if (!hasErrors) {
        let success = false;
        const input = { ...hook.postback, companyId: hook.companyId, targetType };
        delete input.target;

        if (modalType === 'Add') {
          delete input.id;
          success = hook.createPostback(input);
        }
        if (modalType === 'Edit') {
          input.id = hook.postbackId;
          success = hook.updatePostback(input);
        }
        if (success) {
          handleClose();
          hook.showToast(`Postback rule was ${modalType.toLowerCase()}ed successfully`);
        }
      }
    }

    setIsLoading(false);
  };

  /**
   * @name insertDefaultPostbackValue
   * @description Inserts the default postback value into the query string input field.
   */
  const insertDefaultPostbackValue = () => {
    onChangeHandler('queryString', {
      value: `transactionId={{transactionId}}
&transactionStatus={{transactionStatus}}
&clickId={{clickId}}
&affiliateCustomId={{acid}}
&orderId={{orderId}}
&trackingProfileId={{sid}}
&adId={{cid}}
&customerId={{customerId}}
&publisherId={{publisherId}}
&publisherName={{publisherName}}
&merchantId={{merchantId}}
&merchantName={{merchantName}}
&merchantProductId={{mpId}}
&merchantProductName={{productName}}
&grossSale={{grossSale}}
&netSale={{netSale}}
&commissionAmount={{commissionAmount}}
&referralUrl={{referralUrl}}`,
    });
  };

  return (
    <Modal isOpen={isModalOpen}>
      <Styled.WrapperStyled>
        <Styled.TopStyled>
          <PageTitle>{modalType} Postback Rule</PageTitle>

          <Styled.CloseButtonStyled theme="secondary" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Styled.CloseButtonStyled>
        </Styled.TopStyled>

        <Styled.ContentStyled>
          <ErrorBanner message={error} isOpen={!!error} />
          <Styled.SelectStyled
            label="TRANSACTION EVENT"
            placeholder="Select a transaction event type"
            options={eventOptions}
            selected={selectedEventOption}
            error={errors.eventType}
            onChange={(option) => {
              onChangeHandler('eventType', option);
            }}
            required
          />
          {!isNotActiveCompany && (
            <Styled.SelectStyled
              label={`${targetType.toUpperCase()}`}
              placeholder={`Select a ${targetType.toLowerCase()} or 'All Transactions'`}
              options={companyOptions}
              selected={selectedCompanyOption}
              error={errors.targetId}
              onChange={(option) => {
                onChangeHandler('targetId', option);
              }}
              required
            />
          )}
          {isNotActiveCompany && (
            <Styled.SelectStyled
              label={`${targetType.toUpperCase()}`}
              placeholder={`Select a ${targetType.toLowerCase()} or 'All Transactions'`}
              options={[
                {
                  value: hook.postback.targetId,
                  label: `${hook.postback.targetId} - ${hook.postback.target?.companyName}`,
                },
              ]}
              selected={{
                value: hook.postback.targetId,
                label: `${hook.postback.targetId} - ${hook.postback.target?.companyName}`,
              }}
              error={errors.targetId}
              onChange={() => null}
              isDisabled
              required
            />
          )}
          <Styled.InputTextStyled
            type="text"
            name="url"
            label="URL"
            value={hook.postback?.url}
            error={errors.url}
            placeholder="Enter the postback URL, including the protocol (e.g. https://www.example.com/postback)."
            onChange={(event) => {
              onChangeHandler('url', { value: `${event.target.value}`.trim() });
            }}
            required
          />
          <Styled.TextareaStyled
            rows={5}
            label="POSTBACK VALUE"
            value={hook.postback?.queryString}
            error={errors.queryString}
            placeholder="Describe the data you'd like to receive in query string format."
            onChange={(event) => {
              onChangeHandler('queryString', { value: `${event.target.value}`.trim() });
            }}
            required
          />
        </Styled.ContentStyled>
        <Styled.LinksStyled>
          <Button theme="text-only" onClick={insertDefaultPostbackValue}>
            ^ Apply Default Postback Value
          </Button>
          <Styled.AnchorStyled
            href={`${environment.app.kbUrl}/article?id=6449a96a4525cf004bb7d57a`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            Supported Macros
          </Styled.AnchorStyled>
        </Styled.LinksStyled>
        <Styled.FooterStyled>
          <Styled.ButtonStyled theme="secondary" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Styled.ButtonStyled>
          <Styled.ButtonStyled theme="primary" onClick={handleCommit} disabled={isLoading}>
            Save
          </Styled.ButtonStyled>
        </Styled.FooterStyled>
      </Styled.WrapperStyled>
    </Modal>
  );
};

export default PostbackRuleModal;
