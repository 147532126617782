import { Quill } from 'react-quill';

export const sizes = [
  false,
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '30px',
  '36px',
  '48px',
  '60px',
  '72px',
  '96px',
];

export const Sizes = () => {
  const Size = Quill.import('attributors/style/size');
  Size.whitelist = sizes;
  Quill.register(Size, true);
};
