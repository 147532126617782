import React from 'react';

import { FORM_SECTION_TITLE, INPUT_LABEL } from '../../contracts';
import * as Styled from '../../styles';

type ContactInformationProps = {
  hookPublisherAddress: string;
  hookSetPublisherAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hookPublisherAddressTwo?: string;
  hookSetPublisherAddressTwo: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookPublisherCity: string;
  hookSetPublisherCity: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookRegionOptions: SelectOption[];
  hookRegion?: SelectOption;
  hookSetRegion: (value: any) => void;

  hookCountryList: SelectOption[];
  hookSelectedCountry: SelectOption;
  hookSetSelectedCountry: (value: any) => void;

  hookPublisherPostalCode: string;
  hookSetPublisherPostalCode: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookPublisherEmail: string;
  hookSetPublisherEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookPublisherPhoneNumber: string;
  hookSetPublisherPhoneNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hookPublisherPhoneNumberTwo: string;
  hookSetPublisherPhoneNumberTwo: (e: React.ChangeEvent<HTMLInputElement>) => void;

  hookPublisherErrors: { [key: string]: string };
  hookValidateFields: () => void;

  isReadOnly: boolean;
  hookLoading: boolean;
};

export const ContactInformation = ({
  hookPublisherAddress,
  hookPublisherAddressTwo,
  hookPublisherCity,
  hookRegion,
  hookRegionOptions,
  hookSetRegion,
  hookCountryList,
  hookSelectedCountry,
  hookSetSelectedCountry,
  hookPublisherPostalCode,
  hookSetPublisherCity,
  hookSetPublisherEmail,
  hookPublisherEmail,
  hookPublisherPhoneNumber,
  hookSetPublisherPhoneNumber,
  hookSetPublisherAddress,
  hookSetPublisherAddressTwo,
  hookSetPublisherPostalCode,
  hookPublisherPhoneNumberTwo,
  hookSetPublisherPhoneNumberTwo,
  hookPublisherErrors,
  hookValidateFields,
  isReadOnly,
  hookLoading,
}: ContactInformationProps) => {
  const themeForViewOnly = isReadOnly ? 'readonlyTernary' : 'default';
  const addresss = isReadOnly
    ? `${hookPublisherAddress}, ${hookPublisherCity}, ${hookRegion?.value}, ${hookSelectedCountry.value}, ${hookPublisherPostalCode}`
    : hookPublisherAddress;

  return (
    <Styled.ContentStyled>
      <Styled.SectionTitleStyled>{FORM_SECTION_TITLE.CONTACT}</Styled.SectionTitleStyled>

      <>
        <Styled.InputTextStyled
          grids={isReadOnly ? 4 : 2}
          type="text"
          label={INPUT_LABEL.ADDRESS}
          value={addresss}
          onChange={hookSetPublisherAddress}
          error={hookPublisherErrors.address}
          onBlur={hookValidateFields}
          required={!isReadOnly}
          theme={themeForViewOnly}
          readonly={isReadOnly}
          disabled={!isReadOnly && hookLoading}
        />
        <Styled.InputTextStyled
          grids={2}
          type="text"
          label={INPUT_LABEL.ADDRESS_TWO}
          value={hookPublisherAddressTwo}
          onChange={hookSetPublisherAddressTwo}
          onBlur={hookValidateFields}
          theme={themeForViewOnly}
          readonly={isReadOnly}
          disabled={!isReadOnly && hookLoading}
        />
      </>

      {isReadOnly ? (
        <>
          <Styled.InputPhoneStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.PHONE_NUMBER}
            value={hookPublisherPhoneNumber}
            theme={themeForViewOnly}
            readonly
          />
          <Styled.InputPhoneStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.PHONE_NUMBER_SECONDARY}
            value={hookPublisherPhoneNumberTwo}
            theme={themeForViewOnly}
            readonly
          />
          <Styled.InputTextStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.CONPANY_EMAIL}
            value={hookPublisherEmail}
            theme={themeForViewOnly}
            readonly
          />
        </>
      ) : (
        <>
          <Styled.InputTextStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.CITY}
            value={hookPublisherCity}
            onChange={hookSetPublisherCity}
            error={hookPublisherErrors.city}
            onBlur={hookValidateFields}
            required
            disabled={hookLoading}
          />
          <Styled.SelectStyled
            grids={2}
            label={INPUT_LABEL.REGION}
            options={hookRegionOptions}
            onChange={hookSetRegion}
            selected={hookRegion}
            error={hookPublisherErrors.region}
            required
            isDisabled={hookLoading}
          />
          <Styled.SelectStyled
            grids={2}
            label={INPUT_LABEL.COUNTRY}
            onChange={hookSetSelectedCountry}
            selected={hookSelectedCountry}
            options={hookCountryList}
            error={hookPublisherErrors.country}
            required
            isDisabled={hookLoading}
          />
          <Styled.InputTextStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.POST_CODE}
            value={hookPublisherPostalCode}
            onChange={hookSetPublisherPostalCode}
            error={hookPublisherErrors.postalCode}
            onBlur={hookValidateFields}
            required
            disabled={hookLoading}
          />
          <Styled.InputPhoneStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.PHONE_NUMBER}
            value={hookPublisherPhoneNumber}
            onChange={hookSetPublisherPhoneNumber}
            error={hookPublisherErrors.phone}
            onBlur={hookValidateFields}
            required
            disabled={hookLoading}
          />
          <Styled.InputPhoneStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.PHONE_NUMBER_SECONDARY}
            value={hookPublisherPhoneNumberTwo}
            onChange={hookSetPublisherPhoneNumberTwo}
            error={hookPublisherErrors.phoneTwo}
            onBlur={hookValidateFields}
            disabled={hookLoading}
          />
          <Styled.InputTextStyled
            grids={2}
            type="text"
            label={INPUT_LABEL.CONPANY_EMAIL}
            value={hookPublisherEmail}
            onChange={hookSetPublisherEmail}
            error={hookPublisherErrors.publisherEmail}
            onBlur={hookValidateFields}
            disabled={hookLoading}
          />
        </>
      )}
    </Styled.ContentStyled>
  );
};
