import { useState } from 'react';

import { paginator } from '../../../../../../utils';
import { commissionsFormatter } from '../../../../../../utils/formatCommissions';

export const useCommissions = (commisionsInput: any) => {
  const commissions = commissionsFormatter(commisionsInput, false);
  const totalPagesCom = Math.ceil(commissions.length / 10);
  const [paginatedCom, setPaginatedCom] = useState<any>(paginator(commissions, 10, 1));
  const [currentPageCom, setCurrentPageCom] = useState(1);

  const changePageCommissions = (value: any) => {
    setPaginatedCom(paginator(commissions, 10, value));
    setCurrentPageCom(value);
  };

  return {
    hookPaginatedCom: paginatedCom,

    hookCurrentPageCom: currentPageCom,

    hookTotalPageCom: totalPagesCom,

    hookChangePageCommissions: changePageCommissions,
  };
};
