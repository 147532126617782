import React from 'react';

import { truncateNumberAndRoundWithDecimals } from '../../../utils';

export const useStatisticCard = () => {
  const displayCurrency = (amount: number, currency?: string, decimals = 2) => {
    switch (currency) {
      case '%':
        return <p>{truncateNumberAndRoundWithDecimals(amount, decimals)}%</p>;
      case '$':
        return <p>${Math.round(amount * 100) / 100}</p>;
      default:
        return <p>{amount}</p>;
    }
  };

  return {
    hookCurrency: displayCurrency,
  };
};
