import styled from 'styled-components';

import { Button } from '../../../components';
import { toRem } from '../../../utils';

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
  height: 45px;
  svg {
    margin-right: 5px;
  }
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: ${toRem(32)};
`;

export const InfoBox = styled.div`
  margin-top: 4px;
  margin-bottom: 15px;
  font-size: 15px;
  word-spacing: 1px;
  width: 90%;

  & > div {
    margin-bottom: 0.5rem;
  }
`;
