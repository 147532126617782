import { gql } from '@apollo/client';

export const UPDATE_PAYMENTS = gql`
  mutation updatePayment($input: PaymentInput) {
    updatePayment(input: $input) {
      payment {
        id
      }
    }
  }
`;
