export type PermissionType = {
  code: string;
  permission: string;
};

export class Permission {
  private static permissions: PermissionType[];

  static setPermissions(permissions: PermissionType[]) {
    Permission.permissions = permissions;
  }

  static canAccess(codeArray: string[]): boolean {
    if (Permission.permissions === undefined || codeArray.length === 0) {
      return false;
    }

    const filteredPermissions = Permission.filterPermissions(codeArray);
    if (!filteredPermissions || filteredPermissions.length === 0) return false;
    return !filteredPermissions.every((permission: PermissionType) => permission.permission === 'noAccess');
  }

  static readOnlyPermissionsList(codeArray: string[]): boolean[] {
    if (Permission.permissions === undefined || codeArray.length === 0) {
      return [false];
    }

    const filteredPermissions = Permission.filterPermissions(codeArray);

    return filteredPermissions.map(
      (permission: PermissionType) => permission.permission === 'read' || permission.permission === 'noAccess'
    );
  }

  private static filterPermissions(codeArray: string[]): PermissionType[] {
    const filteredPermissions = Permission.permissions.filter((permission: PermissionType) =>
      codeArray.includes(permission.code)
    );

    const orderedFiltered: PermissionType[] = codeArray.map((code) => {
      const permission = filteredPermissions.find((item) => item.code === code);
      return permission ? { ...permission } : { code, permission: 'N/A' };
    });

    return orderedFiltered;
  }
}
