import styled from 'styled-components';

import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';
import { Button } from '../../../../components';

type VisibleStatusProps = {
  readonly visible: boolean;
};

type GraphWrapperProps = {
  readonly show: boolean;
};

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  aside {
    ${fonts.lato.H16}
    ${colors.color1}
  }
`;

export const StatusStyled = styled.div<VisibleStatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  ${fonts.lato.H14}
  padding: ${toRem(8)} ${toRem(10)};
  background-color: ${({ visible }) => (visible ? colors.color12 : colors.color23)};
  color: ${colors.color4};
  border-radius: 2px;
  transition: all 0.5s ease;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.color4};
  padding: 2rem;
  margin: 2rem 0;
`;

export const SectionHeaderStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LatestCheckStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonStyled = styled(Button)`
  ${fonts.gotham.H22}
  width: 23px;
`;

export const LatestCheckTitleStyled = styled.h4`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
  margin-left: 0.5rem;
`;

export const LastCheckStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color19};
  color: ${colors.color1};
  padding: 0.5rem;
  ${fonts.lato.H12}
  border-radius: 4px;
  margin-left: 1rem;
`;

export const GraphWrapper = styled.div<GraphWrapperProps>`
  width: 100%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: flex-start;
  justify-content: space-between;
`;
