export const SUBTITLE = 'Account Balance Stats';

export const TITLE_SUBTEXT = 'Management';

export const BUTTON_LABELS = {
  NOTIFY: 'Notify Deposit',
  NOTIFY_ADMIN: 'Notify Admin',
  ADD: 'Add Deposit',
  CANCEL: 'Cancel',
  CLEAR: 'Clear Form',
  DOWNLOAD: 'Download CSV',
  TEMP: 'TEMP BUTTON',
  TRY_AGAIN: 'Try Again',
  BACK: 'Back to Account Balance',
};

export const INPUT_LABELS = {
  CATEGORY: 'Product Category',
  CURRENCY: 'Deposit Currency',
  AMOUNT: 'Deposit Amount',
  ADDITIONAL_INFO: 'Additional Info',
  PERIOD: 'All Period',
  PAYMENT: 'Payment Amount',
  DATE: 'Payment Date',
  METHOD: 'Deposit Method',
  EMAIL: 'Payment Email',
  PAYER: "Payer's Name",
  RANGE: 'date range',
  STATUS: 'status',
};

export const MODAL = {
  ADD: {
    TITLE: 'Add New Deposit',
  },
  NOTIFICATION: {
    TITLE: 'Deposit Notification',
  },
  PAYPAL_FAILED: {
    TITLE: 'Deposit transaction failed',
    DESCRIPTION:
      'Sorry, we are unable to process your request. Please try again. If you continue to have issue, please try another payment method.',
  },
  PAYPAL_SUCCESS: {
    TITLE: 'Deposit transaction successful',
    DESCRIPTION:
      'It may take several minutes for your account balance to reflect this deposit.You will receive a confirmation email from the system.',
  },
};

export const STATS_TABS = ['Monthly Account Balance', 'PayPal Order'];

export const PLACEHOLDERS = {
  NO_RECODER: 'No records found',
  PERIODS: 'All Periods',
  DATE: 'Select Date',
  PAYPAL: 'A PayPal order report will show up here once you deposit values into your account using PayPal.',
};

export const PAYMENT_CURRENCY = [
  {
    label: 'CAD',
    value: 'CAD',
  },
  {
    label: 'USD',
    value: 'USD',
  },
];

export const TABLE_FOOTER = 'TOTAL';

export const ORDER_STATUS = [
  {
    label: 'All Statuses',
    value: 'any',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Denied',
    value: 'DENIED',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
];

export const SORT_DEFAULT: TableSortColumn = { column: 'year', direction: 'desc' };

export const PRODUCT_CATEGORIES = [
  { label: 'All Categories', value: '' },
  { label: 'Banking', value: 'Banking' },
  { label: 'Business Products', value: 'Businesses' },
  { label: 'Credit Cards', value: 'Credit Cards' },
  { label: 'Credit Reporting', value: 'Credit Reporting' },
  { label: 'Cryptocurrency', value: 'Cryptocurrency' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Investments', value: 'Investments' },
  { label: 'Loans', value: 'Loans' },
  { label: 'Mortgage', value: 'Mortgage' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Tax Services', value: 'Tax Services' },
  { label: 'Wills & Estate Planning', value: 'Wills & Estate Planning' },
];

export const ERROR_MESSAGES = {
  CALENDAR: 'Please select a valid date',
  NEW_DEPOSIT: 'There is a processing transaction, please try again later',
};

export const DEPOSIT_ORDER_TYPE = 'MERCHANT_DEPOSIT';
export const DEPOSIT_ORDER_STATUS = 'INITIALIZED';
