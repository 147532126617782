import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import * as Styled from './styles';

type NetworkStatusMerchantManagementProps = {
  value: boolean;
};

export const NetworkStatusMerchantManagement = ({ value }: NetworkStatusMerchantManagementProps) => (
  <Styled.CellWrapperStyled>{!value && <FontAwesomeIcon icon={faEyeSlash} />}</Styled.CellWrapperStyled>
);
