import reactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { Button, Table } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 23% calc(100% - 23%);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.color1};
  padding-top: 9rem;
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightContentStyled = styled.div`
  min-width: 400px;
  max-width: 1200px;
  padding: 0px 20%;
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color1};
  padding-top: 7rem;
  padding-bottom: 5rem;

  label {
    margin-bottom: 2rem;
  }

  h1 {
    ${fonts.gotham.H36M}
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    ${fonts.gotham.H26M}
  }

  span {
    ${fonts.lato.H14}
    line-height: 1.5;
  }
`;

export const FormStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  margin-top: 1rem;
`;

export const TableStyled = styled(Table)`
  margin: 2rem 0;
`;

export const ModalTitleStyled = styled.h2`
  ${fonts.gotham.H32M}
  color: ${colors.color1};
  margin-bottom: 2rem;
  line-height: 1.3;
`;

export const ModalButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 2rem;
`;

export const ReactMarkdownStyled = styled(reactMarkdown)`
  h2 {
    ${fonts.gotham.H32M};
  }
  p {
    ${fonts.lato.H16};
    line-height: 1.5;
  }
  strong {
    ${fonts.lato.H16B};
    line-height: 1.5;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 40px;
`;
