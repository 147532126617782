import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toRem } from '../../../../../../utils';
import { colors } from '../../../../../../styles/theme';
import { Tooltip } from '../../../../../../components';

type TextStyledProps = {
  readonly theme?: string;
};

type CellWrapperStyledProps = {
  readonly theme?: string;
};

type NotifyerProps = {
  readonly isShow?: boolean;
};

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TextStyled = styled.text<TextStyledProps>`
  font-size: ${({ theme }) => (theme === 'main' ? toRem(16) : toRem(14))};
  font-weight: ${({ theme }) => (theme === 'main' ? 'bold' : 'normal')};
  color: ${({ theme }) => (theme === 'main' ? colors.color1 : colors.color13)};
`;

export const IconButtonStyled = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const ToolTipStyled = styled(Tooltip)`
  z-index: 100;
  position: sticky;
  div:first-child {
    color: ${colors.color2};
    z-index: 1000;
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  color: ${colors.color1};
`;

export const StyledNotifyer = styled.div<NotifyerProps>`
  font-family: latoregular;
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  background-color: ${colors.color11};
  padding: ${toRem(10)};
  margin: ${toRem(32)} 0;
  max-width: 600px;
  b {
    font-family: latobold;
  }
`;

export const Padding = styled.div`
  height: 1px;
  padding-top: ${toRem(26)};
`;
