import styled from 'styled-components';
import { Line } from 'react-chartjs-2';

import { fonts } from '../../../../../../styles/theme';

type CaptionProp = {
  color: string;
};

export const ChartStyled = styled(Line)`
  margin-top: 2rem;
`;

export const FooterStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const FooterInnerStyled = styled.div`
  display: flex;
  width: 550px;
  justify-content: space-between;
  align-items: center;
`;

export const CaptionWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
  height: 0;
`;

export const CaptionColorStyled = styled.div<CaptionProp>`
  background-color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
`;

export const CaptionTextStyled = styled.span`
  ${fonts.gotham.H12}
`;
