import styled from 'styled-components';

import { fonts, colors } from '../../../../../../styles/theme';

export const WrapperStyled = styled.div`
  padding: 1.5rem;
`;

export const TextStyled = styled.div`
  ${fonts.gotham.H14M}
  color: ${colors.color1};
  line-height: 1.5;
  margin-bottom: 1.5rem;
`;
