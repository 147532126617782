import { gql } from '@apollo/client';

export const LIST_CURRENCY_RATE = gql`
  query List($input: CurrenciesConversionV2SearchInput!) {
    currenciesConversionV2(input: $input) {
      count
      currenciesConversion {
        conversionRate
        createdAt
        currencyFrom
        currencyTo
        id
        updatedAt
      }
    }
  }
`;
