import React from 'react';
import { faFileDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper,
  Button,
  InputText,
  LoadingDots,
  Modal,
  PageTitle,
  Pagination,
  ReportPlaceholder,
  Table,
} from '../../../../components';
import { path } from '../../../../utils';
import { DefaultPropsType } from '../../../../types';

import { INVITATION_HISTORY } from './enum';
import { useInvitationHistory } from './hooks';
import { columns } from './contracts';
import InvitationSentModal from './Modal';
import * as Styled from './styles';

type InvitationHistoryProps = DefaultPropsType;

const InvitationHistory = ({ permissionsCodeList = [] }: InvitationHistoryProps) => {
  const hook = useInvitationHistory();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.invitationHistory.name}</PageTitle>

        <Styled.HeaderButtonAreaStyled>
          <Button theme="secondary" onClick={hook.hookBackNavigation}>
            {INVITATION_HISTORY.BUTTON.BACK}
          </Button>
          <Styled.ButtonStyled
            theme="tertiary"
            onClick={() => hook.hookDownloadCSV()}
            disabled={hook.hookCsvLoading}
            loading={hook.hookCsvLoading}
            width="146px"
          >
            <FontAwesomeIcon icon={faFileDownload} />
            {INVITATION_HISTORY.BUTTON.CSV}
          </Styled.ButtonStyled>
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>

      <Styled.SubtitleStyled>{INVITATION_HISTORY.SUBTITLE}</Styled.SubtitleStyled>

      <Styled.FiltersStyled>
        <InputText
          value={hook.hookSearch}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => hook.hookSetSearch(e.target.value)}
          placeholder={INVITATION_HISTORY.SEARCH_PLACEHOLDER}
          faIcon={faSearch}
          type="text"
        />

        <Styled.ExtendFiltersStyled>
          <Styled.LabelStyled>{INVITATION_HISTORY.LABEL.FILTER}</Styled.LabelStyled>
          <Styled.SelectStyled
            name="filters"
            onChange={hook.hookSetFilter}
            options={INVITATION_HISTORY.FILTER_OPTIONS}
            selected={hook.hookFilter}
          />
          <Button theme="text-only" onClick={hook.hookClearFilters}>
            {INVITATION_HISTORY.BUTTON.CLEAR_FORM}
          </Button>
        </Styled.ExtendFiltersStyled>
      </Styled.FiltersStyled>

      {hook.hookLoading && <LoadingDots />}

      {hook.hookTableData && !hook.hookLoading && (
        <>
          <Table
            columns={columns}
            data={hook.hookTableData}
            setOpenModal={hook.hookSetModalOpen}
            setModalInfo={hook.hookSetModalInfo}
            sortColumn={hook.hookSort}
            onSort={hook.hookSortHandler}
            noHorizontalScroll
          />
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookChangePage}
          />
        </>
      )}

      {!hook.hookTableData && <ReportPlaceholder submitted={false} text={INVITATION_HISTORY.REPORT_PLACEHOLDER} />}

      <Modal isOpen={hook.hookIsModalOpen}>
        <InvitationSentModal data={hook.hookModalInfo} closeFn={hook.hookSetModalOpen} />
      </Modal>
    </AppWrapper>
  );
};

export default InvitationHistory;
