import React from 'react';

import * as Styled from './styles';

type FormatedCellProps = {
  value: any;
  secondValue?: any;
};

export const FormatedCell = ({ value, secondValue }: FormatedCellProps) => (
  <Styled.CellWrapperStyled>
    {value}
    {secondValue && <Styled.SecondLineStyled>{secondValue}</Styled.SecondLineStyled>}
  </Styled.CellWrapperStyled>
);
