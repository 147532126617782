import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppWrapper, Checkbox, ErrorBanner, Modal, PageTitle, Spinner } from '../../../../components';
import { dateFormatter, path } from '../../../../utils';
import { SummaryReport } from '../Components';
import { useSummaryReport } from '../Components/SummaryReport/hooks';
import { DefaultPropsType } from '../../../../types';
import { Permission } from '../../../../entities';

import { FINTEL_CHECK_ACTIONS } from './Reducers';
import { Placeholder, PublisherChecksGraph, RuleChecksGraph, UpdateVisibility } from './Components';
import { useFintelCheckReport } from './hooks';
import * as Styled from './styles';

type FintelCheckReportProps = DefaultPropsType;

const FintelCheckReport = ({ permissionsCodeList = [] }: FintelCheckReportProps) => {
  const hook = useFintelCheckReport();
  const summaryHook = useSummaryReport();

  const [isReadOnly] = Permission.readOnlyPermissionsList(permissionsCodeList);

  return (
    <AppWrapper background permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
      <Styled.HeaderStyled>
        <PageTitle isLoading={hook.hookLoading}>{path.fintelCheckReport.name}</PageTitle>
        {hook.hookWhoAmI.isActingAsUserTypeId && (
          <>
            <Styled.StatusStyled visible={hook.hookModalInfo.visibility}>
              {hook.hookModalInfo.visibility ? 'visible' : 'invisible'}
            </Styled.StatusStyled>
            <Checkbox
              disabled={hook.hookLoading || isReadOnly}
              checked={hook.hookModalInfo.visibility}
              onChange={() => {
                if (hook.hookModalInfo.visibility) {
                  hook.hookChangeModalInfo({
                    data: hook.hookInvisibleData,
                  });
                } else {
                  hook.hookChangeModalInfo({
                    data: hook.hookVisibleData,
                  });
                }
                hook.hookModalSetIsOpen();
              }}
            />
            <aside>Set Visible</aside>
          </>
        )}
      </Styled.HeaderStyled>

      <Placeholder
        loading={hook.hookLoading || hook.hookCheckRulesLoading}
        noCheckRules={!hook.hookHasCheckRules}
        show={summaryHook.hookLastCheckOptions?.length < 1}
      />

      {summaryHook.hookLastCheckOptions?.length > 0 && (
        <>
          {hook.hookWhoAmI.companyId && !summaryHook.HIDE_CHECK_GRAPH_MERCHANTS.includes(hook.hookWhoAmI.companyId) && (
            <Styled.SectionWrapper>
              <Styled.LatestCheckStyled>
                <Styled.ButtonStyled
                  theme="quaternary"
                  onClick={() => {
                    if (hook.hookFintelCheckState.show) {
                      hook.hookFintelCheckReducer({ type: FINTEL_CHECK_ACTIONS.HIDE_CHECKS });
                    } else {
                      hook.hookFintelCheckReducer({ type: FINTEL_CHECK_ACTIONS.SHOW_CHECKS });
                    }
                  }}
                >
                  <FontAwesomeIcon icon={hook.hookFintelCheckState.icon} />
                </Styled.ButtonStyled>
                <Styled.LatestCheckTitleStyled>Latest Check Charts</Styled.LatestCheckTitleStyled>
                {hook.hookContextState.date ? (
                  <Styled.LastCheckStyled>{`Last Check: ${dateFormatter(new Date(hook.hookContextState.date), ',')}`}</Styled.LastCheckStyled>
                ) : (
                  <Styled.LastCheckStyled>
                    <Spinner theme="secondary" />
                  </Styled.LastCheckStyled>
                )}
              </Styled.LatestCheckStyled>

              <Styled.GraphWrapper show={hook.hookFintelCheckState.show}>
                <PublisherChecksGraph summaryHook={summaryHook} />
                <RuleChecksGraph summaryHook={summaryHook} />
              </Styled.GraphWrapper>
            </Styled.SectionWrapper>
          )}

          <Styled.SectionWrapper>
            <SummaryReport isMainReport hook={summaryHook} />
          </Styled.SectionWrapper>
        </>
      )}

      <Modal isOpen={hook.hookModalIsOpen}>
        <UpdateVisibility hook={hook} />
      </Modal>
    </AppWrapper>
  );
};

export default FintelCheckReport;
