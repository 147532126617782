export const compareValues = (keyName: any, order: 'asc' | 'desc' | undefined = 'asc') => {
  const innerSort = (firstValue: any, secondValue: any) => {
    if (!Object.hasOwn(firstValue, keyName)) return -1;
    if (!Object.hasOwn(secondValue, keyName)) return 1;

    const varA =
      typeof firstValue[keyName] === 'string' ? firstValue[keyName].toUpperCase().trim() : firstValue[keyName];
    const varB =
      typeof secondValue[keyName] === 'string' ? secondValue[keyName].toUpperCase().trim() : secondValue[keyName];

    let comparison = 0;

    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === 'desc' ? comparison * -1 : comparison;
  };

  return innerSort;
};
