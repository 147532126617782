import styled from 'styled-components';
import React from 'react';

import { toRem } from './toRem';

const FooterDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;

export const FooterDiv = (top: any, bottom: any) => (
  <FooterDivStyled>
    <div>{top}</div>
    <div>{bottom}</div>
  </FooterDivStyled>
);
