import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';

type InfoBoxProps = {
  title: string;
  description: string;
  description2?: string;
  descriptionTitle?: string;
  description2Title?: string;
  icon: IconProp;
  theme?: string;
  className?: string;
};

export const InfoBox = ({
  title,
  description,
  description2,
  icon,
  className,
  theme,
  description2Title,
  descriptionTitle,
}: InfoBoxProps) => (
  <Styled.WrapperStyled className={className} theme={theme}>
    <Styled.LeftSideStyled>
      <FontAwesomeIcon icon={icon} />
    </Styled.LeftSideStyled>

    <Styled.RightSideStyled>
      <Styled.TitleStyled>{title}</Styled.TitleStyled>

      {descriptionTitle && <Styled.ContentStyled>{descriptionTitle}</Styled.ContentStyled>}

      <Styled.ContentStyled>{description}</Styled.ContentStyled>

      {description2Title && <Styled.ContentStyled>{description2Title}</Styled.ContentStyled>}
      {description2 && <Styled.ContentStyled>{description2}</Styled.ContentStyled>}
    </Styled.RightSideStyled>
  </Styled.WrapperStyled>
);
