const commissionBaseFormatter = (commissionBase: string) => {
  if (commissionBase === 'GrossSales') {
    return 'gross sales';
  }
  if (commissionBase === 'NetSales') {
    return 'net sales';
  }
  return commissionBase;
};

export const payableAmount = (commission: any) => {
  let payable = '';
  const { cpaFlat, cpaTiered, referral, revShareFlat, revShareTiered, bonusFlat, bonusTiered } =
    commission.commissionStructure;

  switch (commission.commissionType) {
    case 'CPA':
      if (cpaFlat?.commissionBase) payable = `Earns $${cpaFlat.commissionFee}.`;
      if (cpaTiered?.commissionBase) {
        let num = 0;
        const tiersFormat = cpaTiered.tiers.map((tier: any) => {
          const xFormat =
            num === 0
              ? `First ${tier.upTo} earns $${tier.commissionAmount}`
              : `Next ${tier.upTo} earns $${tier.commissionAmount}`;
          num = Number(tier.upTo) + 1;
          return xFormat;
        });
        const lastCutOff = cpaTiered.cutoffAmount
          ? `, All Subsequent Transactions earn $${cpaTiered.cutoffAmount}`
          : ', All Subsequent Transactions earn $0';
        payable = tiersFormat.join(', ') + lastCutOff;
      }
      return {
        payable,
        transaction: 'Any Transaction',
      };
    case 'Referral':
      if (referral?.levels?.length > 0) {
        const levelsFormat = referral.levels.map((amount: number, index: number) => {
          const xFormat = `Level ${index + 1} earns ${amount}%`;
          return xFormat;
        });
        payable = levelsFormat.join(', ');
      }
      return {
        payable,
        transaction: 'Any Transaction',
      };
    case 'RevShare':
      if (revShareTiered.tiers.length > 0) {
        let num = 0;
        const tiersFormat = revShareTiered.tiers.map((tier: any) => {
          const xFormat =
            num === 0
              ? `Up to ${tier.upTo} earns ${tier.commissionAmount}%`
              : `${num} - ${tier.upTo} earns ${tier.commissionAmount}%`;
          num = Number(tier.upTo) + 1;
          return xFormat;
        });
        const lastCutOff = revShareTiered.cutoffAmount
          ? `, over ${num} earns ${revShareTiered.cutoffAmount}%`
          : `, over ${num} earns 0%`;
        return {
          payable: tiersFormat.join(', ') + lastCutOff,
          transaction: revShareTiered.commissionBase || 'Any Transaction',
        };
      }
      if (revShareFlat?.commissionBase) {
        const commissionBaseFormat = commissionBaseFormatter(revShareFlat.commissionBase);
        return {
          payable: `Earns flat ${revShareFlat.commissionFee}% on ${commissionBaseFormat}`,
          transaction: revShareFlat.commissionBase || 'Any Transaction',
        };
      }
      return {
        transaction: 'Any Transaction',
      };
    case 'Bonus':
      if (bonusFlat?.commissionBase) {
        const commissionBaseFormat = commissionBaseFormatter(bonusFlat.commissionBase);
        return {
          payable: `Earns $${bonusFlat.commissionFee} on ${commissionBaseFormat}`,
          transaction: bonusFlat.commissionBase || 'Any Transaction',
        };
      }
      if (bonusTiered?.commissionBase) {
        let num = 0;
        const tiersFormat = bonusTiered.tiers.map((tier: any) => {
          const xFormat =
            num === 0
              ? `Up to ${tier.upTo} earns $${tier.commissionAmount}`
              : `${num} - ${tier.upTo} earns $${tier.commissionAmount}`;
          num = Number(tier.upTo) + 1;
          return xFormat;
        });
        const lastCutOff = bonusTiered.cutoffAmount
          ? `, over ${num} earns $${bonusTiered.cutoffAmount}`
          : `, over ${num} earns $0`;
        return {
          payable: tiersFormat.join(', ') + lastCutOff,
          transaction: bonusTiered.commissionBase || 'Any Transaction',
        };
      }
      return {
        payable,
        transaction: 'Any Transaction',
      };
    default:
      return {
        payable,
        transaction: 'Any Transaction',
      };
  }
};
