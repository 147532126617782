import { faCopy, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

import { Button, InputText, Modal, Select, Table, Tooltip } from '../../../../../../components';
import type { useTransactionSettings } from '../../hooks/useTransactionSettings';

import AddTrackingSettings from './AdTrackingSettingsModal';
import * as Styled from './styles';
import { columns } from './contracts';
import { TRANSACTION_SETTINGS } from './enums';
import { DeleteTrackingSettingsModal } from './DeleteTrackingSettingsModal';

type TransactionSettingsProps = {
  hook: ReturnType<typeof useTransactionSettings>;
  setIsTracking: (isTracking: boolean) => void;
  isReadOnly: boolean;
};

const TransactionSettings = ({ hook, setIsTracking, isReadOnly }: TransactionSettingsProps) => {
  useEffect(() => {
    setIsTracking(false);
  }, []);

  return (
    <Styled.WrapperStyled>
      <Styled.RowStyled>
        <Select
          label={TRANSACTION_SETTINGS.CURRENCY.LABEL}
          name="currency"
          options={hook.hookCurrencyOptions}
          selected={hook.hookSelectedCurrency}
          onChange={hook.hookSetCurrency}
          error={hook.hookCurrencyError ? 'Please select an option from the dropdown' : ''}
          width="450px"
          isDisabled={isReadOnly}
        />

        <Button onClick={hook.hookSetModalOpen} disabled={isReadOnly}>
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
          {TRANSACTION_SETTINGS.BUTTONS.ADD_TRANSACTION_SETTINGS}
        </Button>
      </Styled.RowStyled>

      <Styled.RowStyled>
        <Styled.TableTitleStyled>
          <Styled.SectionTitleStyled>{TRANSACTION_SETTINGS.TYPE_SETTINGS.LABEL}</Styled.SectionTitleStyled>
          <Tooltip text={TRANSACTION_SETTINGS.TYPE_SETTINGS.TOOLTIP} />
        </Styled.TableTitleStyled>

        <Table
          columns={columns(isReadOnly)}
          data={hook.hookTransactionTypeSettingsData}
          setData={hook.hookSetTransactionsTypeSettingsData}
          setOpenModal={hook.hookSetDeleteModalOpen}
          setModalInfo={hook.hookSetModalInfo as any}
          isLoading={hook.hookLoading}
        />
      </Styled.RowStyled>

      <Styled.RowStyled>
        <Styled.InputTitleStyled>
          <Styled.SectionTitleStyled>{TRANSACTION_SETTINGS.AUTO_DECLINE.LABEL}</Styled.SectionTitleStyled>
          <Tooltip text={TRANSACTION_SETTINGS.AUTO_DECLINE.TOOLTIP} />
        </Styled.InputTitleStyled>
        <InputText
          name="name"
          value={hook.hookDaysToAutoDecline}
          onChange={hook.hookSetDaysToAutoDecline}
          onKeyPress={hook.hookBlockStrings}
          type="number"
          error={hook.hookAutoDeclineError ? TRANSACTION_SETTINGS.AUTO_DECLINE.ERROR : ''}
          disabled={isReadOnly}
        />
      </Styled.RowStyled>

      <Styled.RowStyled>
        <InputText
          label={TRANSACTION_SETTINGS.API_KEY.LABEL}
          name="name"
          value={hook.hookApiKey}
          type="text"
          readonly
          theme="readonlySecondary"
          width="500px"
        />

        <Styled.ButtonAreaStyled>
          <Button theme="secondary" onClick={() => hook.hookCopyValue(hook.hookApiKey)}>
            <FontAwesomeIcon icon={faCopy} style={{ marginRight: 5 }} />
            {TRANSACTION_SETTINGS.BUTTONS.COPY_KEY}
          </Button>

          <Styled.ButtonStyled theme="tertiary" onClick={hook.hookGenerateApiKey} disabled={isReadOnly}>
            {TRANSACTION_SETTINGS.BUTTONS.GENERATE_API_KEY_URL}
          </Styled.ButtonStyled>
        </Styled.ButtonAreaStyled>
      </Styled.RowStyled>

      <Styled.RowStyled>
        <InputText
          type="text"
          label={TRANSACTION_SETTINGS.API_URL.LABEL}
          value={hook.hookApiUrl}
          name="postback"
          width="800px"
          readonly
          theme="readonlySecondary"
          error=""
        />
        <Styled.SpecialButtonStyled theme="tertiary" onClick={() => hook.hookCopyValue(hook.hookApiUrl)}>
          <FontAwesomeIcon icon={faCopy} style={{ marginRight: 5 }} />
          {TRANSACTION_SETTINGS.BUTTONS.COPY_URL}
        </Styled.SpecialButtonStyled>
      </Styled.RowStyled>

      <Modal isOpen={hook.hookIsModalOpen}>
        <AddTrackingSettings
          setModalOpen={hook.hookSetModalOpen}
          tableData={hook.hookTransactionTypeSettingsData}
          setTableData={hook.hookSetTransactionsTypeSettingsData}
        />
      </Modal>
      <Modal isOpen={hook.hookIsDeleteModalOpen}>
        <DeleteTrackingSettingsModal hook={hook} />
      </Modal>
    </Styled.WrapperStyled>
  );
};

export default TransactionSettings;
