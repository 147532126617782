import { faFileDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { AppWrapper, ErrorBanner, Modal, PageTitle } from 'components';
import { RulesSummaryReport } from 'pages/Merchants/FintelCheck/Components';
import MonitoringDetailsModal from 'pages/Merchants/FintelCheck/Components/MonitoringDetailsModal';
import { RulesHistoryReport } from 'pages/Merchants/FintelCheck/Components/MonitoringHistoryReport';
import { SUMMARY } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/enums';
import { useMonitoringRulesReport } from 'pages/Merchants/FintelCheck/MonitoringRulesReport/hooks/useMonitoringRulesReport';
import { DefaultPropsType } from 'types';
import { path } from 'utils';

import RulesSummaryDashboard from '../Components/RulesSummaryDashboard';

import * as Styled from './styles';

type FintelCheckReportProps = DefaultPropsType;

const MonitoringRulesReport = ({ permissionsCodeList = [] }: FintelCheckReportProps): JSX.Element => {
  const hook = useMonitoringRulesReport();

  return (
    <AppWrapper background permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.errorMessage} isOpen={!!hook.errorMessage} />
      <Styled.HeaderStyled>
        <PageTitle>
          {hook.displaySummary ? path.monitoringRulesReport.name : path.monitoringRulesHistoryReport.name}
        </PageTitle>
      </Styled.HeaderStyled>
      <Styled.SectionWrapper>
        {hook.displaySummary ? (
          <>
            <RulesSummaryDashboard isMonitoringReport />

            <RulesSummaryReport summaryHook={hook.summaryHook} hook={hook} isMonitoringReport />
          </>
        ) : (
          <RulesHistoryReport historyHook={hook.historyHook} hook={hook} />
        )}

        <MonitoringDetailsModal hook={hook} />
        <Modal
          isOpen={hook.isScreenshotModalOpen}
          closeModalFunction={() => {
            hook.closeScreenshotModal();
          }}
        >
          {hook.getScreenshotImageLoading ? (
            <Styled.LoadingDotsStyled>
              <div> Loading...</div>
            </Styled.LoadingDotsStyled>
          ) : (
            <>
              <Styled.ScreenshotModalHeaderContainer>
                <Styled.ScreenshotModalHeaderItemLeft>Screenshot Image</Styled.ScreenshotModalHeaderItemLeft>
                <Styled.ScreenshotModalHeaderItemCenter>
                  <Styled.SimpleFlexWrapper>
                    <Styled.ButtonStyled
                      theme="tertiary"
                      onClick={() => hook.downloadScreenshot()}
                      width="160px"
                      disabled={hook.getScreenshotImageLoading}
                    >
                      <Styled.IconStyled icon={faFileDownload} />
                      {SUMMARY.DOWNLOAD_IMAGE}
                    </Styled.ButtonStyled>
                  </Styled.SimpleFlexWrapper>
                </Styled.ScreenshotModalHeaderItemCenter>
                <Styled.ScreenshotModalHeaderItemRight>
                  <Styled.DetailedViewCloseIcon icon={faTimes} onClick={() => hook.closeScreenshotModal()} />
                </Styled.ScreenshotModalHeaderItemRight>
              </Styled.ScreenshotModalHeaderContainer>
              <Styled.ScreenshotImage>
                {hook.screenshotImage ? (
                  <img src={`data:image/jpeg;base64,${hook.screenshotImage}`} alt="cover" />
                ) : (
                  <Styled.NoImage> No Image Found </Styled.NoImage>
                )}
              </Styled.ScreenshotImage>
            </>
          )}
        </Modal>
      </Styled.SectionWrapper>
    </AppWrapper>
  );
};

export default MonitoringRulesReport;
