import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { TableProps } from '../../types';
import { EditStyled } from '../../styles';
import { Button } from '../../../../../../../../../components';

export const EditCell = ({ row, setModalOpen, setModalInfo, isReadOnly }: TableProps) => (
  <EditStyled>
    <Button
      onClick={() => {
        setModalOpen();
        setModalInfo({
          profileId: row.id,
          profileName: row.profileName.name,
          profileType:
            row.trackingProfileType?.toLowerCase() !== 'website'
              ? { label: 'Other', value: 'other' }
              : { label: 'Website', value: 'website' },
          website: row.primaryWebsite,
          profileDescription: row.websiteDescription,
        });
      }}
      theme="quaternary"
      disabled={isReadOnly}
    >
      <FontAwesomeIcon icon={faPenToSquare} />
    </Button>
  </EditStyled>
);
