export const INVITATION_TEMPLATE = {
  PAGE_TITLE: {
    INVITE_NAME: 'Invite Name',
    NEW: 'New',
  },
  BUTTON: {
    DELETE: 'Delete',
    DUPLICATE: 'Duplicate',
    CANCEL: 'Cancel',
    SAVE: 'Save Template',
  },
  INPUT: {
    TEMPLATE_NAME: {
      LABEL: 'Template Name',
      PLACEHOLDER: 'Enter a template name',
    },
    DESCRIPTION: {
      LABEL: 'Description',
      PLACEHOLDER: 'Enter an optional description',
    },
    SUBJECT: {
      LABEL: 'Subject',
      PLACEHOLDER: 'Type a subject',
    },
    MERGE_FIELDS: {
      LABEL: 'Merge Fields',
      PLACEHOLDER: 'Merge Fields',
      OPTIONS: [
        {
          label: 'Merchant Company Name {{merchant}}',
          value: '{{merchant}}',
        },
        {
          label: 'Merchant Program Link {{merchantlink}}',
          value: '{{merchantlink}}',
        },
        {
          label: 'Publisher Company Name {{publisher}}',
          value: '{{publisher}}',
        },
        {
          label: 'Merchant Product Categories {{merchantproduct}}',
          value: '{{merchantproduct}}',
        },
      ],
      TOOLTIP:
        'A merge field is a placeholder in a message or template. Paste the merge field in the Subject or Message body where you want the placeholder to be used, and it will be replaced with your merchant information or the publishers details on send of the message.',
      MERGE_FIELD_COPIED: 'Merge Field copied to clipboard!',
    },
    MESSAGE: {
      LABEL: 'Message',
      PLACEHOLDER: 'Enter your message',
    },
    ERROR: {
      TEMPLATE_NAME: 'Please enter a valid template name',
      SUBJECT: 'Please enter a valid subject',
      MESSAGE: 'Please enter a valid message',
    },
  },
  TOAST: {
    CREATED_MESSAGE: 'Your template was created successfully.',
    SAVED_MESSAGE: 'Your template was saved successfully.',
  },
};
