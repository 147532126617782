import jwtDecode from 'jwt-decode';

import { IAppInitializer } from 'types';
import environment from 'config/environment';

import { Permission, PermissionType } from '../../entities';

export const permissionsInitializer: IAppInitializer = {
  name: 'permissions',
  priority: 3,
  initialize: (): void => {
    const storedPermissions: string | null = localStorage.getItem(environment.app.permissionStorageName);

    if (storedPermissions !== null) {
      const permissionsDecoded = jwtDecode<{ permissions: PermissionType[] }>(storedPermissions);
      Permission.setPermissions(permissionsDecoded.permissions);
    }
  },
};
