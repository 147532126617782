import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';

export const WrapperStyled = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessageStyled = styled.p`
  color: ${colors.color1};
  text-align: center;
  ${fonts.lato.H16};
`;
