import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query Users($companyId: String, $userType: String) {
    users(userType: $userType, companyId: $companyId) {
      users {
        id
        email
        firstName
        lastName
        position
        lastLogin
        status
        roles {
          id
          name
          type
        }
        newRoles {
          id
          name
          oldId
        }
        auth0Id
        auth0Ids
        phone
        subscribeOffers
        preferredLanguage
      }
    }
  }
`;
