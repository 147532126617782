import styled from 'styled-components';

import { colors } from '../../../../styles/theme';

export const CellWrapperStyled = styled.div`
  display: flex;
  min-height: 20px;
  align-items: center;
  text-overflow: ellipsis;
  flex-flow: wrap row;
  text-transform: capitalize;
`;

export const SecondLineStyled = styled.div`
  color: ${colors.color16};
  width: 100%;
  text-transform: lowercase;

  a {
    text-decoration: none;
    color: ${colors.color3};

    &:hover {
      color: ${colors.color2};
    }
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
`;
