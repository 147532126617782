import React from 'react';

import * as Styled from '../styles';
import { CellProps } from '../types';
import { dateFormatter } from '../../../../../../../../utils';

export const DateCell = ({ val }: CellProps) => {
  const formatted = dateFormatter(new Date(val), '/');

  return <Styled.CellStyled>{formatted}</Styled.CellStyled>;
};
