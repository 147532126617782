export const GENERATE_PAYMENT = {
  ESTIMATE_MODAL: {
    CLOSE: 'Cancel',
    CONFIRM: 'Estimate Payments',
    TITLE: 'Merchant account balance',
  },
  GENERATE_MODAL: {
    TITLE: 'Generate Payment',
    CHECKBOX: 'Notify Publisher',
    QUESTION: 'Are you sure you want to generate payment?',
    WARNING_TITLE: 'Payment reports can only be generated once a month',
    WARNING_BODY:
      'Please ensure all pending transactions for the previous month have been reviewed and processed accordingly, so that any publisher commissions due are included for the current month.',
    CANCEL: 'Cancel',
    PAYMENTS: 'Generate Payments',
  },
  DATE: 'June 2022',
  DOWNLOAD: 'Download CSV',
  ESTIMATE: 'Estimate Payments',
  GENERATE: 'Generate Payments',
  TEXT: 'Click estimate payments to get started',
  BUTTON_THEME: 'tertiary',
};

export const MAIN_TEXT = {
  ONCE: 'Payment reports can only be generated once a month.',
  PENDING:
    'Please ensure all pending transactions for the previous month have been reviewed and processed accordingly, so that any affiliate commissions due are included for the current month.',
};
