import { IAppInitializer } from 'types';
import { sentryInitializer } from 'config/initializers/sentry.initializer';
import { amchartsInitializer } from 'config/initializers/amcharts.initializer';
import { permissionsInitializer } from 'config/initializers/permissions.initializer';

const initializers: IAppInitializer[] = [sentryInitializer, amchartsInitializer, permissionsInitializer];

/**
 * Initialize the application by running all initializers in order
 */
export const initializeApp = async (): Promise<void> => {
  const sortedInitializers: IAppInitializer[] = [...initializers].sort(
    (a: IAppInitializer, b: IAppInitializer): number => (a.priority ?? Infinity) - (b.priority ?? Infinity)
  );

  await Promise.all(
    sortedInitializers.map(async (initializer: IAppInitializer): Promise<void> => {
      await initializer.initialize();
    })
  );
};
