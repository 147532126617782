import styled from 'styled-components';

import { Button, InputText, InputTextarea, Select } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 720px;
  padding: 1rem;
`;

export const CloseButtonStyled = styled(Button)`
  border: transparent;
  font-size: 1.2rem;

  &:hover {
    border: transparent;
    background: transparent;
    color: ${colors.color2};
  }
`;

export const TopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ContentStyled = styled.div`
  margin-bottom: 5px;
`;

export const LinksStyled = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -20px;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1.2rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const InputTextStyled = styled(InputText)`
  margin-bottom: 2rem;
`;

export const TextareaStyled = styled(InputTextarea)`
  margin-bottom: 2rem;
`;

export const SelectStyled = styled(Select)`
  margin-bottom: 2rem;
`;

export const AnchorStyled = styled.a`
  ${fonts.lato.H14};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
`;
