import React from 'react';

import * as Styled from '../styled';
import { CellProps } from '../types';

export const CellWrapper = ({ val }: CellProps) => (
  <Styled.CellStyled>
    <Styled.MainValStyled>{val}</Styled.MainValStyled>
  </Styled.CellStyled>
);
