import React from 'react';

import { CellWrapper, WebsiteStyled } from '../../styles';

export const CellDomain = ({ value }: any): JSX.Element => (
  <CellWrapper>
    <WebsiteStyled href={value} target="_blank">
      {value}
    </WebsiteStyled>
  </CellWrapper>
);
