import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useParams } from 'react-router-dom';
import { faChevronRight, faHome, faUser } from '@fortawesome/free-solid-svg-icons';

import { useLogOut, useUserInfo } from '../../hooks';
import { USER_TYPES_ID, path } from '../../utils';
import { Notifications } from '../Notifications';

import * as Styled from './styles';

type TopNavigationProps = {
  className?: string;
};

export const TopNavigation = ({ className }: TopNavigationProps) => {
  const { hookLogout } = useLogOut();
  const { pathname } = useLocation();
  const { hookUserInfo, hookWhoAmI } = useUserInfo();
  const params = useParams();

  const breadCrumberHandler = () => {
    const getParamArr = Object.keys(params);
    const getParamKey = getParamArr[getParamArr.length - 1];

    const breadCrumbItems = pathname.split('/').filter(Boolean);
    const lastItemIndex = breadCrumbItems.length - 1;

    const breadCrumbItemsList = breadCrumbItems.map((item, index) => {
      const pathKey = `/${breadCrumbItems.slice(0, index + 1).join('/')}`;
      if (getParamKey) {
        if (item === params[getParamKey]) {
          return <span key={pathKey} />;
        }
      }
      const page = item.replaceAll('-', ' ').split(' ');
      const pageName = page.map((word) => word[0].toUpperCase() + word.substring(1)).join(' ');
      const disabled = index === lastItemIndex;
      return (
        <Styled.BreadCrumbItemStyled key={pathKey} position={index}>
          <FontAwesomeIcon icon={faChevronRight} />
          <Styled.BreadCrumbLinkStyled to={pathKey} disabled={disabled}>
            {pageName}
          </Styled.BreadCrumbLinkStyled>
        </Styled.BreadCrumbItemStyled>
      );
    });

    let dashboardLink;

    switch (true) {
      case hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT:
        dashboardLink = '/merchant/';
        break;
      case hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER:
        dashboardLink = '/publisher/';
        break;
      default:
        dashboardLink = '/';
        break;
    }

    const toRender = [
      <Styled.BreadCrumbItemStyled key="/" position={-1}>
        <Styled.BreadCrumbLinkStyled to={dashboardLink}>
          <FontAwesomeIcon icon={faHome} />
        </Styled.BreadCrumbLinkStyled>
      </Styled.BreadCrumbItemStyled>,
      ...breadCrumbItemsList,
    ];

    return toRender;
  };

  return (
    <Styled.WrapperStyled className={className}>
      <Styled.BreadCrumbStyled>
        <Styled.BreadCrumbListStyled>{breadCrumberHandler()}</Styled.BreadCrumbListStyled>
      </Styled.BreadCrumbStyled>

      <Styled.MenuStyled>
        <Styled.MenuListStyled>
          {hookUserInfo.userTypesId === USER_TYPES_ID.ADMIN && !hookWhoAmI.isActingAsUserTypeId && (
            <Styled.MenuItemStyled>
              <Styled.MenuLinkStyled to={path.userProfileManagement.href}>
                <FontAwesomeIcon icon={faUser} />
              </Styled.MenuLinkStyled>
            </Styled.MenuItemStyled>
          )}

          <Styled.MenuItemStyled>
            <Notifications />
          </Styled.MenuItemStyled>

          <Styled.MenuItemStyled>
            <Styled.LogoutStyled onClick={() => hookLogout()}>Logout</Styled.LogoutStyled>
          </Styled.MenuItemStyled>
        </Styled.MenuListStyled>
      </Styled.MenuStyled>
    </Styled.WrapperStyled>
  );
};
