import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCheckLogin, useLogOut, useUserInfo } from '../../hooks';
import { SideMenu } from '../SideMenu/SideMenu.component';
import { TopNavigation } from '../TopNavigation';
import { MERCHANT_PREFIX, PUBLISHER_PREFIX, USER_TYPES_ID, path } from '../../utils';

import { useAppWrapper } from './hooks/useAppWrapper';
import * as Styled from './styles';

type AppWrapperProps = {
  children: React.ReactNode;
  className?: string;
  background?: boolean;
  permissionsCodeList?: string[];
  modalOpen?: boolean;
};

export const AppWrapper = ({
  children,
  className,
  background = false,
  permissionsCodeList = [],
  modalOpen = false,
}: AppWrapperProps) => {
  const { hookIsCollapsed, hookToggleCollapsed, hookCanAccess } = useAppWrapper(permissionsCodeList);
  const { hookCookie, hookRenewCookie } = useCheckLogin();
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const { hookLogout } = useLogOut();
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener('mousemove', hookRenewCookie);

    return () => {
      document.removeEventListener('mousemove', hookRenewCookie);
    };
  }, []);

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;

    if (modalOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0px';
    }
  }, [modalOpen]);

  if (!hookCookie) {
    hookLogout().then(() => navigate('/login'));
    return null;
  }

  if (!hookCookie.verified) {
    navigate('/unverified');
    return null;
  }
  if (
    hookCookie.userStatus !== 'Active' ||
    ([2, 3].includes(hookCookie.userTypesId) && ['Closed', 'Pending'].includes(hookCookie.accStatus))
  ) {
    navigate('/invalid-user');
    return null;
  }

  if (!hookCanAccess) {
    if (
      hookCookie.userTypesId === USER_TYPES_ID.MERCHANT ||
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT
    ) {
      navigate(`${MERCHANT_PREFIX}${path.dashboard.href}`);
      return null;
    }

    if (
      hookCookie.userTypesId === USER_TYPES_ID.PUBLISHER ||
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER
    ) {
      navigate(`${PUBLISHER_PREFIX}${path.dashboard.href}`);
      return null;
    }

    navigate(path.dashboard.href);
    return null;
  }

  return (
    <Styled.WrapperStyled isCollapsed={hookIsCollapsed} className={className}>
      <Styled.LeftStyled>
        <SideMenu isCollapsed={hookIsCollapsed} collapseFn={hookToggleCollapsed} />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.RightTopStyled>
          <TopNavigation />
        </Styled.RightTopStyled>

        <Styled.RightContentStyled background={background}>{hookCanAccess && children}</Styled.RightContentStyled>
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};
