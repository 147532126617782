export const generateMonthList = (noAll = false) => {
  const today = new Date();
  const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  const months = [];
  if (!noAll) months.push({ label: 'All Periods', value: 'All Periods' });
  for (let year = lastMonth.getFullYear(); year >= 2000; year -= 1) {
    const startMonth = year === lastMonth.getFullYear() ? lastMonth.getMonth() : 11;
    for (let month = startMonth; month >= 0; month -= 1) {
      const label = `${new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(year, month, 1))}`;
      const value = `${(month + 1).toString().padStart(2, '0')}${year}`;
      months.push({ label, value });
    }
  }

  return months;
};
