import React from 'react';

import { TableProps } from '../types';
import * as Styled from '../styles';
import { Checkbox } from '../../../../../../../components';
import { SIGN_UP_ACTIONS } from '../../../../../reducers';

export const JoinCell = ({ val, row, tableData, setTableData, setContext }: TableProps) => {
  const handleChange = (checked: boolean) => {
    const tableDataCopy = [...tableData];
    tableDataCopy[row.index].join = checked;
    tableDataCopy[row.index].newsletter = false;
    setTableData(tableDataCopy);
    setContext({
      type: SIGN_UP_ACTIONS.SET_PROGRAM_APPLICATIONS,
      data: {
        programId: tableDataCopy[row.index].id,
        merchantId: tableDataCopy[row.index].merchantId,
        apply: checked,
        subscription: false,
      },
    });
  };
  return (
    <Styled.CellStyled>
      <Checkbox label="Apply" checked={val} onChange={() => handleChange(!val)} />
    </Styled.CellStyled>
  );
};
