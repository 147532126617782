import styled from 'styled-components';

import { colors, fonts } from '../../../../styles/theme';
import { InputText, InputTextarea, Select } from '../../../Input';
import { toRem } from '../../../../utils';
import { Button } from '../../../Button';

export const ContentStyled = styled.div`
  display: grid;
  width: 830px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 2rem;
  padding-top: 2rem;
`;

export const Input4Column = styled(InputText)`
  grid-column: span 4;
`;

export const CodeInput = styled(InputTextarea)`
  width: 90%;
  margin-top: 2rem;
`;

export const Select2Column = styled(Select)`
  grid-column: span 2;
`;

export const CodeWrapper = styled.div`
  grid-column: span 4;
`;

export const GeneratedCodeInner = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

export const InputTextArea2Columns = styled(InputTextarea)`
  width: 90%;
`;

export const RadioAreaStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioTitleStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12B}
`;

export const RadioWrappedStyled = styled.div`
  margin-bottom: 0.5rem;

  span {
    margin-left: 0.5rem;
    color: ${colors.color1};
  }
`;

export const FooterAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column: span 4;
`;

export const CopyButtonStyled = styled(Button)`
  margin-left: 1rem;
`;
