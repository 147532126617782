import { gql } from '@apollo/client';

export const LIST_SEARCH_AD = gql`
  query ListAds($input: ListSearchAdInput) {
    listSearchAds(input: $input) {
      ads {
        adName
        id
        adType
        adContent
        height
        width
        productName
        productCategory
        campaignName
        merchantName
        defaultLandingPage
        createdAt
      }
      count
      totalPages
    }
  }
`;
