import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { AppWrapper, PageTitle, Pagination, RecordsDropdown } from '../../../components';
import { Table } from '../../../components/Table';
import { USER_TYPES_ID, CLEAR_FORM, RECORDS_PER_PAGE_OPTIONS } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import { useCampaignManagement } from './hooks';
import { CAMPAIGN } from './enums';
import * as Styled from './styles';

type CampaignManagementPropsType = DefaultPropsType;

const CampaignManagement = ({ permissionsCodeList = [] }: CampaignManagementPropsType) => {
  const { hookIsReadOnlyList, ...hook } = useCampaignManagement(permissionsCodeList);
  const [isReadonly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <Styled.HeaderStyled>
          <PageTitle loadingText={CAMPAIGN.LOADING} isLoading={hook.hookLoading}>
            {CAMPAIGN.TITLE}
          </PageTitle>

          {hook.hookDisplayButton(
            hook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ||
              hook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT,
            isReadonly
          )}
        </Styled.HeaderStyled>

        <Styled.FiltersStyled>
          <Styled.InputTextStyled
            type="text"
            placeholder={CAMPAIGN.SEARCH}
            faIcon={faSearch}
            name="search"
            onChange={hook.hookSetSearch}
            label="Keyword Search"
            value={hook.hookSearch}
          />
          <Styled.ButtonStyled theme="text-only" onClick={hook.hookClearForm}>
            {CLEAR_FORM}
          </Styled.ButtonStyled>
        </Styled.FiltersStyled>

        <Styled.RecordsStyled>
          <RecordsDropdown
            selected={hook.hookRecords}
            options={RECORDS_PER_PAGE_OPTIONS}
            currentPage={hook.hookCurrentPage}
            totalRecords={hook.hookTotalRecords}
            onChange={hook.hookSetRecords}
          />
        </Styled.RecordsStyled>

        <Table
          columns={hook.hookTableColumns()}
          data={hook.hookTableData}
          isLoading={hook.hookLoading}
          onSort={hook.hookSetSortBy}
          sortColumn={hook.hookSortBy}
          onRowClick={hook.hookHandleRowClickActAs}
        />

        <Pagination
          total={hook.hookTotalPages}
          currentPage={hook.hookCurrentPage}
          onPageChange={hook.hookSetCurrentPage}
        />
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default CampaignManagement;
