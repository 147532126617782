import { gql } from '@apollo/client';

export const PUBLISHERS_FOR_INVITATION = gql`
  query PublishersForInvitation($input: PublisherForInvitationInput!) {
    publishersForInvitationV2(input: $input) {
      PublisherForInvitation {
        id
        companyName
        invitesOnHold
        country
        activityStatus
        overview {
          primaryPlatformLink
          hasWebsite
          audienceMarkets
          avgMonthlyAudience
          promotionMethods
        }
        tracking {
          primaryWebsite
        }
        membership {
          id
          status
          statusLastUpdatedBy
          updatedAt
          publisherInvitation {
            id
          }
        }
      }
      filterValues {
        audienceMarkets
        gender
        audienceAges
        creditScoreRating
      }
      count
    }
  }
`;
