import { ChangeEvent, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { LIST_USERS } from '../graphql/queries';
import { RECORDS_PER_PAGE_OPTIONS, USER_STATUS, path } from '../../../../utils';
import { useDebounce } from '../../../../utils/useDebounce';
import { useConfirmationModal } from '../../../../components/Modal/ConfirmationModal/hooks';
import { useToast } from '../../../../hooks';
import { USE_USER_MANAGEMENT_HOOK } from '../enum';
import { UPDATE_USER_INFO } from '../../graphql/mutations';
import { Permission } from '../../../../entities';

type UsersListItem = {
  id: string;
  email: string;
  auth0Id: string;
  firstName: string;
  lastName: string;
  lastLogin: string;
  status: string;
  newRoles: {
    id: string;
    name: string;
  };
};
const userStatusValues = {
  active: 'Active',
  inactive: 'Inactive',
};

type ListUsersReturnType = {
  count: number;
  users: UsersListItem[];
};

const convertToUserStatus = (input: string): string[] => {
  if (input === 'any') return [userStatusValues.active, userStatusValues.inactive];
  return [input];
};

export const useUserManagement = (permissionsCodeList: string[] = []) => {
  const [search, setSearch] = useState('');
  const [userList, setUserList] = useState<UsersListItem[]>([]);
  const [recordsSelected, setRecordsSelected] = useState(RECORDS_PER_PAGE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [toggle, setToggle] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const debouncedSearch = useDebounce(search, 800);
  const [statusSelected, setStatusSelected] = useState<SelectOption>(USER_STATUS[0]);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [modalUser, setModalUser] = useState<UsersListItem>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const { hookShowToast } = useToast();
  const navigate = useNavigate();

  const handleSetSelectedStatus = (value: SelectOption) => {
    setStatusSelected(value);
  };

  const [getUsers, { loading }] = useLazyQuery(LIST_USERS, {
    fetchPolicy: 'no-cache',
  });

  const [updateUserInfo] = useMutation(UPDATE_USER_INFO);
  const setRecordsSelectedHandler = (selected: any) => {
    setCurrentPage(1);
    setRecordsSelected(selected);
  };

  const setSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const setUserListHandler = async () => {
    setErrorMessage('');
    setLoadingMessage('Loading Users');
    const { data, error } = await getUsers({
      variables: {
        input: {
          currentPage,
          limit: Number.parseInt(recordsSelected.value, 10),
          status: convertToUserStatus(statusSelected.value),
          sortOrder: sortColumn?.direction === 'asc' ? -1 : 1,
          sortBy: sortColumn?.column || 'firstName',
          filter: search,
        },
      },
      onError(err) {
        setLoadingMessage('');
        setErrorMessage(err.message);
        setUserList([]);
      },
    });
    setLoadingMessage('');
    if (error) {
      setUserList([]);
      setErrorMessage(error.message);
      return;
    }
    const userData: ListUsersReturnType = data.usersList;
    if (userData) {
      setUserList(userData.users);
      setTotalPages(Math.ceil(userData.count / Number.parseInt(recordsSelected.value, 10)));
      setTotalRecords(userData.count);
    }
  };

  const updateUserStatusHandler = async (
    userId: string | undefined,
    auth0Id: string | undefined,
    newStatus: boolean
  ) => {
    //  Mutation to update the users status
    if (userId) {
      setErrorMessage('');
      const status = newStatus ? userStatusValues.active : userStatusValues.inactive;

      const { errors } = await updateUserInfo({
        variables: {
          input: {
            id: userId,
            status,
          },
        },
        onError(err) {
          setErrorMessage(err.message);
        },
      });
      setUserList((prevUserList) => prevUserList.map((user) => (user.id === userId ? { ...user, status } : user)));
      setLoadingMessage('');
      if (errors) {
        setErrorMessage(errors[0]?.message);
        return;
      }
      // Refetchs the userList data.
      if (newStatus) hookShowToast(USE_USER_MANAGEMENT_HOOK.ACTIVATED_MESSAGE);
      else hookShowToast(USE_USER_MANAGEMENT_HOOK.DEACTIVATED_MESSAGE);
    }
  };

  const onConfirm = () => {
    updateUserStatusHandler(modalUser?.id, modalUser?.auth0Id, false);
  };

  const handleDeactivation = useConfirmationModal(onConfirm, undefined);

  const setToggleHandler = (input: UsersListItem) => {
    if (input.status === userStatusValues.inactive) {
      updateUserStatusHandler(input.id, input.auth0Id, true);
      return;
    }
    handleDeactivation.hookSetIsOpen(true);
    setToggle(true);
    setModalUser(input);
    setIsActive(true);
  };

  const handleSetModalOpen = (value: boolean) => {
    if (toggle && isActive) handleDeactivation.hookSetIsOpen(value);
  };

  const changePageHandler = (pageValue: number) => {
    setCurrentPage(pageValue);
  };

  const onSortHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    setSortColumn({
      column,
      direction: direction === 'desc' ? 'asc' : 'desc',
    });
  };

  const navigateProfile = (user: string) => {
    navigate(path.userAccountManagement.href, {
      state: {
        userId: user,
      },
    });
  };

  const clearFormHandler = () => {
    setSearch('');
    handleSetSelectedStatus(USER_STATUS[0]);
  };

  useEffect(() => {
    setUserListHandler();
  }, [recordsSelected, currentPage, debouncedSearch, statusSelected, sortColumn]);

  useEffect(() => {
    setCurrentPage(1);
  }, [recordsSelected, debouncedSearch, statusSelected, sortColumn]);

  return {
    hookUserList: userList,
    hookSetUserList: setUserListHandler,

    hookRecords: recordsSelected,
    hookSetRecordsSelected: setRecordsSelectedHandler,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookTotalRecords: totalRecords,
    hookChangePage: changePageHandler,

    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookToggle: toggle,
    hookSetToggle: setToggleHandler,

    hookIsLoading: loading,
    hookLoadingMessage: loadingMessage,

    hookDeact: {
      hookOnClose: handleDeactivation.hookOnClose,
      hookOnConfirm: handleDeactivation.hookOnConfirm,
      isOpen: handleDeactivation.hookIsOpen,
      handleSetModalOpen,
    },
    hookModalUser: modalUser,
    hookStatus: isActive,

    hookStatusSelected: statusSelected,
    hookHandleSetSelectedStatus: handleSetSelectedStatus,
    hookUserStatusList: USER_STATUS,

    hookOnSortHandler: onSortHandler,
    hookSortColumn: sortColumn,
    hookNavigateProfile: navigateProfile,
    hookClearFormHandler: clearFormHandler,

    hookErrorMessage: errorMessage,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
