import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useUserInfo } from '../../../../../../hooks';
import { CHECK_IF_NEW_USER } from '../graphql/queries/checkIfNewUser';
import { USER_TYPES_ID } from '../../../../../../utils';
import { UPDATE_NEW_USER_STATE } from '../graphql/mutations/updateNewUserState';

export const usePublisherJoyride = () => {
  const { hookUserInfo } = useUserInfo();
  const [runTutorial, setRunTutorial] = useState<boolean>(false);
  const [auth0Id, setAuth0Id] = useState<string>('');

  const [checkNewUser] = useLazyQuery(CHECK_IF_NEW_USER);
  const [updateNewUser] = useMutation(UPDATE_NEW_USER_STATE);

  const checkNewUserHandler = async () => {
    const { data } = await checkNewUser({
      variables: {
        userId: hookUserInfo.id?.toString() || '',
      },
      fetchPolicy: 'no-cache',
    });
    if (data && data.user) {
      if (data.user.newUser) setRunTutorial(true);
      if (data.user.auth0Id) setAuth0Id(data.user.auth0Id);
    }
  };

  const updateNewUserStateHandler = async () => {
    updateNewUser({
      variables: {
        input: {
          auth0Id,
          newUser: false,
        },
      },
    });
  };

  const tutorialStep = (stepData: any) => {
    const { action, status, index, step } = stepData;

    if (action === 'reset') {
      // Tutorial finished
      updateNewUserStateHandler();
    }
    if (action === 'update') {
      // expand Account menu
      if (status === 'running' && index === 1) {
        const accountDiv = document.querySelector(step.target) as HTMLElement;
        const accountDivChildren = accountDiv.children;
        const accountButton = accountDivChildren.item(0) as HTMLElement;
        if (accountButton) accountButton.click();
      }
    }
  };

  useEffect(() => {
    if (hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER) {
      checkNewUserHandler();
    }
  }, []);

  return {
    hookTutorialStep: tutorialStep,
    hookRunTutorial: runTutorial,
  };
};
