import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { LIST_PUBLISHER_GROUP } from '../graphql/queries';
import { useUserInfo } from '../../../../../hooks';
import { Permission } from '../../../../../entities';

export const usePublisherGroup = (permissionsCodeList: string[] = []) => {
  const { hookWhoAmI } = useUserInfo();
  const [getPublisherGroups, { loading }] = useLazyQuery(LIST_PUBLISHER_GROUP);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const setTableDataHandler = (publisherGroups: any) => {
    const formatter = publisherGroups.map((item: any) => ({
      id: item.id,
      groupName: item.name,
      description: item.description,
      members: item.memberships.length,
      date: new Date(item.createdAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
    }));
    setTableData(formatter);
  };

  const setPublisherGroupsHandler = async () => {
    const { data } = await getPublisherGroups({
      variables: {
        input: {
          filter: {
            programId: hookWhoAmI.programId,
          },
          options: {
            page: currentPage,
          },
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.listPublisherGroups?.groups) {
      setTableDataHandler(data.listPublisherGroups.groups);
    }

    if (data?.listPublisherGroups?.count) {
      setTotalPages(Math.ceil(data.listPublisherGroups.count / 10));
    }
  };

  const changePageHandler = (pageValue: number) => {
    setCurrentPage(pageValue);
  };

  useEffect(() => {
    setPublisherGroupsHandler();
  }, [hookWhoAmI.companyId, currentPage]);

  return {
    hookLoading: loading,
    hookData: tableData,
    hookSetPublisherGroups: setPublisherGroupsHandler,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookChangePage: changePageHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
