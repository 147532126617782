import styled from 'styled-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { toRem, path, dateFormatter, PUBLISHER_PREFIX } from '../../../../../../utils';
import { Button, ManagementThumbnails } from '../../../../../../components';

const LongTextWrapStyled = styled.div`
  max-width: 100%;
  white-space: pre-wrap;
  line-height: 1.5;
`;

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;

const ActionCell = ({ row }: { row: any }) => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${PUBLISHER_PREFIX}${path.productDetails.href}`, { state: row?.productId });
  };
  return (
    <CellWrapper>
      <Button theme="quaternary" onClick={goTo}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </CellWrapper>
  );
};

const LongTextFormat = (value: any) => <LongTextWrapStyled>{value}</LongTextWrapStyled>;

export const columns: TableColumn[] = [
  {
    dataField: 'id',
    text: 'product id',
    formatter: (value: any, row: any) => <ManagementThumbnails value={value} url={row.productImageUrl} />,
    width: '200px',
  },
  {
    dataField: 'merchant',
    text: 'Merchant',
    width: '16%',
  },
  {
    dataField: 'productName',
    text: 'product name',
    width: '18%',
  },
  {
    dataField: 'productCategory',
    text: 'product category',
    width: '16%',
  },
  {
    dataField: 'subcategories',
    text: 'subcategories',
    formatter: (value: any) => LongTextFormat(value),
    width: '16%',
  },
  {
    dataField: 'lastUpdated',
    text: 'last update',
    formatter: (value: any) => <div>{dateFormatter(value)}</div>,
    width: '16%',
  },
  {
    dataField: 'productId',
    text: '',
    formatter: (_: any, row: any) => <ActionCell row={row} />,
    width: '2%',
  },
];
