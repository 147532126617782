import styled from 'styled-components';

import { colors } from '../../styles/theme';

type ImageProps = {
  useType?: string;
};

export const ImagesWrapper = styled.span<ImageProps>`
  display: flex;
  flex-direction: column;
  ${({ useType }) =>
    useType === 'dropZone' &&
    `
    height: 60px;
    align-items: center;
  `}
`;

export const ImageStyled = styled.img<ImageProps>`
  height: 60px;
  ${({ useType }) =>
    useType === 'table' &&
    `
    height: 40px;
    object-fit: cover;
  `}
`;

export const EnlargedImageWrapper = styled.span<ImageProps>`
  position: relative;
  width: 100vw;
  padding-left: ${({ useType }) => (useType === 'dropZone' ? '50vw' : '0')};
`;

export const EnlargedImageStyled = styled.img`
  background-color: ${colors.color4};
  position: absolute;
  width: auto;
  bottom: 45px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;
