import { gql } from '@apollo/client';

export const CREATE_PUBLSIHER_INVITATION = gql`
  mutation createPublisherInvitation($input: PublisherInvitationInput!) {
    createPublisherInvitation(input: $input) {
      id
      merchantId
      name
      description
      subject
      message
      template
    }
  }
`;
