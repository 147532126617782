import React from 'react';

import { Button } from '../../../../../components';
import { BUTTON_LABEL, SUBTITLE, balanceStatsColumn, columns } from '../../contracts';
import * as Styled from '../../styles';

type AccountBalanceStatsProps = {
  accountBalanceStats: AccountBalanceStats[];
  isEnableNewStats: boolean;
  handleNewStatClick: () => void;
  isReadOnly: boolean;
};

export const AccountBalanceStats = ({
  accountBalanceStats,
  isEnableNewStats,
  handleNewStatClick,
  isReadOnly,
}: AccountBalanceStatsProps) => (
  <div>
    <Styled.SubsectionStyled>
      <Styled.SubtitleStyled>{SUBTITLE.STATS}</Styled.SubtitleStyled>
      <Button onClick={handleNewStatClick} theme="tertiary" disabled={isReadOnly}>
        {BUTTON_LABEL.UPDATE_STATS}
      </Button>
    </Styled.SubsectionStyled>

    <Styled.TableStyled columns={isEnableNewStats ? columns : balanceStatsColumn} data={accountBalanceStats} />
  </div>
);
