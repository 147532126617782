import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useMerchantAddress, useModal, useTaxation, useToast, useUserInfo } from 'hooks';
import { dateFormatter, optionsMaker, SUCCESS_MESSAGES } from 'utils';
import environment from 'config/environment';
import { useValidation } from 'utils/validation';
import { colors } from 'styles/theme';
import { UPDATE_USER } from 'components/ManageUser/graphql/mutations/updateUser';
import { copyToClipboard } from 'utils/copyToClipboard';
import { formatAmount } from 'utils/amountFormatter';

import { GET_COMPANY } from '../graphql/queries/getCompany';
import {
  TRANSACTION_CURRENCY,
  validationFields,
  paymentValidationFields,
  CURRENCY_OPTIONS,
  paymentMethodMinimums,
  paypalValidationFields,
  chequeValidationFields,
  wireTransferValidationFields,
} from '../contracts';
import { GET_COUNTRIES } from '../graphql/queries/getCountries';
import { GET_MEMBERSHIPS } from '../graphql/queries/getMemberships';
import { GET_USERS } from '../graphql/queries/getUsers';
import { UPDATE_COMPANY } from '../graphql/mutations/updateCompany';
import { CREATE_NEW_API } from '../graphql/mutations/generateNewApiKey';
import { UPDATE_API } from '../graphql/mutations/updateApiAccessKey';
import { Permission } from '../../../../../entities';
import { sortUsersList } from '../utils';

type TRoleType = { id: number; name: string; type: string };

export const useAccountDetails = (isAdmin: boolean, permissionsCodeList: string[] = []) => {
  const { state } = useLocation();
  const { hookShowToast } = useToast();
  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const validationHook = useValidation();

  const merchantAddressHook = useMerchantAddress();
  const taxationHook = useTaxation();

  const [errorOpen, setErrorOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [publisherID, setPublisherID] = useState('');
  const [publisherName, setPublisherName] = useState('');
  const [publisherEmail, setPublisherEmail] = useState('');
  const [accountCreatedDate, setAccountCreatedDate] = useState('');
  const [publisherPhoneNumber, setPublisherPhoneNumber] = useState('');
  const [publisherPhoneNumberTwo, setPublisherPhoneNumberTwo] = useState('');
  const [publisherPaymentInfosEmail, setPublisherPaymentInfosEmail] = useState('');
  const [publisherActivityStatus, setPublisherActivityStatus] = useState('');
  const [publisherAccountStatus, setPublisherAccountStatus] = useState<SelectOption>({ label: '', value: '' });
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentCurrency, setPaymentCurrency] = useState('');
  const [securityRole, setSecurityRole] = useState<TRoleType[]>();

  const [apiKey, setApiKey] = useState('');
  const [apiKeyStatus, setApiKeyStatus] = useState<SelectOption | undefined>(undefined);
  const [transactionApiUrl, setTransactionApiUrl] = useState('');
  const [clickApiUrl, setClickApiUrl] = useState('');
  const [trackingScript, setTrackingScript] = useState('');
  const [userList, setUserList] = useState<any>([]);
  const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);
  const [regionOptions, setRegionOptions] = useState<SelectOption[]>([]);
  const [allRegions, setAllRegions] = useState<any>([]);
  const [apiCreatedAt, setApiCreatedAt] = useState('');
  const [error, setError] = useState('');

  // Payment Details
  const [selectedCurrency, setSelectedCurrency] = useState<SelectOption>();
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<SelectOption>();
  // Wire Transfer
  const [bankName, setBankName] = useState('');
  const [bankAccountNumber, setBankAccountNumbner] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [transit, setTransit] = useState('');
  const [wireTransferName, setWireTransferName] = useState('');
  // Paypal
  const [accountHolder, setAccountHolder] = useState<string>('');
  const [accountEmail, setAccountEmail] = useState<string>('');
  const [accountPhoneNumber, setAccountPhoneNumber] = useState<string>('');
  // Cheque
  const [payableTo, setPayableTo] = useState<string>('');

  const [keyCopied, setKeyCopied] = useState('Copy Key');
  const [transactionCopied, setTransactionCopied] = useState('Copy URL');
  const [apiUrlCopied, setApiUrlCopied] = useState('Copy URL');
  const [trackingScriptCopied, setTrackingScriptCopied] = useState('Copy Script');

  const handleCopyKey = () => {
    copyToClipboard(apiKey);
    setKeyCopied('Copied Key');
  };

  const handleTransactioCopy = () => {
    copyToClipboard(transactionApiUrl);
    setTransactionCopied('Copied URL');
  };

  const handleApiCopied = () => {
    copyToClipboard(clickApiUrl);
    setApiUrlCopied('Copied URL');
  };

  const handleTrackingCopied = () => {
    copyToClipboard(trackingScript);
    setTrackingScriptCopied('Copied Script');
  };

  const [sameChecked, setSameChecked] = useState(false);

  const [paymentAddress2, setpaymentAddress2] = useState('');
  const [paymentCity, setPaymentCity] = useState('');
  const [paymentRegion, setPaymentRegion] = useState<SelectOption>();
  const [paymentRegionOptions, setPaymentRegionOptions] = useState<SelectOption[]>();
  const [paymentCountry, setPaymentCountry] = useState<SelectOption>();
  const [paymentPostal, setPaymentPostal] = useState('');
  const [paymentPrimaryPhone, setPaymentPrimaryPhone] = useState('');
  const [paymentSecondaryPhone, setPaymentSecondaryPhone] = useState('');
  const [paymentEmail, setPaymentEmail] = useState('');
  const [paymentAddress, setPaymentAddress] = useState('');

  const handlePaymentAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentAddress(e.target.value);
  };

  const handlePaymentAddress2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setpaymentAddress2(e.target.value);
  };

  const handlePaymentCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentCity(e.target.value);
  };

  const handlePaymentRegion = (value: any) => {
    setPaymentRegion(value);
  };

  const handlePaymentCountry = (value: any) => {
    setPaymentRegion({ label: '', value: '' });
    setPaymentRegionOptions(
      optionsMaker(
        allRegions.filter((obj: any) => obj.name === value.label)[0].subdivisions.map((obj: any) => obj.name)
      )
    );
    setPaymentCountry(value);
  };

  const handlePaymentPostal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentPostal(e.target.value);
  };

  const handlePrimaryPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentPrimaryPhone(e === undefined || e.toString() === '' ? '' : e.toString());
  };

  const handleSecondaryPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentSecondaryPhone(e === undefined || e.toString() === '' ? '' : e.toString());
  };

  const handlePaymentEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentEmail(e.target.value);
  };

  const handleTaxationString = (e: React.ChangeEvent<HTMLInputElement>) => {
    taxationHook.hookSetStringTaxation(e.target.value);
    taxationHook.hookSetTaxationStatus('Unverfied');
  };

  const handleSelectedCurrency = (value: any) => {
    setSelectedCurrency(value);
  };

  const [minAmount, setMinAmount] = useState('');

  const handlePaymentMethodSelected = (value: any) => {
    setPaymentMethodSelected(value);
    setMinAmount(formatAmount(paymentMethodMinimums(value.label, selectedCurrency?.value)));
  };

  const handleBankName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBankName(e.target.value);
  };

  const handleBankAccountNumbner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBankAccountNumbner(e.target.value);
  };
  const handleSwift = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSwiftCode(e.target.value);
  };
  const handleTransit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransit(e.target.value);
  };
  const handleWireTransferName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWireTransferName(e.target.value);
  };
  const handleAccountHolder = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountHolder(e.target.value);
  };
  const handleAccountEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountEmail(e.target.value);
  };
  const handleAccountPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountPhoneNumber(e === undefined || e.toString() === '' ? '' : e.toString());
  };
  const handlePayableTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayableTo(e.target.value);
  };
  const [getUsers, { loading: getUsersLoading }] = useLazyQuery(GET_USERS);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>();

  const handleTableSort = (dataField: string, direction: any) => {
    const newDirection = sortColumn?.direction === 'asc' && direction !== undefined ? 'desc' : 'asc';
    setSortColumn({ column: dataField, direction: newDirection });
    const newUserList = sortUsersList(userList, dataField, newDirection);
    setUserList(newUserList);
  };

  const [merchantMemberships, setMerchantMemberships] = useState<any[]>([]);
  const [getMemberships, { loading: getMembershipsLoading }] = useLazyQuery(GET_MEMBERSHIPS);

  const fetchMemberships = async () => {
    const { data } = await getMemberships({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId?.toString(),
          status: 'Approved',
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.memberships !== undefined) {
      setMerchantMemberships(data.memberships.memberships.map((obj: any) => obj.merchant));
    }
  };

  const [selectedRegion, setSelectedRegion] = useState<SelectOption | undefined>(undefined);
  const [manageUserModal, setManageUserModal] = useModal(false);
  const [validOnBlur, setValidOnBlur] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSelf, setIsSelf] = useState(false);
  const [hookUser, setUser] = useState({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    phone: '',
    lang: '',
    position: '',
    subscriptionOffers: false,
    id: '',
    roleId: '',
    auth0Id: '',
  });

  const [getCompany, { loading: getCompanyLoading }] = useLazyQuery(GET_COMPANY);
  const [getCountries, { loading: getCountriesLoading }] = useLazyQuery(GET_COUNTRIES);

  const [deactivateModalOpen, setDeactivateModal] = useState(false);

  const handleDeactivateModal = () => {
    setDeactivateModal(!deactivateModalOpen);
  };

  const selectedFormat = (value: string): SelectOption => ({
    label: value,
    value,
  });

  const setTransactionApiUrlHandler = (key: string) => {
    setTransactionApiUrl(
      `${environment.api.adsBasePath}/reports/transactions/acckey=${key}&publisherId=${publisherID}`
    );
  };

  const setClickApiUrlHandler = (key: string) => {
    setClickApiUrl(`${environment.api.adsBasePath}/reports/click-acid/acckey=${key}&publisherId=${publisherID}`);
  };

  const setTrackingScriptHandler = (newPublisherId?: string) => {
    if (!newPublisherId) {
      setTrackingScript('');
    } else {
      setTrackingScript(`<script defer src='${environment.api.pageViewScripts}'></script>
    <script>window.fcstat = window.fcstat || function() { (window.fcstat.q = window.fcstat.q || []).push(arguments) }; fcstat(${newPublisherId});</script>`);
    }
  };

  const handleChangeCountries = (value: any) => {
    setSelectedRegion({ label: '', value: '' });
    setRegionOptions(
      optionsMaker(
        allRegions.filter((obj: any) => obj.name === value.label)[0].subdivisions.map((obj: any) => obj.name)
      )
    );
    merchantAddressHook.hookSetCountry(value);
    if (sameChecked) handlePaymentCountry(value);
  };

  const getPublisherDetails = async () => {
    try {
      let selectedPublisher;

      if (state?.publisherId !== undefined) {
        selectedPublisher = state.publisherId;
      } else {
        selectedPublisher = hookWhoAmI.companyId?.toString();
      }

      const { data } = await getCompany({
        variables: {
          companyId: selectedPublisher,
        },
        fetchPolicy: 'no-cache',
      });

      if (data?.company !== undefined) {
        const { company } = data;
        // Check if Payment Contact Information Checkbox should be checked
        const checked =
          (company.paymentInfo?.address1 || '') === (company.address1 || '') &&
          (company.paymentInfo?.address2 || '') === (company.address2 || '') &&
          (company.paymentInfo?.city || '') === (company.city || '') &&
          (company.paymentInfo?.state || '') === (company.state || '') &&
          (company.paymentInfo?.country || '') === (company.country || '') &&
          (company.paymentInfo?.countryOther || '') === (company.countryOther || '') &&
          (company.paymentInfo?.zip || '') === (company.zip || '') &&
          (company.paymentInfo?.phone || '') === (company.phone || '') &&
          (company.paymentInfo?.phone2 || '') === (company.phone2 || '') &&
          (company.paymentInfo?.email || '') === (company.companyEmail || '');
        setSameChecked(checked);

        setPaymentAddress(company.paymentInfo.address1);
        setpaymentAddress2(company.paymentInfo.address2);
        setPaymentCity(company.paymentInfo.city);
        setPaymentRegion(selectedFormat(company.paymentInfo.state));
        setPaymentCountry(selectedFormat(company.paymentInfo.country));
        setPaymentPostal(company.paymentInfo.zip);
        setPaymentPrimaryPhone(company.paymentInfo.phone);
        setPaymentSecondaryPhone(company.paymentInfo.phone2);
        setPaymentEmail(company.paymentInfo.email);

        setPublisherID(selectedPublisher);
        setPublisherName(company.companyName);
        setPublisherEmail(company.companyEmail);

        merchantAddressHook.hookSetStringAddress(company.address1);
        merchantAddressHook.hookSetStringAddressTwo(company.address2);
        merchantAddressHook.hookSetStringCity(company.city);
        merchantAddressHook.hookSetStringPostalCode(company.zip);

        merchantAddressHook.hookSetRegion(selectedFormat(company.state));
        merchantAddressHook.hookSetCountry(selectedFormat(company.country));
        setSelectedRegion(selectedFormat(company.state));

        // Paypal
        setAccountHolder(company.paymentInfo.accountHolder);
        setAccountEmail(company.paymentInfo.accountEmail);
        setAccountPhoneNumber(company.paymentInfo.accountPhoneNumber);
        // Cheque
        setPayableTo(company.paymentInfo.payableTo);
        // Wire Transfer
        setBankName(company.paymentInfo.bankName);
        setBankAccountNumbner(company.paymentInfo.bankAccountNumber);
        setSwiftCode(company.paymentInfo.bankCode);
        setTransit(company.paymentInfo.transit);
        setWireTransferName(company.paymentInfo.wireTransferName);

        setPublisherPhoneNumber(company.phone);

        setMinAmount(formatAmount(paymentMethodMinimums(company.paymentInfo.payment, company.paymentInfo.currency)));

        setPublisherActivityStatus(company.activityStatus);

        setAccountCreatedDate(
          new Date(company.createdAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })
        );

        setPublisherAccountStatus(selectedFormat(company.accountStatus));

        setPublisherPaymentInfosEmail(company.paymentInfo.accountEmail);
        setPaymentMethod(company.paymentInfo.payment);
        setPaymentCurrency(TRANSACTION_CURRENCY[company.paymentInfo.currency]);

        setSelectedCurrency(
          CURRENCY_OPTIONS.find((obj: any) => obj.label === TRANSACTION_CURRENCY[company.paymentInfo.currency])
        );
        setPaymentMethodSelected(selectedFormat(company.paymentInfo.payment));

        if (company.paymentInfo) {
          taxationHook.hookSetStringGst(company.paymentInfo.gst);
          taxationHook.hookSetStringHst(company.paymentInfo.hst);
          taxationHook.hookSetStringPst(company.paymentInfo.pst);
          taxationHook.hookSetStringTaxation(company.paymentInfo.tax);

          taxationHook.hookSetGstStatus(company.paymentInfo.gstStatus);
          taxationHook.hookSetHstStatus(company.paymentInfo.hstStatus);
          taxationHook.hookSetPstStatus(company.paymentInfo.pstStatus);
          taxationHook.hookSetTaxationStatus(company.paymentInfo.taxStatus);

          taxationHook.hookSetGstVerifiedDate(
            company.paymentInfo.gstVerifiedDate ? new Date(company.paymentInfo.gstVerifiedDate) : null
          );
          taxationHook.hookSetHstVerifiedDate(
            company.paymentInfo.hstVerifiedDate ? new Date(company.paymentInfo.hstVerifiedDate) : null
          );
          taxationHook.hookSetPstVerifiedDate(
            company.paymentInfo.pstVerifiedDate ? new Date(company.paymentInfo.pstVerifiedDate) : null
          );
          taxationHook.hookSetTaxVerifiedDate(
            company.paymentInfo.taxVerifiedDate ? new Date(company.paymentInfo.taxVerifiedDate) : null
          );
        }

        setApiKey(company.apiAccessKey.apiAccessKey);
        setApiKeyStatus(selectedFormat(company.apiAccessKey.status));
        setApiCreatedAt(dateFormatter(new Date(company.apiAccessKey.apiCreatedAt)));

        setTransactionApiUrlHandler(company.apiAccessKey.apiAccessKey);
        setClickApiUrlHandler(company.apiAccessKey.apiAccessKey);
        setTrackingScriptHandler(selectedPublisher);
        return company;
      }
      return null;
    } catch (err: any) {
      console.log(err);
      return null;
    }
  };

  const handleSameChecked = () => {
    if (!sameChecked) {
      setPaymentAddress(merchantAddressHook.hookAddress); //
      setpaymentAddress2(merchantAddressHook.hookAddressTwo || '');
      setPaymentCity(merchantAddressHook.hookCity);
      setPaymentRegion(merchantAddressHook.hookRegion);
      setPaymentCountry(merchantAddressHook.hookCountry);
      setPaymentPostal(merchantAddressHook.hookPostalCode);
      setPaymentPrimaryPhone(publisherPhoneNumber);
      setPaymentSecondaryPhone(publisherPhoneNumberTwo);
      setPaymentEmail(publisherEmail);
    } else {
      setPaymentAddress('');
      setpaymentAddress2('');
      setPaymentCity('');
      setPaymentRegion({ label: '', value: '' });
      setPaymentCountry({ label: '', value: '' });
      setPaymentPostal('');
      setPaymentPrimaryPhone('');
      setPaymentSecondaryPhone('');
      setPaymentEmail('');
    }
    setSameChecked(!sameChecked);
  };

  const getCountriesHandler = async () => {
    const { data } = await getCountries();
    if (data?.getCountries?.countries !== undefined) {
      setCountryOptions(optionsMaker(data.getCountries.countries.map((obj: any) => obj.name)));
      setAllRegions(data.getCountries.countries);
      const { country, paymentInfo } = await getPublisherDetails();
      setRegionOptions(
        optionsMaker(
          data.getCountries.countries
            .filter((obj: any) => obj.name === country)[0]
            .subdivisions.map((obj: any) => obj.name)
        )
      );
      setPaymentRegionOptions(
        optionsMaker(
          data.getCountries.countries
            .filter((obj: any) => obj.name === paymentInfo.country)[0]
            .subdivisions.map((obj: any) => obj.name)
        )
      );
    }
  };

  const getUsersHandler = async () => {
    try {
      let selectedPublisher;

      if (state?.publisherId !== undefined) {
        selectedPublisher = state.publisherId;
      } else {
        selectedPublisher = hookWhoAmI.companyId?.toString();
      }

      const { data } = await getUsers({
        variables: {
          userType: 'Publisher',
          companyId: selectedPublisher,
        },
        fetchPolicy: 'no-cache',
      });
      if (data?.users !== undefined) {
        const { users } = data.users;
        const userListFormatted = users.map((user: any) => ({
          fullName: `${user.firstName} ${user.lastName}`,
          securityRole: [...user.newRoles].sort((a: TRoleType, b: TRoleType) => (a.name > b.name ? 1 : -1)),
          status: user.status,
          id: user.id,
          lastLoginDate: user.lastLogin ? new Date(user.lastLogin) : null,
          modalInfo: {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            phone: user.phone || '',
            lang: user.preferredLanguage || '',
            position: user.position || '',
            subscriptionOffers: user.subscriptionOffers,
            id: user.id,
            roleId: user.roles[0]?.id,
            newRoles: user.newRoles,
            auth0Id: user.auth0Id,
          },
          lastLogin: user.lastLogin
            ? new Date(user.lastLogin).toLocaleDateString(undefined, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })
            : '',
        }));

        setUserList(userListFormatted);
        const currUser = userListFormatted.find((user: any) => user.id === hookWhoAmI.id);
        if (currUser) setSecurityRole(currUser.securityRole);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const checkRowInactiveHandler = (row: any) => row.status === 'Inactive';

  const handleValidation = (submitting = false) => {
    if (!submitting && !validOnBlur) return false;

    const validationValues = {
      companyName: publisherName,
      address: merchantAddressHook.hookAddress,
      city: merchantAddressHook.hookCity,
      region: merchantAddressHook.hookRegion.label,
      country: merchantAddressHook.hookCountry.label,
      postalCode: merchantAddressHook.hookPostalCode,
      phone: publisherPhoneNumber,
      phoneTwo: publisherPhoneNumberTwo || '',
      publisherEmail,
    };

    const paymentValidationValues = {
      paymentAddress,
      paymentCity,
      paymentRegion: paymentRegion?.label || '',
      paymentCountry: paymentCountry?.label || '',
      country: paymentCountry?.label || '',
      paymentPostal,
      paymentPrimaryPhone,
      ...(paymentSecondaryPhone && { paymentSecondaryPhone }),
    };

    const paypalValidationValues = {
      accountHolder,
      accountEmail,
      accountPhoneNumber,
    };

    const chequeValidationValues = {
      payableTo,
    };

    const wireTransferValues = {
      bankName,
      bankAccountNumber,
      swiftCode,
      wireTransferName,
    };

    let allErrorsHolder: { [key: string]: string } = {};
    let tempErrorsHolder: { [key: string]: string } = {};
    const setTempErrorsHolder = (validationErrors: { [key: string]: string }) => {
      tempErrorsHolder = validationErrors;
      return null;
    };
    const valid = validationHook.validateAll(validationValues, validationFields, setTempErrorsHolder, true);
    allErrorsHolder = { ...allErrorsHolder, ...tempErrorsHolder };
    const paymentValid = validationHook.validateAll(
      paymentValidationValues,
      paymentValidationFields,
      setTempErrorsHolder,
      true
    );
    allErrorsHolder = { ...allErrorsHolder, ...tempErrorsHolder };
    const paypalValid =
      paymentMethodSelected?.value === 'Paypal'
        ? validationHook.validateAll(paypalValidationValues, paypalValidationFields, setTempErrorsHolder, true)
        : true;
    allErrorsHolder = { ...allErrorsHolder, ...tempErrorsHolder };
    const chequeValid =
      paymentMethodSelected?.value === 'Cheque' || paymentMethodSelected?.value === 'Cheque Payment for $1000'
        ? validationHook.validateAll(chequeValidationValues, chequeValidationFields, setTempErrorsHolder, true)
        : true;
    allErrorsHolder = { ...allErrorsHolder, ...tempErrorsHolder };
    const wireTransferValid =
      paymentMethodSelected?.value === 'Wire Transfer'
        ? validationHook.validateAll(wireTransferValues, wireTransferValidationFields, setTempErrorsHolder, true)
        : true;
    allErrorsHolder = { ...allErrorsHolder, ...tempErrorsHolder };
    const taxValid = taxationHook.hookHandleTaxValidation(paymentCountry?.value || '', paymentRegion?.value || '');

    setErrors(allErrorsHolder);
    return valid && paymentValid && paypalValid && chequeValid && wireTransferValid && taxValid;
  };

  const setManageUserModalHandler = (refetchTableData?: boolean) => {
    if (refetchTableData) getUsersHandler();
    setManageUserModal();
  };

  const [updateUsers, { loading: updateUserLoading }] = useMutation(UPDATE_USER);

  const sideBarOptionsHandler = async (action: string, row: any) => {
    setUser(row.modalInfo);
    if (action === 'edit') {
      setIsSelf(row?.id === hookWhoAmI.id);
      setEditModal(true);
    } else if (action === 'Inactive') {
      const { data } = await updateUsers({
        variables: {
          input: {
            id: row.modalInfo.id,
            email: row.modalInfo.email.toLowerCase(),
            firstName: row.modalInfo.firstName,
            lastName: row.modalInfo.lastName,
            position: row.modalInfo.position,
            auth0Id: row.modalInfo.auth0Id,
            status: 'Active',
          },
        },
        onError() {
          setError('Failed to activate user');
        },
      });
      if (data === undefined) {
        setError('Failed to activate user');
      } else {
        await getUsersHandler();
      }
    } else if (action === 'Active') {
      handleDeactivateModal();
    }
  };

  const handleCloseEditModal = (refetchTableData?: boolean) => {
    if (refetchTableData) getUsersHandler();
    setEditModal(false);
  };

  const setSelectedRegionHandler = (value: SelectOption) => {
    setSelectedRegion(value);
    merchantAddressHook.hookSetRegion(value);
    if (sameChecked) handlePaymentRegion(value);
  };

  const setPublisherIDHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPublisherID(e.target.value);
  };

  const setPublisherNameHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setPublisherName(e.target.value);
  };

  const setPublisherEmailHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setPublisherEmail(e.target.value);
    if (sameChecked) handlePaymentEmail(e);
  };

  const setPublisherPhoneNumberHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPublisherPhoneNumber(e === undefined || e.toString() === '' ? '' : e.toString());
    if (sameChecked) handlePrimaryPhone(e);
  };

  const setPublisherPhoneNumberTwoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPublisherPhoneNumberTwo(e === undefined || e.toString() === '' ? '' : e.toString());
    if (sameChecked) handleSecondaryPhone(e);
  };

  const setPublisherPaymentInfosEmailHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setPublisherPaymentInfosEmail(e.target.value);
  };

  const setPaymentMethodHandler = async (value: string) => {
    setPaymentMethod(value);
  };

  const setPaymentCurrencyHandler = async (value: string) => {
    setPaymentCurrency(value);
  };

  const setPublisherActivityStatusHandler = async (value: string) => {
    setPublisherActivityStatus(value);
  };

  const setPublisherAccountStatusHandler = async (value: SelectOption) => {
    setPublisherAccountStatus(value);
  };

  const setPublisherCreatedDateHandler = async (value: Date) => {
    const formatDate = new Date(value);
    setAccountCreatedDate(formatDate.toDateString());
  };

  const setApiKeyHandler = (value: string) => {
    setApiKey(value);
  };

  const setPublisherAddressOneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    merchantAddressHook.hookSetAddress(e);
    if (sameChecked) handlePaymentAddress(e);
  };

  const setPublisherAddressTwoHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    merchantAddressHook.hookSetAddressTwo(e);
    if (sameChecked) handlePaymentAddress2(e);
  };

  const setPublisherCityHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    merchantAddressHook.hookSetCity(e);
    if (sameChecked) handlePaymentCity(e);
  };

  const setPublisherPostalHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    merchantAddressHook.hookSetPostalCode(e);
    if (sameChecked) handlePaymentPostal(e);
  };

  const [updateApiStatus, { loading: updateApiStatusLoading }] = useMutation(UPDATE_API);
  const [apiCaption, setApiCaption] = useState({ color: '', message: '' });

  const setApiAccessHandler = async (value: SelectOption) => {
    const { data } = await updateApiStatus({
      variables: {
        input: {
          status: value.label,
          companyId: hookWhoAmI.companyId?.toString(),
        },
      },
    });
    if (data?.updateApiAccessKey !== undefined) {
      setApiCaption({ color: colors.color29, message: 'Api status has been updated successfully' });
      setApiKeyStatus(value);
    } else {
      setApiCaption({ color: colors.color31, message: 'Api status update unsuccessful' });
    }
  };

  const [updateCompany, { loading: updateCompanyLoading }] = useMutation(UPDATE_COMPANY);

  const noNull = (value: any) => {
    if (value === undefined || value === null) return undefined;
    return value;
  };

  const updateCompanyHandler = async () => {
    const { data } = await updateCompany({
      variables: {
        input: {
          id: noNull(publisherID),
          companyType: noNull('Publisher'),
          companyName: noNull(publisherName),
          companyEmail: noNull(publisherEmail.toLowerCase()),
          address1: noNull(merchantAddressHook.hookAddress),
          address2: noNull(merchantAddressHook.hookAddressTwo),
          city: noNull(merchantAddressHook.hookCity),
          state: noNull(merchantAddressHook.hookRegion.label),
          country: noNull(merchantAddressHook.hookCountry.value),
          zip: noNull(merchantAddressHook.hookPostalCode),
          phone: noNull(publisherPhoneNumber),
          phone2: noNull(publisherPhoneNumberTwo),
          accountStatus: noNull(publisherAccountStatus.label),
          paymentInfo: {
            address1: noNull(paymentAddress),
            address2: noNull(paymentAddress2),
            city: noNull(paymentCity),
            state: noNull(paymentRegion?.label),
            country: noNull(paymentCountry?.label),
            zip: noNull(paymentPostal),
            email: noNull(paymentEmail),
            tax: noNull(taxationHook.hookTaxation),
            gst: noNull(taxationHook.hookGst),
            pst: noNull(taxationHook.hookPst),
            hst: noNull(taxationHook.hookHst),
            taxVerifiedDate: taxationHook.hookTaxVerifiedDate,
            gstVerifiedDate: taxationHook.hookGstVerifiedDate,
            pstVerifiedDate: taxationHook.hookPstVerifiedDate,
            hstVerifiedDate: taxationHook.hookHstVerifiedDate,
            taxStatus: noNull(taxationHook.hookTaxationStatus),
            gstStatus: noNull(taxationHook.hookGstStatus),
            pstStatus: noNull(taxationHook.hookPstStatus),
            hstStatus: noNull(taxationHook.hookHstStatus),
            phone: noNull(paymentPrimaryPhone),
            phone2: noNull(paymentSecondaryPhone),
            currency: noNull(selectedCurrency?.value),
            payment: noNull(paymentMethodSelected?.value),
            // Cheque
            payableTo: noNull(payableTo),
            // Paypal
            accountHolder: noNull(accountHolder),
            accountEmail: noNull(accountEmail),
            accountPhoneNumber: noNull(accountPhoneNumber),
            // Wire Transfer
            bankName: noNull(bankName),
            bankAccountNumber: noNull(bankAccountNumber),
            bankCode: noNull(swiftCode),
            transit: noNull(transit),
            wireTransferName: noNull(wireTransferName),
          },
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.updateCompany !== undefined) {
      hookShowToast(SUCCESS_MESSAGES.PUBLISHER_EDIT(publisherName));
    }
  };

  const [isApiModalOpen, setIsApiModalOpen] = useState(false);
  const [generateNewApi, { loading: generateNewApiLoading }] = useMutation(CREATE_NEW_API);
  const [apiModalError, setApiModalError] = useState('');

  const handleApiModal = () => {
    setIsApiModalOpen(!isApiModalOpen);
  };

  const confirmModalApi = async () => {
    const { data } = await generateNewApi({
      variables: {
        input: {
          status: 'Active',
          companyId: hookWhoAmI.companyId?.toString(),
        },
      },
    });
    if (data.createApiAccessKey === null) {
      setApiModalError('Failed to create new api access key');
    } else {
      setApiKey(data.createApiAccessKey.apiAccessKey);
      setApiCreatedAt(dateFormatter(new Date(data.createApiAccessKey.apiCreatedAt)));
      setApiKeyStatus({ label: data.createApiAccessKey.status, value: data.createApiAccessKey.status });
      handleApiModal();
    }
  };

  const confirmDeactivate = async () => {
    const { data } = await updateUsers({
      variables: {
        input: {
          id: hookUser.id,
          email: hookUser.email.toLowerCase(),
          firstName: hookUser.firstName,
          lastName: hookUser.lastName,
          position: hookUser.position,
          auth0Id: hookUser.auth0Id,
          status: 'Inactive',
        },
      },
    });
    if (data === undefined) {
      setError('Failed to activate user');
    } else {
      await getUsersHandler();
      setDeactivateModal(false);
    }
  };

  const hookChangeGst = (e: any) => {
    taxationHook.hookSetGst(e);
    taxationHook.hookSetGstStatus('Unverified');
  };

  const hookChangePst = (e: any) => {
    taxationHook.hookSetPst(e);
    taxationHook.hookSetPstStatus('Unverified');
  };

  const hookChangeHst = (e: any) => {
    taxationHook.hookSetHst(e);
    taxationHook.hookSetHstStatus('Unverified');
  };

  const updatePublisherHandler = async () => {
    setValidOnBlur(true);
    if (!isAdmin) {
      const valid = handleValidation(true);
      if (!valid) {
        setError('Please check the input fields');
        setErrorOpen(true);
        return;
      }
    }
    setError('');
    setErrorOpen(false);

    await updateCompanyHandler();
  };

  useEffect(() => {
    setError('');
    setErrorOpen(false);
  }, [
    publisherName,
    publisherAccountStatus,
    publisherEmail,
    merchantAddressHook.hookAddress,
    merchantAddressHook.hookAddressTwo,
    merchantAddressHook.hookCity,
    merchantAddressHook.hookRegion,
    merchantAddressHook.hookCountry,
    merchantAddressHook.hookPostalCode,
    publisherPhoneNumber,
    publisherPhoneNumberTwo,
    paymentAddress,
    paymentCity,
    paymentPostal,
    paymentRegion,
    paymentPrimaryPhone,
    paymentSecondaryPhone,
    taxationHook.hookTaxation,
    paymentCountry,
  ]);

  useEffect(() => {
    getCountriesHandler();
    getUsersHandler();
    fetchMemberships();
  }, []);

  useEffect(() => {
    setTransactionApiUrlHandler(apiKey);
    setClickApiUrlHandler(apiKey);
  }, [apiKey]);

  return {
    hookUpdateApiLoading: updateApiStatusLoading,
    hookApiCaption: apiCaption,

    hookApiModalOpen: isApiModalOpen,
    hookHandleApiModal: handleApiModal,
    hookApiError: apiModalError,
    hookGenerateModalLooading: generateNewApiLoading,
    hookConfirmGenerateApi: confirmModalApi,

    hookApiCreatedAt: apiCreatedAt,
    hookManageUserModal: manageUserModal,
    hookSetManageUserModal: setManageUserModalHandler,

    hookSelectedCountry: merchantAddressHook.hookCountry,

    hookSetPublisherID: setPublisherIDHandler,
    hookPublisherID: publisherID,

    hookPublisherName: publisherName,
    hookSetPublisherName: setPublisherNameHandler,

    hookPublisherEmail: publisherEmail,
    hookSetPublisherEmail: setPublisherEmailHandler,

    hookPublisherAddress: merchantAddressHook.hookAddress,
    hookSetPublisherAddress: setPublisherAddressOneHandler,

    hookPublisherAddressTwo: merchantAddressHook.hookAddressTwo,
    hookSetPublisherAddressTwo: setPublisherAddressTwoHandler,

    hookPublisherPhoneNumber: publisherPhoneNumber,
    hookSetPublisherPhoneNumber: setPublisherPhoneNumberHandler,

    hookError: error,

    hookPublisherPhoneNumberTwo: publisherPhoneNumberTwo,
    hookSetPublisherPhoneNumberTwo: setPublisherPhoneNumberTwoHandler,

    hookPublisherPaymentInfoEmail: publisherPaymentInfosEmail,
    hookSetPublisherPaymentInfoEmail: setPublisherPaymentInfosEmailHandler,

    hookPublisherCity: merchantAddressHook.hookCity,
    hookSetPublisherCity: setPublisherCityHandler,

    hookRegion: selectedRegion,
    hookSetRegion: setSelectedRegionHandler,

    hookPublisherPostalCode: merchantAddressHook.hookPostalCode,
    hookSetPublisherPostalCode: setPublisherPostalHandler,

    hookPublisherActivityStatus: publisherActivityStatus,
    hookSetPublisherActivityStatus: setPublisherActivityStatusHandler,

    hookPublisherAccountStatus: publisherAccountStatus,
    hookSetPublisherAccountStatus: setPublisherAccountStatusHandler,

    hookPublisherCreatedDate: accountCreatedDate,
    hookSetPublisherCreatedDate: setPublisherCreatedDateHandler,

    hookGst: taxationHook.hookGst,
    hookSetGst: taxationHook.hookSetGst,
    hookGstStatus: taxationHook.hookGstStatus,
    hookSetGstStatus: taxationHook.hookSetGstStatus,
    hookGstVeriedDate: taxationHook.hookGstVerifiedDate,

    hookPst: taxationHook.hookPst,
    hookSetPst: taxationHook.hookSetPst,
    hookPstStatus: taxationHook.hookPstStatus,
    hookSetPstStatus: taxationHook.hookSetPstStatus,
    hookPstVeriedDate: taxationHook.hookPstVerifiedDate,

    hookHst: taxationHook.hookHst,
    hookSetHst: taxationHook.hookSetHst,
    hookHstStatus: taxationHook.hookHstStatus,
    hookSetHstStatus: taxationHook.hookSetHstStatus,
    hookHstVeriedDate: taxationHook.hookHstVerifiedDate,

    hookTaxation: taxationHook.hookTaxation,
    hookSetTaxation: taxationHook.hookSetTaxation,
    hookTaxationStatus: taxationHook.hookTaxationStatus,
    hookSetTaxationStatus: taxationHook.hookSetTaxationStatus,
    hookTaxVeriedDate: taxationHook.hookTaxVerifiedDate,

    hookApiKey: apiKey,
    hookSetApiKey: setApiKeyHandler,

    hookApiAccess: apiKeyStatus,
    hookSetApiAccess: setApiAccessHandler,

    hookTransactionApiUrl: transactionApiUrl,
    hookSetTransactionApiUrl: setTransactionApiUrlHandler,

    hookClickApiUrl: clickApiUrl,
    hookSetClickApiUrl: setClickApiUrlHandler,

    hookTrackingScript: trackingScript,
    hookSetTrackingScript: setTrackingScriptHandler,

    hookUserList: userList,

    hookUserType: hookUserInfo.userTypesId,

    hookCheckInactive: checkRowInactiveHandler,

    hookUpdatePublisher: updatePublisherHandler,
    hookValidateFields: handleValidation,
    hookErrors: errors,

    hookSideBarOptionsHandler: sideBarOptionsHandler,

    hookHandleCloseEditModal: handleCloseEditModal,
    hookEditModalOpen: editModal,

    hookIsSelf: isSelf,

    hookUser,

    hookDeactivateIsOpen: deactivateModalOpen,
    hookHandleDeactive: handleDeactivateModal,
    hookConfirmDeactivate: confirmDeactivate,

    hookTableSortHandler: handleTableSort,
    hookTableSortColumn: sortColumn,
    hookLoading: getCompanyLoading || getCountriesLoading,

    hookCountryOptions: countryOptions,
    hookRegionOptions: regionOptions,
    hookSetSelectedCountry: handleChangeCountries,

    hookMerchantMemberships: merchantMemberships,
    hookGetMerchantMemberShipsLoading: getMembershipsLoading,

    hookTableLoading: getUsersLoading,

    hookSaveLoading: updateCompanyLoading,

    hookPaymentCurrency: paymentCurrency,
    hookSetPaymentCurrency: setPaymentCurrencyHandler,

    hookPaymentMethod: paymentMethod,
    hookSetPaymentMethod: setPaymentMethodHandler,

    hookBankName: bankName,
    hookBankAccountNumber: bankAccountNumber,
    hookSwift: swiftCode,
    hookTransit: transit,
    hookWireTransfer: wireTransferName,
    hookPaymentSelected: paymentMethodSelected,
    hookCurrencySelected: selectedCurrency,

    hookHandleBankName: handleBankName,
    hookHandleCurrencySelected: handleSelectedCurrency,
    hookHandlePaymentSelected: handlePaymentMethodSelected,
    hookHandleBankAccountNumber: handleBankAccountNumbner,
    hookHandleSwift: handleSwift,
    hookHandleTransit: handleTransit,
    hookHandleWireTransfer: handleWireTransferName,
    // Paypal
    hookAccountHolder: accountHolder,
    hookAccountPhoneNumber: accountPhoneNumber,
    hookAccountEmail: accountEmail,
    hookHandleAccountHolder: handleAccountHolder,
    hookHandleAccountPhoneNumber: handleAccountPhoneNumber,
    hookHandleAccountEmail: handleAccountEmail,
    // Cheque
    hookPayableTo: payableTo,
    hookHandlePaybleTo: handlePayableTo,

    hookPaymentAddress: paymentAddress,
    hookPaymentAddress2: paymentAddress2,
    hookPaymentCity: paymentCity,
    hookPaymentRegion: paymentRegion,
    hookPaymentRegionOptions: paymentRegionOptions,
    hookPaymentCountry: paymentCountry,
    hookPaymentPostal: paymentPostal,
    hookPaymentPrimaryPhone: paymentPrimaryPhone,
    hookPaymentSecondaryPhone: paymentSecondaryPhone,
    hookPaymentEmailL: paymentEmail,

    hookHandlePaymentAddress: handlePaymentAddress,
    hookHandlePaymentAddress2: handlePaymentAddress2,
    hookHandlePaymentCity: handlePaymentCity,
    hookHandlePaymentRegion: handlePaymentRegion,
    hookHandlePaymentCountry: handlePaymentCountry,
    hookHandlePaymentPostal: handlePaymentPostal,
    hookHandlePaymentPrimaryPhone: handlePrimaryPhone,
    hookHandleSecondaryPhone: handleSecondaryPhone,
    hookHandlePaymentEmail: handlePaymentEmail,
    hookHandleTaxationString: handleTaxationString,
    hookHandleGst: taxationHook.hookSetGst,
    hookHandlePst: taxationHook.hookSetPst,
    hookHandleHst: taxationHook.hookSetHst,
    hookTaxErrors: taxationHook.hookTaxErrors,

    hookErrorOpen: errorOpen,

    hookChangeGst,
    hookChangePst,
    hookChangeHst,

    hookSameChecked: sameChecked,
    hookChangeSameChecked: handleSameChecked,

    hookUpdateUserLoading: updateUserLoading,

    hookApiKeyText: keyCopied,
    hookApiUrlText: apiUrlCopied,
    hookTransactionText: transactionCopied,
    hookScriptCopied: trackingScriptCopied,

    hookHandleKey: handleCopyKey,
    hookHandleUrl: handleApiCopied,
    hookHandleTransaction: handleTransactioCopy,
    hookHandleScriptCopy: handleTrackingCopied,

    hookMinimumAmount: minAmount,

    hookId: hookUserInfo.id,
    hookSecurityRole: securityRole,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
    hookUserManagementCanAccess: Permission.canAccess([permissionsCodeList[isAdmin ? 2 : 1]]),
    hookAccountDetailsCanAccess: Permission.canAccess([permissionsCodeList[isAdmin ? 1 : 0]]),
  };
};
