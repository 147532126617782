import React from 'react';

import { useEditCommissions } from '../../../hooks';
import { commissionBaseLabels } from '../utils';
import * as Styled from '../styles';

export const RevShareStructure = ({ hook }: { hook: ReturnType<typeof useEditCommissions> }) => {
  if (!hook.hookCommissionData?.commissionStructure) return <p />;
  const { revShareFlat, revShareTiered } = hook.hookCommissionData.commissionStructure;
  const { cutoffAmount } = revShareTiered;
  const isTiered = revShareTiered.tiers.length > 0;
  const commissionBase = isTiered ? revShareTiered.commissionBase : revShareFlat.commissionBase;
  const commissionFee = isTiered ? '' : revShareFlat.commissionFee;
  const {
    // dynamic label based on different commission base
    commissionBaseFormat,
  } = commissionBaseLabels(commissionBase || '');

  const tierRows = revShareTiered.tiers.map((x, i) => (
    <Styled.TierRowStyled key={`tierRow${i}`}>
      <Styled.TierItemStyled>
        <Styled.InputTextStyled
          type="text"
          value={x.upTo}
          label={`Revenue Share Level ${i + 1} up to $ Amount`}
          theme="readonly"
          readonly
        />
      </Styled.TierItemStyled>
      <Styled.TierItemStyled>
        <Styled.InputTextStyled
          type="text"
          value={x.commissionAmount}
          label={`Level ${i + 1} Commission %`}
          theme="readonly"
          readonly
        />
      </Styled.TierItemStyled>
    </Styled.TierRowStyled>
  ));
  return (
    <>
      <Styled.CommissionStructureRow>
        <Styled.InputTextStyled
          type="text"
          value={commissionBaseFormat}
          label="Commission Base"
          theme="readonly"
          readonly
        />

        <Styled.InputTextStyled
          type="text"
          value={commissionFee || ''}
          label="Flat % Commission"
          theme="readonly"
          readonly
        />
      </Styled.CommissionStructureRow>
      {isTiered ? tierRows : <div />}
      {isTiered && (
        <Styled.InputTextStyled
          type="text"
          value={cutoffAmount || ''}
          label={`After Level ${revShareTiered.tiers.length} %`}
          theme="readonly"
          readonly
        />
      )}
    </>
  );
};
