import React from 'react';

import { Modal } from '../Modal';
import { PageTitle } from '../PageTitle';

import { useAddCommissionModal } from './hooks';
import * as Styled from './styles';
import { ADD_COMMISSION_MODAL } from './utils';

type AddAdModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddCommissionModal = ({ onClose, isOpen }: AddAdModalProps) => {
  const hook = useAddCommissionModal();

  return (
    <Modal isOpen={isOpen}>
      <Styled.WrapperStyled>
        <PageTitle>{ADD_COMMISSION_MODAL.TITLE}</PageTitle>
        <Styled.SelectStyled
          name="type"
          label={ADD_COMMISSION_MODAL.COMMISSION_TYPE_LABEL}
          onChange={hook.hookSetSelectedCommissionType}
          selected={hook.hookSelectedCommissionType}
          options={hook.hookCommissionTypeList}
        />

        <Styled.SelectStyled
          name="defaultCustom"
          label={ADD_COMMISSION_MODAL.SUBTYPE_LABEL}
          onChange={hook.hookSetSelectedCommissionSubType}
          selected={hook.hookSelectedCommissionSubType}
          options={hook.hookCommissionSubTypeList}
        />

        <Styled.ButtonAreaStyled>
          <Styled.ButtonStyled theme="secondary" width="100px" onClick={() => hook.hookOnClose(onClose)}>
            {ADD_COMMISSION_MODAL.CANCEL}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled width="100px" onClick={hook.hookOnConfirm}>
            {ADD_COMMISSION_MODAL.CONFIRM}
          </Styled.ButtonStyled>
        </Styled.ButtonAreaStyled>
      </Styled.WrapperStyled>
    </Modal>
  );
};
