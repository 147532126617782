export const MODAL = {
  TITLE: 'What does publisher check mean?',
  DESCRIPTION: 'Publisher check is the total number of pages that have been checked.',
  CHECK: [
    {
      TITLE: 'Check Pass',
      DESCRIPTION:
        'A rule has been checked on a page where a link exists for an associated product and Fintel Check resulted in an accurate match against the rule requirements.',
    },
    {
      TITLE: 'Check Review',
      DESCRIPTION:
        'A rule has been checked on a page where a link exists for an associated product and Fintel Check resulted in an unresolved match against the rule requirements. A manual check of the page will be required.',
    },
    {
      TITLE: 'Check Fail',
      DESCRIPTION:
        'A rule has been checked on a page where a link exists for an associated product and Fintel Check resulted in an inaccurate match against the rule requirements.',
    },
    {
      TITLE: 'Check Incomplete',
      DESCRIPTION: 'Fintel Check was unable to run a check due to a page(s) failing to load or due to a page redirect.',
    },
    {
      TITLE: 'Check Not Applicable',
      DESCRIPTION: 'The page could be scraped but the page has no reference to the merchant being checked.',
    },
  ],
};

export const GRAPH_TITLE = 'Publisher Checks';
