import { gql } from '@apollo/client';

export const GET_GROUP_LIST = gql`
  query getPublisherGroupList($programId: String) {
    groups(programId: $programId) {
      groups {
        id
        name
      }
    }
  }
`;

export type GetGroupListInput = {
  programId: string;
};

export type GetGroupListOutput = {
  groups: {
    groups: {
      id: string;
      name: string;
    }[];
  };
};
