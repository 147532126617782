export const PROGRAM_DETAILS = {
  BUTTON: {
    BACK: 'Back',
    CANCEL_MEMBERSHIP: 'Cancel Membership',
    CANCEL: 'Cancel',
  },
  CANCEL_MODAL: {
    CANCEL_MEMBERSHIP: 'Cancel Membership',
    DESCRIPTION1: 'You are about to cancel the membership between you and  ',
    DESCRIPTION2:
      '. Once cancelled you will no longer have any access to their program or information, but you may re-apply in the future.',
  },
  DECLINE_MODAL: {
    DECLINE: 'Decline Invitation',
    DESCRIPTION1: 'You are about to decline the invitation from ',
    DESCRIPTION2:
      ' to join their porgram. They will be notified by email and you will not have acces to their program details',
    NOTE: 'You can still re-apply to this merchant program after declining',
    DEC_BUTTON: 'Decline',
  },
  ACCEPT_MODAL: {
    ACCEPT: 'Accept Invitation',
    DESCRIPTION1: 'You are about to accept the invitation from ',
    DESCRIPTION2:
      'to join their program. They will recieve confirmation emails and you will have access to their program details.',
    ACCEPT_BUT: 'Accept',
  },
};

export const MENU_BACK = 'Back';
export const MENU_CANCEL = 'Cancel Membership';
export const MENU_APPLY = 'Apply';
export const MENU_APPROVE = 'Accept';
export const MENU_DECLINE = 'Decline';

export const PENDING = 'Pending';
export const APPROVED = 'Approved';
export const DECLINED = 'Declined';
export const CLOSED = 'Closed';

export const NEW_MEMBER = 'New member offer';

export const I_HAVE = 'I have read and agree to the Terms and Conditions. ';
