import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { colors } from '../../../styles/theme';

import * as Styled from './styles';

type InputPhoneProps = {
  label?: string;
  name?: string;
  type: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  maxLength?: number;
  faIcon?: IconDefinition;
  error?: string;
  width?: string;
  className?: string;
  theme?: 'default' | 'readonly';
  tooltip?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  country?: string;
};

export const InputPhone = ({
  label = '',
  name,
  type,
  value,
  onChange,
  placeholder = '',
  disabled = false,
  required = false,
  readonly = false,
  maxLength = 20,
  faIcon,
  error,
  width = '100%',
  className,
  theme = 'default',
  tooltip,
  onBlur,
  country,
}: InputPhoneProps) => (
  <Styled.WrapperStyled width={width} className={className}>
    {label && (
      <Styled.LabelStyled>
        {label} {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}{' '}
        {tooltip && <Styled.TooltipStyled text={tooltip} />}
      </Styled.LabelStyled>
    )}
    <Styled.InputContainerStyled error={!!error} theme={theme} disabled={disabled}>
      {faIcon && <FontAwesomeIcon icon={faIcon} />}

      <Styled.InputStyled
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        readOnly={readonly}
        maxLength={maxLength}
        onBlur={onBlur}
        country={country}
        international
        withCountryCallingCode
      />

      {error && <FontAwesomeIcon icon={faTimes} style={{ color: colors.color14 }} />}
    </Styled.InputContainerStyled>
    {error && <Styled.ErrorStyled error={error}>{error}</Styled.ErrorStyled>}
  </Styled.WrapperStyled>
);
