import React from 'react';

import * as Styled from './styles';

type FormatedCellProps = {
  value: any;
  secondValue?: any;
  children?: any;
  theme?: string;
};

export const FormatedCell = ({ value, secondValue, children, theme }: FormatedCellProps) => (
  <Styled.CellWrapperStyled theme={theme}>
    {value}
    <Styled.SpaceBetween theme={theme}>{children}</Styled.SpaceBetween>
    {secondValue && <Styled.SecondLineStyled>{secondValue}</Styled.SecondLineStyled>}
  </Styled.CellWrapperStyled>
);
