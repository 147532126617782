import React from 'react';

import { ACTIVITY_STATUS_OPTIONS, DEFAULT_PRODUCT_CATEGORY, PLACEHOLDER, SUBTITLE } from '../../contracts';
import * as Styled from '../../styles';
import {
  AUDIENCE_SIZE,
  AVERAGE_INCOME,
  PRODUCT_CATEGORY_SELECT_OPTIONS,
  PROMOTION_METHOD,
} from '../../../../../../utils';
import { SearchFilterOptions, SearchFilterType } from '../../types';

type AdvancedSearchFilterProps = {
  filterIsOpen: boolean;
  genderOptions: SelectOption[];
  ageOptions: SelectOption[];
  martketOptions: SelectOption[];
  ratingOptions: SelectOption[];
  filter: SearchFilterType;
  setFilter: (name: SearchFilterOptions, value: SelectOption) => void;
  isLoading: boolean;
};

export const AdvancedSearchFilter = ({
  filterIsOpen,
  ageOptions,
  genderOptions,
  martketOptions,
  ratingOptions,
  isLoading,
  filter,
  setFilter,
}: AdvancedSearchFilterProps) => (
  <Styled.AdvancedSearchStyled isOpen={filterIsOpen}>
    <Styled.AdvancedSearchTitleStyled>{SUBTITLE.OVERVIEW}</Styled.AdvancedSearchTitleStyled>
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.MARKET}
      name="market"
      onChange={(value) => setFilter('audienceMarkets', value)}
      selected={filter.audienceMarkets}
      options={martketOptions}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.CATEGORY}
      name="category"
      onChange={(value) => setFilter('productCategories', value)}
      selected={filter.productCategories}
      options={[DEFAULT_PRODUCT_CATEGORY, ...PRODUCT_CATEGORY_SELECT_OPTIONS]}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.PROMOTION}
      name="method"
      onChange={(value) => setFilter('promotionMethods', value)}
      selected={filter.promotionMethods}
      options={PROMOTION_METHOD}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.AUDIENCE_SIZE}
      name="audience"
      onChange={(value) => setFilter('avgMonthlyAudience', value)}
      selected={filter.avgMonthlyAudience}
      options={AUDIENCE_SIZE}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.ACTIVITY_STATUS}
      name="activity"
      onChange={(value) => setFilter('activityStatus', value)}
      selected={filter.activityStatus}
      options={ACTIVITY_STATUS_OPTIONS}
      isLoading={isLoading}
      isDisabled={isLoading}
    />

    <Styled.AdvancedSearchTitleStyled>{SUBTITLE.DEMOGRAPHICS}</Styled.AdvancedSearchTitleStyled>
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.AGE}
      name="age"
      onChange={(value) => setFilter('audienceAges', value)}
      selected={filter.audienceAges}
      options={ageOptions}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.GENDER}
      name="gender"
      onChange={(value) => setFilter('gender', value)}
      selected={filter.gender}
      options={genderOptions}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.RATING}
      name="score"
      onChange={(value) => setFilter('creditScoreRating', value)}
      selected={filter.creditScoreRating}
      options={ratingOptions}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
    <Styled.AdvancedSelectStyled
      placeholder={PLACEHOLDER.INCOME}
      name="income"
      onChange={(value) => setFilter('avgIncomes', value)}
      selected={filter.avgIncomes}
      options={AVERAGE_INCOME}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
  </Styled.AdvancedSearchStyled>
);
