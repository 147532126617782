import { gql } from '@apollo/client';

export const TRANSACTION_MASTER_SAVED_REPORTS = gql`
  query reportProfiles($user: ReportProfilesSearchInput) {
    reportProfiles(input: $user) {
      reports {
        id
        name
        description
        frequency
        every
        company
        report
        user {
          id
          email
        }
        filters {
          field
          value {
            label
            value
          }
        }
        columns {
          label
          value
        }
      }
    }
  }
`;

export type TMSavedReportsInput = {
  user: {
    company?: number | null;
    user: {
      id: string;
      userType: string;
    };
    report: 'Transaction Master';
  };
};

export type TMSavedReport = {
  id: string;
  name: string;
  description: string | null;
  frequency: string | null;
  every: string | null;
  company: number;
  report: string;
  user: { id: string; email: string };
  filters: { field: string; value: SelectOption }[];
  columns: SelectOption[];
};

export type TMSavedReportsOutput = {
  reportProfiles: {
    reports: TMSavedReport[];
  };
};
