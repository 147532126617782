import styled from 'styled-components';

import { InputText, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 23% calc(100% - 23%);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color1};
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightContentStyled = styled.div`
  width: 45%;
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color1};

  label {
    margin-bottom: 2rem;
  }

  h1 {
    ${fonts.gotham.H36M}
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    ${fonts.gotham.H26M}
  }
`;

export const FormStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  margin-top: 1rem;
`;

export const InputStyled = styled(InputText)`
  margin-bottom: 0;
`;

export const InputTwoColumnStyled = styled(InputText)`
  grid-column: span 2;
`;

export const SelectStyled = styled(Select)``;
