import { gql } from '@apollo/client';

import { PerformanceGroupByValue } from '../../types';

export const SEARCH_PERFORMANCE = gql`
  query performances($input: PerformanceSearchInput) {
    performances(input: $input) {
      performances {
        id
        currency
        date
        publisher {
          id
          companyName
          paymentInfo {
            currency
          }
          trackings {
            primaryWebsite
          }
        }
        merchant {
          id
          companyName
          companyUrl
          program {
            id
            transactionSettings {
              currency
            }
          }
        }
        ad {
          id
        }
        trackingProfile {
          id
        }
        product {
          id
          name
          customizedProductId
          productCategory
          program {
            id
          }
        }
        campaign {
          id
          name
        }
        adId
        month
        year
        aggregationDay
        impressions
        CTR
        clicks
        uniqueClicks
        uniqueImpressions
        conversions
        signups
        EPC
        newPendingTransactions
        oldPendingTransactions
        pendingTransactions
        declinedTransactions
        softDeclinedTransactions
        verifyTransactions
        oldApprovedTransactions
        approvedTransactions
        totalTransactions
        approvalRate
        pendingGrossValue
        totalGrossValue
        totalNetValue
        CPACommission
        revShareCommission
        manualBonus
        performanceBonus
        totalCommissionsAmount
        productCategory
      }
    }
  }
`;

enum TransactionStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Declined = 'Declined',
  Verify = 'Verify',
  SoftDecline = 'SoftDecline',
}

export type SearchPerformanceInputType = {
  input: {
    merchantId?: string;
    publisherId?: string;
    startDate: string;
    endDate: string;
    prevStartDate?: Date; // Don't know what this is
    prevEndDate?: Date; // Don't know what this is
    trackingProfileId?: string;
    productId?: string;
    adId?: string;
    productCategory?: string;
    campaignId?: string;
    publisherGroupId?: string;
    transactionStatus?: TransactionStatus;
    groupBy: PerformanceGroupByValue;
  };
};

type SearchPerformacePublisherType = {
  id: string;
  company: string;
  paymentInfo: {
    currency: string;
  };
  trackings: {
    primaryWebsite: string;
  }[];
};

type SearchPerformanceMerchantType = {
  id: string;
  companyName: string;
  companyUrl: string;
  program: {
    id: string;
    transactionSettings: {
      currency: string;
    };
  };
};

type SearchPerformanceProductType = {
  id: string;
  name: string;
  customizedProductId: string;
  productCategory: string;
  program: {
    id: string;
  };
};

export type SearchPerformancePerformanceType = {
  id: string;
  currency: string;
  date: string;
  publisher: SearchPerformacePublisherType | null;
  merchant: SearchPerformanceMerchantType | null;
  ad: { id: string } | null;
  trackingProfile: { id: string } | null;
  product: SearchPerformanceProductType | null;
  campaign: { id: string; name: string } | null;
  adId: string | null;
  month: string | null;
  year: string | null;
  aggregationDay: string | null;
  impressions: string;
  CTR: string;
  clicks: string;
  uniqueClicks: string;
  uniqueImpressions: string;
  conversions: string;
  signups: string | null;
  EPC: string;
  newPendingTransactions: string;
  oldPendingTransactions: string;
  pendingTransactions: string;
  declinedTransactions: string;
  softDeclinedTransactions: string;
  verifyTransactions: string;
  oldApprovedTransactions: string;
  approvedTransactions: string;
  approvedTransactionsToday: string;
  totalTransactions: string;
  approvalRate: string;
  pendingGrossValue: string;
  totalGrossValue: string;
  totalNetValue: string;
  CPACommission: string;
  revShareCommission: string;
  manualBonus: string | null;
  performanceBonus: string | null;
  totalCommissionsAmount: string;
  productCategory: string | null;
};

export type SearchPerformanceOutputType = {
  performances: {
    performances: SearchPerformancePerformanceType[];
  };
};
