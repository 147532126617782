import React from 'react';
import { AnyEventObject, SnapshotFrom } from 'xstate';

import { Button, ErrorBanner } from 'components';
import { BrandName } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/BrandName.component';
import { CancelAddRuleModal } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/CancelAddRuleModal.component';
import { NameGroupType } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/NameGroupType.component';
import { ProductCriteria } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/ProductCriteria.component';
import { ProductEligibility } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/ProductEligibility.component';
import { ProductSelection } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/ProductSelection.component';
import { Summary } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/Summary.component';
import { TextCriteria } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/TextCriteria.component';
import { TextEligibility } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/components/TextEligibility.component';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import { machine } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/machine';

type AddEvaluationRuleProps = {
  stateMachine: SnapshotFrom<typeof machine>;
  send: (event: AnyEventObject) => void;
  refreshRuleManager: () => void;
  isReadOnly: boolean;
};

export const AddEvaluationRule = ({
  stateMachine,
  send,
  refreshRuleManager,
  isReadOnly,
}: AddEvaluationRuleProps): JSX.Element => {
  const hook = useAddEvaluationRule(stateMachine, send, refreshRuleManager);
  return (
    <Styled.PageWrapper>
      <ErrorBanner isOpen={!!hook.errorMessage} message={hook.errorMessage} />
      <Styled.PageTitleStyled>
        {!stateMachine.matches({ AddEvaluationRule: 'NameGroupType' }) && (
          <Styled.BackButton
            onClick={() => {
              send({ type: 'AddEvaluationRule.previous' });
            }}
          >
            &lt;
          </Styled.BackButton>
        )}
        {stateMachine.context.titleContent}
      </Styled.PageTitleStyled>

      {stateMachine.matches({ AddEvaluationRule: 'NameGroupType' }) && <NameGroupType hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'BrandNameText' }) && <BrandName hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'TextEligibility' }) && <TextEligibility hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'TextCriteria' }) && <TextCriteria hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'TextSummary' }) && <Summary hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'ProductSelection' }) && <ProductSelection hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'BrandNameProduct' }) && <BrandName hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'ProductEligibility' }) && <ProductEligibility hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'ProductCriteria' }) && <ProductCriteria hook={hook} />}
      {stateMachine.matches({ AddEvaluationRule: 'ProductSummary' }) && <Summary hook={hook} />}

      <Styled.RuleButtonsWrapper>
        <Button theme="tertiary" width="140px" onClick={() => hook.hookCancelButtonHandler(true)}>
          Cancel
        </Button>
        {!stateMachine.matches({ AddEvaluationRule: 'NameGroupType' }) && (
          <Button
            theme="secondary"
            width="140px"
            onClick={() => {
              send({ type: 'AddEvaluationRule.previous' });
            }}
          >
            Back
          </Button>
        )}
        {!stateMachine.matches({ AddEvaluationRule: 'TextSummary' }) &&
          !stateMachine.matches({ AddEvaluationRule: 'ProductSummary' }) && (
            <Button
              width="140px"
              theme="primary"
              onClick={() => send({ type: 'AddEvaluationRule.next' })}
              disabled={!stateMachine.can({ type: 'AddEvaluationRule.next' })}
            >
              Next
            </Button>
          )}
        {(stateMachine.matches({ AddEvaluationRule: 'TextSummary' }) ||
          stateMachine.matches({ AddEvaluationRule: 'ProductSummary' })) && (
          <Button theme="primary" disabled={isReadOnly} onClick={() => hook.createRule()} width="140px">
            Create Rule
          </Button>
        )}
      </Styled.RuleButtonsWrapper>

      <CancelAddRuleModal
        isOpen={hook.hookCancelOpen}
        cancelButtonHandler={hook.hookCancelButtonHandler}
        exitAddRuleModalHandler={hook.hookExitAddRuleModalHandler}
      />
    </Styled.PageWrapper>
  );
};
