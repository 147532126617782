import React, { useEffect } from 'react';

import { Checkbox } from '../../../../../../components';
import { useTrackingSettings } from '../../hooks/useTrackingSettings';
import { URL_STATUSES } from '../../../../../../utils';

import * as Styled from './styles';
import { TRACKING_SETTINGS } from './enums';

type TrackingSettingsProps = {
  setIsTracking: (isTracking: boolean) => void;
  isReadOnly: boolean;
  setIsSaveEnabled: (isSaveEnabled: boolean) => void;
  trackingHook: ReturnType<typeof useTrackingSettings>;
};

const TrackingSettings = ({ setIsTracking, isReadOnly, setIsSaveEnabled, trackingHook }: TrackingSettingsProps) => {
  useEffect(() => {
    setIsTracking(true);
  }, []);

  /**
   * Handles 3rd party URL checkbox change
   * @param {boolean} checked 3rd party URL checkbox value
   */
  const handleThirdPartyCheckbox = (checked: boolean) => {
    trackingHook.hookCheckThirdPartyStatus(checked);
  };

  /**
   * Handle 3rd party URL change and validate it
   * @param {React.ChangeEvent<HTMLInputElement>} e text change event
   */
  const handleThirdPartyURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    trackingHook.hookSetThirdPartyTrackingURL(e);
  };

  /**
   * Handle landing page URL change and validate the save button
   * @param {React.ChangeEvent<HTMLInputElement>} e text change event
   */
  const handleLandingPageURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    trackingHook.hookSetLandingpageURL(e);
  };

  /**
   * This function is used to validate the third party URL on blur
   */
  const handleThirdPartyURLOnBlur = () => {
    if (trackingHook.hookThirdPartyTrackingURL.length > 0 || trackingHook.hookThirdPartyStatus) {
      trackingHook.validateAllURLs();
    }
  };

  /**
   * This function is used to validate the save button
   * @returns {void} sets the save button state
   */
  const shouldEnableSaveButton = () => {
    const urlErrorsAllowed = [
      URL_STATUSES.ACTIVE_WEBSITE.ERROR,
      URL_STATUSES.UNSAFE_WEBSITE.ERROR,
      URL_STATUSES.INACTIVE_WEBSITE.ERROR,
    ];
    if (trackingHook.hookLandingUrlError && !urlErrorsAllowed.includes(trackingHook.hookLandingUrlError)) {
      setIsSaveEnabled(false);
      return;
    }
    if (trackingHook.hookThirdPartyUrlError && !urlErrorsAllowed.includes(trackingHook.hookThirdPartyUrlError)) {
      setIsSaveEnabled(false);
      return;
    }
    if (trackingHook.hookThirdPartyStatus && trackingHook.hookThirdPartyTrackingURL.length <= 0) {
      setIsSaveEnabled(false);
      return;
    }
    setIsSaveEnabled(true);
  };

  /**
   * This function is used to trigger the validation function for the save button
   */
  useEffect(() => {
    shouldEnableSaveButton();
  }, [trackingHook.hookLandingUrlError, trackingHook.hookThirdPartyUrlError, trackingHook.hookThirdPartyStatus]);

  return (
    <Styled.WrapperStyled>
      <Styled.InputTextStyled
        label={TRACKING_SETTINGS.LABELS.LANDING_URL}
        value={trackingHook.hookLandingPageURL}
        type="url"
        placeholder={TRACKING_SETTINGS.PLACEHOLDERS.LANDING_URL}
        onChange={handleLandingPageURLChange}
        error={trackingHook.hookLandingUrlError}
        onBlur={trackingHook.validateLandingPage}
        required
        disabled={isReadOnly}
      />
      <Styled.InputTextStyled
        label={TRACKING_SETTINGS.LABELS.QUERY_STRING}
        value={trackingHook.hookQueryURL}
        type="text"
        placeholder={TRACKING_SETTINGS.PLACEHOLDERS.QUERY_STRING}
        onChange={trackingHook.hookSetQueryURL}
        disabled={isReadOnly}
      />
      <Styled.InputTextStyled
        label={TRACKING_SETTINGS.LABELS.TRACKING_URL}
        value={trackingHook.hookThirdPartyTrackingURL}
        type="url"
        onChange={handleThirdPartyURLChange}
        error={trackingHook.hookThirdPartyUrlError}
        onBlur={handleThirdPartyURLOnBlur}
        placeholder={TRACKING_SETTINGS.PLACEHOLDERS.TRACKING_URL}
        disabled={isReadOnly}
        required={trackingHook.hookThirdPartyStatus}
      />
      <Styled.CheckBoxAreaStyled>
        <Checkbox
          label={TRACKING_SETTINGS.LABELS.TRACKER}
          onChange={handleThirdPartyCheckbox}
          checked={trackingHook.hookThirdPartyStatus}
          disabled={isReadOnly}
        />
      </Styled.CheckBoxAreaStyled>
      <Styled.InputTextStyled
        label={TRACKING_SETTINGS.LABELS.URL_FORMAT}
        value={trackingHook.hookThirdPartyURLFormat}
        type="text"
        placeholder={TRACKING_SETTINGS.PLACEHOLDERS.URL_FORMAT}
        onChange={trackingHook.hookSetThirdPartyURLFormat}
        disabled={isReadOnly}
      />
    </Styled.WrapperStyled>
  );
};
export default TrackingSettings;
