export const MODAL_KEYS = {
  openDeleteModal: Symbol('openDeleteModal'),
  openSettingModal: Symbol('openSettingsModal'),
};

type SavedReportsReducerProps = {
  [key: symbol]: boolean;
  modalInfo: Record<string, any>;
};

export const savedReportsModals: SavedReportsReducerProps = {
  [MODAL_KEYS.openDeleteModal]: false,
  [MODAL_KEYS.openSettingModal]: false,
  modalInfo: {},
};

export const useSavedReportsReducer = (state: any, { data }: ComponentReducerType): SavedReportsReducerProps => ({
  ...state,
  ...data,
});
