import { useState } from 'react';

/**
 * ConfirmationModal hooks - if using this, you need to use ConfirmationModal component in order to render the modal (components/Modal/ConfirmationModal)
 * @param {() => (void | boolean) | Promise<void | boolean>} confirmCallback optional callback called when confirm button is clicked
 * @param {() => (void | boolean) | Promise<void | boolean>} cancelCallback optional callback called when cancel button is clicked
 * @returns hooks for ConfirmationModal, including hookOnClose, hookOnConfirm, hookIsOpen, hookSetIsOpen
 */
export const useConfirmationModal = (
  confirmCallback?: () => (void | boolean) | Promise<void | boolean>,
  cancelCallback?: () => (void | boolean) | Promise<void | boolean>
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = async () => {
    if (cancelCallback) {
      if ((await cancelCallback()) === false) {
        return;
      }
    }
    setIsOpen(false);
  };

  const onConfirm = async () => {
    if (confirmCallback) {
      if ((await confirmCallback()) === false) {
        return;
      }
    }
    setIsOpen(false);
  };

  return {
    hookOnClose: onClose,
    hookOnConfirm: onConfirm,
    hookIsOpen: isOpen,
    hookSetIsOpen: setIsOpen,
  };
};
