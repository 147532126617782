import React from 'react';

import * as Styled from '../../styles';
import { InputText, InputTextarea, Select } from '../../../../../../../../../components';
import { DUO_OPTIONS, MONETARY_TOOLTIP, optionsMaker, RATE_TYPE_OPTIONS } from '../../../../../../../../../utils';
import { MarketingItems } from '../../MarketingItemComponent';
import { PRODUCT_FEED, MORTGAGE_OPTIONS } from '../../../../enums';

type MortgageProps = {
  hook: any;
  isReadOnly: boolean;
};

export const Mortgage = ({ hook, isReadOnly }: MortgageProps) => (
  <Styled.WrapperStyled>
    <Styled.InputWrappers inverted>
      <Styled.DoubleWrapper>
        <Select
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.MORTGAGE_TYPE.label}
          selected={hook.hookMortgageType}
          onChange={hook.hookChangeMortgageType}
          options={optionsMaker(MORTGAGE_OPTIONS)}
          placeholder={PRODUCT_FEED.MORTGAGE_TYPE.placeholder}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.YEAR.label}
          value={hook.hookMortgageTerm}
          type="text"
          onChange={hook.hookChangeMortageTerm}
          error={hook.hookNewProductErrors.mortgageTerm}
          onBlur={hook.hookValidate}
          placeholder={PRODUCT_FEED.YEAR.placeholder}
        />
      </Styled.DoubleWrapper>
    </Styled.InputWrappers>
    <Styled.InputWrappers inverted>
      <Styled.DoubleWrapper>
        <Select
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.OPEN.label}
          selected={hook.hookOpen}
          onChange={hook.hookChangeOpen}
          options={DUO_OPTIONS}
          placeholder={PRODUCT_FEED.OPEN.placeholder}
        />
        <Select
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.CONVERTIBLE.label}
          selected={hook.hookConvertible}
          onChange={hook.hookChangeConvertible}
          options={DUO_OPTIONS}
          placeholder={PRODUCT_FEED.CONVERTIBLE.placeholder}
        />
      </Styled.DoubleWrapper>
      <Select
        isDisabled={hook.hookLoading || isReadOnly}
        label={PRODUCT_FEED.PRE_APPROVAL.label}
        selected={hook.hookPre}
        onChange={hook.hookChangePre}
        options={DUO_OPTIONS}
        placeholder={PRODUCT_FEED.PRE_APPROVAL.placeholder}
      />
    </Styled.InputWrappers>
    <Styled.InputWrappers inverted>
      <Styled.DoubleWrapper>
        <Select
          isDisabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.RATE_TYPE.label}
          selected={hook.hookRateType}
          onChange={hook.hookChangeRateType}
          options={RATE_TYPE_OPTIONS}
          placeholder={PRODUCT_FEED.RATE_TYPE.placeholder}
        />
        <InputText
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_FEED.APR.label}
          value={hook.hookApr}
          type="text"
          onChange={hook.hookChangeApr}
          tooltip={MONETARY_TOOLTIP}
          error={hook.hookNewProductErrors.apr}
          onBlur={hook.hookValidate}
          placeholder={PRODUCT_FEED.APR.placeholder}
        />
      </Styled.DoubleWrapper>
      <InputText
        disabled={hook.hookLoading || isReadOnly}
        label={PRODUCT_FEED.INTEREST_RATE.label}
        value={hook.hookInterestRate}
        type="text"
        onChange={hook.hookChangeInterestRate}
        error={hook.hookNewProductErrors.interestRate}
        tooltip={MONETARY_TOOLTIP}
        onBlur={hook.hookValidate}
        placeholder={PRODUCT_FEED.INTEREST_RATE.placeholder}
      />
    </Styled.InputWrappers>
    <MarketingItems
      disabled={hook.hookLoading || isReadOnly}
      setMarketingItems={hook.hookSetMarketingItems}
      addMarketingItem={hook.hookAddMarketingItem}
      marketingItems={hook.hookMarketingItems}
      handleXbutton={hook.hookHandleX}
    />

    <Styled.InputWrappers inverted isLast>
      <InputTextarea
        disabled={hook.hookLoading || isReadOnly}
        label={PRODUCT_FEED.GEO_AVAL.label}
        placeholder={PRODUCT_FEED.GEO_AVAL.placeholder}
        value={hook.hookGeoAval}
        onChange={hook.hookChangeGeoAval}
      />
    </Styled.InputWrappers>
  </Styled.WrapperStyled>
);
