import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors, fonts } from '../../../../../../../../styles/theme';

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  ${fonts.lato.H16}
  color: ${colors.color1};
  white-space: break-spaces;
`;

export const DateCellStyled = styled.div`
  display: flex;
  row-gap: 0.5rem;
  ${fonts.lato.H16}
  color: ${colors.color1};
  white-space: break-spaces;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: break-spaces;
  flex-flow: column wrap;
  flex-direction: row;
  align-content: center;
`;

export const MainValStyled = styled.p`
  ${fonts.lato.H16R}
`;

export const SecondaryValStyled = styled.p`
  color: ${colors.color35};
`;

export const IconStyled = styled(FontAwesomeIcon)`
  color: ${colors.color1};
`;
