import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useToast } from '../../../../hooks';
import {
  UPDATE_APP_SUB_SECTION_PERMISSION,
  UPDATE_APP_SECTION_PERMISSION,
  CREATE_NEW_ROLE,
} from '../graphql/mutations';
import { LIST_USER_ROLE_TYPES, GET_PERMISSIONS_BY_ROLE } from '../graphql/queries';
import { AppSectionType, AppSubSectionsType, CRUDActionTypes } from '../types';
import { Permission } from '../../../../entities';

/* List of all userTypes */
const userTypeList: SelectOption[] = [
  {
    label: 'Admin',
    value: 'Admin',
  },
  {
    label: 'Merchant',
    value: 'Merchant',
  },
  {
    label: 'Publisher',
    value: 'Publisher',
  },
];

type SelectionOptionConvertable = {
  name: string;
  id: string;
  [key: string]: any;
};

/** Converts an array with objects that have a name and id property to an SelectionOption[] */
const formatToSelectionOptions = (data: SelectionOptionConvertable[]): SelectOption[] =>
  data.map((item: SelectionOptionConvertable) => ({
    label: item.name,
    value: item.id,
  }));

export const useSecurityPermission = (permissionsCodeList: string[] = []) => {
  // Currently Selected User Role
  const [selectedUserRole, setSelectedUserRole] = useState<SelectOption | undefined>(undefined);
  // List of possible User Roles of a single User Type.
  const [userRoleList, setUserRoleList] = useState<SelectOption[]>([]);
  // Currently Selected User type.
  const [selectedUserType, setSelectedUserType] = useState<string | number>(userTypeList[0].value);
  // List of all permissions for a User Role.
  const [permissionsList, setPermissionsList] = useState<AppSectionType[]>();
  // Name of permissions section that is open.
  const [sectionOpen, setSectionOpen] = useState<string>('');
  const [loadingState, setLoadingState] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newRoleType, setNewRoleType] = useState<SelectOption>(userTypeList[0]);
  const [newRoleName, setNewRoleName] = useState<string>('');

  const setIsModalOpenHandler = (newModalState: boolean) => {
    setIsModalOpen(newModalState);
  };

  const setNewRoleTypeHandler = (newRoleTypeValue: SelectOption) => {
    setNewRoleType(newRoleTypeValue);
  };

  const setNewRoleNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewRoleName(e.target.value);
  };

  const [getPermissionsByRole, { loading: permissionsByRoleLoading }] = useLazyQuery(GET_PERMISSIONS_BY_ROLE, {
    fetchPolicy: 'no-cache',
  });
  const [getListUserRole, { loading: userRoleLoading }] = useLazyQuery(LIST_USER_ROLE_TYPES);

  const [updateAppSubSectionPermission] = useMutation(UPDATE_APP_SUB_SECTION_PERMISSION);
  const [updateAppSectionPermission] = useMutation(UPDATE_APP_SECTION_PERMISSION);

  const [createNewRole] = useMutation(CREATE_NEW_ROLE);

  const { hookShowToast } = useToast();

  const getPermissionsByRoleHandler = async (id: string) => {
    setLoadingMessage('Loading user sub type permissions...');
    setLoadingState(true);
    const { data, error } = await getPermissionsByRole({
      variables: {
        roleId: id,
      },
    });
    setLoadingMessage('');
    setLoadingState(false);
    if (error) {
      setErrorMsg(error.message);
      setPermissionsList([]);
      return;
    }

    setErrorMsg('');
    setPermissionsList(data.permissionsById.appSections);
  };

  const setSelectedUserRoleHandler = (userRole: SelectOption) => {
    setSelectedUserRole(userRole);
    getPermissionsByRoleHandler(userRole.value);
    setSectionOpen('');
  };

  const getListUserRoleHandler = async (userTypesId: string | number) => {
    setLoadingMessage('Loading user sub types...');
    setLoadingState(true);
    const { data, error } = await getListUserRole({
      variables: {
        type: userTypesId,
      },
      fetchPolicy: 'no-cache',
    });
    setLoadingMessage('');
    setLoadingState(false);
    if (error) {
      setErrorMsg(error.message);
      setUserRoleList([]);
    } else if (data.newRolesByType) {
      const rolesList: SelectOption[] = formatToSelectionOptions(data.newRolesByType);
      setUserRoleList(rolesList);
      setErrorMsg('');
    }
  };

  // Clear other fields when swapping User Type.
  const setSelectedUserTypeHandler = (userTypeId: string | number) => {
    setSelectedUserType(userTypeId);
    getListUserRoleHandler(userTypeId);
    setPermissionsList(undefined);
    setSelectedUserRole(undefined);
    setSectionOpen('');
  };

  // Sets the Permission Section that is open.
  const setSectionOpenHandler = (section: string) => {
    if (sectionOpen === section) {
      setSectionOpen('');
    } else {
      setSectionOpen(section);
    }
  };

  const setSubSectionPermissionHandler = async (
    sectionId: string,
    permissionId: string,
    permission: CRUDActionTypes
  ) => {
    setLoadingState(true);
    setLoadingMessage('Updating permission...');
    setErrorMsg('');

    const { errors } = await updateAppSubSectionPermission({
      variables: {
        input: {
          permissionId,
          newValue: permission,
        },
      },
      onError(err) {
        setErrorMsg(err.message);
      },
    });

    if (errors) {
      setLoadingState(false);
      setLoadingMessage('');
      hookShowToast('Error updating permission');
    }

    const newArray: AppSectionType[] = JSON.parse(JSON.stringify(permissionsList));

    const sectionIndex = newArray.findIndex((item: AppSectionType) => item.permissionId === sectionId);
    const subSectionIndex = newArray[sectionIndex].appSubSections.findIndex(
      (item) => item.permissionId === permissionId
    );
    newArray[sectionIndex].appSubSections[subSectionIndex].permission = permission;

    if (newArray[sectionIndex].appSubSections.every(({ permission: oldPermission }) => oldPermission === permission)) {
      newArray[sectionIndex].permission = permission;
    }

    setLoadingState(false);
    setLoadingMessage('');
    hookShowToast('Permission updated successfully');

    setPermissionsList(newArray);
  };

  const setSectionPermissionHandler = async (section: AppSectionType, permission: CRUDActionTypes) => {
    setLoadingState(true);
    setLoadingMessage('Updating permissions...');
    setErrorMsg('');

    const { errors } = await updateAppSectionPermission({
      variables: {
        input: {
          appSectionId: section.appSectionId,
          permissionId: section.permissionId,
          newValue: permission,
        },
      },
      onError(err) {
        setErrorMsg(err.message);
      },
    });

    if (errors !== undefined) {
      setLoadingState(false);
      setLoadingMessage('');
      hookShowToast('Error updating permission');
      return;
    }

    const newArray: AppSectionType[] = JSON.parse(JSON.stringify(permissionsList));

    const updatedSectionPermissionIndex = newArray.findIndex((item) => item.permissionId === section.permissionId);
    newArray[updatedSectionPermissionIndex].permission = permission;

    if (section.appSubSections.length > 0) {
      newArray[updatedSectionPermissionIndex].appSubSections = section.appSubSections.map((item) => ({
        ...item,
        permission,
      }));
    }

    setLoadingState(false);
    setLoadingMessage('');
    hookShowToast('Permission updated successfully');

    setPermissionsList(newArray);
  };

  const checkIfAllSubSectionsAreSelected = (
    section: AppSectionType,
    permissionType: CRUDActionTypes
  ): 'full' | 'half' | 'none' => {
    const filterSubSections = section.appSubSections.filter(
      (subSection: AppSubSectionsType) => subSection.permission === permissionType
    );

    if (filterSubSections.length === 0) {
      return 'none';
    }

    if (filterSubSections.length > 0 && filterSubSections.length < section.appSubSections.length) {
      return 'half';
    }

    if (filterSubSections.length === section.appSubSections.length) {
      return 'full';
    }
    return 'none';
  };

  const createNewRoleHandler = async () => {
    setLoadingState(true);
    setLoadingMessage('Creating Role...');
    setErrorMsg('');
    // Mutation to create a new User Role (also creates all the permission entries in database)
    const { errors } = await createNewRole({
      variables: {
        input: {
          name: newRoleName,
          type: newRoleType.value,
        },
      },
      onError(err) {
        setErrorMsg(err.message);
      },
    });

    if (errors) {
      setLoadingState(false);
      setLoadingMessage('');
      hookShowToast('Error creating new role');
      return;
    }

    setIsModalOpenHandler(false);

    // format dropdown list after call
    getListUserRoleHandler(selectedUserType);
    setNewRoleName('');
    setLoadingState(false);
    setLoadingMessage('');
    hookShowToast('Created new user role successfully');
  };

  useEffect(() => {
    setSelectedUserTypeHandler('Admin');
  }, []);

  return {
    hookSelectedUserRole: selectedUserRole,
    hookSetSelectedUserRole: setSelectedUserRoleHandler,
    hookUserTypeList: userTypeList,
    hookUserRoleList: userRoleList,
    hookSelectedUserType: selectedUserType,
    hookSetSelectedUserType: setSelectedUserTypeHandler,
    hookPermissionsList: permissionsList,
    hookSectionOpen: sectionOpen,
    hookSetSectionOpen: setSectionOpenHandler,
    hookSetSectionPermission: setSectionPermissionHandler,
    hookSetSubSectionPermission: setSubSectionPermissionHandler,
    hookLoading: loadingState || userRoleLoading || permissionsByRoleLoading,
    hookLoadingMessage: loadingMessage,
    hookCheckIfAllSubSectionsAreSelected: checkIfAllSubSectionsAreSelected,

    hookIsModalOpen: isModalOpen,
    hookSetIsModalOpenHandler: setIsModalOpenHandler,
    hookNewRoleType: newRoleType,
    hookNewRoleName: newRoleName,
    hookSetNewRoleTypeHandler: setNewRoleTypeHandler,
    hookCreateNewRoleHandler: createNewRoleHandler,
    hookSetNewRoleNameHandler: setNewRoleNameHandler,

    hookErrorMsg: errorMsg,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
