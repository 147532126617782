import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export const BUTTON_LABEL = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  NEXT: 'Next',
  SELECT: 'Select Merchant and Period',
  CLEAR: 'Clear Form',
  DOWNLOAD: 'Download CSV',
  UPDATE_STATS: 'Update Stats',
  ADD_FEE: 'Add Fees',
  ADD_DEPOSIT: 'Add Deposit',
  SUBMIT: 'submit',
  UPDATE: 'Update',
};

export const DEFAULT_TOTALS = {
  rollover: '$0',
  deposits: '$0',
  commissions: '$0',
  manualAdjustment: '$0',
  systemUsageFee: '$0',
  otherFees: '$0',
  taxes: '$0',
  balance: '$0',
};

export const SUBTITLE = {
  STATS: 'Account Balance Stats',
  MONTHLY: 'Monthly Account Balance',
  FEE_MODAL: 'Add Account Balance Fee',
  DEPOSIT_MODAL: 'Add Deposit Received',
};

export const INPUT_LABEL = {
  CATEGORY: 'Product Category',
  DATE: 'date range',
  FEE_AMOUNT: 'fee amount',
  FEE_DATE: 'fee date',
  FEE_TYPE: 'fee type',
  FEE_OTHER: 'if other, please specify type',
  DEPOSIT_AMOUNT: 'Deposit amount',
  DEPOSIT_DATE: 'Deposit date',
  DEPOSIT_TYPE: 'Deposit type',
  DEPOSIT_DETAILS: 'DETAILS',
  REFERENCE_NUMBER: 'reference number',
  PRODUCT_CATEGORY: 'product category',
  NOTES: 'notes',
  NOTIFY_MERCHANT: 'Notify the Merchant',
  BALANCE_REPORTING: 'Enable Product Account Balance Reporting',
};

export const PLACEHOLDER = {
  DATE: 'All Periods',
  FEE_DATE: 'Select Date',
  DEPOSIT_DETAILS: 'No details needed',
  SELECT: 'Select ...',
};

export const PRODUCT_CATEGORIES = [
  { label: 'All Categories', value: '' },
  { label: 'Banking', value: 'Banking' },
  { label: 'Business Products', value: 'Businesses' },
  { label: 'Credit Cards', value: 'Credit Cards' },
  { label: 'Credit Reporting', value: 'Credit Reporting' },
  { label: 'Cryptocurrency', value: 'Cryptocurrency' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Investments', value: 'Investments' },
  { label: 'Loans', value: 'Loans' },
  { label: 'Mortgage', value: 'Mortgage' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Tax Services', value: 'Tax Services' },
  { label: 'Wills & Estate Planning', value: 'Wills & Estate Planning' },
];

export const FEE_TYPES = [
  { label: 'Setup Fee', value: 'Setup Fee' },
  { label: 'Other', value: 'Other' },
];

export const DEPOSIT_TYPES = [
  { label: 'Paypal', value: 'Paypal' },
  { label: 'Cheque', value: 'Cheque' },
  { label: 'Wire Transfer', value: 'Wire Transfer' },
];

export const MODAL_TYPES = {
  FEE: 'fee',
  DEPOSIT: 'deposit',
};

export const ENABLE_BALANCE_REPORTING_MESSAGE = {
  TITLE: 'Before you enable product account balance reporting',
  DESCRIPTION_TITLE: 'Products & Categories',
  DESCRIPTION:
    'Review all current products - all products should have a category. If any products need to move between categories its recommended they are updated before enabling category reporting.',
  DESCRIPTION_TWO_TITLE: 'Commissions',
  DESCRIPTION_TWO:
    'All commissions should be set per product this will allow commissions to always apply to a specific category - we recommend ending the old “all product” as of the last month the balance will be on merchant level and enter commissions per product starting for the period the product account balance reporting starts.',
  ICON: faExclamationCircle,
};

export const ENABLE_BALANCE_REPORTING_MODAL = {
  TITLE: 'Product Account Balance Reporting',
  SUB_TITLE: 'Are you sure you want to enable Product Account Balance Reporting?',
  LIST_TITLE: 'Once the setting is enabled,',
  LIST: [
    [{ text: 'It cannot be back to Merchant Account Balance Reporting' }],
    [
      { text: 'Manual fees, Deposits, Publisher adjustments should be ' },
      { text: 'REQUIRED', style: 'bold' },
      { text: ' to select a product category' },
    ],
    [
      { text: 'A Product Category should be ' },
      { text: 'REQUIRED', style: 'bold' },
      { text: ' for all products belong to this merchant' },
    ],
  ],
};

export const NEW_STATS_UPDATE_MESSAGE = {
  TITLE: 'New Stats need update',
  DESCRIPTION:
    "Please update the active category and fees before starting the next period otherwise the product level reporting won't be applied.",
  ICON: faExclamationCircle,
};

export const SORT_DEFAULT: TableSortColumn = { column: 'year', direction: 'desc' };

export const PLACEHOLDER_MONTHLY_RECORDED = {
  TITLE: 'Sorry! No reports found',
  SUBTITLE: 'No Account Balance Reports are available for review during this time period.',
};
