import React from 'react';
import {
  faQuestionCircle,
  faFlag as checked,
  faExclamationTriangle,
  faCheckCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faFlag as unchecked } from '@fortawesome/free-regular-svg-icons';

import { CellStyled } from '../styles';
import { StatusTypes, TableProps } from '../types';
import * as Styled from '../styles';

export const StatusCell = ({ val, row, openModal, setModalInfo }: TableProps) => {
  const status = () => {
    switch (val) {
      case 'NF':
        return 'Not Applicable';
      case 'Check Fail':
        return 'Fail';
      default:
        return val;
    }
  };

  const icon = () => {
    switch (val as StatusTypes) {
      case 'Not Applicable':
      case 'NF':
        return faQuestionCircle;
      case 'Pass':
        return faCheckCircle;
      case 'Fail':
      case 'Check Fail':
        return faMinusCircle;
      case 'Review':
        return faExclamationTriangle;
      case 'Incomplete':
      default:
        return faQuestionCircle;
    }
  };

  const handleClick = () => {
    openModal();
    const correctStatus = (() => {
      if (!row.feedback) return undefined;
      if (row.feedback.correct === 'No') return false;
      if (row.feedback.correct === 'Yes') return true;
      return undefined;
    })();

    setModalInfo({
      id: row.id,
      correctStatus,
      shouldBeStatus: row.feedback?.shouldBeStatus || undefined,
      currStatus: row.ruleStatus === 'NF' ? 'Not Applicable' : row.ruleStatus || undefined,
    });
  };

  return (
    <CellStyled>
      <Styled.ButtonStyled theme="text-only" onClick={handleClick}>
        <Styled.TooltipStyled
          feedback={!!row.feedback}
          text={row.feedback ? 'Update Feedback' : 'Add Feedback'}
          icon={row.feedback ? checked : unchecked}
        />
      </Styled.ButtonStyled>
      <Styled.StatusStyled status={status()}>
        <Styled.FontAwesomeIconStyled status={status()} icon={icon()} />
        {status()}
      </Styled.StatusStyled>
    </CellStyled>
  );
};
