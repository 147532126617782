import styled from 'styled-components';

import { toRem } from '../../../../../utils';
import { colors, fonts } from '../../../../../styles/theme';

export const TableWrapper = styled.div`
  margin-top: ${toRem(40)};
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${toRem(80)};
  padding: 0 ${toRem(10)};
  border-bottom: 0.01em solid ${colors.color19};
  align-items: center;
`;

export const KeyStyled = styled.p`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  text-transform: uppercase;
  margin: ${toRem(16)} 0 ${toRem(12)};
  width: 50%;
`;

export const ValueStyled = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  margin: ${toRem(24)} 0;
  max-width: 50%;
  min-width: 50%;
`;

export const BoldValueStyled = styled.b`
  font-weight: 400;
  font-family: gotham_promedium;
  line-height: 1.6;
`;
