import React from 'react';

import { CellProps } from '../types';
import * as Styled from '../styles';

export const PageUrlCell = ({ val }: CellProps) => (
  <Styled.CellStyled>
    <a href={val} target="_blank" rel="noreferrer">
      {val}
    </a>
  </Styled.CellStyled>
);
