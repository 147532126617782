export const MANAGE_SETTINGS = {
  TITLE: 'Manage Tracking & Transaction Settings',
  BUTTONS: {
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
  CURRENCY_ERROR: 'Transaction Currency is empty, Please select an option from the dropdown.',
  SETTINGS_UPDATED: 'Tracking and Transaction settings updated!',
  SAVE_DIALOG_TITLE: 'Save Changes',
  SAVE_DIALOG_DESCRIPTION:
    'The changes to settings will apply immediately to all existing tracking links & transactions - are you sure you want to Save Changes?',
  TRACKING_SETTINGS_TAB_TITLE: 'Tracking Settings',
  TRANSACTION_SETTINGS_TAB_TITLE: 'Transaction Settings',
};
