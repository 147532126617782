import { faCircleExclamation, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { Button, ErrorBanner, InputText, Select } from '../../../../components';
import Progress from '../../../../components/Progress';
import { imageList, path } from '../../../../utils';
import { SIGN_UP_ACTIONS } from '../../reducers';
import { SIGN_UP } from '../enums';

import * as Styled from './styles';
import { usePaymentInfo } from './hooks';
import { PAYMENTS_INFO } from './enums';

const PaymentInfo = () => {
  const hook = usePaymentInfo();

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img src={imageList.logoFull.src} alt="logo" />
        </figure>

        <Progress step={4} />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
          <h1>{SIGN_UP.TITLE}</h1>
          <h2>
            <pre>{`Step 4   ${path.createAccountStep4.name}`}</pre>
          </h2>

          <Styled.FormStyled>
            <Styled.SelectStyled
              label={PAYMENTS_INFO.CURRENCY}
              onChange={(e: SelectOption) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { paymentCurrency: e },
                })
              }
              options={hook.hookCurrencyList}
              selected={hook.hookContextState.paymentCurrency}
            />
            <Select
              label={PAYMENTS_INFO.METHOD}
              onChange={(e: SelectOption) => {
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { paymentMethod: e },
                });
                if (e.value === 'Paypal') {
                  hook.hookRepeatInfo(false); // False sets this to true
                }
              }}
              options={hook.hookPaymentMethodList}
              selected={hook.hookContextState.paymentMethod}
            />
            <Styled.InfoStyled>
              <Styled.IconStyled icon={faCircleExclamation} />
              <span>{PAYMENTS_INFO.MINIMUM_PAYOUT}</span>
            </Styled.InfoStyled>
            {hook.hookContextState.paymentMethod.value !== 'Wire Transfer' &&
              hook.hookContextState.paymentMethod.value !== 'Paypal' && (
                <Styled.InputStyled
                  label={PAYMENTS_INFO.PAYABLE.LABEL}
                  required
                  type="text"
                  placeholder={PAYMENTS_INFO.PAYABLE.PLACEHOLDER}
                  value={hook.hookContextState.paymentPayable}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentPayable: e.target.value },
                    })
                  }
                  error={hook.hookInputErrors?.paymentPayable}
                />
              )}
            {hook.hookContextState.paymentMethod.value === 'Wire Transfer' && (
              <>
                <Styled.InputStyled
                  label={PAYMENTS_INFO.BANK_NAME}
                  required
                  type="text"
                  value={hook.hookContextState.bankName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { bankName: e.target.value },
                    })
                  }
                  error={hook.hookInputErrors?.bankName}
                />
                <Styled.InputStyled
                  label={PAYMENTS_INFO.BANK_ACCOUNT_NUMBER}
                  required
                  type="text"
                  value={hook.hookContextState.bankAccountNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { bankAccountNumber: e.target.value },
                    })
                  }
                  error={hook.hookInputErrors?.bankAccountNumber}
                />
                <InputText
                  label={PAYMENTS_INFO.SWIFT_CODE}
                  required
                  type="text"
                  value={hook.hookContextState.swiftCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { swiftCode: e.target.value },
                    })
                  }
                  error={hook.hookInputErrors?.swiftCode}
                />
                <InputText
                  label={PAYMENTS_INFO.TRANSIT}
                  type="text"
                  value={hook.hookContextState.transit}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { transit: e.target.value },
                    })
                  }
                  error={hook.hookInputErrors?.transit}
                />
                <Styled.InputStyled
                  label={PAYMENTS_INFO.WIRE_TRANSFER_NAME}
                  required
                  type="text"
                  value={hook.hookContextState.wireTransferName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { wireTransferName: e.target.value },
                    })
                  }
                  error={hook.hookInputErrors?.wireTransferName}
                />
              </>
            )}
            {hook.hookContextState.paymentMethod.value === 'Paypal' && (
              <>
                <Styled.InputStyled
                  label={PAYMENTS_INFO.ACCOUNT_HOLDER}
                  required
                  type="text"
                  value={hook.hookContextState.accountHolder}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { accountHolder: e.target.value },
                    })
                  }
                  error={hook.hookInputErrors?.accountHolder}
                />
                <Styled.InputStyled
                  label={PAYMENTS_INFO.ACCOUNT_EMAIL_ADDRESS}
                  required
                  type="text"
                  value={hook.hookContextState.accountEmailAddress}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { accountEmailAddress: e.target.value },
                    })
                  }
                  error={hook.hookInputErrors?.accountEmailAddress}
                />
                <Styled.InputPhoneStyled
                  label={PAYMENTS_INFO.PHONE}
                  required
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentPhone: e || '' },
                    })
                  }
                  value={hook.hookContextState.paymentPhone}
                  error={hook.hookInputErrors?.paymentPhone}
                />
              </>
            )}
            {hook.hookContextState.paymentMethod.value !== 'Paypal' && (
              <>
                <Styled.CheckboxStyled
                  label={PAYMENTS_INFO.INFO_AS_COMPANY}
                  checked={hook.hookContextState.paymentRepeatInfo}
                  onChange={() => hook.hookRepeatInfo(hook.hookContextState.paymentRepeatInfo)}
                />
                <Styled.InputStyled
                  label={PAYMENTS_INFO.EMAIL.LABEL}
                  required
                  type="email"
                  placeholder={PAYMENTS_INFO.EMAIL.PLACEHOLDER}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentEmail: e.target.value },
                    })
                  }
                  value={hook.hookContextState.paymentEmail}
                  disabled={hook.hookContextState.paymentRepeatInfo}
                  error={hook.hookInputErrors?.paymentEmail}
                />
                <Styled.InputPhoneStyled
                  label={PAYMENTS_INFO.PHONE}
                  required
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentPhone: e || '' },
                    })
                  }
                  value={hook.hookContextState.paymentPhone}
                  disabled={hook.hookContextState.paymentRepeatInfo}
                  error={hook.hookInputErrors?.paymentPhone}
                />
                <Styled.InputStyled
                  label={PAYMENTS_INFO.ADDRESS1}
                  required
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentAddress1: e.target.value },
                    })
                  }
                  value={hook.hookContextState.paymentAddress1}
                  disabled={hook.hookContextState.paymentRepeatInfo}
                  error={hook.hookInputErrors?.paymentAddress}
                />
                <Styled.InputStyled
                  label={PAYMENTS_INFO.ADDRESS2}
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentAddress2: e.target.value },
                    })
                  }
                  value={hook.hookContextState.paymentAddress2}
                  disabled={hook.hookContextState.paymentRepeatInfo}
                />
                <InputText
                  label={PAYMENTS_INFO.CITY}
                  required
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentCity: e.target.value },
                    })
                  }
                  value={hook.hookContextState.paymentCity}
                  disabled={hook.hookContextState.paymentRepeatInfo}
                  error={hook.hookInputErrors?.paymentCity}
                />
                <Select
                  required
                  label={PAYMENTS_INFO.COUNTRY}
                  options={hook.hookCountryList}
                  selected={hook.hookContextState.paymentCountry}
                  onChange={(e: SelectOption) => {
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentCountry: e, paymentState: { label: '', value: '' } },
                    });
                  }}
                  isDisabled={hook.hookContextState.paymentRepeatInfo}
                  error={hook.hookInputErrors?.paymentCountry}
                />
                <Select
                  required
                  label={PAYMENTS_INFO.STATE}
                  options={hook.hookRegionList}
                  selected={hook.hookContextState.paymentState}
                  onChange={(e: SelectOption) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentState: e },
                    })
                  }
                  isDisabled={
                    hook.hookContextState.paymentRepeatInfo || hook.hookContextState.paymentCountry.label === ''
                  }
                  error={hook.hookInputErrors?.paymentState}
                />
                <InputText
                  label={PAYMENTS_INFO.ZIP}
                  required
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    hook.hookContextDispatcher({
                      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                      data: { paymentZip: e.target.value },
                    })
                  }
                  value={hook.hookContextState.paymentZip}
                  disabled={hook.hookContextState.paymentRepeatInfo}
                  error={hook.hookInputErrors?.paymentZip}
                />
              </>
            )}
            <Styled.InfoBoxStyled
              title={PAYMENTS_INFO.INFO.TITLE}
              description={PAYMENTS_INFO.INFO.DESCRIPTION}
              icon={faCircleInfo}
            />

            <InputText
              label={PAYMENTS_INFO.GST.LABEL}
              type="text"
              placeholder={PAYMENTS_INFO.GST.PLACEHOLDER}
              disabled={hook.hookDisableGST}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { paymentGST: e.target.value },
                })
              }
              value={hook.hookContextState.paymentGST}
              error={hook.hookTaxErrors?.paymentGST}
            />
            <InputText
              label={PAYMENTS_INFO.PST.LABEL}
              type="text"
              placeholder={PAYMENTS_INFO.PST.PLACEHOLDER}
              disabled={hook.hookDisablePST}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { paymentPST: e.target.value },
                })
              }
              value={hook.hookContextState.paymentPST}
              error={hook.hookTaxErrors?.paymentPST}
            />
            <InputText
              label={PAYMENTS_INFO.HST.LABEL}
              type="text"
              placeholder={PAYMENTS_INFO.HST.PLACEHOLDER}
              disabled={hook.hookDisableHST}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { paymentHST: e.target.value },
                })
              }
              value={hook.hookContextState.paymentHST}
              error={hook.hookTaxErrors?.paymentHST}
            />
            <InputText
              label={PAYMENTS_INFO.TAX.LABEL}
              type="text"
              placeholder={PAYMENTS_INFO.TAX.PLACEHOLDER}
              disabled={hook.hookDisableTAX}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                hook.hookContextDispatcher({
                  type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
                  data: { paymentTAX: e.target.value },
                })
              }
              value={hook.hookContextState.paymentTAX}
              error={hook.hookTaxErrors?.paymentTAX}
            />

            <Button onClick={() => hook.hookNavigate(path.createAccountStep3.href)} theme="secondary">
              {PAYMENTS_INFO.BACK}
            </Button>
            <Button onClick={hook.hookNavigateNextPageHandler}>{PAYMENTS_INFO.NEXT}</Button>
          </Styled.FormStyled>
        </Styled.RightContentStyled>
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};

export default PaymentInfo;
