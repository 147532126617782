import React from 'react';

import { Pagination, Table } from '../../../../../../components';
import * as TabStyled from '../styles';

import { useCampaigns } from './hooks';
import { columns } from './contracts';

const CampaignsTab = () => {
  const hook = useCampaigns();
  return (
    <TabStyled.WrapperStyled>
      <Table columns={columns} data={hook.hookCampaigns} isLoading={hook.hookLoading} />
      {!hook.hookLoading && (
        <Pagination
          total={hook.hookTotalPages}
          currentPage={hook.hookCampaigns?.length > 0 ? hook.hookCurrentPage : 0}
          onPageChange={hook.hookSetCurrentPage}
        />
      )}
    </TabStyled.WrapperStyled>
  );
};

export default CampaignsTab;
