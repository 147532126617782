import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';

type RequiredFieldsProps = {
  margin?: string;
};

export const BannerWrapper = styled.div`
  width: 100%;
`;

export const RequiredFieldsWarning = styled.div<RequiredFieldsProps>`
  color: ${colors.color8};
  width: 100%;
  border: 2px solid ${colors.color8};
  padding: 1rem;
  margin: ${({ margin }) => margin || '0 0 2.5rem'};
  background-color: ${colors.color4};
`;

export const RequiredTextStyled = styled.span`
  ${fonts.lato.H16};
  padding-left: 1rem;
`;
