import React from 'react';
import styled from 'styled-components';

import { colors } from 'styles/theme';
import { dateFormatter, PUBLISHER_PREFIX, path, USER_TYPES_NAMES } from 'utils';

import { PerformanceGroupBy, Totals } from '../types';

import { moneyFormater } from './moneyFormater';

type TAdCallBack = (value: any) => void;

const CellWrapperStyled = styled.div`
  color: ${colors.color1};
  height: 25px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CellLinkStyled = styled.a`
  color: ${colors.color1};
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    color: ${colors.color2};
  }
`;

const FooterWrapperStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color2};

  p {
    margin-bottom: 0.5rem;
  }
`;

const OpenAddButtonStyled = styled.button`
  background: transparent;
  border: none;
  font-size: 1rem;
  text-align: left;
  color: ${colors.color1};
  cursor: pointer;

  &:hover {
    color: ${colors.color2};
  }
`;

const defaultFooter = (
  <FooterWrapperStyled>
    <p>Total CA</p>
    <p>Total US</p>
  </FooterWrapperStyled>
);

const callBack = (adCb: any, value: any): JSX.Element => (
  <CellWrapperStyled>
    <OpenAddButtonStyled onClick={() => adCb(value)}>{value}</OpenAddButtonStyled>
  </CellWrapperStyled>
);

const totalFooter = (cadUsd?: number[], isMoney = false): JSX.Element => (
  <FooterWrapperStyled>
    {cadUsd && (
      <>
        <p>
          {isMoney && `CAD ${moneyFormater.format(cadUsd[0])}`}
          {!isMoney && cadUsd[0]}
        </p>
        <p>
          {isMoney && `USD ${moneyFormater.format(cadUsd[1])}`}
          {!isMoney && cadUsd[1]}
        </p>
      </>
    )}
  </FooterWrapperStyled>
);

const globalColumns = (totals: Totals | undefined): TableColumn[] => {
  const globalColumnsValue: TableColumn[] = [
    {
      dataField: 'impressions',
      text: 'Impressions',
      width: '150px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.impressions),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'int',
    },
    {
      dataField: 'uniqueImpressions',
      text: 'Unique Impressions',
      width: '150px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.uniqueImpressions),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'int',
    },
    {
      dataField: 'ctr',
      text: 'CTR',
      width: '120px',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      formatter: (value: any) => <CellWrapperStyled>{`${value.toFixed(2)}%`}</CellWrapperStyled>,
      dataType: 'percentage',
    },
    {
      dataField: 'clicks',
      text: 'Clicks',
      width: '150px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.clicks),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'int',
    },
    {
      dataField: 'uniqueClicks',
      text: 'Unique Clicks',
      width: '150px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.uniqueClicks),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'int',
    },
    {
      dataField: 'conversions',
      text: 'Conversions %',
      width: '160px',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      formatter: (value: any) => <CellWrapperStyled>{`${value.toFixed(2)}%`}</CellWrapperStyled>,
      dataType: 'percentage',
    },
    {
      dataField: 'epc',
      text: 'EPC',
      width: '120px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.epc, true),
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      dataType: 'money',
    },
    {
      dataField: 'newPendingTransactions',
      text: 'New Pending Transactions',
      width: '170px',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'count',
    },
    {
      dataField: 'declinedTransactions',
      text: 'Declined Transactions',
      width: '170px',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'count',
    },
    {
      dataField: 'softDeclinedTransactions',
      text: 'Soft Declined Transactions',
      width: '170px',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'count',
    },
    {
      dataField: 'verifiedTransactions',
      text: 'Verify Transactions',
      width: '170px',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'count',
    },
    {
      dataField: 'oldPendingTransactions',
      text: 'Old Pending Transactions',
      width: '170px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.oldPendingTransactions),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'count',
    },
    {
      dataField: 'oldApprovedTransactions',
      text: 'Old Approved Transactions',
      width: '170px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.oldApprovedTransactions),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'count',
    },
    {
      dataField: 'approvedTransactions',
      text: 'Approved Transactions',
      width: '170px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.approvedTransactions),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'count',
    },
    {
      dataField: 'totalTransactions',
      text: 'Total Transactions',
      width: '170px',
      footer: 'TOTAL',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.totalTransactions),
      formatter: (value: any) => <CellWrapperStyled>{value}</CellWrapperStyled>,
      dataType: 'count',
    },
    {
      dataField: 'approvalRate',
      text: 'Approval Rate',
      width: '170px',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      formatter: (value: any) => <CellWrapperStyled>{`${value.toFixed(2)}%`}</CellWrapperStyled>,
      dataType: 'percentage',
    },
    {
      dataField: 'pendingGrossValue',
      text: 'Pending Gross Value',
      width: '170px',
      footer: 'TOTAL $',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.pendingGrossValue, true),
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      dataType: 'money',
    },
    {
      dataField: 'totalGrossValue',
      text: 'Total Gross Value',
      width: '170px',
      footer: 'TOTAL $',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.totalGrossValue, true),
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      dataType: 'money',
    },
    {
      dataField: 'totalNetValue',
      text: 'Total Net Value',
      width: '170px',
      footer: 'TOTAL $',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.totalNetValue, true),
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      dataType: 'money',
    },
    {
      dataField: 'commissionCpa',
      text: 'CPA Commission',
      width: '170px',
      footer: 'TOTAL $',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.commissionCpa, true),
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      dataType: 'money',
    },
    {
      dataField: 'commission',
      text: 'Commission %',
      width: '170px',
      footer: 'TOTAL $',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.commission, true),
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      dataType: 'money',
    },
    {
      dataField: 'totalCommission',
      text: 'Total Commission',
      width: '170px',
      footer: 'TOTAL $',
      sortFunction: (a: any, b: any) => a.localeCompare(b),
      footerFormatter: () => totalFooter(totals?.totalCommission, true),
      formatter: (value: any) => <CellWrapperStyled>{moneyFormater.format(value)}</CellWrapperStyled>,
      dataType: 'money',
    },
  ];

  return globalColumnsValue;
};

const merchantColumns: TableColumn[] = [
  {
    dataField: 'merchantId',
    text: 'Merchant ID',
    width: '120px',
    footer: 'Total',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any, row: any) =>
      row?.userType === USER_TYPES_NAMES.PUBLISHER ? (
        <CellWrapperStyled>
          <CellLinkStyled
            href={`${PUBLISHER_PREFIX}${path.programDetails.href}?programId=${row?.merchant?.program?.id}`}
            target="_blank"
          >
            {row.merchant.id || 'N/A'}
          </CellLinkStyled>
        </CellWrapperStyled>
      ) : (
        <CellWrapperStyled>{row.merchant.id || 'N/A'}</CellWrapperStyled>
      ),
    footerFormatter: () => defaultFooter,
    dataType: 'key',
  },
  {
    dataField: 'merchantName',
    text: 'Merchant Name',
    width: '200px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any, row: any) =>
      row?.userType === USER_TYPES_NAMES.PUBLISHER ? (
        <CellWrapperStyled>
          <CellLinkStyled
            href={`${PUBLISHER_PREFIX}${path.programDetails.href}?programId=${row?.merchant?.program?.id}`}
            target="_blank"
          >
            {row.merchant.companyName || 'N/A'}
          </CellLinkStyled>
        </CellWrapperStyled>
      ) : (
        <CellWrapperStyled>{row.merchant.companyName || 'N/A'}</CellWrapperStyled>
      ),
    dataType: 'string',
  },
  {
    dataField: 'merchantUrl',
    text: 'Merchant URL',
    width: '220px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any, row: any) => (
      <CellWrapperStyled>
        <CellLinkStyled href={value} target="_blank">
          {row.merchant.companyUrl || 'N/A'}
        </CellLinkStyled>
      </CellWrapperStyled>
    ),
    dataType: 'string',
  },
];

const publisherIdColumns: TableColumn[] = [
  {
    dataField: 'publisherId',
    text: 'Publisher (ID)',
    width: '120px',
    footer: 'Total',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    footerFormatter: () => defaultFooter,
    dataType: 'key',
  },
];

const publisherColumns: TableColumn[] = [
  {
    dataField: 'publisherName',
    text: 'Publisher Name',
    width: '200px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    dataType: 'string',
  },
  {
    dataField: 'publisherUrl',
    text: 'Publisher URL',
    width: '220px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => (
      <CellWrapperStyled>
        <CellLinkStyled href={value || undefined} target="_blank">
          {value || 'N/A'}
        </CellLinkStyled>
      </CellWrapperStyled>
    ),
    dataType: 'string',
  },
];

const dateColumns: TableColumn[] = [
  {
    dataField: 'date',
    text: 'Date',
    width: '200px',
    footer: 'Total',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{dateFormatter(new Date(value)) || 'N/A'}</CellWrapperStyled>,
    footerFormatter: () => defaultFooter,
    dataType: 'key',
  },
];

const monthColumns: TableColumn[] = [
  {
    dataField: 'month',
    text: 'Month',
    width: '200px',
    footer: 'Total',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{dateFormatter(new Date(value), '/', true)}</CellWrapperStyled>,
    footerFormatter: () => defaultFooter,
    dataType: 'key',
  },
];

const adIdColumns: TableColumn[] = [
  {
    dataField: 'adId',
    text: 'Ad (ID)',
    width: '120px',
    footer: 'Total',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    footerFormatter: () => defaultFooter,
    dataType: 'key',
  },
];

const trackingProfileIdColumns: TableColumn[] = [
  {
    dataField: 'trackingProfileId',
    text: 'Tracking Profile (ID)',
    width: '120px',
    footer: 'Total',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    footerFormatter: () => defaultFooter,
    dataType: 'key',
  },
  {
    dataField: 'publisherId',
    text: 'Publisher (ID)',
    width: '120px',
    footer: 'Total',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    dataType: 'string',
  },
  {
    dataField: 'publisherName',
    text: 'Publisher Name',
    width: '200px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    dataType: 'string',
  },
];

const productColumns: TableColumn[] = [
  {
    dataField: 'productId',
    text: 'Product (ID)',
    width: '150px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    dataType: 'string',
  },
  {
    dataField: 'productName',
    text: 'Product Name',
    width: '200px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    dataType: 'string',
  },
];

const productCategoryColumns: TableColumn[] = [
  {
    dataField: 'productCategory',
    text: 'Product Category',
    width: '200px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    dataType: 'string',
  },
];

const campaignIdColumns: TableColumn[] = [
  {
    dataField: 'campaignId',
    text: 'Campaign (ID)',
    width: '150px',
    footer: 'Total',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    footerFormatter: () => defaultFooter,
    dataType: 'key',
  },
  {
    dataField: 'campaignName',
    text: 'Campaign Name',
    width: '200px',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (value: any) => <CellWrapperStyled>{value || 'N/A'}</CellWrapperStyled>,
    dataType: 'string',
  },
];

export const unremovableColumns: string[] = [
  'merchantId',
  'merchantName',
  'merchantUrl',
  'publisherId',
  'publisherName',
  'publisherUrl',
  'date',
  'month',
  'adId',
  'trackingProfileId',
  'productId',
  'productName',
  'category',
  'campaignId',
  'campaignName',
];

export const getColumnsByGroupBy = (
  groupBy: PerformanceGroupBy,
  adCb?: TAdCallBack,
  totals?: Totals
): TableColumn[] => {
  switch (groupBy.value) {
    case 'PUBLISHER':
      return [...publisherIdColumns, ...globalColumns(totals)];
    case 'PUBLISHER_NAME':
      return [...publisherIdColumns, ...publisherColumns, ...globalColumns(totals)];
    case 'DATE':
      return [...dateColumns, ...globalColumns(totals)];
    case 'MONTH':
      return [...monthColumns, ...globalColumns(totals)];
    case 'AD_ID':
      return [
        {
          ...adIdColumns[0],
          formatter: (value: any) => callBack(adCb, value),
        },
        ...globalColumns(totals),
      ];
    case 'TRACKING_PROFILE':
      return [...trackingProfileIdColumns, ...globalColumns(totals)];
    case 'PRODUCT':
      return [
        {
          ...productColumns[0],
          footer: 'Total',
          footerFormatter: () => defaultFooter,
          dataType: 'key',
        },
        productColumns[1],
        ...productCategoryColumns,
        ...globalColumns(totals),
      ];
    case 'PRODUCT_CATEGORY':
      return [
        {
          ...productCategoryColumns[0],
          footer: 'Total',
          footerFormatter: () => defaultFooter,
          dataType: 'key',
        },
        ...globalColumns(totals),
      ];
    case 'AD_CAMPAIGN':
      return [...campaignIdColumns, ...globalColumns(totals)];
    default:
      return [...merchantColumns, ...globalColumns(totals)];
  }
};

export const getColumnsByGroupByCSV = (groupBy: PerformanceGroupBy): TableColumn[] => {
  switch (groupBy.value) {
    case 'PUBLISHER':
      return [...publisherIdColumns, ...globalColumns(undefined)];
    case 'PUBLISHER_NAME':
      return [...publisherIdColumns, ...publisherColumns, ...globalColumns(undefined)];
    case 'DATE':
      return [...dateColumns, ...globalColumns(undefined)];
    case 'MONTH':
      return [...monthColumns, ...globalColumns(undefined)];
    case 'AD_ID':
      return [...adIdColumns, ...globalColumns(undefined)];
    case 'TRACKING_PROFILE':
      return [...trackingProfileIdColumns, ...globalColumns(undefined)];
    case 'PRODUCT':
      return [...productColumns, ...productCategoryColumns, ...globalColumns(undefined)];
    case 'PRODUCT_CATEGORY':
      return [...productCategoryColumns, ...globalColumns(undefined)];
    case 'AD_CAMPAIGN':
      return [...campaignIdColumns, ...globalColumns(undefined)];
    default:
      return [...merchantColumns, ...globalColumns(undefined)];
  }
};
