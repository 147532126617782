import styled from 'styled-components';

type CellWrapperProps = {
  readonly ellipsis?: boolean;
};
export const CellWrapper = styled.div<CellWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;
  ${({ ellipsis }) =>
    ellipsis
      ? `p {
      width: calc(100%);
      text-overflow: ellipsis;
      overflow: hidden;
    }}`
      : 'white-space: normal;'};
`;
