import React from 'react';

import { Checkbox } from '../../../../../components';

import { Categories, Cell, Company, Header } from './components';
import { CellStyled } from './styles';

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checked',
    text: '',
    width: '5%',
    headerFormatter: (checked, onChange) => Header(checked, onChange, isReadOnly),
    formatter: (val) => (
      <CellStyled>
        <Checkbox key={val} checked={val} onChange={() => ''} disabled={isReadOnly} />
      </CellStyled>
    ),
  },
  {
    dataField: 'id',
    text: 'Id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '10%',
    formatter: (val) => <Cell val={val} />,
  },
  {
    dataField: 'company', // takes companyName and trackings.primaryWebsite
    text: 'Company Name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '30%',
    formatter: (val) => <Company val={val} />,
  },
  {
    dataField: 'country',
    text: 'Location',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '15%',
    formatter: (val) => <Cell val={val} />,
  },
  {
    dataField: 'productCategories',
    text: 'Categories',
    width: '40%',
    formatter: (val) => <Categories val={val} />,
  },
];
