import React, { useEffect, useState } from 'react';

import { PageTitle } from '../PageTitle';
import { Tabs } from '../Tabs';

import { useAdGenerateCode } from './hooks';
import * as Styled from './styles';
import AdDetailsTab from './Tabs/AdDetails';
import GetCodeTab from './Tabs/GetCode';

type AdGenerateCodeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  adId: string;
};

export const AdGenerateCodeModal = ({ isOpen, onClose, adId }: AdGenerateCodeModalProps) => {
  const hook = useAdGenerateCode(isOpen);
  const [isLarge, setIsLarge] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      hook.hookGetAd(adId);
    }
  }, [isOpen, adId]);

  // Changes the modals location if it too large to fit on the screen.
  useEffect(() => {
    const targetElement = document.getElementById('adGenerateCodeModal');
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        setIsLarge(entry?.contentRect.height > window.innerHeight);
      }
    });
    if (targetElement) resizeObserver.observe(targetElement);

    return () => {
      if (targetElement) resizeObserver.unobserve(targetElement);
    };
  }, []);

  return (
    <Styled.WrapperStyled isOpen={hook.hookOpenAd} isLarge={isLarge}>
      <Styled.ContentStyled id="adGenerateCodeModal">
        <Styled.HeaderStyled>
          <PageTitle isLoading={hook.hookAdTrackerLoading}>Generate Code</PageTitle>
        </Styled.HeaderStyled>

        <Tabs
          elements={[
            <AdDetailsTab ad={hook.hookAd} onClose={onClose} key="AdDetailsTab" />,
            <GetCodeTab onClose={onClose} hook={hook} key="GetCodeTab" />,
          ]}
          tabNames={['Ad Details', 'Get Code']}
          isModalOpen={isOpen}
        />
      </Styled.ContentStyled>
    </Styled.WrapperStyled>
  );
};
