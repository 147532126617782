const dataToCSVFormatter = (columns: { dataField: string; text: string }[], data: any[]) => {
  const CSVHeader: string[] = [];
  const CSVDataJoined: string[] = [];
  const CSVFooter: string[] = [];

  columns.forEach((column) => {
    CSVHeader.push(column.text);
  });

  data.forEach((row) => {
    const CSVData: string[] = [];
    columns.forEach((column) => {
      CSVData.push(row[column.dataField]);
    });
    CSVDataJoined.push(CSVData.join(','));
  });

  return `${CSVHeader.join(',')}\n${CSVDataJoined.join('\n')}${CSVFooter.join(',')}`;
};

export default dataToCSVFormatter;
