import { gql } from '@apollo/client';

export const PAYPAL_ORDERS = gql`
  query paypalOrders($input: PaypalOrderSearchInputV2!) {
    paypalOrdersV2(input: $input) {
      orders {
        amount
        currency
        depositId
        notes
        productCategory
        merchantId
        status
        createdAt
        updatedAt
      }
      count
    }
  }
`;
