import React from 'react';

import * as Styled from './styles';

type CellProps = {
  value: any;
  compressed?: boolean;
};

export const Cell = ({ value, compressed }: CellProps) => (
  <Styled.CellStyled compressed={compressed}>{value}</Styled.CellStyled>
);
