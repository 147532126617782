import { useQuery } from '@apollo/client';

import { USER_TYPES_ID } from 'utils';

import { LIST_AD_FILTER_OPTIONS } from '../graphql/queries/listAdFilterOptions';

export const useAdFilterOptions = (userInfo: any, whoAmI: any, selectedMerchant: any) => {
  const campaigns: any[] = [
    {
      label: 'All Campaigns',
      value: '',
    },
  ];

  const products: any[] = [
    {
      label: 'All Products',
      value: '',
    },
  ];

  const publisherGroups: any[] = [
    {
      label: 'All Publisher Groups',
      value: '',
    },
  ];

  let userType = 'Admin';
  if (
    userInfo.userTypesId === USER_TYPES_ID.ADMIN &&
    whoAmI.isActingAsUserTypeId !== undefined &&
    whoAmI.isActingAsUserTypeId !== null
  ) {
    userType = whoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ? 'Merchant' : 'Publisher';
  }
  if (userInfo.userTypesId === USER_TYPES_ID.MERCHANT) userType = 'Merchant';
  if (userInfo.userTypesId === USER_TYPES_ID.PUBLISHER) userType = 'Publisher';

  const { data, loading } = useQuery(LIST_AD_FILTER_OPTIONS, {
    variables: {
      input: {
        programId: selectedMerchant || whoAmI?.programId,
        companyId: whoAmI !== undefined && whoAmI.companyId !== undefined ? whoAmI.companyId.toString() : '',
        userType,
      },
    },
    fetchPolicy: 'no-cache',
  });

  data?.getSearchAdsFilterOptions.campaigns.forEach((item: any) => {
    campaigns.push({
      label: item.label,
      value: item.value,
    });
  });

  data?.getSearchAdsFilterOptions.products.forEach((item: any) => {
    products.push({
      label: item.label,
      value: item.value,
    });
  });

  if (
    data?.getSearchAdsFilterOptions?.publisherGroups !== undefined &&
    Array.isArray(data?.getSearchAdsFilterOptions?.publisherGroups)
  ) {
    data.getSearchAdsFilterOptions.publisherGroups.forEach((item: any) => {
      publisherGroups.push({
        label: item.label,
        value: item.value,
      });
    });
  }

  return {
    hookCampaignsList: campaigns,
    hookProductsList: products,
    hookPublisherGroupsList: publisherGroups,
    hookFilterOptionsLoading: loading,
  };
};
