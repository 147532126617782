import React from 'react';

import { IdCell, CellDomain, DateCell } from './components';

export const columns: TableColumn[] = [
  {
    dataField: 'trackingProfileId',
    text: 'Tracking ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '15%',
    formatter: (value) => <IdCell value={value} />,
  },
  {
    dataField: 'trackingDomainUrl',
    text: 'Domain URL',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '40%',
    formatter: (value) => <CellDomain value={value} />,
  },
  {
    dataField: 'firstActiveDate',
    text: 'First Active Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '22%',
    formatter: (value) => <DateCell value={value} />,
  },
  {
    dataField: 'lastActiveDate',
    text: 'Last Active Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '23%',
    formatter: (value) => <DateCell value={value} />,
  },
];
