import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../../../../../components';

import * as Styled from './styles';

export const ArrowCell = () => (
  <Button theme="text-only" onClick={() => null}>
    <Styled.IconCell>
      <Styled.IconStyled icon={faChevronRight} />
    </Styled.IconCell>
  </Button>
);
