import React from 'react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import * as Styled from '../styles';
import { Checkbox, Tooltip } from '../../../../../components';
import { dateFormatter } from '../../../../../utils';
import { colors } from '../../../../../styles/theme';
import { PublisherMember, SelectedPublisherType } from '../types';
import { checkActionAvailability, convertMembershipStatus } from '../utils';

import { ArrowCell } from './ArrowCell';

const CheckBoxCellFormatter = (
  checked: boolean,
  onChange: (state: any) => void,
  row?: PublisherMember,
  pendingTransaction = false,
  isReadOnly = false
) => (
  <Styled.CheckBoxCellStyled theme="checkBox">
    <Checkbox
      checked={checked}
      onChange={() => onChange(!checked)}
      disabled={isReadOnly || (row ? !checkActionAvailability('Merchant', row) : false)}
    />
    {pendingTransaction && (
      <Tooltip text="Pending Transactions" icon={faTriangleExclamation} iconColor={colors.color2} />
    )}
  </Styled.CheckBoxCellStyled>
);

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checkBox',
    text: '',
    isCheckBox: true,
    width: '70px',
    headerFormatter: (headerCheckBox: boolean, headerCheckBoxHandler: (state: boolean) => void) =>
      CheckBoxCellFormatter(headerCheckBox, headerCheckBoxHandler, undefined, false, isReadOnly),
    formatter: (
      _: any,
      row: PublisherMember,
      __: any,
      ___: any,
      handleCheckBox?: (publisher: SelectedPublisherType, add: boolean) => void
    ) =>
      CheckBoxCellFormatter(
        row.checked || false,
        (state: boolean) => {
          if (handleCheckBox)
            handleCheckBox(
              { membershipId: row.id.toString(), publisherName: row.companyName, publisherId: row.publisherId },
              state
            );
        },
        row,
        row.pendingTransactions,
        isReadOnly
      ),
    notClickable: true,
  },
  {
    dataField: 'publisherNum',
    text: 'ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '100px',
    formatter: (val: string) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
  },
  {
    dataField: 'companyName',
    text: 'Company Name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '250px',
    formatter: (val: string, row: any) => (
      <Styled.TableDataStyled>
        <p>{val}</p>
        <Styled.TableCellLinkStyled href={row.primaryWebsite} target="_blank" rel="noreferrer">
          {row.primaryWebsite}
        </Styled.TableCellLinkStyled>
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'primaryCategory',
    text: 'Category',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '250px',
    cellOverflow: true,
    formatter: (val: string, row: any) => (
      <Styled.TableDataStyled>
        <p>{val}</p>
        <Styled.CellTableSubTextStyled>{row.promotingProduct}</Styled.CellTableSubTextStyled>
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'websiteTraffic',
    text: 'Website Traffic',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '250px',
    formatter: (val: string) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
  },
  {
    dataField: 'promotionMethod',
    text: 'Promotion Method',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '250px',
    formatter: (val: string[]) => (
      <Styled.TableDataStyled smallText>
        {val.reduce((acc, curr, index) => (index ? `${acc}, ${curr}` : curr), '') || ''}
      </Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'applicationDate',
    text: 'Application Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '180px',
    formatter: (val: string) => <Styled.TableDataStyled>{dateFormatter(new Date(val))}</Styled.TableDataStyled>,
  },
  {
    dataField: 'status',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '185px',
    formatter: (val: string, row: PublisherMember) => (
      <Styled.TableDataStyled>{convertMembershipStatus(row)}</Styled.TableDataStyled>
    ),
  },
  {
    dataField: 'summary',
    text: '',
    width: '100px',
    formatter: () => (
      <Styled.TableDataStyled>
        <ArrowCell />
      </Styled.TableDataStyled>
    ),
  },
];
