import { useQuery } from '@apollo/client';

import { USER_TYPES_ID } from '../../../../utils';
import { LIST_STATIC_AD_FILTER_OPTIONS } from '../graphql/queries/listStaticAdFilterOptions';

export const useStaticAdFilterOptions = (userInfo: any, whoAmI: any) => {
  const adStatusTypes: any[] = [
    {
      label: 'All Status',
      value: '',
    },
  ];

  const merchants: any[] = [
    {
      label: 'All Merchants',
      value: '',
    },
  ];

  const adTypes: any[] = [
    {
      label: 'All Ad Types',
      value: '',
    },
  ];

  const languages: any[] = [
    {
      label: 'All Languages',
      value: '',
    },
  ];

  const productCategories: any[] = [
    {
      label: 'All Categories',
      value: '',
    },
  ];

  let userType = 'Admin';
  if (
    userInfo.userTypesId === USER_TYPES_ID.ADMIN &&
    whoAmI.isActingAsUserTypeId !== undefined &&
    whoAmI.isActingAsUserTypeId !== null
  ) {
    userType = whoAmI.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ? 'Merchant' : 'Publisher';
  }
  if (userInfo.userTypesId === USER_TYPES_ID.MERCHANT) userType = 'Merchant';
  if (userInfo.userTypesId === USER_TYPES_ID.PUBLISHER) userType = 'Publisher';

  const { data, loading } = useQuery(LIST_STATIC_AD_FILTER_OPTIONS, {
    variables: {
      input: {
        programId: whoAmI?.programId,
        companyId: whoAmI !== undefined && whoAmI.companyId !== undefined ? whoAmI.companyId.toString() : '',
        userType,
      },
    },
    fetchPolicy: 'no-cache',
  });

  data?.getSearchStaticAdsFilterOptions.adStatusTypes.forEach((item: any) => {
    adStatusTypes.push({
      label: item.type,
      value: item.type,
    });
  });

  if (
    data?.getSearchStaticAdsFilterOptions?.merchants !== undefined &&
    Array.isArray(data?.getSearchStaticAdsFilterOptions?.merchants)
  ) {
    data.getSearchStaticAdsFilterOptions.merchants.forEach((item: any) => {
      merchants.push({
        label: item?.companyName,
        value: item?.program?.id,
      });
    });
  }

  data?.getSearchStaticAdsFilterOptions.adTypes.forEach((item: any) => {
    adTypes.push({
      label: item.type,
      value: item.type,
    });
  });

  data?.getSearchStaticAdsFilterOptions.languages.forEach((item: any) => {
    languages.push({
      label: item.language,
      value: item.language,
    });
  });

  data?.getSearchStaticAdsFilterOptions.productCategories.forEach((item: any) => {
    productCategories.push({
      label: item.name,
      value: item.name,
    });
  });

  return {
    hookAdStatusTypesList: adStatusTypes,
    hookMerchantsList: merchants,
    hookAdTypesList: adTypes,
    hookLanguagesList: languages,
    hookProductCategoriesList: productCategories,
    hookStaticFilterOptionsLoading: loading,
  };
};
