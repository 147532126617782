import { gql } from '@apollo/client';

export const ASSIGN_ADMIN_USER = gql`
  mutation assignAdminUser($input: UserInput!) {
    assignAdminUser(input: $input) {
      user {
        id
        firstName
        lastName
        position
        roles {
          id
          name
          type
        }
        status
      }
    }
  }
`;
