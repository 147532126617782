import React from 'react';

import { GNNotificationType } from '../graphql/queries/getNotifications';

export const formatNotificationDescription = ({ title, fromCompany, toCompany, description }: GNNotificationType) => {
  const words = (description || '').split('||');
  if (!words || (words && words.length === 1)) return description;

  const decoTexts: any[] = [];
  words.forEach((x: any, i: any) => {
    const key = i.toString();
    if (x === 'fromCompany') {
      decoTexts.push(<b key={key}>{fromCompany?.companyName || ''}</b>);
    } else if (x === 'toCompany') {
      decoTexts.push(<b key={key}>{toCompany?.companyName || ''}</b>);
    } else if (title === 'NEW PRODUCT' && x === ' has released a new product') {
      decoTexts.push(<span key={key}> has added a new product</span>);
    } else {
      decoTexts.push(<span key={key}>{x}</span>);
    }
  });
  return decoTexts;
};
