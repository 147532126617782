import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { copyToClipboard } from 'utils/copyToClipboard';
import { ERROR_TYPES, MERCHANT_PREFIX, path } from 'utils';
import { useValidation } from 'utils/validation';
import { useModal, useQueries, useToast, useUserInfo } from 'hooks';

import { INVITATION_TEMPLATE } from '../enum';
import { SAVE_INVITATION_TEMPLATE, UPDATE_TEMPLATE } from '../graphql/mutations';
import { GET_INVITATION_TEMPLATE } from '../graphql/queries';
import { TOAST_MESSAGES } from '../../PublisherInvitation/contracts';
import { COPY_INVIATION_TEMPLATE, DELETE_INVIATION_TEMPLATE } from '../../PublisherInvitation/graphql/mutations';
import { Permission } from '../../../../../entities';

export const useInvitationTemplate = (permissionsCodeList: string[] = []) => {
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  const [selectedMergeField, setSelectedMergeField] = useState<SelectOption>({
    label: 'Merge Fields',
    value: '',
  });
  const [templateId, setTemplateId] = useState('');
  const [message, setMessage] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');
  const [createUserErrors, setCreateUserErrors] = useState<{ [key: string]: string }>({});
  const [secondRender, setSecondRender] = useState<boolean>(false);
  const [modal, setModal] = useModal(false);
  const [getInvitationTemplate] = useLazyQuery(GET_INVITATION_TEMPLATE);
  const [copyInvitationTemplate] = useMutation(COPY_INVIATION_TEMPLATE);
  const [deleteInvitationTemplate] = useMutation(DELETE_INVIATION_TEMPLATE);
  const [saveTemplate] = useMutation(SAVE_INVITATION_TEMPLATE);
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE);

  const navigate = useNavigate();
  const query = useQueries();

  const templateCopyHandler = async (): Promise<void> => {
    const {
      data: { createPublisherInvitation },
      errors,
    } = await copyInvitationTemplate({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId?.toString(),
          name: `Copy of ${templateName}`,
          description,
          subject,
          message,
          template: true,
        },
      },
    });
    if (errors) throw new Error(errors[0].message);
    if (createPublisherInvitation?.id) {
      hookShowToast(
        TOAST_MESSAGES.COPY.MESSAGE,
        `${MERCHANT_PREFIX}${path.invitationTemplate.href}?id=${createPublisherInvitation.id}&edit=${createPublisherInvitation.name}`
      );
    }
  };

  const templateDeleteHandler = async (id: string): Promise<void> => {
    const { errors } = await deleteInvitationTemplate({
      variables: { id },
    });
    if (errors) throw new Error(errors[0].message);
    hookShowToast(TOAST_MESSAGES.DELETE.MESSAGE);
    navigate(-1);
  };

  const editTemplateHandler = async (): Promise<void> => {
    if (query.id !== undefined) {
      const { data } = await getInvitationTemplate({
        variables: {
          id: query.id,
        },
        fetchPolicy: 'no-cache',
      });
      setTemplateName(data.publisherInvitation.name);
      setDescription(data.publisherInvitation.description);
      setSubject(data.publisherInvitation.subject);
      setMessage(data.publisherInvitation.message);
      setTemplateId(data.publisherInvitation.id);
    }
  };

  const goBack = () => {
    navigate(`${MERCHANT_PREFIX}${path.publisherInvitations.href}`);
  };

  const saveTemplateHandler = async (): Promise<any> => {
    if (query.edit) {
      await updateTemplate({
        variables: {
          input: {
            id: templateId,
            description,
            message,
            subject,
            merchantId: hookWhoAmI.companyId?.toString(),
            name: templateName,
            template: true,
          },
        },
      });
      const redirectHref = `${MERCHANT_PREFIX}${path.invitationTemplate.href}?id=${query.id}&edit=${templateName}`;

      hookShowToast(INVITATION_TEMPLATE.TOAST.SAVED_MESSAGE, redirectHref);
    } else {
      const { data } = await saveTemplate({
        variables: {
          input: {
            description,
            message,
            subject,
            merchantId: hookWhoAmI.companyId?.toString(),
            name: templateName,
            template: true,
          },
        },
      });
      const redirectHref = `
      ${MERCHANT_PREFIX}${path.invitationTemplate.href}?id=${data.createPublisherInvitation.id}&edit=${templateName}`;

      hookShowToast(
        query.id === undefined ? INVITATION_TEMPLATE.TOAST.CREATED_MESSAGE : INVITATION_TEMPLATE.TOAST.SAVED_MESSAGE,
        redirectHref
      );

      return data;
    }
    return null;
  };

  const mergeFieldsHandler = (e: SelectOption): void => {
    copyToClipboard(e.value);
    setSelectedMergeField(e);
  };
  const descriptionHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDescription(e.target.value);
  };
  const subjectHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSubject(e.target.value);
  };
  const nameHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTemplateName(e.target.value);
  };
  const messageHandler = (e: any): void => {
    setMessage(e);
    if (e !== '<p><br></p>') {
      const errorCopy = { ...createUserErrors, message: '' };
      setCreateUserErrors(errorCopy);
    }
  };
  const vali = useValidation();
  const values: { [key: string]: string } = {
    templateName,
    subject,
    message: message === '<p><br></p>' ? '' : message,
  };
  const fields = {
    templateName: ERROR_TYPES.NOT_EMPTY,
    subject: ERROR_TYPES.NOT_EMPTY,
    message: ERROR_TYPES.NOT_EMPTY,
  };

  const handleValidation = (): boolean => {
    let noErrors = true;
    if (secondRender) {
      if (!templateName) {
        createUserErrors.templateName = INVITATION_TEMPLATE.INPUT.ERROR.TEMPLATE_NAME;
        noErrors = false;
      }
      if (!subject) {
        createUserErrors.subject = INVITATION_TEMPLATE.INPUT.ERROR.SUBJECT;
        noErrors = false;
      }
    }
    const pass = vali.validateAll(values, fields, setCreateUserErrors, secondRender);
    return pass && noErrors;
  };
  useEffect(() => {
    handleValidation();
  }, [secondRender]);

  useEffect(() => {
    editTemplateHandler();
  }, []);

  const saveHandler = async (): Promise<void> => {
    setSecondRender(true);
    if (await handleValidation()) {
      await saveTemplateHandler();
      navigate(`${MERCHANT_PREFIX}${path.publisherInvitations.href}`, { state: { tabNumber: 1 } });
    }
  };

  return {
    hookCreateUserErrors: createUserErrors,
    hookDescription: description,
    hookMessage: message,
    hookModal: modal,
    hookQuery: query,
    hookSelectedMergeField: selectedMergeField,
    hookSubject: subject,
    hookTemplateName: templateName,
    hookBackNavigation: goBack,
    hookHandleValidation: handleValidation,
    hookMergeFieldsHandler: mergeFieldsHandler,
    hookNameHandler: nameHandler,
    hookSaveHandler: saveHandler,
    hookSetDescription: descriptionHandler,
    hookSetMessage: messageHandler,
    hookSetModal: setModal,
    hookSetSecondRender: setSecondRender,
    hookSetSubject: subjectHandler,

    hookCopyTemplate: templateCopyHandler,
    hookDeleteTemplate: templateDeleteHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
