import styled from 'styled-components';

import { fonts } from '../../../../../styles/theme';

export const CellStyled = styled.div`
  ${fonts.lato.H16};
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;
