import styled from 'styled-components';

import { Button, Select } from '../../../components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

export const FooterButtonsStyled = styled(Button)`
  margin-left: 1rem;
`;

export const SelectStyled = styled(Select)`
  margin: 0 1rem;

  &:first-of-type {
    width: 300px;
  }
`;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const FiltersSelectStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  width: ${toRem(184)};

  span {
    color: ${colors.color1};
    ${fonts.lato.H16}
  }
`;

export const FiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const FooterButtonsAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PaginationBoxStyled = styled.div`
  margin-bottom: ${toRem(40)};
`;
