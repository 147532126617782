import React from 'react';

import { Checkbox } from 'components';

import * as Styled from '../styled';
import { CellProps } from '../types';

type CheckboxProps = CellProps & {
  row: any;
  tableData: any[];
  setTableData: (tableData: any[]) => void;
};

export const CheckboxCell = ({ val, row, tableData, setTableData }: CheckboxProps): JSX.Element => {
  const handleClick = (checked: boolean): void => {
    const tableCopy = [...tableData];
    const index = tableCopy.findIndex((tableRow) => row.id === tableRow.id);
    if (index !== -1) {
      tableCopy[index].check = checked;
    }
    setTableData(tableCopy);
  };

  return (
    <Styled.CellStyled>
      <Checkbox checked={val} onChange={handleClick} disabled={row.removedPublisher} />
    </Styled.CellStyled>
  );
};
