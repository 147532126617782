import styled from 'styled-components';

import { toRem } from '../../../../utils';
import { colors, fonts } from '../../../../styles/theme';
import { InputTextarea } from '../../../../components';

type TextStyledProps = {
  readonly theme?: string;
};

type BlockWrapperStyledProps = {
  readonly theme?: string;
};

export const BlockWrapper = styled.div<BlockWrapperStyledProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ theme }) => (theme === 'outer' ? 'row' : 'column')};
  margin-bottom: ${({ theme }) => (theme === 'outer' ? toRem(40) : 0)};
  min-width: ${({ theme }) => (theme === 'outer' ? toRem(398) : 'fit-content')};
  min-height: ${({ theme }) => (theme === 'outer' ? 'fit-content' : toRem(48))};
  align-items: flex-start;
`;

export const TextStyled = styled.div<TextStyledProps>`
  color: ${({ theme }) => (theme === 'outer' ? colors.color13 : colors.color1)};
  ${({ theme }) => (theme === 'outer' ? fonts.gotham.H12M : fonts.lato.H16)};
  text-transform: uppercase;
  line-height: ${toRem(24)};
`;

export const TextAreaStyled = styled(InputTextarea)`
  min-width: ${toRem(398)};
  min-height: ${toRem(86)};
  margin-bottom: ${toRem(32)};
`;
