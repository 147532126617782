import React from 'react';

import { Checkbox } from '../../../../components';
import { dateFormatter } from '../../../../utils';

import * as Styled from './styles';

const CheckBoxCellFormatter = (checked: boolean, onChange: (state: any) => void, isReadOnly: boolean) => (
  <Styled.CellWrapperStyled theme="checkBox">
    <Checkbox checked={checked} onChange={onChange} disabled={isReadOnly} />
  </Styled.CellWrapperStyled>
);

const FormatItems = (mainValue: any, secondaryValue: any) => (
  <Styled.CellWrapperStyled>
    <Styled.TextStyled theme="main">{mainValue}</Styled.TextStyled>
    <Styled.TextStyled>{secondaryValue}</Styled.TextStyled>
  </Styled.CellWrapperStyled>
);

const FormatText = (value: any) => <Styled.CellStyled>{value}</Styled.CellStyled>;

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checkBox',
    text: '',
    headerFormatter: (headerCheckBox: boolean, headerCheckBoxHandler: (state: boolean) => void) =>
      CheckBoxCellFormatter(headerCheckBox, headerCheckBoxHandler, isReadOnly),
    formatter: (_: any, row: any, __: any, ___: any, handleCheckBox?: (id: string, add: boolean) => void) =>
      CheckBoxCellFormatter(
        row.checked,
        (state: boolean) => {
          if (handleCheckBox) handleCheckBox(row.id, state);
        },
        isReadOnly
      ),
    width: '40px',
    isCheckBox: true,
  },
  {
    dataField: 'id',
    text: 'Trans ID/Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatItems(row?.id, dateFormatter(new Date(row?.createdAt))),
    width: '14%',
  },
  {
    dataField: 'merchant',
    text: 'Merchant Name',
    formatter: (_: any, row: any) => FormatText(row.merchant?.companyName),
    width: '14%',
  },
  {
    dataField: 'orderId',
    text: 'Order/Customer ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatItems(row.orderId, row.customerId),
    width: '16%',
  },
  {
    dataField: 'adId',
    text: 'Ad/Profile ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatItems(row.ad?.id, row.trackingProfile?.id), // FC-1303: Prevent null `ad` objects from throwing errors
    width: '10%',
  },
  {
    dataField: 'productName',
    text: 'Product',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatText(row?.product?.name),
    width: '16%',
  },
  {
    dataField: 'publisherId',
    text: 'Pub ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatText(row.publisher && row.publisher.id ? row.publisher?.id : ''),
    width: '10%',
  },
  {
    dataField: 'netSale',
    text: 'Net/Gross Sales',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatItems(`$${row.netSale}`, `$${row.grossSale}`),
    width: '14%',
  },
];

export const columnsMerchant = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checkBox',
    text: '',
    headerFormatter: (headerCheckBox: boolean, headerCheckBoxHandler: (state: boolean) => void) =>
      CheckBoxCellFormatter(headerCheckBox, headerCheckBoxHandler, isReadOnly),
    formatter: (_: any, row: any, __: any, ___: any, handleCheckBox?: (id: string, add: boolean) => void) =>
      CheckBoxCellFormatter(
        row.checked,
        (state: boolean) => {
          if (handleCheckBox) handleCheckBox(row.id, state);
        },
        isReadOnly
      ),
    width: '40px',
    isCheckBox: true,
  },
  {
    dataField: 'id',
    text: 'Trans ID/Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatItems(row.id, dateFormatter(new Date(row.createdAt))),
    width: '16%',
  },
  {
    dataField: 'orderId',
    text: 'Order/Customer ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatItems(row.orderId, row.customerId),
    width: '16%',
  },
  {
    dataField: 'adId',
    text: 'Ad/Profile ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatItems(row.ad?.id, row.trackingProfile?.id), // FC-1303: Prevent null `ad` objects from throwing errors
    width: '16%',
  },
  {
    dataField: 'productName',
    text: 'Product',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatText(row?.product?.name),
    width: '16%',
  },
  {
    dataField: 'publisherId',
    text: 'Pub ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatText(row.publisher && row.publisher.id ? row.publisher?.id : ''),
    width: '16%',
  },
  {
    dataField: 'netSale',
    text: 'Net/Gross Sales',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => FormatItems(`$${row.netSale}`, `$${row.grossSale}`),
    width: '16%',
  },
];
