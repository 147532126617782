import { useState, useMemo, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { useConfirmationModal } from 'components/Modal/ConfirmationModal/hooks';
import { useToast } from 'hooks';
import { MERCHANT_TYPES } from 'utils';

import { GET_PUBLISHER_INVITES } from '../graphql/queries/getPublisherInvites';
import { UPDATE_PUBLISHER_INVITES } from '../graphql/mutations/updatePublisherInvites';
import { PUBLISHER_INVITES_HOOK_ENUMS } from '../enums';
import { Permission } from '../../../../entities';

type PublisherInvitesManagementType = {
  id: string;
  merchantType: string;
  enabled: boolean;
  limitPerMonth: number;
};

export const usePublisherInvites = (permissionsCodeList: string[] = []) => {
  const [selfServeMerchantLimitAmount, setSelfServeMerchantLimitAmount] = useState<number>(0);
  const [resultsMerchantLimitAmount, setResultsMerchantLimitAmount] = useState<number>(0);
  const [managedMerchantLimitAmount, setManagedMerchantLimitAmount] = useState<number>(0);

  const [selfServeMerchantLimitBoolean, setSelfServeMerchantLimitBoolean] = useState<boolean>(false);
  const [resultsMerchantLimitBoolean, setResultsMerchantLimitBoolean] = useState<boolean>(false);
  const [managedMerchantLimitBoolean, setManagedMerchantLimitBoolean] = useState<boolean>(false);
  const { hookShowToast } = useToast();

  const { data } = useQuery(GET_PUBLISHER_INVITES, { fetchPolicy: 'no-cache' });
  const pubInvManDataArray: PublisherInvitesManagementType[] = useMemo(
    () => data?.publisherInvitationManagement,
    [data]
  );

  const [updatePublisherInvites] = useMutation(UPDATE_PUBLISHER_INVITES);

  const setInitialValuesHandler = (): void => {
    if (!pubInvManDataArray) return;
    pubInvManDataArray.forEach((pubInvData) => {
      if (pubInvData.merchantType === MERCHANT_TYPES.SELF_SERVE_MERCHANT) {
        setSelfServeMerchantLimitAmount(pubInvData.limitPerMonth);
        setSelfServeMerchantLimitBoolean(pubInvData.enabled);
      } else if (pubInvData.merchantType === MERCHANT_TYPES.RESULTS_MERCHANT) {
        setResultsMerchantLimitAmount(pubInvData.limitPerMonth);
        setResultsMerchantLimitBoolean(pubInvData.enabled);
      } else if (pubInvData.merchantType === MERCHANT_TYPES.MANAGED_MERCHANT) {
        setManagedMerchantLimitAmount(pubInvData.limitPerMonth);
        setManagedMerchantLimitBoolean(pubInvData.enabled);
      }
    });
  };

  const discardChanges = (): void => {
    if (data) setInitialValuesHandler();
    else {
      setSelfServeMerchantLimitAmount(0);
      setResultsMerchantLimitAmount(0);
      setManagedMerchantLimitAmount(0);
      setSelfServeMerchantLimitBoolean(false);
      setResultsMerchantLimitBoolean(false);
      setManagedMerchantLimitBoolean(false);
    }
  };
  const handleDiscardChangesModal = useConfirmationModal(undefined, discardChanges);

  const saveChanges = async (): Promise<void> => {
    const inputs: PublisherInvitesManagementType[] = [
      {
        id: pubInvManDataArray.filter((pubInv) => pubInv.merchantType === MERCHANT_TYPES.SELF_SERVE_MERCHANT)[0].id,
        merchantType: MERCHANT_TYPES.SELF_SERVE_MERCHANT,
        enabled: selfServeMerchantLimitBoolean,
        limitPerMonth: selfServeMerchantLimitAmount,
      },
      {
        id: pubInvManDataArray.filter((pubInv) => pubInv.merchantType === MERCHANT_TYPES.RESULTS_MERCHANT)[0].id,
        merchantType: MERCHANT_TYPES.RESULTS_MERCHANT,
        enabled: resultsMerchantLimitBoolean,
        limitPerMonth: resultsMerchantLimitAmount,
      },
      {
        id: pubInvManDataArray.filter((pubInv) => pubInv.merchantType === MERCHANT_TYPES.MANAGED_MERCHANT)[0].id,
        merchantType: MERCHANT_TYPES.MANAGED_MERCHANT,
        enabled: managedMerchantLimitBoolean,
        limitPerMonth: managedMerchantLimitAmount,
      },
    ];
    const { errors } = await updatePublisherInvites({
      variables: {
        inputs,
      },
    });
    if (!errors) hookShowToast(PUBLISHER_INVITES_HOOK_ENUMS.UPDATED_SETTINGS_SUCCESS_TOAST);
    else hookShowToast(PUBLISHER_INVITES_HOOK_ENUMS.UPDATED_SETTINGS_ERROR_TOAST);
  };

  const cancelSaveChanges = (): void => {
    setTimeout(() => {
      handleDiscardChangesModal.hookSetIsOpen(true);
    }, 300);
  };
  const handleSaveChangesModal = useConfirmationModal(saveChanges, cancelSaveChanges);

  const handleSelfServeMerchantLimitAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(e.target.value);
    if (value >= 0) {
      setSelfServeMerchantLimitAmount(Number(e.target.value));
    }
  };
  const handleResultsMerchantLimitAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(e.target.value);
    if (value >= 0) {
      setResultsMerchantLimitAmount(Number(e.target.value));
    }
  };
  const handleMangedMerchantLimitAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(e.target.value);
    if (value >= 0) {
      setManagedMerchantLimitAmount(Number(e.target.value));
    }
  };

  const handleSelfServeMerchantLimitBoolean = (newState: boolean): void => {
    setSelfServeMerchantLimitBoolean(newState);
  };

  const handleResultsMerchantLimitBoolean = (newState: boolean): void => {
    setResultsMerchantLimitBoolean(newState);
  };

  const handleMangedMerchantLimitBoolean = (newState: boolean): void => {
    setManagedMerchantLimitBoolean(newState);
  };

  const handleOnKeyPressNumberField = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setInitialValuesHandler();
  }, [data]);

  return {
    hookSelfServeMerchantLimitBoolean: selfServeMerchantLimitBoolean,
    hookSetSelfServeMerchantLimitBoolean: setSelfServeMerchantLimitBoolean,
    hookHandleSelfServeMerchantLimitBoolean: handleSelfServeMerchantLimitBoolean,
    hookSelfServeMerchantLimitAmount: selfServeMerchantLimitAmount,
    hookSetSelfServeMerchantLimitAmount: setSelfServeMerchantLimitAmount,
    hookHandleSelfServeMerchantLimitAmount: handleSelfServeMerchantLimitAmount,

    hookResultsMerchantLimitBoolean: resultsMerchantLimitBoolean,
    hookSetResultsMerchantLimitBoolean: setResultsMerchantLimitBoolean,
    hookHandleResultsMerchantLimitBoolean: handleResultsMerchantLimitBoolean,
    hookResultsMerchantLimitAmount: resultsMerchantLimitAmount,
    hookHandleResultsMerchantLimitAmount: handleResultsMerchantLimitAmount,
    hookSetResultsMerchantLimitAmount: setResultsMerchantLimitAmount,

    hookManagedMerchantLimitBoolean: managedMerchantLimitBoolean,
    hookSetManagedMerchantLimitBoolean: setManagedMerchantLimitBoolean,
    hookHandleMangedMerchantLimitBoolean: handleMangedMerchantLimitBoolean,
    hookManagedMerchantLimitAmount: managedMerchantLimitAmount,
    hookSetManagedMerchantLimitAmount: setManagedMerchantLimitAmount,
    hookHandleMangedMerchantLimitAmount: handleMangedMerchantLimitAmount,

    hookHandleOnKeyPressNumberField: handleOnKeyPressNumberField,
    hookHandleDiscardChangesModal: handleDiscardChangesModal,
    hookHandleSaveChangesModal: handleSaveChangesModal,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
