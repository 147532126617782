import { gql } from '@apollo/client';

export const CREATE_MANUAL = gql`
  mutation Mutation($input: ManualBonusInput) {
    createManualBonus(input: $input) {
      manualBonus {
        bonusAmount
        bonusDate
        publisher {
          id
          companyType
        }
        bonusType
        merchant {
          companyName
          id
        }
        bonusNote
      }
    }
  }
`;
