type FilterKeys =
  | 'merchantId'
  | 'publisherId'
  | 'productId'
  | 'productId'
  | 'productCategory'
  | 'trackingProfile'
  | 'trackingProfileId'
  | 'groupBy'
  | 'campaignId'
  | 'publisherGroupId'
  | 'adId'
  | 'startDate'
  | 'endDate'
  | 'dateRange'
  | 'dataType'
  | 'transactionStatus'
  | 'search'
  | 'adCampaignId';

export type EditFilterProps = Record<FilterKeys, SelectOption>;

export const useEditFiltersReducer = (state: EditFilterProps, { data }: ComponentReducerType): EditFilterProps => ({
  ...state,
  ...data,
});
