import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GET_TRACKING_DOMAINS } from '../graphql/queries';

import { useAccountInformation } from './useAccountInformation';

export const useTrackingDomains = () => {
  const { hookUser } = useAccountInformation();
  const [tableDomains, setTableDomains] = useState<any[]>([]);
  const [listDomains, { loading }] = useLazyQuery(GET_TRACKING_DOMAINS);
  const [sortBy, setSortBy] = useState<TableSortColumn>({ column: 'lastActiveDate', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const listDomainsHandler = async () => {
    if (hookUser) {
      const { data } = await listDomains({
        variables: {
          input: {
            publisherId: hookUser.id.toString(), // internal publisher id
            sizeParPage: 10,
            sortField: sortBy.column,
            sortType: sortBy.direction,
            currentPage,
          },
        },
      });

      if (data?.trackingDomains?.domains) {
        setTableDomains(data?.trackingDomains?.domains);
      }

      if (data?.trackingDomains?.count) {
        setTotalPages(Math.ceil(data.trackingDomains.count / 10));
      } else {
        setCurrentPage(1);
        setTotalPages(0);
      }
    }
  };

  const setSortByHandler = (dataField: string, direction: any) => {
    if (sortBy.direction === null) {
      setSortBy({ column: dataField, direction });
    } else {
      setSortBy({ column: dataField, direction: sortBy.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    listDomainsHandler();
  }, [hookUser, currentPage, sortBy]);

  return {
    hookLoading: loading,
    hookTrackingDomainsData: tableDomains,

    hookSortBy: sortBy,
    hookSetSortBy: setSortByHandler,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookTotalPages: totalPages,
  };
};
