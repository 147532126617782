import styled from 'styled-components';

import { fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  margin: 2rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleStyled = styled.h1`
  align-self: flex-start;
  ${fonts.gotham.H26}
`;

export const ConfirmationStyled = styled.p`
  margin: 2rem 0;
  ${fonts.lato.H16R}
`;

export const ButtonWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
