import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../../../../../components';
import { colors, fonts } from '../../../../../styles/theme';
import { AD_TYPES, imageList } from '../../../../../utils';

const ImgWrapperStyled = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 0;
  font-size: 1rem;
  color: ${colors.color2};
`;

const CellStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  overflow: hidden;

  a {
    color: ${colors.color1};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${colors.color2};
    }
  }

  p {
    ${fonts.lato.H16R}
    color: ${colors.color1};
    margin-bottom: 0.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    ${fonts.lato.H14}
    color: ${colors.color13};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const formattedCode = (row: any, openAdFn: (adId: string) => void) => (
  <CellStyled>
    <ButtonStyled theme="text-only" onClick={() => openAdFn(row?.id)}>
      <FontAwesomeIcon icon={faCode} />
    </ButtonStyled>
  </CellStyled>
);

const formattedProduct = (value: any, row: any) => (
  <CellStyled>
    <p>{value}</p>
    <span>{row?.productCategory}</span>
  </CellStyled>
);

const formattedId = (value: any, row: any) => (
  <CellStyled>
    <p>{value}</p>
    <span>{row?.campaignName}</span>
  </CellStyled>
);

const formattedName = (value: any, row: any) => (
  <CellStyled>
    {row?.defaultLandingPage && (
      <p>
        <a href={row?.defaultLandingPage} target="_blank" rel="noreferrer">
          {value}
        </a>
      </p>
    )}
    {!row?.defaultLandingPage && <p>{value}</p>}
    <span>{row?.merchantName}</span>
  </CellStyled>
);

const formattedType = (value: any, row: any) => (
  <CellStyled>
    <p>{value}</p>
    <span>
      {row?.width && row?.height && `${row?.width}w x ${row?.height}h`}
      {!row?.width && !row?.height && 'N/A'}
    </span>
  </CellStyled>
);

const formattedDateCell = (value: any) => (
  <CellStyled>
    {new Date(value).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
  </CellStyled>
);

const FullImage = styled.img`
  position: absolute;
  left: 90px;
  top: calc(var(--row-index) * 40px);
  width: 'auto',
  background: white;
  box_shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const FormattedImage = (value: string, adType: string) => {
  const [onHover, setOnHover] = useState(false);
  const [rowIndex, setRowIndex] = useState<number | undefined>(undefined);
  const handleMouseEnter = (event: React.MouseEvent<HTMLImageElement>) => {
    setOnHover(true);
    const img = event.target as HTMLImageElement;
    const tr = img.closest('tr');
    const index = tr ? tr.rowIndex : undefined;
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setOnHover(false);
  };

  return (
    <CellStyled>
      <ImgWrapperStyled>
        <img
          src={adType === AD_TYPES.BANNER && value !== undefined && value !== null ? value : imageList.noPic.src}
          alt="cover"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        {onHover && adType === AD_TYPES.BANNER && value !== undefined && value !== null && (
          <FullImage
            src={adType === AD_TYPES.BANNER && value !== undefined && value !== null ? value : imageList.noPic.src}
            style={{ '--row-index': String(rowIndex) } as React.CSSProperties}
            alt="full image"
          />
        )}
      </ImgWrapperStyled>
    </CellStyled>
  );
};

export const columns = (openAdFn: (adId: string) => void): TableColumn[] => [
  {
    text: 'Thumbnail',
    dataField: 'adContent',
    width: '8%',
    formatter: (value, { adType }) => FormattedImage(value, adType),
  },
  {
    text: 'Type/Size',
    dataField: 'adType',
    width: '10%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedType,
  },
  {
    text: 'Name/Merchant',
    dataField: 'adName',
    width: '25%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedName,
  },
  {
    text: 'ID/Campaign',
    dataField: 'id',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedId,
  },
  {
    text: 'Product/Category',
    dataField: 'productName',
    width: '20%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedProduct,
  },
  {
    text: 'Date',
    dataField: 'createdAt',
    width: '17%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedDateCell,
  },
  {
    text: 'Code',
    dataField: '_',
    width: '5%',
    position: 'center',
    formatter: (_, row) => formattedCode(row, openAdFn),
  },
];
