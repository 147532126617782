import React from 'react';

import { JoinCell, MerchantCell, NewsletterCell, TCCell } from './Components';

export const columns: TableColumn[] = [
  {
    dataField: 'companyName',
    text: 'Merchant',
    width: '25%',
    formatter: (val) => <MerchantCell val={val} />,
  },
  {
    dataField: 'TC',
    text: '',
    width: '35%',
    formatter: (val, _, __, ___, ____, setOpenModal, setModalInfo) => (
      <TCCell val={val} setOpenModal={setOpenModal} setModalInfo={setModalInfo} />
    ),
  },
  {
    dataField: 'join',
    text: 'Join Program',
    width: '20%',
    formatter: (val, row, tableData, setTableData, _, __, ___, setContext) => (
      <JoinCell val={val} row={row} tableData={tableData} setTableData={setTableData} setContext={setContext} />
    ),
  },
  {
    dataField: 'newsletter',
    text: 'Newsletter',
    width: '20%',
    formatter: (val, row, tableData, setTableData, _, __, ___, setContext) => (
      <NewsletterCell val={val} row={row} tableData={tableData} setTableData={setTableData} setContext={setContext} />
    ),
  },
];
