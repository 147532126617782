export const CSV =
  'id,name,description,status,productUrl,productImageUrl,landingPage,thirdPartyUrl,productCategory,subCategories,gender,geographicalStates,age,commissionVisibility,minimumIncome,minimumHouseHoldIncome,minimiumCreditScore,validUntil,cardNetwork,userTypes,annualFee,monthlyFee,signupBonus,supplementaryCardFee,balanceTransferIntroRate,balanceTransferRegular,interestRate,rewardsEarningRate,annualInterestRate,balanceTransferIntroFee,balanceTransferFee,cashAdvanceFee,foreignCurrencyConversion,dishonouredPaymentFee,gracePeriod,legalReference,promoInterestRate,transactionFeeATM,transactionFeeETR,transactionFeeInternal,freeTransactionVolume,closingBalanceToWaiveMonthlyFee,noMinimumBalance,term,redeemable,insurer,minimumAccountBalance,managementFee,tradeCost,numberOfFreeMonthlyTrade,mer,coverageAmount,guaranteedPremiums,exclusionItem1,exclusionItem2,exclusionItem3,exclusionItem4,marketingItem1,marketingItem2,marketingItem3,marketingItem4,marketingItem5,marketingItem6, productUsageGuidelines, productWelcomeOffers, minimumOpenDeposit, interestPaid, interestPaidDays, interestCalculated, interestCalculatedDays, bankingProductInsurance, apyTiers, apyRate, mortgageType, openMortgageType, convertibleMortgageType, preapprovalMortgageType, mortgageRateType, mortgageTypeDiscountedRate, mortgageAprRate, mortgageTermYear, monthlyFeesDetails, balanceTransferRegularDetails, interestRateDetails, AnnualInterestRateDetails, bankingMinimumBalanceRate\n,,,,,,,,,"<Multiple values>",,,"<Multiple values>","<Multiple values>",,,,,,"<Multiple values>","<Multiple values>",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,';
export const VALID_CSV = 'Please submit a valid csv file';
export const STEPS_TITLE = {
  1: 'Step 1',
  2: 'Step 2',
  3: 'Step 3',
};

export const INVALID_CSV =
  'Oops! Something went wrong: Valid product data not found. Please check your csv file and try it again';
