import styled from 'styled-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

import { colors, fonts } from '../../../styles/theme';
import { GNNotificationType } from '../graphql/queries/getNotifications';
import { formatNotificationDate, formatNotificationDescription } from '../utils';

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClockStyled = styled(FontAwesomeIcon)`
  font-size: 10px;
  color: ${colors.color7};
  margin-right: 4px;
`;

const NotificationsHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
`;

const CircleStyled = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${colors.color2};
  margin-right: 3px;
`;

const TitleStyled = styled.aside`
  ${fonts.lato.H12}
  color: ${colors.color7};
  text-transform: uppercase;
  line-height: 1.5;
`;

const DescriptionStyled = styled.aside`
  ${fonts.gotham.H14}
  line-height: 1.5;
  b {
    font-family: gotham_promedium;
  }
`;

const DateStyled = styled.span`
  ${fonts.lato.H12}
  color: ${colors.color7};
`;

const FormatItems = (notification: GNNotificationType): JSX.Element => {
  const { read, title } = notification;
  return (
    <CellWrapper>
      <NotificationsHeader>
        {!read && <CircleStyled />}
        <TitleStyled>{title}</TitleStyled>
      </NotificationsHeader>
      <DescriptionStyled>{formatNotificationDescription(notification)}</DescriptionStyled>
    </CellWrapper>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'title',
    text: '',
    width: 'calc(100% - 132px)',
    formatter: (value: any, row: any) => FormatItems(row),
  },
  {
    dataField: 'createdAt',
    text: '',
    formatter: (value: any) => (
      <NotificationsHeader>
        <ClockStyled icon={faClock} />
        <DateStyled>{formatNotificationDate(new Date(value))}</DateStyled>
      </NotificationsHeader>
    ),
    width: '132px',
  },
];
