import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Checkbox } from 'components';

import * as Styled from '../styles';
import { LSPPublisherType } from '../graphql/queries';

import { TABLE_COLUMN_ORDER } from './enum';

const CheckBoxCellFormatter = (checked: boolean, onChange: (state: any) => void): JSX.Element => (
  <Styled.CheckBoxDataStyled>
    <Checkbox checked={checked} onChange={onChange} />
  </Styled.CheckBoxDataStyled>
);

export const columnsOptions: { [key: string]: TableColumn } = {
  'Member Since': {
    dataField: 'approvedDate',
    text: 'Member Since',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
    formatter: (val: string) => (
      <Styled.TableDataStyled theme="tertiary">
        {new Date(Number(val))
          .toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
          .replace(',', '')}
      </Styled.TableDataStyled>
    ),
  },
  'Audience Markets': {
    dataField: 'audienceMarkets',
    text: 'Audience Markets',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '200px',
    formatter: (val: string[]) => (
      <Styled.TableDataStyled theme="secondary">{val ? val.join(', ') : ''}</Styled.TableDataStyled>
    ),
  },
  'Product Categories': {
    dataField: 'productCategories',
    text: 'Product Categories',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '250px',
    formatter: (val: string[]) => (
      <Styled.TableDataStyled theme="secondary">{val ? val.join(', ') : ''}</Styled.TableDataStyled>
    ),
  },
  'Promotion Method': {
    dataField: 'promotionMethods',
    text: 'Promotion Method',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '220px',
    formatter: (val: string[]) => (
      <Styled.TableDataStyled theme="secondary">{val ? val.join(', ') : ''}</Styled.TableDataStyled>
    ),
  },
  'Monthly Web Visits': {
    dataField: 'avgMonthlyAudience',
    text: 'Monthly Audience Size',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '200px',
    formatter: (val: string) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
  },
  'Last Login': {
    dataField: 'lastLogin',
    text: 'Last Login',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '200px',
    formatter: (val: number) => (
      <Styled.TableDataStyled theme="secondary">
        {!val
          ? 'Never'
          : new Date(val)
              .toLocaleString(undefined, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true,
              })
              .replace(',', '')}
      </Styled.TableDataStyled>
    ),
  },
  'Publisher Status': {
    dataField: 'membershipStatus',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
    formatter: (val: string) => (
      <Styled.TableDataStyled theme="quaternary">
        {val}
        <Styled.IconWrapperStyled>
          <FontAwesomeIcon icon={faChevronRight} />
        </Styled.IconWrapperStyled>
      </Styled.TableDataStyled>
    ),
  },
  Age: {
    dataField: 'audienceAges',
    text: 'age',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '100px',
    formatter: (val: string[]) => (
      <Styled.TableDataStyled theme="secondary">
        <pre>{val ? val.join('\n') : ''}</pre>
      </Styled.TableDataStyled>
    ),
  },
  Gender: {
    dataField: 'gender',
    text: 'Gender',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '150px',
    formatter: (val: string) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
  },
  'Average Income': {
    dataField: 'avgIncomes',
    text: 'Average Income',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '175px',
    formatter: (val: string[]) => (
      <Styled.TableDataStyled theme="secondary">
        <pre>{val ? val.join('\n') : ''}</pre>
      </Styled.TableDataStyled>
    ),
  },
  'Credit Score Rating': {
    dataField: 'creditScoreRating',
    text: 'Credit Score Rating',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '200px',
    formatter: (val: string) => <Styled.TableDataStyled>{val}</Styled.TableDataStyled>,
  },
};

export const customizeColumns = (columns: string[]) => {
  const allColumns: TableColumn[] = [
    {
      dataField: 'checkBox',
      text: '',
      width: '40px',
      headerFormatter: (headerCheckBox: boolean, headerCheckBoxHandler: (state: boolean) => void) =>
        CheckBoxCellFormatter(headerCheckBox, headerCheckBoxHandler),
      formatter: (
        _: any,
        row: LSPPublisherType,
        __: any,
        ___: any,
        handleCheckBox?: (id: string, add: boolean) => void
      ) =>
        CheckBoxCellFormatter(row.checked || false, (state: boolean) => {
          if (handleCheckBox) handleCheckBox(row.membershipId, state);
        }),
      notClickable: true,
    },
    {
      dataField: 'id',
      text: 'id',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '100px',
      cellOverflow: false,
      formatter: (val: string, row: LSPPublisherType) => (
        <Styled.TableDataStyled theme="warning">
          <Styled.IconWrapperStyled>
            {row.pendingTransaction && <FontAwesomeIcon icon={faExclamationTriangle} />}
          </Styled.IconWrapperStyled>
          {val}
        </Styled.TableDataStyled>
      ),
    },
    {
      dataField: 'companyName',
      text: 'Company Name',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '200px',
      formatter: (val: string, row: LSPPublisherType) => {
        if (row.primaryPlatformLink) {
          return (
            <Styled.TableDataStyled theme="quinary">
              {val}
              <Styled.TableCellLinkStyled href={row.primaryPlatformLink || ''} target="_blank" rel="noreferrer">
                {row.primaryPlatformLink}
              </Styled.TableCellLinkStyled>
            </Styled.TableDataStyled>
          );
        }
        return <Styled.TableDataStyled>{val}</Styled.TableDataStyled>;
      },
    },
  ];

  TABLE_COLUMN_ORDER.forEach((header) => {
    if (columns.includes(header)) allColumns.push(columnsOptions[header]);
  });

  return allColumns;
};
