import React from 'react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import * as Styled from '../styled';
import { CellProps } from '../types';
import { colors } from '../../../../../../../../../styles/theme';
import { Tooltip } from '../../../../../../../../../components';

export const IdCell = ({ val }: CellProps) => (
  <Styled.IdStyled>
    {val.pendingTransactions && (
      <Tooltip text="Pending Transactions" icon={faTriangleExclamation} iconColor={colors.color2} />
    )}
    <Styled.MainValStyled>{val.id}</Styled.MainValStyled>
  </Styled.IdStyled>
);
