import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Checkbox, InfoBox, InputPhone, InputText, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 23% calc(100% - 23%);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.color1};
  padding-top: 9rem;
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightContentStyled = styled.div`
  width: 60%;
  display: flex;
  flex-flow: column nowrap;
  color: ${colors.color1};
  padding-top: 7rem;
  padding-bottom: 5rem;

  label {
    margin-bottom: 2rem;
  }

  h1 {
    ${fonts.gotham.H36M}
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    ${fonts.gotham.H26M}
  }

  h3 {
    ${fonts.gotham.H16M}
  }

  span > {
    ${fonts.lato.H14}
    line-height: 1.5;
  }
`;

export const FormStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  margin-top: 1rem;
`;

export const SelectStyled = styled(Select)`
  grid-column: span 2;
`;

export const InfoStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.color19};

  span {
    ${fonts.lato.H16}
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

export const InputStyled = styled(InputText)`
  grid-column: span 2;
`;

export const InputPhoneStyled = styled(InputPhone)`
  grid-column: span 2;
`;

export const CheckboxStyled = styled(Checkbox)`
  grid-column: span 2;

  span {
    ${fonts.lato.H16}
  }
`;

export const InfoBoxStyled = styled(InfoBox)`
  grid-column: span 2;
  margin-bottom: 1rem;
`;
