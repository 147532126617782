import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LIST_CURRENCIES, LIST_PAYMENTS_METHODS } from '../../graphql';
import { useSignUp } from '../../hooks';
import { SIGN_UP_ACTIONS } from '../../../reducers';
import { useCompanyInfo } from '../../ContactInfo/hooks';
import { PAYMENTS_INFO } from '../enums';
import { ERROR_TYPES, path, useValidation } from '../../../../../utils';

const COUNTRIES = {
  CA: 'Canada',
  US: 'United States',
  AU: 'Australia',
  OTHER: 'Other',
};

const TAX_COUNTRIES = ['United States', 'Australia'];

export const usePaymentInfo = () => {
  const navigate = useNavigate();
  const validator = useValidation();
  const { contextState, contextDispatcher } = useSignUp();
  const { hookCountryList } = useCompanyInfo();
  const [regionList, setRegionList] = useState<SelectOption[]>([]);
  const [currencyList, setCurrencyList] = useState<SelectOption[]>([]);
  const [publisherDisabledGST, setPublisherDisabledGST] = useState(true);
  const [publisherDisabledPST, setPublisherDisabledPST] = useState(true);
  const [publisherDisabledHST, setPublisherDisabledHST] = useState(true);
  const [publisherDisabledTAX, setPublisherDisabledTAX] = useState(false);

  const [paymentMethodList, setPaymentMethodList] = useState<SelectOption[]>([]);

  const [inputErrors, setInputErrors] = useState<{ [key: string]: string }>({});
  const [taxErrors, setTaxErrors] = useState<{ [key: string]: string }>({});
  const [failedNext, setFailedNext] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getPaymentMethods, { loading: loadingPaymentMethods }] = useLazyQuery(LIST_PAYMENTS_METHODS);
  const [getCurrencies, { loading: loadingCurrencies }] = useLazyQuery(LIST_CURRENCIES);

  const disableTaxFields = (region: SelectOption) => {
    if (contextState.paymentCountry.value === COUNTRIES.CA) {
      switch (region.label) {
        case PAYMENTS_INFO.VALIDATION.NONE:
          setPublisherDisabledGST(true);
          setPublisherDisabledPST(true);
          setPublisherDisabledHST(true);
          setPublisherDisabledTAX(true);
          contextDispatcher({
            type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
            data: {
              paymentGST: '',
              paymentPST: '',
              paymentHST: '',
              paymentTAX: '',
            },
          });
          break;
        case PAYMENTS_INFO.VALIDATION.ALBERTA:
        case PAYMENTS_INFO.VALIDATION.NORTHWEST:
        case PAYMENTS_INFO.VALIDATION.NUNAVIT:
        case PAYMENTS_INFO.VALIDATION.YUKON:
          setPublisherDisabledGST(false);
          setPublisherDisabledPST(true);
          setPublisherDisabledHST(true);
          setPublisherDisabledTAX(true);
          contextDispatcher({
            type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
            data: {
              paymentPST: '',
              paymentHST: '',
              paymentTAX: '',
            },
          });
          break;
        case PAYMENTS_INFO.VALIDATION.BC:
        case PAYMENTS_INFO.VALIDATION.MANITOBA:
        case PAYMENTS_INFO.VALIDATION.QUEBEC:
        case PAYMENTS_INFO.VALIDATION.SASKATCHEWAN:
          setPublisherDisabledGST(false);
          setPublisherDisabledPST(false);
          setPublisherDisabledHST(true);
          setPublisherDisabledTAX(true);
          contextDispatcher({
            type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
            data: {
              paymentHST: '',
              paymentTAX: '',
            },
          });
          break;
        case PAYMENTS_INFO.VALIDATION.BRUNSWICK:
        case PAYMENTS_INFO.VALIDATION.NEWFOUNDLAND_LABRADOR:
        case PAYMENTS_INFO.VALIDATION.NOVA_SCOTIA:
        case PAYMENTS_INFO.VALIDATION.ONTARIO:
        case PAYMENTS_INFO.VALIDATION.PRINCE_EDWARD:
          setPublisherDisabledGST(true);
          setPublisherDisabledPST(true);
          setPublisherDisabledHST(false);
          setPublisherDisabledTAX(true);
          contextDispatcher({
            type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
            data: {
              paymentGST: '',
              paymentPST: '',
              paymentTAX: '',
            },
          });
          break;
        default:
      }
    } else {
      setPublisherDisabledGST(true);
      setPublisherDisabledPST(true);
      setPublisherDisabledHST(true);
      setPublisherDisabledTAX(false);
      contextDispatcher({
        type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
        data: {
          paymentGST: '',
          paymentPST: '',
          paymentHST: '',
        },
      });
    }
  };

  const nextDisabled = !(
    contextState.paymentPayable &&
    contextState.paymentEmail &&
    contextState.paymentPhone &&
    contextState.paymentAddress1 &&
    contextState.paymentCity &&
    contextState.paymentCountry &&
    contextState.paymentState &&
    contextState.paymentZip
  );

  const getPaymentMethodsHandler = async () => {
    setErrorMessage('');
    const { data } = await getPaymentMethods({
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data.listPaymentMethods !== undefined) {
      setPaymentMethodList(
        [...data.listPaymentMethods.map((item: any) => ({ label: item.type, value: item.type }))].sort(
          (a: SelectOption, b: SelectOption) => (a.value > b.value ? 1 : -1)
        )
      );
    }
  };

  const getCurrenciesHandler = async () => {
    setErrorMessage('');
    const { data } = await getCurrencies({
      onError(error) {
        setErrorMessage(error.message);
      },
    });

    if (data.listCurrencies !== undefined && data.listCurrencies.length > 0) {
      setCurrencyList(
        data.listCurrencies.map((item: any) => ({ label: `${item.name} (${item.currency} $)`, value: item.currency }))
      );
    }
  };

  const navigateHandler = (url: string) => {
    navigate(url);
  };

  const setPaymentInfo = (repeat: boolean) => {
    if (repeat) {
      contextDispatcher({
        type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
        data: {
          paymentEmail: contextState.userEmail,
          paymentPhone: contextState.companyPhone,
          paymentAddress1: contextState.companyAddress1,
          paymentAddress2: contextState.companyAddress2,
          paymentCity: contextState.companyCity,
          paymentZip: contextState.companyZIP,
          paymentCountry: contextState.companyCountry,
          paymentState: contextState.companyState,
        },
      });
    } else {
      contextDispatcher({
        type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
        data: {
          paymentEmail: '',
          paymentPhone: '',
          paymentAddress1: '',
          paymentAddress2: '',
          paymentCity: '',
          paymentZip: '',
          paymentCountry: { label: '', value: '' },
          paymentState: { label: '', value: '' },
        },
      });
    }
  };

  const repeatInfoHandler = (repeat: boolean) => {
    contextDispatcher({
      type: SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER,
      data: { paymentRepeatInfo: !repeat },
    });
    setPaymentInfo(!repeat);
  };

  const regionFormatList = (list: any) => {
    const formatted = list[0]?.subdivisions?.map((item: any) => ({
      label: item.name,
      value: item.name,
    }));

    return formatted;
  };

  const paymentInfoValues: { [key: string]: string } = {
    paymentPayable: contextState.paymentPayable,
    paymentEmail: contextState.paymentEmail,
    paymentPhone: contextState.paymentPhone,
    paymentAddress: contextState.paymentAddress1,
    paymentCity: contextState.paymentCity,
    paymentCountry: contextState.paymentCountry.value,
    country: contextState.paymentCountry.value, // for validator to use with zip
    paymentState: contextState.paymentState.value,
    paymentZip: contextState.paymentZip,

    // Wire Transfer Values
    bankName: contextState.bankName,
    bankAccountNumber: contextState.bankAccountNumber,
    swiftCode: contextState.swiftCode,
    wireTransferName: contextState.wireTransferName,

    // Paypal
    accountHolder: contextState.accountHolder,
    accountEmailAddress: contextState.accountEmailAddress,
  };

  const paymentInfoFields = {
    paymentPhone: ERROR_TYPES.PHONE,
    ...(contextState.paymentMethod.value !== 'Paypal' &&
      contextState.paymentMethod.value !== 'Wire Transfer' && { paymentPayable: ERROR_TYPES.NOT_EMPTY }),
    ...(contextState.paymentMethod.value !== 'Paypal' && { paymentEmail: ERROR_TYPES.EMAIL }),
    ...(contextState.paymentMethod.value !== 'Paypal' && { paymentAddress: ERROR_TYPES.ADDRESS }),
    ...(contextState.paymentMethod.value !== 'Paypal' && { paymentCity: ERROR_TYPES.NOT_EMPTY }),
    ...(contextState.paymentMethod.value !== 'Paypal' && { paymentCountry: ERROR_TYPES.NOT_EMPTY }),
    ...(contextState.paymentMethod.value !== 'Paypal' && { paymentState: ERROR_TYPES.NOT_EMPTY }),
    ...(contextState.paymentMethod.value !== 'Paypal' && { paymentZip: ERROR_TYPES.POSTAL_CODE }),
    ...(contextState.paymentMethod.value === 'Wire Transfer' && { bankName: ERROR_TYPES.NOT_EMPTY }),
    ...(contextState.paymentMethod.value === 'Wire Transfer' && { bankAccountNumber: ERROR_TYPES.BANK_ACCOUNT_NUMBER }),
    ...(contextState.paymentMethod.value === 'Wire Transfer' && { swiftCode: ERROR_TYPES.NOT_EMPTY }),
    ...(contextState.paymentMethod.value === 'Wire Transfer' && { wireTransferName: ERROR_TYPES.NOT_EMPTY }),
    ...(contextState.paymentMethod.value === 'Paypal' && { accountHolder: ERROR_TYPES.NOT_EMPTY }),
    ...(contextState.paymentMethod.value === 'Paypal' && { accountEmailAddress: ERROR_TYPES.EMAIL }),
  };

  const validateForm = () => {
    const isFormValid = validator.validateAll(paymentInfoValues, paymentInfoFields, setInputErrors, true);

    // Only validate Tax Fields if not empty
    const newTaxErrors: { [key: string]: string } = {};
    let isTaxValid = true;
    // United States & Australia
    if (contextState.paymentTAX && TAX_COUNTRIES.includes(contextState.paymentCountry.value)) {
      const taxError = validator.validateTax('us', contextState.paymentTAX, false);
      newTaxErrors.paymentTAX = taxError;
      if (taxError) isTaxValid = false;
    }
    // GST
    if (contextState.paymentGST) {
      const taxError = validator.validateTax('gst', contextState.paymentGST, false);
      newTaxErrors.paymentGST = taxError;
      if (taxError) isTaxValid = false;
    }
    // PST & QST
    if (contextState.paymentPST) {
      if (contextState.paymentState.value === 'Quebec') {
        const qstError = validator.validateTax('qst', contextState.paymentPST, false);
        newTaxErrors.paymentPST = qstError;
        if (qstError) isTaxValid = false;
      } else {
        const pstError = validator.validateTax('pst', contextState.paymentPST, false);
        newTaxErrors.paymentPST = pstError;
        if (pstError) isTaxValid = false;
      }
    }
    // HST
    if (contextState.paymentHST) {
      const hstError = validator.validateTax('hst', contextState.paymentHST, false);
      newTaxErrors.paymentHST = hstError;
      if (hstError) isTaxValid = false;
    }
    setTaxErrors(newTaxErrors);
    return isFormValid && isTaxValid;
  };

  const navigateNextPageHandler = async () => {
    setFailedNext(true);
    if (validateForm()) navigate(path.createAccountStep5.href);
  };

  // Navigate back to first Page if Context is lost (eg. Reloaded Page)
  if (!contextState.userEmail) {
    navigate(path.createAccountStep1.href);
  }

  useEffect(() => {
    getPaymentMethodsHandler();
    getCurrenciesHandler();
    contextDispatcher({
      type: SIGN_UP_ACTIONS.CLEAR_PROGRAM_APPLICAIONS,
    });
  }, []);

  useEffect(() => {
    if (contextState.paymentCountry && hookCountryList) {
      const findCountry = hookCountryList.filter((x) => x.label === contextState.paymentCountry.label);
      setRegionList(regionFormatList(findCountry));
    }
  }, [hookCountryList]);

  useEffect(() => {
    disableTaxFields(contextState.paymentState);
    const findCountry = hookCountryList.filter((x) => x.label === contextState.paymentCountry.label);
    setRegionList(regionFormatList(findCountry));
  }, [contextState.paymentCountry]);

  useEffect(() => {
    disableTaxFields(contextState.paymentState);
  }, [contextState.paymentState]);

  useEffect(() => {
    if (failedNext) validateForm();
  }, [contextState]);

  return {
    hookContextState: contextState,
    hookContextDispatcher: contextDispatcher,
    hookLoading: loadingPaymentMethods || loadingCurrencies,
    hookErrorMessage: errorMessage,
    hookCurrencyList: currencyList,
    hookCountryList,
    hookRegionList: regionList,
    hookPaymentMethodList: paymentMethodList,

    hookRepeatInfo: repeatInfoHandler,

    hookDisableGST: publisherDisabledGST,
    hookDisablePST: publisherDisabledPST,
    hookDisableHST: publisherDisabledHST,
    hookDisableTAX: publisherDisabledTAX,

    hookInputErrors: inputErrors,
    hookTaxErrors: taxErrors,

    hookNextDisabled: nextDisabled,
    hookNavigate: navigateHandler,
    hookNavigateNextPageHandler: navigateNextPageHandler,
  };
};
