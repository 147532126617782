import styled from 'styled-components';

import { Link } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

type InputTextStyledProps = {
  readonly columnSpan?: number;
  readonly gridRow?: string;
};

export const WrapperStyled = styled.div`
  margin-top: 1rem;
  padding: 0 1rem 70px 1rem;
`;

export const RowStyled = styled.div`
  display: grid;
  padding: 2rem 0;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 2rem;

  &:not(:first-child) {
    border-top: 1px solid ${colors.color3};
  }
`;

export const DivAreaStyled = styled.div<InputTextStyledProps>`
  grid-column: span ${({ columnSpan }) => columnSpan || 1};
  grid-row: ${({ gridRow }) => gridRow};
  padding-bottom: ${toRem(32)};
`;

export const SectionTitleStyled = styled.div`
  grid-column: span 5;
  color: ${colors.color1};
  ${fonts.gotham.H18B}
`;

export const PublisherProfileNameStyled = styled.p`
  ${fonts.gotham.H20M}
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  padding: 0.3rem;
`;

export const PublisherProfileSecondNameStyled = styled.p`
  ${fonts.lato.H16}
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  padding: 0.3rem;
`;

export const PublisherInfoStyled = styled.div`
  grid-column: span 5;
  display: grid;
  grid-template-columns: 55px calc(100% - 55px);
  grid-template-rows: repeat(2, calc(55 / 2));
  column-gap: 1.5rem;
`;

export const PublisherLogoStyled = styled.img`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
`;

export const LabelStyled = styled.p`
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
  grid-column: 1/4;
`;

export const ValueStyled = styled.p`
  padding-top: ${toRem(4)};
  ${fonts.lato.H16};
  color: ${colors.color1};
  line-height: 1.5;
`;

export const LinkStyled = styled(Link)`
  padding-top: ${toRem(4)};
  ${fonts.lato.H16};
  line-height: 1.5;
  word-wrap: break-word;
`;

export const ValueGridStyled = styled.div`
  width: 100%;
  display: grid;
  ${fonts.lato.H16};
  color: ${colors.color1};
  line-height: 1.5;
  grid-template-columns: 1fr 1fr 1fr;
`;
