import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';

import { Button, Pagination, Placeholder, Table, FixedFooter, Modal } from '../../../../../../components';
import { MERCHANT_INVITATION } from '../../../../../../utils';
import { columns } from '../../contracts';
import { MEMBERSHIPS } from '../../enum';
import { useMerchantInvitations } from '../../hooks';
import * as Styled from '../styles';

import { MERCHANT_INVITATIONS } from './enum';

type ApplicationPropsType = {
  hook: ReturnType<typeof useMerchantInvitations>;
};

const MerchantInvitationsTab = ({ hook }: ApplicationPropsType) => {
  const { hookIsReadOnlyList } = hook;
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <Styled.WrapperStyled>
      <Styled.TitleStyled>{MERCHANT_INVITATIONS.TITLE}</Styled.TitleStyled>

      <Styled.FiltersStyled>
        <Styled.FiltersLabelStyled>{MERCHANT_INVITATIONS.FILTERS}</Styled.FiltersLabelStyled>

        <Styled.SelectStyled
          options={hook.hookAudienceMarkets}
          name="target"
          onChange={hook.hookSetTargetMarket}
          selected={hook.hookTargetMarket}
          isDisabled={!hook.hookTotalData.length}
          isLoading={hook.hookPageLoading}
        />
        <Styled.SelectStyled
          options={hook.hookCategoryList}
          name="category"
          onChange={hook.hookSetProductCategory}
          selected={hook.hookProductCategory}
          isDisabled={!hook.hookTotalData.length}
          isLoading={hook.hookPageLoading}
        />

        <Button theme="text-only" onClick={hook.hookClearForm} loading={hook.hookPageLoading}>
          {MERCHANT_INVITATIONS.BUTTON.CLEAR}
        </Button>
      </Styled.FiltersStyled>

      {hook.hookTableData.length === 0 && !hook.hookPageLoading && (
        <Placeholder
          title={MEMBERSHIPS.APPLICATIONS.NO_DATA.TITLE}
          subtitle={MEMBERSHIPS.APPLICATIONS.NO_DATA.SUBTITLE}
        />
      )}

      {(hook.hookTableData.length !== 0 || hook.hookPageLoading) && (
        <>
          <Table
            columns={columns}
            data={hook.hookTableData}
            isLoading={hook.hookPageLoading}
            setModalInfo={hook.hookHandleOpenModal}
            sortColumn={hook.hookSort}
            onSort={hook.hookOnSort}
            onRowClick={hook.hookRowClick}
          />
          <Pagination
            currentPage={hook.hookCurrentPage}
            total={hook.hookTotalPages}
            onPageChange={hook.hookSetCurrentPage}
          />
        </>
      )}
      <Modal isOpen={hook.hookIsOpen}>
        <Styled.ModalAreaStyled>
          <Styled.ModalTitleStyled>{`${MERCHANT_INVITATIONS.MODAL.MESSAGE_TITLE} ${hook.hookMessage.name}`}</Styled.ModalTitleStyled>
          <Styled.ModalHeaderStyled>{MERCHANT_INVITATIONS.MODAL.MESSAGE_SUBJECT}</Styled.ModalHeaderStyled>
          <Styled.MessageTextStyled>{hook.hookMessage.subject}</Styled.MessageTextStyled>
          <Styled.ModalHeaderStyled>{MERCHANT_INVITATIONS.MODAL.MESSAGE}</Styled.ModalHeaderStyled>
          <Styled.MessageTextStyled>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{hook.hookMessage.message}</ReactMarkdown>
          </Styled.MessageTextStyled>
          <Styled.ButtonAreaStyled>
            <Button theme="secondary" onClick={hook.hookCancelModal}>
              {MERCHANT_INVITATIONS.BUTTON.CANCEL}
            </Button>
            <Button onClick={hook.hookTermsAndConditions}>{MERCHANT_INVITATIONS.BUTTON.TERMS}</Button>
          </Styled.ButtonAreaStyled>
        </Styled.ModalAreaStyled>
      </Modal>

      <FixedFooter>
        <Styled.FooterWrapperStyled>
          <Styled.FooterTextStyled>
            {MERCHANT_INVITATIONS.FOOTER.TEXT}
            <Styled.TooltipStyled text={MERCHANT_INVITATIONS.FOOTER.TOOLTIP} />
          </Styled.FooterTextStyled>
          <Styled.SelectStyled
            isLoading={hook.hookInvitesLoading || hook.hookInviteChangeLoading}
            menuPlacement="top"
            name="invites"
            onChange={hook.hookSetInviteModal}
            selected={hook.hookInvited}
            options={MERCHANT_INVITATION}
            isDisabled={isReadOnly}
          />{' '}
          <Styled.TimeStyled>{hook.hookRenderedFooter}</Styled.TimeStyled>
        </Styled.FooterWrapperStyled>
      </FixedFooter>
      <Modal isOpen={hook.hookInviteModal}>
        <Styled.ModalAreaStyled>
          <Styled.ModalTitleStyled>{MERCHANT_INVITATIONS.MODAL.TITLE}</Styled.ModalTitleStyled>
          {MERCHANT_INVITATIONS.MODAL.DESC}
          <span>{MERCHANT_INVITATIONS.MODAL.DESC_2}</span>
          <Styled.ButtonAreaStyled>
            <Button onClick={hook.hookCancelInviteModal} theme="secondary">
              {MERCHANT_INVITATIONS.BUTTON.CANCEL}
            </Button>
            <Button
              onClick={() => hook.hookSaveHandler(true)}
              theme="primary"
              loading={hook.hookInviteChangeLoading}
              disabled={isReadOnly}
            >
              {MERCHANT_INVITATIONS.BUTTON.SAVE}
            </Button>
          </Styled.ButtonAreaStyled>
        </Styled.ModalAreaStyled>
      </Modal>
    </Styled.WrapperStyled>
  );
};

export default MerchantInvitationsTab;
