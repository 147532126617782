import styled from 'styled-components';

import { Button } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

export const WrapperStyled = styled.div`
  display: flex;
  padding-top: ${toRem(40)};
  flex-direction: column;
`;

export const SectionTitleStyled = styled.p`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const TableTitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 1rem;
  width: 100%;
`;

export const InputTitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const RowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${toRem(40)};

  &:nth-child(2) {
    display: block;
  }

  &:nth-child(3) {
    flex-flow: row wrap;
  }

  &:nth-child(4),
  &:nth-child(5) {
    justify-content: flex-start;
  }
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;
  margin-left: 2rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const SpecialButtonStyled = styled(Button)`
  margin-top: 1.2rem;
  margin-left: 2rem;
`;

export const ErrorStyled = styled.span`
  margin-top: 0.3rem;
  color: ${colors.color14};
  ${fonts.gotham.H12M}
`;
