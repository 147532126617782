import React from 'react';

import { CellWrapper, CheckboxCell, DetailCell, IdCell, MultiLineCell } from './Components';

export const columns = (): TableColumn[] => [
  {
    dataField: 'check',
    text: '',
    width: '60px',
    formatter: (val, row, tableData, setTableData) => (
      <CheckboxCell val={val} row={row} tableData={tableData} setTableData={setTableData} />
    ),
  },
  {
    dataField: 'publisher',
    text: 'ID',
    width: '100px',
    cellOverflow: true,
    formatter: (val) => <IdCell val={val} />,
  },
  {
    dataField: 'company',
    text: 'Company Name',
    width: 'calc(43% - 210px)',
    formatter: (val, row) => <MultiLineCell val={val} disabled={row.disabled} />,
  },
  {
    dataField: 'memberSince',
    text: 'Start Date',
    width: '16%',
    formatter: (val) => <CellWrapper val={val} />,
  },
  {
    dataField: 'endDate',
    text: 'End Date',
    width: '14%',
    formatter: (val) => <CellWrapper val={val} />,
  },
  {
    dataField: 'url',
    text: 'URL',
    width: '10%',
    formatter: (val) => <CellWrapper val={val} />,
  },
  {
    dataField: 'status',
    text: 'Status',
    width: '16%',
    formatter: (val) => <CellWrapper val={val} />,
  },
  {
    dataField: 'detail',
    text: '',
    width: '50px',
    formatter: (_, row) => <DetailCell publisherId={row.publisher.id} membershipId={row.id} />,
  },
];
