import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Select } from '../../components';
import { fonts, colors } from '../../styles/theme';
import { toRem } from '../../utils';

export const ButtonStyled = styled(Button)``;

export const SelectStyled = styled(Select)`
  margin: 0 1rem;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem auto;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const SubFiltersStyled = HeaderStyled;

export const HeaderButtonStyled = styled.div`
  display: flex;
  gap: 16px;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-bottom: 1rem;
`;

export const AdvanceSelectionStyled = styled.div`
  display: flex;
  align-items: center;
  width: 32%;
  justify-content: space-between;
  padding-top: 1rem;
`;

export const FiltersRowStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const FiltersRowAltStyled = styled(FiltersRowStyled)`
  justify-content: flex-end;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
  gap: 16px;
`;

export const RightStyled = styled.div`
  display: flex;
  align-items: center;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const PlaceholderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;

export const TitleInfoBox = styled.span`
  background: ${colors.color15};
  ${fonts.gotham.H12M}
  padding: 0.5rem;
  margin-left: 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5rem;
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

export const TableDataAnchor = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  color: ${colors.color1};
  width: 100%;

  &:hover {
    color: ${colors.color2};
  }
`;

export const TableDataStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 48px;
  line-height: ${toRem(24)};
  align-items: center;
  white-space: normal;
  justify-content: flex-start;
  flex-direction: row;

  &:hover {
    color: ${colors.color2};
  }

  em {
    font-style: italic;
  }
}`;

export const FAButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 1.3rem;
  width: 30px;
  color: ${colors.color1};
`;
