import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';

import { useModal, useUserInfo } from 'hooks';
import { ReviewStatusChangesType } from 'pages/Merchants/FintelCheck/ChangeMonitoring/ChangeMonitoringDetails/graphql/queries';
import { GET_MONITORING_REVIEW_STATUS_DATA } from 'pages/Merchants/FintelCheck/Components/MonitoringDetailsModal/graphql/getMonitoringReviewStatusData';
import {
  GET_MONITORING_RULE_STATUS_DATA,
  TextPhraseType,
} from 'pages/Merchants/FintelCheck/Components/MonitoringDetailsModal/graphql/getMonitoringRuleStatusData';
import { useHistoryReport } from 'pages/Merchants/FintelCheck/Components/MonitoringHistoryReport/hooks/useHistoryReport';
import { SET_REVIEW_STATUS } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/graphql/mutations/setReviewStatus';
import { SET_RULE_FEEDBACK } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/graphql/mutations/setRuleFeedback';
import {
  RuleReportType,
  RuleCriteriaType,
} from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/graphql/queries/getMonitoringReport';
import { GET_SCREENSHOT_IMAGE } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/graphql/queries/getScreenshotImage';
import { useSummaryReport } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/hooks/useSummaryReport';

const RECORDS_PER_PAGE = 3;

export const useEvaluationRulesReport = () => {
  const isMonitoringReport = false;
  const { hookWhoAmI } = useUserInfo();
  const [isOpen, setIsOpen] = useModal(false);
  // This state variable toggles between displaying the summary report and the history report
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [displaySummary, setDisplaySummary] = useState(true);
  const [selectedReviewStatus, setSelectedReviewStatus] = useState<SelectOption>();
  const [isScreenshotModalOpen, setIsScreenshotModalOpen] = useState(false);
  const [screenshotImage, setScreenshotImage] = useState<string>();
  const [detailedViewModalInfo, setDetailedViewModalInfo] = useState<RuleReportType>();
  const [isDetailedViewModalOpen, setIsDetailedViewModalOpen] = useState(false);

  // Detailed View Modal
  const [reviewStatusNote, setReviewStatusNote] = useState<string>('');
  const [currentReviewStatusPage, setCurrentReviewStatusPage] = useState(1);
  const [totalReviewStatusPages, setTotalReviewStatusPages] = useState(0);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isFeedbackRuleCorrect, setIsFeedbackRuleCorrect] = useState<boolean | null>(null);
  const [selectedFeedbackItem, setSelectedFeedbackItem] = useState<TextPhraseType | RuleCriteriaType>();

  // Text Phrases
  const [textPhrasesData, setTextPhrasesData] = useState<TextPhraseType[]>([]);
  const [ruleSettingsData, setRuleSettingsData] = useState('');

  const [totalReviewStatusTableData, setTotalReviewStatusTableData] = useState<ReviewStatusChangesType[]>([]);
  const [reviewStatusTableData, setReviewStatusTableData] = useState<ReviewStatusChangesType[]>([]);

  const [getScreenshotImage, { loading: getScreenshotImageLoading }] = useLazyQuery(GET_SCREENSHOT_IMAGE);
  const [setReviewStatusMutation, { loading: setReviewStatusLoading }] = useMutation(SET_REVIEW_STATUS);
  const [setRuleFeedbackMutation, { loading: setRuleFeedbackLoading }] = useMutation(SET_RULE_FEEDBACK);
  const [getMonitoringReviewStatusData, { loading: getMonitoringReviewStatusDataLoading }] = useLazyQuery(
    GET_MONITORING_REVIEW_STATUS_DATA
  );
  const [getMonitoringRuleStatusData] = useLazyQuery(GET_MONITORING_RULE_STATUS_DATA);

  const handleOnScreenshotClick = async (val: string): Promise<void> => {
    setIsScreenshotModalOpen(true);
    const { data, error } = await getScreenshotImage({ variables: { input: val }, fetchPolicy: 'no-cache' });
    if (error) {
      setScreenshotImage(undefined);
      return;
    }
    if (data?.getScreenshotImage) {
      setScreenshotImage(data.getScreenshotImage);
    }
  };

  const historyHook = useHistoryReport(handleOnScreenshotClick, false);

  const viewHistoryReport = (row: RuleReportType): void => {
    setDisplaySummary(false);
    historyHook.loadHistory(row);
  };

  const closeScreenshotModal = (): void => {
    setIsScreenshotModalOpen(false);
    setScreenshotImage(undefined);
  };

  /**
   * This function creates a link with it's href attribute set to a data-uri representing the image.
   * It also sets the download attribute to the desired filename and triggers a click event on the link.
   */
  const downloadScreenshot = (): void => {
    try {
      if (!screenshotImage) {
        return;
      }

      const link = document.createElement<'a'>('a');
      link.href = `data:image/jpeg;base64,${screenshotImage}`;
      link.download = 'screenshot.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the screenshot', error);
    }
  };

  // Open the detailed view modal and set the data for the selected row
  const handlerSetDetailedViewModalInfo = (row: RuleReportType): void => {
    setDetailedViewModalInfo(row);
    setIsDetailedViewModalOpen(true);
  };

  const summaryHook = useSummaryReport(
    viewHistoryReport,
    selectedReviewStatus,
    handleOnScreenshotClick,
    handlerSetDetailedViewModalInfo,
    isMonitoringReport
  );

  // Get the review status data for the current change monitoring record
  const getReviewStatusData = async (): Promise<void> => {
    const { data, error } = await getMonitoringReviewStatusData({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId,
          ruleId: detailedViewModalInfo?.id,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      setErrorMessage(error.message);
      return;
    }
    if (data?.getMonitoringReviewStatusData && data.getMonitoringReviewStatusData.statusChanges) {
      setTotalReviewStatusTableData(data.getMonitoringReviewStatusData.statusChanges);
      setReviewStatusTableData(data.getMonitoringReviewStatusData.statusChanges.slice(0, RECORDS_PER_PAGE));
      setCurrentReviewStatusPage(1);
      const pages = Math.ceil((data.getMonitoringReviewStatusData.statusChanges.length || 0) / RECORDS_PER_PAGE);
      setTotalReviewStatusPages(pages > 1 ? pages : 1);
      setErrorMessage('');
    }
  };

  const getTextPhrasesData = async (): Promise<void> => {
    setErrorMessage('');
    setRuleSettingsData('');
    setTextPhrasesData([]);
    const { data, error } = await getMonitoringRuleStatusData({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId,
          resultId: detailedViewModalInfo?.id,
          isMonitoringReport: false,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getMonitoringRuleStatusData) {
      if (data?.getMonitoringRuleStatusData?.ruleStatusSettings) {
        const ruleSettings = data?.getMonitoringRuleStatusData?.ruleStatusSettings;
        const fieldState = ruleSettings?.fieldState?.toLowerCase();
        const fieldsRequired = ruleSettings?.fieldsRequired?.toLowerCase();
        const ruleState = ruleSettings?.ruleState?.toLowerCase();
        if (fieldState && fieldsRequired && ruleState) {
          // eslint-disable-next-line max-len
          const ruleSettingsText = `if ${fieldsRequired} text phrases(s) are ${fieldState} on this page, this rule should  ${ruleState}`;
          setRuleSettingsData(ruleSettingsText);
        }
      }
      if (data?.getMonitoringRuleStatusData?.textPhrases) {
        const textPhrases = data?.getMonitoringRuleStatusData?.textPhrases;
        setTextPhrasesData(textPhrases);
        setErrorMessage('');
      }
    }
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const handleOnSetReviewStatus = async (status: string, uniqueId?: string): Promise<void> => {
    const tableData = displaySummary ? summaryHook.tableData : historyHook.tableData;
    if (tableData) {
      const idsToUpdate = uniqueId
        ? [uniqueId]
        : tableData.filter((item) => item.checked && item.reviewStatus !== status).map((item) => item.id);

      if (idsToUpdate.length === 0) {
        setErrorMessage('No items need updating as their status is already set to the given status.');
        return;
      }
      setErrorMessage('');

      const { data, errors } = await setReviewStatusMutation({
        variables: {
          input: {
            status,
            ids: idsToUpdate,
            userId: hookWhoAmI.id,
            note: reviewStatusNote || '',
          },
        },
        fetchPolicy: 'no-cache',
      });

      setSelectedReviewStatus(undefined);

      if (errors) {
        setErrorMessage(errors[0].message);
        return;
      }
      if (errors || !data?.ruleMonitoringSetReviewStatus) {
        setErrorMessage('Error, Unable to update the review status.');
        return;
      }

      if (data && data.ruleMonitoringSetReviewStatus) {
        if (displaySummary) {
          summaryHook.setSelectedCheckboxItem(undefined);
          summaryHook.setRefetchTable(true);
        }
        setErrorMessage('');
        if (uniqueId) {
          await getReviewStatusData();
          setReviewStatusNote('');
        }
      }
    }
  };

  const resetFeedbackModalValues = (): void => {
    setIsFeedbackModalOpen(false);
    setIsFeedbackRuleCorrect(null);
    setSelectedFeedbackItem(undefined);
  };

  const resetReviewStatus = (): void => {
    setSelectedReviewStatus(undefined);
    setReviewStatusNote('');
  };

  // Close the detailed view modal
  const closeDetailedViewModal = (): void => {
    setIsDetailedViewModalOpen(false);
    setDetailedViewModalInfo(undefined);
    resetReviewStatus();
  };

  // Handle the pagination for the review status table
  const handleOnChangeCurrentPage = (chosenPage: number): void => {
    if (!reviewStatusTableData) {
      setCurrentReviewStatusPage(1);
      return;
    }
    setReviewStatusTableData(
      totalReviewStatusTableData.slice((chosenPage - 1) * RECORDS_PER_PAGE, chosenPage * RECORDS_PER_PAGE)
    );
    setCurrentReviewStatusPage(chosenPage);
  };

  // Disable the set review status button if no status is selected or no note is provided
  const shouldDisableSetReviewStatusButton = (latestStatus?: string): boolean => {
    if (reviewStatusNote.length > 0) {
      return false;
    }
    if (!selectedReviewStatus) {
      return true;
    }
    if (selectedReviewStatus.label === latestStatus) {
      return true;
    }

    if (totalReviewStatusTableData.length > 0 && totalReviewStatusTableData[0].status === selectedReviewStatus.label) {
      return true;
    }
    return false;
  };

  const handleOnFeedbackModalOpen = (data: TextPhraseType | RuleCriteriaType): void => {
    setIsFeedbackModalOpen(true);
    setSelectedFeedbackItem(data);
  };

  const handleFeedbackSubmit = async (): Promise<void> => {
    if (!selectedFeedbackItem || !detailedViewModalInfo) {
      setErrorMessage('Error when submiting feedback');
      return;
    }

    try {
      const hasTextPhrases = Object.keys(selectedFeedbackItem).includes('textPhrase');

      const { data, errors } = await setRuleFeedbackMutation({
        variables: {
          input: {
            isRuleCorrect: isFeedbackRuleCorrect,
            resultId: detailedViewModalInfo?.id,
            userId: hookWhoAmI.id,
            expectedValueId: hasTextPhrases
              ? selectedFeedbackItem.fieldName.concat((selectedFeedbackItem as TextPhraseType)?.textPhrase)
              : selectedFeedbackItem.fieldName.concat((selectedFeedbackItem as RuleCriteriaType)?.expectedValue),
          },
        },
        fetchPolicy: 'no-cache',
      });
      if (errors && errors.length > 0) {
        setErrorMessage(errors[0].message);
        return;
      }
      if (data) {
        setIsFeedbackModalOpen(false);
        setIsFeedbackRuleCorrect(null);
        setSelectedFeedbackItem(undefined);
        setErrorMessage('');
        await getTextPhrasesData();
      }
    } catch (error) {
      setErrorMessage(`${error}`);
    }
  };

  return {
    summaryHook,
    historyHook,
    hookWhoAmI,
    isOpen,
    setIsOpen,
    displaySummary,
    setDisplaySummary,
    errorMessage,
    handleOnSetReviewStatus,
    setReviewStatusLoading,

    selectedReviewStatus,
    setSelectedReviewStatus,

    viewHistoryReport,

    // Detailed View Modal
    isDetailedViewModalOpen,
    setIsDetailedViewModalOpen,
    detailedViewModalInfo,
    handlerSetDetailedViewModalInfo,
    closeDetailedViewModal,

    // Review Status
    reviewStatusNote,
    setReviewStatusNote,
    shouldDisableSetReviewStatusButton,
    getReviewStatusData,
    getMonitoringReviewStatusDataLoading,
    reviewStatusTableData,
    handleOnChangeCurrentPage,
    currentReviewStatusPage,
    totalReviewStatusPages,
    isFeedbackModalOpen,
    setIsFeedbackModalOpen,
    isFeedbackRuleCorrect,
    setIsFeedbackRuleCorrect,
    handleFeedbackSubmit,
    setRuleFeedbackLoading,
    handleOnFeedbackModalOpen,
    setSelectedFeedbackItem,
    resetFeedbackModalValues,

    // Text Phrases
    textPhrasesData,
    getTextPhrasesData,

    // Rule Settings
    ruleSettingsData,

    handleOnScreenshotClick,
    isScreenshotModalOpen,
    screenshotImage,
    closeScreenshotModal,
    getScreenshotImageLoading,
    downloadScreenshot,
  };
};
