import React, { useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import {
  AppWrapper,
  Button,
  Modal,
  PageTitle,
  Table,
  ManageUserModal,
  ErrorBanner,
  DeactivationForm,
} from 'components';
import { ConfirmationModal } from 'components/Modal/ConfirmationModal';
import { DefaultPropsType } from 'types';

import { useAccountDetails } from './hooks';
import {
  BUTTON_LABEL,
  FORM_SECTION_TITLE,
  GENERATE_API_CONFIRMATION,
  GENERATE_API_PART_1,
  GENERATE_API_PART_2,
  PAGE_TITLE,
  useColumns,
} from './contracts';
import {
  ContactInformation,
  PublisherAccountOverview,
  PublisherApiAccessKey,
  PublisherPaymentContact,
  PublisherPaymentDetails,
} from './component';
import * as Styled from './styles';

type AccountDetailsProps = DefaultPropsType & {
  isAdmin: boolean;
};

const AccountDetails = ({ isAdmin, permissionsCodeList = [] }: AccountDetailsProps): JSX.Element => {
  const hook = useAccountDetails(isAdmin, permissionsCodeList);
  const { hookIsReadOnlyList } = hook;

  const [isReadOnly, isUserManagementReadOnly] = isAdmin
    ? [hookIsReadOnlyList[1], hookIsReadOnlyList[2]]
    : hookIsReadOnlyList;
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const isPublisherSettingsReadOnly = isAdmin ? hookIsReadOnlyList[0] : isReadOnly;

  const columnHook = useColumns(hook.hookSideBarOptionsHandler, !isUserManagementReadOnly, hook.hookId);

  const setTabHandler = (value: number): void => {
    setTab(value);
  };

  const hookTabs = { activeTabOveride: tab, setActiveTab: setTabHandler };

  return (
    <AppWrapper
      permissionsCodeList={isAdmin ? permissionsCodeList.slice(1) : permissionsCodeList}
      modalOpen={
        hook.hookApiModalOpen || hook.hookDeactivateIsOpen || hook.hookEditModalOpen || hook.hookManageUserModal
      }
    >
      <ErrorBanner isOpen={hook.hookErrorOpen} message={hook.hookError} />
      {hook.hookAccountDetailsCanAccess && (
        <>
          <Styled.HeaderStyled>
            <PageTitle loadingText="Fetching Company Data" isLoading={hook.hookLoading}>
              {PAGE_TITLE}
            </PageTitle>

            <Styled.HeaderButtonAreaStyled>
              <Styled.HeaderButtonStyled theme="secondary" onClick={() => navigate(-1)}>
                {BUTTON_LABEL.CANCEL}
              </Styled.HeaderButtonStyled>

              <Styled.HeaderButtonStyled
                onClick={hook.hookUpdatePublisher}
                loading={hook.hookSaveLoading}
                disabled={isAdmin ? hookIsReadOnlyList[0] && isReadOnly : isReadOnly}
              >
                {BUTTON_LABEL.SAVE}
              </Styled.HeaderButtonStyled>
            </Styled.HeaderButtonAreaStyled>
          </Styled.HeaderStyled>

          <Styled.ContentStyled>
            <PublisherAccountOverview
              isAdmin={isAdmin}
              isReadOnly={isPublisherSettingsReadOnly}
              hookPublisherAccountStatus={hook.hookPublisherAccountStatus}
              hookPublisherActivityStatus={hook.hookPublisherActivityStatus}
              hookPublisherCreatedDate={hook.hookPublisherCreatedDate}
              hookPublisherID={hook.hookPublisherID}
              hookPublisherName={hook.hookPublisherName}
              hookSetPublisherName={hook.hookSetPublisherName}
              hookSetAccountStatus={hook.hookSetPublisherAccountStatus}
              hookPublisherErrors={hook.hookErrors}
              hookValidateFields={() => hook.hookValidateFields()}
              hookLoading={hook.hookLoading}
            />
            <ContactInformation
              isReadOnly={isReadOnly || isAdmin}
              hookPublisherAddress={hook.hookPublisherAddress}
              hookSetPublisherAddress={hook.hookSetPublisherAddress}
              hookPublisherAddressTwo={hook.hookPublisherAddressTwo}
              hookSetPublisherAddressTwo={hook.hookSetPublisherAddressTwo}
              hookPublisherCity={hook.hookPublisherCity}
              hookSetPublisherCity={hook.hookSetPublisherCity}
              hookPublisherPostalCode={hook.hookPublisherPostalCode}
              hookSetPublisherPostalCode={hook.hookSetPublisherPostalCode}
              hookPublisherEmail={hook.hookPublisherEmail}
              hookSetPublisherEmail={hook.hookSetPublisherEmail}
              hookPublisherPhoneNumber={hook.hookPublisherPhoneNumber}
              hookSetPublisherPhoneNumber={hook.hookSetPublisherPhoneNumber}
              hookPublisherPhoneNumberTwo={hook.hookPublisherPhoneNumberTwo}
              hookSetPublisherPhoneNumberTwo={hook.hookSetPublisherPhoneNumberTwo}
              hookRegion={hook.hookRegion}
              hookSetRegion={hook.hookSetRegion}
              hookRegionOptions={hook.hookRegionOptions}
              hookSelectedCountry={hook.hookSelectedCountry}
              hookSetSelectedCountry={hook.hookSetSelectedCountry}
              hookCountryList={hook.hookCountryOptions}
              hookPublisherErrors={hook.hookErrors}
              hookValidateFields={() => hook.hookValidateFields()}
              hookLoading={hook.hookLoading}
            />

            <PublisherPaymentDetails hook={hook} isReadOnly={isReadOnly || isAdmin} />

            <PublisherPaymentContact hook={hook} isAdmin={isAdmin} isReadOnly={isReadOnly} />

            <PublisherApiAccessKey hook={hook} isReadOnly={isReadOnly} />
          </Styled.ContentStyled>
        </>
      )}
      {hook.hookUserManagementCanAccess && (
        <>
          <Styled.SectionStyled noMarginTop={!hook.hookAccountDetailsCanAccess}>
            <Styled.SectionTitleStyled>
              {FORM_SECTION_TITLE.USER}

              <Styled.FilterStyled>
                <Button
                  theme="tertiary"
                  onClick={() => hook.hookSetManageUserModal()}
                  disabled={isUserManagementReadOnly}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                  {BUTTON_LABEL.ADD_USER}
                </Button>
              </Styled.FilterStyled>
            </Styled.SectionTitleStyled>

            <Table
              noTableOverflow
              columns={columnHook.columns}
              data={hook.hookUserList}
              checkInactive={hook.hookCheckInactive}
              isLoading={hook.hookTableLoading}
              inactiveModal
              onSort={hook.hookTableSortHandler}
              sortColumn={hook.hookTableSortColumn}
            />
          </Styled.SectionStyled>

          <Modal isOpen={hook.hookManageUserModal}>
            <ManageUserModal
              isSelf={hook.hookIsSelf}
              userType="Publisher"
              add
              userTypesId={3}
              closeFn={hook.hookSetManageUserModal}
              hasWritePermission={!isUserManagementReadOnly}
            />
          </Modal>

          <Modal isOpen={hook.hookEditModalOpen}>
            <ManageUserModal
              userToEdit={hook.hookUser}
              key="12321"
              isSelf={hook.hookIsSelf}
              userType="Publisher"
              add={false}
              userTypesId={3}
              closeFn={hook.hookHandleCloseEditModal}
              changeTabHook={hookTabs}
              merchantMemberships={hook.hookMerchantMemberships}
              useTabs={false}
              hasWritePermission={!isUserManagementReadOnly}
            />
          </Modal>

          <DeactivationForm
            title="Deactivate user"
            isOpen={hook.hookDeactivateIsOpen}
            onClose={hook.hookHandleDeactive}
            onCloseText="Cancel"
            onConfirm={hook.hookConfirmDeactivate}
            onConfirmText="Deactivate"
            userInformation={{
              firstName: hook.hookUser.firstName,
              lastName: hook.hookUser.lastName,
              email: hook.hookUser.email,
            }}
            isLoading={hook.hookUpdateUserLoading}
            desc="Are you sure you would like to deactivate this user?"
          />
        </>
      )}

      <ConfirmationModal
        isOpen={hook.hookApiModalOpen}
        title="Generate New API key"
        isLoading={hook.hookGenerateModalLooading}
        onCloseText="Cancel"
        onConfirmText="Generate Key"
        onClose={hook.hookHandleApiModal}
        onConfirm={hook.hookConfirmGenerateApi}
        width="400px"
      >
        <Styled.ApiDesc>
          <p>{GENERATE_API_CONFIRMATION}</p>
          {GENERATE_API_PART_1}
          &nbsp;
          {hook.hookApiCreatedAt}
          {GENERATE_API_PART_2}
        </Styled.ApiDesc>
      </ConfirmationModal>
    </AppWrapper>
  );
};

export default AccountDetails;
