import React from 'react';

import * as Styled from './styles';

type CellProps = {
  value: any;
  url?: string;
};

export const CellCompany = ({ value, url }: CellProps) => (
  <Styled.CellStyled>
    {value}
    <Styled.SecondValueStyled href={url} target="_blank">
      {url}
    </Styled.SecondValueStyled>
  </Styled.CellStyled>
);
