import styled from 'styled-components';

import { Button } from '../Button';
import { Select } from '../Input';

export const WrapperStyled = styled.div`
  width: 400px;
  padding: 1rem;
`;

export const SelectStyled = styled(Select)`
  margin: 2rem 0;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;
