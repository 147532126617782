import styled from 'styled-components';

import { Button, InputText, Select } from '../../../../../components';
import { colors, fonts } from '../../../../../styles/theme';

type CommissionStructureProps = {
  isCustom?: boolean;
};

type FlexWrapperProps = {
  noMargin?: boolean;
};

export const CommissionStructureWrapper = styled.div<CommissionStructureProps>`
  margin-top: ${({ isCustom }) => (isCustom ? '4rem' : '2rem')};
  color: ${colors.color1};
`;

export const TitleStyled = styled.div`
  ${fonts.gotham.H22};
`;

export const RadioSectionStyled = styled.div`
  margin-top: 2.5rem;
`;

export const RadioStyled = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
`;

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ noMargin }) => (noMargin ? '0rem' : '2rem')};
`;

export const StaticFieldsStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectStyled = styled(Select)``;

export const SelectThreeCStyled = styled(Select)`
  width: 50%;
  margin-right: 2rem;
`;

export const InputTextThreeCStyled = styled(InputText)`
  &:nth-child(2) {
    padding: 0 1rem;
  }
`;

export const GridStyled = styled.div`
  display: grid;
  grid-template-columns: 14fr 14fr 0.5fr;
  grid-column-gap: 2%;
  grid-row-gap: 2rem;
  width: 100%;
  margin-top: 2.5rem;
`;

export const InputTextTwoCStyled = styled(InputText)`
  grid-column: span 14fr;
`;

export const DeleteButtonStyled = styled(Button)`
  background: transparent;
  color: ${colors.color16};
  font-size: 1.2rem;
  border: none;
  margin-top: 1rem;
  padding: 0;

  &:hover {
    background: transparent;
    color: ${colors.color2};
    border: none;
  }
`;

export const InputTextAfterStyled = styled(InputText)`
  grid-column: 1;
`;

export const SelectAfterStyled = styled(Select)`
  grid-column: 1;
`;

export const ButtonFieldStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 2.5rem;
  svg {
    margin-right: 5px;
  }
`;
