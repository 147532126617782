import styled from 'styled-components';

import { colors } from '../../../../../../styles/theme';

export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
`;

export const NameCellStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const URLStyled = styled.a`
  color: ${colors.color2};
  padding-top: 0.5rem;
`;

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;
