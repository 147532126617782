import React from 'react';
import { faArrowUpRightFromSquare, faChevronRight, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { LinkCard, ProductByApproval, StatusCard } from '../components';
import { colors, fonts } from '../../../../styles/theme';
import { PRODUCT_BY_APPROVALS, STATUS_CARD } from '../enums';
import { ProductsApprovalStructure } from '../components/Graph/ProductByApproval/types';
import { path } from '../../../../utils/path';
import { MERCHANT_PREFIX } from '../../../../utils';
import environment from '../../../../config/environment';

const WrapperStyled = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 7rem 0;
`;

const TitleStyled = styled.span`
  ${fonts.gotham.H14M}
`;

const DescriptionStyled = styled.span`
  ${fonts.lato.H14}
  margin: 1rem 0;
  line-height: 1.5;
`;

export const formatDomain = (suffix: any) => {
  let domain = '';
  switch (environment.app.environment) {
    case 'localDev':
    case 'development':
    case 'dev':
      domain = `dev${suffix}`;
      break;
    case 'staging':
    case 'demo':
      domain = `${environment.app.environment}${suffix}`;
      break;
    // prod
    default:
      break;
  }
  return domain;
};

export const useRenderCards = () => {
  const displayAnnouncements = (show: boolean, amount: number) => {
    if (show) {
      return (
        <LinkCard title={`You have ${amount} new annoucement`} color={colors.color2} link="" icon={faChevronRight} />
      );
    }
    return null;
  };

  const displayMessages = (show: boolean, amount: number) => {
    if (show) {
      return (
        <LinkCard
          title={`View your message updates (${amount})`}
          color={colors.color2}
          link={`https://support.${formatDomain('.')}app.fintelconnect.com/inbox`}
          icon={faArrowUpRightFromSquare}
          externalLink
        />
      );
    }
    return null;
  };

  const displayAccountBalance = (show: boolean) => {
    if (show) {
      return (
        <StatusCard
          color={colors.color14}
          title={STATUS_CARD.ACCOUNT_BALANCE.TITLE}
          tooltip={STATUS_CARD.ACCOUNT_BALANCE.TOOLTIP}
          amount="Low"
          icon={faDollarSign}
          link={`${MERCHANT_PREFIX}${path.accountBalance.href}`}
        />
      );
    }
    return null;
  };

  const displayProductsByApproval = (data: ProductsApprovalStructure[]) =>
    data.length > 0 ? (
      <ProductByApproval productsData={data} />
    ) : (
      <WrapperStyled>
        <TitleStyled>{PRODUCT_BY_APPROVALS.NO_DATA_TITLE}</TitleStyled>
        <DescriptionStyled>{PRODUCT_BY_APPROVALS.NO_DATA_DESCRIPTION}</DescriptionStyled>
      </WrapperStyled>
    );

  return {
    hookDisplayAnnouncements: displayAnnouncements,
    hookDisplayMessages: displayMessages,
    hookDisplayAccountBalance: displayAccountBalance,
    hookDisplayProductsByApproval: displayProductsByApproval,
  };
};
