import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';
import { toRem } from '../../utils';

type WrapperStyledProps = {
  readonly errorMessage?: string;
};

export const WrapperStyled = styled.div<WrapperStyledProps>`
  display: flex;
  flex-direction: column;

  .quill {
    border: ${({ errorMessage }) => (errorMessage ? `1px solid ${colors.color14}` : `1px solid ${colors.color3}`)};
    border-radius: 4px;

    &:hover {
      border: ${({ errorMessage }) => (errorMessage ? `1px solid ${colors.color14}` : `1px solid ${colors.color1}`)};
    }
  }

  .ql-editor {
    min-height: 160px;
    max-height: 480px;
    p {
      ${fonts.lato.H16};
      margin: ${toRem(16)} 0;
    }
    strong {
      ${fonts.lato.H16B};
    }
    em {
      font-style: italic;
    }
    sub,
    sup {
      font-size: ${toRem(11)};
    }
  }

  .ql-hidden {
    display: none;
  }

  .ql-tooltip {
    left: 1rem !important;
  }

  .ql-editor a {
    color: ${colors.color2};
  }

  .quill > .ql-container > .ql-editor.ql-blank::before {
    opacity: 0.4;
    font-size: ${toRem(14)};
    font-style: normal;
  }

  .ql-formats {
    .ql-undo,
    .ql-redo {
      &:hover {
        svg {
          path {
            fill: none !important;
          }
        }
      }
    }
    button {
      cursor: pointer;
      position: relative;

      &:hover::after {
        background: #0d1e42;
        color: white;
        padding: 0.5em;
        border-radius: 0.4em;
        top: -110%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999999;
        position: absolute;
        font-size: 12px;
        width: max-content;
      }
    }

    span {
      &:hover::after {
        background: #0d1e42;
        color: white;
        padding: 0.5em;
        border-radius: 0.4em;
        top: -110%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999999;
        position: absolute;
        font-size: 12px;
        width: max-content;
      }
    }

    &:hover {
      svg {
        fill: ${colors.color2} !important;

        path,
        line {
          fill: ${colors.color2} !important;
        }
      }
    }
  }

  .ql-header {
    &:hover::after {
      content: 'Block Type';
    }
  }

  .ql-size {
    &:hover::after {
      content: 'Font Size';
    }
  }

  .ql-font {
    &:hover::after {
      content: 'Font';
    }
  }

  .ql-bold {
    &:hover::after {
      content: 'Bold';
    }
  }

  .ql-italic {
    &:hover::after {
      content: 'Italic';
    }
  }

  .ql-underline {
    &:hover::after {
      content: 'Underline';
    }
  }

  .ql-strike {
    &:hover::after {
      content: 'Strikethrough';
    }
  }

  .ql-blockquote {
    &:hover::after {
      content: 'Blockquote';
    }
  }

  .ql-code {
    &:hover::after {
      content: 'Code';
    }
  }

  .ql-script[value='sub'] {
    &:hover::after {
      content: 'Subscript';
    }
  }

  .ql-script[value='super'] {
    &:hover::after {
      content: 'Superscript';
    }
  }

  .ql-color {
    &:hover::after {
      content: 'Color Picker';
    }
  }

  .ql-background {
    &:hover::after {
      content: 'Background';
    }
  }

  .ql-align {
    &:hover::after {
      content: 'Align';
    }
  }

  .ql-list[value='ordered'] {
    &:hover::after {
      content: 'Ordered';
    }
  }

  .ql-list[value='bullet'] {
    &:hover::after {
      content: 'Unordered';
    }
  }

  .ql-indent {
    &:hover::after {
      content: 'Indent';
    }
  }

  .ql-link {
    &:hover::after {
      content: 'Link';
    }
  }

  .ql-image {
    &:hover::after {
      content: 'Image';
    }
  }

  .ql-video {
    &:hover::after {
      content: 'Video';
    }
  }

  .ql-document {
    &:hover::after {
      content: 'Document';
    }
  }

  .ql-emoji {
    &:hover::after {
      content: 'Emoji';
    }
  }

  .ql-clean {
    &:hover::after {
      content: 'Remove';
    }
  }

  .ql-undo {
    &:hover::after {
      content: 'Undo';
    }
  }

  .ql-redo {
    &:hover::after {
      content: 'Redo';
    }
  }

  .ql-container {
    ${fonts.lato.H14}
    border: transparent;
  }

  .ql-toolbar {
    ${fonts.gotham.H14}
    border: transparent;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before {
    content: '16';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before {
    content: 'Arial';
    font-family: 'Arial';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='georgia']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='georgia']::before {
    content: 'Georgia';
    font-family: 'Georgia';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='impact']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='impact']::before {
    content: 'Impact';
    font-family: 'Impact';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='tahoma']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='tahoma']::before {
    content: 'Tahoma';
    font-family: 'Tahoma';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before {
    content: 'Times New Roman';
    font-family: 'Times New Roman';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before {
    content: 'Verdana';
    font-family: 'Verdana';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
    content: 'Monospace';
    font-family: 'Monospace';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='8px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8px']::before {
    content: '8';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='9px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
    content: '9';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
    content: '10';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='11px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='11px']::before {
    content: '11';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
    content: '12';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
    content: '14';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
    content: '16';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
    content: '18';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
    content: '24';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='30px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='30px']::before {
    content: '30';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='36px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
    content: '36';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
    content: '48';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='60px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='60px']::before {
    content: '60';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='72px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='72px']::before {
    content: '72';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='96px']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='96px']::before {
    content: '96';
  }
`;

export const LabelTextStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${toRem(11)};
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12B}
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const ErrorMessageStyled = styled.div`
  ${fonts.lato.H14};
  color: ${colors.color14};
  padding-top: ${toRem(4)};
`;
