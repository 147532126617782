import { gql } from '@apollo/client';

export const GET_MEMBERS = gql`
  query members($input: ID!) {
    group(id: $input) {
      memberships {
        id
        status
        approvedDate
        createdAt
        publisherGroups {
          id
        }
        publisher {
          id
          companyName
          geoIp {
            IP
            country
          }
          trackings {
            primaryWebsite
            websiteStatus
          }
        }
        pendingTrans {
          id
        }
      }
    }
  }
`;
