/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { Spinner } from '../Spinner';

import { useDocumentUpload, useUndo, useRedo } from './hooks';
import * as Styled from './styles';
import { Fonts, Icons, Modules, Sizes } from './utils';

type WysiwygProps = {
  label?: string;
  isLoading?: boolean;
  required?: boolean;
  readonly?: boolean;
  value?: string;
  placeholder?: string;
  error?: string;
  onChange: (value: string) => void;
};

Fonts();
Icons();
Sizes();

export const Wysiwyg = ({
  required,
  label,
  isLoading,
  value,
  readonly,
  onChange,
  placeholder,
  error,
}: WysiwygProps) => {
  const editor = useRef(null);
  const { hookDocumentChange } = useDocumentUpload(editor);
  const { hookUseUndo } = useUndo(editor);
  const { hookUseRedo } = useRedo(editor);

  return (
    <Styled.WrapperStyled errorMessage={error}>
      {label && (
        <Styled.LabelTextStyled>
          {label} {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}{' '}
          {isLoading && <Spinner theme="secondary" />}
        </Styled.LabelTextStyled>
      )}

      <ReactQuill
        ref={editor}
        theme="snow"
        value={value}
        placeholder={placeholder}
        readOnly={readonly}
        onChange={(txt: string) => {
          onChange(txt);
        }}
        modules={useMemo(
          () => ({
            toolbar: {
              container: Modules,
              handlers: {
                document: hookDocumentChange,
                undo: hookUseUndo,
                redo: hookUseRedo,
              },
            },
            history: {
              delay: 500,
              maxStack: 100,
              userOnly: false,
            },
            'emoji-toolbar': true,
          }),
          []
        )}
      />
      <Styled.ErrorMessageStyled>{error}</Styled.ErrorMessageStyled>
    </Styled.WrapperStyled>
  );
};
