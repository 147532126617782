import React from 'react';

import { AppWrapper, PageTitle, Tabs, Button, ErrorBanner } from '../../../../components';
import { DefaultPropsType } from '../../../../types';
import EditCommissionModal from '../Modal/EditCommissionModal';

import { Details, PublishersGroups } from './Tabs';
import { EDIT_COMMISSIONS } from './enums';
import { useEditCommissions } from './hooks';
import * as Styled from './styles';

type EditCommissionsProps = DefaultPropsType;

const EditCommissions = ({ permissionsCodeList = [] }: EditCommissionsProps) => {
  const hook = useEditCommissions(permissionsCodeList);
  const { hookIsReadOnlyList } = hook;
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <EditCommissionModal hook={hook} />

      <ErrorBanner isOpen={!!hook.hookErrorMessage} message={hook.hookErrorMessage} />
      <Styled.HeaderStyled>
        <PageTitle isLoading={hook.hookPageLoading} loadingText={hook.hookLoadingMessage}>
          {`${EDIT_COMMISSIONS.PAGE_TITLE} ${hook.hookIsCustom ? 'Custom' : 'Default'} ${hook.hookCommissionType} ${EDIT_COMMISSIONS.COMMISSION}`}
        </PageTitle>
        <Styled.ButtonsWrapper>
          <Button
            theme="secondary"
            onClick={() => hook.hookNavigateBack()}
            width="104px"
            loading={hook.hookSaveCommissionLoading}
          >
            {EDIT_COMMISSIONS.BUTTONS.CANCEL}
          </Button>
          <Button
            onClick={hook.saveCommissionClickHandler}
            width="104px"
            loading={hook.hookPageLoading}
            disabled={isReadOnly}
          >
            {EDIT_COMMISSIONS.BUTTONS.SAVE}
          </Button>
        </Styled.ButtonsWrapper>
      </Styled.HeaderStyled>
      {hook.hookIsCustom ? (
        <Tabs
          tabNames={['Details', 'Publishers/Groups']}
          elements={[
            <Details key="details" hook={hook} isReadOnly={isReadOnly} />,
            <PublishersGroups key="publishersGroups" hook={hook} isReadOnly={isReadOnly} />,
          ]}
        />
      ) : (
        <Details hook={hook} isReadOnly={isReadOnly} />
      )}
      <EditCommissionModal hook={hook} />
    </AppWrapper>
  );
};

export default EditCommissions;
