import { gql } from '@apollo/client';

export const GET_PUBLISHER_USER = gql`
  query users($userType: String, $companyId: String) {
    users(userType: $userType, companyId: $companyId) {
      users {
        firstName
        lastName
      }
    }
  }
`;
