export type EligibilityFeedbackReducerModalProps = {
  correctStatus: boolean | undefined;
};

export const ELIGIBILITY_FEEDBACK_ACTIONS = {
  SET_STATUS_CONFIRMATION: Symbol('set status confirmation'),
  RESET_MODAL: Symbol('reset modal'),
};

export const eligibilityFeedbackModalReducer = (
  state: EligibilityFeedbackReducerModalProps,
  { type, data }: BaseReducerType
) => {
  switch (type) {
    case ELIGIBILITY_FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION:
      return {
        ...state,
        ...data,
      };
    case ELIGIBILITY_FEEDBACK_ACTIONS.RESET_MODAL:
      return {
        ...state,
        ...data,
      };
    default:
      return { ...state };
  }
};
