import { gql } from '@apollo/client';

export const UPLOAD_PRODUCT_CSV = gql`
  mutation uploadProductsFile($input: ProductsFileInput) {
    uploadProductsFile(input: $input) {
      products {
        id
      }
    }
  }
`;
