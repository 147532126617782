import React from 'react';

import { path } from '../../utils';

import * as Styled from './styles';

type ProgressProp = {
  step: number;
};

const Progress = ({ step }: ProgressProp) => (
  <Styled.ProgressStyled>
    {step === 1 ? (
      <Styled.CurrentStepStyled>
        <Styled.CheckpointInnerStyled>
          <Styled.CheckpointStyled />
        </Styled.CheckpointInnerStyled>

        <Styled.StepStyled>step 1</Styled.StepStyled>
        {path.createAccountStep1.name}
      </Styled.CurrentStepStyled>
    ) : (
      <Styled.OtherStepsStyled>
        <Styled.NextCheckpointStyled />

        <Styled.StepStyled>step 1</Styled.StepStyled>
        {path.createAccountStep1.name}
      </Styled.OtherStepsStyled>
    )}

    <Styled.CheckpointPathWrapperStyled>
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
    </Styled.CheckpointPathWrapperStyled>

    {step === 2 ? (
      <Styled.CurrentStepStyled>
        <Styled.CheckpointInnerStyled>
          <Styled.CheckpointStyled />
        </Styled.CheckpointInnerStyled>

        <Styled.StepStyled>step 2</Styled.StepStyled>
        {path.createAccountStep2.name}
      </Styled.CurrentStepStyled>
    ) : (
      <Styled.OtherStepsStyled>
        <Styled.NextCheckpointStyled />

        <Styled.StepStyled>step 2</Styled.StepStyled>
        {path.createAccountStep2.name}
      </Styled.OtherStepsStyled>
    )}

    <Styled.CheckpointPathWrapperStyled>
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
    </Styled.CheckpointPathWrapperStyled>

    {step === 3 ? (
      <Styled.CurrentStepStyled>
        <Styled.CheckpointInnerStyled>
          <Styled.CheckpointStyled />
        </Styled.CheckpointInnerStyled>

        <Styled.StepStyled>step 3</Styled.StepStyled>
        {path.createAccountStep3.name}
      </Styled.CurrentStepStyled>
    ) : (
      <Styled.OtherStepsStyled>
        <Styled.NextCheckpointStyled />

        <Styled.StepStyled>step 3</Styled.StepStyled>
        {path.createAccountStep3.name}
      </Styled.OtherStepsStyled>
    )}

    <Styled.CheckpointPathWrapperStyled>
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
    </Styled.CheckpointPathWrapperStyled>

    {step === 4 ? (
      <Styled.CurrentStepStyled>
        <Styled.CheckpointInnerStyled>
          <Styled.CheckpointStyled />
        </Styled.CheckpointInnerStyled>

        <Styled.StepStyled>step 4</Styled.StepStyled>
        {path.createAccountStep4.name}
      </Styled.CurrentStepStyled>
    ) : (
      <Styled.OtherStepsStyled>
        <Styled.NextCheckpointStyled />

        <Styled.StepStyled>step 4</Styled.StepStyled>
        {path.createAccountStep4.name}
      </Styled.OtherStepsStyled>
    )}

    <Styled.CheckpointPathWrapperStyled>
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
    </Styled.CheckpointPathWrapperStyled>

    {step === 5 ? (
      <Styled.CurrentStepStyled>
        <Styled.CheckpointInnerStyled>
          <Styled.CheckpointStyled />
        </Styled.CheckpointInnerStyled>

        <Styled.StepStyled>step 5</Styled.StepStyled>
        {path.createAccountStep5.name}
      </Styled.CurrentStepStyled>
    ) : (
      <Styled.OtherStepsStyled>
        <Styled.NextCheckpointStyled />

        <Styled.StepStyled>step 5</Styled.StepStyled>
        {path.createAccountStep5.name}
      </Styled.OtherStepsStyled>
    )}

    <Styled.CheckpointPathWrapperStyled>
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
      <Styled.CheckpointPathStyled />
    </Styled.CheckpointPathWrapperStyled>

    {step === 6 ? (
      <Styled.CurrentStepStyled>
        <Styled.CheckpointInnerStyled>
          <Styled.CheckpointStyled />
        </Styled.CheckpointInnerStyled>

        <Styled.StepStyled>step 6</Styled.StepStyled>
        {path.createAccountStep6.name}
      </Styled.CurrentStepStyled>
    ) : (
      <Styled.OtherStepsStyled>
        <Styled.NextCheckpointStyled />

        <Styled.StepStyled>step 6</Styled.StepStyled>
        {path.createAccountStep6.name}
      </Styled.OtherStepsStyled>
    )}
  </Styled.ProgressStyled>
);

export default Progress;
