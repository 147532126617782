import { gql } from '@apollo/client';

export const UPDATE_MEMBERSHIPS = gql`
  mutation Mutation($inputs: [MembershipInput], $status: MembershipStatus, $statusLastUpdatedBy: MembershipUserType) {
    updateMembership(inputs: $inputs, status: $status, statusLastUpdatedBy: $statusLastUpdatedBy) {
      n
      nModified
    }
  }
`;
