import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Button, ErrorBanner, Pagination, Select, Table } from '../../../components';
import { BulkUploadOutputType } from '../AddTransactions/Tabs/BulkImportLog/types';

import { useShowBulkImportLogDetails } from './hooks';
import * as Styled from './styles';
import { columns } from './contracts';

type BulkImportLogPropTypes = {
  details?: BulkUploadOutputType;
  backButtonFunction: () => void;
};

const BulkImportLogDetails = ({ details, backButtonFunction }: BulkImportLogPropTypes) => {
  const showBulkImportHook = useShowBulkImportLogDetails({ details });

  return (
    <>
      <ErrorBanner isOpen={!!showBulkImportHook.hookErrorMessage} message={showBulkImportHook.hookErrorMessage} />
      <Styled.FiltersRowStyled>
        <Styled.FileDescriptionStyled>
          {`${details?.merchant.id} ${details?.merchant.companyName} / ${details?.originalFilename}`}
        </Styled.FileDescriptionStyled>
        <Button theme="tertiary" onClick={() => backButtonFunction()} width="104px">
          Back
        </Button>
      </Styled.FiltersRowStyled>
      <Styled.FiltersRowRightStyled>
        <Styled.TextStyled>Show</Styled.TextStyled>
        <Select
          name="showSelect"
          label=""
          options={showBulkImportHook.hookFilterOptions}
          onChange={showBulkImportHook.hookSetFilter}
          width="250px"
          selected={showBulkImportHook.hookSelectedStatus}
        />
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={showBulkImportHook.hookGetCSV}
          loading={showBulkImportHook.hookGetCSVLoading}
          width="150px"
        >
          <FontAwesomeIcon icon={faFile} />
          Download CSV
        </Styled.ButtonStyled>
      </Styled.FiltersRowRightStyled>

      <Table data={showBulkImportHook.hookTableData} columns={columns} isLoading={showBulkImportHook.hookIsLoading} />

      <Pagination
        total={showBulkImportHook.hookTotalPages}
        currentPage={showBulkImportHook.hookPage}
        onPageChange={showBulkImportHook.hookSetPage}
      />
    </>
  );
};

export default BulkImportLogDetails;
