import { gql } from '@apollo/client';

export const GET_GROUP = gql`
  query group($input: ID!) {
    group(id: $input) {
      id
      name
      description
      createdAt
      program {
        id
      }
      memberships {
        id
        status
        approvedDate
        createdAt
        publisher {
          id
          companyName
          geoIp {
            IP
            country
          }
          trackings {
            primaryWebsite
            websiteStatus
          }
        }
        pendingTrans {
          id
        }
      }
    }
  }
`;
