import React from 'react';

import * as Styled from '../styles';

type InfoHeaderProps = {
  type: string;
  value: string;
};

export const InfoHeader = ({ type, value }: InfoHeaderProps) => (
  <div>
    <Styled.CategoryStyled>{type}</Styled.CategoryStyled>
    <Styled.TextStyled>{value}</Styled.TextStyled>
  </div>
);
