import styled from 'styled-components';

import { colors, fonts } from '../../../../styles/theme';
import { Select } from '../../../../components';
import { toRem } from '../../../../utils';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeaderLeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const DescriptionStyled = styled.p`
  ${fonts.gotham.H16M}
  color: ${colors.color1};
  margin-top: 1rem;
`;

export const ReportWrapper = styled.div`
  height: fit-content;
  margin-top: 2rem;
  padding: 2rem 1rem 1rem 1rem;
  background-color: ${colors.color4};
`;

export const SelectStyled = styled(Select)`
  width: ${toRem(270)};
`;

export const TableWrapper = styled.div`
  margin-top: 2rem;
`;
