import React from 'react';

import { Button, InputText, Select } from '../../../../../../components';
import { UPDATE_REVIEW_STATUS } from '../../enum';

import * as Styled from './styles';
import { REVIEW_STATUS } from './enums';

type ReviewStatusProps = {
  setSelectedReviewStatus: React.Dispatch<
    React.SetStateAction<
      | {
          label: string;
          value: string;
        }
      | undefined
    >
  >;
  selectedReviewStatus:
    | {
        label: string;
        value: string;
      }
    | undefined;
  setReviewStatusNote: React.Dispatch<React.SetStateAction<string>>;
  reviewStatusNote: string;
  shouldDisableSetReviewStatusButton: (latestStatus?: string) => boolean;
  setReviewStatusLoading: boolean;
  onClickAction: (value: any) => void;
  latestStatus?: string;
  dropdownOptions?: SelectOption[];
};

const ReviewStatus = ({
  setSelectedReviewStatus,
  selectedReviewStatus,
  setReviewStatusNote,
  reviewStatusNote,
  shouldDisableSetReviewStatusButton,
  setReviewStatusLoading,
  onClickAction,
  latestStatus,
  dropdownOptions = REVIEW_STATUS,
}: ReviewStatusProps) => (
  <Styled.SetStatusContent>
    <Select
      options={dropdownOptions}
      onChange={(e) => setSelectedReviewStatus(e)}
      selected={selectedReviewStatus}
      placeholder="Select Review Status"
      menuPlacement="top"
      label="Update Review Status"
    />
    <InputText
      type="text"
      label=""
      placeholder="Add your review note here..."
      value={reviewStatusNote}
      onChange={(e) => setReviewStatusNote(e.target.value)}
      maxLength={255}
    />
    <Button
      onClick={onClickAction}
      theme="primary"
      width="25rem"
      disabled={shouldDisableSetReviewStatusButton(latestStatus)}
      loading={setReviewStatusLoading}
    >
      {UPDATE_REVIEW_STATUS}
    </Button>
  </Styled.SetStatusContent>
);

export default ReviewStatus;
