import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useUserInfo } from '../../../../../hooks';
import { GET_PUBLISHER } from '../graphql/queries';

type UserProps = {
  id: number;
  name: string;
  companyName: string;
  accountStatus: string;
  registration: string;
  application: string;
};

export const useAccountInformation = () => {
  const { hookWhoAmI } = useUserInfo();
  const [user, setUser] = useState<UserProps>();
  const [getPublisher, { loading }] = useLazyQuery(GET_PUBLISHER);

  const getPublisherHandler = async () => {
    const { data } = await getPublisher({
      variables: {
        input: {
          id: hookWhoAmI.companyId,
        },
      },
    });

    setUser({
      id: data.showInternalPublisher.id,
      name: data.showInternalPublisher.name,
      companyName: data.showInternalPublisher.companyName,
      accountStatus: data.showInternalPublisher.accountStatus,
      registration: new Date(data.showInternalPublisher.registrationDate).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
      application: new Date(data.showInternalPublisher.applicationDate).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    });
  };

  useEffect(() => {
    getPublisherHandler();
  }, []);

  return {
    hookLoadingPublisher: loading,
    hookUser: user,
  };
};
