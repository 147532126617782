import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';

import { Spinner } from '../../Spinner';
import { ImagePreview } from '../../ImagePreview';

import { useDropZone } from './hooks';
import * as Styled from './styles';

type DropZoneProps = {
  onChange?: (file: File) => void;
  className?: string;
  label?: string;
  required?: boolean;
  isLoading?: boolean;
  previewUrl?: string;
  name: string;
  tooltip?: string;
  disabled?: boolean;
  accept?: string;
  maxFileSize?: number;
  info?: string;
  type?: string;
};

export const DropZone = ({
  onChange,
  className,
  label,
  required = false,
  isLoading = false,
  name,
  previewUrl,
  tooltip,
  disabled = false,
  accept,
  maxFileSize = 1000,
  info,
  type,
}: DropZoneProps) => {
  const {
    hookSetFiles,
    hookDragOver,
    hookDragLeave,
    hookIsDraggingOver,
    hookUpload,
    hookImagePreviewUrl,
    hookFile,
    hookSetImagePreviewUrl,
    hookError,
  } = useDropZone(maxFileSize, onChange, type, accept);

  useEffect(() => {
    if (previewUrl) {
      hookSetImagePreviewUrl(previewUrl);
    }
  }, [previewUrl]);

  return (
    <Styled.WrapperStyled className={className}>
      {hookError && <Styled.ErrorStyled>{hookError}</Styled.ErrorStyled>}

      {label && (
        <Styled.LabelTextStyled>
          {label} {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}{' '}
          {tooltip && <Styled.TooltipStyled text={tooltip} />} {isLoading && <Spinner theme="secondary" />}
        </Styled.LabelTextStyled>
      )}

      <Styled.ZoneStyled
        isDragging={hookIsDraggingOver}
        onDragOver={hookDragOver}
        onDrop={hookSetFiles}
        onDragLeave={hookDragLeave}
        disabled={disabled}
      >
        <Styled.EmptyZoneStyled isDisplayed={!!hookFile || !!hookImagePreviewUrl} disabled={disabled}>
          <FontAwesomeIcon icon={faArrowUpFromBracket} />

          <Styled.EmptyZoneTextStyled>
            <Styled.UploadLabelStyled htmlFor={name}>Upload a file</Styled.UploadLabelStyled> or drag and drop here
          </Styled.EmptyZoneTextStyled>
        </Styled.EmptyZoneStyled>

        <Styled.NonEmptyZoneStyled isDisplayed={!!hookFile || !!hookImagePreviewUrl} disabled={disabled}>
          <Styled.ImagePreviewBoxStyled>
            <ImagePreview src={hookImagePreviewUrl} alt="preview" useType="dropZone" width="auto" />
          </Styled.ImagePreviewBoxStyled>
          <Styled.OptionsAreaStyled>
            {!disabled ? (
              <>
                <Styled.OptionsAreaTextStyled>Drop file here to upload or</Styled.OptionsAreaTextStyled>
                <Styled.ButtonStyled htmlFor={name}>Choose another</Styled.ButtonStyled>
              </>
            ) : (
              'Not allowed to change'
            )}
          </Styled.OptionsAreaStyled>
        </Styled.NonEmptyZoneStyled>

        <input type="file" onChange={hookUpload} id={name} name={name} disabled={disabled} accept={accept} />
      </Styled.ZoneStyled>
      {info && <Styled.InfoStyled>{info}</Styled.InfoStyled>}
    </Styled.WrapperStyled>
  );
};
