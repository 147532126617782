import React from 'react';
import { faDesktop, faLongArrowAltDown, faLongArrowAltUp, faMobile, faTablet } from '@fortawesome/free-solid-svg-icons';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement } from 'chart.js';

import { colors } from '../../../../../../styles/theme';
import {
  returnColorBaseOnValue,
  returnIconBaseOnValue,
  truncateNumberAndRoundWithDecimals,
} from '../../../../../../utils';

import * as Styled from './styles';
import { ClickStructure } from './types';

type ClickByDeviceProps = {
  clickData: ClickStructure;
};

export const ClickByDevice = ({ clickData }: ClickByDeviceProps) => {
  ChartJS.register(ArcElement);
  const data = {
    labels: ['Desktop', 'Mobile', 'Tablet'],
    datasets: [
      {
        backgroundColor: [colors.color21, colors.color2, colors.color12],
        hoverBackgroundColor: [colors.color21, colors.color2, colors.color12],
        data: [clickData.desktop, clickData.mobile, clickData.tablet],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    config: {
      cutout: 70,
    },
  };

  return (
    <>
      <Styled.ChartWrapperStyled>
        <Styled.TotalClickStyled>
          <Styled.TotalClickItemStyled>Total Clicks</Styled.TotalClickItemStyled>
          <Styled.TotalClickValueStyled>{clickData.total.toLocaleString()}</Styled.TotalClickValueStyled>
        </Styled.TotalClickStyled>
        <Doughnut data={data} width="80%" options={options} />
      </Styled.ChartWrapperStyled>

      <Styled.CaptionWrapperStyled>
        <Styled.CaptionStyled>
          <Styled.IconStyled icon={faDesktop} color={colors.color21} />
          <Styled.CaptionNameStyled>Desktop</Styled.CaptionNameStyled>
          <Styled.CaptionNumberStyled>
            {truncateNumberAndRoundWithDecimals(clickData.desktopPercentage)}%
          </Styled.CaptionNumberStyled>
          <Styled.PercentageStyled
            color={returnColorBaseOnValue({
              value: clickData.desktopPerformanceIncrease,
              negativeColor: colors.color14,
              neutralColor: colors.color2,
              positiveColor: colors.color21,
            })}
          >
            {clickData.desktopPerformanceIncrease !== 0 && (
              <Styled.IconStyled
                margin
                color={returnColorBaseOnValue({
                  value: clickData.desktopPerformanceIncrease,
                  negativeColor: colors.color14,
                  neutralColor: colors.color2,
                  positiveColor: colors.color21,
                })}
                icon={returnIconBaseOnValue({
                  value: clickData.desktopPerformanceIncrease,
                  negativeIcon: faLongArrowAltDown,
                  positiveIcon: faLongArrowAltUp,
                })}
              />
            )}
            {truncateNumberAndRoundWithDecimals(clickData.desktopPerformanceIncrease, 0)}%
          </Styled.PercentageStyled>
        </Styled.CaptionStyled>
        <Styled.CaptionStyled>
          <Styled.IconStyled icon={faMobile} color={colors.color2} />
          <Styled.CaptionNameStyled>Mobile</Styled.CaptionNameStyled>
          <Styled.CaptionNumberStyled>
            {truncateNumberAndRoundWithDecimals(clickData.mobilePercentage)}%
          </Styled.CaptionNumberStyled>
          <Styled.PercentageStyled
            color={returnColorBaseOnValue({
              value: clickData.mobilePerformanceIncrease,
              negativeColor: colors.color14,
              neutralColor: colors.color2,
              positiveColor: colors.color21,
            })}
          >
            {clickData.mobilePerformanceIncrease !== 0 && (
              <Styled.IconStyled
                margin
                color={returnColorBaseOnValue({
                  value: clickData.mobilePerformanceIncrease,
                  negativeColor: colors.color14,
                  neutralColor: colors.color2,
                  positiveColor: colors.color21,
                })}
                icon={returnIconBaseOnValue({
                  value: clickData.mobilePerformanceIncrease,
                  negativeIcon: faLongArrowAltDown,
                  positiveIcon: faLongArrowAltUp,
                })}
              />
            )}
            {truncateNumberAndRoundWithDecimals(clickData.mobilePerformanceIncrease, 0)}%
          </Styled.PercentageStyled>
        </Styled.CaptionStyled>
        <Styled.CaptionStyled>
          <Styled.IconStyled icon={faTablet} color={colors.color12} />
          <Styled.CaptionNameStyled>Tablet</Styled.CaptionNameStyled>
          <Styled.CaptionNumberStyled>
            {truncateNumberAndRoundWithDecimals(clickData.tabletPercentage)}%
          </Styled.CaptionNumberStyled>
          <Styled.PercentageStyled
            color={returnColorBaseOnValue({
              value: clickData.tabletPerformanceIncrease,
              negativeColor: colors.color14,
              neutralColor: colors.color2,
              positiveColor: colors.color21,
            })}
          >
            {clickData.tabletPerformanceIncrease !== 0 && (
              <Styled.IconStyled
                margin
                color={returnColorBaseOnValue({
                  value: clickData.tabletPerformanceIncrease,
                  negativeColor: colors.color14,
                  neutralColor: colors.color2,
                  positiveColor: colors.color21,
                })}
                icon={returnIconBaseOnValue({
                  value: clickData.tabletPerformanceIncrease,
                  negativeIcon: faLongArrowAltDown,
                  positiveIcon: faLongArrowAltUp,
                })}
              />
            )}
            {truncateNumberAndRoundWithDecimals(clickData.tabletPerformanceIncrease, 0)}%
          </Styled.PercentageStyled>
        </Styled.CaptionStyled>
      </Styled.CaptionWrapperStyled>
    </>
  );
};
