import React from 'react';

import { CellWrapper } from '../../styles';

export const DateCell = ({ value }: any) => {
  const date = value
    ? new Date(value).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })
    : '';
  return (
    <CellWrapper>
      <p>{date}</p>
    </CellWrapper>
  );
};
