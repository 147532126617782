import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Select, Table } from '../../components';
import { colors, fonts } from '../../styles/theme';

type IconPropsTypes = {
  readonly color: string;
};

type TopContainerStyledTypes = {
  readonly hasAccess: boolean;
};

export const WrapperStyled = styled.div``;

export const TopContainerStyled = styled.div<TopContainerStyledTypes>`
  display: flex;
  flex-direction: ${({ hasAccess }) => (hasAccess ? 'row' : 'column')};
  justify-content: space-between;
`;

export const ContentStyled = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 35% calc(65% - 2rem);
  column-gap: 2rem;
  margin-top: 2.5rem;
`;

export const ContentColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxStyled = styled.div`
  border: 1px solid ${colors.color19};
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
`;

export const SmallTextStyled = styled.p`
  color: ${colors.color13};
  ${fonts.gotham.H12M}
  padding: 0.5rem;
`;

export const BigNumberStyled = styled.p`
  ${fonts.lato.H24}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  span {
    color: ${colors.color1};
  }

  svg {
    margin-right: 0.5rem;

    &:nth-of-type(2) {
      margin-left: 1rem;
    }
  }
`;

export const SectionTitleStyled = styled.p`
  ${fonts.gotham.H18}
  color: ${colors.color1};
  margin-bottom: 2rem;
`;

export const TableStyled = styled(Table)`
  width: 100%;
`;

export const FlexStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const FlexTitleStyled = styled.p`
  ${fonts.gotham.H18}
  color: ${colors.color1};
`;

export const SelectStyled = styled(Select)`
  width: 300px;
`;

export const LoadingStyled = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 8px;
`;

export const IconStyled = styled(FontAwesomeIcon)<IconPropsTypes>`
  color: ${({ color }) => color};
`;

export const TitleStyled = styled.h3`
  ${fonts.gotham.H24}
  color: ${colors.color1};
  margin-top: 0.5rem;
`;
