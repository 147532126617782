import React from 'react';

import { CellProps } from '../../types';
import { CellWrapper, WebsiteStyled } from '../../styles';

export const Company = ({ val }: CellProps) => (
  <CellWrapper>
    <p>{val.name}</p>
    <WebsiteStyled href={val.website} target="_blank" rel="noopener noreferrer">
      {val.website}
    </WebsiteStyled>
  </CellWrapper>
);
