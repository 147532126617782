import styled from 'styled-components';

import { Button, InputText, Select } from '../../../../components';
import { CodeEditor } from '../../../../components/Input/Code';
import { fonts } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  margin-bottom: 1rem;
`;

export const TitleStyled = styled.div`
  ${fonts.gotham.H22}
  margin: 3rem 0;
`;

export const ButtonStyled = styled(Button)`
  width: 160px;
  height: 40px;

  svg {
    margin-right: 5px;
  }
`;

export const FieldsStyled = styled.div`
  display: flex;
  column-gap: 2%;
  margin: 2rem 0;
  align-items: start;
  justify-content: space-between;

  ${ButtonStyled} {
    margin-top: 20px;
  }
`;

export const CheckboxWrapperStyled = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 1rem;
  ${fonts.lato.H16}
`;

export const InputWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 2%;
  margin-top: 3rem;
`;

export const AdvancedStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2%;
  row-gap: 2.5rem;
`;

export const InputStyled = styled(InputText)`
  grid-column: 1 / 3;
`;

export const SelectStyled = styled(Select)`
  grid-column: 1 / 4;
`;

export const CodeEditorStyled = styled(CodeEditor)`
  margin-top: 3rem;
`;

export const FooterStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5rem;
  margin-bottom: 1rem;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const CodeFieldStyled = styled.div`
  margin: 2rem 0 1rem 0;
`;
