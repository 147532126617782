import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
  gap: 0.5rem;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubtitleStyled = styled.p`
  ${fonts.gotham.H16M}
  color: ${colors.color1};
  margin-bottom: 2rem;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 360px) 360px;
  grid-gap: 1.5rem;
  margin-bottom: 2rem;
`;

export const ExtendFiltersStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectStyled = styled(Select)`
  width: 180px;
`;

export const LabelStyled = styled.span`
  ${fonts.lato.H14}
`;

export const CellStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  height: 40px;
`;

export const LinkStyled = styled.a`
  ${fonts.gotham.H12};
  color: ${colors.color2};
  display: block;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  cursor: pointer;
`;
