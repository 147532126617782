import styled from 'styled-components';

import { Button, Pagination, Table } from '../../../components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type TableDataStyledProps = {
  readonly theme?: string;
};

export const ModalContentWrapperStyled = styled.div`
  width: 500px;
  padding: ${toRem(40)};
`;

export const ModalTitleStyled = styled.div`
  ${fonts.gotham.H26M}
`;

export const ModalDescriptionStyled = styled.div`
  ${fonts.lato.H16}
  color: ${colors.color7};
  margin: ${toRem(40)} 0;
`;

export const ModalSelectionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${toRem(40)};
`;

export const ModalButtonsStyled = styled.div`
  margin-top: ${toRem(40)};
  display: flex;
  justify-content: end;
`;

export const ModalButtonStyled = styled(Button)`
  margin-left: ${toRem(10)};
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: ${toRem(40)};
`;

export const FilterStyled = styled.div`
  display: flex;
  width: 50%;
`;

export const FilterClearButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  margin-top: ${toRem(20)};
  width: 30%;
`;

export const FilterDownloadButtonStyled = styled(Button)`
  margin-bottom: ${toRem(5)};

  svg {
    margin-right: ${toRem(10)};
  }
`;

export const TableStyled = styled(Table)`
  margin: ${toRem(40)} 0;
`;

export const TableDataStyled = styled.div<TableDataStyledProps>`
  display: flex;
  align-items: center;
  min-height: 35px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

  justify-content: ${({ theme }) => (theme === 'secondary' ? 'space-between' : 'flex-start')};

  svg {
    margin-right: ${toRem(10)};
    color: ${({ theme }) => (theme === 'secondary' ? colors.color1 : colors.color2)};
  }

  p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const TableCellLink = styled.div`
  &:hover {
    svg {
      color: ${colors.color2};
    }
  }
`;

export const TableDataDetailStyled = styled.div`
  min-height: 35px;
  svg {
    margin-right: ${toRem(10)};
    color: ${colors.color10};
  }
`;

export const TableFooterStyled = styled.div`
  color: ${colors.color2};
`;

export const PaginationStyled = styled(Pagination)``;

export const LoadingWrapperStyled = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
