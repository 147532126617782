import styled from 'styled-components';

import { AppWrapper, Button, Select, Table } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

type WrapperStyledProps = {
  readonly minHeight?: string;
};

type TableStyledProps = {
  readonly isLoading?: boolean;
};

export const AppWrapperStyled = styled(AppWrapper)`
  background-color: ${colors.color20};
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const MerchantWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1.5rem;
`;

export const SelectStyled = styled(Select)`
  width: 300px;
`;

export const WrapperStyled = styled.div<WrapperStyledProps>`
  width: 100%;
  background: ${colors.color4};
  padding: 2rem;
  min-height: ${({ minHeight }) => minHeight || 'initial'};
`;

export const BodyStyled = styled.div`
  display: grid;
  width: 100%;
  max-width: 100%;
  grid-template-columns: calc(56% - 3em) 44%;
  column-gap: 3rem;
  margin: 3rem 0;
`;

export const FooterBodyStyled = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: calc(70% - 3em) 30%;
  column-gap: 3rem;
  margin: 3rem 0;
  height: 476px;
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 1rem;
`;

export const StatisticWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  background: ${colors.color4};
`;

export const SectionTitleStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.gotham.H20M}
  color: ${colors.color5};
  margin-bottom: 1.5rem;
`;

export const TopPublisherStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const TitleStyled = styled.span`
  ${fonts.gotham.H14M}
  color: ${colors.color1};
`;

export const DescriptionStyled = styled.span`
  ${fonts.lato.H14}
  margin: 1rem 0;
  width: 40%;
  line-height: 1.5;
  color: ${colors.color1};
`;

export const TopProductStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80%;
`;

export const MerchantTableStyled = styled.div`
  width: 100%;
  background: ${colors.color4};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 4px solid ${colors.color21};
  border-radius: 3px;
  min-height: 170px;
`;

export const TableWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  padding-bottom: 0;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  color: ${colors.color21};
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-top: 1px solid ${colors.color35};

  &:hover {
    color: ${colors.color21};
    border-top: 1px solid ${colors.color13};
  }
`;

export const ProductApprovalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenteredSpinnerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const TableStyled = styled(Table)<TableStyledProps>`
  height: ${({ isLoading }) => (isLoading ? '100%' : '')};
`;
