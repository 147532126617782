import styled from 'styled-components';

import { Button } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabWrapperStyled = styled.div`
  gap: ${toRem(30)};
  display: flex;
  flex-direction: column;
  margin-top: ${toRem(40)};
`;

export const TabTitleStyled = styled.p`
  ${fonts.gotham.H22};
  color: ${colors.color1};
`;

export const ButtonStyled = styled(Button)``;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: end;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const ProductProfileStyled = styled.div``;

export const ProductStyled = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-end;
  margin: 2rem 0;
`;

export const DescriptiveSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
`;

export const Subtitle = styled.p`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  text-transform: uppercase;
`;

export const ContentStyled = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
`;

export const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${toRem(64)};
  margin-bottom: ${toRem(40)};
`;

export const ProductImgStyled = styled.img`
  margin-right: 2rem;
  max-width: ${toRem(80)};
`;

export const ProductNameStyled = styled.p`
  color: ${colors.color1};
  ${fonts.lato.H22B}
  margin-bottom: 1rem;
`;

export const ProductLinkStyled = styled.a`
  ${fonts.lato.H16}
  color: ${colors.color12};

  &:hover {
    color: ${colors.color2};
  }
`;
