import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';
import { toRem } from '../../utils';
import { InputText } from '../Input';

type DayItemProps = {
  readonly isToday?: boolean;
  readonly isDisabled?: boolean;
  readonly isSelected?: boolean;
};

type WrapperProps = {
  readonly isOpen: boolean;
};

type RangeSelectorButtonStyledProps = {
  readonly isSelected?: boolean;
};

export const WrapperStyled = styled.div<WrapperProps>`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
`;

export const InnerStyled = styled.div<WrapperProps>`
  max-width: 940px;
  max-height: 550px;
  background-color: ${colors.color4};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  margin-top: ${({ isOpen }) => (isOpen ? '0' : '-50px')};
`;

export const BodyStyled = styled.div`
  width: 316px;
  display: flex;
  flex-direction: column;

  &:nth-of-type(2) {
    margin-left: 3rem;
  }
`;

export const WeekListStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const WeekItemStyled = styled.div`
  padding: 0 ${toRem(12)};
  width: calc(100% / 7);
  ${fonts.lato.H14M}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: ${colors.color1};
`;

export const DayListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  min-height: 220px;
`;

export const DayItemStyled = styled.label<DayItemProps>`
  ${fonts.lato.H14}
  width: calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: ${colors.color1};
  cursor: pointer;
  border-radius: 5px;

  ${({ isToday }) => {
    if (isToday) {
      return `
        text-decoration: underline;
        text-decoration-color: ${colors.color2};
        text-decoration-thickness: 2px;
        text-underline-position: under;
      `;
    }
    return '';
  }}

  &:hover {
    background-color: ${colors.color6};
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }

  input {
    display: none;
  }
  ${({ isSelected }) =>
    isSelected
      ? ` 
    background-color: ${colors.color2};
    color: ${colors.color4};
    `
      : ''}
`;

export const InvalidDayStyled = styled(DayItemStyled)`
  color: ${colors.color7};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'default')};
  text-decoration: none;

  &:hover {
    color: ${colors.color7};
    background-color: ${({ isDisabled }) => (isDisabled ? colors.color6 : 'transparent')};
  }
`;

export const MonthWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const DropdownWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SelectionStyled = styled.select`
  cursor: pointer;
  border: none;
  background-color: transparent;
  ${fonts.lato.H14};
  text-align: center;

  &:nth-child(1) {
    width: 60%;
  }
  &:nth-child(2) {
    width: 40%;
    padding-right: 20px;
  }
`;

export const OptionStyled = styled.option`
  font-family: arial;
`;

export const DateAreaStyled = styled.div`
  display: flex;
`;

export const RangeSelectorContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colors.color15};
  margin-right: 2rem;
  padding: 0 2rem 0 0;
`;

export const RangeSelectorButtonStyled = styled.button<RangeSelectorButtonStyledProps>`
  margin-bottom: 0.8rem;
  padding: 0.5rem;
  text-align: left;
  background-color: transparent;
  border: none;
  ${fonts.lato.H14}
  color: ${colors.color1};
  cursor: pointer;
  &:hover {
    color: ${colors.color2};
  }
  ${({ isSelected }) =>
    isSelected
      ? ` 
    background-color: ${colors.color2};
    color: ${colors.color4};
    border-radius: 5px;
    &:hover {
      color: ${colors.color4};
    }
    `
      : ''}
`;

export const FooterStyled = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${colors.color15};
  justify-content: flex-end;
  align-items: center;
  padding-top: ${toRem(24)};

  button {
    margin-left: 1rem;
  }
`;

export const BodyTopStyled = styled.div`
  display: flex;
`;

export const InputTextStyled = styled(InputText)`
  height: 100px;
`;
