import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faNoteSticky, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { dateFormatter, monthNames, TABLE_FOOTER_USD, TABLE_FOOTER_CAD } from 'utils';
import { Button, Checkbox } from 'components';

import * as Styled from './styles';

const paymentMethodMinimums: any = {
  Cheque: {
    AUD: 60,
    CAD: 75,
    USD: 60,
  },
  'Cheque Payment for $1000': {
    USD: 1000,
    CAD: 1000,
    AUD: 1000,
  },
  Paypal: {
    AUD: 20,
    CAD: 25,
    USD: 20,
  },
  'Wire Transfer': {
    USD: 500,
    CAD: 500,
    AUD: 500,
  },
};

const CheckBoxCellFormatter = (checked: boolean, onChange: (state: any) => void): JSX.Element => (
  <Styled.CellWrapperStyled theme="checkBox">
    <Checkbox checked={checked} onChange={onChange} />
  </Styled.CellWrapperStyled>
);

const FormatPayments = (
  _: any,
  row: any,
  __: any,
  ____: any,
  ___: any,
  ______: any,
  _______: any,
  nav: (values: Record<string, any>) => void
): JSX.Element => {
  const { paymentInfo } = row;
  return (
    <Styled.ArrowDiv>
      <div>{paymentInfo?.tax}</div>
      <Button theme="text-only" onClick={() => nav({ row })}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </Styled.ArrowDiv>
  );
};

const AddNote = (onClick: () => void, row: any): JSX.Element => {
  const { referenceNumber, note } = row;
  return (
    <Styled.Wrapper>
      <Styled.IconButtonStyled onClick={onClick}>
        <FontAwesomeIcon icon={faNoteSticky} />
      </Styled.IconButtonStyled>
      {referenceNumber === null && note !== null && <FontAwesomeIcon icon={faExclamationTriangle} />}
    </Styled.Wrapper>
  );
};

const getMinimumPayable = (currency: string, paymentMethod: string): number => {
  const minimums = paymentMethodMinimums[paymentMethod];
  if (minimums && minimums[currency]) {
    return minimums[currency];
  }
  return 0;
};

const FooterDiv = (top: any, bottom: any) => (
  <Styled.FooterDiv>
    <div>{top}</div>
    <div>{bottom}</div>
  </Styled.FooterDiv>
);

export const columns: TableColumn[] = [
  {
    dataField: 'checked',
    text: '',
    headerFormatter: (headerCheckBox: any, headerCheckBoxHandler: (state: any) => void) =>
      CheckBoxCellFormatter(headerCheckBox, headerCheckBoxHandler),
    formatter: (_: any, row: any, __: any, ___: any, handleCheckBox?: (id: any, state: any) => void) =>
      CheckBoxCellFormatter(row.checked, (state: boolean) => {
        if (handleCheckBox) {
          handleCheckBox(row.id, state);
        }
      }),
    width: '40px',
    isCheckBox: true,
    footerFormatter: () => FooterDiv(TABLE_FOOTER_CAD, TABLE_FOOTER_USD),
  },
  {
    dataField: 'note',
    text: 'Reference/ Note',
    width: '3%',
    formatter: (
      _: any,
      row: any,
      __: any,
      ___: any,
      ____: any,
      _____: any,
      setModalInfo: (values: Record<string, any>) => void
    ) => AddNote(() => setModalInfo({ id: row.id, note: row.note, referenceNumber: row.referenceNumber }), row),
  },
  {
    dataField: 'paymentStatusApproved',
    text: 'Payment Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '6%',
  },
  {
    dataField: 'id',
    text: 'Payment ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '4%',
  },
  {
    dataField: 'month',
    text: 'Payment Period',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '4%',
    formatter: (value: any, row: any) => (
      <div>
        {monthNames[value - 1]} / {row.year}
      </div>
    ),
  },
  {
    dataField: 'publisher',
    text: 'publisher',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>
        {value.id} - {value.companyName}
      </div>
    ),
    width: '8%',
  },
  {
    dataField: 'paymentMethod',
    text: 'Method',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '5%',
  },
  {
    dataField: 'paymentGeneratedDate',
    text: 'Generated Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? dateFormatter(new Date(value)) : ''}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentPaidDate',
    text: 'Paid Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value ? dateFormatter(new Date(value)) : ''}</div>,
    width: '5%',
  },
  {
    dataField: 'currency',
    text: 'Currency',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '5%',
  },
  {
    dataField: 'paymentMethod',
    text: 'Min Payment Amount',
    formatter: (val: any, row: any) => <div>{getMinimumPayable(row.currency, val)}</div>,
    width: '5%',
  },
  {
    dataField: 'currentRollover',
    text: 'Rollover Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value.toFixed(2)}</div>,
    width: '5%',
  },
  {
    dataField: 'totalCommissions',
    text: 'Total Commission',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value.toFixed(2)}</div>,
    width: '5%',
  },
  {
    dataField: 'bonus',
    text: 'Bonus Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value.toFixed(2)}</div>,
    width: '5%',
  },
  {
    dataField: 'bonusTax',
    text: 'Bonus Tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value.toFixed(2)}</div>,
    width: '5%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.bonusTaxCad.toFixed(2), footerData?.bonusTaxUsd.toFixed(2)),
  },
  {
    dataField: 'totalTaxes',
    text: 'Total Tax',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value.toFixed(2)}</div>,
    width: '5%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.commissionTaxCad.toFixed(2), footerData?.commissionTaxUsd.toFixed(2)),
  },
  {
    dataField: 'totalPayable',
    text: 'Total Payable',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '5%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.totalPayableCad.toFixed(2), footerData?.totalPayableUsd.toFixed(2)),
    formatter: (value: any) => <div>{value.toFixed(2)}</div>,
  },
  {
    dataField: 'periodBalance',
    text: 'Period Balance',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value.toFixed(2)}</div>,
    width: '5%',
    footerFormatter: (_: any, __: any, footerData?: { [key: string]: any }) =>
      FooterDiv(footerData?.periodBalanceCad.toFixed(2), footerData?.periodBalanceUsd.toFixed(2)),
  },
  {
    dataField: 'paymentInfo.payableTo',
    text: 'payable to',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.payableTo?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.accountHolder',
    text: 'account holder',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.accountHolder?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.accountEmail',
    text: 'account email',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.accountEmail?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.accountPhoneNumber',
    text: 'account phone number',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.accountPhoneNumber?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.bankName',
    text: 'bank name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.bankName?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.bankAccountNumber',
    text: 'bank account number',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.bankAccountNumber?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.bankCode',
    text: 'swift code / iban / routing',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.bankCode?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.transit',
    text: 'transit',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.transit?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.wireTransferName',
    text: 'wire transfer name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.wireTransferName?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.email',
    text: 'payment contact email',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.email?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.phone',
    text: 'phone number',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.phone?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.phone2',
    text: 'phone number 2',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.phone2?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.address1',
    text: 'address 1',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.address1?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.address2',
    text: 'address 2',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.address2?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.city',
    text: 'city',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.city?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.zip',
    text: 'zip',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.zip?.replace(/.(?=.{4,}$)/g, '*')}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.country',
    text: 'country',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.country}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.state',
    text: 'state/province',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.state}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.gst',
    text: 'gst',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.gst}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.pst',
    text: 'pst',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.pst}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.hst',
    text: 'hst',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: any, row: any) => <div>{row.paymentInfo?.hst}</div>,
    width: '5%',
  },
  {
    dataField: 'paymentInfo.tax',
    text: 'tax no.',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: FormatPayments,
    width: '5%',
  },
];
