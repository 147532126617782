import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { LIST_PRODUCT_FEED_DATA } from '../graphql/queries';
import { CheckRulesOutputType, Rule } from '../../../types';
import { TOAST_ERR_MESSAGES_NO_PAGE } from '../../../../../../../utils';

export type FeedCategoryType = {
  category: string;
  values: {
    productName: string;
    value: string;
  }[];
};

// Copied from old app for formatting values displayed in table
export const formatFeedItem = (val: any, field: string) => {
  let newVal = val;
  if (typeof val === 'object') {
    newVal = val.join(' || ');
  }
  if (field === 'annualFee') {
    if (val && val.toString().charAt(0) !== '$') newVal = `$${val}`;
  } else if (field !== 'legalReference' && field !== 'name') {
    if (val && val.toString().charAt(val.length - 1) !== '%') newVal = `${val}%`;
  }
  return newVal;
};

export const useDetailsModal = (value?: CheckRulesOutputType | Rule) => {
  const [parametersOpen, setParametersOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [feedData, setFeedData] = useState<FeedCategoryType[]>();
  const [tableData, setTableData] = useState<FeedCategoryType>();

  const [listProductFeedData, { loading: productFeedLoading }] = useLazyQuery(LIST_PRODUCT_FEED_DATA);

  const setFeedDataHandler = async () => {
    if (!value) return;
    setErrorMessage('');
    const { data, error } = await listProductFeedData({
      variables: {
        input: {
          merchantId: value?.merchantId,
          productCategory: value?.productCategory,
          parameters: value?.productFeed.map((feed) => feed.productFeed),
        },
      },
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (error) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(error?.message));
      return;
    }
    if (data.listProductFeedData) {
      setFeedData(data.listProductFeedData.data);
    }
  };

  const handleSetParametersOpen = (state: boolean, feedName?: string) => {
    if (state === false) {
      setParametersOpen(false);
      return;
    }
    if (!feedName || !feedData) return;
    const index = feedData.findIndex((feed) => feed.category === feedName);
    if (index === -1) {
      setTableData({ category: feedName || '', values: [] });
      setParametersOpen(true);
      return;
    }
    setTableData(structuredClone(feedData[index]));
    setParametersOpen(true);
  };

  // Handles setting the data to display
  useEffect(() => {
    setFeedDataHandler();
  }, [value]);

  return {
    hookParametersOpen: parametersOpen,
    hookSetParametersOpen: handleSetParametersOpen,

    hookTableData: tableData,
    hookFeedLoading: productFeedLoading,

    hookErrorMessage: errorMessage,
  };
};
