import styled from 'styled-components';

import { Button, InputCalendar, InputText, Select } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

type AdvancedSearchProps = {
  readonly isOpen: boolean;
};

export const AdvanceFilterSectionStyled = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1.5rem;
  align-items: flex-start;

  &:nth-child(2) {
    margin-top: 2rem;
  }

  &:nth-child(3) {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
`;

export const CalendarFilter = styled(InputCalendar)`
  width: calc(100% / 3 - 100px);
  margin-right: 2rem;
`;

export const FilterFooterStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const FooterSelectStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 190px;
  ${fonts.lato.H14}
`;

export const FilterButtonsStyled = styled.div`
  display: flex;
  margin-top: 15px;
  height: 50%;
`;

export const SearchButtonStyled = styled(Button)`
  margin-left: 15px;
  gap: 10px;
`;

export const InputTextStyled = styled(InputText)`
  grid-column: span 6;
  margin-bottom: 2rem;
`;

export const SelectStyled = styled(Select)`
  grid-column: span 3;
`;

export const LeftSideStyled = styled.div`
  grid-column: span 3;
  span:nth-child(2) {
    ${fonts.lato.H16};
    color: ${colors.color1};
  }
`;

export const RightSideStyled = styled.div`
  grid-column: span 3;
  justify-self: end;
  margin-right: -1rem;
`;

export const RecordsPerPageStyled = styled(Select)`
  width: 120px;
  margin-left: 1rem;
`;

export const RecordsPerPageTextStyled = styled.span`
  color: ${colors.color1};
  ${fonts.lato.H16}
`;

export const AdvancedSearchStyled = styled.div<AdvancedSearchProps>`
  display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
  grid-template-columns: repeat(6, 1fr);
  margin-top: -0.5rem;
  column-gap: 1.5rem;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  padding-bottom: 2rem;
`;

export const AdvancedSelectStyled = styled(Select)`
  grid-column: span 2;
  margin-bottom: 1.5rem;
`;

export const AdvancedSearchTitleStyled = styled.p`
  grid-column: span 6;
  text-transform: uppercase;
  color: ${colors.color13};
  margin-bottom: 1rem;
  ${fonts.gotham.H12M}
`;

export const RegistrationTitleStyled = styled.p`
  ${fonts.lato.H14}
  color: ${colors.color1};
  width: 160px;
`;
