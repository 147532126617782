import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { useMutation } from '@apollo/client';

import { ActionCellProps } from '../../types';
import * as Styled from '../../styles';
import { MODAL_KEYS } from '../../../reducers';
import { useToast, useUserInfo } from '../../../../../../hooks';
import { CREATE_REPORT } from '../../../graphql';
import { path } from '../../../../../../utils';

export const ActionsCell = ({ row, reportCreatedNotifier, setModals }: ActionCellProps) => {
  const navigate = useNavigate();
  const { hookWhoAmI } = useUserInfo();
  const [createReport] = useMutation(CREATE_REPORT);
  const { hookShowToast } = useToast();

  const disableButton = () => {
    if (hookWhoAmI.id !== row.user.id) {
      return true;
    }
    return false;
  };

  const copyHandler = async () => {
    const newName = {
      name: `${row.name} (Copy)`,
    };
    const formattedFilters = row.filters.map((filter: any) => ({
      field: filter.field,
      value: {
        label: filter.value.label,
        value: filter.value.value,
      },
    }));
    const formattedColumns = row.columns.map((column: any) => ({
      label: column.label,
      value: column.value,
    }));

    const { data } = await createReport({
      variables: {
        save: {
          ...newName,
          description: row.description,
          frequency: row.frequency,
          every: row.every,
          company: row.company,
          report: row.report,
          filters: formattedFilters,
          columns: formattedColumns,
          user: {
            id: hookWhoAmI.id,
            email: hookWhoAmI.email,
          },
        },
      },
    });

    if (data?.createUpdateReportProfile?.id !== undefined) {
      reportCreatedNotifier();
      hookShowToast(`${row.name} has been copied`);
    }
  };

  const editHandler = () => {
    const params = {
      id: row.id,
    };
    navigate({
      pathname: path.editSavedReports.href,
      search: `${createSearchParams(params)}`,
    });
  };

  const deleteHandler = () => {
    setModals({
      [MODAL_KEYS.openDeleteModal]: true,
      modalInfo: {
        id: row.id,
        name: row.name,
      },
    });
  };

  return (
    <Styled.ScheduleStyled>
      <Styled.ButtonStyled theme="secondary" onClick={copyHandler}>
        <FontAwesomeIcon icon={faCopy} />
      </Styled.ButtonStyled>
      <Styled.ButtonStyled theme="secondary" onClick={editHandler} disabled={disableButton()}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </Styled.ButtonStyled>
      <Styled.ButtonStyled theme="secondary" onClick={deleteHandler} disabled={disableButton()}>
        <FontAwesomeIcon icon={faTrash} />
      </Styled.ButtonStyled>
    </Styled.ScheduleStyled>
  );
};
