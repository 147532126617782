export const INVITE_PUBLISHERS = {
  TABLE: {
    ID: 'ID',
    COMPANY_NAME: 'Company Name',
    AUDIENCE_MARKETS: 'Audience Markets',
    MONTHLY: 'Monthly Audience Size',
    PROMOTION_METHOD: 'Promotion Method',
    STATUS: 'Status',
  },
  DESC: {
    FIRST:
      'Here you can search and invite publishers in Fintel Connect network who are not yet a member of your program.',
    SECOND:
      'To start sending your invitation, please search for a publisher by entering their URL in the search field.',
  },
  PLACEHOLDER: {
    SEARCH_BY: 'Search by publisher URLs',
    SELECT_MEMBER: 'Select Member Type',
    PRIMARY_AUDIENCE: 'Primary Audience Market',
    PRODUCT_CATEGORY: 'Product Category',
    PROMOTION_METHOD: 'Promotion Method',
    AVERAGE_MONTHLY: 'Average Monthly Audience Size',
    ACTIVITY: 'Activity Status',
    AGE: 'Age',
    GENDER: 'Gender',
    CREDIT_SCORE: 'Credit Score Rating',
    AVERAGE_INCOME: 'Average Income',
  },
  SELECT: {
    MEMBER: [
      {
        label: 'Not A Member',
        value: 'false',
      },
      {
        label: 'Any Membership Status',
        value: 'true',
      },
    ],
    PRIMARY_AUDIENCE: [
      {
        label: 'Any Audience Markets',
        value: null,
      },
    ],
    PRODUCT_CATEGORY: [
      {
        label: 'Any Product Category',
        value: 'all',
      },
      {
        label: 'Banking',
        value: 'Banking',
      },
      {
        label: 'Business Products',
        value: 'Businesses',
      },
      {
        label: 'Credit Cards',
        value: 'Credit Cards',
      },
      {
        label: 'Credit Reporting',
        value: 'Credit Reporting',
      },
      {
        label: 'Cryptocurrency',
        value: 'Cryptocurrency',
      },
      {
        label: 'Insurance',
        value: 'Insurance',
      },
      {
        label: 'Investments',
        value: 'Investments',
      },
      {
        label: 'Loans',
        value: 'Loans',
      },
      {
        label: 'Mortgage',
        value: 'Mortgage',
      },
      {
        label: 'Real Estate',
        value: 'Real Estate',
      },
      {
        label: 'Tax Services',
        value: 'Tax Services',
      },
      {
        label: 'Wills & Estate Planning',
        value: 'Wills & Estate Planning',
      },
    ],
    PROMOTION_METHOD: [
      { label: 'Any Promotion Method', value: 'all' },
      { label: 'Long Form Content', value: 'Long Form Content' },
      { label: 'Deals/Coupons', value: 'Deals/Coupons' },
      { label: 'Tiktok', value: 'Tiktok' },
      { label: 'Forum', value: 'Forum' },
      { label: 'Cashback/Rewards', value: 'Cashback/Rewards' },
      { label: 'Facebook', value: 'Facebook' },
      { label: 'Product Reviews', value: 'Product Reviews' },
      { label: 'Newsletter', value: 'Newsletter' },
      { label: 'YouTube', value: 'YouTube' },
      { label: 'Comparison', value: 'Comparison' },
      { label: 'Instagram', value: 'Instagram' },
      { label: 'Social Media - Other', value: 'Social Media - Other' },
    ],
    AVERAGE_INCOME: [
      { label: 'Any Average Income', value: 'all' },
      { label: 'Less than $20,000', value: 'Less than $20,000' },
      { label: '$20,000 - $34,999', value: '$20,000 - $34,999' },
      { label: '$35,000 - $49,999', value: '$35,000 - $49,999' },
      { label: '$50,000 - $74,999', value: '$50,000 - $74,999' },
      { label: '$75,000 - $99,999', value: '$75,000 - $99,999' },
      { label: '$100,000 - $149,999', value: '$100,000 - $149,999' },
      { label: '$150,000 - $199,999', value: '$150,000 - $199,999' },
      { label: '$200,000 or more', value: '$200,000 or more' },
    ],
    CREDIT: [{ label: 'Any Credit Score Rating', value: 'Any Credit Score Rating' }],
    AUDIENCE: [
      { label: 'Any Audience Size', value: 'all' },
      { label: 'Under 5,000', value: 'Under 5,000' },
      { label: '5,000 - 25,000', value: '5,000 - 25,000' },
      { label: '25,000 - 100,000', value: '25,000 - 100,000' },
      { label: '100,000 - 250,000', value: '100,000 - 250,000' },
      { label: '250,000 - 500,000', value: '250,000 - 500,000' },
      { label: '500,000 - 1,000,000', value: '500,000 - 1,000,000' },
      { label: '1,000,000 +', value: '1,000,000 +' },
    ],
    ACTIVITY_STATUS: [
      { label: 'Any Activity Status', value: 'all' },
      { label: 'Click active', value: 'Click active' },
      { label: 'Approval active', value: 'Approval active' },
      { label: 'Not live', value: 'Not live' },
      { label: 'Not live (new to network)', value: 'New to network' },
    ],
    GENDER: [{ label: 'Any Gender', value: 'Any Gender' }],
    AGE: [{ label: 'Any Age', value: 'Any Age' }],
  },
  REPORT_PLACEHOLDER: 'Enter a domain to get started',
  SHOW_ADVANCED: 'Show Advanced Search',
  CLEAR_FORM: 'Clear Form',
  SEND_INVITE: 'Send Invite',
  ALL_VALUE: 'all',
  ALL_AUDIENCE_MARKEST: 'Any Audience Markets',
  ALL_GENDER: 'Any Gender',
  ALL_AGES: 'Any Age',
  ALL_CREDIT_SCORE_RATING: 'Any Credit Score Rating',
};
