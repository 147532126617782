import { gql } from '@apollo/client';

export const UPDATE_API = gql`
  mutation updateApiAccessKey($input: ApiAccessKeyInput!) {
    updateApiAccessKey(input: $input) {
      apiAccessKey
      status
      apiCreatedAt
    }
  }
`;
