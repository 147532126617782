import React from 'react';

import * as Styled from '../../styles';
import { InputText } from '../../../../../components';
import { DISCLAIMER, INPUT_LABEL } from '../../contracts';

type BasicStatsSectionProps = {
  sectionTitle: string;
  subSectionTitle: string;
  subSectionValue: string;
  sectionInputTitle: string;
  inputs: { [key: string]: number | string };
  handleChange: (value: React.ChangeEvent<HTMLInputElement>, field: string) => void;
  errors: { [key: string]: string };
  isLast?: boolean;
  isReadOnly?: boolean;
};

export const BasicStatsSection = ({
  sectionTitle,
  subSectionTitle,
  subSectionValue,
  sectionInputTitle,
  inputs,
  handleChange,
  isLast,
  errors,
  isReadOnly,
}: BasicStatsSectionProps) => (
  <Styled.SectionStyled theme={isLast ? 'secondary' : 'default'}>
    <Styled.SectionTitleStyled>{sectionTitle}</Styled.SectionTitleStyled>
    <Styled.SectionLabelStyled>{subSectionTitle}</Styled.SectionLabelStyled>
    <Styled.SectionContentStyled>{subSectionValue}</Styled.SectionContentStyled>

    <div>
      <Styled.SectionLabelWrapperStyled theme="secondary">
        <Styled.SectionLabelStyled>{sectionInputTitle}</Styled.SectionLabelStyled>
        <Styled.DisclaimerStyled>{DISCLAIMER}</Styled.DisclaimerStyled>
      </Styled.SectionLabelWrapperStyled>
      <Styled.InputContainerStyled theme="third" marginBottom="sm">
        {Object.keys(inputs).map((input) => (
          <Styled.InputWrapperStyled key={input}>
            <InputText
              type="text"
              label={INPUT_LABEL[input.toUpperCase()]}
              onChange={(value) => handleChange(value, input)}
              value={inputs[input]}
              error={errors[input]}
              disabled={isReadOnly}
            />
          </Styled.InputWrapperStyled>
        ))}
      </Styled.InputContainerStyled>
    </div>
  </Styled.SectionStyled>
);
