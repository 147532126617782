import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from '../../styles';
import { StatusCellProps } from '../../types';
import { PUBLISHER_PREFIX, path } from '../../../../../../../utils';

import { STATUS_CELL } from './enum';

export const StatusCell = ({ val, row }: StatusCellProps) => {
  const navigate = useNavigate();

  if (val === STATUS_CELL.APPLY) {
    return (
      <Styled.CellWrapper>
        <Styled.ButtonStyled
          theme="text-only"
          onClick={() => navigate(`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${row.programId}`)}
        >
          {val}
        </Styled.ButtonStyled>
      </Styled.CellWrapper>
    );
  }
  return <Styled.CellWrapper>{val}</Styled.CellWrapper>;
};
