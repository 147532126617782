import React from 'react';

import { CellWrapper } from '../../components';
import { dateFormatter } from '../../../../../../utils';

export const columns = (): TableColumn[] => [
  {
    dataField: 'reviewDate',
    text: 'Review Date',
    formatter: (cell: any) => <CellWrapper>{dateFormatter(new Date(cell))}</CellWrapper>,
    width: '15%',
  },
  {
    dataField: 'status',
    text: 'Review Status',
    formatter: (cell: any) => <CellWrapper>{cell || 'New Finding'}</CellWrapper>,
    width: '20%',
  },
  {
    dataField: 'reviewer',
    text: 'Reviewer',
    formatter: (cell: any) => <CellWrapper>{cell}</CellWrapper>,
    width: '20%',
  },
  {
    dataField: 'note',
    text: 'Note',
    formatter: (cell: any) => <CellWrapper>{cell}</CellWrapper>,
    width: '45%',
  },
];
