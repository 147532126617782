import styled from 'styled-components';

import { fonts } from '../../../../../styles/theme';

type FieldStyledProps = {
  columns?: string;
  flex?: string;
};

type FiltersButtonAreaStyledProps = {
  theme?: string;
};

export const WrapperStyled = styled.div`
  margin-top: 2rem;
`;

export const FiltersStyled = styled.div``;

export const FiltersRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const FieldStyled = styled.div<FieldStyledProps>`
  display: grid;
  align-items: ${({ flex }) => flex || 'flex-end'};
  grid-template-columns: ${({ columns }) => columns};
  grid-gap: 2%;
  row-gap: 2rem;
  margin: 2rem 0;
`;

export const FiltersButtonAreaStyled = styled.div<FiltersButtonAreaStyledProps>`
  height: 42px;
  display: flex;
  align-items: ${({ theme }) => (theme === 'generateLog' ? 'end' : 'center')};
`;

export const PlaceholderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;

export const RightSideStyled = styled.div`
  grid-column: span 1;
  justify-self: end;
`;
