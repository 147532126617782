import React from 'react';

import { dateFormatter } from '../../../../../../utils';

import { DefaultCell, DetailsCell, PageUrlCell, ScreenShotCell } from './Components';

export const camelCaseToSentenceCase = (str: string) =>
  str ? str.replace(/([A-Z])/g, ' $1').replace(/^./, (string) => string.toUpperCase()) : '';

export const columns: TableColumn[] = [
  {
    dataField: 'trackingEventDate',
    text: 'Check Date',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '180px',
    formatter: (val) => <DefaultCell val={dateFormatter(new Date(val), '/')} />,
  },
  {
    dataField: 'referralUrl',
    text: 'Page URL',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '300px',
    formatter: (val) => <PageUrlCell val={val} />,
  },

  {
    dataField: 'productCategory',
    text: 'Category',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '180px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'productName',
    text: 'Product',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '300px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'ruleName',
    text: 'Rule',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '180px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'ruleRequired',
    text: 'Required',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '120px',
    formatter: (val) => <DefaultCell val={val ? 'Yes' : 'No'} />,
  },
  {
    dataField: 'productFeed',
    text: 'Rule Requirement',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '180px',
    formatter: (val) => <DefaultCell val={camelCaseToSentenceCase(val.toString())} />,
  },
  {
    dataField: 'expectedMatch',
    text: 'Expected Match',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '300px',
    formatter: (val) => <DetailsCell val={val} />,
  },
  {
    dataField: 'resultFound',
    text: 'Result Found',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '180px',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'screenshotName',
    text: 'Screenshot',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '120px',
    formatter: (val) => <ScreenShotCell val={val} />,
  },
];
