import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { csvGenerator, MONTH_NAMES, path, PDF_TYPES, PRODUCT_CATEGORY } from '../../../../utils';
import { DEFAULT_TOTALS, MODAL_MERCHANT_DEFAULT, MODAL_PERIOD_ALL } from '../contracts';
import { getPeriodOptions } from '../../../../utils/getPeriodOptions';
import { ACCOUNT_BALANCE_LIST, MERCHANTS_LIST } from '../graphql/queries';
import { GET_ACCOUNT_BALANCE_CSV } from '../graphql/queries/accountBalancesCSV';

export const useAccountBalanceManagement = () => {
  const navigate = useNavigate();
  const [getCompanies] = useLazyQuery(MERCHANTS_LIST);
  const [getAccountBalanceList] = useLazyQuery(ACCOUNT_BALANCE_LIST);
  const [getAccountBalanceCSV] = useLazyQuery(GET_ACCOUNT_BALANCE_CSV);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'year', direction: 'asc' });

  const [modalOpen, setModalOpen] = useState(true);
  const [selectMerchantList, setSelectMerchantList] = useState<SelectOption[]>([]);
  const [selectedMerchant, setSeletedMerchant] = useState(MODAL_MERCHANT_DEFAULT);

  const [period, setPeriod] = useState<SelectOption[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<SelectOption>();

  const [accountBalances, setAccountBalances] = useState<AccountBalanceManagementTableData[]>([]);
  const [accountBalanceTotals, setAccountBalanceTotal] =
    useState<AccountBalanceManagementTableTotalData>(DEFAULT_TOTALS);

  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedProductCategory, setSelectedProductCategory] = useState(PRODUCT_CATEGORY[0]);

  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [errors, setErrors] = useState<string | undefined>();

  const [noLoading, setNoLoading] = useState(false);

  const formateStringArrayForOptions = (data: string[]) => data.map((info) => ({ label: info, value: info }));

  const formatterForOptions = (data: any[]) =>
    data.map((info) => ({ label: info.companyName, value: `${info.program.id},${info.id}` }));

  const getMerchants = async () => {
    if (selectedPeriod && selectMerchantList.length > 0) return;
    const periodData = formateStringArrayForOptions(getPeriodOptions());
    if (periodData) {
      setPeriod(periodData);
      setSelectedPeriod(periodData[0]);
    }

    const {
      data: {
        listSearchMerchants: { companies },
      },
    } = await getCompanies({
      variables: {
        input: {
          filter: {
            companyType: 'Merchant',
            accountStatusType: 'Approved',
          },
          options: {
            page: 1,
          },
        },
      },
    });

    if (companies) {
      const formatedList = formatterForOptions(companies);
      setSelectMerchantList([MODAL_MERCHANT_DEFAULT, ...formatedList]);
    }
  };

  const getAccountBalances = async (productCategory = '') => {
    setIsTableLoading(true);
    const formatPeriod = selectedPeriod?.value.split(' ');
    const input: {
      year?: string;
      month?: string;
      productCategory?: string;
    } = formatPeriod
      ? {
          year: formatPeriod[1],
          month: `${MONTH_NAMES.indexOf(formatPeriod[0]) + 1}`,
        }
      : {};

    if (productCategory && productCategory !== PRODUCT_CATEGORY[0].label) input.productCategory = productCategory;
    if (!productCategory && selectedProductCategory.label !== PRODUCT_CATEGORY[0].label)
      input.productCategory = selectedProductCategory.label;

    try {
      setErrors(undefined);

      const {
        data: { accountBalancesV2 },
        error,
      } = await getAccountBalanceList({
        variables: {
          input: {
            ...input,
            options: {
              page: currentPage,
              items: 20,
              order: sortColumn.direction?.toUpperCase(),
            },
            sortBy: sortColumn.column,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) {
        setErrors(error.message);
        setIsTableLoading(false);
        setIsLoading(false);
        return;
      }
      if (accountBalancesV2) {
        setAccountBalances(accountBalancesV2.accountBalances);
        setAccountBalanceTotal(accountBalancesV2.accountBalanceTotals || DEFAULT_TOTALS);
        setNumberOfPages(Math.ceil(accountBalancesV2.count / 20));
        setIsTableLoading(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      setErrors(err.message);
      setIsTableLoading(false);
      setIsLoading(false);
    }
  };

  const generateCSVHandler = async () => {
    try {
      const formatPeriod = selectedPeriod?.value.split(' ');

      const input: {
        year?: string;
        month?: string;
        productCategory?: string;
      } = formatPeriod
        ? {
            year: formatPeriod[1],
            month: `${MONTH_NAMES.indexOf(formatPeriod[0]) + 1}`,
          }
        : {};

      if (selectedProductCategory.label !== PRODUCT_CATEGORY[0].label)
        input.productCategory = selectedProductCategory.label;

      const {
        data: { accountBalanceCSV },
        error,
      } = await getAccountBalanceCSV({
        variables: {
          input: {
            ...input,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) setErrors(error.message);

      if (accountBalanceCSV?.csv) {
        csvGenerator(accountBalanceCSV.csv, 'account-balance-report');
      }
    } catch (err: any) {
      setErrors(err);
    }
  };

  const rowClickHandler = (row: any) => {
    navigate(path.merchantAccountBalanceManagementDetails.href, {
      state: {
        merchantId: row.merchantId,
        merchant: row.merchant,
        accountBalancesId: row.id,
        productCategory: row.productCategory,
        finalized: row.finalized,
        month: Number(row.month),
        year: Number(row.year),
      },
    });
  };

  const navigatePdfHandler = (row: any) => {
    navigate(path.invoiceStatement.href, {
      state: {
        merchantId: row.merchantId,
        accountBalancesId: row.id,
        productCategory: row.productCategory,
        pdfType: PDF_TYPES.MERCHANT,
        year: row?.year?.toString(),
        month: row?.month?.toString(),
      },
    });
  };

  const getAccountBalancesHandler = () => {
    if (selectedMerchant.value !== 'default') {
      const values = selectedMerchant.value.split(',');
      navigate(path.merchantAccountBalanceStats.href, {
        state: {
          programId: values[0],
          merchantId: values[1],
          merchantName: selectedMerchant.label,
        },
      });
    } else {
      getAccountBalances();
      setModalOpen(false);
    }
  };

  const setModalOpenHandler = (open: boolean) => {
    setModalOpen(open);
  };

  const setSeletedMerchantHandler = (value: SelectOption) => {
    setSeletedMerchant(value);

    if (value.value !== 'default') {
      setSelectedPeriod(MODAL_PERIOD_ALL);
    } else if (selectedPeriod?.value === 'all') {
      setSelectedPeriod(period[0]);
    }
  };

  const setSeletedPeriodHandler = (value: SelectOption) => {
    setSelectedPeriod(value);
  };

  const cancelModal = () => {
    setModalOpen(false);
    setNoLoading(true);
  };

  const setSelectedProductCategoryHandler = (value: SelectOption) => {
    setIsLoading(true);
    setSelectedProductCategory(value);
    getAccountBalances(value.label);
  };

  const setCurrentPageHandler = (value: number) => setCurrentPage(value);

  const setSortColumnHandler = (dataField: string, direction: 'asc' | 'desc' | undefined) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  useEffect(() => {
    getMerchants();
  }, []);

  useEffect(() => {
    if (isLoading) return;
    getAccountBalances();
  }, [currentPage, JSON.stringify(sortColumn)]);

  return {
    hookSetModalOpen: setModalOpenHandler,
    hookModalOpen: modalOpen,

    hookSelectMerchantList: selectMerchantList,
    hookSetSeletedMerchant: setSeletedMerchantHandler,
    hookSelectedMerchant: selectedMerchant,

    hookPeriod: period,
    hookSelectedPeriod: selectedPeriod,
    hookSetSelectedPeriod: setSeletedPeriodHandler,

    hookCancelModal: cancelModal,

    hookGetAccountBalances: getAccountBalancesHandler,
    hookAccountBalances: accountBalances,
    hookAccountBalanceTotals: accountBalanceTotals,

    hookSelectedProductCategory: selectedProductCategory,
    hookSetSelectedProductCategory: setSelectedProductCategoryHandler,

    hookNumberOfPages: numberOfPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,
    hookSortColumn: sortColumn,
    hookSetSortColumn: setSortColumnHandler,

    hookIsLoading: isLoading,
    hookIsTableLoading: isTableLoading,
    hookRowClick: rowClickHandler,

    hookGenerateCSV: generateCSVHandler,
    hookNavigatePdf: navigatePdfHandler,

    hookNoLoading: noLoading,

    hookErrors: errors,
  };
};
