import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../../../../../../../components';
import * as Styled from '../styles';
import { MARKETING_ITEMS } from '../../../enums';

type MarketingItemsProps = {
  marketingItems: any;
  setMarketingItems: any;
  addMarketingItem: any;
  handleXbutton: any;
  disabled: boolean;
};

export const MarketingItems = ({
  marketingItems,
  setMarketingItems,
  addMarketingItem,
  handleXbutton,
  disabled,
}: MarketingItemsProps) => (
  <Styled.InnerWrapperStyled>
    <Styled.WrapperTitleStyled>{MARKETING_ITEMS.TITLE}</Styled.WrapperTitleStyled>
    {marketingItems.length === 0 && (
      <Styled.MarketingItemWrapper key={0}>
        <Styled.MarketingItemTextArea
          disabled={disabled}
          label={`MARKETING ITEM # ${1}`}
          placeholder="Add description for this marketing item"
          value=""
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMarketingItems(event, 0)}
        />
      </Styled.MarketingItemWrapper>
    )}
    {marketingItems.map((item: string, index: number) => (
      <Styled.MarketingItemWrapper key={index}>
        <Styled.MarketingItemTextArea
          disabled={disabled}
          label={`MARKETING ITEM # ${index + 1}`}
          placeholder="Add description for this marketing item"
          value={item || ''}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMarketingItems(event, index)}
        />
        {index > 0 && (
          <Styled.XButton theme="secondary" onClick={() => handleXbutton(index)} disabled={disabled}>
            <FontAwesomeIcon icon={faX} />
          </Styled.XButton>
        )}
      </Styled.MarketingItemWrapper>
    ))}
    <Button onClick={addMarketingItem} theme="tertiary" disabled={disabled}>
      <FontAwesomeIcon icon={faPlus} />
      <Styled.ButtonTextWrapperStyled>{MARKETING_ITEMS.ADD_LABEL}</Styled.ButtonTextWrapperStyled>
    </Button>
  </Styled.InnerWrapperStyled>
);
