import React from 'react';

import { FormatedCell, NetworkStatusMerchantManagement, SelectMerchant } from '../components';
import { ManagementThumbnails } from '../../../../components';
import { NEW_MERCHANT_STATUS_OPTIONS, NEW_MERCHANT_TYPES_OPTIONS } from '../utils';

export const tableColumn: TableColumn[] = [
  {
    dataField: 'companyImgUrl',
    text: '',
    formatter: (value: any) => <ManagementThumbnails url={value} />,
    width: '7%',
  },
  {
    dataField: 'id',
    text: 'ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <FormatedCell value={value} />,
    width: '8%',
  },
  {
    dataField: 'companyName',
    text: 'Company Name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any, row: any) => <FormatedCell value={value} secondValue={row.companyUrl} />,
    width: '32%',
  },
  {
    dataField: 'merchantType',
    text: 'Type',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <FormatedCell value={NEW_MERCHANT_TYPES_OPTIONS.find((item) => item.value === value)?.label} />
    ),
    width: '10%',
  },
  {
    dataField: 'country',
    text: 'Country',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <FormatedCell value={value} />,
    width: '12%',
  },
  {
    dataField: 'createdAt',
    text: 'Registration',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <FormatedCell
        value={new Date(value).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
      />
    ),
    width: '160px',
  },
  {
    dataField: 'accountStatus',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <FormatedCell value={NEW_MERCHANT_STATUS_OPTIONS.find((item) => item.value === value)?.label} />
    ),
    width: '11%',
  },
  {
    dataField: 'networkStatus',
    text: '',
    formatter: (value: any) => <NetworkStatusMerchantManagement value={value !== 'Invisible'} />,
    width: '4%',
  },
  {
    dataField: 'id',
    text: '',
    formatter: (value: any) => <SelectMerchant value={value} />,
    width: '6%',
  },
];
