import styled from 'styled-components';

import { Button, InputText, InputTextarea, PageTitle } from '../../../../components';

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const InputTextStyled = styled(InputText)`
  margin-bottom: 2rem;
`;

export const InputTextareaStyled = styled(InputTextarea)`
  margin-bottom: 3rem;
`;

export const PageTitleStyled = styled(PageTitle)`
  margin-bottom: 3rem;
`;
