type ReturnColorBasedOnValueArgs = {
  value: number;
  negativeColor: string;
  neutralColor: string;
  positiveColor: string;
};

export const returnColorBaseOnValue = ({
  value,
  negativeColor,
  neutralColor,
  positiveColor,
}: ReturnColorBasedOnValueArgs) => {
  if (value < 0) {
    return negativeColor;
  }

  if (value > 0) {
    return positiveColor;
  }

  return neutralColor;
};
