import { Font, StyleSheet } from '@react-pdf/renderer';

import { fontsPDF, colors } from '../../../styles/theme';

Font.register({
  family: 'Gotham',
  src: fontsPDF.gotham.bold,
});
Font.register({
  family: 'Lato',
  src: fontsPDF.lato.bold,
});
Font.register({
  family: 'Lato-regular',
  src: fontsPDF.lato.regular,
});
Font.register({
  family: 'Lato-italic',
  src: fontsPDF.lato.italic,
});

export const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: colors.color4,
    padding: 50,
  },
  invoiceSection: {
    width: 270,
    height: 89,
    backgroundColor: colors.color1,
    fontFamily: 'Gotham',
    fontSize: 38,
    color: 'white',
    paddingTop: 26,
    paddingLeft: 50,
    marginLeft: -50,
    textTransform: 'uppercase',
  },
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowSpaceBetweenHalf: {
    display: 'flex',
    flexDirection: 'row',
    width: 220,
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  rowSpaceBetween50: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'space-between',
  },
  row50Start: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    justifyContent: 'flex-start',
    marginTop: 3,
  },
  rowStart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 3,
  },
  rowEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rowSpace: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  columnEnd: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  boldText: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: colors.color1,
  },
  text: {
    fontFamily: 'Lato-regular',
    fontSize: 10,
    color: colors.color1,
  },
  textSmallItalic: {
    fontFamily: 'Lato-italic',
    fontSize: 8,
    color: colors.color1,
    marginBottom: 2,
  },
  textSmallBold: {
    fontFamily: 'Lato',
    fontSize: 8,
    color: colors.color1,
    marginBottom: 2,
  },
  textSmall: {
    fontFamily: 'Lato-regular',
    fontSize: 8,
    color: colors.color1,
    marginBottom: 2,
  },
  textInTable: {
    fontFamily: 'Lato-regular',
    fontSize: 10,
    color: colors.color1,
    marginBottom: 5,
    marginTop: 5,
  },
  label: {
    fontFamily: 'Gotham',
    fontSize: 9,
    color: colors.color13,
    marginTop: 3,
  },
  border: {
    borderTopColor: colors.color19,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
  borderBottom: {
    borderBottomColor: colors.color19,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    paddingBottom: 5,
  },
  borderHeavy: {
    borderTopColor: colors.color33,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
  borderAround: {
    paddingTop: 5,
    borderTopColor: colors.color33,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderBottomColor: colors.color33,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  borderFinal: {
    paddingTop: 5,
    paddingBottom: 5,
    borderTopColor: colors.color19,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderBottomColor: colors.color19,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  totalDue: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.color32,
    marginTop: 2,
  },
  rowSpaceBetween60: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'space-between',
  },
});
