/* eslint-disable no-case-declarations */
type Programs = {
  programId: number;
  merchantId: number;
  apply: boolean;
  subscription?: boolean;
};

type CheckBoxOption = {
  label: string;
  value: string;
  checked: boolean;
};

export type SignUpProps = {
  companyName: string;
  companyUrl: string;
  companyPhone: string;
  companyAddress1: string;
  companyAddress2: string;
  companyZIP: string;
  companyCountry: SelectOption;
  companyState: SelectOption;
  companyCity: string;

  // user info
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPassword: string;
  preferredLanguage: SelectOption;
  subscribeUpdates: false;
  subscribeOffers: false;
  agreesTC: boolean;

  // payment info
  paymentCurrency: SelectOption;
  paymentMethod: SelectOption;
  paymentPayable: string;
  paymentEmail: string;
  paymentPhone: string;
  paymentAddress1: string;
  paymentAddress2: string;
  paymentCity: string;
  paymentZip: string;
  paymentCountry: SelectOption;
  paymentState: SelectOption;
  paymentGST: string;
  paymentPST: string;
  paymentHST: string;
  paymentTAX: string;
  paymentRepeatInfo: boolean;

  // Wire Transfer Payment Method
  bankName: string;
  bankAccountNumber: string;
  swiftCode: string;
  transit: string;
  wireTransferName: string;

  // Paypal Payment Method
  accountHolder: string;
  accountEmailAddress: string;

  paymentGstStatus: string;
  paymentPstStatus: string;
  paymentHstStatus: string;
  paymentTaxStatus: string;

  // overview
  businessDescription: string;
  profilePhotoURL: string;
  hasWebsite: boolean;
  audienceMarket: SelectOption;
  averageMonthlyAudience: SelectOption;
  productCategories: CheckBoxOption[];
  promotionMethods: CheckBoxOption[];
  audienceAges: SelectOption;
  gender: SelectOption;
  creditScoreRating: SelectOption;
  averageIncome: SelectOption;
  programs: Programs[];
};

export const initialState: SignUpProps = {
  companyName: '',
  companyUrl: '',
  companyPhone: '',
  companyAddress1: '',
  companyAddress2: '',
  companyZIP: '',
  companyCountry: { label: '', value: '' },
  companyState: { label: '', value: '' },
  companyCity: '',

  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPassword: '',
  preferredLanguage: { label: 'English', value: 'English' },
  subscribeUpdates: false,
  subscribeOffers: false,
  agreesTC: false,

  paymentCurrency: { label: 'Canadian Dollars (CAD $)', value: 'CAD' },
  paymentMethod: { label: 'Cheque', value: 'Cheque' },
  paymentPayable: '',
  paymentEmail: '',
  paymentPhone: '',
  paymentAddress1: '',
  paymentAddress2: '',
  paymentCity: '',
  paymentZip: '',
  paymentCountry: { label: '', value: '' },
  paymentState: { label: '', value: '' },
  paymentGST: '',
  paymentPST: '',
  paymentHST: '',
  paymentTAX: '',
  paymentRepeatInfo: false,

  bankName: '',
  bankAccountNumber: '',
  swiftCode: '',
  transit: '',
  wireTransferName: '',

  accountHolder: '',
  accountEmailAddress: '',

  paymentGstStatus: 'Unverified',
  paymentPstStatus: 'Unverified',
  paymentHstStatus: 'Unverified',
  paymentTaxStatus: 'Unverified',

  businessDescription: '',
  profilePhotoURL: '',
  hasWebsite: true,
  audienceMarket: { label: '', value: '' },
  averageMonthlyAudience: { label: '', value: '' },
  productCategories: [],
  promotionMethods: [],
  audienceAges: { label: '', value: '' },
  gender: { label: '', value: '' },
  creditScoreRating: { label: '', value: '' },
  averageIncome: { label: '', value: '' },
  programs: [],
};

export const SIGN_UP_ACTIONS = {
  UPDATE_NEW_PUBLISHER: Symbol('update new publisher'),
  SET_PRODUCT_CATEGORIES: Symbol('create product categories array'),
  SET_PROMOTION_METHOD: Symbol('create promotion methods array'),
  SET_PROGRAM_APPLICATIONS: Symbol('set program applications'),
  SET_PROGRAM_SUBSCRIPTION: Symbol('set program subscriptions'),
  CLEAR_PROGRAM_APPLICAIONS: Symbol('clear program applications'),
};

export const useSignUpReducer = (state: any, { type, data }: BaseReducerType): SignUpProps => {
  switch (type) {
    case SIGN_UP_ACTIONS.UPDATE_NEW_PUBLISHER:
      return {
        ...state,
        ...data,
      };
    case SIGN_UP_ACTIONS.SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: data,
      };
    case SIGN_UP_ACTIONS.SET_PROMOTION_METHOD:
      return {
        ...state,
        promotionMethods: data,
      };
    case SIGN_UP_ACTIONS.SET_PROGRAM_APPLICATIONS:
      const programsCopy = [...(state.programs as any[])];
      if (programsCopy?.some((item: any) => item.programId === data.programId)) {
        const index = programsCopy.findIndex((item: any) => item.programId === data.programId);
        if (index !== -1) {
          programsCopy.splice(index, 1);
        }
      }
      if (data.apply === true) {
        programsCopy.push(data);
      }

      return {
        ...state,
        programs: programsCopy,
      };
    case SIGN_UP_ACTIONS.SET_PROGRAM_SUBSCRIPTION:
      const updatedPrograms = state.programs.map((item: any) => {
        if (item.programId === data.programId) {
          return {
            ...item,
            ...data,
          };
        }
        return {
          ...item,
        };
      });

      return {
        ...state,
        programs: updatedPrograms,
      };
    case SIGN_UP_ACTIONS.CLEAR_PROGRAM_APPLICAIONS:
      return {
        ...state,
        programs: [],
      };
    default:
      return {
        ...state,
      };
  }
};
