import styled from 'styled-components';

import { toRem } from '../../../../../../utils';
import { InputText, Select } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';

type CellWrapperStyledProps = {
  readonly theme?: string;
};

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 2rem;
  margin-top: ${toRem(40)};
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
`;

export const InputTextStyled = styled(InputText)`
  margin: 0 1rem;
  flex: 5 1 0;
`;

export const SelectStyled = styled(Select)`
  margin: 0 1rem;
  flex: 1 1 0;
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const DatesStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CalendarsStyled = styled.div`
  display: flex;
  gap: 2rem;
`;

export const DateHeaderStyled = styled.h2`
  ${fonts.lato.H12}
  color: ${colors.color1};
`;

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  height: 100%;
  min-height: ${({ theme }) => (theme === 'checkBox' ? 'auto' : toRem(38))};
  display: flex;
  align-items: center;
  justify-content: ${({ theme }) => (theme === 'checkBox' ? 'center' : 'space-between')};
  flex-direction: ${({ theme }) => (theme === 'checkBox' ? 'row' : 'column')};
`;

export const FooterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

export const ButtonContStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const WarningStyled = styled.p`
  color: ${colors.color31};
`;

export const DateCellStyled = styled.div`
  display: flex;
  color: ${colors.color17};
  ${fonts.lato.H16};
  line-height: 20px;
  min-height: 40px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  white-space: normal;
`;

export const TextSpanStyled = styled.span`
  padding-left: 16px;
  ${fonts.lato.H14}
  color: ${colors.color1}
`;
