import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { FormatedCell } from '../components';
import { dateFormatter, timeFormatter } from '../../../../../../utils';
import { Button } from '../../../../../../components';
import { colors } from '../../../../../../styles/theme';
import { BulkUploadOutputType } from '../types';

const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 1.3rem;
  width: 30px;
  color: ${colors.color1};
`;

const CellStyled = styled.div`
  height: 20px;
  padding: 0.4rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  overflow: hidden;
`;

type ActionProps = {
  row: any;
  gotoDetailsHandler?: (id: string, _: any) => void;
};

const ViewBulkImportLog = ({ row, gotoDetailsHandler }: ActionProps) => {
  const goTo = () => {
    if (gotoDetailsHandler) gotoDetailsHandler(row.id, null);
  };

  return (
    <CellStyled>
      <ButtonStyled theme="text-only" onClick={goTo}>
        <FontAwesomeIcon icon={faChevronRight} />
      </ButtonStyled>
    </CellStyled>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'merchantsId',
    text: 'Merchant',
    formatter: (_, row: BulkUploadOutputType) => (
      <FormatedCell
        value={`
          ${row.merchant.id} - ${row.merchant.companyName}
        `}
      />
    ),
    width: '14%',
  },
  {
    dataField: 'userName',
    text: 'Imported By',
    formatter: (value, row: BulkUploadOutputType) => <FormatedCell value={value || row.uploadType} />,
    width: '10%',
  },
  {
    dataField: 'uploadType',
    text: 'Upload Type',
    formatter: (value) => <FormatedCell capitalize value={value} />,
    width: '8%',
  },
  {
    dataField: 'fileName',
    text: 'File Name',
    formatter: (_, row: BulkUploadOutputType) => (
      <FormatedCell
        value=""
        secondValue={row.originalFilename}
        link={
          row.uploadType === 'FTP'
            ? `${process.env.REACT_APP_BULK_UPLOAD_BUCKET_FTP}/${row.bucketFilename}`
            : `${process.env.REACT_APP_BULK_UPLOAD_BUCKET_MANUAL}/${row.bucketFilename}`
        }
      />
    ),
    width: '15%',
  },
  {
    dataField: 'status',
    text: 'Queue Status',
    formatter: (value: any) => <FormatedCell value={value} />,
    width: '10%',
  },
  {
    dataField: 'totalTransactions',
    text: 'Total Transactions',
    formatter: (value: any) => <FormatedCell value={value || 0} />,
    width: '10%',
  },
  {
    dataField: 'errors',
    text: 'Errors',
    formatter: (_, row: BulkUploadOutputType) => <FormatedCell value={row.uploadErrors} />,
    width: '6%',
  },
  {
    dataField: 'createdAt',
    text: 'Imported',
    formatter: (value: any) => {
      const date = new Date(value);
      const day = dateFormatter(date);
      return <FormatedCell secondValue={day} value={timeFormatter(date)} />;
    },
    width: '10%',
  },
  {
    dataField: 'updatedAt',
    text: 'Completed',
    formatter: (value: any, row: BulkUploadOutputType) => {
      if (row.status === 'Uploaded') return <div />;
      const date = new Date(value);
      const day = dateFormatter(date);

      return <FormatedCell secondValue={day} value={timeFormatter(date)} />;
    },
    width: '12%',
  },
  {
    text: '',
    dataField: '__',
    width: '4%',
    formatter: (_: any, row: BulkUploadOutputType, __: any, ___: any, goToFunction) => {
      if (row.status === 'Uploaded') return <div />;
      return <FormatedCell value={<ViewBulkImportLog row={row} gotoDetailsHandler={goToFunction} />} />;
    },
  },
];
