import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppWrapper, InfoBox, Modal, PageTitle } from '../../../components';
import { path } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import { BUTTON_LABEL, INFO_BOX, INFO_LABEL, SECTION_TITLES } from './contracts';
import { useAccountBalanceManagementNewStats } from './hook';
import { ActiveProductCategoryStats, BasicStatsOrignalStats, BasicStatsSection, SystemUsageRate } from './components';
import { ProductCategoryModal } from './Modals';
import * as Styled from './styles';

type AccountBalanceManagementNewStatsProps = DefaultPropsType;

const AccountBalanceManagementNewStats = ({ permissionsCodeList = [] }: AccountBalanceManagementNewStatsProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = useAccountBalanceManagementNewStats(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {hook.hookHasCategoryError && (
        <InfoBox
          title={INFO_BOX.CATEGORY_ERROR.TITLE}
          icon={INFO_BOX.CATEGORY_ERROR.ICON}
          description={INFO_BOX.CATEGORY_ERROR.DESCRIPTION}
          theme="ERROR"
        />
      )}
      {hook.hookErrors.message && (
        <InfoBox title="" icon={INFO_BOX.CATEGORY_ERROR.ICON} description={hook.hookErrors.message} theme="ERROR" />
      )}
      <Styled.HeaderStyled>
        <PageTitle loadingText="Loading">{path.merchantAccountBalanceManagementNewStats.name}</PageTitle>
        <Styled.ButtonContainerStyled>
          <Styled.ButtonStyled theme="secondary" onClick={() => navigate(-1)}>
            {BUTTON_LABEL.CANCEL}
          </Styled.ButtonStyled>
          <Styled.ButtonStyled onClick={hook.hookUpdateStats} margin="right" disabled={isReadOnly}>
            {BUTTON_LABEL.SAVE}
          </Styled.ButtonStyled>
        </Styled.ButtonContainerStyled>
      </Styled.HeaderStyled>
      <InfoBox
        title={INFO_BOX.NEW_STATS.TITLE}
        icon={INFO_BOX.NEW_STATS.ICON}
        description={INFO_BOX.NEW_STATS.DESCRIPTION}
      />

      {hook.hookEnableNewStats && (
        <ActiveProductCategoryStats
          activeCategories={hook.hookActiveCategories}
          newActiveCategories={hook.hookNewActiveCategories}
          setModalOpen={hook.hookSetModalOpen}
          isReadOnly={isReadOnly}
        />
      )}

      <SystemUsageRate
        currentSystemRate={hook.hookCurrentSystemRate}
        rateType={hook.hookRateType}
        setRateType={hook.hookSetRateType}
        tierRateLevels={hook.hookTierRateLevels}
        setRateLevels={hook.hookSetRateLevels}
        flatRateLevel={hook.hookFlatRateLevel}
        setFlatRateLevels={hook.hookSetFlatRateLevels}
        addNewTier={hook.hookAddNewTier}
        removeNewTier={hook.hookRemoveNewTier}
        errors={hook.hookRateErrors}
        isReadOnly={isReadOnly}
      />

      {hook.hookEnableNewStats && (
        <>
          <BasicStatsSection
            sectionTitle={SECTION_TITLES.MINIMUM_FEE}
            subSectionTitle={INFO_LABEL.FEE}
            subSectionValue={hook.hookCurrentMinUsageFee}
            sectionInputTitle={INFO_LABEL.NEW_FEE}
            inputs={hook.hookMinUsageFee}
            handleChange={hook.hookSetMinUsageFee}
            errors={hook.hookErrors}
            isReadOnly={isReadOnly}
          />

          <BasicStatsSection
            sectionTitle={SECTION_TITLES.MANAGEMENT_FEE}
            subSectionTitle={INFO_LABEL.FEE}
            subSectionValue={hook.hookManagementCurrentFee}
            sectionInputTitle={INFO_LABEL.NEW_FEES}
            inputs={hook.hookNewManagementFee}
            handleChange={hook.hookSetNewManagementFee}
            errors={hook.hookErrors}
            isReadOnly={isReadOnly}
          />

          <BasicStatsSection
            sectionTitle={SECTION_TITLES.BALANCE_LEVELS}
            subSectionTitle={INFO_LABEL.BALANCE}
            subSectionValue={hook.hookCurrentMinimumBalance}
            sectionInputTitle={INFO_LABEL.NEW_BALANCE}
            inputs={hook.hookMinimumBalance}
            handleChange={hook.hookSetMinimumBalance}
            errors={hook.hookErrors}
            isReadOnly={isReadOnly}
            isLast
          />
        </>
      )}

      {!hook.hookEnableNewStats && (
        <>
          <BasicStatsOrignalStats
            title={SECTION_TITLES.MINIMUM_FEE}
            currentLabel={INFO_LABEL.FEE}
            newLabel={INFO_LABEL.NEW_FEE_DOLLAR}
            currentValue={hook.hookCurrentMinUsageFee}
            newValue={hook.hookMinUsageFee.minUsageFee}
            updateNewValue={hook.hookSetMinUsageFee}
            error={hook.hookErrors.minUsageFee}
            isReadOnly={isReadOnly}
          />

          <BasicStatsOrignalStats
            title={SECTION_TITLES.MANAGEMENT_FEE}
            currentLabel={INFO_LABEL.FEE}
            newLabel={INFO_LABEL.NEW_FEE_DOLLAR}
            currentValue={hook.hookManagementCurrentFee}
            newValue={hook.hookNewManagementFeeBasic}
            updateNewValue={hook.hookSetNewManagementFee}
            error={hook.hookErrors.managementFee}
            isReadOnly={isReadOnly}
          />

          <BasicStatsOrignalStats
            title={SECTION_TITLES.BALANCE_LEVELS}
            currentLabel={INFO_LABEL.BALANCE}
            newLabel={INFO_LABEL.NEW_BALANCE_DOLLAR}
            currentValue={hook.hookCurrentMinimumBalance}
            newValue={hook.hookMinimumBalance.minimumBalance}
            updateNewValue={hook.hookSetMinimumBalance}
            error={hook.hookErrors.minimumBalance}
            isReadOnly={isReadOnly}
          />
        </>
      )}

      <Modal isOpen={hook.hookModalOpen}>
        <ProductCategoryModal
          cancelHandler={() => hook.hookSetModalOpen(false)}
          saveHandler={hook.hookUpdatedNewActiveCategories}
          currentProductCategory={hook.hookNewActiveCategories}
        />
      </Modal>
    </AppWrapper>
  );
};

export default AccountBalanceManagementNewStats;
