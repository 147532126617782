import styled from 'styled-components';

import { fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

export const WrapperStyled = styled.div`
  width: 100%;
`;

export const HeaderStyled = styled.div`
  width: 100%;
`;

export const HeaderDescriptionWrapperStyled = styled.div`
  margin: ${toRem(20)} 0 ${toRem(30)};
`;

export const HeaderDescriptionStyled = styled.div`
  ${fonts.lato.H16}
  width: 45%;
  line-height: 1.5;
`;
