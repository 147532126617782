import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { InputText, Button, ErrorBanner } from '../../components';
import { imageList, path } from '../../utils';

import * as Styled from './styles';
import { useForgotPassword } from './hooks';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const hook = useForgotPassword();

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img src={imageList.logoFull.src} alt="logo" />
        </figure>
      </Styled.LeftStyled>
      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />

          <h1>Forgot your password?</h1>

          <span>Enter the email associated with your account. We will send you a link to reset your password.</span>

          <Styled.FormStyled>
            <InputText
              placeholder="Email Address"
              value={hook.hookEmailValue}
              onChange={hook.hookSetEmailValue}
              required
              type="email"
              name="email"
              label="Email Address"
              error={hook.hookEmailInputError}
            />

            <Styled.RowStyled>
              <Styled.ButtonStyled theme="secondary" type="button" onClick={() => navigate(-1)}>
                Back to Login
              </Styled.ButtonStyled>
              <Styled.ButtonStyled onClick={() => hook.hookOnSubmitHandler()} type="submit">
                Request reset link
              </Styled.ButtonStyled>
            </Styled.RowStyled>
          </Styled.FormStyled>
        </Styled.RightContentStyled>
      </Styled.RightStyled>

      <Styled.ModalStyled isOpen={hook.hookModalOpen}>
        <Styled.IconStyled icon={faEnvelopeOpen} />
        <h1>Check your email</h1>
        <span>We have sent a reset password link to your email.</span>

        <Button onClick={() => navigate(path.login.href)}>Login</Button>

        <Styled.ConfirmEmailStyled>Didn&apos;t receive the link?</Styled.ConfirmEmailStyled>
        <Button theme="text-only" onClick={() => hook.hookSetModalOpen(false)}>
          Confirm you email address
        </Button>
      </Styled.ModalStyled>
    </Styled.WrapperStyled>
  );
};

export default ForgotPassword;
