import styled from 'styled-components';

import { PageTitle } from '../../../components';
import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';

type OptionPageWrapperProps = {
  readonly isOpen: boolean;
};

export const WrapperStyled = styled.div`
  width: 100%;
`;

export const TitleStyled = styled(PageTitle)``;

export const RolesStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${toRem(24)} 0;
  ${fonts.gotham.H16M}
`;

export const NewRoleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;

export const RoleTypeStyled = styled.div`
  ${fonts.lato.H14}
`;

export const RoleTypeTitleStyled = styled.div`
  ${fonts.gotham.H12M}
  color: ${colors.color16};
  line-height: ${toRem(16)};
  text-transform: uppercase;
`;

export const RoleTypeListStyled = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${toRem(4)};
  gap: ${toRem(216)};
`;

export const RadioBoxStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const RadioBoxLabelStyled = styled.div`
  ${fonts.lato.H14}
  margin-left: ${toRem(8)};
  color: ${colors.color1};
`;

export const RoleNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${toRem(32)} 0;
  max-width: 376px;
  ${fonts.lato.H16}
  color: ${colors.color16};

  span:first-of-type {
    ${fonts.gotham.H12M}
  }
`;

export const UpdatePermissionsStyled = styled.div`
  ${fonts.lato.H16}
  width: 100%;
  padding-top: ${toRem(32)};
  border-top: 1px solid ${colors.color15};
`;

export const SettingsOptionsStyled = styled.div`
  border: 1px solid ${colors.color15};
  border-bottom: 2px solid ${colors.color15};
  margin-top: ${toRem(24)};
  border-radius: 4px;
`;

export const OptionTitleWrapper = styled.div`
  padding: ${toRem(12)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionTitleStyled = styled.div`
  ${fonts.lato.H14}
  display: flex;

  svg {
    margin-right: 8px;
  }

  button {
    ${fonts.lato.H14B}
    color: ${colors.color1};
  }
`;

export const OptionCRUDStyled = styled.div`
  ${fonts.lato.H12}
  display: flex;
  justify-content: space-around;
  width: 640px;

  label {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    width: 60px;
  }
`;

export const OptionPageWrapper = styled.div<OptionPageWrapperProps>`
  display: ${({ isOpen }) => {
    switch (isOpen) {
      case true:
        return 'flex';
      default:
        return 'none';
    }
  }};
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid ${colors.color15};

  > div:last-child {
    border-bottom: none;
  }
`;

export const PermissionListItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${toRem(12)} ${toRem(12)} ${toRem(12)} ${toRem(24)};
  border-bottom: 1px solid ${colors.color15};
`;

export const OptionPageStyled = styled.div`
  ${fonts.lato.H12B}
  line-height: ${toRem(18)};
`;

export const OptionPageCRUDStyled = styled.div`
  display: flex;
  justify-content: space-around;
  width: 640px;
`;

export const CreateRoleStyled = styled.div`
  width: 500px;
  padding: ${toRem(8)};
`;

export const CreateRoleTypeStyled = styled.div`
  ${fonts.gotham.H12}
  margin: ${toRem(24)} 0;
`;

export const CreateRoleTitleStyled = styled.div`
  ${fonts.lato.H16}
`;

export const CreateRoleButtonsStyled = styled.div`
  display: flex;
  justify-content: end;
  padding-top: ${toRem(24)};
  button {
    margin-left: ${toRem(8)};
  }
`;

export const RadioBoxPermissionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${toRem(75)};

  span {
    margin: 0;
  }
`;

export const RadioBoxPermissionLabelStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${toRem(8)};
  ${fonts.lato.H12B}
  color: ${colors.color1};
`;

export const RadioBoxPemissionStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioBoxPemissionLabelStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  ${fonts.lato.H16}
  color: ${colors.color1};
`;
