import { gql } from '@apollo/client';

export const LIST_ACTIVE_MERCHANT_COMMISSIONS = gql`
  query ListActiveCommissions($input: NewCommissionsInput!) {
    getCommissionsNew(input: $input) {
      count
      totalPages
      currentPage
      commissions {
        id
        endDate
        startDate
        description
        commissionStructure {
          bonusFlat {
            commissionBase
            minimumSaleAmount
            commissionFee
          }
          cpaFlat {
            minimumSaleAmount
            commissionFee
            commissionBase
          }
          cpaTiered {
            commissionBase
            tiers {
              upTo
              commissionAmount
            }
            cutoffAmount
            minimumSaleAmount
          }
          referral {
            levels
          }
          revShareFlat {
            commissionFee
            commissionBase
          }
          revShareTiered {
            cutoffAmount
            commissionBase
            tiers {
              upTo
              commissionAmount
            }
          }
          bonusTiered {
            minimumSaleAmount
            commissionBase
            period
            calculationType
            cutoffAmount
            tiers {
              upTo
              commissionAmount
            }
          }
        }
        commissionType
        commissionName
        commissionTarget {
          publisherGroupTargets {
            endDate
            publisherGroupId
            startDate
          }
          publisherTargets {
            endDate
            publisherId
            startDate
          }
        }
        products {
          id
          name
          productCategory
        }
        merchant {
          id
          companyName
        }
        publisherGroups {
          id
          name
        }
        publishers {
          id
          companyName
        }
      }
    }
  }
`;

enum NewComissionsSortby {
  startDate,
  createdAt,
  id,
  commissionType,
  commissionName,
}

export type ListActiveMerchantCommissionsInput = {
  input: {
    filters: {
      merchantId: string;
      commissionType: string | undefined;
    };
    targetDate: Date;
    limit: number;
    currentPage: number;
    productId: string | undefined;
    productCategory: string | undefined;
    publisherGroupId: string | undefined;
    publisherId: string | undefined;
    sortBy: string | NewComissionsSortby;
    sortDirection: 'asc' | 'desc' | undefined;
  };
};

export type LAMCCommissionStructureType = {
  cpaFlat: {
    minimumSaleAmount: string | null;
    commissionFee: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
  };
  cpaTiered: {
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
    tiers: { upTo: string | null; commissionAmount: string | null }[];
    cutoffAmount: string | null;
    minimumSaleAmount: string | null;
  };
  referral: { levels: string[] };
  revShareFlat: {
    commissionFee: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
  };
  revShareTiered: {
    tiers: { upTo: string; commissionAmount: string }[];
    cutoffAmount: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
  };
  bonusFlat: {
    minimumSaleAmount: string | null;
    commissionFee: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
  };
  bonusTiered: {
    minimumSaleAmount: string | null;
    commissionBase: 'GrossSales' | 'NetSales' | 'SalesNumber' | 'CPA' | null;
    period: string | null;
    calculationType: 'Stepped' | 'Lookup' | null;
    tiers: { upTo: string; commissionAmount: string }[];
    cutoffAmount: string;
  };
};

export type LAMCCommissionTargetType = {
  publisherGroupTargets: { endDate: string | null; publisherGroupId: string; startDate: string | null }[];
  publisherTargets: { endDate: string | null; publisherId: string; startDate: string | null }[];
};

export type LAMCCommissionsType = {
  id: string;
  endDate: string | null;
  startDate: string | null;
  commissionName: string | null;
  description: string | null;
  commissionType: string | null;
  commissionStructure: LAMCCommissionStructureType;
  commissionTarget: LAMCCommissionTargetType;
  products: { id: string; name: string | null; productCategory: string | null }[];
  productCategory: string | null;
  merchant: { id: string };
  publisherGroups: { id: string; name: string }[];
  publishers: { id: string; companyName: string }[];
};

export type ListActiveMerchantCommissionsOutput = {
  getCommissionsNew: {
    count: number | null;
    totalPages: number | null;
    currentPage: number | null;
    commissions: LAMCCommissionsType[];
  };
};
