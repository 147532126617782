import { IconDefinition, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export type FintelCheckActionsProps = {
  show: boolean;
  icon: IconDefinition;
};

export const FINTEL_CHECK_ACTIONS = {
  SHOW_CHECKS: Symbol('show checks'),
  HIDE_CHECKS: Symbol('hide checks'),
};

export const fintelCheckReducer = (state: any, { type }: Pick<BaseReducerType, 'type'>) => {
  switch (type) {
    case FINTEL_CHECK_ACTIONS.SHOW_CHECKS:
      return {
        ...state,
        show: true,
        icon: faChevronDown,
      };
    case FINTEL_CHECK_ACTIONS.HIDE_CHECKS:
      return {
        ...state,
        show: false,
        icon: faChevronRight,
      };
    default:
      return { ...state };
  }
};
