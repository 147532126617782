import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { MERCHANT_PREFIX, csvGenerator, path } from '../../../../../utils';
import { GET_MEMBERSHIP } from '../graphql/queries';
import { useModal, useUserInfo } from '../../../../../hooks';
import { INVITATION_HISTORY_CSV_COLUMNS } from '../enum';
import { processDataToCSVString } from '../../../FintelCheck/FintelCheckChangeHistory/utils/processDataForCSV';

type ModalInfoProps = {
  publisherId: string;
  publisherName: string;
  subject: string;
  message: string;
};

const formatMembershipData = (invitationsData: any[]) => {
  const formatedData = invitationsData.map((element: any) => {
    const formatStatus = (() => {
      if (element.status === 'Pending') return 'Invited';
      if (element.status === 'Approved') return 'Approved';
      if (element.status === 'Declined') return 'Invitation Declined';
      return element.statusLastUpdatedBy ? `${element.status} by ${element.statusLastUpdatedBy}` : element.status;
    })();
    return {
      id: element.id,
      companyName: element.publisher.companyName,
      url: element.publisher.companyUrl,
      publisherId: element.publisher.id,
      invitation: new Date(element.createdAt).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
      createdAt: element.createdAt,
      status: formatStatus,
      subject: element.publisherInvitation?.subject,
      message: element.publisherInvitation?.message,
    };
  });
  return formatedData;
};

export const useInvitationHistory = () => {
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<SelectOption>();
  const [modalOpen, setModalOpen] = useModal();
  const [modalInfo, setModalInfo] = useState<ModalInfoProps | undefined>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [getMemberships, { loading }] = useLazyQuery(GET_MEMBERSHIP);
  const [getCSVMemberships, { loading: csvLoading }] = useLazyQuery(GET_MEMBERSHIP);

  const getMembershipHandler = async () => {
    const { data } = await getMemberships({
      variables: {
        input: {
          filter: {
            merchantId: hookWhoAmI.companyId?.toString(),
            search,
            status: filter?.value,
          },
          options: {
            page,
            order: sortColumn.direction?.toUpperCase(),
          },
          sortBy: sortColumn.column,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.listInvitationHistory?.invitations !== undefined) {
      const newData = formatMembershipData(data.listInvitationHistory.invitations);
      setTableData(newData);
      setTotalPages(Math.ceil(data.listInvitationHistory.count / 10));
    }
  };

  const downloadCSVHandler = async () => {
    const { data } = await getCSVMemberships({
      variables: {
        input: {
          filter: {
            merchantId: hookWhoAmI.companyId?.toString(),
            search,
            status: filter?.value,
          },
          options: {
            page: -1,
            order: sortColumn.direction?.toUpperCase(),
          },
          sortBy: sortColumn.column,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.listInvitationHistory?.invitations !== undefined) {
      const csvData = formatMembershipData(data?.listInvitationHistory?.invitations);
      const csvString = processDataToCSVString(csvData, INVITATION_HISTORY_CSV_COLUMNS);
      csvGenerator(csvString, 'Invitations History');
    }
  };

  const setSearchHandler = (string: string) => {
    setSearch(string);
  };

  const setFilterHandler = (option: SelectOption) => {
    setFilter(option);
  };

  const clearFilters = () => {
    setFilter(undefined);
    setSearch('');
  };

  const goBack = () => {
    navigate(`${MERCHANT_PREFIX}${path.publisherInvitations.href}`, {
      state: {
        ...location.state,
        from: `${MERCHANT_PREFIX}${path.invitationHistory.href}`,
      },
    });
  };

  const setModalInfoHandler = ({ id, subject, message }: Record<string, any>) => {
    setModalInfo({
      publisherId: id,
      publisherName: hookWhoAmI.companyName || '',
      subject,
      message,
    });
  };

  const changePageHandler = (pageValue: number) => {
    setPage(pageValue);
  };

  const handleSort = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  useEffect(() => {
    getMembershipHandler();
  }, [filter, search, sortColumn, page]);

  return {
    hookLoading: loading,
    hookCsvLoading: csvLoading,
    hookFilter: filter,
    hookIsModalOpen: modalOpen,
    hookModalInfo: modalInfo,
    hookSearch: search,
    hookTableData: tableData,
    hookSort: sortColumn,
    hookCurrentPage: page,
    hookTotalPages: totalPages,
    hookBackNavigation: goBack,
    hookClearFilters: clearFilters,
    hookNavigate: navigate,
    hookSetFilter: setFilterHandler,
    hookSetModalInfo: setModalInfoHandler,
    hookSetModalOpen: setModalOpen,
    hookSetSearch: setSearchHandler,
    hookSortHandler: handleSort,
    hookChangePage: changePageHandler,
    hookDownloadCSV: downloadCSVHandler,
  };
};
