import React from 'react';

import { RECORDS_PER_PAGE_OPTIONS } from '../../utils';
import { Pagination, Table, InputCalendar, Select, Calendar, RecordsDropdown } from '..';
import PlaceHolderNoResults from '../NoResultsPlaceHolder';

import { CURRENCY_TABS } from './enums';
import { columns } from './contracts';
import * as Styled from './styles';
import { useCurrencyRates } from './hooks';

const CurrencyRateTabs = () => {
  const hooks = useCurrencyRates();

  return (
    <Styled.WrapperStyled>
      <Styled.OptionsRowStyled>
        <Styled.LeftStyled>
          <RecordsDropdown
            selected={hooks.hookRecords}
            onChange={hooks.hookSetRecords}
            options={RECORDS_PER_PAGE_OPTIONS}
            totalRecords={hooks.hookTotalRecords}
            currentPage={hooks.hookCurrentPage}
          />
          <Styled.PublisherSelectStyled>
            <Select
              label={CURRENCY_TABS.EMPTY_LABEL}
              name={CURRENCY_TABS.PUBLISHER_SELECT}
              onChange={hooks.hookSetSelectedCurrency}
              selected={hooks.hookSelectedCurrency}
              options={CURRENCY_TABS.CURRENCY_OPTIONS}
            />
          </Styled.PublisherSelectStyled>
        </Styled.LeftStyled>

        <Styled.RightStyled>
          <InputCalendar
            label={CURRENCY_TABS.CALENDAR_LABEL}
            value={hooks.hookSelectDate}
            onClick={hooks.hookSetOpenCalendar}
            placeholder={CURRENCY_TABS.CALENDAR_PLACEHOLDER}
          />
        </Styled.RightStyled>
      </Styled.OptionsRowStyled>

      {hooks.hookCurrencyRateList.length > 0 && (
        <>
          <Table
            columns={columns}
            data={hooks.hookCurrencyRateList}
            isLoading={hooks.hookCurrencyLoading}
            sortColumn={hooks.hookSort}
            onSort={hooks.hookHandleSort}
          />
          <Pagination
            total={hooks.hookTotalPages}
            currentPage={hooks.hookCurrentPage}
            onPageChange={hooks.hookSetPage}
          />
        </>
      )}

      {hooks.hookTotalRecords === 0 && !hooks.hookCurrencyLoading && <PlaceHolderNoResults />}

      <Calendar
        date={hooks.hookStartDate}
        endDate={hooks.hookEndDate}
        onApply={hooks.hookOnApplyCalendar}
        onCancel={hooks.hookOnCancelCalendar}
        isOpen={hooks.hookOpenCalendar}
        hasRange
      />
    </Styled.WrapperStyled>
  );
};

export default CurrencyRateTabs;
