import React from 'react';

import * as Styled from '../../styles';
import { Button, Modal, ErrorBanner, InputText, Select } from '../../../../../../../../components';
import { STEP_ONE_MODAL } from '../../enums';

type AddRuleStepOneProps = {
  isOpen: boolean;
  errorMessage: string;
  ruleName: string;
  setRuleName: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  ruleNameError: string | undefined;
  checkRuleGroupsList: any;
  cancelButtonHandler: () => void;
  navigateModalTwo: () => Promise<void>;
  canContinueOne: boolean;
  ruleNameCheckLoading: boolean;
  setSelectedCheckRuleGroupHandler: (newSelectedRuleGroup: SelectOption) => void;
  selectedCheckRuleGroup: SelectOption | undefined;
};

export const AddRuleStepOneModal = ({
  isOpen,
  errorMessage,
  ruleName,
  setRuleName,
  ruleNameError,
  checkRuleGroupsList,
  setSelectedCheckRuleGroupHandler,
  selectedCheckRuleGroup,
  cancelButtonHandler,
  navigateModalTwo,
  canContinueOne,
  ruleNameCheckLoading,
}: AddRuleStepOneProps) => (
  <Modal isOpen={isOpen}>
    <Styled.ModalWrapperStyled>
      <ErrorBanner isOpen={!!errorMessage} message={errorMessage} />
      <Styled.PageTitleStyled>{STEP_ONE_MODAL.TITLE}</Styled.PageTitleStyled>
      <Styled.DropdownSectionStyled>
        <InputText
          required
          type="text"
          name="ruleName"
          label={STEP_ONE_MODAL.RULE_NAME.LABEL}
          tooltip={STEP_ONE_MODAL.RULE_NAME.TOOLTIP}
          placeholder={STEP_ONE_MODAL.RULE_NAME.PLACEHOLDER}
          value={ruleName}
          onChange={setRuleName}
          error={ruleNameError}
        />
      </Styled.DropdownSectionStyled>
      <Styled.DropdownSectionStyled>
        <Select
          name="ruleGroup"
          label={STEP_ONE_MODAL.GROUP_RULES.LABEL}
          tooltip={STEP_ONE_MODAL.GROUP_RULES.TOOLTIP}
          placeholder={STEP_ONE_MODAL.GROUP_RULES.PLACEHOLDER}
          selected={selectedCheckRuleGroup}
          options={checkRuleGroupsList}
          onChange={setSelectedCheckRuleGroupHandler}
        />
      </Styled.DropdownSectionStyled>
      <Styled.ModalFooterStyled>
        <Styled.PageCounterStyled>{STEP_ONE_MODAL.STEP_COUNTER}</Styled.PageCounterStyled>
        <Styled.NavButtonsWrapperStyled>
          <Button theme="secondary" onClick={cancelButtonHandler} width="100px">
            {STEP_ONE_MODAL.CANCEL_BUTTON}
          </Button>
          <Button
            theme="primary"
            onClick={navigateModalTwo}
            disabled={!canContinueOne}
            width="120px"
            loading={ruleNameCheckLoading}
          >
            {STEP_ONE_MODAL.CONTINUE_BUTTON}
          </Button>
        </Styled.NavButtonsWrapperStyled>
      </Styled.ModalFooterStyled>
    </Styled.ModalWrapperStyled>
  </Modal>
);
