import React from 'react';

import { useInternalPublisher } from '../../hooks';

import * as Styled from './styles';

const AccountInformationTab = () => {
  const hook = useInternalPublisher();

  return (
    <Styled.WrapperStyled>
      <Styled.InputTextStyled
        label="ID"
        theme="readonly"
        readonly
        type="text"
        value={hook.hookAccountInformation.hookUser?.id}
      />
      <Styled.InputTextStyled
        label="Company Name"
        theme="readonly"
        readonly
        type="text"
        value={hook.hookAccountInformation.hookUser?.companyName}
      />
      <Styled.InputTextStyled
        label="Name"
        theme="readonly"
        readonly
        type="text"
        value={hook.hookAccountInformation.hookUser?.name}
      />
      <Styled.InputTextStyled
        label="Network Registration Date"
        theme="readonly"
        readonly
        type="text"
        value={hook.hookAccountInformation.hookUser?.registration}
      />
      <Styled.InputTextStyled
        label="Application Date"
        theme="readonly"
        readonly
        type="text"
        value={hook.hookAccountInformation.hookUser?.application}
      />
      <Styled.InputTextStyled
        label="Account Status"
        theme="readonly"
        readonly
        type="text"
        value={hook.hookAccountInformation.hookUser?.accountStatus}
      />
    </Styled.WrapperStyled>
  );
};

export default AccountInformationTab;
