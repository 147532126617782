import styled from 'styled-components';

import { InputText } from '../../../../../../components';
import { fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

export const WrapperStyled = styled.div`
  padding: 1.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

export const InputTextStyled = styled(InputText)`
  span {
    ${fonts.gotham.H11M} !important;
  }
  input {
    font-size: ${toRem(16)} !important;
  }
`;
