import styled from 'styled-components';

import { Button, InfoBox, InputTextarea, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

type GridStyledProps = {
  columns?: number;
  rows?: number;
};

export const InfoBoxStyled = styled(InfoBox)`
  margin-top: 2rem;
`;

export const GridStyled = styled.div<GridStyledProps>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
  grid-gap: 1rem 2%;
  margin: 2rem 0;
`;

export const Select2Styled = styled(Select)`
  grid-column: span 2;
`;

export const Select3Styled = styled(Select)`
  grid-column: span 3;
`;

export const TextareaStyled = styled(InputTextarea)`
  grid-column: span 5;
`;

export const LabelStyled = styled.span`
  color: ${colors.color13};
  ${fonts.gotham.H12B}
  text-transform: uppercase;
`;

export const FileTypeStyled = styled.div`
  display: flex;
  margin-top: 24px;
  gap: ${toRem(30)};
`;

export const ButtonStyled = styled(Button)`
  svg {
    margin-right: 5px;
  }
`;

export const RadioWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
  text-transform: uppercase;
`;

export const TitleStyled = styled.p`
  color: ${colors.color1};
  ${fonts.gotham.H22}
  margin-top: 4rem;
  margin-bottom: 1rem;
`;

export const EmptyDiv = styled.div``;

export const ClearFormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  grid-column: span 6;
`;
