import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Button } from '../../../components';
import { fonts, colors } from '../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 550px;
  padding: 2rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  h1 {
    color: ${colors.color1};
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    ${fonts.gotham.H26M};
  }

  span {
    margin-bottom: 3rem;
    text-align: center;
    ${fonts.lato.H16}
    line-height: 1.2;
  }

  a {
    color: ${colors.color2};
  }
`;
export const IconStyled = styled(FontAwesomeIcon)`
  fontsize: 100;
  marginbottom: 40;
  color: colors.softGreen;
`;
export const ButtonStyled = styled(Button)`
  padding: 1rem 2rem;
`;
