import { gql } from '@apollo/client';

export const PAYMENT_ESTIMATION = gql`
  mutation PaymentEstimation($input: PaymentSearchInput) {
    paymentEstimation(input: $input) {
      payments {
        feasible
        publisher {
          id
          companyName
        }
        totalCommissions
        totalTaxes
        currentRollover
        bonus
        bonusTax
        paymentMethod
        currency
        totalPayable
        periodBalance
      }
      count
    }
  }
`;
