/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

import environment from '../../../../../../../../config/environment';

type PaypalButtonProps = {
  onSuccess: (transaction: any) => void;
  onFailed: () => void;
  createOrder: () => any;
  currency: string;
  amount: string;
  productCategory: string;
};

export const PaypalButton = ({
  currency,
  amount,
  onFailed,
  onSuccess,
  createOrder,
  productCategory,
}: PaypalButtonProps) => {
  const initialOptions = {
    'client-id': environment.app.paypal,
    currency: 'USD',
    intent: 'capture',
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        disabled={false}
        forceReRender={[amount, productCategory]}
        createOrder={async (data, actions) => {
          const order = await createOrder();
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: currency,
                  value: amount,
                },
                ...order,
              },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING',
            },
          });
        }}
        onApprove={(data, actions: any) =>
          actions.order.capture().then((transaction: any) => {
            onSuccess(transaction);
          })
        }
        onError={() => onFailed()}
      />
    </PayPalScriptProvider>
  );
};
