export type FeedbackStatusType = 'Review' | 'Pass' | 'Fail' | 'Not Applicable';

export type FeedbackReducerModalProps = {
  correctStatus: boolean | undefined;
  shouldBeStatus: FeedbackStatusType | undefined;
  currStatus: FeedbackStatusType | undefined;
  id: string | undefined;
};

export const FEEDBACK_ACTIONS = {
  SET_STATUS_CONFIRMATION: Symbol('set status confirmation'),
  SET_CORRECTED_STATUS: Symbol('set corrected status'),
  RESET_MODAL: Symbol('reset modal'),
};

export const feedbackModalReducer = (state: FeedbackReducerModalProps, { type, data }: BaseReducerType) => {
  switch (type) {
    case FEEDBACK_ACTIONS.SET_STATUS_CONFIRMATION:
      return {
        ...state,
        ...data,
      };
    case FEEDBACK_ACTIONS.SET_CORRECTED_STATUS:
      return {
        ...state,
        ...data,
      };
    case FEEDBACK_ACTIONS.RESET_MODAL:
      return {
        ...state,
        ...data,
      };
    default:
      return { ...state };
  }
};
