import React from 'react';

import { AppWrapper, Modal, Tabs } from 'components';
import { useModal } from 'hooks';
import { DefaultPropsType } from 'types';

import InvitationSentModal from '../../../Merchants/Publishers/InvitationHistory/Modal';

import ApplicationsTab from './Tabs/Applications';
import MerchantInvitationsTab from './Tabs/MerchantInvitations';
import { MEMBERSHIPS } from './enum';
import { useApplications, useMerchantInvitations } from './hooks';
import * as Styled from './styles';

type MembershipsProps = DefaultPropsType;

const Memberships = ({ permissionsCodeList = [] }: MembershipsProps): JSX.Element => {
  const [modal, setModal] = useModal(false);
  const hookMerchant = useMerchantInvitations(permissionsCodeList);
  const application = useApplications();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.PageTitleStyled>{MEMBERSHIPS.PAGE_TITLE}</Styled.PageTitleStyled>

      <Tabs
        tabNames={['Merchant Invitations', 'Applications']}
        elements={[
          <MerchantInvitationsTab hook={hookMerchant} key="MerchantInvitationsTab" />,
          <ApplicationsTab hook={application} key="ApplicationsTab" />,
        ]}
      />

      <Modal isOpen={modal}>
        <InvitationSentModal data={hookMerchant.hookMessage} closeFn={setModal} />
      </Modal>
    </AppWrapper>
  );
};

export default Memberships;
