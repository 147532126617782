import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createNewUser($input: UserInput!) {
    createNewUser(input: $input) {
      user {
        id
        email
      }
    }
  }
`;
