import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { commissionsFormatter, CommissionFormatted } from 'utils/formatCommissions';
import { COMMISSION_TYPES, defaultOption } from 'utils';
import { useUserInfo } from 'hooks';

import {
  GET_PUBLISHER_COMMISSIONS,
  GetPublisherCommissionsInput,
  GetPublisherCommissionsOutput,
  GET_MEMBERSHIPS,
  GetMembershipsInput,
  GetMembershipsOutput,
  GET_PUBLISHER_PRODUCTS,
} from '../queries';

const defaultMerchantOption: SelectOption = { label: 'All Approved Merchants', value: '' };

type TProduct = {
  id: string;
  name: string;
  status: string;
  productCategory: string;
};

export const useActiveCommissions = () => {
  const { hookWhoAmI } = useUserInfo();

  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption>(defaultMerchantOption);
  const [merchantList, setMerchantList] = useState<SelectOption[]>([]);

  const [completeCommissionList, setCompleteCommissionList] = useState<CommissionFormatted[]>([]);
  const [commissionList, setCommissionList] = useState<CommissionFormatted[]>([]);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'id', direction: 'asc' });
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [refetchPage, setRefetchPage] = useState<boolean>(false);

  const [records, setRecords] = useState({ label: '10', value: '10' });
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getPublisherCommissions, { loading: getPublisherCommissionsLoading }] = useLazyQuery<
    GetPublisherCommissionsOutput,
    GetPublisherCommissionsInput
  >(GET_PUBLISHER_COMMISSIONS);
  const [getMemberships, { loading: getMembershipsLoading }] = useLazyQuery<GetMembershipsOutput, GetMembershipsInput>(
    GET_MEMBERSHIPS
  );

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false);

  const defaultProduct = {
    id: '',
    name: defaultOption.product.label,
    status: '',
    productCategory: defaultOption.productCategory.label,
  };
  const [productCategory, setProductCategory] = useState<SelectOption>(defaultOption.productCategory);
  const [productList, setProductList] = useState<TProduct[]>([defaultProduct]);
  const [staticProductList, setStaticProductList] = useState<TProduct[]>([defaultProduct]);
  const [selectedProduct, setSelectedProduct] = useState<SelectOption>(defaultOption.product);

  /* Only show CPA & RevShare commission types as per FC-379 */
  const defaultCommission = { label: 'All Commission Types', value: 'All Commission Types' };
  const [commissionTypes] = useState<SelectOption[]>([
    defaultCommission,
    ...Object.entries(COMMISSION_TYPES)
      .filter((ct) => ['REVSHARE', 'CPA'].includes(ct[0]))
      .map(([key, value]) => ({
        label: value,
        value: key,
      })),
  ]);
  const [selectedCommissionType, setSelectedCommissionType] = useState<SelectOption>(defaultCommission);

  const [getProducts, { loading: getProductsLoading }] = useLazyQuery(GET_PUBLISHER_PRODUCTS);

  const setRecordsHandler = (value: any) => {
    setRecords({
      label: value.value,
      value: value.value,
    });
  };

  /**
   * Get the list of merchants the publisher is associated with
   */
  const getMerchantListHandler = async () => {
    setErrorMessage('');
    if (!hookWhoAmI.companyId) return;

    const { data: merchantListData } = await getMemberships({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId.toString(),
          status: 'Approved',
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (merchantListData && merchantListData.memberships) {
      const newMerchantList = merchantListData.memberships.memberships.map(
        (company): SelectOption => ({ label: company.merchant.companyName, value: company.merchant.id })
      );
      setMerchantList([defaultMerchantOption, ...newMerchantList]);
    }
  };

  /**
   * Get Publisher Active Commissions, based on the filters
   */
  const getPublisherActiveCommissions = async () => {
    setErrorMessage('');
    if (!hookWhoAmI.companyId) return;

    const { data: merchantListData } = await getPublisherCommissions({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId.toString(),
          limit: Number(records.value) || 10,
          currentPage,
          sortBy: sortColumn.column,
          sortDirection: sortColumn.direction,
          startDate: selectedDate,
          productCategory: productCategory.label,
          productId: selectedProduct.label === 'All Products' ? '' : selectedProduct.label,
          merchantId: selectedMerchant.label === 'All Approved Merchants' ? '' : selectedMerchant.value,
          commissionType: selectedCommissionType?.label === 'All Commission Types' ? '' : selectedCommissionType?.label,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (merchantListData && merchantListData.getPublisherCommissions) {
      const formattedCommissions = commissionsFormatter(merchantListData.getPublisherCommissions.commissions, true);

      setCompleteCommissionList(formattedCommissions);
      setCommissionList(formattedCommissions);
      setTotalPages(merchantListData.getPublisherCommissions.totalPages || 0);
      setTotalRecords(merchantListData.getPublisherCommissions.count || 0);
    }
  };

  const setCurrentPageHandler = (newPage: number) => {
    setCurrentPage(newPage);
    setRefetchPage(true);
  };

  /** Sorts the table by the column and direction given */
  const sortTableHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    const newDirection = direction === 'desc' ? 'asc' : 'desc';
    setSortColumn({ column, direction: newDirection });
    setRefetchPage(true);
  };

  const setSelectedMerchantHandler = (newMerchant: SelectOption) => {
    setSelectedMerchant(newMerchant);
  };

  /**
   * Clear all filters
   */
  const onClearFormClick = () => {
    setSelectedDate(undefined);
    setSelectedProduct(defaultOption.product);
    setSelectedCommissionType(defaultCommission);
    setProductCategory(defaultOption.productCategory);
    setCommissionList(completeCommissionList);
    setSelectedMerchant(defaultMerchantOption);
    setCurrentPage(1);
    setRefetchPage(true);
  };

  const setCalendarIsOpenHandler = () => {
    setCalendarIsOpen(!calendarIsOpen);
  };

  const onApplySelectedCalendarHandler = (start: Date) => {
    setSelectedDate(start);
    setCurrentPage(1);
    setCalendarIsOpen(false);
  };

  const onCancelSelectedCalendarHandler = () => {
    setCalendarIsOpen(false);
  };

  /**
   * When the user selects a product category,
   * set the product list to the products in that category
   * @param e Category selected
   */
  const onProductCategoryChange = (e: SelectOption) => {
    setSelectedProduct(defaultOption.product);
    setProductCategory(e);
    if (e.label === 'All Categories') {
      setProductList(staticProductList);
      return;
    }
    setProductList([defaultProduct, ...staticProductList.filter((p) => p.productCategory === e.label)]);
  };

  /**
   * Get the list of products
   */
  const getProductListHandler = async () => {
    if (!hookWhoAmI.companyId) return;
    const { data, error } = await getProducts({
      variables: {
        input: {
          publisherId: hookWhoAmI.companyId.toString(),
          status: 'Approved',
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      setErrorMessage(error.message);
      return;
    }
    if (data.getPublisherProducts) {
      const { products } = data.getPublisherProducts;
      setStaticProductList((oldValues) => [...oldValues, ...products]);
      setProductList((oldValues) => [...oldValues, ...products]);
    }
  };

  const onProductChange = (e: SelectOption) => {
    setSelectedProduct(e);
  };

  /**
   * When any filter changes, refetch the data
   */
  const onFiltersChangeHandler = async () => {
    setCurrentPage(1);
    await getPublisherActiveCommissions();
  };

  useEffect(() => {
    onFiltersChangeHandler();
  }, [records, selectedDate, productCategory, selectedProduct, selectedCommissionType, selectedMerchant]);

  /**
   * Initial fetch
   */
  useEffect(() => {
    getMerchantListHandler();
    getProductListHandler();
    getPublisherActiveCommissions();
  }, [window.location.href]);

  if (refetchPage) {
    setRefetchPage(false);
    getPublisherActiveCommissions();
  }

  return {
    hookPageLoading: getPublisherCommissionsLoading || getMembershipsLoading,
    hookErrorMessage: errorMessage,

    hookSelectedMerchant: selectedMerchant,
    hookMerchantList: merchantList,
    hookSetSelectedMerchant: setSelectedMerchantHandler,

    hookTableData: commissionList,
    hookTotalPages: totalPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,

    hookSortColumn: sortColumn,
    hookSortTableHandler: sortTableHandler,

    selectedDate,
    calendarIsOpen,
    openCalendar: setCalendarIsOpenHandler,
    onApplySelectedCalendar: onApplySelectedCalendarHandler,
    onCancelSelectedCalendar: onCancelSelectedCalendarHandler,

    productCategory,
    setProductCategory,
    onProductCategoryChange,

    productList,
    selectedProduct,
    setSelectedProduct,
    onProductChange,
    getProductsLoading,

    commissionTypes,
    selectedCommissionType,
    setSelectedCommissionType,

    onClearFormClick,

    records,
    setRecords: setRecordsHandler,
    totalRecords,
    setTotalRecords,
  };
};
