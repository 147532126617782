import React from 'react';
import jwtDecode from 'jwt-decode';

import environment from '../../config/environment';
import { imageList, getCookie } from '../../utils';
import { useLogOut } from '../../hooks';

import * as Styled from './styles';
import { VERIFY_MESSAGE, PLACEHOLDER_BACK, PLACEHOLDER_RESEND } from './enums';
import { VerificationEmailSentModal } from './VerificationEmailSentModal';
import { useSendEmail } from './hooks';

const Unverified = () => {
  const userCookie = getCookie(environment.app.cookieName);
  const user: any = userCookie ? jwtDecode(userCookie) : {};
  const hook = useSendEmail();
  const { hookLogout } = useLogOut();

  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img src={imageList.logoFull.src} alt="logo" />
        </figure>
      </Styled.LeftStyled>
      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <figure>
            <img src={imageList.envelope.src} alt={imageList.envelope.alt} />
          </figure>
          <h1>{VERIFY_MESSAGE.TITLE}</h1>
          <span>
            {VERIFY_MESSAGE.PART1}
            <a href={`mailto:${user.email}`}>{user.email}</a>
            {VERIFY_MESSAGE.PART2}
          </span>
          <Styled.ButtonAreaStyled>
            <Styled.ButtonStyled theme="secondary" type="button" onClick={() => hookLogout()}>
              {PLACEHOLDER_BACK}
            </Styled.ButtonStyled>

            <Styled.ButtonStyled type="submit" onClick={hook.hookSendEmail}>
              {PLACEHOLDER_RESEND}
            </Styled.ButtonStyled>
          </Styled.ButtonAreaStyled>
        </Styled.RightContentStyled>
      </Styled.RightStyled>

      <VerificationEmailSentModal isOpen={hook.hookModal} onClose={hook.hookCloseModal} email={user.email} />
    </Styled.WrapperStyled>
  );
};

export default Unverified;
