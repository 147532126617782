import React from 'react';

import { AppWrapper, PageTitle, Wysiwyg, Button } from '../../../../components';
import { DefaultPropsType } from '../../../../types';

import { SEND_PUBLISHER_INVITATION } from './enum';
import { useSendPublisherInvitation } from './hooks/useSendPublisherInvitation';
import * as Styled from './styles';

type SendPublisherInvitationProps = DefaultPropsType;

const SendPublisherInvitation = ({ permissionsCodeList = [] }: SendPublisherInvitationProps) => {
  const { hookIsReadOnlyList, ...hook } = useSendPublisherInvitation(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle isLoading={hook.hookLoading}>{SEND_PUBLISHER_INVITATION.PAGE_TITLE}</PageTitle>
      </Styled.HeaderStyled>
      <Styled.SelectStyled
        placeholder={SEND_PUBLISHER_INVITATION.APPLY_INVITATION_TEMPLATE}
        onChange={hook.hookHandleTemplateSelected}
        selected={hook.hookTemplateSelected}
        options={hook.hookTemplateOptions}
        isDisabled={isReadOnly}
      />
      <Styled.InputTextStyled
        type="text"
        placeholder={SEND_PUBLISHER_INVITATION.PUBLISHER_NAME.PLACEHOLDER}
        label={SEND_PUBLISHER_INVITATION.PUBLISHER_NAME.LABEL}
        disabled
        value={hook.hookPublisherName}
        required
      />
      <Styled.InputTextStyled
        type="text"
        placeholder={SEND_PUBLISHER_INVITATION.SUBJECT.PLACEHOLDER}
        label={SEND_PUBLISHER_INVITATION.SUBJECT.LABEL}
        value={hook.hookSubject}
        onChange={hook.hookSetSubject}
        required
        disabled={isReadOnly}
      />
      <Styled.SpecialFieldStyled>
        <Styled.SelectStyled
          placeholder={SEND_PUBLISHER_INVITATION.MERGE_FIELDS.PLACEHOLDER}
          name="merge"
          selected={hook.hookSelectedMergeField}
          onChange={hook.hookMergeFieldsHandler}
          options={SEND_PUBLISHER_INVITATION.MERGE_FIELDS.OPTIONS}
          isDisabled={isReadOnly}
        />
        <Styled.TooltipStyled text={SEND_PUBLISHER_INVITATION.MERGE_FIELDS.TOOLTIP} />
        <Styled.CopiedStyled>{hook.hookIndicator}</Styled.CopiedStyled>
      </Styled.SpecialFieldStyled>
      <Wysiwyg
        label="Message"
        value={hook.hookMessage}
        required
        onChange={hook.hookSetMessage}
        placeholder=""
        readonly={isReadOnly}
      />

      <Styled.ButtonAreaStyled>
        <Button theme="secondary" onClick={hook.hookGoBack}>
          {SEND_PUBLISHER_INVITATION.BUTTON.CANCEL}
        </Button>
        <Button
          theme="primary"
          onClick={hook.hookSendInvite}
          loading={hook.hookSendLoading || hook.hookLoading}
          disabled={hook.hookInvitesOnHold || isReadOnly}
        >
          {SEND_PUBLISHER_INVITATION.BUTTON.SEND}
        </Button>
      </Styled.ButtonAreaStyled>
    </AppWrapper>
  );
};

export default SendPublisherInvitation;
