import React from 'react';

import { InputText, Select, InputTextarea, DropZone, Checkbox } from 'components';
import { PRODUCT_CATEGORY_SELECT_OPTIONS } from 'utils';

import { STATUS_OPTIONS, TARGET_GENDER_OPTIONS, VISIBILITY_OPTIONS, PRODUCT_INFORMATION } from '../../enums';

import * as Styled from './styles';

type ProductInformationProps = {
  hook: any;
  isReadOnly: boolean;
};

const ProductInformation = ({ hook, isReadOnly }: ProductInformationProps): JSX.Element => (
  <Styled.WrapperStyled>
    <Styled.InnerWrapperStyled>
      <Styled.InputWrappers>
        <InputText
          label={PRODUCT_INFORMATION.PRODUCT_NAME.LABEL}
          required
          type="text"
          disabled={hook.hookLoading || isReadOnly}
          placeholder={PRODUCT_INFORMATION.PRODUCT_NAME.PLACEHOLDER}
          value={hook.hookProductName}
          onChange={hook.hookHandleProductName}
          error={hook.hookNewProductErrors.productName}
        />
        <Select
          isLoading={hook.hookLoading}
          label={PRODUCT_INFORMATION.STATUS}
          required
          isDisabled={hook.hookLoading || isReadOnly}
          options={STATUS_OPTIONS}
          selected={hook.hookStatus}
          onChange={hook.hookHandleStatus}
          error={hook.hookNewProductErrors.status}
        />
        <InputText
          label={PRODUCT_INFORMATION.PRODUCT_URL}
          required
          disabled={hook.hookLoading || isReadOnly}
          value={hook.hookProductUrl}
          type="text"
          onChange={hook.hookHandleProductUrl}
          onBlur={hook.hookValidateWebsite}
          error={hook.hookProductUrlError}
        />
        <InputText
          label={PRODUCT_INFORMATION.ID}
          required
          disabled={hook.hookLoading || isReadOnly}
          type="text"
          value={hook.hookProductId}
          onChange={hook.hookHandleId}
          onBlur={hook.hookValidate}
          error={hook.hookNewProductErrors.productId}
        />
        <Styled.MidSectionWrapper>
          <InputTextarea
            label={PRODUCT_INFORMATION.PRODUCT_DESC}
            value={hook.hookDescription}
            required
            disabled={hook.hookLoading || isReadOnly}
            onChange={hook.hookHandleDescription}
            onBlur={hook.hookValidate}
            error={hook.hookNewProductErrors.description}
          />
          <InputText
            label={PRODUCT_INFORMATION.LANDING}
            value={hook.hookLandingPage}
            disabled={hook.hookLoading || isReadOnly}
            type="text"
            onChange={hook.hookHandleLandingPage}
            onBlur={hook.hookValidateWebsite}
            error={hook.hookLandingUrlError}
          />
          <InputText
            label={PRODUCT_INFORMATION.THIRD}
            value={hook.hookThirdUrl}
            disabled={hook.hookLoading || isReadOnly}
            type="text"
            onBlur={hook.hookValidateWebsite}
            error={hook.hookThirdPartyUrlError}
            onChange={hook.hookHandleThirdParty}
          />
        </Styled.MidSectionWrapper>
        <DropZone
          label="Default Product Image"
          name="upload"
          accept=".png, .jpg, .jpeg, .gif"
          previewUrl={hook.hookProductImageUrl}
          maxFileSize={200000}
          onChange={hook.hookHandleUploadImage}
          isLoading={hook.hookLoading}
          disabled={hook.hookLoading || isReadOnly}
          type="ProfileImage"
        />
      </Styled.InputWrappers>
      <Styled.SingleLineWrapper>
        <Select
          isLoading={hook.hookLoading}
          label={PRODUCT_INFORMATION.PRODUCT_CATEGORY}
          required
          options={PRODUCT_CATEGORY_SELECT_OPTIONS}
          selected={hook.hookCategory}
          onChange={hook.hookHandleCategory}
          error={hook.hookNewProductErrors.productCategory}
          isDisabled={hook.hookLoading || isReadOnly}
        />
        {hook.hookSubCategory.length !== 0 && (
          <Styled.LabeledCheckWrapper>
            <Styled.LabelTextStyled>{PRODUCT_INFORMATION.SUB}</Styled.LabelTextStyled>
            <Styled.CheckBoxesWrapper>
              {hook.hookSubCategory.map((subCat: any) => (
                <Checkbox
                  label={subCat.name}
                  checked={subCat.checked}
                  onChange={() => hook.hookHandleSubCategory(subCat.name)}
                  disabled={isReadOnly}
                  key={subCat.name}
                />
              ))}
            </Styled.CheckBoxesWrapper>
          </Styled.LabeledCheckWrapper>
        )}
      </Styled.SingleLineWrapper>
    </Styled.InnerWrapperStyled>
    <Styled.InnerWrapperStyled>
      <Styled.WrapperTitleStyled>{PRODUCT_INFORMATION.TARGET}</Styled.WrapperTitleStyled>
      <Styled.InputWrappers isBottom>
        <Styled.MidSectionWrapper>
          <Select
            isLoading={hook.hookLoading}
            label={PRODUCT_INFORMATION.GENDER_LABEL}
            required
            options={TARGET_GENDER_OPTIONS}
            selected={hook.hookGender}
            onChange={hook.hookHandleGender}
            error={hook.hookNewProductErrors.targetGender}
            isDisabled={hook.hookLoading || isReadOnly}
          />
          <Select
            isLoading={hook.hookLoading}
            label={PRODUCT_INFORMATION.COMMISSIONS}
            required
            options={VISIBILITY_OPTIONS}
            selected={hook.hookVisibility}
            onChange={hook.hookHandleCommissions}
            isDisabled={hook.hookLoading || isReadOnly}
          />
        </Styled.MidSectionWrapper>
        <Styled.LabeledCheckWrapper>
          <Styled.LabelTextStyled>{PRODUCT_INFORMATION.AGE_RANGES}</Styled.LabelTextStyled>
          <Styled.CheckBoxesWrapper>
            {hook.hookAges.map((age: any) => (
              <Checkbox
                label={age.label}
                checked={age.checked}
                onChange={() => hook.hookHandleAges(age.label)}
                disabled={isReadOnly}
                key={age.label}
              />
            ))}
          </Styled.CheckBoxesWrapper>
        </Styled.LabeledCheckWrapper>
        <Select
          isLoading={hook.hookLoading}
          label={PRODUCT_INFORMATION.COUNTRY}
          options={hook.hookCountryOptions}
          required
          selected={hook.hookTargetCountry}
          onChange={hook.hookHandleCountry}
          isDisabled={hook.hookLoading || isReadOnly}
          error={hook.hookNewProductErrors.targetCountry}
        />
        <Select
          selectedMulti={hook.hookTargetStates}
          label={PRODUCT_INFORMATION.STATE}
          isMulti
          isDisabled={hook.hookLoading || isReadOnly}
          options={hook.hookRegionOptions}
          onChange={hook.hookHandleTargetState}
        />
        <InputText
          value={hook.hookMininmumIncome}
          disabled={hook.hookLoading || isReadOnly}
          label={PRODUCT_INFORMATION.INCOME}
          placeholder={PRODUCT_INFORMATION.INCOME_PLACEHOLDER}
          onChange={hook.hookHandleIncome}
          type="text"
        />
        <Styled.MidSectionWrapper isRow>
          <InputText
            value={hook.hookMinimumHouseHold}
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_INFORMATION.HOUSHOLD}
            onChange={hook.hookHandleHousehold}
            placeholder={PRODUCT_INFORMATION.INCOME_PLACEHOLDER}
            type="text"
          />
          <InputText
            value={hook.hookMinimumCredit}
            disabled={hook.hookLoading || isReadOnly}
            label={PRODUCT_INFORMATION.CREDIT}
            placeholder={PRODUCT_INFORMATION.CREDIT_PLACEHOLDER}
            onChange={hook.hookHandleCredit}
            type="text"
          />
        </Styled.MidSectionWrapper>
      </Styled.InputWrappers>
    </Styled.InnerWrapperStyled>
  </Styled.WrapperStyled>
);

export default ProductInformation;
