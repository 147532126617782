import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useModal } from '../../../../../hooks';
import { useSignUp } from '../../hooks';
import { LIST_CATEGORY_PROGRAMS, LIST_COUNTRY_PROGRAMS } from '../../graphql';
import { path } from '../../../../../utils';

export const useMerchantProgram = () => {
  const { contextState, contextDispatcher } = useSignUp();
  const [modalTC, setModalTC] = useModal();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any[]>([]);
  const [modalInfo, setModalInfo] = useState({ title: '', terms: '' });

  const [getPrograms, { loading }] = useLazyQuery(LIST_CATEGORY_PROGRAMS);
  const [getCountryPrograms, { loading: countryProgramsLoading }] = useLazyQuery(LIST_COUNTRY_PROGRAMS);
  const navigateHandler = (url: string) => {
    navigate(url);
  };

  const getProgramsHandler = async () => {
    const categoriesFormated = contextState.productCategories
      .filter((category) => category.checked)
      .map((category) => category.label);
    const { data } = await getPrograms({
      variables: {
        productCategories: categoriesFormated.join(),
      },
    });
    let programData = data?.categoryPrograms || { programs: [] };
    if (!data || data?.categoryPrograms.programs.length === 0) {
      const { data: countryData } = await getCountryPrograms({
        variables: {
          country: contextState.companyCountry.value,
        },
      });
      programData = countryData?.countryPrograms || { programs: [] };
    }
    const formatData = () =>
      programData.programs.map((program: any, index: number) => ({
        ...program,
        index,
        merchantId: program.merchant.id,
        companyName: program.merchant.companyName,
        TC: {
          title: program.termsConditionsTitle,
          terms: program.termsConditions,
        },
        join: contextState.programs.find((checkedProgram) => checkedProgram.programId === program.id)?.apply || false,
        newsletter:
          contextState.programs.find((checkedProgram) => checkedProgram.programId === program.id)?.subscription ||
          false,
      }));

    setTableData(formatData());
  };
  const setModalInfoHandler = (value: Record<string, any>) =>
    setModalInfo({
      title: value.title,
      terms: value.terms,
    });

  // Navigate back to first Page if Context is lost (eg. Reloaded Page)
  if (!contextState.userEmail) {
    navigate(path.createAccountStep1.href);
  }

  useEffect(() => {
    getProgramsHandler();
  }, [contextState.productCategories]);

  return {
    hookTableData: tableData,
    hookSetTableData: setTableData,
    hookLoading: loading || countryProgramsLoading,
    hookContextState: contextState,
    hookContextDispatcher: contextDispatcher,
    hookModalTC: modalTC,
    hookSetModalTC: setModalTC,
    hookModalInfo: modalInfo,
    hookSetModalInfo: setModalInfoHandler,
    hookNavigate: navigateHandler,
  };
};
