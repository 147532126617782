import { gql } from '@apollo/client';

export const GET_ADS = gql`
  query listSearchAds($input: ListSearchAdInput) {
    listSearchAds(input: $input) {
      count
      totalPages
      ads {
        programId
        id
        adContent
        adType
        width
        height
        adName
        merchantName
        campaignName
        productName
        productCategory
        createdAt
        defaultLandingPage
        description
      }
    }
  }
`;
