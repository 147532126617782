import { gql } from '@apollo/client';

export const CREATE_PRODUCT_FEED = gql`
  mutation createProductFeed($input: ProductFeedInput) {
    createProductFeed(input: $input) {
      id
      trackingProfileId
      merchants {
        id
        companyName
      }
      productCategories
      subCategories
      regionCountry
      regionStates
      productFeedUrl
    }
  }
`;
