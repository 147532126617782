import React from 'react';

import { AppWrapper, Button, PageTitle, Tabs } from 'components';
import { path } from 'utils';
import { DefaultPropsType } from 'types';

import CampaignsTab from './Tabs/Campaigns';
import CommissionsTab from './Tabs/Commissions';
import MembersTab from './Tabs/Members';
import { useEditGroup } from './hooks';
import * as Styled from './styles';

type EditPublisherGroupProps = DefaultPropsType;

const EditPublisherGroup = ({ permissionsCodeList = [] }: EditPublisherGroupProps): JSX.Element => {
  const { hookIsReadOnlyList, ...hook } = useEditGroup(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <Styled.HeaderStyled>
          <PageTitle>{path.editPublisherGroup.name}</PageTitle>

          <Styled.HeaderButtonAreaStyled>
            <Button theme="secondary" onClick={hook.hookGoBack}>
              Cancel
            </Button>
            <Styled.HeaderButtonStyled onClick={hook.hookSave} disabled={isReadOnly}>
              Save
            </Styled.HeaderButtonStyled>
          </Styled.HeaderButtonAreaStyled>
        </Styled.HeaderStyled>

        <Styled.EditSectionStyled>
          <Styled.InputTextStyled
            type="text"
            label="Group Name"
            value={hook.hookGroupName}
            onChange={hook.hookSetGroupName}
            error={hook.hookGroupNameError}
            required
            disabled={isReadOnly}
          />

          <Styled.InputTextareaStyled
            name="description"
            placeholder="Maximum length of text: 500"
            label="Description"
            value={hook.hookDescription}
            onChange={hook.hookSetDescription}
            error={hook.hookDescriptionError}
            required
            disabled={isReadOnly}
          />
        </Styled.EditSectionStyled>

        <Tabs
          tabNames={['Members', 'Commissions', 'Campaigns']}
          elements={[
            <MembersTab isReadOnly={isReadOnly} key="Members" />,
            <CommissionsTab key="CommissionsTab" />,
            <CampaignsTab key="CampaignsTab" />,
          ]}
        />
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default EditPublisherGroup;
