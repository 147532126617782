import { gql } from '@apollo/client';

export const UPDATE_MEMBERSHIPS = gql`
  mutation updateMembership($inputs: [MembershipInput], $status: MembershipStatus) {
    updateMembership(inputs: $inputs, status: $status, statusLastUpdatedBy: Merchant) {
      n
      nModified
    }
  }
`;

export type UpdateMembershipsInput = {
  inputs: {
    id: string;
    approvedDate: Date | null;
    status: string;
  }[];
  status: 'Approved' | 'Declined' | 'Closed';
};

export type UpdateMembershipsOutput = {
  updateMembership: {
    n: string;
    nModified: string;
  };
};
