import React from 'react';

import { CellWrapper } from '../../styles';

export const DateCell = (value: any): JSX.Element => {
  const { value: dateValue } = value;
  const date = value
    ? new Date(dateValue).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })
    : '';

  return (
    <CellWrapper>
      <p>{date}</p>
    </CellWrapper>
  );
};
