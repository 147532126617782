import styled from 'styled-components';

import { Select, Tooltip } from '../../../../../components';
import { colors, fonts } from '../../../../../styles/theme';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleStyled = styled.p`
  ${fonts.gotham.H16B}
  color: ${colors.color1};
  margin: 2rem 0;
`;
export const ModalTitleStyled = styled.p`
  ${fonts.gotham.H24M}
  color: ${colors.color1};
  margin-bottom: 2rem;
`;

export const FiltersStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

export const FiltersLabelStyled = styled.p`
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const SelectStyled = styled(Select)`
  width: 220px;
  margin-left: 1rem;
`;

export const FooterWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterTextStyled = styled.p`
  display: flex;
  align-items: center;
  ${fonts.gotham.H16B}
  color: ${colors.color1};
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 1rem;
`;

export const ModalAreaStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 32rem;
  min-height: 15rem;
  justify-content: space-between;
  padding: 1rem;
  ${fonts.lato.H14};
`;

export const TimeStyled = styled.span`
  margin-left: 1rem;
`;

export const ModalHeaderStyled = styled.h2`
  ${fonts.gotham.H12M}
  text-transform: uppercase;
  color: ${colors.color16};
  margin-bottom: 0.5rem;
`;

export const MessageTextStyled = styled.p`
  margin-bottom: 2rem;
  line-height: 1.5rem;
`;
