import styled from 'styled-components';

import { fonts } from '../../styles/theme';

export const CellWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-flow: wrap row;
`;

export const TextStyled = styled.div`
  ${fonts.lato.H16}
`;

export const ImageWrapperStyled = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 4px;
`;
