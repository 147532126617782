import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Button, InputTextarea } from '../../../../../components';
import { toRem } from '../../../../../utils';
import { fonts, colors } from '../../../../../styles/theme';

type GridWrapperProps = {
  theme?: string;
};

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonStyled = styled(Button)``;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: end;
  max-height: fit-content;

  ${ButtonStyled} {
    margin-left: 1rem;
  }
`;

export const CopyWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const GridWrapper = styled.div<GridWrapperProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: ${toRem(28)};
  row-gap: ${toRem(40)};
  gap: ${toRem(28)};
`;

export const StyledInputArea = styled(InputTextarea)`
  grid-column: 1 / span 2;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: ${toRem(8)};
`;

export const TabWrapperStyled = styled.div`
  gap: ${toRem(30)};
  display: flex;
  flex-direction: column;
  margin-top: ${toRem(40)};
`;

export const TabTitleStyled = styled.p`
  ${fonts.gotham.H22};
  color: ${colors.color1};
`;
