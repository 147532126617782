import React, { useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Placeholder, InputCalendar, Pagination, Select, Table } from '../../../../../../components';
import * as Styled from '../styles';
import {
  BUTTON_LABELS,
  INPUT_LABELS,
  monthlyAccountBalanceColumns,
  monthlyAccountBalanceOriginalColumns,
  PLACEHOLDERS,
} from '../../contracts';
import { defaultOption } from '../../../../../../utils';
import { PRODUCT_CATEGORIES } from '../../contracts/enum';

type MonthlyAccountBalanceProps = {
  setAddModal: () => void;
  selectedProductCategory: SelectOption;
  setSelectedProjectCategory: (value: SelectOption) => void;
  setIsCalendarOpen: () => void;
  monthyAccountBalance: MerchantMonthyAccountBalance[];
  dateInputValue: string;
  clearFilterHander: () => void;
  monthyAccountTotals: any;
  navigationHandler: (row: any) => void;
  csvDownHandler: () => void;
  statementNavigationHandler: (row: any) => void;
  onSort: (column: string, direction: 'asc' | 'desc' | undefined) => void;
  sortColumn: TableSortColumn;
  numberOfPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  isLoading: boolean;
  enableNewStats: boolean;
  isReadOnly: boolean;
};

const MonthlyAccountBalance = ({
  selectedProductCategory,
  setAddModal,
  setSelectedProjectCategory,
  monthyAccountTotals,
  setIsCalendarOpen,
  monthyAccountBalance,
  dateInputValue,
  clearFilterHander,
  onPageChange,
  navigationHandler,
  statementNavigationHandler,
  onSort,
  sortColumn,
  currentPage,
  numberOfPages,
  isLoading,
  csvDownHandler,
  enableNewStats,
  isReadOnly,
}: MonthlyAccountBalanceProps) => {
  useEffect(() => {
    setSelectedProjectCategory(defaultOption.productCategory);
  }, []);
  return (
    <Styled.WrapperStyled>
      <Styled.FiltersStyled>
        {enableNewStats && (
          <Select
            name="product"
            label={INPUT_LABELS.CATEGORY}
            onChange={setSelectedProjectCategory}
            selected={selectedProductCategory}
            options={PRODUCT_CATEGORIES}
          />
        )}
        {useMemo(
          () => (
            <InputCalendar
              label={INPUT_LABELS.PERIOD}
              value={dateInputValue}
              placeholder={PLACEHOLDERS.PERIODS}
              onClick={setIsCalendarOpen}
            />
          ),
          [dateInputValue]
        )}

        <Styled.ButtonAreaStyled enableNewStats={enableNewStats}>
          <Styled.ButtonStyled theme="text-only" onClick={clearFilterHander}>
            {BUTTON_LABELS.CLEAR}
          </Styled.ButtonStyled>

          <Styled.ButtonStyled theme="tertiary" wrapperTheme="secondary" onClick={csvDownHandler}>
            <FontAwesomeIcon icon={faFileDownload} style={{ marginRight: 5 }} />
            {BUTTON_LABELS.DOWNLOAD}
          </Styled.ButtonStyled>
        </Styled.ButtonAreaStyled>
      </Styled.FiltersStyled>

      {monthyAccountBalance && (
        <>
          <Table
            columns={
              enableNewStats
                ? monthlyAccountBalanceColumns(statementNavigationHandler, navigationHandler)
                : monthlyAccountBalanceOriginalColumns(statementNavigationHandler, navigationHandler)
            }
            footerData={monthyAccountTotals}
            data={monthyAccountBalance}
            onSort={onSort}
            sortColumn={sortColumn}
            isLoading={isLoading}
            onRowClick={navigationHandler}
          />
          <Pagination total={numberOfPages} currentPage={currentPage} onPageChange={onPageChange} />
        </>
      )}

      {!monthyAccountBalance && (
        <Placeholder
          title={PLACEHOLDERS.NO_RECODER}
          buttonIcon={faPlus}
          buttonText={BUTTON_LABELS.ADD}
          action={setAddModal}
          isReadOnly={isReadOnly}
        />
      )}
    </Styled.WrapperStyled>
  );
};

export default MonthlyAccountBalance;
