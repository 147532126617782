export const PAYMENT_REPORT = {
  LOADING_TEXT: 'Loading',
  SEARCH_BAR: {
    LABEL: '',
    INPUT_NAME: 'search',
    PLACEHOLDER: 'Search Name/ID/URL ... etc.',
  },
  PUBLISHER_FILTER: {
    LABEL: 'Publisher Account ID',
    NAME: 'publisherAccount',
  },
  MERCHANT_FILTER: {
    LABEL: 'Payment Method',
    NAME: 'paymentMethod',
  },
  PAYMENT_PERIOD: 'Payment Period',

  PUBLISHER_SELECT: 'publisherSelect',
  CLEAR_FORM: 'Clear Form',
  DOWNLOAD_BUTTON: 'Download CSV',
  GENERATE_BUTTON: 'Generate Report',
  PLACEHOLDER: {
    GET_RESULT: 'Click generate reports to get started',
  },
};

export const FOOTER_VALUES = {
  ROLLOVER: '$234243.45',
  TOTAL_COMMISSION: '$234.46',
  BONUS_AMOUNT: '$53252.78',
  BONUS_TAX: '$2435.78',
  COMMISSION_TAX: '$23452.75',
  TOTAL_PAYABLE: '$2345.67',
  PERIOD_BALANCE: '$2345.43',
};

export const CSV = {
  AMOUNTS: [
    'cpaCommission',
    'revShareCommission',
    'periodBalance',
    'currentRollover',
    'totalCommissions',
    'bonus',
    'bonusTax',
    'totalTaxes',
    'totalPayable',
  ],
  DATES: ['paymentGeneratedDate', 'paymentPaidDate'],
  MONTH_NAMES_SUB: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  COLUMNS: [
    {
      dataField: 'id',
      text: 'PAYMENT ID',
    },
    {
      dataField: 'month',
      text: 'PAYMENT PERIOD',
    },
    {
      dataField: 'paymentStatus',
      text: 'STATUS',
    },
    {
      dataField: 'publisher',
      text: 'PUBLISHER',
    },
    {
      dataField: 'paymentMethod',
      text: 'METHOD',
    },
    {
      dataField: 'productCategory',
      text: 'PRODUCT CATEGORY',
    },
    {
      dataField: 'currentRollover',
      text: 'ROLLOVER',
    },
    {
      dataField: 'periodBalance',
      text: 'PERIOD BALANCE',
    },
    {
      dataField: 'totalCommissions',
      text: 'TOTAL COMMISSION',
    },
    {
      dataField: 'bonus',
      text: 'BONUS AMOUNT',
    },
    {
      dataField: 'bonusTax',
      text: 'BONUS TAX',
    },
    {
      dataField: 'totalTaxes',
      text: 'COMMISSION TAX',
    },
    {
      dataField: 'totalPayable',
      text: 'TOTAL PAYABLE',
    },
  ],
};
