/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import { useTableColumns } from '../../hooks';
import { LoadingDots } from '../LoadingDots';
import { Spinner } from '../Spinner';

import * as Styled from './styles';

type TableProps = {
  columns?: TableColumn[];
  data?: any[];
  setData?: (data: any[]) => void;
  onSort?: (column: string, direction: 'asc' | 'desc' | undefined) => void;
  onRowClick?: any;
  checkInactive?: (row: any) => boolean;
  inactiveModal?: boolean;
  sortColumn?: TableSortColumn;
  isLoading?: boolean;
  loadWithData?: boolean;
  noTableOverflow?: boolean;
  className?: string;
  headerCheckBox?: any;
  handleHeaderCheckBox?: (state: any) => void;
  handleCheckBox?: (id: any, value: any) => void;
  setOpenModal?: () => void;
  setModalInfo?: (values: Record<string, any>) => void;
  setContext?: (values: Record<string, any>) => void;
  spinner?: boolean;
  cellAlignItems?: string;
  footerData?: { [key: string]: any };
  noHorizontalScroll?: boolean;
};

export const Table = ({
  columns = [],
  data = [],
  setData = () => {},
  isLoading = false,
  loadWithData = false,
  noTableOverflow = false,
  onSort = undefined,
  sortColumn = undefined,
  className,
  onRowClick,
  checkInactive,
  headerCheckBox,
  handleHeaderCheckBox,
  handleCheckBox,
  setModalInfo = () => {},
  setOpenModal = () => {},
  setContext = () => {},
  spinner,
  cellAlignItems = undefined,
  footerData,
  inactiveModal = false,
  noHorizontalScroll,
}: TableProps) => {
  const { hookColumns, hookSetColumns } = useTableColumns(columns);

  useEffect(() => {
    hookSetColumns(columns);
  }, [JSON.stringify(columns)]);

  useEffect(() => {
    setData(data);
  }, [JSON.stringify(data)]);

  const renderHeaders = () => (
    <Styled.TableHead>
      <Styled.TableHeaderRow>
        {hookColumns.map((column: TableColumn, index: number) => (
          <Styled.ColumnStyled width={column.width} data-column={index} key={index}>
            <Styled.SortButtonStyled
              isCheckBox
              theme={column.headerFormatter ? 'checkBox' : ''}
              isSorted={!!column.sortFunction}
              disabled={!column.sortFunction && !(column.headerFormatter && handleHeaderCheckBox)}
              position={column.position}
              onClick={() => (!column.isCheckBox ? onSort?.(column.dataField, sortColumn?.direction) : null)}
            >
              {column.headerFormatter && handleHeaderCheckBox
                ? column.headerFormatter(headerCheckBox, handleHeaderCheckBox)
                : column.text}
              {sortColumn?.column !== column.dataField && !!column.sortFunction && <FontAwesomeIcon icon={faSort} />}
              {sortColumn && sortColumn.column === column.dataField && !!column.sortFunction && (
                <FontAwesomeIcon icon={sortColumn.direction === 'asc' ? faSortUp : faSortDown} />
              )}
            </Styled.SortButtonStyled>
          </Styled.ColumnStyled>
        ))}
      </Styled.TableHeaderRow>
    </Styled.TableHead>
  );

  const renderData = () =>
    data.map((dataItem: any, index: number) => (
      <Styled.TableRows
        key={index}
        clickable={
          !!onRowClick &&
          !hookColumns[0].notClickable &&
          (dataItem.disabled === undefined || dataItem.disabled === false)
        }
        inactive={checkInactive !== undefined ? checkInactive(dataItem) : dataItem.disabled || false}
        inactiveModal={inactiveModal}
        alignItems={cellAlignItems}
      >
        {hookColumns.map((column: any, index2: number) => (
          <Styled.CellStyled
            onClick={() =>
              onRowClick && !column.notClickable && (dataItem.disabled === undefined || dataItem.disabled === false)
                ? onRowClick(dataItem)
                : ''
            }
            clickable={
              !!onRowClick && !column.notClickable && (dataItem.disabled === undefined || dataItem.disabled === false)
            }
            width={column.width}
            turnOffOverflow={column.cellOverflow || false}
            key={index2}
            wrapText={column.wrapText}
          >
            {!column.formatter
              ? dataItem[column.dataField]
              : column.formatter(
                  dataItem[column.dataField],
                  dataItem,
                  data,
                  setData,
                  handleCheckBox,
                  setOpenModal,
                  setModalInfo,
                  setContext
                )}
          </Styled.CellStyled>
        ))}
      </Styled.TableRows>
    ));

  const renderFooter = () => (
    <Styled.TableFooter>
      {hookColumns.map((column: TableColumn, index: number) => {
        if (column.footer || column.footerFormatter) {
          return (
            <Styled.CellStyled isFooter width={column.width} key={index}>
              {!column.footerFormatter ? column.footer : column.footerFormatter(column.footer, column, footerData)}
            </Styled.CellStyled>
          );
        }
        return <Styled.CellStyled width={column.width} key={index} />;
      })}
    </Styled.TableFooter>
  );

  const renderTable = () => (
    <Styled.TableStyled noHorizontalScroll={noHorizontalScroll}>
      {renderHeaders()}
      <Styled.TableBody>{renderData()}</Styled.TableBody>
      {(hookColumns[0]?.footer || hookColumns[0]?.footerFormatter) && renderFooter()}
    </Styled.TableStyled>
  );

  return (
    <Styled.WrapperStyled
      className={className}
      isLoading={isLoading}
      loadWithData={loadWithData}
      noTableOverflow={noTableOverflow}
    >
      {(!isLoading || loadWithData) && data && renderTable()}
      {isLoading && spinner && (
        <Styled.LoadingStyled>
          <Spinner size="1.5rem" theme="secondary" />
        </Styled.LoadingStyled>
      )}
      {isLoading && !spinner && (
        <Styled.LoadingStyled>
          <LoadingDots />
        </Styled.LoadingStyled>
      )}
    </Styled.WrapperStyled>
  );
};
