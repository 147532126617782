import React from 'react';

import { PageTitle } from '../PageTitle';
import { Button } from '../Button';
import { Tabs } from '../Tabs';
import { ErrorBanner } from '../ErrorBanner';

import { AccountTab } from './Tabs/Account';
// import { SubscriptionsTab } from './Tabs/Subscriptions';
import { EditAccount } from './Tabs/Edit';
import * as Styled from './styles';
import { useManageUser } from './hooks';

type ManageUserModalProps = {
  closeFn: (isSave?: boolean) => void;
  userType?: 'Admin' | 'Merchant' | 'Publisher';
  add?: boolean;
  edit?: boolean;
  userTypesId: number;
  isSelf?: boolean;
  // admin?: boolean
  changeTabHook?: any;
  userToEdit?: any;
  merchantMemberships?: any;
  useTabs?: boolean;
  hasWritePermission: boolean;
};

export const ManageUserModal = ({
  closeFn,
  userType = 'Merchant',
  add = true,
  userTypesId,
  isSelf,
  changeTabHook,
  userToEdit,
  merchantMemberships,
  hasWritePermission,
  edit = false,
  useTabs = true,
}: ManageUserModalProps) => {
  const hook = useManageUser(userTypesId, closeFn, merchantMemberships, hasWritePermission, userToEdit);

  const tabs = () => {
    if (add) {
      return {
        tabs: ['Account'],
        elements: [<AccountTab key="account-tab" hook={hook} add={add} />],
      };
    }

    return {
      tabs: ['Account'], // , 'Subscriptions'],
      elements: [
        <AccountTab key="account-tab" hook={hook} add={add} isSelf={isSelf} />,
        // Subscriptions Tab no longer wanted, see ticket: https://nautilusdigital.atlassian.net/browse/FIN-2953
        // <SubscriptionsTab
        //   hook={hook}
        //   isSelf={isSelf}
        //   admin={admin}
        // />
      ],
    };
  };
  return (
    <Styled.WrapperStyled>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
      <Styled.HeaderStyled>
        <PageTitle>
          {add ? 'Add' : 'Edit'} {userType} User
        </PageTitle>

        <Styled.HeaderButtonAreaStyled>
          <Styled.HeaderButtonStyled
            theme="secondary"
            onClick={() => {
              closeFn();
              changeTabHook?.setActiveTab(0);
            }}
          >
            Cancel
          </Styled.HeaderButtonStyled>
          <Button
            loading={hook.hookEditLoading || hook.hookAddUserLoading}
            onClick={() => hook.hookSave(add ? 'add' : 'edit')}
          >
            Save
          </Button>
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>
      {useTabs ? (
        <div>{edit ? <EditAccount hook={hook} /> : <AccountTab hook={hook} add={add} />}</div>
      ) : (
        <Tabs hookOveride={changeTabHook} tabNames={tabs().tabs} elements={tabs().elements} />
      )}
    </Styled.WrapperStyled>
  );
};
