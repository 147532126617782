import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserInfo } from '../../../hooks';
import { MERCHANT_PREFIX, path } from '../../../utils';

export const useAddCommissionModal = () => {
  const commissionTypeList = [
    {
      label: 'CPA - Cost per Action',
      value: 'CPA',
    },
    {
      label: 'Revenue Share',
      value: 'RevShare',
    },
    // FC-407 - Hide Bonus Commission Type
    // {
    //   label: 'Bonus',
    //   value: 'Bonus',
    // },
  ];
  const commissionSubTypeList = [
    {
      label: 'Default - All Members qualify for this commission',
      value: 'Default',
    },
    {
      label: 'Custom - Only select members/groups qualify for this commission',
      value: 'Custom',
    },
  ];
  const [selectedCommissionType, setSelectedCommissionType] = useState<SelectOption | undefined>(commissionTypeList[0]);
  const [selectedCommissionSubType, setSelectedCommissionSubType] = useState<SelectOption | undefined>(
    commissionSubTypeList[0]
  );

  const userHook = useUserInfo();
  const navigate = useNavigate();
  const onCloseHandler = (onClose: () => void) => {
    setSelectedCommissionSubType(commissionSubTypeList[0]);
    setSelectedCommissionType(commissionTypeList[0]);
    onClose();
  };

  const setSelectedCommissionTypeHandler = (item: any) => {
    setSelectedCommissionType({
      label: item.label,
      value: item.value,
    });
  };

  const setSelectedCommissionSubTypeHandler = (item: any) => {
    setSelectedCommissionSubType({
      label: item.label,
      value: item.value,
    });
  };

  const onConfirmHandler = () => {
    navigate(`${MERCHANT_PREFIX}${path.addNewCommission.href}`, {
      state: {
        commissionType: selectedCommissionType,
        commissionSubType: selectedCommissionSubType,
      },
    });
  };

  return {
    hookOnClose: onCloseHandler,
    hookCommissionTypeList: commissionTypeList,
    hookCommissionSubTypeList: commissionSubTypeList,
    hookSelectedCommissionType: selectedCommissionType,
    hookSelectedCommissionSubType: selectedCommissionSubType,
    hookSetSelectedCommissionType: setSelectedCommissionTypeHandler,
    hookSetSelectedCommissionSubType: setSelectedCommissionSubTypeHandler,
    hookOnConfirm: onConfirmHandler,
    hookUserType: userHook.hookUserInfo.userTypesId,
    hookWhoAmI: userHook.hookWhoAmI,
  };
};
