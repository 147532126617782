import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import * as am5theme from '@amcharts/amcharts5/themes/Animated';

import * as Styled from './styles';
import { ProductsApprovalStructure } from './types';

type ProductsApprovalProps = {
  productsData: ProductsApprovalStructure[];
};

export const ProductByApproval = ({ productsData }: ProductsApprovalProps) => {
  useEffect(() => {
    am5.ready(() => {
      am5.array.each(am5.registry.rootElements, (root) => {
        if (root?.dom.id === 'chart') root.dispose();
      });
      const root = am5.Root.new('chart');

      root.setThemes([am5theme.default.new(root)]);

      const chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
          innerRadius: am5.percent(40),
        })
      );

      const series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: 'Categories',
          valueField: 'count',
          categoryField: 'productCategory',
          legendLabelText: '[{fill}] {productCategory}',
          legendValueText: '',
        })
      );
      series.data.setAll(productsData);
      series.labels.template.set('forceHidden', true);
      series.ticks.template.set('forceHidden', true);

      const caption = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(55),
          layout: root.gridLayout,
        })
      );
      caption.data.setAll(series.dataItems);
    });
  }, []);

  return (
    <Styled.ChartWrapperStyled>
      <Styled.ChartStyled id="chart" />
    </Styled.ChartWrapperStyled>
  );
};
