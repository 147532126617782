import React from 'react';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

import { Dropdown } from '../Dropdown';
import { useDropdown } from '../../hooks';
import { path } from '../../utils';
import { formatNotificationDate, formatNotificationDescription } from '../../pages/Notifications/utils';

import { useNotifications } from './hooks/useNotifications';
import * as Styled from './styles';

export const Notifications = () => {
  const hook = useNotifications();
  const dropdownHook = useDropdown();
  return (
    <Styled.MenuNotificationStyled onClick={() => dropdownHook.setIsOpen}>
      {hook.hookNumberOfUnread > 0 && <Styled.UnreadsStyled>{hook.hookNumberOfUnread}</Styled.UnreadsStyled>}
      <Dropdown
        isOpen={dropdownHook.isOpen}
        setIsOpen={dropdownHook.setIsOpen}
        dropdownRef={dropdownHook.dropdownRef}
        text=""
        leftIcon
        iconClass={faBell}
      >
        <Styled.DropdownWrapperStyled>
          {hook.hookNotifications.map((notification) => (
            <Styled.NotificationStyled
              type="button"
              key={notification.id}
              onClick={() => hook.hookUpdateNotificationHandler(notification.id, !!notification.read)}
            >
              <Styled.NotificationsHeader>
                {!notification.read && <Styled.CircleStyled />}
                <Styled.TitleStyled>{notification.title}</Styled.TitleStyled>
              </Styled.NotificationsHeader>
              <Styled.DescriptionStyled>{formatNotificationDescription(notification)}</Styled.DescriptionStyled>
              <Styled.FooterStyled>
                <Styled.ClockStyled icon={faClock} />
                <Styled.DateStyled>
                  {' '}
                  {formatNotificationDate(new Date(notification?.createdAt || ''))}
                </Styled.DateStyled>
              </Styled.FooterStyled>
            </Styled.NotificationStyled>
          ))}
          {hook.hookNotifications && hook.hookNotifications.length > 4 && (
            <Styled.SeeAllWrapper>
              <Styled.LinkStyled to={`${path.notifications.href}`}>See all activity</Styled.LinkStyled>
            </Styled.SeeAllWrapper>
          )}
        </Styled.DropdownWrapperStyled>
      </Dropdown>
    </Styled.MenuNotificationStyled>
  );
};
