import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppWrapper, Button, Checkbox, InfoBox, Modal, PageTitle } from '../../../components';
import { path } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import {
  BUTTON_LABEL,
  ENABLE_BALANCE_REPORTING_MESSAGE,
  INPUT_LABEL,
  MODAL_TYPES,
  NEW_STATS_UPDATE_MESSAGE,
} from './contracts';
import { useAccountBalanceManagementStats } from './hooks';
import { AccountBalanceStats, MonthlyAccountBalance } from './components';
import { AddModal, ProductAccountBalanceReporting } from './Modals';
import * as Styled from './styles';

type AccountBalanceManagementStatsProps = DefaultPropsType;

const AccountBalanceManagementStats = ({ permissionsCodeList = [] }: AccountBalanceManagementStatsProps) => {
  const { hookIsReadOnlyList, ...hook } = useAccountBalanceManagementStats(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;
  const navigate = useNavigate();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>
          {hook.hookMerchantName} {path.merchantAccountBalanceStats.name}
        </PageTitle>
        <Button onClick={() => navigate(-1)} theme="secondary">
          {BUTTON_LABEL.CANCEL}
        </Button>
      </Styled.HeaderStyled>

      {!hook.hookIsEnableNewStats && (
        <>
          <Styled.BalanceRepostingWrapperStyled>
            <InfoBox
              title={ENABLE_BALANCE_REPORTING_MESSAGE.TITLE}
              descriptionTitle={ENABLE_BALANCE_REPORTING_MESSAGE.DESCRIPTION_TITLE}
              description={ENABLE_BALANCE_REPORTING_MESSAGE.DESCRIPTION}
              description2Title={ENABLE_BALANCE_REPORTING_MESSAGE.DESCRIPTION_TWO_TITLE}
              description2={ENABLE_BALANCE_REPORTING_MESSAGE.DESCRIPTION_TWO}
              icon={ENABLE_BALANCE_REPORTING_MESSAGE.ICON}
            />
          </Styled.BalanceRepostingWrapperStyled>
          <Styled.BalanceRepostingWrapperStyled>
            <Checkbox
              checked={hook.hookEnableNewStatsInput}
              onChange={hook.hookSetEnableNewStatsInput}
              label={INPUT_LABEL.BALANCE_REPORTING}
              disabled={isReadOnly}
            />
          </Styled.BalanceRepostingWrapperStyled>
        </>
      )}

      {hook.hookIsEnableNewStats && hook.hookUpdateStatsWarning && (
        <InfoBox
          title={NEW_STATS_UPDATE_MESSAGE.TITLE}
          description={NEW_STATS_UPDATE_MESSAGE.DESCRIPTION}
          icon={NEW_STATS_UPDATE_MESSAGE.ICON}
          theme="secondary"
        />
      )}

      <AccountBalanceStats
        accountBalanceStats={hook.hookAccountBalanceStats}
        isEnableNewStats={hook.hookIsEnableNewStats}
        handleNewStatClick={hook.hookNewStatNavgate}
        isReadOnly={isReadOnly}
      />

      <MonthlyAccountBalance
        tableLoading={hook.hookTableLoading}
        monthlyAccountBalanceData={hook.hookMonthyAccountBalance}
        monthlyAccountBalanceTotals={hook.hookMonthyAccountBalanceTotals}
        calendarOpen={hook.hookCalendarOpen}
        date={hook.hookDate}
        endDate={hook.hookEndDate}
        handleCalenderApply={hook.hookHandleCalenderApply}
        selectProductCategory={hook.hookSelectedProductCategory}
        setSelectProductCategory={hook.hookSetSelectedProductCategory}
        setCalendarOpen={() => hook.hookSetCalendarOpen(true)}
        handleCalenderCancel={() => hook.hookSetCalendarOpen(false)}
        handleClearForm={hook.hookClearForm}
        csvDownloadHandler={hook.hookCsvDownloadHandler}
        openAddModal={hook.hookSetAddModal}
        isEnableNewStats={hook.hookIsEnableNewStats}
        currentPage={hook.hookCurrentPage}
        numberOfPages={hook.hookNumberOfPages}
        onPageChange={hook.hookSetCurrentPage}
        usePlaceholder={hook.hookNoRecords || false}
        isLoading={hook.hookIsLoading}
        setSortBy={hook.hookSetSortColumn}
        sortBy={hook.hookSortColumn}
        onRowClick={hook.hookRowClick}
        onInvoiceClick={hook.hookNavigatePdf}
        isReadOnly={isReadOnly}
      />

      <Modal isOpen={hook.hookAddModal}>
        <AddModal
          type={hook.hookAddModalType}
          cancelHandler={hook.hookSetAddModal}
          saveHandler={hook.hookSaveHandler}
          addAmount={hook.hookAddAmount}
          setAddAmount={hook.hookSetAddModalAmount}
          openAddModalCalender={hook.hookAddModalCalenderOpen}
          setOpenAddModalCalender={hook.hookSetAddModalCalenderOpen}
          addDate={hook.hookAddModalDate}
          setDate={hook.hookSetAddModalDate}
          selectedType={
            hook.hookAddModalType === MODAL_TYPES.FEE ? hook.hookSelectedAddFeeType : hook.hookSelectedAddDepositTypes
          }
          setSelectedType={
            hook.hookAddModalType === MODAL_TYPES.FEE
              ? hook.hookSetSelectedAddFeeType
              : hook.hookSetSelectedAddDepositType
          }
          addFeeTypeOther={hook.hookAddFeeTypeOther}
          setAddFeeTypeOther={hook.hookSetFeeTypeOther}
          addNote={hook.hookAddModalNote}
          setAddNote={hook.hookSetAddModalNote}
          addModalProductCategoryOptions={hook.hookaddModalProductCategoryOptions}
          addModalReferenceNumber={hook.hookAddModalReferenceNumber}
          setAddModalReferenceNumber={hook.hookSetAddModalReferenceNumber}
          setSelectedAddModalProductCategory={hook.hookSetSelectedAddModalProductCategory}
          addSelectedModalProductCategory={hook.hookSelectedAddModalProductCategory}
          handleCheck={hook.hookSetNotifyMerchant}
          notifyMerchant={hook.hookNotifyMerchant}
          errors={hook.hookAddModalErrors}
          isEnableNewStats={hook.hookIsEnableNewStats}
        />
      </Modal>

      <Modal isOpen={hook.hookEnableNewStatsModalOpen}>
        <ProductAccountBalanceReporting
          closeHandler={hook.hookBalanceReportingModalCancel}
          updateHandler={hook.hookBalanceReportingModalUpdate}
          errors={hook.hookAddModalErrors}
        />
      </Modal>
    </AppWrapper>
  );
};

export default AccountBalanceManagementStats;
