import styled from 'styled-components';

import { InputText, Select } from '../../../../../../components';

export const WrapperStyled = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-self: end;
`;

export const InputTextStyled = styled(InputText)`
  grid-column: 1 / -1;
`;

export const SelectStyled = styled(Select)`
  grid-column: 1 / -1;
`;

export const PaypayWrapperStyled = styled.div`
  grid-column: 1/3;
`;

export const ErrorDiv = styled.div`
  padding: 0 2rem 0 2rem;
`;
