import { useMutation } from '@apollo/client';

import { DELETE_REPORT } from '../../graphql';
import { MODAL_KEYS } from '../../reducers';
import { useToast } from '../../../../../hooks';

export const useDeleteReport = (reportDeleted: () => void, setOpenModal: (value: Record<string, any>) => void) => {
  const { hookShowToast } = useToast();
  const [deleteModal, { loading }] = useMutation(DELETE_REPORT);

  const closeModalHandler = () => {
    setOpenModal({
      [MODAL_KEYS.openDeleteModal]: false,
    });
  };

  const deleteReportHandler = async (modalId: string) => {
    const { data } = await deleteModal({
      variables: {
        id: modalId,
      },
    });

    if (data?.deleteReportProfile) {
      closeModalHandler();
      reportDeleted();
      hookShowToast('Your report has been deleted');
    }
  };

  return {
    hookLoading: loading,
    hookDeleteReport: deleteReportHandler,
    hookCloseModal: closeModalHandler,
  };
};
