import React from 'react';

import * as Styled from './styles';

type FormatedCellProps = {
  value: any;
  secondValue?: any;
  children?: any;
  onClick?: () => void;
};

export const FormatedCell = ({ value, secondValue, children, onClick }: FormatedCellProps) => (
  <Styled.CellWrapperStyled onClick={onClick}>
    {value}
    {children && <Styled.SpaceBetween>{children}</Styled.SpaceBetween>}
    {secondValue && (
      <Styled.SecondLineStyled>
        <a href={secondValue} target="_blank" rel="noreferrer">
          {secondValue}
        </a>
      </Styled.SecondLineStyled>
    )}
  </Styled.CellWrapperStyled>
);
