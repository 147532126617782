import React from 'react';

import { CellProps } from '../../types';
import * as Styled from '../../styles';

export const NameCell = ({ val }: CellProps) => (
  <Styled.CellWrapper>
    <Styled.ValueStyled>{val.name}</Styled.ValueStyled>
    {val.description && <Styled.DescriptionStyled>{val.description}</Styled.DescriptionStyled>}
  </Styled.CellWrapper>
);
