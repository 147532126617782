import React from 'react';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import {
  AppWrapper,
  Button,
  PageTitle,
  Pagination,
  Select,
  RecordsDropdown,
  ErrorBanner,
  DeactivationForm,
} from '../../../components';
import { Table } from '../../../components/Table';
import { path, RECORDS_PER_PAGE_OPTIONS_WITH_150 } from '../../../utils';
import { DefaultPropsType } from '../../../types';

import { columns } from './contracts';
import { useUserManagement } from './hooks';
import { USER_MANAGEMENT } from './enum';
import * as Styled from './styles';

type UserManagementProps = DefaultPropsType;

const UserManagement = ({ permissionsCodeList = [] }: UserManagementProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = useUserManagement(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
        <Styled.HeaderStyled>
          <PageTitle loadingText={hook.hookLoadingMessage} isLoading={hook.hookIsLoading}>
            {path.userManagement.name}
          </PageTitle>

          <Styled.HeaderButtonsAreaStyled>
            <Styled.ButtonStyled
              theme={USER_MANAGEMENT.ADD_USER_BUTTON.THEME}
              disabled={hook.hookIsLoading || isReadOnly}
              onClick={() => navigate(path.addNewUser.href)}
            >
              <FontAwesomeIcon icon={faPlus} /> {USER_MANAGEMENT.ADD_USER_BUTTON.TEXT}
            </Styled.ButtonStyled>
          </Styled.HeaderButtonsAreaStyled>
        </Styled.HeaderStyled>

        <Styled.FiltersStyled>
          <Styled.InputTextStyled
            type="text"
            placeholder={USER_MANAGEMENT.SEARCH_BAR.PLACEHOLDER}
            faIcon={faSearch}
            name={USER_MANAGEMENT.SEARCH_BAR.NAME}
            onChange={hook.hookSetSearch}
            label={USER_MANAGEMENT.SEARCH_BAR.LABEL}
            value={hook.hookSearch}
            disabled={hook.hookIsLoading}
          />

          <Styled.FiltersSelectStyled>
            <span>{USER_MANAGEMENT.FILTER.TEXT}</span>
            <Select
              label={USER_MANAGEMENT.FILTER.label}
              name={USER_MANAGEMENT.FILTER.NAME}
              onChange={hook.hookHandleSetSelectedStatus}
              options={hook.hookUserStatusList}
              isLoading={hook.hookIsLoading}
              isDisabled={hook.hookIsLoading}
              selected={hook.hookStatusSelected}
            />
          </Styled.FiltersSelectStyled>
        </Styled.FiltersStyled>

        <Styled.OptionsStyled>
          <RecordsDropdown
            selected={hook.hookRecords}
            options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
            currentPage={hook.hookCurrentPage}
            totalRecords={hook.hookTotalRecords}
            onChange={hook.hookSetRecordsSelected}
            isDisabled={hook.hookIsLoading}
            isLoading={hook.hookIsLoading}
          />

          <Button
            type="button"
            theme="text-only"
            onClick={() => hook.hookClearFormHandler()}
            disabled={hook.hookIsLoading}
          >
            {USER_MANAGEMENT.CLEAR_FORM.TEXT}
          </Button>
        </Styled.OptionsStyled>

        <Table
          columns={columns(hook.hookSetToggle, hook.hookDeact, hook.hookNavigateProfile, isReadOnly)}
          onSort={hook.hookOnSortHandler}
          isLoading={hook.hookIsLoading}
          data={hook.hookUserList}
          sortColumn={hook.hookSortColumn}
        />

        <DeactivationForm
          isOpen={hook.hookDeact.isOpen}
          onCloseText={USER_MANAGEMENT.CANCEL_BUTTON.TEXT}
          onConfirmText={USER_MANAGEMENT.DEACTIVATE_BUTTON.TEXT}
          onClose={hook.hookDeact.hookOnClose}
          onConfirm={hook.hookDeact.hookOnConfirm}
          title={USER_MANAGEMENT.DEACTIVATE_BUTTON.TITLE_TEXT}
          desc={USER_MANAGEMENT.DEACTIVATE_BUTTON.QUESTION}
          userInformation={{
            firstName: hook.hookModalUser?.firstName,
            lastName: hook.hookModalUser?.lastName,
            email: hook.hookModalUser?.email,
          }}
        />

        <Pagination total={hook.hookTotalPages} currentPage={hook.hookCurrentPage} onPageChange={hook.hookChangePage} />
      </Styled.WrapperStyled>
    </AppWrapper>
  );
};

export default UserManagement;
