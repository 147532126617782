import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import * as Styled from './styles';

type ChipProps = {
  label: string;
  onClick?: (id: string) => void;
  className?: string;
  id: string;
};

export const Chip = ({ label, onClick, className, id }: ChipProps) => (
  <Styled.WrapperStyled className={className}>
    {label}
    {onClick && (
      <Styled.XButtonStyled onClick={() => onClick(id)}>
        <FontAwesomeIcon icon={faTimes} />
      </Styled.XButtonStyled>
    )}
  </Styled.WrapperStyled>
);
