import { gql } from '@apollo/client';

export const INVITATIONS_AVAILABLE = gql`
  query publisherInvitationAvailability($merchantId: String!) {
    publisherInvitationAvailability(merchantId: $merchantId) {
      available
      limited
      numberOfSent
      numberOfRemaining
    }
  }
`;
