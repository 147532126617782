import styled from 'styled-components';

import { Button, InputText, PageTitle, Select } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

type AdvancedSearchProps = {
  readonly isOpen: boolean;
};

type TableDataStyledProps = {
  readonly theme?: 'checkBox' | 'warning' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary';
};

type SelectStyledProps = {
  readonly gridRow?: string;
};

type RightSideStyledProps = {
  readonly theme?: 'secondary';
};

type LeftSideStyledProps = {
  readonly theme?: 'secondary';
};

type SpanStyledProps = {
  readonly theme?: 'secondary';
};

export const WrapperStyled = styled.div`
  padding-bottom: 100px;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1.5rem;
  align-items: center;
  margin-bottom: ${toRem(16)};

  &:nth-child(2) {
    margin-top: 2rem;
  }

  &:nth-child(4) {
    margin-top: 1rem;
  }
`;

export const InputTextStyled = styled(InputText)`
  grid-column: 1 / 5;
`;

export const SelectStyled = styled(Select)<SelectStyledProps>`
  grid-column: span 2;
  grid-row: ${({ gridRow }) => gridRow || 'auto'};
`;

export const LeftSideStyled = styled.div<LeftSideStyledProps>`
  grid-column: span 2;
  width: fit-content;
  grid-row: ${({ theme }) => (theme === 'secondary' ? '1/2' : '3/4')};

  &:nth-child(1) {
    display: flex;
    align-items: center;
  }
`;

export const RightSideStyled = styled.div<RightSideStyledProps>`
  grid-column: 5/7;
  grid-row: ${({ theme }) => (theme === 'secondary' ? '1/2' : '3/4')};
  justify-self: end;
`;

export const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${toRem(16)};
`;

export const RecordsPerPageStyled = styled(Select)`
  width: 120px;
  margin-left: 1rem;
`;

export const RecordsPerPageTextStyled = styled.span`
  color: ${colors.color1};
  ${fonts.lato.H16}
`;

export const AdvancedSearchStyled = styled.div<AdvancedSearchProps>`
  display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1rem;
  column-gap: 1.5rem;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
`;

export const AdvancedSelectStyled = styled(Select)`
  grid-column: span 1;
  margin-bottom: 1.5rem;
`;

export const AdvancedSearchTitleStyled = styled.p`
  grid-column: span 3;
  text-transform: uppercase;
  color: ${colors.color13};
  margin-bottom: 1rem;
  ${fonts.gotham.H14M}
`;

export const FooterWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.5rem;
  row-gap: 0.5rem;
  padding: 0.5rem;
`;

export const FooterSelectLabelStyled = styled.span`
  grid-column: 1/4;
  color: ${colors.color1};
  ${fonts.lato.H14}
`;

export const FooterSelectStyled = styled(Select)`
  grid-column: span 2;
`;

export const FooterButtonAreaStyled = styled.div`
  grid-column: span 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FooterButtonWrapperStyled = styled.div`
  margin-left: ${toRem(16)};
`;

export const ModalWrapperStyled = styled.div`
  width: 600px;
  padding: 1rem;
  grid-gap: 1rem;
`;

export const ModalCheckboxGroupStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin: ${toRem(16)} 0 ${toRem(40)};
`;

export const PageTitleStyled = styled(PageTitle)`
  grid-column: span 3;
  margin-bottom: 2rem;
`;

export const ModalCheckboxStyled = styled.div`
  display: flex;
  grid-column: span 1;
  color: ${colors.color1};
  ${fonts.lato.H16}
`;

export const ModalSubtitleStyled = styled.p`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12B}
  grid-column: 1 / -1;
  margin-top: 2rem;
`;

export const ModalButtonAreaStyled = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const ModalButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const ButtonStyled = styled(Button)`
  svg {
    margin-right: ${toRem(16)};
  }
`;

export const TableDataStyled = styled.div<TableDataStyledProps>`
  display: flex;
  width: 100%;
  min-height: 48px;
  line-height: ${({ theme }) => (theme === 'secondary' ? toRem(18) : toRem(24))};
  align-items: ${({ theme }) => (theme === 'quinary' ? 'flex-start' : 'center')};
  white-space: normal;
  justify-content: ${({ theme }) => (theme === 'quaternary' ? 'space-between' : 'flex-start')};
  flex-direction: ${({ theme }) => (theme === 'quinary' ? 'column' : 'row')};

  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return fonts.lato.H12;
      case 'tertiary':
        return fonts.lato.H12;
      default:
        return fonts.lato.H14;
    }
  }}

  color: ${({ theme }) => {
    switch (theme) {
      case 'tertiary':
        return colors.color28;
      default:
        return '';
    }
  }};

  svg {
    margin-right: ${toRem(16)};
    font-size: ${toRem(16)};
    color: ${({ theme }) => {
      switch (theme) {
        case 'warning':
          return colors.color2;
        case 'checkbox':
          return colors.color4;
        default:
          return colors.color5;
      }
    }};

    &:hover {
      color: ${colors.color2};
    }
  }
`;

export const CheckBoxDataStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 48px;

  &:hover {
    color: ${colors.color2};
  }
`;

export const IconWrapperStyled = styled.div`
  min-width: ${toRem(32)};
`;

export const TableCellLinkStyled = styled.a`
  color: ${colors.color2};
`;

export const AddRemoveModalStyled = styled.div`
  padding: ${toRem(34)};
  width: ${toRem(527)};
`;

export const ModalTitleStyled = styled.p`
  ${fonts.gotham.H26M};
  margin-bottom: ${toRem(30)};
`;

export const ModalGroupsStyled = styled.p`
  ${fonts.gotham.H20M};
  margin-bottom: ${toRem(15)};
`;

export const ModelDescriptionStyled = styled.p`
  margin-bottom: ${toRem(30)};
`;

export const SpanStyled = styled.span<SpanStyledProps>`
  ${({ theme }) => (theme === 'secondary' ? fonts.lato.H16B : fonts.lato.H16)}
`;

export const SpanStyledSmall = styled.span<SpanStyledProps>`
  ${fonts.lato.H12}
`;
