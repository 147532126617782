import { gql } from '@apollo/client';

export const GET_PUBLISHERS = gql`
  query GetPublishers {
    getPublishers {
      companyName
      id
    }
  }
`;
