import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';

type IconProps = {
  color: string;
  margin?: boolean;
};

type PercentageProps = {
  color: string;
};

export const TotalClickStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 40%;
  left: 32%;

  @media (min-width: 1920px) {
    top: 42%;
    left: 37%;
  }
`;

export const TotalClickItemStyled = styled.div`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
`;

export const TotalClickValueStyled = styled.div`
  ${fonts.gotham.H40M}
  color: ${colors.color1};
  margin-top: 1rem;
`;

export const ChartWrapperStyled = styled.div`
  width: 100%;
  position: relative;
  margin-top: 5rem;
`;

export const CaptionWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
`;

export const CaptionStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  align-items: center;
`;

export const CaptionNameStyled = styled.aside`
  ${fonts.gotham.H12}
  margin-top: 1rem;
`;

export const IconStyled = styled(FontAwesomeIcon)<IconProps>`
  color: ${({ color }) => color};
  margin-right: ${({ margin }) => (margin ? '5px' : '0')};
`;

export const CaptionNumberStyled = styled.span`
  ${fonts.gotham.H20}
  margin: 1rem 0;
`;

export const PercentageStyled = styled.div<PercentageProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  ${fonts.lato.H14B}
  border-radius: 15px;
  width: fit-content;
  height: 30px;
  color: ${({ color }) => color};
  background: ${({ color }) => {
    switch (color) {
      case colors.color14:
        return 'rgba(225, 93, 104, 0.05)';
      case colors.color2:
        return 'rgba(247, 141, 45, 0.05)';
      case colors.color21:
        return 'rgba(75, 199, 192, 0.05)';
      default:
        return 'none';
    }
  }};
`;
