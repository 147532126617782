import styled from 'styled-components';

import { PageTitle, Select } from 'components';
import { colors, fonts } from 'styles/theme';

type ProductFeedButtonProps = {
  readonly active: boolean;
};

export const StyledSelected = styled(Select)`
  max-height: 100px;
`;

export const BackButton = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const ModalWrapperStyled = styled.div`
  color: ${colors.color1};
  display: flex;
  flex-direction: column;
  width: 696px;
  padding: 8px 16px;
`;

export const BackIconStyled = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  :hover {
    cursor: pointer;
  }
`;

export const PageTitleStyled = styled(PageTitle)`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const DropdownSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 8px;
`;

export const AddRuleButtonWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const SubTitleTextStyled = styled.h3`
  ${fonts.gotham.H20M};
  color: ${colors.color1};
  line-height: 1.5;
`;

export const TextStyled = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
`;

export const TextPaddedStyled = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color1};
  padding-top: 32px;
`;

export const BoldTextStyled = styled.span`
  ${fonts.lato.H14B};
  color: ${colors.color1};
`;

export const InputHeaderStyled = styled.label`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  text-transform: uppercase;
`;

export const SelectStyled = styled(Select)`
  .optionLabel {
    ${fonts.lato.H14B};
    color: ${colors.color1};
    padding: 8px 0;
  }
  .optionDescription {
    ${fonts.lato.H14};
    color: ${colors.color16};
    padding-bottom: 8px;
  }
`;

export const RequirementRowStyled = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr 40px;
  grid-template-rows: 1fr auto;
  gap: 16px;
  padding: 12px 0;
`;

export const DeleteRequirementButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 5px;
  border: 1px solid ${colors.color3};
  background-color: ${colors.color4};
  margin-top: 22px;
  :hover {
    cursor: pointer;
    border: 1px solid ${colors.black};
  }
`;

export const RequiredCheckboxWrapper = styled.div`
  width: fit-content;
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 32px;
`;

export const PageCounterStyled = styled.div`
  ${fonts.lato.H14}
  color: ${colors.color1};
`;

export const NavButtonsWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export const AndSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0 12px 0;
`;

export const AndText = styled.span`
  color: ${colors.color23};
  ${fonts.lato.H16}
  margin: 0 8px;
`;

export const AndLine = styled.span`
  width: 100%;
  border-bottom: 1px solid ${colors.color15};
  margin-bottom: 7px;
`;

export const ProductFeedButton = styled.button<ProductFeedButtonProps>`
  height: 40px;
  background-color: ${colors.color4};
  width: 100%;
  border: ${({ active }) => (active ? `1px solid ${colors.color2}` : `1px solid ${colors.color3}`)};
  color: ${colors.color2};

  :hover {
    cursor: ${({ active }) => (active ? 'pointer' : 'initial')};
  }
`;

export const ProductFeedSection = styled.div`
  text-transform: uppercase;
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  width: 100%;
  padding-bottom: 8px;
`;

export const CriteriaTitleStyled = styled.div`
  ${fonts.lato.H14B};
  padding-bottom: 4px;
`;

export const PageWrapper = styled.div`
  width: 100%;
`;

export const FormStyled = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const TextDescription = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  padding-top: 1rem;
`;

export const TextSubDescription = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  white-space: nowrap;
`;

export const TextDescriptionBold = styled.p`
  ${fonts.lato.H16B};
  color: ${colors.color1};
  padding-top: 1rem;
`;

export const RuleButtonsWrapper = styled(NavButtonsWrapperStyled)`
  gap: 2vw;
  position: fixed;
  bottom: 0.5rem;
  right: 0;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem 1rem;
`;

export const SectionContentStyled = styled.div`
  width: 100 %;
  display: flex;
  align-items: center;
`;

export const InputSection = styled(SectionContentStyled)`
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const RadioLabelStyled = styled.div`
  ${fonts.lato.H14}
  margin-left: 0.2rem;
`;

export const InputItem = styled.div`
  flex: 1;
  display: flex;
`;

export const InputItemType = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
`;

export const InputItemValue = styled.div`
  flex: 0.5;
`;

export const HeaderInfoText = styled.div`
  ${fonts.lato.H16};
  color: ${colors.color1};
`;

export const TextDescriptionWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const TextCriteriaContentWrapper = styled.div`
  margin-top: 3rem;
`;

export const RuleStatusWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const RuleStatusLabel = styled.p`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  text-transform: uppercase;
`;

export const RuleStatusDescription = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  padding-top: 1rem;
  padding-bottom: 0.75rem;
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: 0.25rem;
`;

export const TextFieldLabel = styled.p`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  text-transform: uppercase;
`;

export const TextFieldLabelDisclaimer = styled.p`
  ${fonts.lato.H12};
  color: ${colors.color13};
  margin-bottom: 0.5rem;
`;

export const TextFieldValue = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  word-break: break-word;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
  flex: 1;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;
  flex: 1;
`;

export const TextMatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
