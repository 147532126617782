import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors, fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';
import { Button } from '../../../../components';

type VisibleStatusProps = {
  readonly visible: boolean;
};

type GraphWrapperProps = {
  readonly show: boolean;
};

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  aside {
    ${fonts.lato.H16}
    ${colors.color1}
  }
`;

export const StatusStyled = styled.div<VisibleStatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  ${fonts.lato.H14}
  padding: ${toRem(8)} ${toRem(10)};
  background-color: ${({ visible }) => (visible ? colors.color12 : colors.color23)};
  color: ${colors.color4};
  border-radius: 2px;
  transition: all 0.5s ease;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.color4};
  padding: 2rem;
  margin: 2rem 0;
`;

export const SectionHeaderStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LatestCheckStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonStyled = styled(Button)`
  ${fonts.lato.H14}
  width: 10rem;
`;

export const LatestCheckTitleStyled = styled.h4`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
  margin-left: 0.5rem;
`;

export const LastCheckStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color19};
  color: ${colors.color1};
  padding: 0.5rem;
  ${fonts.lato.H12}
  border-radius: 4px;
  margin-left: 1rem;
`;

export const GraphWrapper = styled.div<GraphWrapperProps>`
  width: 100%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: flex-start;
  justify-content: space-between;
`;

export const TitleStyled = styled.h4`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
`;

export const LoadingDotsStyled = styled.div``;

export const NoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37.5rem;
  width: 37.5rem;
  ${fonts.gotham.H20M}
`;

export const HeaderStyledScreenshot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.625rem;
  padding-bottom: 2.5rem;
`;

export const DetailedViewCloseIcon = styled(FontAwesomeIcon)`
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  font-size: 1.125rem;
  color: ${colors.color1};
  &:hover {
    color: ${colors.color2};
  }
`;

export const DownloadIcon = styled(FontAwesomeIcon)`
  display: inline;
  cursor: pointer;
  font-size: 1rem;
  color: ${colors.white};
  &:hover {
    color: ${colors.color2};
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 0.625rem;
`;

export const ScreenshotImage = styled.div`
  height: 36.25rem !important;
  overflow-y: scroll !important;
`;

export const ScreenshotModalHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 12.5rem auto 1.25rem;
  padding: 0;
  max-width: 56.25rem;
  padding-top: 0.625rem;
  padding-bottom: 2.5rem;
}
`;

export const ScreenshotModalHeaderItem = styled.div`
  background-color: ${colors.white};
  padding: 0;
  font-size: 1.25rem;
  text-align: center;
`;

export const ScreenshotModalHeaderItemCenter = styled(ScreenshotModalHeaderItem)`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
  text-align: right;
  padding-right: 4rem;
  cursor: pointer;
  a {
    ${fonts.lato.H14R}
    float: right;
    display: block;
    text-decoration: none;
    line-height: 0.4;
    -webkit-appearance: button;
    border-radius: 0.125rem;
    border: 1px solid ${colors.color1};
    background-color: ${colors.color1};
    color: ${colors.white};
    padding: 0.625rem 1rem;
  }
`;

export const SimpleFlexWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ScreenshotModalHeaderItemRight = styled(ScreenshotModalHeaderItem)`
  padding: 0;
`;

export const ScreenshotModalHeaderItemLeft = styled(ScreenshotModalHeaderItem)`
  ${fonts.gotham.H20M}
  color: ${colors.color1};
`;
