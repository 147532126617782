import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from '../../styles/theme';

export const WrapperStyled = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  color: ${colors.color19};
`;

export const RowWrapper = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid ${colors.color19};

  :first-of-type,
  :last-of-type {
    border: none;
  }

  :hover {
    background-color: ${colors.color27};
    cursor: pointer;
  }
`;

export const InvisibleIcon = styled(FontAwesomeIcon)`
  color: ${colors.transparent};
`;

export const HeadStyled = styled.th`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100px;
`;

export const IconsWrapperStyled = styled.div`
  margin-left: 10px;
`;

export const DataStyled = styled.td`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 16px;
  width: 100%;
  background-color: ${colors.color19};
  max-width: 100px;
`;

export const InvisibleData = styled.td`
  display: flex;
  height: 16px;
  width: 100%;
  background-color: ${colors.transparent};
  max-width: 100px;
`;
