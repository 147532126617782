import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { COMMISSION_TYPES, ERROR_TYPES, REGEX_VALIDATORS } from '../../../../../utils';
import { useValidation } from '../../../../../utils/validation';
import { AMOUNT_ERROR_MESSAGE, LEFT, REV_SHARE, RIGHT } from '../utils';

export const useCommissionStructure = () => {
  const [radioSelected, setRadioSelected] = useState(0);

  const [commissionBase, setCommissionBase] = useState<SelectOption>({ label: '', value: '' });
  const [minAmount, setMinAmount] = useState('');
  const [flatValue, setFlatValue] = useState('');
  const [commissionAmount, setCommissionAmount] = useState('');

  const [tierList, setTierList] = useState([
    {
      leftInput: '',
      rightInput: '',
      leftError: '',
      rightError: '',
    },
  ]);
  const [afterLevels, setAfterLevels] = useState('');
  const [trailingPeriod, setTrailingPeriod] = useState<SelectOption>({ label: '4 months', value: '4' });

  const location = useLocation();
  const commissionType: string = location.state.commissionType.value;

  const vali = useValidation();
  const [commissionStructureErrors, setCommissionStructureErrors] = useState<{ [key: string]: string }>({});

  // Validates an input string to make sure it does not contain more than 1 `.`
  const dotValidator = (inputValue: string) => {
    let dotCount = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < inputValue.length; i++) {
      if (dotCount >= 1 && inputValue[i] === '.') {
        return false;
      }
      if (inputValue[i] === '.') {
        dotCount += 1;
      }
    }
    return true;
  };

  const setRadioSelectedHandler = (value: any) => {
    setRadioSelected(Number(value));
  };

  const setCommissionBaseHandler = (value: SelectOption) => {
    setCommissionBase(value);
  };

  const setMinAmountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.validity.badInput) return;

    if (
      e.target.value === undefined ||
      REGEX_VALIDATORS.NUMBERS_DECIMALS.REGEX.test(e.target.value) ||
      e.target.value === ''
    ) {
      setMinAmount(e.target.value);
    }
  };

  const setFlatValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!dotValidator(inputValue)) return null;
    if (!inputValue) return setFlatValue('');
    return !/^[0-9.]+$/.test(inputValue) ? '' : setFlatValue(inputValue);
  };

  const setCommissionAmountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!dotValidator(inputValue)) return null;
    if (!inputValue) return setCommissionAmount('');
    return !/^[0-9.]+$/.test(inputValue) ? '' : setCommissionAmount(inputValue);
  };

  const setLeftInputHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newList = [...tierList];
    const inputValue = e.target.value;
    if (!dotValidator(inputValue)) return;
    if (!inputValue) {
      newList[index].leftInput = inputValue;
    } else if (!/^[0-9.]+$/.test(inputValue)) {
      return;
    }
    newList[index].leftInput = inputValue;
    setTierList(
      newList.map((item) => ({
        ...item,
        leftError: '',
        rightError: '',
      }))
    );
  };

  const setRightInputHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newList = [...tierList];
    const inputValue = e.target.value;
    if (!dotValidator(inputValue)) return;
    if (!inputValue) {
      newList[index].rightInput = inputValue;
    } else if (!/^[0-9.]+$/.test(inputValue)) {
      return;
    }
    newList[index].rightInput = inputValue;
    setTierList(
      newList.map((item) => ({
        ...item,
        leftError: '',
        rightError: '',
      }))
    );
  };

  const setAfterLevelsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!dotValidator(inputValue)) return null;
    if (!inputValue) return setAfterLevels('');
    return !/^[0-9.]+$/.test(inputValue) ? '' : setAfterLevels(inputValue);
  };

  const setTrailingPeriodHandler = (value: SelectOption) => {
    setTrailingPeriod(value);
  };

  const addItemHandler = () => {
    setTierList([
      ...tierList,
      {
        leftInput: '',
        rightInput: '',
        leftError: '',
        rightError: '',
      },
    ]);
  };

  const removeItemHandler = (index: number) => {
    if (tierList.length > 1) {
      const newList = [...tierList];
      newList.splice(index, 1);
      setTierList(newList);
    }
  };

  const cpaValues: { [key: string]: string } = {
    commissionAmount,
  };

  const cpaFields: any = {
    commissionAmount: ERROR_TYPES.NOT_EMPTY,
  };

  const revShareBonusValues: { [key: string]: string } = {
    commissionBase: commissionBase?.label || '',
    flatCommission: flatValue,
    flatBonus: flatValue,
  };

  const revShareBonusFields: any = {
    commissionBase: ERROR_TYPES.SELECTION_REQUIRED,
    flatCommission: radioSelected === 0 && commissionType === REV_SHARE ? REGEX_VALIDATORS.PERCENTAGE.REGEX : '',
    flatBonus: radioSelected === 0 && commissionType === COMMISSION_TYPES.BONUS ? ERROR_TYPES.NOT_EMPTY : '',
  };

  const validateStaticFields = () => {
    if (commissionBase.label !== '') {
      cpaValues.minAmount = minAmount;
      revShareBonusValues.minAmount = minAmount;
      cpaFields.minAmount = ERROR_TYPES.COMMISSION_AMOUNT;
      revShareBonusFields.minAmount = ERROR_TYPES.COMMISSION_AMOUNT;
    }
    let validate = true;
    if (commissionType === COMMISSION_TYPES.CPA && radioSelected === 0)
      validate = vali.validateAll(cpaValues, cpaFields, setCommissionStructureErrors, true);
    if (commissionType === REV_SHARE || commissionType === COMMISSION_TYPES.BONUS) {
      validate = vali.validateAll(revShareBonusValues, revShareBonusFields, setCommissionStructureErrors, true);
    }
    return validate;
  };

  const checkPercentage = (input: string) => input.match(REGEX_VALIDATORS.PERCENTAGE.REGEX);

  const validateSingleTierListElement = (index: number, side: string): boolean => {
    let noErrors = true;
    const newErrorList = [...tierList];
    if (radioSelected !== 0) {
      if (commissionType === COMMISSION_TYPES.REFERRAL || commissionType === REV_SHARE) {
        if (commissionType === COMMISSION_TYPES.REFERRAL && side === LEFT && tierList[index].leftInput !== '') {
          newErrorList[index].leftError = checkPercentage(tierList[index].leftInput)
            ? ''
            : REGEX_VALIDATORS.PERCENTAGE.MESSAGE;
        } else if (commissionType === REV_SHARE && side === LEFT) {
          newErrorList[index].leftError = tierList[index].leftInput === '' ? AMOUNT_ERROR_MESSAGE : '';
        }
        if (side === RIGHT && tierList[index].rightInput !== '') {
          newErrorList[index].rightError = checkPercentage(tierList[index].rightInput)
            ? ''
            : REGEX_VALIDATORS.PERCENTAGE.MESSAGE;
        } else if (side === RIGHT) {
          newErrorList[index].rightError = AMOUNT_ERROR_MESSAGE;
        }
        newErrorList[index].leftError =
          side === LEFT && tierList[index].leftInput === '' ? AMOUNT_ERROR_MESSAGE : newErrorList[index].leftError;
        newErrorList[index].rightError =
          side === RIGHT && tierList[index].rightInput === '' ? AMOUNT_ERROR_MESSAGE : newErrorList[index].rightError;
      } else if (side === LEFT) {
        newErrorList[index].leftError =
          tierList[index].leftInput === '' || !REGEX_VALIDATORS.INTEGERS_ONLY.REGEX.test(tierList[index].leftInput)
            ? REGEX_VALIDATORS.INTEGERS_ONLY.MESSAGE
            : '';
      } else if (side === RIGHT) {
        newErrorList[index].rightError =
          tierList[index].rightInput === '' || !REGEX_VALIDATORS.INTEGERS_ONLY.REGEX.test(tierList[index].rightInput)
            ? REGEX_VALIDATORS.INTEGERS_ONLY.MESSAGE
            : '';
      }
      if (newErrorList[index].leftError !== '' || newErrorList[index].rightError !== '') noErrors = false;
    }
    setTierList(newErrorList);
    return noErrors;
  };

  const validateTierList = () => {
    let noErrors = true;
    const newErrorList = [...tierList];
    if (radioSelected !== 0) {
      tierList.forEach((item, itemIndex) => {
        if (item.leftInput === '') {
          newErrorList[itemIndex].leftError = AMOUNT_ERROR_MESSAGE;
          noErrors = false;
        } else if (!item.leftInput.match(REGEX_VALIDATORS.INTEGERS_ONLY.REGEX)) {
          newErrorList[itemIndex].leftError = REGEX_VALIDATORS.INTEGERS_ONLY.MESSAGE;
          noErrors = false;
        } else {
          newErrorList[itemIndex].leftError = '';
        }
        if (item.rightInput === '') {
          newErrorList[itemIndex].rightError = AMOUNT_ERROR_MESSAGE;
          noErrors = false;
        } else if (!item.rightInput.match(REGEX_VALIDATORS.INTEGERS_ONLY.REGEX)) {
          newErrorList[itemIndex].rightError = REGEX_VALIDATORS.INTEGERS_ONLY.MESSAGE;
          noErrors = false;
        } else {
          newErrorList[itemIndex].rightError = '';
        }
      });
      setTierList(newErrorList);
    }
    return noErrors;
  };

  const handleValidation = () => {
    const noErrors = validateTierList();
    return validateStaticFields() && noErrors;
  };

  useEffect(() => {
    if (commissionType !== COMMISSION_TYPES.REFERRAL && radioSelected !== 0) {
      const temp = commissionStructureErrors;
      temp.commissionAmount = '';
      setCommissionAmount('');

      temp.flatCommission = '';
      temp.flatBonus = '';
      setFlatValue('');

      setCommissionStructureErrors(temp);
    }
    setTierList([
      {
        leftInput: '',
        rightInput: '',
        leftError: '',
        rightError: '',
      },
    ]);
  }, [radioSelected]);

  useEffect(() => {
    if (commissionType === COMMISSION_TYPES.REFERRAL) setRadioSelected(1);
  }, [tierList]);

  return {
    hookRadioSelected: radioSelected,
    hookSetRadioSelected: setRadioSelectedHandler,

    hookCommissionBase: commissionBase,
    hookSetCommissionBase: setCommissionBaseHandler,

    hookMinAmount: minAmount,
    hookSetMinAmount: setMinAmountHandler,

    hookFlatValue: flatValue,
    hookSetFlatValue: setFlatValueHandler,

    hookCommissionAmount: commissionAmount,
    hookSetCommissionAmount: setCommissionAmountHandler,

    hookSetLeftInput: setLeftInputHandler,
    hookSetRightInput: setRightInputHandler,

    hookTrailingPeriod: trailingPeriod,
    hookSetTrailingPeriod: setTrailingPeriodHandler,

    hookTierList: tierList,
    hookRemoveItem: removeItemHandler,
    hookAddItem: addItemHandler,

    hookAfterLevels: afterLevels,
    hookSetAfterLevels: setAfterLevelsHandler,

    hookCommissionStructureErrors: commissionStructureErrors,
    hookValidateCommissionStructure: handleValidation,
    hookValidateStaticFields: validateStaticFields,
    hookValidateTierList: validateTierList,
    hookValidateSingleTierList: validateSingleTierListElement,
  };
};
