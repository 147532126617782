import React from 'react';

import { PageUrlCell } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/contracts/Component/PageUrlCell/index';

import { Checkbox } from '../../../../../../../components/index';

import { Delete, Edit } from './components';
import { CellStyled, CellWrapper } from './styles';

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checked',
    text: '',
    width: '4vw',
    formatter: (val) => (
      <CellStyled>
        <Checkbox key={val} checked={val} onChange={() => ''} disabled={isReadOnly} />
      </CellStyled>
    ),
  },
  {
    dataField: 'url',
    text: 'Url',
    width: '40vw',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => <PageUrlCell val={val} />,
  },
  {
    dataField: 'category',
    text: 'Category',
    width: '20vw',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => <CellWrapper>{val}</CellWrapper>,
  },
  {
    dataField: 'checkType',
    text: 'Check Type',
    width: '15vw',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    formatter: (val) => <CellWrapper>{val}</CellWrapper>,
  },
  {
    dataField: 'edit',
    text: '',
    width: '4vw',
    notClickable: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <Edit val={val} row={row} setOpenModal={setOpenModal} setModalInfo={setModalInfo} isReadOnly={isReadOnly} />
    ),
  },
  {
    dataField: 'delete',
    text: '',
    width: '4vw',
    notClickable: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <Delete val={val} row={row} setOpenModal={setOpenModal} setModalInfo={setModalInfo} isReadOnly={isReadOnly} />
    ),
  },
];
