import React from 'react';

import { Button, PageTitle } from '../../../../../components';

import * as Styled from './styles';
import { PUBLISHER_LIST } from './enums';

type UpdatePublisherModalProps = {
  closeFn: () => void;
  publisherList: any[];
  removePublisher: (index: number) => void;
  setIsHeader: () => void;
  updateList: () => void;
};

const UpdateCustomListModal = ({
  closeFn,
  publisherList,
  removePublisher,
  setIsHeader,
  updateList,
}: UpdatePublisherModalProps) => (
  <Styled.WrapperStyled>
    <PageTitle>{PUBLISHER_LIST.TITLE}</PageTitle>

    <Styled.DescriptionStyled>{PUBLISHER_LIST.DESCRIPTION}</Styled.DescriptionStyled>

    <Styled.ScrollFrameStyled>
      {publisherList.map((item) => {
        const formatLabel = `${item.id} ${item.company.name}`;
        return (
          <Styled.ChipStyled
            label={formatLabel}
            id={item.index}
            onClick={() => {
              removePublisher(item);
            }}
            key={item.id}
          />
        );
      })}
    </Styled.ScrollFrameStyled>

    <Styled.ButtonAreaStyled>
      <Styled.ButtonStyled
        theme="secondary"
        onClick={() => {
          closeFn();
          setIsHeader();
        }}
      >
        {PUBLISHER_LIST.BACK}
      </Styled.ButtonStyled>
      <Button onClick={updateList}>{PUBLISHER_LIST.SAVE}</Button>
    </Styled.ButtonAreaStyled>
  </Styled.WrapperStyled>
);

export default UpdateCustomListModal;
