import React from 'react';

import * as Styled from '../styles';
import { CellProps } from '../types';

export const DateCell = ({ val }: CellProps) => (
  <Styled.DateCellStyled>
    <pre>{val.start} - </pre>
    <pre>{val.end}</pre>
  </Styled.DateCellStyled>
);
