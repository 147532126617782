import styled from 'styled-components';

import { colors, fonts } from '../../../../../../../styles/theme';
import { Button } from '../../../../../../../components/Button';

export const WrapperStyled = styled.div`
  width: 500px;
  padding: 1rem;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ButtonStyled = styled(Button)`
  border: none;
  background: transparent;
  color: ${colors.color1};
  font-size: 1.2rem;

  &:hover {
    color: ${colors.color2};
    border: none;
    background: transparent;
  }
`;

export const ContentStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  margin-bottom: 2rem;
`;

export const FooterAreaStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 175px;
`;
