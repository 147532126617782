import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Button } from '../../../../../components/Button';
import { Modal } from '../../../../../components/Modal';

import { useSaveReportProfile } from './hooks';
import SaveReportStepOne from './Modals/SaveReportStepOne';
import SaveReportStepTwo from './Modals/SaveReportStepTwo';
import UpdateReport from './Modals/UpdateReport';
import * as Styled from './styles';

type SaveReportProfileProps = {
  disabled: boolean;
  onSave: (profile: any) => void;
  onUpdate?: () => void;
  loading?: boolean;
  isUpdate?: boolean;
  reportName?: string;
};

export const SaveReportProfile = ({
  disabled,
  onSave,
  loading,
  isUpdate = false,
  reportName,
  onUpdate,
}: SaveReportProfileProps) => {
  const hook = useSaveReportProfile({ onSave, isUpdate });

  return (
    <Styled.WrapperStyled>
      <Button onClick={hook.hookSetStepOne} disabled={disabled || loading} loading={loading} width="164px">
        {isUpdate && <>Update Report</>}
        {!isUpdate && (
          <>
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} /> Save Report
          </>
        )}
      </Button>

      <Modal isOpen={hook.hookStepOne}>
        <SaveReportStepOne
          closeFn={() => hook.hookSetCloseAndClean(true)}
          reportNameFn={hook.hookSetReportName}
          reportName={hook.hookReportName}
          reportDescription={hook.hookReportDescription}
          reportDescriptionFn={hook.hookSetReportDescription}
          permissions={hook.hookReportPermission}
          permissionsFn={hook.hookSetReportPermission}
          nextFn={hook.hookSetChangeStep}
          permissionsOptions={hook.hookReportPermissionOptions}
        />
      </Modal>

      <Modal isOpen={hook.hookStepTwo}>
        <SaveReportStepTwo
          closeFn={() => hook.hookSetCloseAndClean(false)}
          backFn={hook.hookSetChangeStep}
          scheduleFrequency={hook.hookReportScheduleFrequency}
          scheduleFrequencyFn={hook.hookSetReportScheduleFrequency}
          scheduleFrequencyOptions={hook.hookReportScheduleFrequencyOptions}
          selectDetailedFrequencyFn={hook.hookSetReportScheduleDetailedFrequency}
          selectedDetailedFrequency={hook.hookReportScheduleDetailedFrequency}
          saveFn={hook.hookSetSaveReport}
        />
      </Modal>

      {isUpdate && onUpdate && (
        <Modal isOpen={hook.hookUpdateReport}>
          <UpdateReport
            closeFn={() => hook.hookSetCloseAndClean(true)}
            saveFn={() => {
              hook.hookSetCloseAndClean(true);
              onUpdate();
            }}
            reportName={reportName}
          />
        </Modal>
      )}
    </Styled.WrapperStyled>
  );
};
