import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import * as Styled from '../styled';
import { CellProps } from '../types';
import { Button } from '../../../../../../../../../components';
import { MERCHANT_PREFIX, path } from '../../../../../../../../../utils';

export const DetailCell = ({ membershipId, publisherId }: CellProps) => {
  const navigate = useNavigate();

  return (
    <Styled.CellStyled>
      <Button
        theme="text-only"
        onClick={() =>
          navigate(`${MERCHANT_PREFIX}${path.membershipDetails.href}`, {
            state: {
              membershipId,
              publisherId,
            },
          })
        }
      >
        <Styled.IconStyled icon={faChevronRight} />
      </Button>
    </Styled.CellStyled>
  );
};
