import { gql } from '@apollo/client';

export const GET_DETAILED_RECORD = gql`
  query RawCommissions($input: RawCommissionsSearchInput!) {
    rawCommissions(input: $input) {
      count
      rawCommissions {
        createdAt
        transactions {
          id
          orderId
          grossSale
          netSale
          createdAt
          commissionsDetailedAmount {
            commissionAmount
            commissionType
            commissionId
          }
          product {
            name
          }
        }
        rate
        commissionStructure {
          id
        }
        commissionType
        totalCommissions
        merchant {
          id
          companyName
        }
      }
    }
  }
`;
