import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { RECORDS_PER_PAGE_OPTIONS_WITH_150, ORDER_STATUS, csvGenerator } from '../../../../utils';
import { GET_PAYPAL_ORDERS } from '../Tabs/PayPalOrders/graphql/getPaypalOrders';
import { GET_CSV } from '../Tabs/PayPalOrders/graphql/getPaypalCsv';

export const usePaypalOrder = () => {
  const today = new Date();
  const [paypalOrders, setOrders] = useState<any[]>([]); // TODO: This is just for user flow testing
  const [selectedStatus, setStatus] = useState<SelectOption>(ORDER_STATUS[0]);
  const [records, setRecordSelected] = useState<SelectOption>(RECORDS_PER_PAGE_OPTIONS_WITH_150[0]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(100);
  const [isCalenderOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
  );
  const [endDate, setEndDate] = useState<Date>(new Date(today.setHours(23 - 8, 59, 59, 999)));
  const [displayRange, setDisplayRange] = useState<string>(`${startDate.toDateString()} - ${endDate.toDateString()}`);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [getPaypalOrders, { loading: paypalOrdersLoading }] = useLazyQuery(GET_PAYPAL_ORDERS);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [getPaypalCsv, { loading: csvLoading }] = useLazyQuery(GET_CSV);
  const [activeTab, setActiveTab] = useState(1);

  const handelSetActiveTab = () => {
    setActiveTab(0);
  };

  const handleDownloadCsv = async () => {
    const { data } = await getPaypalCsv({
      variables: {
        input: {
          startDate,
          endDate,
          sortBy: sortColumn === undefined ? 'amount' : sortColumn.column,
          sortOrder: sortColumn?.direction === 'asc' ? 1 : -1,
          status: selectedStatus.value === 'any' ? undefined : selectedStatus.value.toUpperCase(),
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.getPaypalCsv?.csv) {
      csvGenerator(data.getPaypalCsv.csv, 'Payment Report List');
    }
  };

  const handleGetPaypalOrders = async () => {
    const { data } = await getPaypalOrders({
      variables: {
        input: {
          currentPage: page,
          startDate,
          endDate,
          sortBy: sortColumn === undefined ? 'amount' : sortColumn.column,
          sortOrder: sortColumn?.direction === 'asc' ? 1 : -1,
          status: selectedStatus.value === 'any' ? undefined : selectedStatus.value.toUpperCase(),
          limit: Number(records.value),
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (data?.paypalOrdersV2 === undefined) {
      setErrorMessage('Failed to fetch Paypal Orders');
    } else {
      setTotalRecords(data.paypalOrdersV2.count);
      setTotalPages(Math.ceil(data.paypalOrdersV2.count / Number(records.value)));
      setOrders(data.paypalOrdersV2.orders);
    }
  };

  const handleChangePage = (value: any) => {
    setPage(value);
  };

  const handleSortColumn = (dataField: string, direction: any) => {
    if (sortColumn?.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn?.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const handleSetCalendarOpen = () => {
    setIsCalendarOpen(!isCalenderOpen);
  };

  const handleApplyDate = (start: Date, end: Date | undefined) => {
    handleSetCalendarOpen();
    setStartDate(start);
    if (end !== undefined) {
      setEndDate(end);
      setDisplayRange(`${start.toDateString()} - ${end.toDateString()}`);
    }
  };

  const handleClearForm = () => {
    const end = new Date(today.setHours(23 - 8, 59, 59, 999));
    const start = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    setStartDate(start);
    setEndDate(end);
    setDisplayRange(`${start.toDateString()} - ${end.toDateString()}`);
    setStatus(ORDER_STATUS[0]);
  };

  const handleSetStatus = (value: any) => {
    setStatus(value);
  };

  const handleSetRecords = (value: any) => {
    setRecordSelected(value);
  };

  useEffect(() => {
    setErrorMessage('');
    handleGetPaypalOrders();
  }, [page, records, selectedStatus, sortColumn, startDate, endDate]);

  return {
    hookSortColumn: sortColumn,

    hookErrorMessage: errorMessage,

    hookRecords: records,

    hookPage: page,

    hookStatus: selectedStatus,

    hookPaypalOrders: paypalOrders,

    hookTotalPages: totalPages,

    hookDateFilter: startDate,

    hookDisplayRange: displayRange,

    hookIsCalenderOpen: isCalenderOpen,

    hookTableLoading: paypalOrdersLoading,

    hookTotalRecords: totalRecords,

    hookCsvLoading: csvLoading,

    hookActiveTab: activeTab,

    hookHandleClearForm: handleClearForm,

    hookHandleDownloadCsv: handleDownloadCsv,

    hookOnSort: handleSortColumn,

    hookChangePage: handleChangePage,

    hookHandleSetStatus: handleSetStatus,

    hookHandleSetOrders: setOrders,

    hookHandleSetRecords: handleSetRecords,

    hookHandleAplyDate: handleApplyDate,

    hookHandleIsCalendarOpen: handleSetCalendarOpen,

    hookHandleSetTab: handelSetActiveTab,
  };
};
