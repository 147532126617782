import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';

import environment from 'config/environment';
import { initializeApp } from 'config/initializeApp';

import Router from './routes';
import { apollo } from './services';
import './styles/normalize.css';
import './styles/reset.css';
import './styles/fonts.css';
import './utils/chartsSetup';

initializeApp();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Auth0Provider
    domain={environment.auth0.customDomain}
    clientId={environment.auth0.clientId}
    issuer={environment.auth0.customDomain}
    authorizationParams={{
      redirect_uri: environment.auth0.redirectUri,
      audience: environment.auth0.audience,
      scope: environment.auth0.scope,
    }}
  >
    <ApolloProvider client={apollo}>
      <Router />
    </ApolloProvider>
  </Auth0Provider>
);
