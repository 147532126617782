import { gql } from '@apollo/client';

export const GET_FINTEL_CHECK_VISIBILITY = gql`
  query fintelCheckVisilbility($companyId: ID!) {
    company(id: $companyId) {
      id
      visibleToFintelCheck
    }
  }
`;

export type GetFintelCheckVisibilityInput = {
  companyId: string;
};

export type GetFintelCheckVisibilityOutput = {
  company: {
    id: string;
    visibleToFintelCheck: boolean;
  };
};
