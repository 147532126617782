import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';

export const WrapperStyled = styled.div`
  ${fonts.gotham.H14}
  color: ${colors.color1};
  padding: 0.8rem 1rem;
  border-radius: 50px;
  line-height: 1.5;
  background: ${colors.color3};
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const XButtonStyled = styled.button`
  background: transparent;
  border: transparent;
  font-size: 1rem;
  color: ${colors.color16};
  cursor: pointer;

  &:hover {
    color: ${colors.color1};
  }
`;
