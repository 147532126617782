import React from 'react';

import { TabWrapperStyled, TabTitleStyled } from '../../styles';
import { Table, Pagination, AdGenerateCodeModal } from '../../../../../../components';
import { PRODUCT_DETAILS } from '../../enums';

import { columns } from './Contracts/columns';

const AdsTab = ({ hook }: { hook: any }) => (
  <TabWrapperStyled>
    <TabTitleStyled>{PRODUCT_DETAILS.ADS}</TabTitleStyled>
    <Table columns={columns} data={hook.hookAds} onRowClick={hook.hookHandleOpenModal} />
    <AdGenerateCodeModal adId={hook.hookModalAdId} isOpen={hook.hookModalOpen} onClose={hook.hookHandleCloseModal} />
    <Pagination currentPage={hook.hookCurrentPage} total={hook.hookTotalPages} onPageChange={hook.hookChangePageAds} />
  </TabWrapperStyled>
);

export default AdsTab;
