import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

export const renderStartDate = (HoldStartDate: any) => {
  const currentTime = moment();
  const startTime = moment(HoldStartDate);
  const duration = moment.duration(currentTime.diff(startTime));

  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths()) % 12;
  const days = Math.floor(duration.asDays()) % 30;
  const hours = Math.floor(duration.asHours()) % 24;

  return (
    <div
      style={{
        display: 'flex',
        marginLeft: 20,
        alignSelf: 'center',
        color: months > 0 ? 'red' : 'default',
      }}
    >
      {years > 0 && (
        <>
          {years}
          &nbsp;
          {years === 1 ? 'year' : 'years'}
          &nbsp;
        </>
      )}
      {months > 0 && (
        <>
          {months}
          &nbsp;
          {months === 1 ? 'month' : 'months'}
          &nbsp;
        </>
      )}
      {days >= 0 && (
        <>
          {days}
          &nbsp;
          {days === 1 ? 'day' : 'days'}
          &nbsp;
        </>
      )}
      {hours >= 0 && (
        <>
          {hours}
          &nbsp;
          {hours === 1 ? 'hour' : 'hours'}
          &nbsp;
        </>
      )}
      {months > 0 && (
        <FontAwesomeIcon
          style={{
            marginLeft: 5,
            marginTop: 5,
            fontSize: 14,
            color: 'red',
          }}
          icon="exclamation-circle"
        />
      )}
    </div>
  );
};
