import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';

import environment from '../../config/environment';
import { imageList, getCookie, path } from '../../utils';
import { LoadingPage } from '../../components';

import * as Styled from './styles';
import * as ERROR_MESSAGES from './enums';
import { ErrorType } from './enums';

const InvalidUser = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [displayMessage, setDisplayMessage] = React.useState(ERROR_MESSAGES.UNEXPECTED_ERROR);
  const userCookie = getCookie(environment.app.cookieName);
  const decrypt: any = userCookie ? jwtDecode(userCookie) : {};

  const handleErrorToDisplay = () => {
    /* Unknown Merchant/Publisher login */
    if (
      decrypt?.merchantsId &&
      (decrypt?.accStatus === 'Closed' || decrypt?.accStatus === 'Pending' || decrypt?.userStatus === 'Pending')
    ) {
      setDisplayMessage(ERROR_MESSAGES.INVALID_COMPANY_MESSAGES);
      return;
    }
    /* Inactive or Disabled Users */
    if (decrypt?.userStatus === 'Inactive') {
      setDisplayMessage(ERROR_MESSAGES.INVALID_COMPANY_MESSAGES);
      return;
    }
    /* Publisher signed up and their company isn't approved yet */
    if (!state) {
      setDisplayMessage(ERROR_MESSAGES.INVALID_USER_MESSAGES);
      return;
    }
    /* Other scenarios sent from /callback - SSO, Unexpected */
    const error = state?.error as ErrorType;
    setDisplayMessage(error || ERROR_MESSAGES.UNEXPECTED_ERROR);
  };

  useEffect(() => {
    handleErrorToDisplay();
  }, []);

  if (!displayMessage) return <LoadingPage />;
  return (
    <Styled.WrapperStyled>
      <Styled.LeftStyled>
        <figure>
          <img src={imageList.logoFull.src} alt="logo" />
        </figure>
      </Styled.LeftStyled>
      <Styled.RightStyled>
        <Styled.RightContentStyled>
          <h1>{displayMessage.TITLE}</h1>
          <span>
            {displayMessage.PART1}
            {displayMessage.PART2.length > 0 && (
              <>
                <a href="mailto:info@fintelconnect.com">{ERROR_MESSAGES.EMAIL_MESSAGE}</a>
                {displayMessage.PART2}
              </>
            )}
          </span>
          <figure>
            <img src={imageList.lightHousePlaceholder.src} alt={imageList.lightHousePlaceholder.alt} />
          </figure>

          {displayMessage === ERROR_MESSAGES.INVALID_SSO_ACCOUNT_CREATION ? (
            <Styled.ButtonContainerStyled>
              <Styled.ButtonSignUpStyled
                theme="secondary"
                type="button"
                onClick={() => navigate(path.createAccountStep1.href)}
              >
                {ERROR_MESSAGES.PLACEHOLDER_SIGN_UP}
              </Styled.ButtonSignUpStyled>
              <Styled.ButtonSignUpStyled theme="secondary" type="button" onClick={() => navigate(path.login.href)}>
                {ERROR_MESSAGES.PLACEHOLDER_LOGIN}
              </Styled.ButtonSignUpStyled>
            </Styled.ButtonContainerStyled>
          ) : (
            <Styled.ButtonStyled theme="secondary" type="button" onClick={() => navigate(path.login.href)}>
              {ERROR_MESSAGES.PLACEHOLDER_BACK}
            </Styled.ButtonStyled>
          )}
        </Styled.RightContentStyled>
      </Styled.RightStyled>
    </Styled.WrapperStyled>
  );
};

export default InvalidUser;
