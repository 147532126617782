import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../../components';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const IconStyled = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;
