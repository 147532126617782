import React from 'react';

import * as Styled from './styles';

type FormatedCellProps = {
  value: any;
  secondValue?: any;
  children?: any;
  link?: string;
  capitalize?: boolean;
};

export const FormatedCell = ({ value, secondValue, children, link, capitalize }: FormatedCellProps) => (
  <Styled.CellWrapperStyled capitalize={capitalize}>
    {value}
    <Styled.SpaceBetween>{children}</Styled.SpaceBetween>
    {secondValue && (
      <Styled.SecondLineStyled isLink={link !== undefined}>
        {link ? (
          <a href={link} target="_blank" rel="noreferrer">
            {secondValue}
          </a>
        ) : (
          secondValue
        )}
      </Styled.SecondLineStyled>
    )}
  </Styled.CellWrapperStyled>
);
