import styled from 'styled-components';

import { colors } from '../../styles/theme';

export const ProgressStyled = styled.div`
  margin-top: 5rem;
  width: 280px;
`;

export const StepStyled = styled.span`
  text-transform: uppercase;
  margin: 0 2rem 0 1rem;
`;

export const CurrentStepStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.color4};
`;

export const OtherStepsStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.color19};
`;

export const CheckpointInnerStyled = styled.div`
  border: 2px solid ${colors.color2};
  border-radius: 12px;
  width: 22px;
  height: 22px;
`;

export const CheckpointStyled = styled.div`
  background-color: ${colors.color2};
  border: 4px solid ${colors.color1};
  border-radius: 10px;
  width: 18px;
  height: 18px;
`;

export const CheckpointPathWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 25px;
  margin: 15px 8px;
`;

export const CheckpointPathStyled = styled.div`
  background-color: ${colors.color19};
  border-radius: 5px;
  width: 4px;
  height: 4px;
`;

export const NextCheckpointStyled = styled.div`
  background-color: ${colors.color19};
  border-radius: 10px;
  width: 14px;
  height: 14px;
  margin: 10px 1px 10px 4px;
`;
