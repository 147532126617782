import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      companyImgUrl
      companyName
      companyType
      address1
      address2
      city
      state
      country
      zip
      phone
      phone2
      companyEmail
      companyUrl
      accountStatus
      networkStatus
      createdAt
      activityStatus
      merchantType
      paymentInfo {
        email
        tax
        gst
        pst
        hst
        taxStatus
        gstStatus
        pstStatus
        hstStatus
        accountEmail
      }
      accountStatusHistory {
        status
        date
      }
    }
  }
`;
