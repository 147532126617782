import styled from 'styled-components';

import { Button, InputText, Select, Tooltip } from '../../../../components';
import { fonts, colors } from '../../../../styles/theme';

export const InputTextStyled = styled(InputText)`
  margin-bottom: 2rem;
`;

export const SelectStyled = styled(Select)`
  margin-bottom: 2rem;
  width: 450px;
`;

export const SpecialFieldStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const TooltipStyled = styled(Tooltip)`
  margin-bottom: 2rem;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const HeaderButtonAreaStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonAreaStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 0.5rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const CopiedStyled = styled.p`
  ${fonts.lato.H14};
  color: ${colors.color2};
  margin-bottom: 2rem;
`;
