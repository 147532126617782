import { gql } from '@apollo/client';

export const LIST_OPTIONS = gql`
  query Products($programId: String) {
    products(programId: $programId) {
      products {
        customizedProductId
      }
    }
  }
`;
