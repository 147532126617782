import { useState } from 'react';

import { Permission } from '../../../../../entities';

import { useTrackingDomains } from './useTrackingDomains';
import { useTrackingProfiles } from './useTrackingProfiles';

export const useTrackingDetails = (permissionsCodeList: string[] = []) => {
  const [downloadButton, setDownloadButton] = useState(false);
  const trackingProfiles = useTrackingProfiles();
  const trackingDomains = useTrackingDomains();

  const setDownloadButtonHandler = (show: boolean) => {
    setDownloadButton(show);
  };

  return {
    hookTrackingProfile: trackingProfiles,
    hookTrackingDomains: trackingDomains,
    hookDownloadButton: downloadButton,
    hookSetDownloadButton: setDownloadButtonHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
