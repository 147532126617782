import React from 'react';

import { Checkbox, FixedFooter, InputText, PageTitle } from '../../../components';
import { ConfirmationModal } from '../../../components/Modal/ConfirmationModal';
import { DefaultPropsType } from '../../../types';

import { usePublisherInvites } from './hooks';
import { PUBLISHER_INVITES_PAGE as ENUMS } from './enums';
import * as Styled from './styles';

type PublisherInvitesManageProps = DefaultPropsType;

const PublisherInvitesManage = ({ permissionsCodeList = [] }: PublisherInvitesManageProps) => {
  const { hookIsReadOnlyList, ...hook } = usePublisherInvites(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <Styled.AppWrapperStyled permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <Styled.TopContainerStyled>
          <PageTitle>{ENUMS.PAGETITLE}</PageTitle>
        </Styled.TopContainerStyled>

        <Styled.ContentStyled>
          <Styled.InviteLimitStyled>
            <Styled.MerchantTypeStyled>
              {ENUMS.SELF_SERVE_MERCHANT.TITLE}
              <Styled.ToolTipStyled text={ENUMS.SELF_SERVE_MERCHANT.TOOLTIP} />
            </Styled.MerchantTypeStyled>

            <Styled.SetLimitStyled>
              <Checkbox
                checked={hook.hookSelfServeMerchantLimitBoolean}
                partial={false}
                onChange={hook.hookHandleSelfServeMerchantLimitBoolean}
                label={ENUMS.SELF_SERVE_MERCHANT.CHECKBOX_LABEL}
                disabled={isReadOnly}
              />
            </Styled.SetLimitStyled>
            <Styled.CollaspeSectionStyled isVisible={hook.hookSelfServeMerchantLimitBoolean}>
              <Styled.InputWrapper>
                <InputText
                  label={ENUMS.SELF_SERVE_MERCHANT.INPUT_LABEL}
                  name={ENUMS.SELF_SERVE_MERCHANT.INPUT_NAME}
                  type="number"
                  value={hook.hookSelfServeMerchantLimitAmount}
                  onChange={hook.hookHandleSelfServeMerchantLimitAmount}
                  placeholder={ENUMS.SELF_SERVE_MERCHANT.INPUT_PLACEHOLDER}
                  required
                  onKeyPress={hook.hookHandleOnKeyPressNumberField}
                  disabled={isReadOnly}
                />
              </Styled.InputWrapper>
            </Styled.CollaspeSectionStyled>
          </Styled.InviteLimitStyled>

          <Styled.InviteLimitStyled>
            <Styled.MerchantTypeStyled>
              {ENUMS.RESULTS_MERCHANT.TITLE}
              <Styled.ToolTipStyled text={ENUMS.RESULTS_MERCHANT.TOOLTIP} />
            </Styled.MerchantTypeStyled>

            <Styled.SetLimitStyled>
              <Checkbox
                checked={hook.hookResultsMerchantLimitBoolean}
                partial={false}
                onChange={hook.hookHandleResultsMerchantLimitBoolean}
                label={ENUMS.RESULTS_MERCHANT.CHECKBOX_LABEL}
                disabled={isReadOnly}
              />
            </Styled.SetLimitStyled>
            <Styled.CollaspeSectionStyled isVisible={hook.hookResultsMerchantLimitBoolean}>
              <Styled.InputWrapper>
                <InputText
                  label={ENUMS.RESULTS_MERCHANT.INPUT_LABEL}
                  name={ENUMS.RESULTS_MERCHANT.INPUT_NAME}
                  type="number"
                  value={hook.hookResultsMerchantLimitAmount}
                  onChange={hook.hookHandleResultsMerchantLimitAmount}
                  placeholder={ENUMS.RESULTS_MERCHANT.INPUT_PLACEHOLDER}
                  required
                  onKeyPress={hook.hookHandleOnKeyPressNumberField}
                  disabled={isReadOnly}
                />
              </Styled.InputWrapper>
            </Styled.CollaspeSectionStyled>
          </Styled.InviteLimitStyled>

          <Styled.InviteLimitStyled>
            <Styled.MerchantTypeStyled>
              {ENUMS.MANAGED_MERCHANT.TITLE}
              <Styled.ToolTipStyled text={ENUMS.MANAGED_MERCHANT.TOOLTIP} />
            </Styled.MerchantTypeStyled>

            <Styled.SetLimitStyled>
              <Checkbox
                checked={hook.hookManagedMerchantLimitBoolean}
                partial={false}
                onChange={hook.hookHandleMangedMerchantLimitBoolean}
                label={ENUMS.MANAGED_MERCHANT.CHECKBOX_LABEL}
                disabled={isReadOnly}
              />
            </Styled.SetLimitStyled>
            <Styled.CollaspeSectionStyled isVisible={hook.hookManagedMerchantLimitBoolean}>
              <Styled.InputWrapper>
                <InputText
                  label={ENUMS.MANAGED_MERCHANT.INPUT_LABEL}
                  name={ENUMS.MANAGED_MERCHANT.INPUT_NAME}
                  type="number"
                  value={hook.hookManagedMerchantLimitAmount}
                  onChange={hook.hookHandleMangedMerchantLimitAmount}
                  placeholder={ENUMS.MANAGED_MERCHANT.INPUT_PLACEHOLDER}
                  required
                  onKeyPress={hook.hookHandleOnKeyPressNumberField}
                  disabled={isReadOnly}
                />
              </Styled.InputWrapper>
            </Styled.CollaspeSectionStyled>
          </Styled.InviteLimitStyled>
        </Styled.ContentStyled>

        <FixedFooter>
          <Styled.FooterButtonStyled
            onClick={() => hook.hookHandleSaveChangesModal.hookSetIsOpen(true)}
            disabled={isReadOnly}
          >
            {ENUMS.FOOTER_BUTTON_TEXT}
          </Styled.FooterButtonStyled>
        </FixedFooter>
      </Styled.WrapperStyled>
      <ConfirmationModal
        title={ENUMS.DISCARD_CHANGES_MODAL.TITLE}
        desc={ENUMS.DISCARD_CHANGES_MODAL.DESC}
        isOpen={hook.hookHandleDiscardChangesModal.hookIsOpen}
        onCloseText={ENUMS.DISCARD_CHANGES_MODAL.ON_CLOSE_BUTTON_TEXT}
        onConfirmText={ENUMS.DISCARD_CHANGES_MODAL.ON_CONFIRM_BUTTON_TEXT}
        onClose={hook.hookHandleDiscardChangesModal.hookOnClose}
        onConfirm={hook.hookHandleDiscardChangesModal.hookOnConfirm}
      />
      <ConfirmationModal
        title={ENUMS.SAVE_CHANGES_MODAL.TITLE}
        desc={ENUMS.SAVE_CHANGES_MODAL.DESC}
        isOpen={hook.hookHandleSaveChangesModal.hookIsOpen}
        onCloseText={ENUMS.SAVE_CHANGES_MODAL.ON_CLOSE_BUTTON_TEXT}
        onConfirmText={ENUMS.SAVE_CHANGES_MODAL.ON_CONFIRM_BUTTON_TEXT}
        onClose={hook.hookHandleSaveChangesModal.hookOnClose}
        onConfirm={hook.hookHandleSaveChangesModal.hookOnConfirm}
      />
    </Styled.AppWrapperStyled>
  );
};

export default PublisherInvitesManage;
