import React, { useMemo, useState } from 'react';

import { MerchantAccessProps, CompanyType } from '../types';

export const useMerchantAccessInternal = ({
  merchantList,
  checkedMerchants,
  selectedMerchants,
  handleSetSelectedMerchants,
  handleSetCheckedMerchants,
}: MerchantAccessProps) => {
  /* Value used to filter list of displayed merchants. */
  const [searchValue, setSearchValue] = useState<string>('');
  /* List of First Letters of displayed merchants. */
  const [firstLetterList, setFirstLetterList] = useState<string[]>([]);
  /* Controls display of Add to Merchant List Modal. */
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  /** List of Merchants to be displayed on left side. */
  const merchantListState = useMemo(() => {
    // Generate the list of displayed Merchants.
    let cleanedList = merchantList.filter(({ id }) => !selectedMerchants.find((item) => item.id === id));
    if (searchValue !== '') {
      cleanedList = cleanedList.filter((item) => item.companyName.toLowerCase().includes(searchValue.toLowerCase()));
    }

    // Generate the list of letter headings.
    const firstLetter: string[] = [];
    cleanedList.forEach((merchant) => {
      const letter = merchant.companyName.charAt(0).toUpperCase();
      if (!firstLetter.includes(letter)) {
        firstLetter.push(letter);
      }
    });
    const firstLetterSorted = firstLetter.sort((a, b) => a.localeCompare(b));
    setFirstLetterList(firstLetterSorted);

    return cleanedList;
  }, [merchantList, searchValue, selectedMerchants]);

  /** Updates displayed list to filter out by searchValue. */
  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  /** Remove one from Selected Merchants list. */
  const onRemoveOneHandler = (id: string) => {
    const filteredList = selectedMerchants.filter((item) => item.id !== id);
    handleSetSelectedMerchants(filteredList);
  };

  /** Add one to Checked Merchants list. */
  const addOrRemoveOneToCheckedHandler = (company: CompanyType) => {
    if (checkedMerchants.find((item) => item.id === company.id)) {
      handleSetCheckedMerchants(checkedMerchants.filter((item) => item.id !== company.id));
    } else {
      handleSetCheckedMerchants([...checkedMerchants, company]);
    }
  };

  return {
    hookSearchValue: searchValue,
    hookFirstLetterList: firstLetterList,
    hookIsModalOpen: isModalOpen,
    hookSetIsModalOpen: setIsModalOpen,

    hookMerchantListState: merchantListState,
    hookOnSearchHandler: onSearchHandler,
    hookOnRemoveOneHandler: onRemoveOneHandler,
    hookAddOrRemoveOneToCheckedHandler: addOrRemoveOneToCheckedHandler,
  };
};
