import { rangeFormat } from '../../../utils';

// initializes the dates on calender for admin report
export const ADMIN_DATE_RANGE_INIT_RAW = {
  startDate: rangeFormat('last7Days').start,
  endDate: new Date(),
};

export const REPORT = {
  INVISIBLE_DATA: {
    TITLE: 'Set Invisible',
    VISIBILITY_NAME: 'invisible',
    LIST: ['The Fintel Check will be hidden for the merchant.'],
    BUTTON_NAME: 'Set Invisible',
  },
  VISIBLE_DATA: {
    TITLE: 'Set Visible',
    VISIBILITY_NAME: 'visible',
    LIST: [
      'Merchant will be able to access the Fintel Check immediately.',
      'if Admin has used Fintel Check for internal user, all the settings and history reports will be reset.',
    ],
    BUTTON_NAME: 'Set Visible',
  },
};

export const SUMMARY = {
  TITLE: 'Admin Summary Report',
  LAST_CHECK: 'Last Check:',
  MAX_DOWNLOAD: 'Maximum download size: 3000',
  DOWNLOAD_ALL: 'Download All Screenshots',
  DOWNLOAD_CSV: 'Download CSV',
  CLEAR_FORM: 'Clear Form',
  GENERATE_REPORT: 'Generate Report',
};

export const CSV_FILE_NAME = (dateString: string) => `Fintel Check Summary Report - ${dateString}`;

export const ALL_PRODUCTS = 'All Products';
export const ALL_STATUSES = 'All Statuses';
export const NO_PRODUCTS = 'No Products';

export const CHECKDATE_DEFAULT_OPTIONS: {
  label: any;
  value: string;
  publisherCount: number;
}[] = [];
export const PUBLISHER_DEFAULT_OPTIONS = () => [
  {
    label: 'All Publishers',
    value: '',
  },
  {
    label: 'Manual URL List',
    value: 'mu',
  },
];

export const MERCHANT_DEFAULT_OPTIONS = () => [
  {
    label: 'All Merchants',
    value: '',
  },
];

export const CATEGORY_DEFAULT_OPTIONS: SelectOption[] = [
  {
    label: 'All Categories',
    value: '',
  },
];
export const PRODUCT_DEFAULT_OPTIONS = [
  {
    label: ALL_PRODUCTS,
    value: ALL_PRODUCTS,
  },
  {
    label: NO_PRODUCTS,
    value: '',
  },
];
export const STATUS_DEFAULT_OPTIONS = [
  {
    label: ALL_STATUSES,
    value: ALL_STATUSES,
  },
  {
    label: 'Pass',
    value: 'Pass',
  },
  {
    label: 'Review',
    value: 'Review',
  },
  {
    label: 'Fail',
    value: 'Fail',
  },
  {
    label: 'Incomplete',
    value: 'Incomplete',
  },
  {
    label: 'Not Applicable',
    value: 'Not Applicable',
  },
];

export const SORT_COLUMN_NAMES: { [key: string]: string } = {
  publisher: 'publisherId',
  page: 'pageCount',
  pass: 'passCount',
  incomplete: 'incompleteCount',
  review: 'reviewCount',
  fail: 'failCount',
  notApplicable: 'nfCount',
};

export const SUMMARY_REPORT_CSV_COLUMNS = [
  { dataField: 'publisher', text: 'Publisher' },
  { dataField: 'pageCount', text: 'Page' },
  { dataField: 'passCount', text: 'Pass' },
  { dataField: 'reviewCount', text: 'Review' },
  { dataField: 'failCount', text: 'Fail' },
  { dataField: 'incompleteCount', text: 'Incomplete' },
  { dataField: 'nfCount', text: 'Not Applicable' },
];

export const SUMMARY_REPORT_ADMIN_CSV_COLUMNS = [
  { dataField: 'merchant', text: 'Merchant' },
  { dataField: 'publisher', text: 'Publisher' },
  { dataField: 'checkdate', text: 'Run Date' },
  { dataField: 'pageCount', text: 'Page' },
  { dataField: 'passCount', text: 'Pass' },
  { dataField: 'reviewCount', text: 'Review' },
  { dataField: 'failCount', text: 'Fail' },
  { dataField: 'incompleteCount', text: 'Incomplete' },
  { dataField: 'nfCount', text: 'Not Applicable' },
];
