export const MEMBERSHIPS = {
  PAGE_TITLE: 'Merchant Memberships Overview',
  APPLICATIONS: {
    TITLE: 'Here you can take action on pending invitations from active merchants to join their programs.',
    FILTERS: 'Filters',
    CLEAR_FORM: 'Clear Form',
    NO_DATA: {
      TITLE: 'No records found',
      SUBTITLE:
        'When you receive new invitations from active merchants to join their programs, they will show up here.',
    },
    PLACEHOLDER: {
      ALL_STATUSES: 'All Statuses',
      TARGET_MARKET: 'Target Market',
      PRODUCT_CATEGORY: 'Product Category',
    },
  },
};

export const formatMemberships = (memberships: any[]) => {
  if (!Array.isArray(memberships)) return [];

  // formatting membership
  return memberships.map(({ id, publisherInvitation, program, createdAt, status, statusLastUpdatedBy }) => {
    const categories: any[] = [];
    const markets: any[] = [];
    if (Array.isArray(program?.products)) {
      const { products } = program;

      // loop by products
      products.forEach(({ productCategory, targetDemographics }: { productCategory: any; targetDemographics: any }) => {
        // categories
        if (productCategory && !categories.includes(productCategory)) {
          categories.push(productCategory);
        }

        // markets
        if (targetDemographics?.geographicalCountry && !markets.includes(targetDemographics.geographicalCountry)) {
          markets.push(targetDemographics.geographicalCountry);
        }
      });
    }

    return {
      id,
      programId: program?.id,
      companyName: program?.merchant?.companyName,
      companyUrl: program?.merchant?.companyUrl,
      companyImageUrl: program?.merchant?.companyImgUrl,
      publisherInvitation,
      categories,
      markets,
      invited: createdAt,
      status,
      statusLastUpdatedBy,
    };
  });
};

export const TOAST_MESSAGE = "All invites are now on hold. Choose 'Open to Invites' to receive invitations again.";

export const handleMarkets = (value: any[]) => {
  const marketListV2: any[] = [];
  marketListV2.push({ label: 'All Markets', value: '' });
  value.forEach((val: any) => {
    if (val.markets !== undefined) {
      val.markets.forEach((market: any) => {
        if (!marketListV2.find((obj: any) => obj.label === market)) {
          marketListV2.push({ label: market, value: '' });
        }
      });
    }
  });
  return marketListV2;
};

export const handleCategoriesList = (memberships: any[]) => {
  const options = memberships.reduce((sum, membership) => [...sum, ...membership.categories], []);
  const uniqueOptions: any[] = [];
  const categoryListV2: any[] = [];
  categoryListV2.push({ label: 'All Categories', value: '' });
  options.forEach((item: any) => {
    if (!uniqueOptions.includes(item)) {
      uniqueOptions.push(item);
      categoryListV2.push({ label: item, value: item });
    }
  });
  return categoryListV2;
};

export const optionsForInvites: any = {
  true: { label: 'Invites on Hold', value: true },
  false: { label: 'Open to Invites', value: false },
};

export const COMPARE = {
  invited: 'invited',
  id: 'id',
  publisherInvitation: 'publisherInvitation',
};
