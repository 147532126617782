import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import * as Styled from './styles';

type SpinnerProps = {
  theme?: Theme;
  size?: string;
  className?: string;
};

export const Spinner = ({ theme = 'primary', size = '1rem', className }: SpinnerProps) => (
  <Styled.WrapperStyled theme={theme} size={size} className={className}>
    <FontAwesomeIcon icon={faSpinner} />
  </Styled.WrapperStyled>
);
