import { gql } from '@apollo/client';

export const GET_MEMBERSHIPS = gql`
  query Query($input: MembershipSearchInput!) {
    memberships(input: $input) {
      memberships {
        publisherGroups {
          id
        }
      }
    }
  }
`;
