import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { subCategories, ERROR_TYPES, optionsMaker, paginator, PUBLISHER_PREFIX, path } from '../../../../../utils';
import { useValidation } from '../../../../../utils/validation';
import { PRODUCT_FEED } from '../enums';
import { GET_COMPANIES } from '../graphql/getCompany';
import { useUserInfo } from '../../../../../hooks';
import { GET_COUNTRIES } from '../../../../../hooks/graphql/queries';
import { CREATE_PRODUCT_FEED } from '../graphql/createNewProductFeed';
import { Permission } from '../../../../../entities';
import { compareProductFeed } from '../utils';

type ProductOption = {
  label: string;
  value: string;
  merchantName: string;
};

type ProductFeedOption = {
  trackingProfileOptions: string[];
  merchantOptions: string[];
  productOptions: ProductOption[];
  productFeeds: any[];
};

type Tracking = {
  id: string;
  profileName?: string;
  primaryWebsite?: string;
  websiteDescription?: string;
  productFeeds?: any[];
};

type Membership = {
  merchant: {
    id: string;
    companyName: string;
  };
  program: {
    products: {
      customizedProductId: string;
      id: string;
      productFeedSection: {
        legalReference: any;
      };
    }[];
  };
  status: string;
};

const formatProductFeedOptions = (company: { trackings: Tracking[]; memberships: Membership[] }): ProductFeedOption => {
  const { trackings, memberships } = company;
  const merchantOptions: string[] = [];
  const productOptions: ProductOption[] = [];
  const productFeeds: any[] = [];
  const trackingProfileOptions: string[] = [];

  trackings.forEach((tracking) => {
    let trackingOption: string;
    if (tracking.profileName) {
      trackingOption = `${tracking.id} - ${tracking.profileName}`;
    } else {
      trackingOption = tracking.primaryWebsite
        ? `${tracking.id} - ${tracking.primaryWebsite}`
        : `${tracking.id} - ${tracking.websiteDescription}`;
    }
    trackingProfileOptions.push(trackingOption);

    if (tracking.productFeeds && tracking.productFeeds.length > 0) {
      tracking.productFeeds.forEach((productFeed) => productFeeds.push(productFeed));
    }
  });

  memberships.forEach((membership) => {
    const {
      merchant,
      program: { products },
      status,
    } = membership;

    if (status === 'Approved') {
      merchantOptions.push(`${merchant.id} - ${merchant.companyName}`);
      products.forEach((product) => {
        if (product.productFeedSection && product.productFeedSection.legalReference !== null) {
          productOptions.push({
            label: `${product.customizedProductId}`,
            value: product.id,
            merchantName: `${merchant.id} - ${merchant.companyName}`,
          });
        }
      });
    }
  });

  return {
    trackingProfileOptions,
    merchantOptions,
    productOptions,
    productFeeds,
  };
};

export const useProductFeed = (permissionsCodeList: string[] = []) => {
  const [getCompanies, { loading: getCompaniesLoading }] = useLazyQuery(GET_COMPANIES);
  const currentUser = useUserInfo();
  const [getCountries, { loading: getCountriesLoading }] = useLazyQuery(GET_COUNTRIES);

  const [trackingProfileIdState, setTrackingProfileId] = useState({ label: '', value: '' });
  const [marketCountry, setMarketCountry] = useState<SelectOption>();
  const [statesSelected, setStates] = useState<SelectOption[]>([]);
  const [merchantsSelected, setMerchants] = useState<SelectOption[]>([]);
  const [productIds, setProductIds] = useState<SelectOption[]>([]);
  const [merchantOptionsState, setMerchantOptions] = useState<SelectOption[]>([]);
  const [productIdOptions, setProductIdOptions] = useState<SelectOption[]>();
  const [productFeedUrl, setProductFeedUrl] = useState('');
  const [xmlChecked, setXmlChecked] = useState(true);
  const [jsonChecked, setJsonChecked] = useState(false);
  const [copyUrl, setCopyUrl] = useState('Copy Url');
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [productCategory, setProductCategory] = useState<SelectOption[]>([]);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [subCategoriesSelected, setSubCategories] = useState<SelectOption[]>([]);
  const [memberships, setMemberships] = useState<any[]>([]);
  const [allProductFeeds, setAllProductFeeds] = useState<any[]>([]);
  const [allSubs, setAllSubs] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPage, setTotalPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const navigate = useNavigate();

  // Validations
  const validationHook = useValidation();
  const [feedErrors, setFeedErrrors] = useState<{ [key: string]: string }>({});
  const [trackingError, setTrackingError] = useState<{ [key: string]: string }>({});
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createProductFeed, { loading: createLoading }] = useMutation(CREATE_PRODUCT_FEED);

  const [trackingOptions, setTrackingOptions] = useState<any>();

  const [productFeedsState, setPaginatedFeeds] = useState<any[]>([]);

  const handleChangePage = (value: any) => {
    setCurrentPage(value);
    setPaginatedFeeds(paginator(allProductFeeds, 6, value));
  };

  const handleGetCountries = async () => {
    const { data } = await getCountries();
    if (data.getCountries?.countries !== undefined) {
      setCountriesOptions(optionsMaker(data.getCountries?.countries.map((c: any) => c.name)));
      setAllSubs(data.getCountries.countries);
    } else {
      setIsError(true);
      setErrorMessage('Failed to fetch countries');
    }
  };

  const handleCompanies = async () => {
    await handleGetCountries();
    const { data } = await getCompanies({
      variables: {
        companyId: currentUser.hookWhoAmI.companyId,
      },
      fetchPolicy: 'no-cache',
    });
    if (data.company !== undefined) {
      const { trackingProfileOptions, merchantOptions, productOptions, productFeeds } = formatProductFeedOptions(
        data.company
      );
      setTrackingOptions(optionsMaker(trackingProfileOptions));
      setMerchantOptions(optionsMaker(merchantOptions));
      setProductIdOptions(productOptions);
      setPaginatedFeeds(paginator(productFeeds, 6, 1));
      setAllProductFeeds(productFeeds);
      setMemberships(data.company.memberships);
      setTotalPage(Math.ceil(productFeeds.length / 6));
      setCurrentPage(1);
    } else {
      setIsError(true);
      setErrorMessage('Failed to fetch company');
    }
  };

  const idOptionsMaker = (merchantSelected: SelectOption[] | undefined) => {
    if (merchantSelected !== undefined) {
      const m: any[] = [];
      merchantSelected.forEach((element: SelectOption) => {
        m.push(element.label.slice(0, 5));
      });
      const customizedIds: any[] = [];
      memberships.forEach((mem) => {
        if (m.includes(mem.merchant.id)) {
          mem.program.products.forEach((element: any) => {
            customizedIds.push({ label: element.customizedProductId, value: element.customizedProductId });
          });
        }
      });
      return customizedIds;
    }
    return [];
  };

  const subCategoriesMaker = (categories: SelectOption[]) => {
    let subCat: any = [];
    categories.forEach((category: any) => {
      subCat = subCat.concat(subCategories(category.label));
    });
    return optionsMaker(subCat);
  };

  const feedMaker = () => {
    if (marketCountry !== undefined) return [marketCountry].map(({ label }) => label);
    if (merchantsSelected.length !== 0) return merchantsSelected.map(({ label }) => label);
    if (productCategory.length !== 0) return productCategory.map(({ label }) => label);
    return [];
  };

  const handleValidation = () => {
    const valdationArrayValues = {
      feed: feedMaker(),
    };
    const arrayValidationfields = {
      feed: ERROR_TYPES.AT_LEAST_ONE,
    };
    const tracking = {
      tracking: trackingProfileIdState.value,
    };
    const trackingFields = {
      tracking: ERROR_TYPES.NOT_EMPTY,
    };
    const atLeastOne = validationHook.validateArray(valdationArrayValues, arrayValidationfields, setFeedErrrors);
    const trackingSelected = validationHook.validateAll(tracking, trackingFields, setTrackingError, true);
    return atLeastOne && trackingSelected;
  };

  const handleProductCategory = (value: SelectOption[]) => {
    setIsError(false);
    setSubCategoriesOptions(subCategoriesMaker(value));
    setProductCategory(value);
  };

  const handleSubCategories = (value: SelectOption[]) => {
    setIsError(false);
    setSubCategories(value);
  };

  const handleTrackingProfileId = (value: SelectOption) => {
    setIsError(false);
    setTrackingProfileId(value);
  };

  const handleMarketingCountry = (value: SelectOption) => {
    setStates([]);
    setIsError(false);
    setMarketCountry(value);
    setStateOptions(
      optionsMaker(allSubs.find((obj: any) => obj.name === value.label).subdivisions.map((obj: any) => obj.name))
    );
  };

  const handleStates = (value: SelectOption[]) => {
    setIsError(false);
    setStates(value);
  };

  const handleMerchants = (value: SelectOption[]) => {
    setIsError(false);
    setProductIdOptions(idOptionsMaker(value));
    setMerchants(value);
  };

  const handleProductIds = (value: SelectOption[]) => {
    setIsError(false);
    setIsError(false);
    setProductIds(value);
  };

  const handleChecks = () => {
    setXmlChecked(!xmlChecked);
    setJsonChecked(!jsonChecked);
  };

  const handleGenerateUrl = async () => {
    if (handleValidation()) {
      const input = {
        trackingProfileId: trackingProfileIdState.label.split(' - ')[0],
        merchantIds: merchantsSelected.map((x) => x.label.split(' - ')[0]),
        productIds: productIds.map((x) => x.value),
        productCategories: productCategory.map((x) => x.label),
        subCategories: subCategoriesSelected.map((x: any) => x.label),
        regionCountry: marketCountry?.label === 'All Countries' ? undefined : marketCountry?.label,
        regionStates: statesSelected.map((x) => x.label),
      };
      let hasExactSame = false;
      allProductFeeds.forEach((productFeed) => {
        const { trackingProfileId, merchants, products = [] } = productFeed;
        if (
          input.trackingProfileId === trackingProfileId &&
          input.merchantIds.sort().join() ===
            merchants
              .map((merchant: any) => merchant.id)
              .sort()
              .join() &&
          (input.productIds.sort().join() ===
            products
              .map((product: any) => product.id)
              .sort()
              .join() ||
            input.productIds.length === 0) &&
          input.productCategories.sort().join() === productFeed.productCategories.sort().join() &&
          input.subCategories.sort().join() === productFeed.subCategories.sort().join() &&
          input.regionCountry === productFeed.regionCountry &&
          input.regionStates.sort().join() === productFeed.regionStates.sort().join()
        )
          hasExactSame = true;
      });
      if (hasExactSame) {
        setIsError(true);
        setErrorMessage('There is a same product feed already');
      } else {
        const { data } = await createProductFeed({
          variables: {
            input,
          },
          fetchPolicy: 'no-cache',
        });
        if (data !== undefined) {
          if (jsonChecked) {
            setProductFeedUrl(`${data.createProductFeed.productFeedUrl}?format=json`);
          } else {
            setProductFeedUrl(data.createProductFeed.productFeedUrl);
          }
          handleCompanies();
        }
      }
    } else {
      setErrorMessage(PRODUCT_FEED.AT_LEAST_ONE);
      setIsError(true);
      if (trackingError.tracking) setErrorMessage(PRODUCT_FEED.TRACKING_PROFILE_ERROR);
      setIsError(true);
    }
  };

  const handleCopyUrl = () => {
    setIsError(false);
    navigator.clipboard.writeText(productFeedUrl);
    setCopyUrl('Copied Url');
  };

  const handleRowClick = (row: any) => {
    navigate(`${PUBLISHER_PREFIX}${path.productFeedDetails.href}`, { state: row.id });
  };

  const onSortHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    const newDirection = direction === 'desc' ? 'asc' : 'desc';
    setSortColumn({
      column,
      direction: newDirection,
    });
    const sortedData = [...allProductFeeds].sort(compareProductFeed(column, direction));
    setAllProductFeeds(sortedData);
    setPaginatedFeeds(paginator(sortedData, 6, currentPage));
  };

  const handleClearForm = () => {
    setIsError(false);
    setTrackingProfileId({ label: '', value: '' });
    setMarketCountry({ label: 'All Countries', value: 'All Countries' });
    setStates([]);
    setMerchants([]);
    setProductIdOptions([]);
    setProductIds([]);
    setProductFeedUrl('');
    setXmlChecked(true);
    setJsonChecked(false);
    setCopyUrl('Copy Url');
    setSubCategories([]);
    setProductCategory([]);
  };

  useEffect(() => {
    handleCompanies();
  }, []);

  useEffect(() => {
    setIsError(false);
  }, [productCategory, subCategoriesSelected, trackingProfileIdState, marketCountry, statesSelected, productIds]);

  return {
    hookProductFeeds: productFeedsState,

    hookPage: currentPage,
    hookTotalPage: totalPage,
    hookChangePage: handleChangePage,

    hookTrackingProfileId: trackingProfileIdState,
    hookTrackingOptions: trackingOptions,
    hookChangeTrackingProfileId: handleTrackingProfileId,

    hookMarketCountry: marketCountry,
    hookCountryOptions: countriesOptions,
    hookChangeMarketCountry: handleMarketingCountry,

    hookStates: statesSelected,
    hookStatesOptions: stateOptions,
    hookChangeStates: handleStates,

    hookMerchants: merchantsSelected,
    hookMerchantOptions: merchantOptionsState,
    hookChangeMerchants: handleMerchants,

    hookProductIds: productIds,
    hookProductIdOptions: productIdOptions,
    hookChangeProductIds: handleProductIds,

    hookProductFeedUrl: productFeedUrl,

    hookXmlChecked: xmlChecked,
    hookJsonChecked: jsonChecked,
    hookChangeChecked: handleChecks,

    hookCopyUrl: copyUrl,
    hookHandleCopyUrl: handleCopyUrl,
    hookHandleGenerateUrl: handleGenerateUrl,

    hookHandleClearForm: handleClearForm,

    hookProductCategory: productCategory,
    hookChangeProductCategory: handleProductCategory,

    hookSubCategories: subCategoriesSelected,
    hookChangeSub: handleSubCategories,
    hookSubCategoryOptions: subCategoriesOptions,

    hookTrackingError: trackingError,
    hookFeedErrors: feedErrors,
    hookIsError: isError,
    hookErrorMessage: errorMessage,

    loading: getCompaniesLoading || getCountriesLoading,

    hookCreateLoading: createLoading,

    hookSortColumn: sortColumn,
    hookOnSortHandler: onSortHandler,
    hookOnRowClick: handleRowClick,
    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
