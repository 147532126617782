import { gql } from '@apollo/client';

export const GET_MANUAL = gql`
  query ManualBonuses($input: ManualBonusInputV2!) {
    manualBonusesV2(input: $input) {
      manualBonuses {
        bonusType
        merchant {
          companyName
        }
        publisher {
          companyUrl
          companyName
          id
        }
        month
        year
        currency
        bonusAmount
        bonusNote
        createdBy {
          firstName
          lastName
        }
        createdAt
        productCategory
      }
      count
    }
  }
`;
