import { useState, useCallback, useRef } from 'react';

import { useOutsideClickDetector } from './useOutsideClickDetector';

export const useDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const closeDropdownFunction = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  useOutsideClickDetector(dropdownRef, closeDropdownFunction);

  return {
    isOpen,
    setIsOpen,
    dropdownRef,
  };
};
