export const USER_INFO = {
  DESCRIPTION:
    "We're glad you're here! Come join our network of curated influencers and affiliates to get access to innovative, leading brands in the financial services space.",
  BACK: 'Back to Login',
  NEXT: 'Next',
  VALIDATIONS: {
    EMAIL: {
      INVALID: 'Please enter a valid email address',
      EXISTS: 'Email already exists',
    },
    PASSWORD: 'Password must have at least 8 characters',
  },
};
