import React from 'react';

import { Button } from '../../../Button';
import { InputTextarea } from '../../../Input';
import { AD_TYPES, NOT_AVAILABLE, USER_TYPES_ID } from '../../../../utils/enums';
import { useUserInfo } from '../../../../hooks';

import * as Styled from './styles';

type AdDetailsTabProps = {
  ad: any;
  onClose: () => void;
};

export const TYPE_NO_PREVIEW = 0;
export const TYPE_HORIZONTAL_PREVIEW = 1;
export const TYPE_VERTICAL_PREVIEW = 2;

const getPreviewSize = (height: number, width: number, type: number) => {
  // no-preview
  if (type === TYPE_NO_PREVIEW) return null;

  // For Horizontal Banners they should have a max height of 150px
  if (type === TYPE_HORIZONTAL_PREVIEW && height > 180) {
    return {
      width: (width * 180) / height,
      height: 180,
    };
  }

  // Vertical/Square Banners should have a max width of 200px
  if (type === TYPE_VERTICAL_PREVIEW && width > 200) {
    return {
      width: 200,
      height: (height * 200) / width,
    };
  }

  return {
    width,
    height,
  };
};

const AdDetailsTab = ({ ad, onClose }: AdDetailsTabProps) => {
  const {
    id,
    adName,
    description,
    defaultLandingPage,
    adContent,
    altText,
    width,
    height,
    createdAt,
    updatedAt,
    merchantName,
    campaignName,
    publisherGroups,
    adType,
  } = ad;
  const previewType = (() => {
    if (!adType || !width || !height) return TYPE_NO_PREVIEW;
    return parseInt(width, 10) < parseInt(height, 10) ? TYPE_VERTICAL_PREVIEW : TYPE_HORIZONTAL_PREVIEW;
  })();
  const adSize = getPreviewSize(parseInt(height, 10), parseInt(width, 10), previewType);
  const userHook = useUserInfo();

  return (
    <>
      <Styled.ContentStyled previewType={previewType}>
        {adContent && adType === AD_TYPES.BANNER && (
          <Styled.AdAreaStyledContent previewType={previewType}>
            <img src={adContent} alt={altText} height={adSize?.height} width={adSize?.width} />
          </Styled.AdAreaStyledContent>
        )}
        <InputTextarea label="Ad Id" readonly disabled theme="generateCode" value={id} />
        <InputTextarea
          label="Last Updated"
          readonly
          disabled
          theme="generateCode"
          value={new Date(updatedAt).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })}
        />
        {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER &&
          userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
            <InputTextarea
              label="Publisher Group"
              readonly
              disabled
              theme="generateCode"
              value={Array.isArray(publisherGroups) ? publisherGroups.join(', ') : ''}
            />
          )}
        {(userHook.hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER ||
          userHook.hookWhoAmI.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER) && (
          <InputTextarea
            label="Date Added"
            readonly
            disabled
            theme="generateCode"
            value={new Date(createdAt).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          />
        )}
        <InputTextarea
          label="Ad Size (Width x Height)"
          readonly
          disabled
          theme="generateCode"
          value={`${width && height ? `${width} x ${height} px` : NOT_AVAILABLE}`}
        />
        {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER &&
          userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
            <InputTextarea
              label="Date Added"
              readonly
              disabled
              theme="generateCode"
              value={new Date(createdAt).toLocaleDateString(undefined, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })}
            />
          )}
        <InputTextarea label="Ad Campaign" readonly disabled theme="generateCode" value={campaignName || ''} />
        <Styled.FirstPosition
          label="Merchant Name"
          readonly
          disabled
          theme="generateCode"
          value={merchantName || NOT_AVAILABLE}
          previewType={previewType}
        />
        <Styled.Input2Column
          label="Ad Description"
          readonly
          disabled
          theme="generateCode"
          value={description || NOT_AVAILABLE}
          previewType={previewType}
        />
        <InputTextarea label="Ad Name" readonly disabled theme="generateCode" value={adName} />
        <Styled.Input2Column
          label="Landing Page"
          readonly
          disabled
          theme="generateCode"
          value={defaultLandingPage || NOT_AVAILABLE}
          previewType={previewType}
          isLink={!!defaultLandingPage}
        />
      </Styled.ContentStyled>

      <Styled.FooterAreaStyled>
        <Button onClick={onClose} theme="secondary" width="100px">
          Cancel
        </Button>
      </Styled.FooterAreaStyled>
    </>
  );
};

export default AdDetailsTab;
