import styled from 'styled-components';

import { colors } from '../../../../../styles/theme';

type CellWrapperStyledProps = {
  readonly theme?: string;
};

type SpaceBetweenStyledProps = {
  readonly theme?: string;
};

const ToggleColumnTheme = 'ToggleColumn';
const ToggleColumnDeactive = 'ToggleColumn2';

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  display: flex;
  min-height: 36px;
  align-items: center;
  align-content: center;
  text-overflow: ellipsis;
  flex-flow: wrap row;
  text-transform: capitalize;
  justify-content: ${({ theme }) =>
    theme === ToggleColumnTheme || theme === ToggleColumnDeactive ? 'space-between' : 'auto'};
  color: ${({ theme }) => (theme === ToggleColumnDeactive ? colors.color28 : colors.color1)};
`;

export const SecondLineStyled = styled.div`
  color: ${colors.color13};
  width: 100%;
  text-transform: lowercase;
  margin-top: 8px;
`;

export const SpaceBetween = styled.div<SpaceBetweenStyledProps>`
  width: ${({ theme }) =>
    theme === ToggleColumnTheme || theme === ToggleColumnDeactive ? 'calc(100% - 100px)' : 'auto'};
  display: flex;
  justify-content: ${({ theme }) =>
    theme === ToggleColumnTheme || theme === ToggleColumnDeactive ? 'space-between' : 'auto'};
  margin-left: 20px;
  margin-right: 20px;
`;
