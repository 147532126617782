import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../../../components';
import { colors } from '../../../../../styles/theme';

type LinkCardProps = {
  readonly color?: string;
};

export const CardWrapperStyled = styled.div<LinkCardProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 4px solid ${({ color }) => color};
  border-radius: 3px;
  background: ${colors.color4};
  padding: 1rem 2rem;
`;

export const ButtonStyled = styled(Button)<LinkCardProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ color }) => color};

  &:hover {
    color: ${colors.color2};
  }
`;

export const IconStyled = styled(FontAwesomeIcon)<LinkCardProps>`
  width: 15px;
`;
