import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '../../../../../components';
import { colors, fonts } from '../../../../../styles/theme';

type CellProps = {
  val: any;
  // eslint-disable-next-line react/no-unused-prop-types
  isReadOnly?: boolean;
};

type CommissionCellProps = {
  val: any;
  row: any;
};

const CellStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.color1};
`;

const CommissionCellStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  color: ${colors.color1};

  p {
    ${fonts.lato.H16R}
    margin-bottom: 0.3rem;
  }

  span {
    color: ${colors.color13};
    width: 100%;
    white-space: none;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }
`;

const CheckboxCell = ({ val, isReadOnly = false }: CellProps) => (
  <CellStyled>
    <Checkbox key={val} checked={val} onChange={() => ''} disabled={isReadOnly} />
  </CellStyled>
);

const CellFormatted = ({ val }: CellProps) => <CellStyled>{val}</CellStyled>;

const CommissionCell = ({ val, row }: CommissionCellProps) => (
  <CommissionCellStyled>
    <p>{val}</p>
    <span>{row?.type}</span>
  </CommissionCellStyled>
);

const formattedDateCell = (val: any) => (
  <CellStyled>
    {val && val !== 'N/A'
      ? new Date(val).toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })
      : ''}
  </CellStyled>
);

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'checked',
    text: '',
    formatter: (val) => <CheckboxCell val={val} isReadOnly={isReadOnly} />,
    width: '4%',
  },
  {
    dataField: 'id',
    text: 'ID',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (val: any) => <CellFormatted val={val} />,
    width: '7%',
  },
  {
    dataField: 'name',
    text: 'Name/Type',
    sortFunction: (a: any, b: any) => a.localeCompare(b),
    formatter: (val: any, row: any) => <CommissionCell val={val} row={row} />,
    width: '74%',
  },
  {
    dataField: 'acceptedDate',
    text: 'Accepted Date',
    width: '15%',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: formattedDateCell,
  },
];
