import styled from 'styled-components';

import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';
import { Tooltip } from '../../Tooltip';

type InputContainerProps = {
  readonly error: any;
  readonly theme: 'default' | 'readonly' | 'generateCode' | 'readonlySecondary';
  readonly disabled: boolean;
  readonly readonly: boolean;
};

type WrapperProps = {
  readonly width: string;
};

type TextareaProps = {
  readonly height: string;
  readonly theme?: string;
};

const borderReturn = (theme: 'default' | 'readonly' | 'generateCode' | 'readonlySecondary', hover: boolean) => {
  if (theme === 'default') {
    return !hover ? colors.color3 : colors.color1;
  }
  if (theme === 'readonlySecondary') {
    return colors.color3;
  }

  return 'transparent';
};

export const WrapperStyled = styled.label<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

export const LabelStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${toRem(11)};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => (theme === 'generateCode' ? 0 : toRem(8))};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const InputStyled = styled.textarea<TextareaProps>`
  width: 100%;
  background: ${colors.color4};
  border: none;
  ${fonts.lato.H14}
  resize: vertical;
  line-height: 1.5;
  height: ${({ height }) => height};
  position: relative;

  min-height: ${({ theme }) => (theme === 'generateCode' ? `${toRem(72)}` : `${toRem(100)}`)};

  ${({ theme }) =>
    theme === 'getCode' &&
    `
    min-height: ${toRem(42)};
    height: ${toRem(40)};
  `};
`;

export const InputContainerStyled = styled.div<InputContainerProps>`
  display: flex;
  border: 1px solid ${({ error, theme }) => (error ? colors.color14 : borderReturn(theme, false))};
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  border-radius: 3px;
  padding: 0
    ${({ theme }) =>
      theme === 'readonly' || theme === 'generateCode' || theme === 'readonlyTertiary'
        ? '0'
        : `${toRem(10)} 0 ${toRem(10)} ${toRem(10)}`};
  height: auto;

  &:hover {
    border: 1px solid ${({ error, theme }) => (error ? colors.color14 : borderReturn(theme, true))};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    background-color: ${colors.color11};

    &:hover {
      border: 1px solid ${theme === 'generateCode' ? colors.color4 : colors.color3};

      svg {
        color: ${colors.color1};
      }
    }

    ${InputStyled} {
      background-color: ${colors.color11};
    }
  `}

  ${({ readonly, theme }) =>
    readonly &&
    `
      background-color: ${theme === 'generateCode' ? colors.color4 : colors.color11};

      textarea {
        background-color: ${colors.color11};
      }
    `}

    ${({ theme }) =>
    theme === 'readonlyTertiary' &&
    `
            background-color: ${colors.transparent};

          textarea {
            background-color: ${colors.transparent};
          }
    `}
  ${InputStyled} {
    padding: ${({ theme }) =>
      theme === 'readonly' || theme === 'generateCode' || theme === 'readonlyTertiary' ? '0' : toRem(10)};

    ${({ theme }) => {
      if (theme === 'readonly' || theme === 'readonlyTertiary') {
        return `
          font-size: ${toRem(16)};
          color: ${colors.color1};
          resize: none;
        `;
      }
      if (theme === 'generateCode') {
        return `
        ${fonts.lato.H16};
        color: ${colors.color1};
        background-color: ${colors.color4};
        resize: none;
      `;
      }
      if (theme === 'readonlySecondary') {
        return `
          color: ${colors.color13};
        `;
      }
      return '';
    }}
  }
`;

export const LinkStyled = styled.a`
  width: 100%;
  color: ${colors.color12};
  line-height: 1.5rem;
`;

export const ErrorStyled = styled.span`
  margin-top: 0.3rem;
  color: ${colors.color14};
  ${fonts.lato.H14}
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;

export const DisclaimerStyled = styled.div`
  border-radius: ${toRem(2)};
  background-color: #feedde;
  ${fonts.gotham.H10M};
  margin-left: ${toRem(15)};
  padding: ${toRem(2)} ${toRem(4)};
  margin-top: -2px;
  width: fit-content;
  text-transform: capitalize;
`;
