import React from 'react';

import * as Styled from '../styles';
import { CellProps } from '../types';

export const CampaignCell = ({ val }: CellProps) => {
  const primary = val.name;
  const secondary = val.description;
  return (
    <Styled.CellWrapper isId>
      <Styled.MainValStyled>{primary}</Styled.MainValStyled>
      <Styled.SecondaryValStyled>{secondary}</Styled.SecondaryValStyled>
    </Styled.CellWrapper>
  );
};
