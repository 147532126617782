import React from 'react';

import { Spacer } from 'components';
import { useAddEvaluationRule } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/AddEvaluationRule/hooks/useAddEvaluationRule';
import { ProductSummary } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/ProductSummary.component';
import * as Styled from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/styles';
import { TextSummary } from 'pages/Merchants/FintelCheck/FintelCheckRuleManager/components/TextSummary.component';

export const Summary = ({ hook }: { hook: ReturnType<typeof useAddEvaluationRule> }): JSX.Element => {
  return (
    <>
      <Spacer size="medium" direction="vertical" />

      <Styled.HeaderInfoText>
        Review your new rule details carefully here before saving. After you create the rule, it cannot be edited.
        Created rules can only be activated or deactivated.
      </Styled.HeaderInfoText>

      <Spacer size="medium" direction="vertical" />

      <Styled.FlexRow>
        <Styled.FlexColumn>
          <Styled.TextFieldLabel>Rule Name</Styled.TextFieldLabel>
          <Styled.TextFieldValue>{hook.stateMachine.context.ruleName}</Styled.TextFieldValue>
        </Styled.FlexColumn>
        <Styled.FlexColumn>
          <Styled.TextFieldLabel>Rule Group</Styled.TextFieldLabel>
          <Styled.TextFieldValue>{hook.stateMachine.context.ruleGroup}</Styled.TextFieldValue>
        </Styled.FlexColumn>
      </Styled.FlexRow>

      <Spacer size="xlarge" direction="vertical" />

      {hook.stateMachine.matches({ AddEvaluationRule: 'TextSummary' }) && (
        <TextSummary stateMachine={hook.stateMachine} />
      )}
      {hook.stateMachine.matches({ AddEvaluationRule: 'ProductSummary' }) && (
        <ProductSummary stateMachine={hook.stateMachine} />
      )}
    </>
  );
};
