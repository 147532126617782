import { gql } from '@apollo/client';

export const GET_COMMISSION_NAME = gql`
  query getCommissionName($input: CommissionNameInput!) {
    uniqueCommissionName(input: $input) {
      value
      commissionId
    }
  }
`;

export type GetCommissionNameInput = {
  input: {
    merchantId: string;
    value: string;
  };
};

export type GetCommissionNameOutput = {
  input: {
    commissionId: string;
    value: boolean;
  };
};
