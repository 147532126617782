import React from 'react';

import { AppWrapper, Button, PageTitle, Tabs } from 'components';
import { path } from 'utils';
import { DefaultPropsType } from 'types';

import { Permission } from '../../../../entities';

import InvitationTemplateTab from './Tabs/InvitationTemplate';
import InvitePublishersTab from './Tabs/InvitePublishers';
import { PAGE_TYPES, PUBLISHER_INVITATION, TABS } from './contracts';
import { usePublisherInvitation } from './hooks';
import * as Styled from './styles';

type PublisherInvitationProps = DefaultPropsType;

const PublisherInvitation = ({ permissionsCodeList = [] }: PublisherInvitationProps): JSX.Element => {
  const hook = usePublisherInvitation(PAGE_TYPES.MAIN);
  const hookInvite = usePublisherInvitation(PAGE_TYPES.INVITE);
  const [isReadOnly] = Permission.readOnlyPermissionsList(permissionsCodeList);

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.publisherInvitations.name}</PageTitle>

        <Button theme="tertiary" onClick={() => hookInvite.hookNavigateToInvitationHistory()}>
          {PUBLISHER_INVITATION.INVITATION_HISTORY}
        </Button>
      </Styled.HeaderStyled>

      <Tabs
        hookOveride={hook.hookOverrideTabs}
        tabNames={TABS}
        elements={[
          <InvitePublishersTab isReadOnly={isReadOnly} hook={hookInvite} key="InvitePublishersTab" />,
          <InvitationTemplateTab
            modalOpen={hook.hookModalOpen}
            templateId={hook.hookTemplateId}
            templateName={hook.hookTemplateName}
            deleteTemplate={hook.hookDeleteTemplate}
            closeModal={hook.hookCloseModal}
            getTemplateList={hook.hookGetTemplete}
            templateList={hook.hookTemplates}
            sortBy={hook.hookSortBy}
            sortHandler={hook.hookSetSortBy}
            currentPage={hook.hookCurrentPageTemplate}
            setCurrentPage={hook.hookSetCurrentPageTemplate}
            totalPages={hook.hookNumberOfPagesTemplate}
            templateAction={hook.hookTemplateAction}
            isReadOnly={isReadOnly}
            key="InvitationTemplateTab"
          />,
        ]}
      />
    </AppWrapper>
  );
};

export default PublisherInvitation;
