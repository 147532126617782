import styled from 'styled-components';

import { colors, fonts } from '../../../../../styles/theme';

export const WrapperStyled = styled.div`
  width: 650px;
  padding: 1rem;
`;
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;
export const ContentStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
export const RowStyled = styled.div`
  width: 33%;
  margin-bottom: 1rem;
`;

export const ButtonsFooterStyled = styled.div`
  display: flex;
  justify-content: space-around;
  width: 33%;
  margin-top: 3rem;
`;

export const ColumnTitleStyled = styled.div`
  ${fonts.gotham.H12M};
  color: ${colors.color13};
  width: 100%;
  margin: 1.5rem 0 1rem;
`;
