import styled from 'styled-components';

import { colors, fonts } from '../../../../../styles/theme';

type LoadingMerchantProps = {
  loading: boolean;
};

export const Wrapper = styled.div<LoadingMerchantProps>`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  align-items: center;
  gap: 0.5rem;
  ${fonts.lato.H12M}
  color: ${colors.color1};
  background-color: ${colors.color3};
  padding: 0.5rem;
  border-radius: 4px;
`;
