import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { RECORDS_PER_PAGE_OPTIONS, csvGenerator, path } from '../../../../utils';
import { LIST_SEARCH_MERCHANTS, LIST_SEARCH_MERCHANTS_CSV } from '../graphql/queries';
import { useActAsContext } from '../../../../context';
import { useDebounce } from '../../../../utils/useDebounce';
import { Permission } from '../../../../entities';

import { useMerchantFilterOptions } from './useMerchantFilterOptions';

export const useMerchantManagement = (permissionsCodeList: string[] = []) => {
  const [merchantList, setMerchantList] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState({ label: 'All Statuses', value: '' });
  const [type, setType] = useState({ label: 'All Merchant Types', value: '' });
  const [country, setCountry] = useState({ label: 'All Countries', value: '' });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [records, setRecords] = useState({ label: '5', value: '5' });
  const [recordsCount, setRecordsCount] = useState(0);
  const recordOptions = [{ label: '5', value: '5' }, ...RECORDS_PER_PAGE_OPTIONS];
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  // don't have debounce trigger search if search manually triggered before debounce triggers search
  const [currentlyDebounced, setCurrentlyDebounced] = useState<boolean>(false);

  const navigate = useNavigate();
  const { hookAccountStatusTypeList, hookMerchantTypeList, hookCountriesList, hookFilterOptionsLoading } =
    useMerchantFilterOptions();
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'createdAt', direction: 'asc' });
  const [getCompanies, { loading: merchantLoading }] = useLazyQuery(LIST_SEARCH_MERCHANTS);
  const [getCompaniesCSV, { loading: merchantCSVLoading }] = useLazyQuery(LIST_SEARCH_MERCHANTS_CSV);

  const hookActAs = useActAsContext();
  const debouncedSearch = useDebounce(search, 800);

  const getMerchantsHandler = async () => {
    const { data } = await getCompanies({
      variables: {
        input: {
          filter: {
            q: search,
            companyType: 'Merchant',
            accountStatusType: status.value || undefined,
            merchantType: type.value || undefined,
            country: country.value || undefined,
          },
          options: {
            page: currentPage,
            items: parseInt(records.value, 10),
            order: sortColumn.direction?.toUpperCase(),
          },
          sortBy: sortColumn.column,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (data?.listSearchMerchants.companies) {
      setMerchantList(data?.listSearchMerchants.companies);
    }
    if (data?.listSearchMerchants.count) {
      setRecordsCount(data.listSearchMerchants.count);
      setTotalPages(Math.ceil(data.listSearchMerchants.count / parseInt(records.value, 10)));
    } else {
      setCurrentPage(1);
      setRecordsCount(0);
      setTotalPages(0);
    }
  };

  const getMerchantCSVHandler = async () => {
    const { data } = await getCompaniesCSV({
      variables: {
        input: {
          filter: {
            q: search,
            companyType: 'Merchant',
            accountStatusType: status.value || undefined,
            merchantType: type.value || undefined,
            country: country.value || undefined,
          },
          options: {
            order: sortColumn?.direction?.toUpperCase(),
          },
        },
      },
    });
    if (data?.listSearchCompaniesCSV.csv) {
      csvGenerator(data?.listSearchCompaniesCSV.csv, 'Merchant Report');
    }
  };

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page);
  };

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentlyDebounced(true);
  };

  const setStatusHandler = (value: any) => {
    setStatus({
      label: value.label,
      value: value.value,
    });
  };

  const handleSort = (dataField: string, direction: any) => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  const setTypeHandler = (value: any) => {
    setType({
      label: value.label,
      value: value.value,
    });
  };

  const setCountryHandler = (value: any) => {
    setCountry({
      label: value.label,
      value: value.value,
    });
  };

  const setRecordsHandler = (value: any) => {
    setRecords({
      label: value.label,
      value: value.value,
    });
  };

  const clearFormHandler = () => {
    setSearch('');

    setStatus({
      label: 'All Statuses',
      value: '',
    });

    setType({
      label: 'All Merchant Types',
      value: '',
    });

    setCountry({
      label: 'All Countries',
      value: '',
    });

    setSearchClicked(false);
    setMerchantList([]);
  };

  const rowClickActAsHandler = (row: any) => {
    hookActAs.setActAsMerchantHandler(row.id);
  };

  const searchButtonHandler = () => {
    setSearchClicked(true);
    setCurrentlyDebounced(false);
    if (currentPage !== 1) setCurrentPage(1);
    else getMerchantsHandler();
  };

  const navigateHandler = () => {
    navigate(path.addNewMerchant.href, { replace: true });
  };

  useEffect(() => {
    if (searchClicked) {
      getMerchantsHandler();
    }
  }, [type, status, country, currentPage, records, sortColumn]);

  useEffect(() => {
    if (searchClicked && currentlyDebounced && currentPage === 1) getMerchantsHandler();
    else if (currentPage !== 1) setCurrentPage(1);
  }, [debouncedSearch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [records, type, status, country]);

  return {
    hookLoading: merchantLoading || hookFilterOptionsLoading,
    hookCSVLoading: merchantCSVLoading,
    hookMerchantList: merchantList,

    hookStatusList: hookAccountStatusTypeList,
    hookTypeList: hookMerchantTypeList,
    hookCountryOptions: hookCountriesList,
    hookMerchantCSV: getMerchantCSVHandler,

    hookSearch: search,
    hookStatus: status,
    hookType: type,
    hookCountry: country,

    hookSetSearch: setSearchHandler,
    hookSetStatus: setStatusHandler,
    hookSetType: setTypeHandler,
    hookSetCountry: setCountryHandler,

    hookRecords: records,
    hookTotalRecords: recordsCount,
    hookSetRecords: setRecordsHandler,
    hookRecordOptions: recordOptions,

    hookSetPage: setCurrentPageHandler,
    hookCurrentPage: currentPage,

    hookTotalPages: totalPages,
    hookClearForm: clearFormHandler,

    hookHandleSort: handleSort,
    hookSortColumn: sortColumn,

    hookHandleRowClickActAs: rowClickActAsHandler,

    hookSearchClicked: searchClicked,
    hookSearchButtonHandler: searchButtonHandler,

    hookNavigate: navigateHandler,
    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
