import { useLazyQuery } from '@apollo/client';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import {
  LIST_COMPANIES,
  ListCompaniesInput,
  ListCompaniesOutput,
  GET_MERCHANT_INFO,
  GetMerchantInfoInput,
  GetMerchantInfoOutput,
  LIST_TRACKINGS,
  ListTrackingsInput,
  ListTrackingsOutput,
  LTTrackingType,
  NEW_SITE_MON_REPORT,
  NewSiteMonReportInput,
  NewSiteMonReportOutput,
  NSMRFilters,
  NSMRRow,
  NEW_SITE_MON_CSV,
  NewSiteMonCSVOutput,
} from '../graphql/queries';
import {
  csvGenerator,
  defaultOption,
  PAGE_TYPE,
  RECORDS_PER_PAGE_OPTIONS,
  toUTCHours,
  USER_TYPES_ID,
  rangeFormat,
} from '../../../../utils';
import { linkManagerColumns } from '../contracts';
import { formatDataForCSV, processDataToCSVString } from '../utils';
import { useUserInfo } from '../../../../hooks';
import { MerchantOptionsType } from '../../../Reports/PerfomanceReport/types';
import { DOWNLOAD_ERROR } from '../../enums';

const formatTrackProfile = (trackings: LTTrackingType[]) => {
  const formattedTrackings = trackings.map((tracking) => ({
    label: `${tracking.id} - ${tracking.profileName}`,
    value: tracking.id,
  }));

  return [defaultOption.trackingProfile, ...formattedTrackings];
};

export const useNewSiteMonitoring = () => {
  // Global Values
  const { hookWhoAmI, hookUserInfo } = useUserInfo();
  const today = new Date();
  const defaultRange = rangeFormat('last30Days');
  const thirtyDaysAgo = defaultRange.start;
  const originalMerchantOption: SelectOption = useMemo(() => {
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ||
      hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT
    ) {
      const merchantId = hookWhoAmI.companyId?.toString() || '0';
      return { label: '', value: merchantId };
    }
    return defaultOption.merchant;
  }, [window.location.href]);
  const originalPublisherOption: SelectOption = useMemo(() => {
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER ||
      hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER
    ) {
      const publisherId = hookWhoAmI.companyId?.toString() || '0';
      return { label: '', value: publisherId };
    }
    return defaultOption.publisher;
  }, [window.location.href]);
  const currentUserType: UserStringType = useMemo(() => {
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.PUBLISHER ||
      hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER
    ) {
      return 'Publisher';
    }
    if (
      hookWhoAmI?.isActingAsUserTypeId === USER_TYPES_ID.MERCHANT ||
      hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT
    ) {
      return 'Merchant';
    }
    return 'Admin';
  }, [window.location.href]);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [disabledFields, setDisabledFields] = useState(true);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(today);
  const [dateRange, setDateRange] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState(`${startDate.toDateString()} / ${endDate.toDateString()}`);

  const [merchantList, setMerchantList] = useState<SelectOption[]>([originalMerchantOption]);
  const [selectedMerchant, setSelectedMerchant] = useState<SelectOption>(originalMerchantOption);

  const [publisherList, setPublisherList] = useState<SelectOption[]>([originalPublisherOption]);
  const [selectedPublisher, setSelectedPublisher] = useState<SelectOption>(originalPublisherOption);

  const [productList, setProductList] = useState<SelectOption[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<SelectOption>(defaultOption.product);

  const [adList, setAdList] = useState<SelectOption[]>([]);
  const [selectedAd, setSelectedAd] = useState<SelectOption>(defaultOption.ad);

  const [selectedPageType, setSelectedPageType] = useState<SelectOption>(PAGE_TYPE[0]);

  const [trackingProfileList, setTrackingProfileList] = useState<SelectOption[]>([defaultOption.trackingProfile]);
  const [selectedTrackingProfile, setSelectedTrackingProfile] = useState<SelectOption>(defaultOption.trackingProfile);

  const [search, setSearch] = useState('');

  const [selectedRecords, setSelectedRecords] = useState<SelectOption>(RECORDS_PER_PAGE_OPTIONS[0]);

  // Table Data
  const [tableData, setTableData] = useState<NSMRRow[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  // Table Status
  const [reportColumns, setReportColumns] = useState<TableColumn[]>(linkManagerColumns(currentUserType));
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>();
  const [isGenerated, setIsGenerated] = useState(false);
  const [refetchTable, setRefetchTable] = useState(false);

  const [getMerchantInfo, { loading: merchantInfoLoading }] = useLazyQuery<GetMerchantInfoOutput, GetMerchantInfoInput>(
    GET_MERCHANT_INFO
  );
  const [getReport, { loading: reportLoading }] = useLazyQuery<NewSiteMonReportOutput, NewSiteMonReportInput>(
    NEW_SITE_MON_REPORT
  );
  const [getReportCSV, { loading: reportCSVLoading }] = useLazyQuery<NewSiteMonCSVOutput, NewSiteMonReportInput>(
    NEW_SITE_MON_CSV
  );
  const [getMerchants, { loading: merchantsLoading }] = useLazyQuery<ListCompaniesOutput, ListCompaniesInput>(
    LIST_COMPANIES
  );
  const [getTrackings, { loading: getTrackingsLoading }] = useLazyQuery<ListTrackingsOutput, ListTrackingsInput>(
    LIST_TRACKINGS
  );

  const getMerchantInfoHandler = async (merchantId: string) => {
    const publisherListOptions: SelectOption[] = [originalPublisherOption];
    const immutableTrackingProfilesList: any = [defaultOption.trackingProfile];
    const productListOptions: SelectOption[] = [defaultOption.product];
    const adListOptions: SelectOption[] = [defaultOption.ad];
    setSelectedPublisher(originalPublisherOption);
    setSelectedProduct(defaultOption.product);
    if (currentUserType !== 'Publisher') {
      setSelectedTrackingProfile(defaultOption.trackingProfile);
    }
    setSelectedPageType(PAGE_TYPE[0]);
    setSearch('');
    setErrorMessage('');

    const { data } = await getMerchantInfo({
      variables: {
        companyId: merchantId,
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    if (data?.company.program) {
      const program = data?.company.program;

      const publishersFormatted = data.company.memberships
        .filter((membership) => membership.status === 'Approved')
        .map((membership) => ({
          label: `${membership.publisher.id} - ${membership.publisher.companyName}`,
          value: membership.publisher.id,
        }));

      const productsFormatted = program.products
        .filter((product) => product.status === 'Active')
        .map((product) => ({
          label: `${product.customizedProductId} - ${product.name}`,
          value: product.customizedProductId,
        }));

      const adsFormatted = program.ads
        .filter((ad) => ad.adStatus === 'Active')
        .map((ad) => ({
          label: `${ad.id} - ${ad.adName}`,
          value: ad.id,
        }));

      if (publisherListOptions.length > 0) {
        publisherListOptions.push(...publishersFormatted);
      }

      if (productListOptions.length > 0) {
        productListOptions.push(...productsFormatted);
      }

      if (adListOptions.length > 0) {
        adListOptions.push(...adsFormatted);
      }
    }

    if (currentUserType !== 'Publisher') {
      setPublisherList(publisherListOptions);
      setTrackingProfileList(formatTrackProfile(immutableTrackingProfilesList));
    }
    setProductList(productListOptions);
    setDisabledFields(false);
    setAdList(adListOptions);
  };

  const selectMerchantHandler = (merchant: SelectOption) => {
    setTableData([]);
    setCurrentPage(1);
    setSelectedMerchant(merchant);
    setIsGenerated(false);

    if (merchant.value) {
      setTableData([]);
      setCurrentPage(1);
      getMerchantInfoHandler(merchant.value);
      setIsGenerated(false);
    } else {
      setDisabledFields(true);
      setSelectedPublisher(originalPublisherOption);
      setSelectedProduct(defaultOption.product);
      setSelectedAd(defaultOption.ad);
      setSelectedTrackingProfile(defaultOption.trackingProfile);
    }
  };

  const setPublisherHandler = async (publisherItem: SelectOption) => {
    setSelectedPublisher({
      label: publisherItem.label,
      value: publisherItem.value,
    });
    setIsGenerated(false);
    setTableData([]);
    setCurrentPage(1);
    setErrorMessage('');
    if (!publisherItem.value) {
      setSelectedTrackingProfile(defaultOption.trackingProfile);
      return;
    }
    const { data } = await getTrackings({
      variables: {
        companyId: publisherItem.value,
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });

    if (data && data.trackings) {
      const newTrackingsList = formatTrackProfile(data.trackings.trackings);
      setTrackingProfileList(newTrackingsList);
    }
  };

  const selectProductHandler = (product: SelectOption) => {
    setTableData([]);
    setCurrentPage(1);
    setSelectedProduct(product);
    setIsGenerated(false);
  };

  const selectAdHandler = (ad: SelectOption) => {
    setTableData([]);
    setCurrentPage(1);
    setSelectedAd(ad);
    setIsGenerated(false);
  };

  const setPageTypeHandler = (pageTypeItem: SelectOption) => {
    setSelectedPageType({
      label: pageTypeItem.label,
      value: pageTypeItem.value,
    });
    setIsGenerated(false);
    setTableData([]);
    setCurrentPage(1);
  };

  const setSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setIsGenerated(false);
    setTableData([]);
    setCurrentPage(1);
  };

  const setRecordsHandler = (recordItem: SelectOption) => {
    setCurrentPage(1);
    setSelectedRecords({
      label: recordItem.label,
      value: recordItem.value,
    });
    if (isGenerated) setRefetchTable(true);
  };

  const setOpenCalendarHandler = () => {
    setOpenCalendar(!openCalendar);
  };

  const setOnCancelCalendarHandler = () => {
    setOpenCalendar(false);
  };

  const setOnApplyCalendarHandler = (startDateValue: Date, endDateValue?: Date, range?: string) => {
    setTableData([]);
    setCurrentPage(1);
    setOpenCalendar(false);
    setStartDate(startDateValue);
    setIsGenerated(false);

    if (endDateValue) {
      setEndDate(endDateValue);
    }

    if (range) {
      setDateRange(range);
    }

    setSelectedDateRange(`${startDateValue.toDateString()} / ${endDateValue?.toDateString()}`);
  };

  const setGenerateReportHandler = async () => {
    const filters: NSMRFilters = {
      publisherId: selectedPublisher.value || null,
      merchantId: selectedMerchant.value || null,
      startDate: toUTCHours(startDate, 'beginning').toISOString(),
      endDate: toUTCHours(endDate, 'end').toISOString(),
      adId: selectedAd.value || null,
      customizedProductId: selectedProduct.value || null,
      pageType: selectedPageType?.value || null,
      trackingProfileId: selectedTrackingProfile.value || null,
      q: search,
      newToSystem: true,
    };
    setErrorMessage('');
    const { data } = await getReport({
      variables: {
        input: {
          filter: filters,
          sortBy: sortColumn?.column || 'trackingProfileId',
          options: {
            items: Number(selectedRecords.value) || 10,
            page: Number(currentPage) || 1,
            order: sortColumn?.direction === 'desc' ? 'DESC' : 'ASC',
          },
        },
      },
      fetchPolicy: 'no-cache',
      onError() {
        setErrorMessage(DOWNLOAD_ERROR);
      },
    });

    setIsGenerated(true);

    if (data?.newTrackingEvents) {
      setTableData(data.newTrackingEvents.trackingEventReportItems);
      setTotalPages(data.newTrackingEvents.totalPages);
      setTotalRecords(data.newTrackingEvents.count);
      setCurrentPage(data.newTrackingEvents.currentPage);
    } else {
      setTableData([]);
      setTotalPages(1);
      setTotalRecords(0);
      setCurrentPage(1);
    }
  };

  /** Creates the list of available merchants for Merchant ID - Name dropdown */
  const createMerchantListHandler = (companyList: MerchantOptionsType[]) => {
    const newMerchantList = [originalMerchantOption];

    if (companyList) {
      const listOptions: SelectOption[] = companyList.map((merchant: MerchantOptionsType) => ({
        label: `${merchant.id} - ${merchant.companyName}`,
        value: merchant.id,
      }));
      newMerchantList.push(...listOptions);
    }
    setMerchantList(newMerchantList);
  };

  const getInitialOptions = async () => {
    if (currentUserType === 'Admin') {
      setErrorMessage('');
      const { data } = await getMerchants({
        variables: {
          input: {
            companyType: 'Merchant',
            accountStatus: 'Approved',
          },
        },
        fetchPolicy: 'no-cache',
        onError(err) {
          setErrorMessage(err.message);
        },
      });

      if (data && data.companies) {
        createMerchantListHandler(data.companies.companies);
      }
    }
    if (currentUserType === 'Merchant') {
      getMerchantInfoHandler(originalMerchantOption.value);
    }
  };

  const setClearFormHandler = () => {
    setTableData([]);
    setReportColumns(linkManagerColumns(currentUserType));
    setSelectedMerchant(originalMerchantOption);
    setSelectedPublisher(originalPublisherOption);
    setSelectedProduct(defaultOption.product);
    setSelectedAd(defaultOption.ad);
    setSelectedPageType(PAGE_TYPE[0]);
    setSelectedTrackingProfile(defaultOption.trackingProfile);
    setSearch('');
    setSelectedRecords(RECORDS_PER_PAGE_OPTIONS[0]);
    setIsGenerated(false);
    getInitialOptions();
    setCurrentPage(1);
    setTotalRecords(0);
    setTotalPages(1);
    setSortColumn(undefined);
    setStartDate(thirtyDaysAgo);
    setEndDate(today);
    setDateRange('');
    setSelectedDateRange(`${thirtyDaysAgo.toDateString()} / ${today.toDateString()}`);
  };

  // Table Handlers
  const setCurrentPageHandler = (pageNum: number) => {
    setCurrentPage(pageNum);
    setRefetchTable(true);
  };

  const sortTableHandler = (column: string, direction: 'desc' | 'asc' | undefined) => {
    const newDirection = direction === 'desc' ? 'asc' : 'desc';
    setSortColumn({ column, direction: newDirection });
    setRefetchTable(true);
  };

  const setOnDragEndHandler = (result: TableColumn[]) => {
    setReportColumns(result);
  };

  const getCSVReportHandler = async () => {
    const filters: NSMRFilters = {
      publisherId: selectedPublisher.value || null,
      merchantId: selectedMerchant.value || null,
      startDate: toUTCHours(startDate, 'beginning').toISOString(),
      endDate: toUTCHours(endDate, 'end').toISOString(),
      adId: selectedAd.value || null,
      customizedProductId: selectedProduct.value || null,
      pageType: selectedPageType?.value || null,
      trackingProfileId: selectedTrackingProfile.value || null,
      q: search,
      newToSystem: true,
    };
    setErrorMessage('');
    const { data } = await getReportCSV({
      variables: {
        input: {
          filter: filters,
          csv: true,
        },
      },
      fetchPolicy: 'no-cache',
      onError() {
        setErrorMessage(DOWNLOAD_ERROR);
      },
    });

    if (data && data.newTrackingEventsCSV) {
      const formatedData = formatDataForCSV(data.newTrackingEventsCSV?.trackingEventReportItems ?? []);
      const csvString = processDataToCSVString(formatedData, reportColumns);
      csvGenerator(csvString, 'New Site Monitoring Report');
    }
  };

  if (refetchTable) {
    setRefetchTable(false);
    setGenerateReportHandler();
  }

  useEffect(() => {
    setClearFormHandler();
  }, [window.location.href]);

  return {
    // Page Status
    hookErrorMessage: errorMessage,
    hookDisabledFields: disabledFields,
    hookPageLoading: merchantInfoLoading || merchantsLoading || reportLoading || reportCSVLoading,
    hookUserType: currentUserType,

    // Dropdowns & Filters
    hookMerchantList: merchantList,
    hookMerchant: selectedMerchant,
    hookSetMerchant: selectMerchantHandler,
    hookMerchantInfoLoading: merchantInfoLoading,

    hookClearForm: setClearFormHandler,

    hookPublisherList: publisherList,
    hookPublisher: selectedPublisher,
    hookSetPublisher: setPublisherHandler,

    hookProductList: productList,
    hookSetProduct: selectProductHandler,
    hookProduct: selectedProduct,

    hookAdList: adList,
    hookAd: selectedAd,
    hookSetAd: selectAdHandler,

    hookSetPageType: setPageTypeHandler,
    hookPageType: selectedPageType,

    hookTrackingProfileList: trackingProfileList,
    hookTrackingProfile: selectedTrackingProfile,
    hookSetTrackingProfile: setSelectedTrackingProfile,
    hookTrackingProfileActive: !!selectedPublisher.value && !getTrackingsLoading,
    hookTrackingProfileLoading: getTrackingsLoading,

    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookRecords: selectedRecords,
    hookSetRecords: setRecordsHandler,

    hookOnCancelCalendar: setOnCancelCalendarHandler,
    hookOnApplyCalendar: setOnApplyCalendarHandler,
    hookSelectDate: selectedDateRange,
    hookStartDate: startDate,
    hookEndDate: endDate,
    hookRange: dateRange,
    hookOpenCalendar: openCalendar,
    hookSetOpenCalendar: setOpenCalendarHandler,

    // Table Data
    hookTableData: tableData,
    hookTotalPages: totalPages,
    hookTotalRecords: totalRecords,
    // Table Status
    hookIsGenerated: isGenerated,
    hookTableColumns: reportColumns,
    hookCurrentPage: currentPage,
    hookGenerateReportLoading: reportLoading,
    hookSortColumn: sortColumn,

    // Table Handlers
    hookGenerateReport: setGenerateReportHandler,
    hookSetCurrentPage: setCurrentPageHandler,
    hookSortTableHandler: sortTableHandler,
    hookSetOnDragEnd: setOnDragEndHandler,

    // CSV Handlers
    hookGetCSVHandler: getCSVReportHandler,
    hookCSVLoading: reportCSVLoading,
  };
};
