import { gql } from '@apollo/client';

export const INVOICE_STATEMENT = gql`
  query invoiceStatement($input: InvoiceStatementInput!) {
    invoiceStatement(input: $input) {
      invoiceDetails {
        invoiceNo
        invoiceDate
        dueDate
        totalDue
        taxes
        subTotal
        balance
        payment
        month
        year
        paymentMethod
      }
      publisher {
        id
        companyName
        address1
        city
        zip
        country
        state
        paymentInfo {
          address1
          phone
          city
          zip
          country
          state
          gst
          pst
          hst
          tax
        }
      }
      merchant {
        id
        companyName
        address1
        city
        zip
        country
        state
      }
      tableData {
        cpa {
          publisher
          count
          amount
          tax
          systemUsageFee
          total
        }
        referral {
          publisher
          count
          amount
          tax
          systemUsageFee
          total
        }
        manualBonus {
          publisher
          count
          amount
          tax
          systemUsageFee
          total
        }
        manualCommission {
          publisher
          count
          amount
          tax
          systemUsageFee
          total
        }
        giftCard {
          publisher
          count
          amount
          tax
          systemUsageFee
          total
        }
        managementFee {
          publisher
          count
          amount
          tax
          systemUsageFee
          total
        }
        manualFee {
          publisher
          count
          amount
          tax
          systemUsageFee
          total
        }
        other {
          publisher
          count
          amount
          tax
          systemUsageFee
          total
        }
      }
    }
  }
`;
