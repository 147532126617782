import styled from 'styled-components';

import { colors, fonts } from '../../../styles/theme';
import { toRem } from '../../../utils';
import { Tooltip } from '../../Tooltip';

type InputContainerProps = {
  readonly error: boolean;
  readonly theme: 'default' | 'readonly' | 'readonlySecondary' | 'readonlyTernary';
  readonly disabled: boolean;
  readonly noHoverColor?: boolean;
};

type WrapperProps = {
  readonly width: string;
};

type ErrorProps = {
  readonly error?: string;
};

type LabelStatusIconStyledProps = {
  readonly theme?: 'Verified' | 'Invalid' | 'Unverified';
  readonly noMarginLeft?: boolean;
};

const borderReturn = (theme: 'default' | 'readonly' | 'readonlySecondary' | 'readonlyTernary', hover: boolean) => {
  if (theme === 'default') {
    return !hover ? colors.color3 : colors.color1;
  }
  if (theme === 'readonlySecondary') {
    return colors.color19;
  }

  return 'transparent';
};

export const WrapperStyled = styled.label<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

export const LabelStyled = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: ${toRem(8)};
  color: ${colors.color13};
  ${fonts.gotham.H12M}
`;

export const RequiredStyled = styled.span`
  color: ${colors.color2};
  margin-left: ${toRem(4)};
`;

export const InputStyled = styled.input`
  width: 100%;
  padding: ${toRem(10)};
  background: ${colors.color4};
  border: none;
  ${fonts.lato.H14}

  &::placeholder {
    color: ${colors.color7};
  }
`;

export const ShowPasswordStyled = styled.button`
  background: ${colors.color4};
  border: none;
  color: ${colors.color5};
  cursor: pointer;
  font-size: ${toRem(18)};
`;

export const InputContainerStyled = styled.div<InputContainerProps>`
  display: flex;
  border: 1px solid ${({ error, theme }) => (error ? colors.color14 : borderReturn(theme, false))};
  align-items: center;
  width: 100%;
  border-radius: 3px;
  padding-right: ${({ theme }) => (theme === 'readonly' || theme === 'readonlyTernary' ? '0' : toRem(10))};
  height: ${toRem(40)};

  cursor: ${({ theme }) => (theme === 'readonlySecondary' ? 'not-allowed' : 'text')};
  background: ${({ theme }) => (theme === 'readonlySecondary' ? colors.color11 : colors.color4)};

  &:hover {
    border: 1px solid ${({ error, theme }) => (error ? colors.color14 : borderReturn(theme, true))};

    svg {
      color: ${({ noHoverColor }) => (noHoverColor ? '' : colors.color1)};
    }
  }

  svg {
    color: ${colors.color13};
    margin-left: 0.5rem;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    background: ${colors.color11};

    &:hover {
      border: 1px solid ${colors.color3};

      svg {
        color: ${colors.color1};
      }
    }

    ${InputStyled} {
      background: ${colors.color11};
    }
  `}

  ${InputStyled} {
    padding: ${({ theme }) => (theme === 'readonly' || theme === 'readonlyTernary' ? '0' : toRem(10))};

    ${({ theme }) => {
      if (theme === 'readonly') {
        return `
        font-size: ${toRem(18)};
        color: ${colors.color1};
      `;
      }
      if (theme === 'readonlySecondary') {
        return `
        background: ${colors.color11};
        cursor: default;
      `;
      }
      return '';
    }}
  }
`;

export const ErrorStyled = styled.span<ErrorProps>`
  margin-top: 0.3rem;
  ${fonts.lato.H14}
  color: ${({ error }) => {
    switch (error) {
      case 'green':
        return '#5DD755';
      case 'grey':
        return colors.color13;
      case 'orange':
        return colors.color2;
      default:
        return colors.color14;
    }
  }};
`;

export const TooltipStyled = styled(Tooltip)`
  height: auto;

  div {
    margin-left: 2px;
  }
`;

export const LabelStatusIconStyled = styled.span<LabelStatusIconStyledProps>`
  margin: ${({ noMarginLeft: noMargin }) => (noMargin ? 0 : `0 ${toRem(5)} 0 ${toRem(10)}`)};
  font-size: ${toRem(15)};

  color: ${({ theme }) => {
    switch (theme) {
      case 'Unverified':
        return colors.color7;
      case 'Verified':
        return colors.color25;
      case 'Invalid':
        return colors.color14;
      default:
        return colors.color13;
    }
  }};

  svg {
    margin-left: ${({ noMarginLeft: noMargin }) => (noMargin ? 0 : '')};
    margin-right: ${({ noMarginLeft: noMargin }) => (noMargin ? toRem(4) : '')};
    color: ${({ theme }) => {
      switch (theme) {
        case 'Unverified':
          return colors.color7;
        case 'Verified':
          return colors.color25;
        case 'Invalid':
          return colors.color14;
        default:
          return colors.color13;
      }
    }};
  }
`;

export const LabelStatusTextStyled = styled.span`
  text-transform: capitalize;
`;

export const LabelStatusDateStyled = styled.span`
  ${fonts.lato.H12B}
  font-size: 10px;
  padding: 2px;
  border: 1px solid rgb(101, 197, 125);
  border-radius: 5px;
  margin-left: ${toRem(40)};
  text-transform: initial;
`;

export const PasswordResetStyled = styled.div`
  position: relative;
`;

export const PasswordResetButton = styled.button`
  ${fonts.lato.H16}
  cursor: pointer;
  width: fit-content;
  background-color: ${colors.color1};
  border: 1px solid ${colors.color1};
  color: ${colors.color4};
  position: absolute;
  right: 0px;
  top: -16px;
  border-radius: ${toRem(4)};
  padding: ${toRem(6)} ${toRem(12)};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const DisclaimerStyled = styled.div`
  border-radius: ${toRem(2)};
  background-color: #feedde;
  ${fonts.gotham.H10M};
  margin-left: ${toRem(15)};
  padding: ${toRem(2)} ${toRem(4)};
  margin-top: -2px;
  width: fit-content;
  text-transform: capitalize;
`;
