import React from 'react';

import { imageList } from '../../../../../../../utils';

import * as Styled from './styles';

type MerchantManagementThumbnailProps = {
  value: string;
  url: string;
};

export const MerchantManagementThumbnail = ({ value, url }: MerchantManagementThumbnailProps) => (
  <Styled.CellWrapperStyled>
    <div
      style={{
        width: 35,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
      }}
    >
      <img src={url || imageList.noPic.src} alt="thumbnail" />
    </div>
    <Styled.TextStyled>{value}</Styled.TextStyled>
  </Styled.CellWrapperStyled>
);
