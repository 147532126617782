import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Permission } from '../../../../../entities';

export const useManualUrls = (
  permissionsCodeList: string[] = []
): { hookNavigate: NavigateFunction; hookIsReadOnlyList: boolean[] } => {
  const navigate = useNavigate();

  return {
    hookNavigate: navigate,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
