import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';

export const CellStyled = styled.div`
  ${fonts.lato.H16R}
  overflow: visible;
  white-space: normal;
  word-wrap: break-word;
  color: ${colors.color1};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label {
    margin: 0;
  }
  min-height: 48px;
`;

export const TCCellStyled = styled(CellStyled)`
  text-align: center;
  color: ${colors.color2};
  justify-content: flex-start;
  text-decoration: none;
`;
