import React from 'react';

import * as Styled from './styles';

type CellWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

export const CellWrapper = ({ children, className }: CellWrapperProps) => (
  <Styled.CellWrapperStyled className={className}>{children}</Styled.CellWrapperStyled>
);
