import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { MERCHANT_PREFIX, path } from '../../../../../../../utils';

import * as Styled from './styles';

type IconCellPRops = {
  val: string;
};

export const IconCell = ({ val }: IconCellPRops) => {
  const navigate = useNavigate();

  return (
    <Styled.ButtonStyled
      theme="text-only"
      onClick={() => navigate(`${MERCHANT_PREFIX}${path.editPublisherGroup.href}?id=${val}`)}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </Styled.ButtonStyled>
  );
};
