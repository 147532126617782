import React from 'react';

import { Modal, Button, Select, InputText, ErrorBanner } from '../../../../components';
import { BONUS_TYPE } from '../../../../utils';
import { MANUAL_PUBLISHER_BONUS } from '../enums';
import { useManualPublisherBonus } from '../hooks';

import * as Styled from './styles';

type AddModalProps = {
  hooks: ReturnType<typeof useManualPublisherBonus>;
};

export const AddBonusModal = ({ hooks }: AddModalProps) => (
  <Modal isOpen={hooks.hookModalIsOpen}>
    <Styled.ErrorDiv>
      <ErrorBanner message={hooks.hookErrorModal} isOpen={!!hooks.hookErrorModal} />
    </Styled.ErrorDiv>
    <Styled.OuterWrapperStyled>
      <Styled.WrapperStyled>
        <Styled.TitleStyled>{MANUAL_PUBLISHER_BONUS.ADD_TITLE}</Styled.TitleStyled>
        <Styled.WrapperStyled theme="buttons">
          <Button theme="secondary" onClick={hooks.hookHandleCancelModal}>
            {MANUAL_PUBLISHER_BONUS.CANCEL}
          </Button>
          <Button loading={hooks.hookSaveButtonLoading} onClick={hooks.hookHandleSaveModal}>
            {MANUAL_PUBLISHER_BONUS.SAVE}
          </Button>
        </Styled.WrapperStyled>
      </Styled.WrapperStyled>
      <Styled.WrapperStyled>
        <Select
          error={(hooks.hookPublisherIdError && MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PUBLISHER.ERROR) || undefined}
          required
          label={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PUBLISHER.LABEL}
          name={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PUBLISHER.NAME}
          onChange={hooks.hookHandleSetPublisherModal}
          selected={hooks.hookPublisherIdModal}
          options={hooks.hookPublisherIdOptionsModal}
          placeholder={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PUBLISHER.PLACEHOLDER}
        />
        <InputText
          error={
            (hooks.hookPaymentError && MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_AMOUNT_TEXTS.ERROR) || undefined
          }
          value={hooks.hookPaymentAmountModal}
          onChange={hooks.hookHandlePaymentAmountModal}
          type="text"
          label={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_AMOUNT_TEXTS.LABEL}
          required
          name={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_AMOUNT_TEXTS.NAME}
        />
      </Styled.WrapperStyled>
      <Styled.WrapperStyled>
        <Select
          required
          label={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_PERIOD_TEXTS.LABEL}
          name={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_PERIOD_TEXTS.NAME}
          selected={hooks.hookPaymentPeriodModal}
          onChange={hooks.hookHandleSetPaymentPeriodModal}
          options={hooks.hookPaymentPeriodModalOptions}
          isLoading={hooks.hookCountLoading}
          placeholder={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_PERIOD_TEXTS.PLACEHOLDER}
        />
        <Select
          required
          label={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_TYPE_TEXTS.LABEL}
          name={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_TYPE_TEXTS.NAME}
          selected={hooks.hookPaymentTypeModal}
          onChange={hooks.hookHandlesSetPaymentType}
          options={BONUS_TYPE}
          placeholder={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PAYMENT_TYPE_TEXTS.PLACEHOLDER}
        />
      </Styled.WrapperStyled>
      <Styled.WrapperStyled>
        <Select
          error={(hooks.hookMerchantError && MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.MERCHANT_TEXTS.ERROR) || undefined}
          required
          label={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.MERCHANT_TEXTS.LABEL_NAME}
          name={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.MERCHANT_TEXTS.LABEL_NAME}
          selected={hooks.hookMerchantModal}
          onChange={hooks.hookHandleSetMerchantModal}
          options={hooks.hookMerchantOptionsModal}
          isLoading={hooks.hookHookAccountStatsLoading}
          placeholder={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.MERCHANT_TEXTS.PLACEHOLDER}
          width={hooks.hookProductOptionsModal.length === 0 ? '49%' : '100%'}
        />
        {hooks.hookProductOptionsModal.length !== 0 && (
          <Select
            required
            label={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PRODUCT_TEXTS.LABEL}
            name={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PRODUCT_TEXTS.NAME}
            selected={hooks.hookProductCategoryModal}
            onChange={hooks.hookHandleProductCategoryModal}
            options={hooks.hookProductOptionsModal}
            placeholder={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PRODUCT_TEXTS.PLACEHOLDER}
            error={
              (hooks.hookProductCategoryError && MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.PRODUCT_TEXTS.ERROR) ||
              undefined
            }
          />
        )}
      </Styled.WrapperStyled>
      <Styled.WrapperStyled theme="half">
        <InputText
          value={hooks.hookNoteModal}
          onChange={hooks.hookHandleNoteModal}
          type="text"
          label={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.NOTE_TEXTS.LABEL_NAME}
          name={MANUAL_PUBLISHER_BONUS.ADD_BONUS_MODAL.NOTE_TEXTS.LABEL_NAME}
        />
      </Styled.WrapperStyled>
    </Styled.OuterWrapperStyled>
  </Modal>
);
