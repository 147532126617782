import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import { Spinner, Tooltip } from '../../../../../components';

import * as Styled from './styles';

type StatusCardProps = {
  title: string;
  tooltip: string;
  amount: string;
  link: string;
  icon: any;
  color: string;
  loading?: boolean;
};

export const StatusCard = ({ title, tooltip, amount, link, icon, color, loading = false }: StatusCardProps) => {
  const navigate = useNavigate();
  return (
    <Styled.CardWrapperStyled color={color}>
      <Styled.ContentStyled>
        <Styled.HeaderStyled>
          <p>{title}</p>
          <Tooltip text={tooltip} />
        </Styled.HeaderStyled>
        <Styled.BodyStyled>
          {loading ? <Spinner theme="secondary" /> : <p>{amount}</p>}
          <Styled.IconWrapperStyled color={color}>
            <Styled.IconStyled icon={icon} color={color} />
          </Styled.IconWrapperStyled>
        </Styled.BodyStyled>
      </Styled.ContentStyled>
      <Styled.ContentStyled>
        <Styled.ButtonStyled onClick={() => navigate(`${link}`)} theme="quaternary">
          View Details
          <Styled.IconStyled icon={faChevronRight} />
        </Styled.ButtonStyled>
      </Styled.ContentStyled>
    </Styled.CardWrapperStyled>
  );
};
