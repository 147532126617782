import React from 'react';

import { PageTitle } from '../../../../../components';

import * as Styled from './styles';

type DeleteTemplateModalProps = {
  id: string;
  name: string;
  deleteTemplate: (id: string) => void;
  closeFn: () => void;
};

const DeleteTemplateModal = ({ id, name, closeFn, deleteTemplate }: DeleteTemplateModalProps) => (
  <Styled.WrapperStyled>
    <PageTitle>Delete Template</PageTitle>

    <Styled.TextStyled>
      The template <strong>{name}</strong> is about to be permanently deleted and you won&apos;t be able to restore it.
    </Styled.TextStyled>

    <Styled.ButtonAreaStyled>
      <Styled.ButtonStyled theme="secondary" onClick={closeFn}>
        Cancel
      </Styled.ButtonStyled>
      <Styled.ButtonStyled
        onClick={() => {
          deleteTemplate(id);
          closeFn();
        }}
      >
        Delete
      </Styled.ButtonStyled>
    </Styled.ButtonAreaStyled>
  </Styled.WrapperStyled>
);

export default DeleteTemplateModal;
