import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components';
import { colors, fonts } from '../../styles/theme';

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 390px calc(100% - 390px);
  height: 100vh;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.color1};
`;

export const RightStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightContentStyled = styled.div`
  width: 65%;
  display: flex;
  flex-flow: column nowrap;

  label {
    margin-bottom: 2rem;
  }

  h1 {
    color: ${colors.color1};
    margin-bottom: 4rem;
    ${fonts.gotham.H36M}
  }
`;

export const LinkStyled = styled(Link)`
  color: ${colors.color1};
  ${fonts.lato.H16}

  &:hover {
    color: ${colors.color2};
  }
`;

export const RowStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;

  &:first-of-type {
    margin-bottom: 2rem;
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  padding: 1rem;

  &:first-of-type {
    margin-right: 2rem;
  }
`;
