import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Pagination, Table, Button, InputCalendar, Calendar, FixedFooter } from '../../../../../../components';
import { DESC_ASC_OPTIONS, EDIT_COMMISSIONS, SORT_DATE } from '../../enums';
import { useEditCommissions } from '../../hooks';

import * as Styled from './styles';
import { columns } from './contracts/columns';

type PublishersGroupsProps = {
  hook: ReturnType<typeof useEditCommissions>;
  isReadOnly: boolean;
};

export const PublishersGroups = ({ hook, isReadOnly }: PublishersGroupsProps) => (
  <Styled.WrapperStyled>
    <Styled.FiltersStyled>
      <Styled.InputTextStyled
        placeholder={EDIT_COMMISSIONS.PUBLISHERS.SEARCH_PLACEHOLDER}
        type="text"
        faIcon={faSearch}
        value={hook.hookSearch}
        onChange={(e) => hook.hookSearchHandler(e)}
        onClear={hook.hookClearSearch}
      />
      <Styled.TextSpanStyled>{EDIT_COMMISSIONS.PUBLISHERS.SORT_BY.LABEL}</Styled.TextSpanStyled>
      <Styled.SelectStyled
        label={EDIT_COMMISSIONS.PUBLISHERS.ORDER_DATE.LABEL}
        name={EDIT_COMMISSIONS.PUBLISHERS.ORDER_DATE.NAME}
        options={SORT_DATE}
        selected={hook.hookSortByDateSelected}
        onChange={hook.hookSetSortByDateSelected}
      />
      <Styled.SelectStyled
        label={EDIT_COMMISSIONS.PUBLISHERS.ORDER.LABEL}
        name={EDIT_COMMISSIONS.PUBLISHERS.ORDER.NAME}
        options={DESC_ASC_OPTIONS}
        selected={hook.hookSortByOrderSelected}
        onChange={hook.hookSetSortByOrderSelected}
      />
    </Styled.FiltersStyled>
    <Styled.ContentStyled>
      <Table columns={columns(isReadOnly)} data={hook.hookTableData} handleCheckBox={hook.hookHandleChangeCheck} />
      <Pagination total={hook.hookPageTotal} currentPage={hook.hookCurrentPage} onPageChange={hook.hookSetPage} />
    </Styled.ContentStyled>
    <FixedFooter>
      <Styled.FooterWrapperStyled>
        <Styled.DatesStyled>
          <Styled.DateHeaderStyled>{EDIT_COMMISSIONS.PUBLISHERS.FOOTER.TEXT}</Styled.DateHeaderStyled>
          <Styled.CalendarsStyled>
            <InputCalendar
              placeholder={EDIT_COMMISSIONS.PUBLISHERS.FOOTER.START_DATE}
              value={hook.hookStartDate?.toDateString() || ''}
              onClick={() => hook.hookSetStartCalendarState(true)}
              isDisabled={isReadOnly || hook.disableStartDate || hook.disableDates}
            />
            <InputCalendar
              placeholder={EDIT_COMMISSIONS.PUBLISHERS.FOOTER.END_DATE}
              value={hook.hookEndDate?.toDateString() || ''}
              onClick={() => hook.hookSetEndCalendarState(true)}
              isDisabled={isReadOnly || hook.disableDates}
            />
          </Styled.CalendarsStyled>
        </Styled.DatesStyled>
        <Styled.ButtonContStyled>
          {hook.hookUpdateDateWarning && (
            <Styled.WarningStyled>{EDIT_COMMISSIONS.PUBLISHERS.FOOTER.WARNING}</Styled.WarningStyled>
          )}
          <Button theme="tertiary" onClick={hook.hookUpdateDateHandler} disabled={isReadOnly}>
            {EDIT_COMMISSIONS.BUTTONS.UPDATE}
          </Button>
        </Styled.ButtonContStyled>
      </Styled.FooterWrapperStyled>
    </FixedFooter>
    <Calendar
      key={`$startCalendar${hook.hookStartDateMin}-${hook.hookStartDateMax}`}
      date={hook.hookStartDate}
      onApply={hook.hookOnApplyStartCalendar}
      onCancel={() => hook.hookSetStartCalendarState(false)}
      isOpen={hook.hookStartCalendarOpen}
      minDate={hook.hookStartDateMin}
      maxDate={hook.hookStartDateMax}
      isSingle
    />
    <Calendar
      key={`$endCalendar${hook.hookEndDateMin}-${hook.hookEndDateMax}`}
      date={hook.hookEndDate}
      onApply={hook.hookOnApplyEndCalendar}
      onCancel={() => hook.hookSetEndCalendarState(false)}
      isOpen={hook.hookEndCalendarOpen}
      minDate={hook.hookEndDateMin}
      maxDate={hook.hookEndDateMax}
      isSingle
    />
  </Styled.WrapperStyled>
);
