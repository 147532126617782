import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import { AppWrapper, Button, LoadingDots, PageTitle, Pagination, Table } from '../../../../components';
import { MERCHANT_PREFIX, path } from '../../../../utils';
import { DefaultPropsType } from '../../../../types';

import { columns } from './contracts';
import { usePublisherGroup } from './hooks';
import { PUBLISHER_GROUPS } from './enum';
import * as Styled from './styles';

type PublisherGroupsProps = DefaultPropsType;

const PublisherGroups = ({ permissionsCodeList = [] }: PublisherGroupsProps) => {
  const navigate = useNavigate();
  const { hookIsReadOnlyList, ...hook } = usePublisherGroup(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  const goToAddGroup = () => {
    navigate(`${MERCHANT_PREFIX}${path.addPublisherGroup.href}`);
  };

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <PageTitle>{path.publisherGroups.name}</PageTitle>
        <Button theme="tertiary" onClick={goToAddGroup} disabled={isReadOnly}>
          <Styled.IconStyled icon={faPlus} />
          {PUBLISHER_GROUPS.ADD}
        </Button>
      </Styled.HeaderStyled>

      {hook.hookLoading ? (
        <LoadingDots />
      ) : (
        <>
          <Table columns={columns} data={hook.hookData} noHorizontalScroll />
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookChangePage}
          />
        </>
      )}
    </AppWrapper>
  );
};

export default PublisherGroups;
