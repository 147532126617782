import styled from 'styled-components';

import { colors } from '../../../styles/theme';

type BodyProps = {
  readonly checked: boolean;
  readonly disabled?: boolean;
};

export const WrapperStyled = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 25px;
  box-sizing: border-box;
  cursor: pointer;

  input {
    display: none;
  }
`;

export const BodyStyled = styled.div<BodyProps>`
  width: 44px;
  height: 24px;
  background: ${({ checked, disabled }) => {
    if (checked) {
      return colors.color2;
    }
    if (disabled) {
      return colors.color7;
    }
    return colors.color1;
  }};
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 2px 2px;
  transition: all 0.2s ease;
`;

export const BallStyled = styled.div<BodyProps>`
  border-radius: 100%;
  background: white;
  height: 20px;
  width: 20px;
  transform: ${({ checked }) => (checked ? 'translateX(100%)' : 'translateX(0)')};
  transition: all 0.2s ease;
`;
