import { gql } from '@apollo/client';

export const LIST_MERCHANT_FILTER_OPTIONS = gql`
  query getSearchCompaniesFilterOptions($input: SearchCompaniesFilterOptionsInput) {
    getSearchCompaniesFilterOptions(input: $input) {
      accountStatusTypes {
        type
        merchantDisplayValue
      }
    }
  }
`;
