import React from 'react';

import { AppWrapper, Checkbox, PageTitle, Tabs } from 'components';
import { path } from 'utils';
import { DefaultPropsType } from 'types';
import { useUserInfo } from 'hooks';

import { ProductInformation } from './Tabs';
import { useNewProduct } from './hooks';
import * as Styled from './styles';

type NewProductProps = DefaultPropsType;

const NewProduct = ({ permissionsCodeList = [] }: NewProductProps): JSX.Element => {
  const { hookIsReadOnlyList, ...hook } = useNewProduct(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;
  const userInfo = useUserInfo();
  const isAdmin = userInfo?.hookUserInfo?.userTypesId === 1;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <PageTitle>{path.addNewProduct.name}</PageTitle>
      <Styled.HeaderSectionStyled>
        {isAdmin && (
          <>
            <Checkbox
              checked={hook.hookNotifyPublisher}
              label=""
              onChange={hook.hookSetNotifyPublisher}
              disabled={isReadOnly}
            />{' '}
            Notify Publishers
          </>
        )}
      </Styled.HeaderSectionStyled>

      <Tabs
        tabNames={['Product Information']}
        elements={[<ProductInformation isReadOnly={isReadOnly} key="Product Information" />]}
      />
    </AppWrapper>
  );
};

export default NewProduct;
