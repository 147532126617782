import { gql } from '@apollo/client';

export const GET_SUBSCRIPTIONS = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      subscribeMerchantIds
    }
  }
`;
