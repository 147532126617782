enum ErrorMessage {
  FAILED_TO_SAVE = 'Could not save the values in the local storage for ',
  FAILED_TO_READ = 'Could not get the values in the local storage for ',
}

/**
 * Save the search values to the local storage
 * @param {string} storageTag this tag will be the identifier for the local storage
 * @param {any} item this is the item that will be saved in the local storage
 */
export const updateSavedSearchLocalStorageValues = (storageTag: string, item?: any) => {
  try {
    if (item) {
      const toBeSaved: any = {};
      Object.keys(item).forEach((key) => {
        toBeSaved[key] = item[key];
      });
      const saved = localStorage.getItem(storageTag);
      localStorage.setItem(
        storageTag,
        JSON.stringify({
          ...JSON.parse(saved || '{}'),
          ...toBeSaved,
        })
      );
    }
  } catch (e) {
    throw new Error(`${ErrorMessage.FAILED_TO_SAVE} ${storageTag}`);
  }
};

/**
 * Initialize the local storage values
 * @param {string} storageTag this tag will be the identifier for the local storage
 * @param {any} objectToBeSaved this is the object that will be saved in the local storage
 */
export const initializeLocalStorageValues = (storageTag: string, objectToBeSaved: any) => {
  try {
    localStorage.setItem(storageTag, JSON.stringify(objectToBeSaved));
  } catch (e) {
    throw new Error(`${ErrorMessage.FAILED_TO_SAVE} ${storageTag}`);
  }
};

/**
 * Get the local storage values
 * @param {string} storageTag this tag will be the identifier for the local storage
 * @param {any} objectToBeSaved this is the object that will be saved in the local storage
 * @returns {string} the values in the local storage for a given tag
 */
export const getLocalStorageValues = (storageTag: string) => {
  try {
    return localStorage.getItem(storageTag);
  } catch (e) {
    throw new Error(`${ErrorMessage.FAILED_TO_READ} ${storageTag}`);
  }
};
