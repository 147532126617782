import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faMinusCircle, faPen, faPowerOff } from '@fortawesome/free-solid-svg-icons';

import { Button, Dropdown } from '../../../../../components';
import { useDropdown } from '../../../../../hooks';

import * as Styled from './styles';
import { DROPDOWN_LABEL, STATUS_TYPES, TABLE_ACTIONS } from './enum';

type DropdrowProps = {
  handleModel: (type: string, row: any) => void;
  row: any;
};

const DropDown = ({ row, handleModel }: DropdrowProps) => {
  const dropdownHook = useDropdown();

  return (
    <Styled.CellDivStyled>
      <Dropdown
        isOpen={dropdownHook.isOpen}
        setIsOpen={() => dropdownHook.setIsOpen(!dropdownHook.isOpen)}
        dropdownRef={dropdownHook.dropdownRef}
        text=""
        iconClass={faEllipsis}
        leftIcon
        top="-80%"
        right="100%"
      >
        <Styled.DropdownChildStyled>
          <Button
            onClick={() => {
              handleModel(TABLE_ACTIONS.EDIT, row);
              dropdownHook.setIsOpen(false);
            }}
            theme="quaternary"
            width="100%"
          >
            <Styled.ButtonStyled>
              <FontAwesomeIcon icon={faPen} />
              <p>{DROPDOWN_LABEL.EDIT}</p>
            </Styled.ButtonStyled>
          </Button>
          <Button
            onClick={() => {
              handleModel(row.status === STATUS_TYPES.ACTIVE ? TABLE_ACTIONS.DEACTIVE : TABLE_ACTIONS.ACTIVE, row);
              dropdownHook.setIsOpen(false);
            }}
            theme="quaternary"
            width="100%"
          >
            <Styled.ButtonStyled>
              <FontAwesomeIcon icon={row.status === STATUS_TYPES.ACTIVE ? faMinusCircle : faPowerOff} />
              <p>{row.status === STATUS_TYPES.ACTIVE ? DROPDOWN_LABEL.INACTIVE : DROPDOWN_LABEL.ACTIVE}</p>
            </Styled.ButtonStyled>
          </Button>
        </Styled.DropdownChildStyled>
      </Dropdown>
    </Styled.CellDivStyled>
  );
};

export const useColumns = (handleModel: (type: string, row: any) => void, editPermissions: boolean, id: any) => {
  const columns: TableColumn[] = [
    {
      dataField: 'fullName',
      text: 'Full Name',
      width: '35%',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (val: string, row: any) => (
        <Styled.CellStyled theme="secondary">
          {val}
          {row.position && <Styled.SubTextStyled>{row.position}</Styled.SubTextStyled>}
        </Styled.CellStyled>
      ),
    },
    {
      dataField: 'securityRole',
      text: 'Security Role',
      width: '30%',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (val: string) => (
        <Styled.CellStyled>
          <p>{val}</p>
        </Styled.CellStyled>
      ),
    },
    {
      dataField: 'status',
      text: 'Status',
      width: '15%',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (val: string) => (
        <Styled.CellStyled>
          <p>{val}</p>
        </Styled.CellStyled>
      ),
    },
    {
      dataField: 'lastLogin',
      text: 'Last Login',
      width: '15%',
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
      formatter: (val: string) => (
        <Styled.CellStyled>
          <p>{val}</p>
        </Styled.CellStyled>
      ),
    },
    {
      dataField: editPermissions ? 'status' : '',
      text: '',
      width: '5%',
      cellOverflow: true,
      formatter: (_: any, row: any) => {
        if (editPermissions || id === row.id) {
          return <DropDown handleModel={(action: string) => handleModel(action, row)} row={row} />;
        }
        return <div />;
      },
    },
  ];

  return {
    columns,
  };
};
