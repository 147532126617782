import { gql } from '@apollo/client';

export const GET_COMPANIES = gql`
  query company($companyId: ID!) {
    company(id: $companyId) {
      id
      trackings {
        id
        profileName
        primaryWebsite
        websiteDescription
        databaseSize
        productFeeds {
          id
          trackingProfileId
          merchants {
            id
            companyName
          }
          products {
            id
          }
          productCategories
          subCategories
          regionCountry
          regionStates
          productFeedUrl
        }
      }
      memberships {
        id
        status
        merchant {
          id
          companyName
        }
        program {
          id
          products {
            id
            customizedProductId
            productFeedSection {
              legalReference
            }
          }
        }
      }
    }
  }
`;
