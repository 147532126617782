import React from 'react';

import { Select } from '../../../../../components';
import { BUTTON_LABEL, MODAL_DESCRIPTION, MODAL_LABEL, MODAL_TITLE } from '../../contracts';

import * as Styled from './styles';

type AccountBalanceModalProps = {
  merchantList: SelectOption[];
  selectedMerchant: SelectOption;
  hookSetSeletedMerchantHandler: (value: SelectOption) => void;
  periodList: SelectOption[];
  selectedPeriod?: SelectOption;
  hookSetSelectedPeriod: (value: SelectOption) => void;
  hookGetAccountBalances: () => void;
  cancelModal: () => void;
};

export const AccountBalanceModal = ({
  merchantList,
  hookSetSeletedMerchantHandler,
  selectedMerchant,
  periodList,
  selectedPeriod,
  hookSetSelectedPeriod,
  hookGetAccountBalances,
  cancelModal,
}: AccountBalanceModalProps) => (
  <Styled.ModalContentWrapperStyled>
    <Styled.ModalTitleStyled>{MODAL_TITLE}</Styled.ModalTitleStyled>
    <Styled.ModalDescriptionStyled>{MODAL_DESCRIPTION}</Styled.ModalDescriptionStyled>
    <Styled.ModalSelectionsStyled>
      <Select
        label={MODAL_LABEL.MERCHANTS}
        options={merchantList}
        selected={selectedMerchant}
        onChange={hookSetSeletedMerchantHandler}
        name="merchants"
      />
      <Select
        label={MODAL_LABEL.PERIOD}
        options={periodList}
        selected={selectedPeriod}
        onChange={hookSetSelectedPeriod}
        name="period"
        isDisabled={selectedPeriod?.value === 'all'}
      />
    </Styled.ModalSelectionsStyled>
    <Styled.ModalButtonsStyled>
      <Styled.ModalButtonStyled onClick={cancelModal} theme="secondary">
        {BUTTON_LABEL.CANCEL}
      </Styled.ModalButtonStyled>
      <Styled.ModalButtonStyled onClick={hookGetAccountBalances}>{BUTTON_LABEL.NEXT}</Styled.ModalButtonStyled>
    </Styled.ModalButtonsStyled>
  </Styled.ModalContentWrapperStyled>
);
