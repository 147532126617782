import styled from 'styled-components';

import { colors, fonts } from '../../../../../../styles/theme';
import { InputText, Select } from '../../../../../../components';

export const SetStatusContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1.5rem;
`;

export const SubtitleStyled = styled.p`
  color: ${colors.color1};
  ${fonts.gotham.H18B};
`;

export const NoteInputStyled = styled(InputText)``;

export const SelectStyled = styled(Select)``;
