import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../../../components';

import * as Styled from './styles';
import { PLACEHOLDER } from './enum';

type PlaceholderProps = {
  link: string;
};

export const Placeholder = ({ link }: PlaceholderProps) => {
  const navigate = useNavigate();
  return (
    <Styled.WrapperStyled>
      <Styled.TitleStyled>{PLACEHOLDER.TITLE}</Styled.TitleStyled>
      <Styled.DescriptionStyled>{PLACEHOLDER.DESCRIPTION}</Styled.DescriptionStyled>
      <Button onClick={() => navigate(link)}>
        <FontAwesomeIcon icon={faPlus} />
        {` ${PLACEHOLDER.CREATE}`}
      </Button>
    </Styled.WrapperStyled>
  );
};
