import { gql } from '@apollo/client';

export const SHOW_MERCHANT_INFO = gql`
  query getFiltersForReports($input: CompanyInput) {
    companyOptions(input: $input) {
      companies {
        id
        companyName
        program {
          id
          campaigns {
            id
            name
          }
          products {
            id
            name
            status
            customizedProductId
            productCategory
          }
          publisherGroups {
            id
            name
          }
          ads {
            id
            adName
          }
        }
        memberships {
          id
          publisher {
            id
            companyName
            trackings {
              id
              primaryWebsite
              websiteDescription
              profileName
              trackingProfileType
            }
          }
        }
      }
    }
  }
`;

export type ShowMerchantInputType = {
  input: {
    companyType: string;
    accountStatus?: string;
    id: string;
  };
};

export type ShowMerchantTrackingsType = {
  id: string;
  primaryWebsite: string;
  profileName: string;
  trackingProfileType: string;
  websiteDescription: string;
  /** Not Part of return, Added After for reference */
  publisherId?: string;
};

export type ShowMerchantCampaignsType = {
  id: string;
  name: string;
};

export type ShowMerchantProductsType = {
  id: string;
  name: string;
  status: string;
  customizedProductId: string;
  productCategory: string;
};

export type ShowMerchantPublisherGroupsType = {
  id: string;
  name: string;
};

export type ShowMerchantAdsType = {
  id: string;
  adName: string;
};

export type ShowMerchantProgramType = {
  id: string;
  campaigns: ShowMerchantCampaignsType[];
  products: ShowMerchantProductsType[];
  publisherGroups: ShowMerchantPublisherGroupsType[];
  ads: ShowMerchantAdsType[];
};

export type ShowMerchantPublisherType = {
  id: string;
  companyName: string;
  trackings: ShowMerchantTrackingsType[];
};

export type ShowMerchantMembershipsType = {
  id: string;
  publisher: ShowMerchantPublisherType;
};

export type ShowMerchantCompaniesType = {
  id: string;
  companyName: string;
  program: ShowMerchantProgramType;
  memberships: ShowMerchantMembershipsType[];
};

export type ShowMerchantOutputType = {
  companyOptions: {
    companies: ShowMerchantCompaniesType[];
  };
};
