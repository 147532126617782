import React from 'react';

import { CellWrapper } from '../../styles';
import { ColumnProps } from '../../types';
import { dateFormatter } from '../../../../../../../../../utils';

export const DateCell = ({ value }: ColumnProps) => {
  const date = value ? dateFormatter(new Date(value)) : '';
  return (
    <CellWrapper value={value}>
      <p>{date}</p>
    </CellWrapper>
  );
};
