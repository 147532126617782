import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';

type WrapperProps = {
  readonly type: 'error';
  readonly isOpen: boolean;
};

const colorSwitch = (type: string) => {
  switch (type) {
    case 'error':
      return colors.color4;
    default:
      return colors.color2;
  }
};

const backgroundSwitch = (type: string) => {
  switch (type) {
    case 'error':
      return colors.color14;
    default:
      return colors.color1;
  }
};

export const WrapperStyled = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  max-width: 800px;
  background: ${({ type }) => backgroundSwitch(type)};
  border: 2px solid ${({ type }) => colorSwitch(type)};
  color: ${({ type }) => colorSwitch(type)};
  top: 0;
  transition: all 0.2s ease-in-out;

  transform: translateY(${({ isOpen }) => (isOpen ? '2rem' : '-100%')});

  ${fonts.lato.H16}

  svg {
    margin-right: 0.5rem;
  }
`;
