import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';

import { LoadingDots, Pagination, Placeholder, Table } from 'components';

import { TRACKING_DETAILS } from '../../enums';

import * as Styled from './styles';
import { columns } from './contracts';
import { TRACKING_PROFILES } from './enums';

type TrackingDomainsTabProps = {
  modalHook: () => void;
  isOpen: boolean;
  hook: any;
  downloadButton: (show: boolean) => void;
  isReadOnly: boolean;
};

const TrackingProfilesTab = ({
  modalHook,
  isOpen,
  hook,
  downloadButton,
  isReadOnly,
}: TrackingDomainsTabProps): JSX.Element => {
  downloadButton(false);
  const [, setOpenState] = useState(false);

  useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen]);

  return (
    <Styled.WrapperStyled>
      <Styled.TextStyled>
        <p>{TRACKING_PROFILES.DESCRIPTION}</p>
        <p>{TRACKING_PROFILES.INSTRUCTION}</p>
      </Styled.TextStyled>

      {hook.hookLoading && <LoadingDots />}

      {hook.hookTrackingProfilesData.length > 0 && !hook.hookLoading && (
        <>
          <Table
            columns={columns(isReadOnly)}
            data={hook.hookTrackingProfilesData}
            setOpenModal={modalHook}
            setModalInfo={hook.hookAddProfile.hookSetModalInfo}
            onSort={hook.hookSetSortBy}
            sortColumn={hook.hookSortBy}
          />
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookSetCurrentPage}
          />
        </>
      )}

      {hook.hookTrackingProfilesData.length === 0 && !hook.hookLoading && (
        <Placeholder
          title={TRACKING_DETAILS.NO_PROFILES}
          action={modalHook}
          buttonIcon={faPlus}
          buttonText={TRACKING_DETAILS.ADD_PROFILE}
        />
      )}
    </Styled.WrapperStyled>
  );
};

export default TrackingProfilesTab;
