import styled from 'styled-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { toRem, PUBLISHER_PREFIX, path } from '../../../../../utils';
import { colors } from '../../../../../styles/theme';
import { Button } from '../../../../../components';
import { isStringEmptyOrUndefined } from '../utils';

type TextStyledProps = {
  readonly theme?: string;
};

const LongTextWrapStyled = styled.div`
  max-width: 100%;
  white-space: pre-wrap;
  line-height: 1.5;
`;

const TextStyled = styled.p<TextStyledProps>`
  font-size: ${toRem(14)};
  font-weight: ${({ theme }) => (theme === 'main' ? 'bold' : 'normal')};
  color: ${({ theme }) => (theme === 'main' ? colors.color1 : colors.color13)};
`;

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;

const ActionCell = ({ row }: { row: any }) => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${PUBLISHER_PREFIX}${path.productFeedDetails.href}`, { state: row.id });
  };
  return (
    <CellWrapper>
      <Button theme="quaternary" onClick={goTo}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </CellWrapper>
  );
};

const FormatItems = (mainValue: any, secondaryValue: any) => (
  <CellWrapper>
    <TextStyled theme="main">{mainValue}</TextStyled>
    <TextStyled>{secondaryValue}</TextStyled>
  </CellWrapper>
);

const LongTextFormat = (value: any) => <LongTextWrapStyled>{value}</LongTextWrapStyled>;

export const columns: TableColumn[] = [
  {
    dataField: 'trackingProfileId',
    text: 'Tracking Profile ID',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '19.5%',
  },
  {
    dataField: 'merchants',
    text: 'Merchant',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <div>{isStringEmptyOrUndefined(value[0]?.companyName) ? 'All Merchants' : value[0]?.companyName}</div>
    ),
    width: '19.5%',
  },
  {
    dataField: 'productCategories',
    text: 'Product Category',
    formatter: (value: any) =>
      LongTextFormat(isStringEmptyOrUndefined(value?.join(', ')) ? 'All Categories' : value?.join(', ')),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '19.5%',
  },
  {
    dataField: 'subCategories',
    text: 'Subcategory',
    formatter: (value: any) =>
      LongTextFormat(isStringEmptyOrUndefined(value?.join(', ')) ? 'All Subcategories' : value?.join(', ')),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '19.5%',
  },
  {
    dataField: 'region',
    text: 'Region',
    formatter: (_: any, row: any) =>
      FormatItems(
        isStringEmptyOrUndefined(row.regionCountry) ? 'International' : row.regionCountry,
        isStringEmptyOrUndefined(row.regionStates.join(', ')) ? 'All Areas' : row.regionStates?.join(', ')
      ),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '19.5%',
  },
  {
    dataField: '_',
    text: '',
    formatter: (_: any, row: any) => <ActionCell row={row} />,
    width: '2%',
  },
];
