import { gql } from '@apollo/client';

export const GET_MEMBERSHIPS = gql`
  query memberships($input: MembershipSearchInput!) {
    memberships(input: $input) {
      memberships {
        id
        publisher {
          id
          companyName
          companyUrl
        }
        status
        approvedDate
      }
    }
  }
`;

export type GetMembershipsInput = {
  input: {
    merchantId: string;
    status: 'Approved';
  };
};

export type GMMembershipType = {
  id: string;
  publisher: {
    id: string;
    companyName: string;
    companyUrl: string;
  };
  status: string;
  approvedDate: string;
};

export type GetMembershipsOutput = {
  memberships: {
    memberships: GMMembershipType[];
  };
};
