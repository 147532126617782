import styled from 'styled-components';

import { Button } from '../../../../components';
import { fonts } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${toRem(50)};
  min-width: 751px;
  min-height: 443px;
  ${fonts.lato.H16};
  line-height: ${toRem(24)};
`;

export const ModalTitleStyled = styled.div`
  ${fonts.gotham.H26M}
`;

export const ModalListStyled = styled.ul`
  list-style: disc;
  list-style-position: inside;
  ${fonts.lato.H16};
`;

export const ModalListItemStyled = styled.li``;

export const ButtonContainerStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;

  svg {
    margin-right: ${toRem(5)};
  }
`;
