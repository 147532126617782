import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { MERCHANT_PREFIX, path } from '../../../../utils';
import { Button } from '../../../../components';
import { CAMPAIGN } from '../enums';

const ButtonStyled = styled(Button)`
  svg {
    margin-right: 5px;
  }
`;

export const useRenderButton = () => {
  const navigate = useNavigate();
  const displayButton = (show: boolean, isReadonly: boolean): JSX.Element | null => {
    if (show) {
      return (
        <ButtonStyled
          theme="tertiary"
          onClick={() => navigate(`${MERCHANT_PREFIX}${path.createCampaign.href}`)}
          disabled={isReadonly}
        >
          <FontAwesomeIcon icon={faPlus} />
          {CAMPAIGN.ADD_BUTTON}
        </ButtonStyled>
      );
    }
    return null;
  };

  return {
    hookDisplayButton: displayButton,
  };
};
