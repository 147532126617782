import React, { useEffect } from 'react';
import { faCircleXmark, faTriangleExclamation, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import { AppWrapper, Calendar, InputCalendar, PageTitle, Spinner } from '../../components';
import { colors } from '../../styles/theme';
import { USER_TYPES_ID, path, MERCHANT_PREFIX, PUBLISHER_PREFIX } from '../../utils';
import { useUserInfo } from '../../hooks';
import { DefaultPropsType } from '../../types';

import LineChart from './Charts/Line';
import { topAdsColumns, topMerchantsColumns, topProductsColumns, topPublishersColumns } from './contracts';
import { useDashboard } from './hooks';
import * as Styled from './styles';

type DashboardPropsType = DefaultPropsType;

const Dashboard = ({ permissionsCodeList = [] }: DashboardPropsType) => {
  const userHook = useUserInfo();
  const navigate = useNavigate();
  const hook = useDashboard(permissionsCodeList);

  useEffect(() => {
    if (userHook.hookUserInfo.userTypesId === USER_TYPES_ID.MERCHANT)
      navigate(`${MERCHANT_PREFIX}${path.dashboard.href}`);
    if (userHook.hookUserInfo.userTypesId === USER_TYPES_ID.PUBLISHER)
      navigate(`${PUBLISHER_PREFIX}${path.dashboard.href}`);
  }, [userHook.hookUserInfo.userTypesId]);

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      {hook.hookCanAccess ? (
        <>
          <Styled.WrapperStyled>
            <Styled.TopContainerStyled hasAccess={hook.hookCanAccess}>
              <PageTitle>{path.dashboard.name}</PageTitle>

              <InputCalendar value={hook.hookDateRange} width="350px" onClick={() => hook.hookOpenCalendar()} />
            </Styled.TopContainerStyled>

            <Styled.ContentStyled>
              <Styled.ContentColumnStyled>
                <Styled.BoxStyled>
                  {hook.hookIsLoading && (
                    <Styled.LoadingStyled>
                      <Spinner size="1.5rem" theme="secondary" />
                    </Styled.LoadingStyled>
                  )}
                  <Styled.SmallTextStyled>Pending Network Applications</Styled.SmallTextStyled>
                  <Styled.BigNumberStyled>
                    <Styled.IconStyled icon={faUser} color={colors.color17} />
                    <span>{hook.hookPendingApplications}</span>
                  </Styled.BigNumberStyled>
                </Styled.BoxStyled>

                <Styled.BoxStyled>
                  <Styled.SmallTextStyled>Merchant Account Balance</Styled.SmallTextStyled>
                  <Styled.BigNumberStyled>
                    <Styled.IconStyled icon={faTriangleExclamation} color={colors.color2} />
                    <span>0</span>

                    <Styled.IconStyled icon={faCircleXmark} color={colors.color14} />
                    <span>0</span>
                  </Styled.BigNumberStyled>
                </Styled.BoxStyled>

                <Styled.SectionTitleStyled>Top Products</Styled.SectionTitleStyled>
                <Styled.BoxStyled>
                  <Styled.TableStyled
                    columns={topProductsColumns}
                    data={hook.hookProductData}
                    isLoading={hook.hookIsLoading}
                    spinner
                  />
                </Styled.BoxStyled>

                <Styled.SectionTitleStyled>Top Ads</Styled.SectionTitleStyled>
                <Styled.BoxStyled>
                  <Styled.TableStyled
                    columns={topAdsColumns}
                    data={hook.hookAdData}
                    isLoading={hook.hookIsLoading}
                    spinner
                  />
                </Styled.BoxStyled>
              </Styled.ContentColumnStyled>

              <Styled.ContentColumnStyled>
                <Styled.SectionTitleStyled>Top Merchants</Styled.SectionTitleStyled>
                <Styled.BoxStyled>
                  <Styled.TableStyled
                    columns={topMerchantsColumns}
                    data={hook.hookMerchantData}
                    isLoading={hook.hookIsLoading}
                    spinner
                  />
                </Styled.BoxStyled>

                <Styled.FlexStyled>
                  <Styled.FlexTitleStyled>Merchant Performance</Styled.FlexTitleStyled>
                  <Styled.SelectStyled
                    selected={hook.hookTopMerchant}
                    options={hook.hookTopMerchants}
                    onChange={hook.hookSetMerchantPerformance}
                    isLoading={hook.hookIsLoading}
                    isDisabled={hook.hookIsLoading}
                  />
                </Styled.FlexStyled>
                <Styled.BoxStyled>
                  {hook.hookIsLoading && (
                    <Styled.LoadingStyled>
                      <Spinner size="1.5rem" theme="secondary" />
                    </Styled.LoadingStyled>
                  )}
                  <LineChart data={hook.hookMerchantPerformance} />
                </Styled.BoxStyled>

                <Styled.SectionTitleStyled>Top Publishers</Styled.SectionTitleStyled>
                <Styled.BoxStyled>
                  <Styled.TableStyled
                    columns={topPublishersColumns}
                    data={hook.hookPublisherData}
                    isLoading={hook.hookIsLoading}
                    spinner
                  />
                </Styled.BoxStyled>
              </Styled.ContentColumnStyled>
            </Styled.ContentStyled>
          </Styled.WrapperStyled>

          <Calendar
            isOpen={hook.hookCalendarOpen}
            date={hook.hookStartDate}
            onApply={(startDate, endDate, range) => hook.hookSetDateRange(startDate, endDate, range)}
            onCancel={() => hook.hookCloseCalendar()}
          />
        </>
      ) : (
        <Styled.WrapperStyled>
          <Styled.TopContainerStyled hasAccess={hook.hookCanAccess}>
            <PageTitle>Welcome to the Admin Home page</PageTitle>

            <Styled.TitleStyled>Please select an option from the side menu to get started</Styled.TitleStyled>
          </Styled.TopContainerStyled>
        </Styled.WrapperStyled>
      )}
    </AppWrapper>
  );
};

export default Dashboard;
