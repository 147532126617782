import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { useQueries, useToast, useUserInfo } from '../../../../../hooks';
import { GET_GROUP } from '../graphql/queries';
import { UPDATE_GROUP } from '../graphql/mutations';
import { Permission } from '../../../../../entities';

export const useEditGroup = (permissionsCodeList: string[] = []) => {
  const navigate = useNavigate();
  const { id } = useQueries();
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  const [getGroup, { loading }] = useLazyQuery(GET_GROUP);
  const [updateGroup] = useMutation(UPDATE_GROUP);

  const [groupName, setGroupName] = useState<string>('');
  const [groupNameError, setGroupNameError] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');

  const getGroupHandler = async () => {
    const { data } = await getGroup({
      variables: {
        input: id,
      },
      fetchPolicy: 'no-cache',
    });

    if (data.group !== undefined) {
      setGroupName(data.group.name);
      setDescription(data.group.description);
    }
  };

  const saveGroupHandler = async () => {
    if (groupName === '') setGroupNameError('Please enter valid group name.');
    if (description === '') setDescriptionError('Please enter description.');
    if (groupName !== '' && description !== '') {
      const { data } = await updateGroup({
        variables: {
          input: {
            id,
            name: groupName,
            description,
            programId: hookWhoAmI.programId,
          },
        },
      });

      if (data?.updateGroup !== undefined) {
        hookShowToast(`${groupName} updated successfully`);
        navigate(-1);
      }
    }
  };

  const setGroupNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupNameError('');
    setGroupName(e.target.value);
  };

  const setDescriptionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescriptionError('');
    setDescription(e.target.value);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getGroupHandler();
  }, []);

  return {
    hookLoading: loading,
    hookGroupName: groupName,
    hookGroupNameError: groupNameError,
    hookSetGroupName: setGroupNameHandler,
    hookDescription: description,
    hookDescriptionError: descriptionError,
    hookSetDescription: setDescriptionHandler,
    hookGoBack: goBack,
    hookSave: saveGroupHandler,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
