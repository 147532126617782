import styled from 'styled-components';

import { Button } from '../../../../../components';
import { colors } from '../../../../../styles/theme';

export const CellWrapperStyled = styled.div`
  display: flex;
  min-height: 40px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-flow: wrap row;
`;

export const SecondLineStyled = styled.div`
  color: ${colors.color16};
  width: 100%;

  a {
    text-decoration: none;
    color: ${colors.color16};

    &:hover {
      color: ${colors.color2};
    }
  }
`;

export const ButtonStyled = styled(Button)`
  background: none;
  border: none;
  color: ${colors.color1};
  font-size: 1.2rem;

  &:hover {
    border: none;
    background: none;
    color: ${colors.color2};
  }
`;
