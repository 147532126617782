import { gql } from '@apollo/client';

export const LIST_SAVED_REPORTS = gql`
  query getSavedReports($user: ReportProfilesSearchInput) {
    reportProfiles(input: $user) {
      reports {
        id
        name
        description
        frequency
        every
        company
        report
        user {
          id
          email
        }
        filters {
          field
          value {
            label
            value
          }
        }
        columns {
          label
          value
        }
      }
    }
  }
`;

export type LSRInputType = {
  user: {
    company: number | null;
    user: {
      id: string;
      userType: string;
    };
    report: string;
  };
};

export type LSRReportFilters = {
  field: string;
  value: SelectOption;
};

export type LSRReportProfileUser = {
  id: string;
  email: string;
};

export type LSRReportType = {
  id: string;
  name: string;
  description: string;
  frequency: string;
  every: string;
  company: number;
  report: string;
  user: LSRReportProfileUser;
  filters: LSRReportFilters[];
  columns: SelectOption[];
};

export type LSROutputType = {
  reportProfiles: {
    reports: LSRReportType[];
  };
};
