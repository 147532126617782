import React from 'react';

import * as Styled from '../styles';
import { CellProps } from '../types';

export const CommissionCell = ({ val }: CellProps) => {
  const primary = val.commission;
  const secondary = val.member;
  return (
    <Styled.CellWrapper>
      <Styled.MainValStyled>{primary}</Styled.MainValStyled>
      <Styled.SecondaryValStyled>{secondary}</Styled.SecondaryValStyled>
    </Styled.CellWrapper>
  );
};
