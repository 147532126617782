import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, fonts } from '../../styles/theme';

export const WrapperStyled = styled(Link)`
  text-decoration: none;
  color: ${colors.color2};
  ${fonts.lato.H14B}
`;
