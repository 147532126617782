import { gql } from '@apollo/client';

export const GET_CURRENCIES_CONVERSION = gql`
  query currenciesConversion($input: CurrenciesConversionSearchInput!) {
    currenciesConversion(input: $input) {
      count
      currenciesConversion {
        id
        currencyFrom
        currencyTo
        conversionRate
        createdAt
      }
    }
  }
`;
