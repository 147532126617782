import React from 'react';

import { imageList } from '../../utils';

import * as Styled from './styles';

type ManagementThumbnail = {
  value?: string;
  url: string;
};

export const ManagementThumbnails = ({ value, url }: ManagementThumbnail) => (
  <Styled.CellWrapperStyled>
    <Styled.ImageWrapperStyled>
      <img src={url || imageList.noPic.src} alt="thumbnail" />
    </Styled.ImageWrapperStyled>
    <Styled.TextStyled>{value}</Styled.TextStyled>
  </Styled.CellWrapperStyled>
);
