import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faArrowDown, faGrip } from '@fortawesome/free-solid-svg-icons';

import {
  AdGenerateCodeModal,
  AppWrapper,
  Button,
  Calendar,
  Checkbox,
  DragTable,
  ErrorBanner,
  InputTextRef,
  LoadReportProfile,
  Modal,
  PageTitle,
  Pagination,
  PLACEHOLDER,
  RecordsDropdown,
  ReportPlaceholder,
  Select,
} from '../../../components';
import { InputCalendar } from '../../../components/Input/Calendar';
import { path, USER_TYPES_ID } from '../../../utils';
import { useUserInfo } from '../../../hooks';
import { SaveReportProfile } from '../PerfomanceReport/Modals/SaveReportProfile';
import { DefaultPropsType } from '../../../types';

import { useTransactionMasterReport } from './hooks';
import CustomizeColumnsModal from './Modals/CustomizeColumns';
import * as Styled from './styles';

type TransactionMasterReportPropsType = DefaultPropsType;

const TransactionMasterReport = ({ permissionsCodeList = [] }: TransactionMasterReportPropsType) => {
  const hook = useTransactionMasterReport();
  const userHook = useUserInfo();

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message={hook.hookErrorMessage} isOpen={!!hook.hookErrorMessage} />
      <Styled.WrapperStyled isAdvance={hook.hookAdvancedSearch}>
        {useMemo(
          () => (
            <Styled.HeaderStyled>
              <PageTitle isLoading={hook.hookLoading || hook.hookTableLoading} loadingText={hook.hookPageLoadingText}>
                {path.transactionMaster.name}
                {hook.hookIsSavedReportLoaded && <Styled.TitleInfoBox>{hook.hookLoadedReportName}</Styled.TitleInfoBox>}
              </PageTitle>

              <Styled.HeaderButtonStyled>
                <LoadReportProfile
                  disabled={hook.hookLoading || hook.hookSavedReportsLoading || !hook.hookCanLoadAndSave}
                  loading={hook.hookLoading || hook.hookSavedReportsLoading}
                  loadSavedReportHandler={hook.hookLoadSavedReport}
                  reportList={hook.hookSavedReports}
                />
                <SaveReportProfile
                  disabled={
                    hook.hookIsDisabled ||
                    hook.hookLoading ||
                    !hook.hookTableGenerated ||
                    !hook.hookCanLoadAndSave ||
                    hook.hookNotMyReportLoaded
                  }
                  onSave={hook.hookOnSave}
                  onUpdate={hook.hookOnUpdate}
                  loading={hook.hookLoading}
                  isUpdate={hook.hookIsSavedReportLoaded}
                  reportName={hook.hookLoadedReportName}
                />
              </Styled.HeaderButtonStyled>
            </Styled.HeaderStyled>
          ),
          [
            hook.hookLoading,
            hook.hookPageLoadingText,
            hook.hookTableLoading,
            hook.hookSavedReportsLoading,
            hook.hookSavedReports,
            hook.hookGlobalColumnState,
            hook.hookTableGenerated,
          ]
        )}

        <Styled.FiltersStyled>
          {useMemo(
            () => (
              <>
                <InputCalendar
                  label="Date Range"
                  value={hook.hookSelectDate}
                  width="100%"
                  onClick={() => hook.hookSetOpenCalendar(!hook.hookOpenCalendar)}
                  isDisabled={hook.hookTableLoading}
                />
                {/* Remove field while acting as merchant */}
                {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.MERCHANT &&
                  userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.MERCHANT && (
                    <Select
                      label="Merchant ID - Name"
                      name="merchantSelect"
                      options={hook.hookMerchantList}
                      selected={hook.hookMerchant}
                      onChange={(option) => hook.hookSetSelectedMerchant(option)}
                      isDisabled={hook.hookLoading || hook.hookTableLoading}
                      width="100%"
                    />
                  )}
                <Select
                  label="Date Type"
                  name="dataTypeSelect"
                  selected={hook.hookDateType}
                  onChange={hook.hookSetDateType}
                  options={hook.hookDateTypeList}
                  width="100%"
                  isLoading={hook.hookMerchantInfoLoading}
                  isDisabled={hook.hookLoading || hook.hookTableLoading}
                />
              </>
            ),
            [
              hook.hookCurrentUserType,
              hook.hookSelectDate,
              hook.hookMerchantList,
              hook.hookMerchant,
              hook.hookLoading,
              hook.hookDateType,
              hook.hookDateTypeList,
              hook.hookMerchantInfoLoading,
              hook.hookTableLoading,
            ]
          )}
          {/* Remove field while acting as publisher */}
          {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER &&
            userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
              <Select
                label="Publisher ID - Name"
                name="publisherSelect"
                selected={hook.hookPublisher}
                onChange={(publisher) => hook.hookSetPublisher(publisher)}
                options={hook.hookPublisherList}
                width="100%"
                isDisabled={hook.hookLoading || hook.hookDisabledFields || hook.hookTableLoading}
                isLoading={hook.hookMerchantInfoLoading}
              />
            )}
          {useMemo(
            () => (
              <>
                <Select
                  label="Product ID - Name"
                  name="categorySelect"
                  selected={hook.hookProduct}
                  onChange={hook.hookSetProduct}
                  options={hook.hookProductList}
                  width="100%"
                  isDisabled={hook.hookLoading || hook.hookDisabledFields || hook.hookTableLoading}
                  isLoading={hook.hookMerchantInfoLoading}
                />
                <Select
                  label="Transaction Status"
                  name="transactionSelect"
                  isLoading={hook.hookMerchantInfoLoading}
                  isDisabled={hook.hookLoading || hook.hookTableLoading}
                  onChange={hook.hookSetTransactionStatus}
                  options={hook.hookTransactionStatusList}
                  selected={hook.hookTransactionStatus}
                  width="100%"
                />
              </>
            ),
            [
              hook.hookProduct,
              hook.hookProductList,
              hook.hookLoading,
              hook.hookDisabledFields,
              hook.hookMerchantInfoLoading,
              hook.hookTransactionStatusList,
              hook.hookTransactionStatus,
              hook.hookTableLoading,
            ]
          )}
        </Styled.FiltersStyled>
        {useMemo(
          () => (
            <Styled.FiltersRowAltStyled>
              <Styled.AdvanceSelectionStyled>
                <Checkbox
                  label="Show Advanced Search"
                  onChange={hook.hookSetAdvancedSearch}
                  partial={false}
                  checked={hook.hookAdvancedSearch}
                />
                <Button theme="text-only" onClick={hook.hookClearFormHandler}>
                  Clear Form
                </Button>
              </Styled.AdvanceSelectionStyled>
            </Styled.FiltersRowAltStyled>
          ),
          [hook.hookSetAdvancedSearch, hook.hookClearFormHandler]
        )}

        <Styled.AdvancedFiltersRowStyled>
          {useMemo(
            () => (
              <Select
                label="Tracking Profile"
                name="trackingSelect"
                selected={hook.hookSelectedTrackingProfile}
                onChange={hook.hookSetSelectedTrackingProfile}
                options={hook.hookTrackingProfileList}
                isDisabled={
                  hook.hookLoading ||
                  (hook.hookDisabledFields && hook.hookCurrentUserType !== 'Publisher') ||
                  hook.hookTableLoading
                }
                isLoading={hook.hookMerchantInfoLoading}
                width="100%"
              />
            ),
            [
              hook.hookSelectedTrackingProfile,
              hook.hookTrackingProfileList,
              hook.hookLoading,
              hook.hookDisabledFields,
              hook.hookMerchantInfoLoading,
              hook.hookTableLoading,
            ]
          )}

          {/* Remove field while acting as publisher */}
          {userHook.hookUserInfo.userTypesId !== USER_TYPES_ID.PUBLISHER &&
            userHook.hookWhoAmI.isActingAsUserTypeId !== USER_TYPES_ID.PUBLISHER && (
              <Select
                label="Publisher Group"
                name="publisherGroupSelect"
                selected={hook.hookPublisherGroup}
                onChange={hook.hookSetPublisherGroup}
                options={hook.hookPublisherGroupList}
                isDisabled={hook.hookLoading || hook.hookDisabledFields || hook.hookTableLoading}
                isLoading={hook.hookMerchantInfoLoading}
                width="100%"
              />
            )}

          {useMemo(
            () => (
              <>
                <Select
                  label="Ad ID"
                  name="adIdSelect"
                  selected={hook.hookAd}
                  onChange={hook.hookSetAd}
                  options={hook.hookAdList}
                  isDisabled={hook.hookLoading || hook.hookDisabledFields || hook.hookTableLoading}
                  isLoading={hook.hookMerchantInfoLoading}
                  width="100%"
                />
                <Select
                  label="Ad Campaign"
                  name="adCampaingSelect"
                  selected={hook.hookAdCampaign}
                  onChange={hook.hookSetAdCampaign}
                  options={hook.hookAdCampaignList}
                  isDisabled={hook.hookLoading || hook.hookDisabledFields || hook.hookTableLoading}
                  isLoading={hook.hookMerchantInfoLoading}
                  width="100%"
                />
                <Select
                  label="Product Category"
                  name="categorySelect"
                  selected={hook.hookProductCategory}
                  onChange={hook.hookSetProductCategory}
                  options={hook.hookProductCategoryList}
                  isDisabled={hook.hookTableLoading}
                  width="100%"
                />
              </>
            ),
            [
              hook.hookAd,
              hook.hookAdList,
              hook.hookAdCampaign,
              hook.hookAdCampaignList,
              hook.hookProductCategory,
              hook.hookProductCategoryList,
              hook.hookLoading,
              hook.hookDisabledFields,
              hook.hookMerchantInfoLoading,
              hook.hookTableLoading,
            ]
          )}

          <InputTextRef
            faIcon={faSearch}
            type="text"
            label="search"
            name="search"
            placeholder="Search Order ID / Publisher CID / Customer ID / Click ID"
            width="100%"
            ref={hook.hookSearchInputRef}
            disabled={hook.hookTableLoading}
          />
        </Styled.AdvancedFiltersRowStyled>

        <Styled.OptionsRowStyled>
          <Styled.LeftStyled>
            <RecordsDropdown
              onChange={hook.hookSetSelectedRecordsPerPage}
              selected={hook.hookSelectedRecordsPerPage}
              options={hook.hookRecordsPerPage}
              isDisabled={hook.hookLoading || hook.hookTableLoading}
              currentPage={hook.hookCurrentPage}
              totalRecords={hook.hookTotalRecords}
            />
            <Button
              theme="tertiary"
              onClick={hook.hookDownloadCSV}
              disabled={hook.hookLoading || hook.hookTableLoading}
              loading={hook.hookTableLoading || hook.hookCSVLoading}
              width="150px"
            >
              <FontAwesomeIcon icon={faArrowDown} style={{ marginRight: 5 }} /> Download CSV
            </Button>
          </Styled.LeftStyled>

          <Styled.RightStyled>
            <Styled.ButtonStyled
              theme="tertiary"
              onClick={hook.hookSetColumnsModal}
              disabled={hook.hookLoading || hook.hookTableLoading}
              loading={hook.hookTableLoading}
              width="180px"
            >
              <FontAwesomeIcon icon={faGrip} style={{ marginRight: 5 }} /> Customize Columns
            </Styled.ButtonStyled>

            <Styled.ButtonStyled
              onClick={() => hook.hookGenerateReport()}
              disabled={hook.hookLoading || hook.hookTableLoading}
              loading={hook.hookTableLoading}
              width="160px"
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} /> Generate Report
            </Styled.ButtonStyled>
          </Styled.RightStyled>
        </Styled.OptionsRowStyled>

        {((hook.hookTableData && hook?.hookTableData?.length > 0) ||
          (hook.hookTableLoading && hook.hookTableGenerated)) && (
          <>
            <DragTable
              columns={hook.hookTableColumns}
              data={hook.hookTableData ?? []}
              isLoading={hook.hookTableLoading}
              onSort={hook.hookSortTableHandler}
              sortColumn={hook.hookSortColumn}
              onDragEnd={hook.hookSetOnDragEnd}
              key={hook.hookTableKey}
            />
            <Pagination
              total={hook.hookTotalPages}
              currentPage={hook.hookCurrentPage}
              onPageChange={hook.hookSetCurrentPage}
            />
          </>
        )}

        {(!hook.hookTableData || !hook.hookTableData.length) && !(hook.hookTableLoading && hook.hookTableGenerated) && (
          <ReportPlaceholder
            submitted={hook.hookTableGenerated}
            text={hook.hookTableGenerated ? PLACEHOLDER.TEXT_NO_RESULTS : PLACEHOLDER.TEXT_PENDING}
            subtext={hook.hookTableGenerated ? PLACEHOLDER.SUBTEXT_NO_RESULTS : PLACEHOLDER.SUBTEXT_PENDING}
          />
        )}
        {useMemo(
          () => (
            <Calendar
              key={hook.hookCalendarKey}
              date={hook.hookStartDate}
              endDate={hook.hookEndDate}
              onApply={hook.hookOnApplyCalendar}
              onCancel={() => hook.hookSetOpenCalendar(false)}
              isOpen={hook.hookOpenCalendar}
              hasRange
              byRange={hook.hookRange}
            />
          ),
          [hook.hookStartDate, hook.hookEndDate, hook.hookOpenCalendar, hook.hookRange]
        )}
      </Styled.WrapperStyled>

      {useMemo(
        () => (
          <Modal isOpen={hook.hookColumnsModal}>
            <CustomizeColumnsModal
              closeFn={hook.hookSetColumnsModal}
              checkFn={hook.hookSetColumns}
              columns={hook.hookGlobalColumnState}
              reportColumns={hook.hookReportColumns}
              checkAllColumnsFn={hook.hookSetAllColumns}
            />
          </Modal>
        ),
        [hook.hookColumnsModal, hook.hookGlobalColumnState]
      )}

      {/** Shown when clicking on Ad ID Value */}
      {useMemo(
        () => (
          <AdGenerateCodeModal
            adId={hook.hookAdModalId}
            isOpen={hook.hookAdModal}
            onClose={hook.hookOpenCloseAdModal}
          />
        ),
        [hook.hookAdModalId, hook.hookAdModal, hook.hookOpenCloseAdModal]
      )}
    </AppWrapper>
  );
};

export default TransactionMasterReport;
