import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import * as Styled from '../styles';
import { CellProps } from '../types';
import { Button } from '../../../../../../../../../components';
import { MERCHANT_PREFIX, path } from '../../../../../../../../../utils';

export const IconCell = ({ val }: CellProps) => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate(`${MERCHANT_PREFIX}${path.editCommissions.href}`, {
      state: { val, goBack: -1 },
    });
  };

  return (
    <Styled.CellWrapper>
      <Button onClick={redirect} theme="text-only">
        <Styled.IconStyled icon={faChevronRight} />
      </Button>
    </Styled.CellWrapper>
  );
};
