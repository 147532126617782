import styled from 'styled-components';

import { fonts } from '../../../../../../styles/theme';
import { toRem } from '../../../../../../utils';

export const EmptyStateStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${toRem(100)};
  line-height: ${toRem(21)};
`;

export const TitleStyled = styled.p`
  ${fonts.lato.H14B};
  margin-bottom: ${toRem(8)};
`;

export const DecsriptionStyled = styled.p`
  ${fonts.lato.H14}
`;
