import styled from 'styled-components';

import { fonts, colors } from '../../../../styles/theme';
import { toRem } from '../../../../utils';

type TextStyledProps = {
  readonly theme?: string;
};

type BoxStyledProps = {
  readonly theme?: string;
};

export const TextStyled = styled.p<TextStyledProps>`
  ${({ theme }) => (theme === 'outer' ? fonts.lato.H14B : fonts.lato.H14)};
  color: ${({ theme }) => (theme === 'outer' ? colors.color1 : colors.color13)};
`;

export const BoxStyled = styled.div<BoxStyledProps>`
  display: flex;
  flex-direction: ${({ theme }) => (theme === 'amountCol' ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${({ theme }) => (theme === 'amountCol' ? 'center' : 'flex-start')};
  min-height: ${({ theme }) => (theme === 'amountCol' ? 'auto' : toRem(42))};
  gap: ${({ theme }) => (theme === 'amountCol' ? '' : '8px')};
`;
