export const TAB_NAMES = ['Account Information', 'Tracking Profile', 'Tracking Domains'];

export const SUBTITLE = {
  OVERVIEW: 'Overview',
  DEMOGRAPICS: 'Demographics',
};

export const TAB_DESCRIPTION = {
  TRACKING: "This is a list of the publisher's unique URLs used for tracking affiliate performance and traffic.",
  TRACKING_PUBLISHER_USER:
    "This is a list of your unique URLs used for tracking affiliate performance and traffic.\nPlease use the link to the webpage that contains the information regarding the merchant's products.",
  DOMAIN:
    "This is a complete list of domains we've detected to be associated with any one of the publisher's tracking profile(s).",
  DOMAIN_PUBLISHER_USER:
    "This is a complete list of domains we've detected to be associated with any one of your tracking profile(s).",
  DOMAIN_EMPTY: 'The tracking domain will show up here once a tracking event happens.',
  DOMAIN_EMPTY_PUBLISHER_USER: 'Your tracking domain will show up here once a tracking event happens.',
};

export const TAB_TYPES = {
  DOMAIN: 'domain',
  PROFILE: 'profile',
};

export const SORT_DEFAULT: TableSortColumn = { column: 'id', direction: 'desc' };

export const EMPTYSTATE_TITLE = {
  DOMAIN: 'No tracking domains found',
};

export const BUTTON_LABEL = {
  MESSAGE: 'Send Message',
  BACK: 'Back',
  DECLINE: 'Decline',
  APPROVE: 'Approve',
  PENDING: 'Pending',
  CLOSE: 'Close',
  CANCEL: 'Cancel',
  INVITE: 'Invite',
};

export const INPUT_LABEL = {
  ID: 'id',
  NETWORK_DATE: 'Network Registration Date',
  ACTIVITY_STATUS: 'Activity Status',
  APPLIED_DATE: 'Applied Date',
  INVITED_DATE: 'Invited date',
  MEMBERSHIP_HISTORY: 'membership history',
  MEMBERSHIP_STATUS: 'membership status',
  BUSINESS_DESCRIPTION: 'business description',
  PLATFORN_LINK: 'primary platform link',
  AUDIENCE_SIZE: 'Average Monthly Audience Size',
  AUDIENCE_MARKET: 'Primary Audience Market(s)',
  PRODUCT_CATEGORIES: 'Product Categories',
  PROMOTION_METHOD: 'Promotion Method(s)',
  AUDIENCE_AGE: 'Audience Age(s)',
  GENDER: 'Gender',
  RATING: 'Credit Score Rating',
  INCOME: 'Average Incomes(s)',
  PUBLISHER_GROUPS: 'Publisher Groups',
};

export const NA = 'N/A';

export const USER_TYPE = {
  PUBLISHER: 'Publisher',
};

export const DEFAULT_OVERVIEW = {
  businessDesc: '',
  primaryPlatformLink: '',
  hasWebsite: false,
  audienceMarkets: [],
  avgMonthlyAudience: '',
  productCategories: [],
  promotionMethods: [],
  audienceAges: [],
  gender: '',
  creditScoreRating: '',
  avgIncomes: [],
};

export const DEFAULT_PUBLISHER = {
  id: '',
  createdAt: '',
  updatedAt: '',
  approvedDate: '',
  accountStatus: '',
  statusLastUpdatedBy: null,
  activityStatus: '',
  publisherInvitation: false,
  companyName: '',
  name: '',
  companyUrl: '',
  companyImgUrl: '',

  publisherGroups: [],
  overview: {
    businessDesc: '',
    primaryPlatformLink: '',
    hasWebsite: false,
    audienceMarkets: [],
    avgMonthlyAudience: '',
    productCategories: [],
    promotionMethods: [],
    audienceAges: [],
    gender: '',
    creditScoreRating: '',
    avgIncomes: [],
  },
};

export const TOTAL_PER_PAGE = 10;

export const BUTTON_ACTIONS = {
  MENU_BACK: 'back',
  MENU_DECLINE: 'decline',
  MENU_APPROVE: 'appove',
  MENU_SEND_INVITATION: 'sendInvitation',
  MENU_INVITED: 'invited',
  MENU_CLOSE: 'close',
};

export const MENU_BUTTON_INIT: { [key: string]: boolean } = {
  back: true,
  decline: false,
  appove: false,
  sendInvitation: false,
  invited: false,
  close: false,
};

export const MODAL: {
  [key: string]: {
    TITLE: string;
    DESCRIPTION: string[][];
    BUTTON: string;
    STATUS: string;
  };
} = {
  appove: {
    TITLE: 'Approved Application',
    DESCRIPTION: [
      ['You are about to accept the application from ', '####', ' to join your program?'],
      ['They will receive confirmation emails and have access to your program details.'],
    ],
    BUTTON: BUTTON_LABEL.APPROVE,
    STATUS: 'Approved',
  },
  close: {
    TITLE: 'Close Application',
    DESCRIPTION: [
      ['You are about to close the membership between you and ', '####'],
      [
        ' They will be notified by email and not have access to your program details, but you can re-approve in the future.',
      ],
    ],
    BUTTON: BUTTON_LABEL.CLOSE,
    STATUS: 'Closed',
  },
  decline: {
    TITLE: 'Decline Application',
    DESCRIPTION: [
      ['You are about to decline application from ', '####', ' to join your program?'],
      ['They will be notified by email and not have access to your program details.'],
    ],
    BUTTON: BUTTON_LABEL.DECLINE,
    STATUS: 'Declined',
  },
};

export const MERCHANT = 'Merchant';

export const STATUS = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  CLOSED: 'Closed',
  PENDING: 'Pending',
  INVITED: 'Invited',
  INVITATION_DECLINED: 'Invitation Declined',
  CLOSED_BY_PUBLISHER: 'Closed By Publisher',
  CLOSED_BY_MERCHANT: 'Closed By Merchant',
};

export const STATUS_TEXT = {
  APPROVED: (date: string) => `Approved on ${date}`,
  DECLINED: (date: string) => `Declined on ${date}`,
  CLOSED: (date: string, declineDate: string) => `Approved on ${date} \nDeclined on ${declineDate}`,
};
