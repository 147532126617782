import styled from 'styled-components';

import { fonts, colors } from '../../../../styles/theme';
import { Button } from '../../../../components/Button';

type CellStyledProps = {
  readonly theme?: string;
};
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const CellStyled = styled.span<CellStyledProps>`
  min-height: 42px;
  display: flex;
  gap: 0.5rem;
  word-wrap: normal;
  color: ${colors.color1};

  justify-content: ${({ theme }) => (theme === 'quaternary' ? 'space-between' : 'flex-start')};
  flex-direction: ${({ theme }) => (theme === 'third' ? 'column' : 'row')};
  white-space: ${({ theme }) => (theme === 'third' ? '' : 'pre-wrap')};
  word-wrap: ${({ theme }) => (theme === 'third' ? '' : 'break-word')};
  ${({ theme }) => (theme === 'secondary' ? fonts.lato.H12 : fonts.lato.H14)};

  align-items: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
      case 'third':
        return 'flex-start';
      default:
        return 'center';
    }
  }};
`;

export const LinkStyled = styled.a`
  color: ${colors.color2};
  ${fonts.lato.H12};
`;

export const ButtonStyled = styled(Button)`
  ${fonts.lato.H14};
`;
