import React from 'react';

import { MonitoringRuleSummaryModal } from '../MonitoringRuleSummaryModal';

import { useAddMonitoringRuleModal } from './hooks';
import { AddRuleStepOneModal, AddRuleStepTwoModal, CancelAddRuleModal } from './components';

type RuleDetailsModalProps = {
  closeModalFn: (state: boolean) => void;
  isOpen: boolean;
  newRuleCreated: () => void;
};

export const AddMonitoringRuleModal = ({ closeModalFn, isOpen, newRuleCreated }: RuleDetailsModalProps) => {
  const hook = useAddMonitoringRuleModal(closeModalFn, newRuleCreated);

  return (
    <>
      <CancelAddRuleModal
        isOpen={hook.cancelOpen && isOpen}
        cancelButtonHandler={hook.cancelButtonHandler}
        exitAddRuleModalHandler={hook.exitAddRuleModalHandler}
      />
      <AddRuleStepOneModal
        isOpen={hook.currentModal === 1 && !hook.cancelOpen && isOpen}
        errorMessage={hook.errorMessage}
        ruleName={hook.ruleName}
        setRuleName={hook.setRuleName}
        ruleNameError={hook.ruleNameError}
        selectedCheckRuleGroup={hook.selectedCheckRuleGroup}
        checkRuleGroupsList={hook.checkRuleGroupsList}
        setSelectedCheckRuleGroupHandler={hook.setSelectedCheckRuleGroupHandler}
        cancelButtonHandler={() => hook.cancelButtonHandler(true)}
        navigateModalTwo={hook.navigateModalTwo}
        canContinueOne={hook.canContinueOne}
        ruleNameCheckLoading={hook.loadingNavigateModalTwo}
      />

      <AddRuleStepTwoModal
        isOpen={hook.currentModal === 2 && !hook.cancelOpen && isOpen}
        uniqueErrors={hook.uniqueErrors}
        backButtonHandler={hook.backButtonHandler}
        customTextList={hook.customTextList}
        updateCustomTextValue={hook.updateCustomTextValue}
        addNewCustomText={hook.addNewCustomText}
        stepTwoErrors={hook.stepTwoErrors}
        onBlurCheck={hook.onBlurCheck}
        removeCustomTextById={hook.removeCustomTextById}
        cancelButtonHandler={() => hook.cancelButtonHandler(true)}
        navigateModalThree={hook.navigateModalThree}
        canContinueTwo={hook.canContinueTwo}
        canAddMoreCustomText={hook?.canAddMoreCustomText}
        ruleStatusSettings={hook.ruleStatusSettings}
        setRuleStatusSettings={hook.setRuleStatusSettings}
      />

      <MonitoringRuleSummaryModal
        closeModalFn={() => hook.cancelButtonHandler(true)}
        goBackFn={() => hook.setCurrentModal(2)}
        isOpen={hook.currentModal === 4 && !hook.cancelOpen && isOpen}
        errorMessage={hook.createRuleError}
        isNewRule
        value={hook.newSummary}
        createRuleFn={hook.createRule}
        createRuleLoading={hook.createRuleLoading}
      />
    </>
  );
};
