/* eslint-disable no-nested-ternary */
import React from 'react';

import { Placeholder } from '../../Placeholder';
import { path } from '../../../../../utils';
import { LoadingDots, Modal, Pagination, Table } from '../../../../../components';
import { settingColumns } from '../../contracts';
import { MODAL_KEYS } from '../../reducers';
import { FilterSettingsModal } from '../../FilterSettingsModal';
import { DeleteReportModal } from '../../DeleteReportModal';

import { useTransactionReports } from './hooks';
import * as Styled from './styles';

export const TransactionReports = () => {
  const hook = useTransactionReports();
  return (
    <Styled.WrapperStyled>
      {hook.hookLoading ? (
        <LoadingDots />
      ) : hook.hookData.length === 0 ? (
        <Placeholder link={path.performanceReport.href} />
      ) : (
        <>
          <Table
            data={hook.hookData}
            columns={settingColumns}
            setData={hook.hookSetData}
            setContext={hook.hookSetModals}
            setOpenModal={hook.hookReportCreated}
          />
          <Pagination
            currentPage={hook.hookCurrentPage}
            total={hook.hookTotalPages}
            onPageChange={hook.hookSetCurrentPage}
          />

          <Modal isOpen={hook.hookModals[MODAL_KEYS.openSettingModal]}>
            <FilterSettingsModal
              info={hook.hookModals.modalInfo}
              setOpenModal={hook.hookSetModals}
              reportType="Transaction Master"
            />
          </Modal>

          <Modal isOpen={hook.hookModals[MODAL_KEYS.openDeleteModal]}>
            <DeleteReportModal
              info={hook.hookModals.modalInfo}
              setOpenModal={hook.hookSetModals}
              reportDeleted={hook.hookReportDeleted}
            />
          </Modal>
        </>
      )}
    </Styled.WrapperStyled>
  );
};
