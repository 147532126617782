import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';

import { Link } from '../../../../components';
import { colors, fonts } from '../../../../styles/theme';
import {
  PUBLISHER_PREFIX,
  imageList,
  path,
  returnColorBaseOnValue,
  returnIconBaseOnValue,
  truncateNumberAndRoundWithDecimals,
} from '../../../../utils';

type CellProps = {
  val: any;
};

type CommissionFormattedProps = {
  totalCommissions: number;
  commissionsPerformance: number;
};
type IconStyledProps = {
  color: string;
};

type GridCellStyledProps = {
  readonly small?: boolean;
};

type PercentageStyledProps = {
  readonly color: string;
};

type CellWrapperProps = {
  readonly cellHeight?: string;
  readonly whiteSpace?: string;
};

const CellWrapper = styled.div<CellWrapperProps>`
  width: 100%;
  height: ${({ cellHeight }) => cellHeight || '40px'};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.5;

  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${({ whiteSpace }) => whiteSpace || 'nowrap'};
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
`;

const ImgStyled = styled.img`
  height: 50px;
`;

const LinkStyled = styled(Link)`
  color: ${colors.color1};
`;

const IconStyled = styled(FontAwesomeIcon)<IconStyledProps>`
  width: 15px;
  color: ${({ color }) => color};
`;

const GridCellStyled = styled.div<GridCellStyledProps>`
  display: grid;
  grid-template-columns: ${({ small }) => (small ? '20% 80%' : '35% 65%')};
  text-align: left;
  width: 100%;
  align-items: center;
`;

const FlexItemStyled = styled.div`
  display: flex;
  align-items: center;
`;

const CellFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <p>{val}</p>
  </CellWrapper>
);

const PercentageStyled = styled.div<PercentageStyledProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${fonts.lato.H14}
  border-radius: 15px;
  min-width: 60px;
  padding: 5px;
  height: 30px;
  color: ${({ color }) => color};
  background: ${({ color }) => {
    switch (color) {
      case colors.color14:
        return 'rgba(225, 93, 104, 0.05)';
      case colors.color2:
        return 'rgba(247, 141, 45, 0.05)';
      case colors.color21:
        return 'rgba(75, 199, 192, 0.05)';
      default:
        return 'none';
    }
  }};
  margin-left: 1rem;
`;

const CommissionsFormatted = ({ totalCommissions, commissionsPerformance }: CommissionFormattedProps) => (
  <CellWrapper>
    <GridCellStyled>
      <FlexItemStyled>${totalCommissions}</FlexItemStyled>
      <FlexItemStyled>
        <PercentageStyled
          color={returnColorBaseOnValue({
            value: commissionsPerformance,
            negativeColor: colors.color14,
            neutralColor: colors.color2,
            positiveColor: colors.color21,
          })}
        >
          {commissionsPerformance !== 0 && (
            <IconStyled
              icon={returnIconBaseOnValue<IconProp>({
                value: commissionsPerformance,
                negativeIcon: faLongArrowAltDown,
                positiveIcon: faLongArrowAltUp,
              })}
              color={returnColorBaseOnValue({
                value: commissionsPerformance,
                negativeColor: colors.color14,
                neutralColor: colors.color2,
                positiveColor: colors.color21,
              })}
            />
          )}
          {truncateNumberAndRoundWithDecimals(Math.abs(commissionsPerformance), 0)}%
        </PercentageStyled>
      </FlexItemStyled>
    </GridCellStyled>
  </CellWrapper>
);

const ImageFormatted = ({ val }: CellProps) => (
  <CellWrapper cellHeight="50px">
    <ImgWrapper>
      <ImgStyled src={val || imageList.noPic.src} alt="cover" />
    </ImgWrapper>
  </CellWrapper>
);

const ProductCategoryCell = ({ val }: CellProps) => (
  <CellWrapper whiteSpace="break-spaces">
    <p>{val}</p>
  </CellWrapper>
);

const LinkFormatted = ({ val }: CellProps) => (
  <CellWrapper>
    <LinkStyled to={val}>Apply now</LinkStyled>
  </CellWrapper>
);

export const topProductColumns: TableColumn[] = [
  {
    dataField: 'name',
    text: 'Name',
    width: '150px',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'merchantName',
    text: 'Merchants',
    width: '22.5%',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'totalTransactions',
    text: 'Total Transactions',
    width: '170px',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'approvedTransactions',
    text: 'Approved Transactions',
    width: '210px',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'totalCommissions',
    text: 'Total Commission',
    width: '170px',
    formatter: (val: any, row: any) => (
      <CommissionsFormatted totalCommissions={val} commissionsPerformance={row.commissionsPerformance * 100} />
    ),
  },
];

export const newMerchantColumns: TableColumn[] = [
  {
    dataField: 'logo',
    text: '',
    width: '35%',
    formatter: (val: any) => <ImageFormatted val={val} />,
  },
  {
    dataField: 'productCategories',
    text: 'Product Categories',
    width: '30%',
    formatter: (val: any) => <ProductCategoryCell val={val} />,
  },
  {
    dataField: 'country',
    text: 'Country',
    width: '18%',
    formatter: (val: any) => <CellFormatted val={val} />,
  },
  {
    dataField: 'action',
    text: 'Action',
    width: '17%',
    formatter: (val: any) => <LinkFormatted val={`${PUBLISHER_PREFIX}${path.programDetails.href}?id=${val}`} />,
  },
];
