import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';

import { PDF_TYPES, csvGenerator, dateFormatter, path, sortDate, sortString } from 'utils';
import { useUserInfo } from 'hooks';
import { useDebounce } from 'utils/useDebounce';
import dataToCSVFormatter from 'utils/csvStringConverter';

import { CSV_HEADERS_DETAILS, SORT_DEFAULT } from '../contracts';
import { GET_ACCOUNT_BALANCE_DETAILS } from '../graphql/queries';
import { FINALIZE_ACCOUNNT_BALANCE_BY_ID } from '../graphql/mutations';

export const useAccountBalanceManagementDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userHook = useUserInfo();

  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>(SORT_DEFAULT);

  const [getAccountBalanceDetails] = useLazyQuery(GET_ACCOUNT_BALANCE_DETAILS);
  const [finalizeAccountBalanceDetails] = useMutation(FINALIZE_ACCOUNNT_BALANCE_BY_ID);

  const [searchValue, setSearchValue] = useState('');
  const [modelOpen, setModelOpen] = useState(false);

  const [merchant, setMerchant] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [isFinalized, setIsFinalized] = useState(false);
  const [accountBalancesId, setAccountBalancesId] = useState(false);

  const [accountRecord, setAccountRecord] = useState<AccountBalanceRecord[]>([]);
  const [allAccountRecord, setAllAccountRecord] = useState<AccountBalanceRecord[]>([]);

  const debouncedSearch = useDebounce(searchValue, 500);

  const [isLoading, setIsLoading] = useState(true);
  const [isFinalizedLoading, setIsFinalizedLoading] = useState(false);

  const [errors, setErrors] = useState<string | undefined>();

  const today = new Date();

  const withInPeriod =
    location?.state?.month <= today.getUTCMonth() || location?.state?.year !== today.getUTCFullYear();

  const paginationData = (data: any[]) => {
    const limit = 20;
    const skipAmount = limit * (currentPage - 1) || 0;
    return data.slice(skipAmount, skipAmount + limit);
  };

  const getRecords = async (firstLoad = false): Promise<void> => {
    const accountBalancesIdState = location.state?.accountBalancesId;
    const productCategory = location.state?.productCategory;

    try {
      if (firstLoad) {
        setIsFinalized(isFinalized || location.state?.finalized);
        setMerchantId(location.state?.merchantId);
        setAccountBalancesId(accountBalancesIdState);
      }

      const {
        data: { accountBalanceDetailsV2 },
        error,
      } = await getAccountBalanceDetails({
        variables: {
          input: {
            id: accountBalancesId || accountBalancesIdState,
            productCategory,
            options: {
              page: currentPage,
              items: 20,
              order: sortColumn.direction?.toUpperCase(),
            },
            sortBy: sortColumn.column,
          },
        },
        fetchPolicy: 'no-cache',
      });

      if (error) {
        setErrors(error.message);
        setIsLoading(false);
        return;
      }

      if (accountBalanceDetailsV2) {
        const pageAccount = paginationData(accountBalanceDetailsV2.accountBalanceDetailItems);
        setAccountRecord(pageAccount);
        setAllAccountRecord(accountBalanceDetailsV2.accountBalanceDetailItems);
        setNumberOfPages(Math.ceil(accountBalanceDetailsV2.count / 20));
      }
    } catch (err: any) {
      setErrors(err);
    }
    setIsLoading(false);
  };

  const handleFinlizeAccoundRecord = async (): Promise<void> => {
    setIsFinalizedLoading(true);
    const input = {
      id: accountBalancesId,
      finalized: true,
    };

    try {
      const data = await finalizeAccountBalanceDetails({
        variables: {
          input,
        },
      });

      if (!data.data.updateAccountBalance.accountBalance)
        throw new Error('Failed to finalize account balance. Please try it again later');
      setModelOpen(false);
      setIsFinalized(true);
    } catch (err: any) {
      setErrors(err.message);
    }
    setIsFinalizedLoading(false);
  };

  const sortPaginationHandler = async (skipPagination = false): Promise<AccountBalanceRecord[]> => {
    let sortedData = allAccountRecord;

    if (sortColumn.column === 'recordDate') {
      sortedData = sortDate(allAccountRecord, sortColumn.column, sortColumn.direction);
    } else {
      sortedData = sortString(allAccountRecord, sortColumn.column, sortColumn.direction);
    }

    if (searchValue !== '') {
      sortedData = sortedData.filter((record) => {
        if (record.recordName.toLowerCase().includes(searchValue.toLowerCase())) return true;
        if (record.recordType.toLowerCase().includes(searchValue.toLowerCase())) return true;
        return false;
      });
    }
    if (!skipPagination) {
      sortedData = paginationData(sortedData);
      setNumberOfPages(Math.ceil(sortedData.length / 20));
      setAccountRecord(sortedData);
    } else {
      return sortedData;
    }
    return sortedData;
  };

  const generateCSVHandler = async (): Promise<void> => {
    const csvAllRecords = await sortPaginationHandler(true);

    if (Array.isArray(csvAllRecords)) {
      const formatedRecords = csvAllRecords.map((record) => ({
        ...record,
        recordName: `"${record.recordName}"`,
        recordType: `"${record.recordType}"`,
        systemFee: record.systemFee || 0,
        recordDate: dateFormatter(new Date(record.recordDate)),
      }));
      const csvData = dataToCSVFormatter(CSV_HEADERS_DETAILS, formatedRecords);
      csvGenerator(csvData, 'account-balance-details');
    }
  };

  const setSearchValueHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
  };

  const statementHandler = (): void => {
    navigate(path.invoiceStatement.href, {
      state: {
        merchantId,
        accountBalancesId,
        productCategory: location.state?.productCategory,
        pdfType: PDF_TYPES.MERCHANT,
        year: location.state?.year?.toString(),
        month: location.state?.month?.toString(),
      },
    });
  };

  const setModelOpenHandler = (value: boolean): void => setModelOpen(value);

  const setCurrentPageHandler = (value: number): void => setCurrentPage(value);

  const setSortColumnHandler = (dataField: string, direction: 'asc' | 'desc' | undefined): void => {
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  useEffect(() => {
    if (location.state) {
      setMerchant(location.state?.merchant || '');
      getRecords(true);
    }
  }, []);

  useEffect(() => {
    sortPaginationHandler();
  }, [currentPage, JSON.stringify(sortColumn), debouncedSearch]);

  return {
    hookSearchValue: searchValue,
    hookSetSearchValue: setSearchValueHandler,

    hookWithInPeriod: withInPeriod,

    hookMerchant: merchant,
    hookAccountRecord: accountRecord,

    hookModelOpen: modelOpen,
    hookSetModelOpen: setModelOpenHandler,
    hookStatementHandler: statementHandler,

    hookIsFinalized: isFinalized,
    hookHandleFinlizeAccoundRecord: handleFinlizeAccoundRecord,

    hookGenerateCSV: generateCSVHandler,

    hookIsLoading: isLoading,
    hookUserType: userHook.hookUserInfo,
    hookIsFinalizedLoading: isFinalizedLoading,

    hookNumberOfPages: numberOfPages,
    hookCurrentPage: currentPage,
    hookSetCurrentPage: setCurrentPageHandler,

    hookSortColumn: sortColumn,
    hookSetSortColumn: setSortColumnHandler,

    hookErrors: errors,
  };
};
