import React from 'react';

import { InputCalendar, Calendar } from '../../../../../../components';
import { useEditCommissions } from '../../hooks/useEditCommissions';
import { EDIT_COMMISSIONS } from '../../enums';
import { dateFormatter } from '../../../../../../utils';

import { BonusStructure, CPAStructure, ReferralStructure, RevShareStructure } from './components';
import * as Styled from './styles';

type DetailsProps = {
  hook: ReturnType<typeof useEditCommissions>;
  isReadOnly: boolean;
};

export const Details = ({ hook, isReadOnly }: DetailsProps) => (
  <Styled.WrapperStyled>
    <Styled.GridedRowStyled>
      {hook.hookIsCustom && (
        <Styled.InputTextName
          required
          label={EDIT_COMMISSIONS.COMMISSION_NAME}
          name="commissionName"
          type="text"
          value={hook.commissionName}
          onChange={hook.setCommissionNameHandler}
          error={hook.hookEditCommissionErrors.commissionName}
        />
      )}
      {!hook.hookIsCustom && (
        <Styled.InputTextStyled
          label={EDIT_COMMISSIONS.COMMISSION_NAME}
          type="text"
          value="Default"
          theme="readonly"
          readonly
        />
      )}
      <Styled.InputTextStyled
        label={EDIT_COMMISSIONS.DETAILS.INPUT.COMMISSION_ID}
        type="text"
        value={hook.hookCommissionData?.id || ''}
        theme="readonly"
        readonly
      />
      <Styled.InputTextStyled
        label={EDIT_COMMISSIONS.DETAILS.INPUT.PRODUCT_NAME}
        type="text"
        value={hook.hookCommissionData?.products.map((product) => product.name).toString() || 'All Products'}
        theme="readonly"
        readonly
      />
      <InputCalendar
        label={EDIT_COMMISSIONS.DETAILS.INPUT.START_DATE}
        placeholder={EDIT_COMMISSIONS.DETAILS.INPUT.START_DATE}
        value={dateFormatter(hook.hookDetailsStartDate ?? '')}
        isDisabled
      />
      <InputCalendar
        label={EDIT_COMMISSIONS.DETAILS.INPUT.END_DATE}
        placeholder={EDIT_COMMISSIONS.DETAILS.INPUT.END_DATE_PLACEHOLDER}
        value={hook.hookDetailsEndDate?.toDateString() || ''}
        onClick={() => hook.hookSetDetailsCalendarState(true)}
        isDisabled={isReadOnly}
      />
    </Styled.GridedRowStyled>

    <Styled.SectionTitleStyled>
      {hook.hookIsCustom && (
        <Styled.InputTextStyled
          theme="readonly"
          big
          maxLength={9000}
          label={EDIT_COMMISSIONS.DETAILS.INPUT.COMMISSION_DESC}
          type="text"
          value={hook.hookCommissionData?.description || ''}
          readonly
        />
      )}
    </Styled.SectionTitleStyled>

    <Styled.SectionTitleStyled>{EDIT_COMMISSIONS.DETAILS.SECTION_TITLE}</Styled.SectionTitleStyled>

    {(() => {
      switch (hook.hookCommissionData?.commissionType) {
        case 'CPA':
          return <CPAStructure hook={hook} />;
        case 'Referral':
          return <ReferralStructure hook={hook} />;
        case 'RevShare':
          return <RevShareStructure hook={hook} />;
        case 'Bonus':
          return <BonusStructure hook={hook} />;
        default:
          return <p />;
      }
    })()}

    <Calendar
      date={hook.hookDetailsEndDate}
      onApply={hook.hookOnApplyDetailsCalendar}
      onCancel={() => hook.hookSetDetailsCalendarState(false)}
      isOpen={hook.hookDetailsCalendarOpen}
      maxYear={new Date().getFullYear() + 2}
      isSingle
      minDate={hook.hookDetailsMinDate}
    />
  </Styled.WrapperStyled>
);
