import React from 'react';

import { DefaultPropsType } from '../../types';
import { AppWrapper, Pagination, Table } from '../../components';

import { useNotifications } from './hooks/useNotifications';
import { columns } from './contracts/columns';

type NotificationsPropsType = DefaultPropsType;

export const Notifications = ({ permissionsCodeList = [] }: NotificationsPropsType) => {
  const hook = useNotifications();
  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Table
        columns={columns}
        data={hook.hookTableData}
        isLoading={hook.hookNotificationsLoading}
        onRowClick={hook.hookUpdateNotificationHandler}
      />
      {hook.hookTotalPages !== 0 && (
        <Pagination total={hook.hookTotalPages} currentPage={hook.hookCurrentPage} onPageChange={hook.hookSetPage} />
      )}
    </AppWrapper>
  );
};
