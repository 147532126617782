import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { paginator, PUBLISHER_PREFIX, path } from '../../../../../../utils';
import { GET_PRODUCT_FEED } from '../graphql/getProductFeed';
import { Permission } from '../../../../../../entities';
import { copyToClipboard } from '../../../../../../utils/copyToClipboard';

export const useProductFeedDetails = (permissionsCodeList: string[] = []) => {
  const location = useLocation();
  const productFeedId = location.state;
  const [getProductFeed, { loading: getProductFeedLoading }] = useLazyQuery(GET_PRODUCT_FEED);
  const [productFeed, setProductFeed] = useState<any>();
  const [products, setPaginatedProducts] = useState<any>();
  const [allProducts, setAllProducts] = useState<any>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [urlCopied, setUrlCopied] = useState(false);
  const navigate = useNavigate();

  const goTo = (row: any) => {
    navigate(`${PUBLISHER_PREFIX}${path.productDetails.href}`, { state: row?.productId });
  };

  const FormatProductFeed = (feed: any) => {
    const merchants = feed.merchants.map((merchant: any) => merchant.companyName).join(', ');
    const subCategories = feed.subCategories.join(', ');
    const productCategories = feed.productCategories.join(', ');
    const regionStates = feed.regionStates.join(', ');
    return {
      trackingProfileId: feed.trackingProfileId,
      regionCountry: feed.regionCountry,
      regionStates,
      productFeedUrl: feed.productFeedUrl,
      productCategories,
      subCategories,
      merchants,
    };
  };

  const FormatProducts = (feed: any) =>
    feed.products.map((product: any) => ({
      id: product.customizedProductId,
      merchant: product.program.merchant.companyName,
      productName: product.name,
      productCategory: product.productCategory,
      subcategories: product.subCategories.join(', '),
      lastUpdated: new Date(product.updatedAt),
      productId: product.id,
      productImageUrl: product.productImageUrl,
    }));

  const handleGetProductFeed = async () => {
    const { data } = await getProductFeed({ variables: { productFeedId }, fetchPolicy: 'no-cache' });
    if (data.productFeed !== undefined) {
      setProductFeed(FormatProductFeed(data.productFeed));
      setAllProducts(FormatProducts(data.productFeed));
      setPaginatedProducts(paginator(FormatProducts(data.productFeed), 6, 1));
      setCurrentPage(1);
      setTotalPages(Math.ceil(data.productFeed.products.length / 6));
    }
  };

  const handleChangePage = (value: any) => {
    setPaginatedProducts(paginator(allProducts, 6, value));
    setCurrentPage(value);
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(productFeed?.productFeedUrl);
    setUrlCopied(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    handleGetProductFeed();
  }, []);

  return {
    hookChangePage: handleChangePage,

    hookProductFeed: productFeed,

    hookProducts: products,

    hookCurrentPage: currentPage,

    hookTotalPages: totalPages,

    hookHandleBack: handleBack,

    hookLoading: getProductFeedLoading,

    hookCopyToClipboard: handleCopyToClipboard,

    hookUrlCopied: urlCopied,

    hookGoTo: goTo,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
