import React from 'react';

import { CellWrapper } from '../../styles';
import { Checkbox } from '../../../../../../../components';

export const Header = (checked: boolean, onChange: (state: boolean) => void, isReadOnly: boolean) => (
  <CellWrapper>
    <Checkbox checked={checked} onChange={onChange} disabled={isReadOnly} />
  </CellWrapper>
);
