import React from 'react';

import { BUTTON_LABEL, MODAL_DISCRIPTION, MODAL_TITLE } from '../../contracts';
import { Button } from '../../../../../../components';
import * as Styled from '../../styles';

type SearchPublisherAddRemoveModalProps = {
  modalType: string;
  groupName: string;
  selectedPublisherCount: number;
  cancelHandler: () => void;
  submitHandler: () => void;
};

export const SearchPublisherAddRemoveModal = ({
  modalType,
  groupName,
  selectedPublisherCount,
  cancelHandler,
  submitHandler,
}: SearchPublisherAddRemoveModalProps) => (
  <Styled.AddRemoveModalStyled>
    <Styled.ModalTitleStyled>{MODAL_TITLE[modalType]}</Styled.ModalTitleStyled>
    <Styled.ModalGroupsStyled>{groupName}</Styled.ModalGroupsStyled>
    <Styled.ModelDescriptionStyled>
      {MODAL_DISCRIPTION[modalType]?.split('##').map((dec, index) => (
        <Styled.SpanStyled key={dec} theme={index === 1 ? 'secondary' : ''}>
          {index === 1 ? selectedPublisherCount : dec}
        </Styled.SpanStyled>
      ))}
    </Styled.ModelDescriptionStyled>
    {modalType === 'REMOVE' && <Styled.SpanStyled>{MODAL_DISCRIPTION.REMOVE_METADATA}</Styled.SpanStyled>}
    <Styled.ModalButtonAreaStyled>
      <Button onClick={cancelHandler} theme="secondary">
        {BUTTON_LABEL.CANCEL}
      </Button>

      <Styled.FooterButtonWrapperStyled>
        <Button onClick={submitHandler}>{BUTTON_LABEL[modalType]}</Button>
      </Styled.FooterButtonWrapperStyled>
    </Styled.ModalButtonAreaStyled>
  </Styled.AddRemoveModalStyled>
);
