export const REPORT = {
  INVISIBLE_DATA: {
    TITLE: 'Set Invisible',
    VISIBILITY_NAME: 'invisible',
    LIST: ['The Fintel Check will be hidden for the merchant.'],
    BUTTON_NAME: 'Set Invisible',
  },
  VISIBLE_DATA: {
    TITLE: 'Set Visible',
    VISIBILITY_NAME: 'visible',
    LIST: [
      'Merchant will be able to access the Fintel Check immediately.',
      'if Admin has used Fintel Check for internal user, all the settings and history reports will be reset.',
    ],
    BUTTON_NAME: 'Set Visible',
  },
};
