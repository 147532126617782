import styled from 'styled-components';

import { colors } from '../../styles/theme';
import { Select } from '../Input';

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.color1};
`;

export const RecordsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectStyled = styled(Select)`
  padding: 0 1rem;
`;
