import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faSort } from '@fortawesome/free-solid-svg-icons';

import * as Styled from './styles';

export const TablePlaceholder = () => (
  <Styled.WrapperStyled>
    <Styled.RowWrapper>
      <Styled.HeadStyled>
        Publisher
        <Styled.IconsWrapperStyled>
          <FontAwesomeIcon icon={faSort} />
        </Styled.IconsWrapperStyled>
      </Styled.HeadStyled>
      <Styled.HeadStyled>
        Group By
        <Styled.IconsWrapperStyled>
          <FontAwesomeIcon icon={faSort} />
        </Styled.IconsWrapperStyled>
      </Styled.HeadStyled>
      <Styled.HeadStyled>
        Page
        <Styled.IconsWrapperStyled>
          <FontAwesomeIcon icon={faSort} />
        </Styled.IconsWrapperStyled>
      </Styled.HeadStyled>
      <Styled.HeadStyled>
        Pass
        <Styled.IconsWrapperStyled>
          <FontAwesomeIcon icon={faSort} />
        </Styled.IconsWrapperStyled>
      </Styled.HeadStyled>
      <Styled.HeadStyled>
        Review
        <Styled.IconsWrapperStyled>
          <FontAwesomeIcon icon={faSort} />
        </Styled.IconsWrapperStyled>
      </Styled.HeadStyled>
      <Styled.HeadStyled>
        Fail
        <Styled.IconsWrapperStyled>
          <FontAwesomeIcon icon={faSort} />
        </Styled.IconsWrapperStyled>
      </Styled.HeadStyled>
      <th>
        <Styled.InvisibleIcon icon={faChevronRight} />
      </th>
    </Styled.RowWrapper>
    <Styled.RowWrapper>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.InvisibleData />
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <td>
        <FontAwesomeIcon icon={faChevronRight} />
      </td>
    </Styled.RowWrapper>
    <Styled.RowWrapper>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.InvisibleData />
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <td>
        <FontAwesomeIcon icon={faChevronRight} />
      </td>
    </Styled.RowWrapper>
    <Styled.RowWrapper>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.InvisibleData />
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <td>
        <FontAwesomeIcon icon={faChevronRight} />
      </td>
    </Styled.RowWrapper>
    <Styled.RowWrapper>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.InvisibleData />
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <td>
        <FontAwesomeIcon icon={faChevronRight} />
      </td>
    </Styled.RowWrapper>
    <Styled.RowWrapper>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.InvisibleData />
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <Styled.DataStyled> </Styled.DataStyled>
      <td>
        <FontAwesomeIcon icon={faChevronRight} />
      </td>
    </Styled.RowWrapper>
  </Styled.WrapperStyled>
);
