import React from 'react';

import { CellFormatted, DateCell, EditCell, WebsiteCell } from './components';

export const columns = (isReadOnly: boolean): TableColumn[] => [
  {
    dataField: 'id',
    text: 'Id',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '15%',
    formatter: (value) => <CellFormatted value={value} />,
  },
  {
    dataField: 'profileName',
    text: 'Website URL / Description',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '44%',
    formatter: (value) => <WebsiteCell value={value} />,
  },
  {
    dataField: 'createdAt',
    text: 'Date Created',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '18%',
    formatter: (value) => <DateCell value={value} />,
  },
  {
    dataField: 'lastActive',
    text: 'Last Active Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '18%',
    formatter: (value) => <DateCell value={value} />,
  },
  {
    dataField: 'edit',
    text: '',
    width: '5%',
    formatter: (_, row, __, ___, ____, setModalOpen, setModalInfo) => (
      <EditCell row={row} setModalOpen={setModalOpen} setModalInfo={setModalInfo} isReadOnly={isReadOnly} />
    ),
  },
];
