import styled from 'styled-components';

import { Button } from '../../../../../../../components';
import { colors, fonts } from '../../../../../../../styles/theme';

export const CellWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
  ${fonts.lato.H16};
`;

export const DateStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ButtonStyled = styled(Button)`
  background: none;
  border: none;
  padding: 0.5rem;
  color: ${colors.color1};

  &:hover {
    border: none;
    background: none;
    color: ${colors.color2};
  }
`;
