import React from 'react';

import { CellWrapper, WebsiteStyled } from '../../styles';

export const CellDomain = (value: any): JSX.Element => {
  const { value: url } = value;
  return (
    <CellWrapper>
      <WebsiteStyled href={url} target="_blank">
        {url}
      </WebsiteStyled>
    </CellWrapper>
  );
};
