import React from 'react';

import * as Styled from './Components/styles';
import { DateCell, PublishersCell, ScreenshotCell, SummaryCell } from './Components';

export const columns: TableColumn[] = [
  {
    dataField: 'checkDate',
    text: 'Run Date',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '24%',
    formatter: (val) => <DateCell val={val} />,
  },
  {
    dataField: 'publisherCount',
    text: 'Publishers Checked',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '24%',
    formatter: (val) => <PublishersCell val={val} />,
  },
  {
    dataField: 'status',
    text: 'Status Rechecked',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '24%',
    formatter: (val) => <Styled.CellStyled>{val}</Styled.CellStyled>,
  },
  {
    dataField: 'zipfiles',
    text: 'Screenshots',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '24%',
    formatter: (val) => <ScreenshotCell val={val} />,
  },
  {
    dataField: 'summary',
    text: '',
    width: '4%',
    formatter: (_, row, __, ___, ____, _____, ______, setContext) => <SummaryCell row={row} setContext={setContext} />,
  },
];
