import styled from 'styled-components';

import { Button, Select, Table } from '../../../components';
import { colors, fonts } from '../../../styles/theme';

export const SelectStyled = styled(Select)`
  margin: 0 1rem;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;
`;

export const OptionsRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const HeaderButtonStyled = styled.div`
  display: flex;
`;

export const FiltersStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-bottom: 1rem;
`;

export const ClearFormStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  grid-column: 3;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.lato.H14}
`;

export const RightStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const PlaceholderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;

export const MaxDownloadStyled = styled.div`
  color: ${colors.color7};
  ${fonts.lato.H16}
`;

export const TableStyled = styled(Table)`
  display: flex;
  width: 100%;
  margin-top: 2rem;
`;
