import styled from 'styled-components';

import { Button } from '../../../components';
import { fonts, colors } from '../../../styles/theme';

export const ButtonStyled = styled(Button)`
  margin-left: 1rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const WrapperStyled = styled.div``;

export const HeaderStyled = styled.div`
  margin-bottom: 2rem;
`;

export const FiltersRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const FiltersRowRightStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 470px;
  margin-bottom: 2rem;
  margin-left: 71.5%;
`;

export const TextStyled = styled.span`
  margin-right: 1rem;
  ${fonts.gotham.H14}
`;

export const FileDescriptionStyled = styled.h2`
  color: ${colors.color1};
  margin-bottom: 2rem;
  ${fonts.gotham.H22}
`;

export const PlaceholderStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;
  ${fonts.lato.H18}

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    width: 180px;
  }

  span {
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }
`;
