import { gql } from '@apollo/client';

export const UPDATE_PUBLISHER_INVITES = gql`
  mutation updatePublisherInvitationManagement($inputs: [PublisherInvitationManagementInput]!) {
    updatePublisherInvitationManagement(inputs: $inputs) {
      id
      merchantType
      enabled
      limitPerMonth
    }
  }
`;
